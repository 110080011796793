import React, { Component } from "react";
import Swiper from "react-id-swiper";
import ListingMediaModal from "../../../components/Modals/Media/ListingMediaModal";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import ListingMeta from "./ListingMeta";

import { formatPriceAndCurrency, displayNumber } from "../../../util/number";
import AutoExteriorColor from "../../../../pages/Listings/Autos/Assets/auto-exterior-color.svg";
import EngageBox from "../../../../shared/uielements/Engage/EngageBox";
import { titleCase } from "../../../util/string";
import { getTranslatedPhrase } from "../../../util/lang";
import DefaultImage from "../../../../assets/default/default-events-listing-preview.jpg";
import DefaultAutoImage from "../../../../assets/default/default-auto-listing-preview.jpg";
import RealEstateFloorSize from "../../../../pages/Listings/RealEstate/Assets/realestate-surface.svg";
import CBALogo from "../../../../pages/Listings/RealEstate/Assets/cba-logo-white.png";
import { toHttps } from "../../../util/toHttps";

class ListingHeader extends Component {
  state = {
    openModal: false,
    currentPhoto: -1,
    photos: [],
    a: "",
  };

  componentDidMount() {
    let a = this.props.address.trim();

    if (!a) {
      a = "0"; //Empire State Building';
    }

    a = a.replace(/\W(ste|Ste|Suite|SUITE|apt|Apt|APT|#).?\s?\d+/g, "");
    a = a.replace(/\s\s*/g, "+");
    a = a.replace(/&/g, "and");

    const photoList = [...this.props.photos];
    const cleanPhotos = [];

    photoList.forEach((el) => {
      cleanPhotos.push(el.replace(/\\/g, ""));
    });

    this.setState({ photos: cleanPhotos, a: a });
  }

  componentDidUpdate() {
    if (this.state.photos[0] !== this.props.photos[0])
      this.setState({ photos: this.props.photos });
  }

  onClickPhoto = (i) => {
    this.setState({
      openModal: true,
      currentPhoto: i,
    });
  };

  onCloseMediaModal = () => {
    this.setState({
      openModal: false,
    });
  };

  goBack = () => {
    let c = this.state.currentPhoto;
    if (!c) {
      c = this.state.photos.length - 1;
    } else {
      c = c - 1;
    }

    this.setState({
      currentPhoto: c,
    });
  };

  goForward = () => {
    let c = this.state.currentPhoto + 1;
    if (c >= this.state.photos.length) {
      c = 0;
    }

    this.setState({
      currentPhoto: c,
    });
  };

  addDefaultSrc = (ev) => {
    // const cleanPhotos = this.state.photos.filter(img => img !== ev.target.src);
    // this.setState({ photos: cleanPhotos });

    ev.target.parentElement.remove();
  };

  addDefaultSingleSrc = (ev) => {
    if (
      this.props.websiteSection === "autos" ||
      this.props.websiteSection === "cars"
    ) {
      if (this.props.carPhotoUrl) {
        ev.target.src = toHttps(this.props.carPhotoUrl);
        this.props.photos[0] = toHttps(this.props.carPhotoUrl);
      } else {
        ev.target.src = DefaultAutoImage;
        this.props.photos[0] = DefaultAutoImage;
      }
    } else {
      ev.target.src = DefaultImage;
      this.props.photos[0] = DefaultImage;
    }
  };

  render() {
    const params = {
      slidesPerView: "auto",
      initialSlide: 0,
      keyboard: {
        enabled: !this.state.openModal,
        onlyInViewport: false,
      },
      loop: true,
      rebuildOnUpdate: true,
      freeMode: false,
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    if (!this.props.photos || !this.props.photos.length) {
      return null;
    }

    let timeOnMarket;

    if (this.props.listedDate) {
      timeOnMarket = moment().diff(moment(this.props.listedDate), "days");
    }

    if (this.props.websiteSection === "cars") {
      if (this.props.carPhotoUrl) {
        this.props.photos[0] = toHttps(this.props.carPhotoUrl);
      } else {
        this.props.photos[0] = DefaultAutoImage;
      }
    }

    console.log("props", this.props);

    return (
      <>
        <div style={{ display: "none" }}>
          <ReactTooltip
            place="bottom"
            type="info"
            effect="solid"
            backgroundColor="#555555"
          />
        </div>

        <div
          className={`ProductDetailHeader ${
            this.props.pickUp === "sell-online"
              ? "ProductDetailHeader-SellOnline"
              : "null"
          }`}
        >
          <div
            className={`ProductDetailHeaderBox ${
              this.props.pickUp === "sell-online"
                ? "ProductDetailHeaderBox-Photo"
                : "null"
            }`}
          >
            <div
              className={`product-listing-header-image ${
                this.props.websiteSection === "jobs"
                  ? "product-listing-header-image-jobs"
                  : ""
              }`}
            >
              {this.props.photos.length === 1 ? (
                <div key="first-photo" className="ProductDetailHeaderBoxinBox">
                  <img
                    onError={this.addDefaultSingleSrc}
                    src={toHttps(
                      this.props.photos[0].replace(
                        "https://followone.blob.core.windows.net",
                        "https://cdn-westus2.followone.com"
                      )
                    )}
                    alt="234093091"
                    onClick={() => this.onClickPhoto(0)}
                  />
                </div>
              ) : (
                <Swiper {...params}>
                  {this.state.photos.map((v, i) => (
                    <div key={i}>
                      <img
                        onError={this.addDefaultSrc}
                        src={toHttps(
                          v.replace(
                            "https://followone.blob.core.windows.net",
                            "https://cdn-westus2.followone.com"
                          )
                        )}
                        alt={"Photo " + i}
                        onClick={() => this.onClickPhoto(i)}
                      />
                    </div>
                  ))}
                </Swiper>
              )}

              {(this.props.isMlsBiz || this.props.isMlsCom) && (
                <img
                  src={CBALogo}
                  className="cba-logo"
                  alt="Listing provided by CBA"
                />
              )}
            </div>
            {this.props.websiteSection === "autos" ||
            this.props.websiteSection === "cars" ? (
              <>
                <div className="cost-map">
                  <div className="auto-details-cost">
                    <div className="dealer-cost">
                      {!this.props.carPrice ||
                      this.props.carPrice === "Call for Pricing" ? (
                        <span className="call-for-pricing">
                          Call for Pricing
                        </span>
                      ) : !this.props.afterDiscountPrice ? (
                        formatPriceAndCurrency(
                          this.props.carPrice,
                          this.props.carPrice !== "Call for Pricing"
                            ? this.props.carCurrency
                            : "",
                          ","
                        )
                      ) : (
                        formatPriceAndCurrency(
                          this.props.afterDiscountPrice,
                          this.props.afterDiscountPrice !== "Call for Pricing"
                            ? this.props.carCurrency
                            : "",
                          ","
                        )
                      )}

                      {/* show if deal or coupon exists */}
                      {/*<span>$28,500</span>*/}
                    </div>

                    {/* show only if seller type is dealer and there is no deal or coupon */}
                    {!!this.props.carPrice &&
                      this.props.carPrice !== "Call for Pricing" &&
                      !this.props.addDiscount &&
                      this.props.sellerType === "dealer" &&
                      !!this.props.author &&
                      this.props.author.fspShow && (
                        <div className="fsp-cost">
                          FSP:{" "}
                          <span>
                            {formatPriceAndCurrency(
                              this.props.fspPrice,
                              this.props.carCurrency,
                              ","
                            )}
                          </span>
                          <i
                            data-multiline="true"
                            data-tip="The Followone Suggested Price (FSP),<br> doesn't not reflect the value of the car,<br> or the deal offered by the dealer.<br> It is based on our assumption that this<br> is a good negotiation starting point."
                            className="fal fa-info-circle"
                          />
                        </div>
                      )}

                    <div className="original-price">
                      {!this.props.addDiscount ? null : (
                        <span className="product-cost-discount">
                          {formatPriceAndCurrency(
                            this.props.carPrice,
                            this.props.carCurrency,
                            ","
                          )}
                        </span>
                      )}
                    </div>
                  </div>

                  {this.props.carCondition && (
                    <div className="cost-map-icon">
                      <img src={AutoExteriorColor} alt="car condition" />
                      <p>
                        Condition <br />
                        <strong className="capitalize">
                          {this.props.carCondition}
                        </strong>
                      </p>
                    </div>
                  )}
                  {!!this.props.carDaysOnMarket && (
                    <div className="cost-map-icon">
                      {/*<img src={AutoDaysOnMarket} alt="days on market" />*/}
                      <i className="fal fa-calendar-alt" />
                      <p>
                        On Market
                        <br />
                        <strong>{this.props.carDaysOnMarket} Days</strong>
                      </p>
                    </div>
                  )}
                </div>
              </>
            ) : this.props.websiteSection !== "jobs" &&
              this.props.websiteSection !== "services" ? (
              <div className="cost-map">
                <div className="auto-details-cost">
                  {this.props.websiteSection === "realestate" && (
                    <p>
                      {(this.props.rentOrSell === "sell" ||
                        this.props.listingType === "For Sale") &&
                        "For Sale"}
                      {this.props.rentOrSell === "rent" &&
                        this.props.category !== "commercial" &&
                        "For Rent"}
                      {((this.props.rentOrSell === "rent" &&
                        this.props.category === "commercial") ||
                        this.props.listingType === "For Lease") &&
                        "For Lease"}
                      {this.props.isMlsBiz && (
                        <span>
                          Business
                          <br />
                          Opportunity
                        </span>
                      )}
                    </p>
                  )}
                  <div className="dealer-cost">
                    {!!this.props.callForPricing &&
                    this.props.callForPricing === "call-for-pricing" ? (
                      <span className="call-for-pricing">
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "Call For Pricing"
                        )}
                      </span>
                    ) : this.props.pickUpLocation === "free-entrance" ? (
                      <span className="call-for-pricing">
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "Free Entrance"
                        )}
                      </span>
                    ) : !this.props.afterDiscountPrice ? (
                      this.props.productCost === "0" ? (
                        getTranslatedPhrase(this.props.userLanguage, "FREE")
                      ) : (
                        <>
                          {formatPriceAndCurrency(
                            this.props.productCost,
                            this.props.productCurrency,
                            ","
                          )}
                          {this.props.isMlsCom &&
                            this.props.listingType === "For Lease" && (
                              <span className="listing-cost-type">
                                {" "}
                                /SF /YR
                              </span>
                            )}

                          {!this.props.productRepeat || this.props.isMlsCom ? (
                            ""
                          ) : (
                            <span className="listing-cost-type">
                              {"/ " +
                                getTranslatedPhrase(
                                  this.props.userLanguage,
                                  this.props.productRepeat
                                )}
                            </span>
                          )}
                        </>
                      )
                    ) : (
                      <>
                        {this.props.afterDiscountPrice !== "0" ? (
                          <>
                            {this.props.afterDiscountPrice}
                            {!this.props.productRepeat ? (
                              ""
                            ) : (
                              <span className="listing-cost-type">
                                {"/ " +
                                  getTranslatedPhrase(
                                    this.props.userLanguage,
                                    this.props.productRepeat
                                  )}
                              </span>
                            )}
                          </>
                        ) : (
                          getTranslatedPhrase(this.props.userLanguage, "FREE")
                        )}

                        {this.props.productCost === "0"
                          ? getTranslatedPhrase(this.props.userLanguage, "FREE")
                          : null}
                      </>
                    )}
                  </div>
                  <div className="original-price">
                    {!this.props.afterDiscountPrice ? null : (
                      <>
                        <span className="product-cost-discount">
                          {formatPriceAndCurrency(
                            this.props.productCost,
                            this.props.productCurrency,
                            ","
                          )}
                        </span>
                        {/*{(!this.props.productRepeat) ? ""*/}
                        {/*  : (<span className="listing-cost-type">{"/ " + getTranslatedPhrase(this.props.userLanguage, this.props.productRepeat)}</span>)}*/}
                      </>
                    )}
                  </div>
                </div>

                {this.props.websiteSection === "store" && (
                  <>
                    <div className="cost-map-icon">
                      {this.props.productCondition === "New" && (
                        <i className="fal fa-box-alt" />
                      )}
                      {this.props.productCondition === "Used" && (
                        <i className="fal fa-box-open" />
                      )}
                      <p>
                        Condition <br />
                        <strong className="capitalize">
                          {this.props.productCondition}
                        </strong>
                      </p>
                    </div>

                    <div className="cost-map-icon">
                      {this.props.pickUpLocation === "sell-online" && (
                        <i className="fal fa-truck" />
                      )}
                      {(this.props.pickUpLocation === "sell-local" ||
                        this.props.pickUpLocation === "buy-local" ||
                        this.props.pickUpLocation === "local-only") && (
                        <i className="fal fa-map-marker-alt" />
                      )}
                      <p>
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "Delivery"
                        )}
                        :<br />
                        {this.props.pickUpLocation === "sell-online" && (
                          <strong>
                            {getTranslatedPhrase(
                              this.props.userLanguage,
                              "Shipping"
                            )}
                          </strong>
                        )}
                        {(this.props.pickUpLocation === "sell-local" ||
                          this.props.pickUpLocation === "buy-local" ||
                          this.props.pickUpLocation === "local-only") && (
                          <strong>
                            {getTranslatedPhrase(
                              this.props.userLanguage,
                              "Pickup"
                            )}
                          </strong>
                        )}
                      </p>
                    </div>
                  </>
                )}

                {this.props.websiteSection === "realestate" &&
                  !this.props.isMlsCom &&
                  !this.props.isMlsBiz &&
                  !this.props.isMlsNwResi && (
                    <>
                      <div className="cost-map-icon">
                        <i className="fal fa-bed-alt" />
                        <p>
                          Bedrooms <br />
                          <strong className="capitalize">
                            {this.props.bedCount}
                          </strong>
                        </p>
                      </div>

                      <div className="cost-map-icon">
                        <i className="fal fa-calendar-alt"></i>
                        <p>
                          Time on Market <br />
                          <strong className="capitalize">Call Agent</strong>
                        </p>
                      </div>
                    </>
                  )}

                {(this.props.isMlsCom ||
                  this.props.isMlsBiz ||
                  this.props.isMlsNwResi) && (
                  <>
                    <div className="cost-map-icon">
                      <img src={RealEstateFloorSize} alt="floor size" />
                      {this.props.floorSize && (
                        <p>
                          Floor Size <br />
                          <strong className="capitalize">
                            {displayNumber(this.props.floorSize)} SF
                          </strong>
                        </p>
                      )}
                      {!this.props.floorSize && (
                        <p>
                          Floor Size <br />
                          <strong className="capitalize">Call Agent</strong>
                        </p>
                      )}
                    </div>

                    <div className="cost-map-icon">
                      <i className="fal fa-calendar-alt"></i>
                      <p>
                        Time on Market <br />
                        <strong className="capitalize">
                          {this.props.listedDate
                            ? timeOnMarket + " Days"
                            : "Call Agent"}
                        </strong>
                      </p>
                    </div>
                  </>
                )}
              </div>
            ) : null}

            {this.props.websiteSection === "services" && (
              <div className="cost-map">
                <div className="auto-details-cost">
                  <div className="dealer-cost">
                    {!!this.props.callForPricing
                      ? getTranslatedPhrase(
                          this.props.userLanguage,
                          "Call for Pricing"
                        )
                      : !!this.props.volunteer
                      ? getTranslatedPhrase(
                          this.props.userLanguage,
                          "Volunteer"
                        )
                      : this.props.price === "0"
                      ? getTranslatedPhrase(this.props.userLanguage, "FREE")
                      : !this.props.afterDiscountPrice
                      ? formatPriceAndCurrency(
                          this.props.price,
                          this.props.currency,
                          ","
                        )
                      : this.props.afterDiscountPrice}

                    {this.props.rateType &&
                      !this.props.callForPricing &&
                      !this.props.volunteer &&
                      this.props.price !== "0" && (
                        <span className="listing-cost-type">
                          {" "}
                          /&nbsp;
                          {getTranslatedPhrase(
                            this.props.userLanguage,
                            titleCase(this.props.rateType)
                          )}
                        </span>
                      )}
                  </div>

                  {this.props.addDiscount && (
                    <div className="original-price">
                      <span className="product-cost-discount">
                        {!this.props.afterDiscountPrice
                          ? formatPriceAndCurrency(
                              this.props.price,
                              this.props.currency,
                              ",",
                              getTranslatedPhrase(
                                this.props.userLanguage,
                                titleCase(this.props.rateType)
                              )
                            )
                          : formatPriceAndCurrency(
                              this.props.price,
                              this.props.currency,
                              ","
                            )}
                      </span>
                      {this.props.rateType &&
                        !this.props.callForPricing &&
                        !this.props.volunteer &&
                        this.props.price !== "0" && (
                          <span className="listing-cost-type small-font">
                            {" "}
                            /&nbsp;
                            {getTranslatedPhrase(
                              this.props.userLanguage,
                              titleCase(this.props.rateType)
                            )}
                          </span>
                        )}
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="listing-engage-box listing-header-engage-box">
              {this.props.websiteSection === "events" ? (
                <EngageBox
                  websiteSection={"events"}
                  itemId={this.props.itemId}
                  authorId={this.props.authorId}
                  withComments={true}
                  hideAllComments={true}
                  // getClickToCommentEvent={true}
                  // onLeaveComment={this.focusOnComments}
                />
              ) : (
                <EngageBox
                  websiteSection={this.props.websiteSection}
                  itemId={this.props.itemId}
                  favoriteItemId={this.props.favoriteItemId}
                  authorId={this.props.authorId}
                  withComments={false}
                >
                  {/*<Rate noStars={this.props.productStars} noReviews={this.props.reviewCount} />*/}
                  <button className="print-button" onClick={window.print}>
                    <i className="fal fa-print" />
                  </button>
                </EngageBox>
              )}
            </div>
          </div>

          {this.props.pickUp !== "sell-online" && this.state.a && (
            <div className="ProductDetailHeaderBox">
              <iframe
                frameBorder="0"
                style={{ border: "0" }}
                title="locationMap"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC3sPCT7W11tTP8LCANvOBoKe0dK08AYoI&q=${this.state.a}`}
                allowFullScreen
              />
            </div>
          )}

          {this.props.pickUp === "sell-online" && (
            <div className="ProductDetailHeaderBox ProductDetailHeaderBox-ListingMeta">
              <ListingMeta
                websiteSection={this.props.websiteSection}
                shareItemId={this.props.shareItemId}
                itemIsShared={this.props.itemIsShared}
                shareCount={this.props.shareCount}
                viewCount={this.props.viewCount}
                authorId={this.props.authorId}
                productDetailTitle={this.props.productDetailTitle}
                brandName={this.props.brandName}
                authorSlug={this.props.authorSlug}
                authorName={this.props.authorName}
                authorProfileImage={this.props.authorProfileImage}
                categoryName={this.props.category}
                subcategory={this.props.subcategory || "other"}
                productCost={this.props.price}
                productRepeat={this.props.subcategory}
                afterDiscountPrice={this.props.afterDiscountPrice}
                PropertyDetailAddress={this.props.PropertyDetailAddress}
                productCurrency={this.props.productCurrency}
                callForPricing={this.props.callForPricing}
                noLove={this.props.noLove}
                productIsLoved={this.props.productIsLoved}
                productStars={this.props.productStars}
                reviewCount={this.props.reviewCount}
                sellerPhone={this.props.sellerPhone}
                sellerEmail={this.props.sellerEmail}
                userIsAuthenticated={this.props.userIsAuthenticated}
                pickUpLocation={this.props.pickUpLocation}
                ticketSaleURL={this.props.ticketSaleURL}
                buttonLabel="Buy now"
                numberToCall={this.props.numberToCall}
                onChangeTab={this.props.onChangeTab}
                pickUp={this.props.pickUp}
              />
            </div>
          )}

          {this.state.currentPhoto === -1 ? null : (
            <ListingMediaModal
              active={this.state.openModal}
              onClickCloseHandler={this.onCloseMediaModal}
              photoElement={
                <img
                  src={this.state.photos[this.state.currentPhoto]}
                  alt={this.state.photos[this.state.currentPhoto]}
                />
              }
              showArrows={this.state.photos.length > 1}
              goBack={this.goBack}
              goForward={this.goForward}
              // goBackWithArrowLeft={this.goBackWithArrowLeft}
            />
          )}
        </div>
      </>
    );
  }
}

export default ListingHeader;
