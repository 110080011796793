import React, { useState } from 'react';
import JobsPreview from './JobsPreview';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import JobsSideBar from './JobsSideBar';
import JobsSideBarMobile from './JobsSideBarMobile';
import JobsFeatured from './JobsFeatured';
import JobsHeader from '../../Home/Components/Jobs';
import feaImage from '../../../assets/category/followone_cat_feature_jobs.jpg';

function JobsCategory() {
  const [categoryList, setCategoryList] = useState([]);

  function onUpdateCategories(c) {
    if (!categoryList.length)
      setCategoryList(c);
  }

  return (
    <>
      <div className="category-search-panel" style={{ backgroundImage: `url(${feaImage})` }}>
        <JobsHeader category section="jobs" categoryList={categoryList} />
      </div>
      <CategoryPage
        section="jobs"
        description="Advertise an Open Position or Apply for Jobs"
        ItemPreview={JobsPreview}
        SideBar={(props) => (<JobsSideBar onLoadCategories={onUpdateCategories} {...props} />)}
        SideBarMobile={(props) => (<JobsSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
        FeaturedItems={JobsFeatured}
      />
    </>
  );
}

export default JobsCategory;