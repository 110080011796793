import React from 'react';
import RealEstateForLease from "../Assets/realestate-forlease.svg";
import RealEstateForSale from "../Assets/realestate-forsale.svg";
import RealEstateRetail from "../Assets/realestate-retail.svg";
import RealEstateOffice from "../Assets/realestate-office.svg";
import RealEstateFloors from "../Assets/realestate-stairs.svg";
import RealEstateBizOpen from "../Assets/realestate-biz-open.svg";
// import RealEstateForRent from '../Assets/realestate-forrent.svg';
// import RealEstateParking from '../Assets/realestate-parking.svg';
import RealEstateAC from '../Assets/realestate-ac.svg';
// import RealEstateAccessibility from '../Assets/realestate-accessibility.svg';
// import RealEstateAppartment from '../Assets/realestate-appartment.svg';
import RealEstateBathroom from '../Assets/realestate-bathroom.svg';
import RealEstateBedroom from '../Assets/realestate-bedroom.svg';
// import RealEstateCityView from '../Assets/realestate-cityview.svg';
import RealEstateGarage from '../Assets/realestate-garage.svg';
// import RealEstateGym from '../Assets/realestate-gym.svg';
// import RealEstateHouse from '../Assets/realestate-house.svg';
import RealEstateNatureView from '../Assets/realestate-natureview.svg';
// import RealEstatePool from '../Assets/realestate-pool.svg';

const RealEstateListingIcons = (props) => {

    return (
        <div className="listing-icon-specs">
            {props.availableStatus &&
                <div className="listing-icon-spec">
                    {(props.availableStatus === 'Available' || props.availableStatus === 'Active')
                        ? <i className="fal fa-check-circle" style={{ color: '#60AE5B' }} />
                        : <i className="fal fa-times-circle" />
                    }
                    <p>Listing Status <br /> <strong>{props.availableStatus}</strong></p>
                </div>
            }

            {props.bedrooms &&
                <div className="listing-icon-spec">
                    <img src={RealEstateBedroom} alt="" />
                    <p>Bedrooms <br /> <strong>{props.bedrooms}</strong></p>
                </div>
            }

            {props.bathrooms &&
                <div className="listing-icon-spec">
                    <img src={RealEstateBathroom} alt="" />
                    <p>Bathrooms <br /> <strong>{props.bathrooms}</strong></p>
                </div>
            }

            {!!props.halfBathrooms &&
                <div className="listing-icon-spec">
                    <i className="fal fa-sink" />
                    <p>Half-Bathrooms <br /> <strong>{props.halfBathrooms}</strong></p>
                </div>
            }


            {props.beerWineLic && props.beerWineLic !== 'Unknown' &&
                <div className="listing-icon-spec">
                    <i className="fal fa-wine-glass-alt" />
                    <p>Beer/Wine Lic. <br /> <strong>{props.beerWineLic}</strong></p>
                </div>
            }

            {props.classHLic && props.classHLic !== 'Unknown' &&
                <div className="listing-icon-spec">
                    <i className="fal fa-cocktail" />
                    <p>Class H Lic. <br /> <strong>{props.classHLic}</strong></p>
                </div>
            }

            {props.proLic && props.proLic !== 'Unknown' &&
                <div className="listing-icon-spec">
                    <i className="fal fa-file-certificate" />
                    <p>Professional Lic. <br /> <strong>{props.proLic}</strong></p>
                </div>
            }

            {props.gamblingLic && props.gamblingLic !== 'Unknown' &&
                <div className="listing-icon-spec">
                    <i className="fal fa-dice" />
                    <p>Gambling Lic. <br /> <strong>{props.gamblingLic}</strong></p>
                </div>
            }

            {props.noSpecLic && props.noSpecLic !== 'Unknown' &&
                <div className="listing-icon-spec">
                    <i className="fal fa-certificate" />
                    <p>No Special Lic. <br /> <strong>{props.noSpecLic}</strong></p>
                </div>
            }

            {props.listingType &&
                <div className="listing-icon-spec">
                    {props.listingType === "For Lease" && <img src={RealEstateForLease} alt="" />}
                    {props.listingType === "For Sale" && <img src={RealEstateForSale} alt="" />}
                    <p>Listing Type <br /> <strong>{props.listingType}</strong></p>
                </div>
            }



            {props.propertyType &&
                <div className="listing-icon-spec">
                    {props.propertyType === "Retail" && <img src={RealEstateRetail} alt="" />}
                    {props.propertyType === "Office" && <img src={RealEstateOffice} alt="" />}
                    {props.propertyType === "Land" && <i className="fal fa-trees"></i>}
                    {props.propertyType === "Hotel/Motel" && <img src={RealEstateOffice} alt="" />}
                    {props.propertyType === "Industrial" && <i className="fal fa-warehouse"></i>}
                    {props.propertyType === "Multi-Family" && <i className="fal fa-building"></i>}
                    <p>Property Type <br /> <strong>{props.propertyType}</strong></p>
                </div>
            }

            {props.floors &&
                <div className="listing-icon-spec">
                    <img src={RealEstateFloors} alt="" />
                    <p>Floors <br /> <strong>{props.floors}</strong></p>
                </div>
            }

            {props.daysOpen &&
                <div className="listing-icon-spec">
                    <img src={RealEstateBizOpen} alt="Days Open" />
                    <p>Days Open <br /> <strong>{props.daysOpen}</strong></p>
                </div>
            }

            {props.phoneSystem &&
                <div className="listing-icon-spec">
                    <i className="fal fa-phone-office" />
                    <p>Phone System <br /> <strong>{props.phoneSystem}</strong></p>
                </div>
            }

            {props.seating &&
                <div className="listing-icon-spec">
                    <i className="fal fa-chair-office" />
                    <p>Seating <br /> <strong>{props.seating}</strong></p>
                </div>
            }

            {props.livingQuarters &&
                <div className="listing-icon-spec">
                    <i className="fal fa-bed-alt" />
                    <p>Living Quarters <br /> <strong>{props.livingQuarters}</strong></p>
                </div>
            }

            {props.hasHeating && props.hasHeating !== 'undefined' &&
                <div className="listing-icon-spec">
                    <img src={RealEstateAC} alt="Heating" />
                    <p>Heating <br /> <strong>Yes</strong></p>
                </div>
            }

            {props.hasGarage &&
                <div className="listing-icon-spec">
                    <img src={RealEstateGarage} alt="Garage" />
                    <p>Garage <br /> <strong>{props.hasGarage}</strong></p>
                </div>
            }

            {props.parking &&
                <div className="listing-icon-spec">
                    <i className="fal fa-parking-circle" />
                    <p>Parking <br /> <strong>{props.parking} Spaces</strong></p>
                </div>
            }

            {props.openParkingSpaces &&
                <div className="listing-icon-spec">
                    <i className="fal fa-parking-circle" />
                    <p>Open Parking <br /> <strong>{props.openParkingSpaces} Spaces</strong></p>
                </div>
            }

            {props.hasView &&
                <div className="listing-icon-spec">
                    <img src={RealEstateNatureView} alt="Property View" />
                    <p>Has View <br /> <strong>{props.hasView}</strong></p>
                </div>
            }

            {props.waterFront &&
                <div className="listing-icon-spec">
                    <i className="fal fa-water" />
                    <p>Water Front <br /> <strong>{props.waterFront}</strong></p>
                </div>
            }

            {props.petsAllowed &&
                <div className="listing-icon-spec">
                    <i className="fal fa-dog-leashed" />
                    <p>Pets Allowed <br /> <strong>{props.petsAllowed}</strong></p>
                </div>
            }
        </div>
    )
}

export default RealEstateListingIcons;