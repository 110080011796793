import React from "react";
import { Link } from "react-router-dom";
import { hostNameAndPort } from "../../../shared/util/vars";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { titleCase } from "../../../shared/util/string";
import MediaModal from "../../../shared/components/Modals/Media/MediaModal";
import Box from "../../../shared/util/box";
import ListingSidebar from "../../../shared/templates/ListingTemplate/listing-sidebar";
import EngageBox from "../../../shared/uielements/Engage/EngageBox";
import Axios from "axios";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import moment from "moment";
import actionType from "../../../redux-store/action-type";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import PhotoGalleryPreview from "./PhotoGalleryPreview";
// import PhotoPreviewArea from "../../../components/PhotoPreviewArea/PhotoPreviewArea";
import { maxUploadFileSize } from "../../../shared/util/file";
import ErrorMessages from "../../../shared/uielements/ErrorMessages/ErrorMessages";

class PhotoGallery extends React.Component {
  state = {
    category: "all",
    subcategory: "all",
    shares: [],
    likes: [],
    extraPhotos: [
      {
        photoName: "",
        photoId: "",
      },
    ],
    title: "Photo Gallery",
    entityContact: {},
    openModal: false,
    currentPhoto: -1,
    pageRefresh: "",
    newPhotosFiles: [],
    current_item_id: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let p = this.props.match.params || {};
    this.setState({
      pageRefresh: this.props.pageRefresh,
      current_item_id: p.storeItem,
      pageViewStart: moment(),
    });
    if (p && p.storeItem) {
      setTimeout(() => {
        this.getPhotoGallery(p.storeItem);
      }, 100);
    }
  }

  componentDidUpdate() {
    let k = this.props.pageRefresh;
    let p = this.props.match.params || {};
    if (
      this.state.pageRefresh !== k ||
      this.state.current_item_id !== p.storeItem
    ) {
      this.setState({ pageRefresh: k, current_item_id: p.storeItem });
      window.scrollTo(0, 0);
      if (p && p.storeItem) {
        this.getPhotoGallery(p.storeItem);
      }
    }
  }

  componentWillUnmount() {
    // console.log('VERIFICA', this.state.authorId === this.props.loggedInUser.userId)

    if (this.state.authorId === this.props.loggedInUser?.userId) {
      this.onPublishHandler();
    }
  }

  handlePPVCampaignViewer = async (isOrganic, itemId) => {
    try {
      let ppvData = new FormData();
      ppvData.append("campaignId", this.props.payperviewRedirect);
      ppvData.append("itemId", itemId);
      ppvData.append("itemType", "photos");
      let options = {
        url: `${hostNameAndPort}/api/ppv/add${isOrganic}view`,
        method: "post",
        data: ppvData,
      };
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        // Success! Nothing to do
      }
    } catch (error) {
      // Network error
    }
  };

  async getPhotoGallery(b) {
    let url = `${hostNameAndPort}/api/photos/${b}`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        if (!data.item.extraPhotos.length)
          data.item.extraPhotos.unshift({
            photoId: data.item.featuredImageId,
            photoName: data.item.featuredImage,
          });
        else if (
          data.item.featuredImageId &&
          data.item.extraPhotos[0].photoId !== data.item.featuredImageId
        ) {
          data.item.extraPhotos.unshift({
            photoId: data.item.featuredImageId,
            photoName: data.item.featuredImage,
          });
        }

        this.setState(data.item);

        setTimeout(() => {
          if (moment().diff(moment(this.state.pageViewStart), "seconds") >= 5) {
            if (!!this.props.payperviewRedirect) {
              this.handlePPVCampaignViewer("", data.item.itemId);
              this.props.resetPayperviewRedirect();
            } else {
              this.handlePPVCampaignViewer("organic", data.item.itemId);
            }
          }
        }, 5100);
      } else this.props.history.push("/404");
    } catch (err) {
      // console.log(err)
      this.props.history.push("/404");
    }
  }

  onClickPhoto = (i) => {
    if (i < 0) {
      return;
    }
    this.setState({
      openModal: true,
      currentPhoto: i,
    });
    this.props.history.replace(this.props.match.url + "?p=" + i);
  };

  onCloseMediaModal = () => {
    this.setState({
      openModal: false,
      currentPhoto: -1,
    });
  };

  goBack = () => {
    let c = this.state.currentPhoto;
    if (c <= 0) {
      c = this.state.extraPhotos.length - 1;
    } else {
      c = c - 1;
    }

    this.props.history.replace(this.props.match.url + "?p=" + c);
    this.setState({
      currentPhoto: c,
    });
  };

  goForward = () => {
    let c = this.state.currentPhoto + 1;
    if (c >= this.state.extraPhotos.length) {
      c = 0;
    }

    this.props.history.replace(this.props.match.url + "?p=" + c);
    this.setState({
      currentPhoto: c,
    });
  };

  onClickOnPhotoHandler = (pos, action) => {
    let a = this.state.newPhotosFiles;
    if (action === "select") {
      // // Choose main photo
      // for (let i in a) {
      //     if (a[i].className === 'selectedPhoto') {
      //         a[i].className = '';
      //     }
      // }
      // if (pos >= 0 && pos < a.length) {
      //     a[pos].className = 'selectedPhoto';
      // }
      // this.setState({
      //     newPhotosFiles: a,
      //     photoGallerySaved: false,
      //     errorMessage: ''
      // });
    } else {
      if (action === "remove") {
        // Remove photo
        a.splice(pos, 1);
        this.setState({
          newPhotosFiles: a,
          photoGallerySaved: false,
          errorMessage: "",
        });
      }
    }
  };

  itsTimeToSetNewPhotosFiles = (newPhotos) => {
    let a = this.state.newPhotosFiles.concat(newPhotos);
    // let notSelected = true;
    for (let i in a) {
      if (a[i].className === "selectedPhoto") {
        // notSelected = false;
        break;
      }
    }
    // if (notSelected) a[0].className = 'selectedPhoto';
    this.setState({
      newPhotosFiles: a,
      photoGallerySaved: false,
      errorMessage: "",
      successMessage: "New photos added succesfully.",
    });

    setTimeout(() => {
      this.setState({
        successMessage: "",
      });
    }, 3000);
  };

  onChangeUploadedImagesHandler = (e) => {
    function photoLoader(n) {
      let k = 0;
      let loadedPhotos = Array(n);
      return function (ctx, files, i, reader) {
        loadedPhotos[i] = {
          file: files[i],
          imagePreviewUrl: reader.result,
          imageName: files[i].name,
          className: "",
        };
        k++;
        if (k === n) {
          ctx.itsTimeToSetNewPhotosFiles(loadedPhotos);
        }
      };
    }

    let _error = false;
    let epf = e.target.files;
    let o = Object.keys(epf);
    if (o.length > 20) {
      this.setState({
        temp: {
          ...this.state.temp,
          errorMessage: getTranslatedPhrase(
            this.props.userLanguage,
            "You may select 20 photos maximum"
          ),
        },
      });
      return;
    }
    for (let i of o) {
      if (epf[i].size > maxUploadFileSize) {
        this.setState({
          temp: {
            ...this.state.temp,
            errorMessage: getTranslatedPhrase(
              this.props.userLanguage,
              "Each photo must be smaller than 5 MB"
            ),
          },
        });
        _error = true;
        break;
      }
    }
    if (!_error) {
      let n = o.length;
      let onRegisterPhoto = photoLoader(n);
      for (let i of o) {
        let reader = new FileReader();
        let ctx = this;
        reader.onloadend = function () {
          onRegisterPhoto(ctx, epf, i, this);
        };
        reader.readAsDataURL(epf[i]);
      }
    }
  };

  onPublishHandler = async () => {
    this.setState({ postListingPressed: true });

    let nbd = Object.assign({}, this.state);
    nbd.title = titleCase(nbd.title);
    delete nbd.errorMessage;
    delete nbd.photoGallerySaved;

    nbd.extraPhotosFiles = [];

    let featuredImageIncluded = false;
    if (nbd.extraPhotos.length > 1) {
      for (let i = 0; i < nbd.extraPhotos.length; i++)
        if (nbd.extraPhotos[i].photoName === nbd.featuredImage) {
          featuredImageIncluded = true;
          break;
        }
    }
    if (nbd.extraPhotos.length === 1 || featuredImageIncluded)
      nbd.extraPhotos.shift();
    for (let i in nbd.extraPhotos) {
      nbd.extraPhotos[i] = nbd.extraPhotos[i].photoName;
    }

    for (let i in nbd.newPhotosFiles) {
      nbd.extraPhotosFiles.push(nbd.newPhotosFiles[i].file);
    }

    delete nbd.newPhotosFiles;

    let newPhotoData = new FormData();
    for (let i in nbd) {
      newPhotoData.append(i, nbd[i]);
    }

    newPhotoData.delete("extraPhotosFiles");

    for (let i in nbd.extraPhotosFiles) {
      newPhotoData.append("extraPhotoFile" + i, nbd.extraPhotosFiles[i]);
    }

    let options = {
      url: `${hostNameAndPort}/api/photos/update`,
      method: "post",
      data: newPhotoData,
    };
    try {
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        this.setState({
          itemId: data.itemId,
          slug: data.slug,
          mainButtonLabel: "Update Gallery",
          photoGallerySaved: true,
          errorMessage: "",
          postListingPressed: false,
        });
      } else {
        this.setState({
          errorMessage: data.error,
          photoGallerySaved: false,
          postListingPressed: false,
        });
      }
    } catch (err) {
      //console.log(err)
      this.setState({
        errorMessage: getTranslatedPhrase(
          this.props.userLanguage,
          "Network error ERRFEPHOGED01"
        ),
        photoGallerySaved: false,
        postListingPressed: false,
      });
    }
  };

  onClickContact = (e) => {
    let u = {
      userId: this.state.authorId,
      userName: this.state.authorName,
      profileImage: this.state.authorProfileImage,
      slug: this.state.authorSlug,
    };
    let m = window.location.href;
    this.props.updateMessagesPageProps(
      u,
      getTranslatedPhrase(
        this.props.userLanguage,
        "I am writing in regards to this"
      ) +
        " " +
        getTranslatedPhrase(this.props.userLanguage, "photo gallery") +
        ": " +
        m
    );
    this.props.history.push("/chat");
  };

  render() {
    if (!this.state.title) return null;

    const existingPhotos = this.state.extraPhotos;
    const newPhotos = this.state.newPhotosFiles;

    const newPhotosArray = newPhotos.map((newPhoto) => {
      return {
        photoName: newPhoto.imagePreviewUrl,
      };
    });

    // console.log(this.state.currentPhoto)
    // console.log(this.state.extraPhotos)

    // let combinePhotoLists = existingPhotos.concat(newPhotosArray);

    return (
      <Box>
        <div className="listing-body">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{this.state.title} | Followone</title>
            <link
              rel="canonical"
              href={
                "https://followone.com/photos/" +
                this.state.category +
                "/" +
                this.state.subcategory +
                "/" +
                this.state.slug
              }
            />
          </Helmet>

          <div className="main-container MediaGalleryPhotos">
            <h1>{this.state.title}</h1>

            <div className="MediaGalleryMeta">
              <div className="media-gallery-meta">
                {getTranslatedPhrase(this.props.userLanguage, "created on") +
                  " " +
                  moment(this.state.creationDate).local().format("LL")}{" "}
                &nbsp; /&nbsp;{" "}
                {getTranslatedPhrase(this.props.userLanguage, "in")}{" "}
                {/* <Link to={`/photos/${this.state.category}`}> */}
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  titleCase(this.state.category.replace(/-/g, " "))
                )}
                {/* </Link> */}{" "}
                {this.state.subcategory &&
                  this.state.subcategory !== "null" &&
                  " /  " +
                    getTranslatedPhrase(
                      this.props.userLanguage,
                      titleCase(this.state.subcategory.replace(/-/g, " "))
                    ) +
                    " "}
                &nbsp; /&nbsp;{" "}
                {getTranslatedPhrase(this.props.userLanguage, "by")}{" "}
                <Link to={"/" + this.state.authorSlug}>
                  {this.state.authorName}
                </Link>
              </div>

              <div className="JobPostingContent__Engage">
                {!this.props.userIsAuthenticated ||
                !this.props.loggedInUser ||
                !this.state.authorId ||
                this.state.authorId === this.props.loggedInUser.userId ||
                this.state.authorAccountType === "personal" ? null : (
                  <button
                    className="button button-regular button-small"
                    onClick={this.onClickContact}
                  >
                    {getTranslatedPhrase(
                      this.props.userLanguage,
                      "Contact photographer"
                    )}
                  </button>
                )}

                <EngageBox
                  websiteSection={"photos"}
                  itemId={this.state.itemId}
                  authorId={this.state.authorId}
                  withComments={true}
                  hideAllComments={true}
                  getClickToCommentEvent={true}
                  onLeaveComment={this.onClickHandler}
                />
              </div>

              {this.state.userIsAuthorized && (
                <div className="row">
                  <div className="col span-2-of-2">
                    <label>
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Add Photos To Gallery"
                      )}{" "}
                      * (
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "20 max at a time"
                      )}
                      )
                    </label>
                    <input
                      type="file"
                      className="button button-regular setFeaturedImageBtn"
                      multiple="multiple"
                      accept="image/*"
                      onChange={this.onChangeUploadedImagesHandler}
                    />
                  </div>
                </div>
              )}
            </div>

            <ErrorMessages
              errorMessageClass={"error-message error-message-info"}
              errorMessageTitle={getTranslatedPhrase(
                this.props.userLanguage,
                "Success"
              )}
              errorMessageBody={getTranslatedPhrase(
                this.props.userLanguage,
                this.state.successMessage
              )}
              show={this.state.successMessage}
            />

            <div className="MediaGalleryContainer">
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                gutter="10px"
              >
                <Masonry>
                  {existingPhotos.map((v, i) => (
                    <button key={i} onClick={() => this.onClickPhoto(i)}>
                      <img
                        src={v.photoName.replace(
                          "https://followone.blob.core.windows.net",
                          "https://cdn-westus2.followone.com"
                        )}
                        alt={this.state.title}
                      />
                    </button>
                  ))}
                  {newPhotosArray.map((v, i) => (
                    <button key={i} onClick={() => this.onClickPhoto(i)}>
                      <img
                        src={`${v.photoName.replace(
                          "https://followone.blob.core.windows.net",
                          "https://cdn-westus2.followone.com"
                        )}`}
                        alt={this.state.title}
                      />
                    </button>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>

            <EngageBox
              withComments={true}
              websiteSection={"photos"}
              itemId={this.state.itemId}
              authorId={this.state.authorId}
              hideButtons={true}
              clickedToComment={true}
            />
          </div>

          <ListingSidebar
            section="photos"
            category={this.state.category}
            itemSlug={this.state.slug}
            itemId={this.state.itemId}
            userIsAuthorized={this.state.userIsAuthorized}
            authorId={this.state.authorId}
            authorName={this.state.authorName}
            authorSlug={this.state.authorSlug}
            authorProfileImage={this.state.authorProfileImage}
            description={this.state.description}
            likeList={this.state.likes}
            createNewTitle="New Gallery"
            ItemPreview={PhotoGalleryPreview}
          />

          {this.state.currentPhoto === -1 ? null : (
            <MediaModal
              active={this.state.openModal}
              onClickCloseHandler={this.onCloseMediaModal}
              photoElement={
                <img
                  src={
                    this.state.extraPhotos[this.state.currentPhoto].photoName
                  }
                  alt={
                    this.state.extraPhotos[this.state.currentPhoto].photoName
                  }
                />
              }
              showArrows={this.state.extraPhotos.length > 1}
              authorProfileImage={this.state.authorProfileImage}
              authorName={this.state.authorName}
              mediaTitle={this.state.title}
              mediaDescription={this.state.description}
              websiteSection="photos"
              goBack={this.goBack}
              goForward={this.goForward}
              itemId={this.state.extraPhotos[this.state.currentPhoto].photoId}
              authorId={this.state.authorId}
              clickedToComment={true}
            />
          )}
        </div>
      </Box>
    );
  }
}

const mapStateToPhotoGalleryProps = (state, props) => {
  return {
    userIsAuthenticated: state.userIsAuthenticated,
    loggedInUser: state.loggedInUser,
    pageRefresh: state.pageRefresh,
    userLanguage: state.userLanguage,
    payperviewRedirect: state.payperviewRedirect,
  };
};

const mapDispatchToPhotoGalleryProps = (dispatch) => {
  return {
    resetPayperviewRedirect: () => {
      dispatch({
        type: actionType.PAYPERVIEW_REDIRECT,
        payperviewRedirect: false,
      });
    },
    updateMessagesPageProps: (u, m) => {
      dispatch({
        type: actionType.MESSAGES_PAGE,
        messagesDefaultUser: u,
        messagesDefaultMessage: m,
      });
    },
  };
};

export default connect(
  mapStateToPhotoGalleryProps,
  mapDispatchToPhotoGalleryProps
)(withRouter(PhotoGallery));
