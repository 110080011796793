import React from 'react';
import FeaturedItems from '../../../shared/templates/CategoryTemplate/featured-items';
import GroupFeaturedPreview from './GroupFeaturedPreview';

export default function GroupFeatured(props) {
    return (
        <FeaturedItems 
            {...props}
            FeaturedItemPreview={GroupFeaturedPreview}
        />
    );
}