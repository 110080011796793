import React, {Component} from 'react';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';
import EngageBox from '../../../uielements/Engage/EngageBox';


class ListingComments extends Component {

    componentDidUpdate() {
        if (this.props.itsTimeToFocus === 'reviews') {
            let a = document.createElement("A");
            a.href = '#eventListingCommentBox';
            a.click();
        }
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="ProductDetailBox ProductDetailReviews" id="eventListingCommentBox">
                <h4>{getTranslatedPhrase(this.props.userLanguage,'Comments')}</h4>

                <EngageBox 
                    websiteSection={this.props.websiteSection}
                    itemId={this.props.itemId}
                    authorId={this.props.authorId}
                    showCommentsTitle={true}
                    withComments={true}
                    hideButtons={true}
                    clickedToComment={this.props.clickedToComment}
                    resetOutsideClickToComment={this.props.resetClickToComment}
                    // clickedToComment={true}
                    //resetOutsideClickToComment={false}
                />
            </div>
        );
    }
}

const mapStateToListingCommentsProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToListingCommentsProps, null)(ListingComments);
