import React, { Component } from 'react';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import Axios from 'axios';
import {connect} from 'react-redux';


class WallVideo extends Component {
    state = {
        videoNotFound: true,
        title: ''
    }

    componentDidMount() {
        let b = this.props.videoId;
        if (!b) {
            this.setState({ videoNotFound: true });
            return;
        }
        let url = `${hostNameAndPort}/api/videos/${b}`;
        Axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {                
                data.item.creationDate = (new Date(data.item.creationDate)).toDateString();
                data.item.videoNotFound = false;
                this.setState(data.item);
                let _wallAction;
                switch (this.props.activityType) {
                    case 'comment':
                    _wallAction = 'commented on a video';
                    break;
                    case 'like':
                    _wallAction = 'liked a video';
                    break;
                    case 'share':
                    _wallAction = 'shared a video';
                    break;
                    case 'post':
                    _wallAction = 'posted a video';
                    break;
                    default:
                    break;
                }
                this.props.onItemLoaded({
                    itemFailedLoading: false,
                    wallAction: _wallAction,
                });
            }
            else {
                this.props.onItemLoaded({ itemFailedLoading: true });
            }
        }) 
        .catch(err => {
            //console.log('Network error');
            this.props.onItemLoaded({ itemFailedLoading: true });
        });
    }

    render () {
        if (this.state.videoNotFound) {
            return '';
        }
        return (
            <div className="WallPhoto">
                <button className="WallVideo">
                    <iframe width="638" title={this.state.title} height="359" src={this.state.videoURL} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen />
                </button>
            </div>
        );
    }
}



const mapStateToWallVideoProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToWallVideoProps, null)(WallVideo);

