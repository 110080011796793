import React, { Component } from "react";
// import ProfileSettingsNav from "../ProfileNav/ProfileSettingsNav";
import ProfileSettingsBox from "../ProfileSettingsBox";
import { hostNameAndPort } from "../../../../shared/util/vars";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import Axios from "axios";
import SettingsMenu from "../SettingsMenu";

class PrivacySettings extends Component {
    state = {
        searchPrivacy: "public",
        profilePrivacy: "followers"
    };

    componentDidMount() {
        setTimeout(() => {
            this.getUserData();
        }, 500);
    }

    getUserData = async () => {
        let url = `${hostNameAndPort}/api/user/privacy-settings`;
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                this.setState({
                    searchPrivacy: data.user.searchPrivacy,
                    profilePrivacy: data.user.privacy
                });
            } else this.props.history.push("/");
        } catch (err) {
            this.props.history.push("/");
        }
    };

    updateSearchPrivacy = () => {
        let nsp = (this.state.searchPrivacy === "public") ? "followers" : "public";
        if (["public", "followers"].indexOf(nsp) < 0) {
            nsp = "public";
        }
        this.setState({ searchPrivacy: nsp });
        this.updateSearchPrivacyOnServer(nsp);
    };

    updateSearchPrivacyOnServer = async (nsp) => {
        let _data = new FormData();
        _data.append("newSearchPrivacy", nsp);
        let options = {
            url: `${hostNameAndPort}/api/user/update-privacy`,
            method: "post",
            data: _data
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                // No error
            }
        } catch (err) {
            // Network error
        }
    };

    updateProfilePrivacy = (p) => {
        if (["public", "followers"].indexOf(p) < 0) {
            p = "public";
        }
        this.setState({ profilePrivacy: p });
        this.updateProfilePrivacyOnServer(p);
    };

    updateProfilePrivacyOnServer = async (p) => {
        let _data = new FormData();
        _data.append("newPrivacy", p);
        let options = {
            url: `${hostNameAndPort}/api/user/update-privacy`,
            method: "post",
            data: _data
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                // No error
            }
        } catch (err) {
            // Network error
        }
    };

    render() {
        return (
          <SettingsMenu PrivacyLiActive="active">
              <div className="privacy-settings-options">
                  <ProfileSettingsBox>
                      <div className="privacy-settings-options_box">
                          <h4>{getTranslatedPhrase(this.props.userLanguage, "Content Privacy")}</h4>
                          <p>{getTranslatedPhrase(this.props.userLanguage, "Only you and your followers may see your content, unless it is public. For public content, all registered members may give feedback. Your followers may give feedback on your content with privacy set on 'Followers' and 'Public'.")}</p>
                          <p>{getTranslatedPhrase(this.props.userLanguage, "Your personal information may only be viewed by your followers, unless you desire to make your profile public (useful for organizations, institutions and businesses). Even if your profile page is not public, you may choose to appear in searches. Users will only see your display name, profile image,  and cover image.")}</p>
                          {/*<p>Adding a person to your block list makes your profile (and all of your other content) unviewable to them and vice-versa. Blocked users will not be able to message you or view things you post. Any connections you have to the blocked person will be canceled. To add someone to your block list, visit that person's profile page.</p>*/}

                          <div className="form-input-lists" onClick={this.updateSearchPrivacy}>
                              <input type="checkbox" name="contentPrivacySelection" onChange={() => null} checked={(this.state.searchPrivacy === "followers") ? true : false} />
                              <label htmlFor="contentPrivacySelection"><span><i className="fal fa-check" /></span> {getTranslatedPhrase(this.props.userLanguage, "Do not display me in searches, or for browsing members.")}</label>
                          </div>
                      </div>
                  </ProfileSettingsBox>
              </div>
          </SettingsMenu>
        );
    }
}


const mapStateToPrivacySettingsProps = (
  state,
  props
) => {
    return {
        userLanguage: state.userLanguage,
        userIsAuthenticated: state.userIsAuthenticated
    };
};

export default connect(mapStateToPrivacySettingsProps, null)(PrivacySettings);
/* <ProfileSettingsBox>

<div className="privacy-settings-options_box">
    <h4>{getTranslatedPhrase(this.props.userLanguage,'Profile Privacy')}</h4>   
    <p>{getTranslatedPhrase(this.props.userLanguage,'Who can view your profile?')}</p>

    <div className="form-input-lists" onClick={(e) => this.updateProfilePrivacy('followers')}>
        <input type="radio" name="profilePrivacySelection" onChange={()=>null} checked={(this.state.profilePrivacy==='followers')?true:false}/> 
        <label htmlFor="profilePrivacySelection"><span><i className="fal fa-check"></i></span> {getTranslatedPhrase(this.props.userLanguage,'Only My Followers')}</label>
    </div>
    <div className="form-input-lists" onClick={(e) => this.updateProfilePrivacy('public')}>
        <input type="radio" name="profilePrivacySelection" onChange={()=>null} checked={(this.state.profilePrivacy==='followers')?false:true}/> 
        <label htmlFor="profilePrivacySelection"><span><i className="fal fa-check"></i></span> {getTranslatedPhrase(this.props.userLanguage,'Everyone')}</label>
    </div>
</div>

</ProfileSettingsBox> */
