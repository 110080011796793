import React, { Component } from 'react';
import {titleCase} from '../../../shared/util/string';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

class EventsFeaturedPreview extends Component {
    formatDateForEvent(sd,ed) {
        let s = moment(sd);
        let e = moment(ed);
        if ( s.isSame(e, 'day') ) return s.format('LL');
        return s.format('L') + ' - ' + e.format('L');
    }

    render() {
        let v = this.props;
        return (
        <div className="featured-item featured-event">
            <Link className="featured-event_image" to={'/events/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}>
                <img src={v.featuredImage} alt={v.title} />
         

                <div className="featured-event_details">
                    <Link to={'/events/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug} className="featured-event_details_title">
                        <h3>{v.title}</h3>
                    </Link>

                    
                    <div className="featured-event_details_box">
                        <Link to={'/events/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug} className="featured-event_details_info">
                            <i className="fal fa-map-marker-alt" />
                            <span>
                                {v.buildingName}<br />
                                {v.address}<br />
                                {titleCase(v.city)} {(v.state !== 'n/a')?v.state+', ':''} {v.zipCode} <br />
                                {v.country}
                            </span>
                        </Link>

                        <Link to={'/events/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug} className="featured-event_details_info">
                            <i className="fal fa-calendar-alt" />
                            <span> 
                                {this.formatDateForEvent(v.startDate, v.endDate)}<br />
                                {v.startTime} - {v.endTime}
                            </span>
                        </Link> 
                    </div>
                </div>
            </Link>
        </div>
        );
    }
}


const mapStateToEventsFeaturedPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToEventsFeaturedPreviewProps, null)(EventsFeaturedPreview);