import React, { useRef } from 'react';
import JoditEditor from "jodit-react";

const Editor = (props) => {
    const editor = useRef(null)

    const config = {
        placeholder: 'Write something awesome. (No photos please!)',
        buttons: "bold,strikethrough,underline,italic,|,ul,outdent,indent,|,font,fontsize,brush,paragraph,,|,align,undo,redo,|,print,find",
        buttonsMD: "bold,strikethrough,underline,italic,|,ul,outdent,indent,|,font,fontsize,brush,paragraph,,|,align,undo,redo,|,print,find",
        buttonsSM: "bold,strikethrough,underline,italic,|,ul,outdent,indent,|,font,fontsize,brush,paragraph,,|,align,undo,redo,|,print,find",
        buttonsXS: "bold,strikethrough,underline,italic,|,ul,outdent,indent,|,font,fontsize,brush,paragraph,,|,align,undo,redo,|,print,find",
        minHeight: 350,
        askBeforePasteHTML: false,
        showXPathInStatusbar: false,
        defaultActionOnPaste: "insert_only_text",
        disablePlugins: "pastestorage,media,imageprocessor,imageproperties,draganddropelement,draganddrop",
    };

    return (
        <JoditEditor
            ref={editor}
            value={props.value}
            config={config}
            tabIndex={1}
            onChange={props.onChange}
            onBlur={props.onBlur}
        />
    );
}

export default Editor;