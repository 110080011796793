import React, { useEffect, useState } from 'react';
import { hostNameAndPort } from '../../../../shared/util/vars';
import Axios from 'axios';
import ProfileInfoBox from '../../../../shared/components/SideBar/ProfileInfoBox/ProfileInfoBox';
import ListingPolicies from '../../../../shared/templates/ListingTemplate/components/ListingPolicies';

const ProfileAbout = (props) => {

    const [contact, setContact] = useState({});
    const [userId, setUserId] = useState(null);

    let u = props.userId;

    useEffect(() => {
        setUserId(u);
        getContactDataFromServer(u);
    }, [u])

    useEffect(() => {
        if (userId !== u) {
            getContactDataFromServer(u);
            setUserId(u);
        }
    }, [userId])


    const getContactDataFromServer = async (u) => {
        if (!u) {
            return;
        }
        let url = `${hostNameAndPort}/api/user/contact/${u}/user`;
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                if (data.contact.Category) data.contact.Category = data.contact['Category'].toUpperCase().replace(/-/g, ' ');
                let c = {};
                for (let i in data.contact) {
                    if (data.contact.hasOwnProperty(i)) {
                        c[i] = data.contact[i];
                    }
                }
                setContact(c);
            }
        } catch (err) {
            // Network error
        }
    }

    return (
        <div className="profile-tab about-profile-tab profile-tab-limited">
            <div className="profile-tab_content">
                <div className="new-profile">
                    <div id="profileAbout" />
                    <div className="new-profile-description">
                        <ProfileInfoBox profileDetails={contact} isAccredited={props.isAccredited} userName={props.userName} />

                        <div className="BusinessAboutTab">
                            <h4>{props.descriptionLabel}</h4>

                            {props.businessAboutContent.split('\n').map((v, i) => ('' === v || v === '\r') ? (<br key={i} />) : (<p key={i}>{v}</p>))}
                            <br />

                            <ListingPolicies policyUserId={props.userId} show={true} />
                        </div>
                    </div>


                    <div id="profileReview" />
                </div>
            </div>
        </div>
    )
}

export default ProfileAbout;