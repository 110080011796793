import React, { Component } from 'react';
import ListLoader from '../../../../shared/util/list-loader';
import { titleCase } from '../../../../shared/util/string';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import CreateCouponModal from '../../../../shared/components/Modals/Account/CreateCouponModal';
import moment from 'moment';
import { currencyList } from '../../../../shared/util/string';
const MORE_SPECS = ['Rating'];




export default class EventEditFields extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state = {
            title: '',
            brandName: '',
            ticketSaleURL: '',
            buildingName: '',
            startDate: moment().format('YYYY-MM-DD'),
            startTime: `${new Date().getHours()}:${new Date().getMinutes()}`,
            endDate: moment().format('YYYY-MM-DD'),
            endTime: `${new Date().getHours() + 1}:${new Date().getMinutes()}`,
            category: 'select-category',
            subcategory: '',
            subcategoriesSelect: [],
            pickUp: 'pick-up-tickets',
            description: '',
            price: '',
            currency: 'USD',
            callForPricing: '',
            specList: [],
            couponList: [],
            addDiscount: 'how-about-discount',
            discountId: '',
            alreadyLoadedItem: false,
            moreSpecs: [...MORE_SPECS],
            temp: {
                specName: '',
                specValue: '',
                selectWhichCoupon: '',
                discountType: '',
                discountValue: '',
                discountCurrency: '%',
                availability: '',
                endDate: moment().format('YYYY-MM-DD'),
                startDate: moment().format('YYYY-MM-DD'),
                showCouponModal: false
            }
        }
    }

    componentDidMount() {
        let Loader = new ListLoader({
            cb: this.onLoad,
            options: {
                delay: 3000,
                count: 20,
                request: {
                    url: '/api/coupons/items',
                    method: 'post',
                    formParams: { filter: JSON.stringify({ author: '', isDeal: false }) }
                }
            }
        });
        Loader.init();
        this.setState({ Loader });
        this.onChangeItem('new-address', 'newAddress');
        setTimeout(() => {
            this.forceUpdate();
        }, 2000);
    }

    componentDidUpdate() {
        if (!this.state.alreadyLoadedItem && this.props.loadedItem.title && this.props.categoriesSelect.length) {
            let s = { ...this.state };
            let { Loader, couponList } = this.state;
            for (let i in s) {
                if (s.hasOwnProperty(i))
                    s[i] = this.props.loadedItem[i];
            }
            s.Loader = Loader;
            s.couponList = couponList;
            s.alreadyLoadedItem = true;
            s.startDate = moment.utc(s.startDate).format('YYYY-MM-DD');
            s.endDate = moment.utc(s.endDate).format('YYYY-MM-DD');

            // Subcategories
            let a = this.props.categoriesSelect;
            let b = [];
            let i = 0;
            for (; i < a.length; i++) {
                if (a[i] === s.category) break;
            }
            i++;
            while (/^\s*-+/.test(a[i]) && i < a.length) {
                b.push(a[i].replace(/^\s*-+\s*/, ''));
                i++;
            }
            s.subcategoriesSelect = [...b];
            i = 0;
            while (i < s.moreSpecs.length)
                if (s.specList.map(v => v.specName).indexOf(s.moreSpecs[i]) >= 0) s.moreSpecs.shift();
                else i++;

            this.setState(s);
            if (s.pickUp === 'at-the-venue') this.props.onUpdateItem(true, 'addressIsNeeded');
            else this.props.onUpdateItem(false, 'addressIsNeeded');
        }
        if (this.props.loadedItem.clearAllFields !== this.state.clearAllFields) {
            this.setState({ clearAllFields: this.props.loadedItem.clearAllFields });
            let a = this.initialState;
            a.moreSpecs = [...MORE_SPECS];
            a.specList = [];
            this.setState(a);

            for (let i in a.temp) {
                this.props.onUpdateTempItem(a.temp[i], i);
            }
            for (let i in a) {
                this.props.onUpdateItem(a[i], i);
            }
        }
    }

    onLoad = () => {
        if (!this.state.Loader) return;
        let { items } = this.state.Loader;
        if (!items) items = [];
        this.setState({ couponList: items });
    }

    onChangeDiscountProperty = (e, _propName, _sizeLimit, _propMsg) => {
        let newValue = e.target.value;
        if (newValue.length > _sizeLimit) return;
        if (_propName === 'discountValue') {
            newValue = newValue.replace(/,/g, '').replace('.', '');
            newValue = Number(newValue);
            if (Number.isNaN(newValue)) newValue = 0;
            if (this.state.temp.discountCurrency === '%' && (newValue > 100 || newValue < 0)) return;
            if (this.state.temp.discountCurrency === this.state.currency && newValue > Number(this.state.price)) return;
        }
        if (_propName === 'selectWhichCoupon' && newValue === 'add-new-coupon') {
            this.setState({ temp: { ...this.state.temp, showCouponModal: true } });
        } else {
            if (!this.props.onUpdateTempState) return;
            let nextTempState = Object.assign({}, this.state.temp);
            nextTempState[_propName] = newValue;

            // Copy to all listings
            if (_propName === 'selectWhichCoupon') {
                const foundCoupon = this.state.couponList.filter(v => v.itemId === newValue)

                if (foundCoupon.length) {
                    nextTempState.discountValue = foundCoupon[0].couponValue;
                    nextTempState.discountCurrency = foundCoupon[0].couponCurrency;
                }
            }


            if (_propName === 'discountType') {
                nextTempState.discountValue = '';
                nextTempState.discountCurrency = '%';
                nextTempState.availability = '';
            }
            if (_propName === 'discountCurrency') nextTempState.discountValue = '';
            this.props.onUpdateTempState(nextTempState);
            this.setState({ temp: nextTempState });
        }
    }


    onCloseCouponModal = _newCoupon => {
        let a = this.state.couponList;
        if (!!_newCoupon) {
            a.unshift(_newCoupon);
            if (!this.props.onUpdateTempState) return;


            // Copy to all listings
            let result =
                this.props.onUpdateTempState({
                    ...this.state.temp,
                    discountValue: _newCoupon.couponValue,
                    discountCurrency: _newCoupon.couponCurrency,
                    selectWhichCoupon: _newCoupon.itemId,
                })

            if (result) {

                this.setState({
                    couponList: a,
                    temp: {
                        ...this.state.temp,
                        discountValue: _newCoupon.couponValue,
                        discountCurrency: _newCoupon.couponCurrency,
                        selectWhichCoupon: _newCoupon.itemId,
                        showCouponModal: false
                    }
                });
            }
        } else {
            this.setState({ temp: { ...this.state.temp, showCouponModal: false } });
        }
    };

    onAddSpecSubmit = (e) => {
        e.preventDefault();
        this.onAddSpec({ newSpecName: this.state.temp.specName, newSpecValue: this.state.temp.specValue });
        this.state.temp.specFocusedInput.focus();
    }

    onAddSpec = ({ newSpecName, newSpecValue }) => {
        if (!newSpecValue) return;
        let moreSpecs = [];
        for (let i in this.state.moreSpecs) {
            if (this.state.moreSpecs[i] === this.state.temp.specName) continue;
            else moreSpecs.push(this.state.moreSpecs[i]);
        }
        if (this.state.moreSpecs.length === moreSpecs.length) {
            newSpecName = moreSpecs.shift();
        }
        if (!newSpecName) return;
        this.setState({ moreSpecs });

        let _specList = this.state.specList;
        _specList.push({ specName: newSpecName, specValue: newSpecValue });
        if (!this.props.onUpdateItem) return;
        let result = this.props.onUpdateItem(_specList, 'specList');
        if (result) this.setState({ specList: _specList, temp: { ...this.state.temp, specName: '', specValue: '' } });
    }

    onRemoveSpec = (k) => {
        let moreSpecs = this.state.moreSpecs;
        let _specList = this.state.specList.filter((v, i) => { if (i === k) { moreSpecs.unshift(v.specName); return false; } else return true; });
        if (!this.props.onUpdateItem) return;
        let result = this.props.onUpdateItem(_specList, 'specList');
        if (result) this.setState({ specList: _specList, moreSpecs });
    }

    onChangeSpecNameHandler = (e) => {
        if (e.target.value.length > 100) return;
        this.setState({ temp: { ...this.state.temp, specName: e.target.value } });
    }

    onChangeSpecValueHandler = (e) => {
        if (e.target.value.length > 100) return;
        this.setState({ temp: { ...this.state.temp, specValue: e.target.value } });
    }

    onChangeItem = (value, propName, limit, message) => {
        if (!this.props.onUpdateItem) return;
        if (propName === 'pickUp') {
            if (value === 'at-the-venue')
                this.props.onUpdateItem(true, 'addressIsNeeded');
            else {
                this.props.onUpdateItem(false, 'addressIsNeeded');
                if (value === 'call-for-pricing' || value === 'free-entrance') {
                    this.onChangeItem('no-discount', 'addDiscount', 50, 'Add Discount');
                }
            }
        }
        if (propName === 'addDiscount' && value === 'no-discount') {
            this.onChangeDiscountProperty({ target: { value: '' } }, 'discountType', 50, 'Discount Type');
        }
        if (propName === 'category' && value !== 'select-category') {
            let a = this.props.categoriesSelect;
            let b = [];
            let i = 0;
            for (; i < a.length; i++) {
                if (a[i] === value) break;
            }
            i++;
            while (/^\s*-+/.test(a[i]) && i < a.length) {
                b.push(a[i].replace(/^\s*-+\s*/, ''));
                i++;
            }
            this.setState({ subcategoriesSelect: [...b] })
            this.props.onUpdateItem('', 'subcategory', 50, 'Subcategory');
        }
        if (propName === 'pickUp') {
            if (value === 'call-for-pricing') {
                let result = this.props.onUpdateItem('call-for-pricing', 'callForPricing', limit, message);
                if (result) this.setState({ callForPricing: 'call-for-pricing' });
            } else {
                let result = this.props.onUpdateItem('', 'callForPricing', limit, message);
                if (result) this.setState({ callForPricing: '' });
            }
        }
        let result = this.props.onUpdateItem(value, propName, limit, message);
        if (result) this.setState({ [propName]: value });
    }

    onChangePriceItem = (value) => {
        value = value.replace(/,/g, '').replace('.', '');
        if (String(value).length > 12) return;
        if (!this.props.onUpdateItem) return;
        let result = this.props.onUpdateItem(value, 'price', 20, 'Price');
        if (result) this.setState({ price: value });
    }

    displayPriceValue = (value, propName) => {
        if (isNaN(value) || value === '') return '';
        if (value === '0' || value === 0) return '0';
        if (this.state.temp.discountCurrency === '%' && propName === 'discountValue') return value;
        value = String(value).split('').reverse();
        let result = [];
        for (let i = 0; i < value.length; i++) {
            if (i === 2) result.push('.');
            else if ((i > 2) && (i % 3 === 2)) result.push(',');
            result.push(value[i]);
        }
        if (result.length > 1)
            while (result[result.length - 1] === ',' || ((result[result.length - 1] === '0') && (result[result.length - 2] !== '.')))
                result.pop();
        if (value.length && value.length <= 2) result.push('.', '0');
        result = result.reverse().join('');
        return result;
    }


    render() {
        let categoriesSelect = this.props.categoriesSelect.filter(v => (!/^\s*-+/.test(v))).map(v => ({ value: v, text: getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/g, ' '))) })).sort((a, b) => ((a.text > b.text) ? 1 : -1));
        return (<>

            {/* Main Info */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Main Info</p>
                </div>

                <div className="new-edit-page_section-content">
                    <div className="edit-page-field edit-page-field-full">
                        <input className="form-input-text form-input-full" type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Event Name') + ' *'}
                            value={this.state.title}
                            onChange={(e) => this.onChangeItem(e.target.value, 'title', 50, 'The title')} />
                    </div>

                    <div className="edit-page-field">
                        <input className="form-input-text form-input-full" type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Brand Name') + ' *'}
                            value={this.state.brandName}
                            onChange={(e) => this.onChangeItem(e.target.value, 'brandName', 30, 'The brand name')} />
                    </div>

                    <div className="edit-page-field">
                        <div className="form-input-select">
                            <select className="form-input-full" value={this.state.category} onChange={(e) => this.onChangeItem(e.target.value, 'category', 50, 'Category')}>
                                <option value="select-category">{getTranslatedPhrase(this.props.userLanguage, 'Select Category')} *</option>
                                {categoriesSelect.map((v, i) => (<option value={v.value} key={i}>{v.text}</option>))}
                            </select>
                        </div>
                    </div>

                    {!!this.state.subcategoriesSelect && !!this.state.subcategoriesSelect.length && (
                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select className="form-input-full" value={this.state.subcategory} onChange={(e) => this.onChangeItem(e.target.value, 'subcategory', 50, 'Subcategory')}>
                                    <option value="select-subcategory">{getTranslatedPhrase(this.props.userLanguage, 'Select Subcategory')} *</option>
                                    {this.state.subcategoriesSelect.map((v, i) => (<option value={v} key={'sub' + i}>{getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/, ' ')))}</option>))}
                                </select>
                            </div>
                        </div>
                    )}

                    <div className="edit-page-field">
                        <div className="form-input-select">
                            <select className="form-input-full" value={this.state.pickUp} onChange={(e) => this.onChangeItem(e.target.value, 'pickUp', 50, 'Pick Up Tickets')}>
                                <option value="pick-up-tickets">{getTranslatedPhrase(this.props.userLanguage, 'Pick Up Tickets')} *</option>
                                <option value="at-the-venue">{getTranslatedPhrase(this.props.userLanguage, 'At The Venue')}</option>
                                <option value="see-website">{getTranslatedPhrase(this.props.userLanguage, 'See Website')}</option>
                                {/* <option value="call-for-pricing">{getTranslatedPhrase(this.props.userLanguage, 'Call For Pricing')}</option> */}
                                <option value="free-entrance">{getTranslatedPhrase(this.props.userLanguage, 'Free Entrance')}</option>
                            </select>
                        </div>
                    </div>

                    {!(this.state.pickUp === 'see-website') ? null : (
                        <div className="edit-page-field">
                            <input type="text" className="form-input-text form-input-full" placeholder={getTranslatedPhrase(this.props.userLanguage, 'URL for buying tickets, e.g. http://example.com') + ' *'} value={this.state.ticketSaleURL} onChange={e => this.onChangeItem(e.target.value, 'ticketSaleURL', 2000, 'The URL')} />
                        </div>
                    )}

                    <div className="edit-page-field">
                        <input className="form-input-text form-input-full" type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Venue Name')} maxLength="30"
                            value={this.state.buildingName} onChange={(e) => this.onChangeItem(e.target.value, 'buildingName', 200, 'Building Name')} />
                    </div>
                </div>
            </div>




            {/* Date & Time */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Date & Time</p>
                </div>

                <div className="new-edit-page_section-content new-edit-page_section-content-x4">
                    <div className="edit-page-field">
                        <label>{getTranslatedPhrase(this.props.userLanguage, 'Start Date')} *</label>
                        <input className="form-input-text form-input-full" type="date" placeholder="YYYY-MM-DD"
                            value={this.state.startDate} onChange={(e) => this.onChangeItem(e.target.value, 'startDate', 50, 'Start Date')} />
                    </div>

                    <div className="edit-page-field">
                        <label>{getTranslatedPhrase(this.props.userLanguage, 'Start Time')} *</label>
                        <input className="form-input-text form-input-full" type="time" placeholder="HH:MM AM/PM"
                            value={this.state.startTime} onChange={(e) => this.onChangeItem(e.target.value, 'startTime', 50, 'Start Time')} />
                    </div>

                    <div className="edit-page-field">
                        <label>{getTranslatedPhrase(this.props.userLanguage, 'End Date')} *</label>
                        <input className="form-input-text form-input-full" type="date" placeholder="YYYY-MM-DD"
                            value={this.state.endDate} onChange={(e) => this.onChangeItem(e.target.value, 'endDate', 50, 'End Date')} />
                    </div>

                    <div className="edit-page-field">
                        <label>{getTranslatedPhrase(this.props.userLanguage, 'End Time')} *</label>
                        <input className="form-input-text form-input-full" type="time" placeholder="HH:MM AM/PM"
                            value={this.state.endTime} onChange={(e) => this.onChangeItem(e.target.value, 'endTime', 50, 'End Time')} />
                    </div>
                </div>
            </div>




            {/* Pricing & Discount */}
            {(this.state.pickUp !== 'call-for-pricing' && this.state.pickUp !== 'free-entrance') && (
                <div className="new-edit-page_section">
                    <div className="new-edit-page_section-title">
                        <p>Pricing & Discount</p>
                    </div>


                    <div className="new-edit-page_section-content">
                        {!this.state.callForPricing && (<>
                            <div className="edit-page-field">
                                <input className="form-input-text form-input-full" type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Price') + ' *'}
                                    value={this.displayPriceValue(this.state.price)}
                                    onChange={(e) => this.onChangePriceItem(e.target.value)} />
                            </div>

                            <div className="edit-page-field">
                                <div className="form-input-select">
                                    <select className="form-input-full" value={this.state.currency} onChange={(e) => this.onChangeItem(e.target.value, 'currency', 10, 'The currency')} >
                                        {currencyList.map(v => (
                                            <option key={v} value={v}>{v}</option>)
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="edit-page-field">
                                <div className="form-input-select">
                                    <select className="form-input-full" value={this.state.addDiscount} onChange={(e) => this.onChangeItem(e.target.value, 'addDiscount', 50, 'Add Discount')}>
                                        <option value="how-about-discount">{getTranslatedPhrase(this.props.userLanguage, 'Add Discount?')} *</option>
                                        <option value="no-discount">{getTranslatedPhrase(this.props.userLanguage, 'No Discount')}</option>
                                        <option value="add-discount">{getTranslatedPhrase(this.props.userLanguage, 'Add New Discount')}</option>
                                    </select>
                                </div>
                            </div>

                            {this.state.addDiscount === 'add-discount' && (
                                <div className="edit-page-field">
                                    <div className="form-input-select">
                                        <select className="form-input-full" value={this.state.temp.discountType} onChange={(e) => this.onChangeDiscountProperty(e, 'discountType', 50, 'Discount Type')}>
                                            <option value="discount-type">{getTranslatedPhrase(this.props.userLanguage, 'Discount Type *')}</option>
                                            <option value="discount-coupon">{getTranslatedPhrase(this.props.userLanguage, 'Coupon')}</option>
                                            <option value="discount-deal">{getTranslatedPhrase(this.props.userLanguage, 'Deal')}</option>
                                        </select>
                                    </div>
                                </div>
                            )}

                            {this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-coupon' && (
                                <div className="edit-page-field">
                                    <div className="form-input-select">
                                        <select className="form-input-full" value={this.state.temp.selectWhichCoupon} onChange={(e) => this.onChangeDiscountProperty(e, 'selectWhichCoupon', 50, 'Select Coupon')}>
                                            <option value="select-coupon">{getTranslatedPhrase(this.props.userLanguage, 'Select Coupon')} *</option>
                                            <option value="add-new-coupon">{getTranslatedPhrase(this.props.userLanguage, 'Add New')}</option>
                                            {this.state.couponList.map((v, i) => (
                                                <option key={'coupon-' + i} value={v.itemId}>{v.title}</option>)
                                            )}
                                        </select>
                                    </div>
                                </div>
                            )}

                            {(this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-deal') && (
                                <>
                                    <div className="edit-page-field">
                                        <input className="form-input-text form-input-full" type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Discount Value *')}
                                            value={this.displayPriceValue(this.state.temp.discountValue, 'discountValue')}
                                            onChange={(e) => this.onChangeDiscountProperty(e, 'discountValue', 20, 'Discount Value')} />
                                    </div>

                                    <div className="edit-page-field">
                                        <div className="form-input-select">
                                            <select className="form-input-full" value={this.state.temp.discountCurrency} onChange={(e) => this.onChangeDiscountProperty(e, 'discountCurrency', 50, 'Currency')}>
                                                <option value="%">{getTranslatedPhrase(this.props.userLanguage, '%')}</option>
                                                <option value={this.state.currency}>{this.state.currency}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="edit-page-field">
                                        <div className="form-input-select">
                                            <select className="form-input-full" value={this.state.temp.availability} onChange={(e) => this.onChangeDiscountProperty(e, 'availability', 50, 'Set Availability')}>
                                                <option value="set-availability">{getTranslatedPhrase(this.props.userLanguage, 'Set Availability *')}</option>
                                                <option value="choose-dates">{getTranslatedPhrase(this.props.userLanguage, 'Choose Dates')}</option>
                                                <option value="no-expiration-date">{getTranslatedPhrase(this.props.userLanguage, 'No Expiration Date')}</option>
                                            </select>
                                        </div>
                                    </div>
                                </>
                            )}

                            {(this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-deal' && this.state.temp.availability === 'choose-dates') && (
                                <>
                                    <div className="edit-page-field">
                                        <input className="form-input-text form-input-full" type="date" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Start Date')}
                                            value={this.state.temp.startDate}
                                            onChange={(e) => this.onChangeDiscountProperty(e, 'startDate', 20, 'Start Date')} />
                                    </div>

                                    <div className="edit-page-field">
                                        <input className="form-input-text form-input-full" type="date" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Expiration Date')}
                                            value={this.state.temp.endDate}
                                            onChange={(e) => this.onChangeDiscountProperty(e, 'endDate', 20, 'Expiration Date')} />
                                    </div>
                                </>
                            )}
                        </>)}
                    </div>
                </div>
            )}





            {/* Specifications */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Specifications</p>
                </div>


                <div className="new-edit-page_section-specs">

                    <div>
                        <label>{getTranslatedPhrase(this.props.userLanguage, 'Add More') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'Specifications')}</label>

                        <form onSubmit={this.onAddSpecSubmit}>
                            <table className="addSpecTable">
                                <tbody>
                                    <tr>
                                        {/* <td className="addSpecLabel"><input type="text" placeholder={getTranslatedPhrase(this.props.userLanguage,'Rating')}
                                ref={(n) => { if (!this.state.temp.specFocusedInput) this.setState({ temp: {...this.state.temp, specFocusedInput: n}}); }}
                                value={this.state.temp.specName}
                                onChange={this.onChangeSpecNameHandler}/></td> */}
                                        <td className="addSpecLabel">
                                            <div className="form-input-select">
                                                <select className="form-input-full" ref={(n) => { if (!this.state.temp.specFocusedInput) this.setState({ temp: { ...this.state.temp, specFocusedInput: n } }); }}
                                                    value={this.state.temp.specName}
                                                    disabled={this.state.moreSpecs.length === 0 ? true : false}
                                                    onChange={this.onChangeSpecNameHandler}>
                                                    {this.state.moreSpecs.map(v => (
                                                        <option key={v} value={v}>{getTranslatedPhrase(this.props.userLanguage, v)}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addSpecValue"><input className="form-input-text form-input-full" disabled={this.state.moreSpecs.length === 0 ? true : false} type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'PG 13')}
                                            value={this.state.temp.specValue}
                                            onChange={this.onChangeSpecValueHandler}
                                            maxLength="20"
                                        /></td>
                                        <td className="addRemoveValue">
                                            <button disabled={this.state.moreSpecs.length === 0 ? true : false} onClick={() => this.onAddSpec({ newSpecName: this.state.temp.specName, newSpecValue: this.state.temp.specValue })}>
                                                <i className="fal fa-plus-square" />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>

                        <table className="SubmitedSpecTable">
                            <tbody>
                                {this.state.specList.map((v, i) => (<tr key={i}>
                                    <td className="addSpecLabel">{v.specName}</td>
                                    <td className="addSpecValue">{v.specValue}</td>
                                    <td className="addRemoveValue">
                                        <button onClick={() => this.onRemoveSpec(i)}>
                                            <i className="fal fa-minus-square" />
                                        </button></td>
                                </tr>))}

                            </tbody>
                        </table>

                    </div>
                </div>
            </div>






            {/* Description */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Description</p>
                </div>


                <div className="new-edit-page_section-content">
                    <div className="edit-page-field edit-page-field-full">
                        <textarea
                            className="form-input-textarea form-input-full"
                            placeholder={getTranslatedPhrase(this.props.userLanguage, 'Write your event description. *')}
                            value={this.state.description}
                            onChange={(e) => this.onChangeItem(e.target.value, 'description', 10000, 'Description')} >
                        </textarea>
                    </div>
                </div>
            </div>



            <CreateCouponModal
                active={this.state.temp.showCouponModal}
                onClose={this.onCloseCouponModal}
                initialPrice={this.state.price}
                oldCoupon={null}
                couponCurrency={this.state.currency}
            />
        </>);
    }
}