import React, { Component } from 'react';
import LayoutSideBar from '../../../../shared/util/layout-sidebar';
import {connect} from 'react-redux';

import UserList from '../../../../shared/components/SideBar/UserList/UserList';
import ProfileInfoBox from '../../../../shared/components/SideBar/ProfileInfoBox/ProfileInfoBox';
import JoinNowButton from '../../../../shared/components/SideBar/CreateNewButton/CreateNewButton';
import CouponsSideBar from '../../../../shared/components/SideBar/CouponsSideBar/CouponsSideBar';
import SponsoredItems from '../../../../shared/components/SideBar/ProductsHolder/ProductsHolder';
import AdminPanel from '../../../../shared/components/SideBar/AdminPanel/AdminPanel';
import {hostNameAndPort} from '../../../../shared/util/vars';
import Axios from 'axios';
import DescriptionBox from '../../../../shared/components/SideBar/DescriptionBox/DescriptionBox';

import { getTranslatedPhrase } from '../../../../shared/util/lang';
import actionType from '../../../../redux-store/action-type';


class GroupListingSidebar extends Component {
    state = {
        sponsoredList: [],
        userList: [],
        joinGroupButtonLabel: 'Join Now',
    }

    componentDidMount() {
        let k = this.props.category;
        if (!k || k === 'all') {
            k = 'first-page';
        }
        if (this.state.category !== k) {            
            this.setState({ category: k });
            this.getSponsored(k);
        }
    }

    getSponsored = async (k) => {
        let groupListParams = new FormData();  
        groupListParams.append('section', 'groups');
        groupListParams.append('category', k);
        groupListParams.append('count', 2);
        let options = {
                url: `${hostNameAndPort}/api/adv/sponsored`,
                method: 'post',
                data: groupListParams
            }
        try {
            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                this.setState({ sponsoredList: data.items });
            }
        } catch(err) {
            // Error
        }
    }

    joinThisGroup = async () => {
      try {
          let _data = new FormData();
          _data.append('itemId', this.props.itemId);
          let options = {
            url: hostNameAndPort+'/api/groups/join',
            method: 'post',
            data: _data
          };
          let result = await Axios(options);
          let {data} = result;
          if (!data.error) {
              this.setState({ joinGroupButtonLabel: 'Request sent' });
              this.props.showJoinGroupRequestAlert( getTranslatedPhrase(this.props.userLanguage,'Join Group request was sent') );
          }
      } catch(err) {
          alert(getTranslatedPhrase(this.props.userLanguage,'Network error ERRFEGRPLST01'));
      }
    }
    
    render () {
        return (
            <LayoutSideBar>

                <AdminPanel 
                    itemType={this.props.section}
                    itemSlug={this.props.itemSlug}
                    itemId={this.props.itemId}
                    userIsAuthorized={this.props.userIsAuthorized}
                />

                <ProfileInfoBox 
                    infoBoxTitle={getTranslatedPhrase(this.props.userLanguage,'About')}
                    profileDetails={this.props.groupDetails} 
                />

                {(!this.props.userIsMember)?(
                  <JoinNowButton onClickHandler={this.joinThisGroup} title={getTranslatedPhrase(this.props.userLanguage,this.state.joinGroupButtonLabel)} />
                ):null}

                <DescriptionBox
                    descriptionBoxTitle={getTranslatedPhrase(this.props.userLanguage,'Description')}
                    descriptionBoxText={this.props.description}
                />

                <UserList 
                    UserListTitle={getTranslatedPhrase(this.props.userLanguage,'Members')} 
                    userList={this.props.members}
                    onChangeTab={() => null}
                />

                <SponsoredItems 
                    title="Sponsored" 
                    items={this.state.sponsoredList}
                    section="groups"
                />

                <CouponsSideBar 
                    section={this.props.section}
                    category={this.props.category}
                />

            </LayoutSideBar>
        );
    }
}

const mapStateToGroupListingSidebarProps = (
    state,
    props
) => {
    return {
        userIsAuthenticated: state.userIsAuthenticated,
        userLanguage: state.userLanguage,
    };
};

const mapDispatchToGroupListingSidebarProps = (
    dispatch
) => {
    return {
        showJoinGroupRequestAlert: (m) => {
            dispatch({type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: m || ' ' });
        },
    };
};


export default connect(mapStateToGroupListingSidebarProps, mapDispatchToGroupListingSidebarProps)(GroupListingSidebar);