import React, { Component } from "react";
// import ProfileSettingsNav from "../ProfileNav/ProfileSettingsNav";
import ProfileSettingsBox from "../ProfileSettingsBox";
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import { formatPriceAndCurrency } from "../../../../shared/util/number";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import Axios from "axios";
import { connect } from "react-redux";
import { hostNameAndPort } from "../../../../shared/util/vars";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ResellerTerms from "../../../Static/Affiliates/Terms/ResellerTerms";
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    ViberShareButton
    // EmailShareButton,
} from "react-share";
import SettingsMenu from "../SettingsMenu";

class ResellerAffiliateDashboard extends Component {
    state = {
        coins: 0,
        cashinDisabled: "disabled",
        cashinClicked: false,
        invoiceList: [],
        emails: "",
        affiliateURL: "",
        copied: false,
        totalCoins: 0,
        totalEarned: 0,
        success: false,
        agreeWithTerms: true,
        referredList: [],
        user: { profileImage: "/assets/email/images/email-invite-friends-2.jpg" }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.getUserInfo();
        }, 500);
        this.getAffiliateURL();
        this.getCoinStatus();
        this.updateInvoiceList();
        this.getRefferedList();
    }

    getUserInfo = async () => {
        let user = this.props.loggedInUser;
        if (user) this.setState({ user });
    };

    getRefferedList = async () => {
        try {
            let referredData = new FormData();
            referredData.append("filter", JSON.stringify({ start: 0, count: 20 }));
            let options = {
                url: `${hostNameAndPort}/api/reseller/referredlist`,
                method: "post",
                data: referredData
            };
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.setState({ referredList: data.items });
            }
        } catch (err) {
            // Network error
        }
    };

    getAffiliateURL = async () => {
        try {
            let url = `${hostNameAndPort}/api/user/affiliate-info`;
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                this.setState({ affiliateURL: data.user.affiliateURL });
            }
        } catch (err) {
            // Network error
        }
    };

    getCoinStatus = async () => {
        try {
            let url = `${hostNameAndPort}/api/reseller/unpaidamount`;
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                let bt = "disabled";
                // data.coins = 576;
                if (data.coins >= 1000) {
                    bt = "regular";
                }
                let totalEarned = this.state.totalEarned;
                if (!!this.state.totalCoins) totalEarned = this.state.totalCoins + Number(data.coins);
                this.setState({ totalEarned, coins: data.coins, cashinDisabled: bt });
            }
        } catch (err) {
            // Network error
        }
    };

    sendCashinRequest = async () => {
        try {
            if (!this.state.coins || Number(this.state.coins) < 1000) return;
            let options = `${hostNameAndPort}/api/mail/sendcashinrequest`;
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.setState({ cashinClicked: true, errorMessage: "" });
            }
        } catch (err) {
            this.setState({ errorMessage: getTranslatedPhrase(this.props.userLanguage, "Network error ERRFERSLAD01") });
        }
    };

    onUpdatePayment = () => {
        this.props.history.push("/reseller-application");
    };

    updateInvoiceList = async () => {
        try {
            let invoiceData = new FormData();
            invoiceData.append("filter", JSON.stringify({ reseller: 1 }));
            let options = {
                url: `${hostNameAndPort}/api/invoices/items`,
                method: "post",
                data: invoiceData
            };
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                let totalCoins = data.items.reduce((a, v) => a + v.amount, 0);
                let totalEarned = this.state.totalEarned;
                if (!!this.state.coins) totalEarned = totalCoins + Number(this.state.coins);
                this.setState({ totalEarned, totalCoins, invoiceList: data.items });
            }
        } catch (err) {
            // Network error
        }
    };

    onSubmitOptOut = async () => {
        try {
            let options = {
                url: `${hostNameAndPort}/api/reseller`,
                method: "delete"
            };

            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.props.history.push("/");
            } else this.setState({ errorMessage: data.error });
        } catch (err) {
            this.setState({ errorMessage: "Network error ERRFERSLAD02" });
        }
    };

    toggleAgreeWithTerms = () => {
        this.setState({ agreeWithTerms: !this.state.agreeWithTerms, errorMessage: "" });
    };

    render() {
        let shareLink = "https://www.followone.com/affiliateurl/" + this.state.affiliateURL;
        return (
          <SettingsMenu ResellerAffiliateDashboardLiActive="active">


              <ProfileSettingsBox>
                  <div className="ras-section">
                      <h4>USE YOUR PERSONAL URL TO SIGN UP USERS AND GET PAID</h4>

                      <div className="ras-url">
                          <input className="form-input-text" type="text" onChange={() => null} value={"https://www.followone.com/affiliateurl/" + this.state.affiliateURL} />

                          <CopyToClipboard text={"https://www.followone.com/affiliateurl/" + this.state.affiliateURL} onCopy={() => this.setState({ copied: true })}>
                              <button className="button button-regular button-copy"><i className="fal fa-copy" /> {this.state.copied ? <span>Copied!</span> : null}</button>
                          </CopyToClipboard>
                          <FacebookShareButton url={shareLink}>
                              <button className="button button-regular button-copy"><i className="fab fa-facebook-f" /></button>
                          </FacebookShareButton>
                          <TwitterShareButton url={shareLink}>
                              <button className="button button-regular button-copy"><i className="fab fa-twitter" /></button>
                          </TwitterShareButton>
                          <PinterestShareButton url={shareLink} media={this.state.user.profileImage}>
                              <button className="button button-regular button-copy"><i className="fab fa-pinterest-p" /></button>
                          </PinterestShareButton>
                          <WhatsappShareButton url={shareLink}>
                              <button className="button button-regular button-copy"><i className="fab fa-whatsapp" /></button>
                          </WhatsappShareButton>
                          <ViberShareButton url={shareLink}>
                              <button className="button button-regular button-copy"><i className="fab fa-viber" /></button>
                          </ViberShareButton>


                          {/* <button className="button button-regular"><i className="fal fa-envelope"></i></button>
                            <button className="button button-regular"><i className="fab fa-facebook-f"></i></button>
                            <button className="button button-regular"><i className ="fab fa-twitter"></i></button> */}
                      </div>

                      <p className="ras-url-info"><strong>IMPORTANT!</strong> We use this URL to track sales to your affiliate account. When subscribing new users please be sure to use this URL.</p>
                  </div>
              </ProfileSettingsBox>

              <ProfileSettingsBox>
                          <h4 className="txtc mgb-sm">Earnings</h4>

                          <div className="ras-earnings">

                              <div className="ras-circle ras-cash-ready">
                                  <p>{formatPriceAndCurrency(this.state.coins, "USD", ",")}</p>
                                  <p>Current<br /> Balance</p>
                              </div>

                              <div className="ras-circle">
                                  <p>{formatPriceAndCurrency(this.state.totalCoins, "USD", ",")}</p>
                                  <p>Total<br /> Paid Amount</p>
                              </div>

                              <div className="ras-circle">
                                  <p>{formatPriceAndCurrency(this.state.totalEarned, "USD", ",")}</p>
                                  <p>Total<br /> Earned to Date</p>
                              </div>
                          </div>

                          <div className="ras-actions">
                              {!this.state.cashinClicked && (<button onClick={this.sendCashinRequest} className={`button button-${this.state.cashinDisabled}`}>Transfer Cash</button>)}
                              {this.state.cashinClicked && (<button className={`button button-disabled`}>Request Sent</button>)}
                              <button className="button" onClick={this.onUpdatePayment}>Update Payment Account</button>
                          </div>

              </ProfileSettingsBox>

              <ProfileSettingsBox>
                      <h4 className="txtc mgb-sm">Affiliate Level</h4>

                      <div className="ras-levels">

                          <div className="ras-circle current-silver ras-circle-silver">
                              <p>Silver</p>
                              <p><span>Current Level<br />&nbsp;</span></p>
                          </div>

                          <div className="ras-circle ras-circle-gold">
                              <p>Gold</p>
                              <p>500 Sales To <br /> Gold Level</p>
                          </div>

                          <div className="ras-circle ras-circle-diamond">
                              <p>Diamond</p>
                              <p>2500 Sales To <br /> Diamond Level</p>
                          </div>

                          <div className="ras-circle ras-circle-executive">
                              <p>Executive</p>
                              <p>5000 Sales To <br /> Executive Level</p>
                          </div>
                      </div>
          </ProfileSettingsBox>


            <ProfileSettingsBox>
                <h4>Invoices</h4>

                <table className="ras-invoice-table">
                    <tbody>
                    <tr className="table-header">
                        <td>Invoice ID</td>
                        <td>Date</td>
                        <td>Amount</td>
                        <td>Method</td>
                    </tr>

                    {this.state.invoiceList.map((v, i) => (<tr>
                        <td>{v.title}</td>
                        <td>{moment(v.paidOn).format("L")}</td>
                        <td>{formatPriceAndCurrency(v.amount, "USD", ",")}</td>
                        <td>{v.paymentMethod}</td>
                    </tr>))}

                    </tbody>
                </table>

            </ProfileSettingsBox>


            <ProfileSettingsBox>
                <h4>Signed Up Accounts</h4>

                <table className="ras-accounts-table">
                    <tbody>
                    <tr className="table-header">
                        <td>Business</td>
                        <td>Sale Date</td>
                        <td>Account Type</td>
                    </tr>

                    {this.state.referredList.map((v, i) => (
                      <tr>
                          <td><Link to={v.slug}>{v.entityName}</Link></td>
                          <td>{moment(v.creationDate).format("L")}</td>
                          <td>{v.subscription}</td>
                      </tr>
                    ))}
                    </tbody>
                </table>

            </ProfileSettingsBox>




        <ProfileSettingsBox>
            <h4>Opt Out</h4>

            <ResellerTerms />
            <br />

            <div className="form-input-lists form-input-lists_row mgb-sm">
                <input id="Check" type="checkbox" checked={this.state.agreeWithTerms} onChange={() => null} onClick={this.toggleAgreeWithTerms} />
                <label htmlFor="Check">
                    <i className="fal fa-check" /> <span>I have read and agreed with all terms and conditions mentioned above. I agree that by opting out I will loose any available balance on the account.</span>
                </label>
            </div>

            <div className="ras-divider" />
            <button onClick={this.onSubmitOptOut} className="button button-sale">Opt Out</button>
        </ProfileSettingsBox>


    </SettingsMenu>
    )}
}

const mapStateToAccountSettingsProps = (
  state,
  props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

export default connect(mapStateToAccountSettingsProps, null)(withRouter(ResellerAffiliateDashboard));
/**
 * <tr>
 <td><Link to="">Toyota of Bellevue</Link></td>
 <td>01/30/2019</td>
 <td>Premium</td>
 <td className="txtc">$20</td>
 </tr>

 <tr>
 <td><Link to="">Dry Fish Restaurant</Link></td>
 <td>01/30/2019</td>
 <td>Basic</td>
 <td className="txtc">$5</td>
 </tr>

 <tr>
 <td><Link to="">Daniel's Broyler</Link></td>
 <td>01/29/2019</td>
 <td>Premium</td>
 <td className="txtc">$20</td>
 </tr>

 <tr>
 <td><Link to="">Bellevue's French Backery</Link></td>
 <td>01/29/2019</td>
 <td>Premium</td>
 <td className="txtc">$20</td>
 </tr>
 */