import React from "react";
import { Link } from "react-router-dom";
import { hostNameAndPort } from "../../../shared/util/vars";
import { titleCase } from "../../../shared/util/string";
import Box from "../../../shared/util/box";
import MediaModal from "../../../shared/components/Modals/Media/MediaModal";
import VideosListingSidebar from "../../../shared/templates/ListingTemplate/listing-sidebar";
import EngageBox from "../../../shared/uielements/Engage/EngageBox";
import Axios from "axios";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import moment from "moment";
import actionType from "../../../redux-store/action-type";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import VideoPreview from "./VideoPreview";

class VideoDetail extends React.Component {
  state = {
    itemIsLiked: false,
    itemIsShared: false,
    likes: [],
    category: "all",
    subcategory: "all",
    shares: [],
    itemId: "",
    likeCount: 0,
    shareCount: 0,
    title: "",
    openModal: true,
    pageRefresh: "",
    current_item_id: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let p = this.props.match.params || {};
    this.setState({
      pageRefresh: this.props.pageRefresh,
      current_item_id: p.storeItem,
      pageViewStart: moment(),
    });
    if (p && p.storeItem) {
      setTimeout(() => {
        this.getVideo(p.storeItem);
      }, 100);
    }
  }

  componentDidUpdate() {
    let k = this.props.pageRefresh;
    let p = this.props.match.params || {};
    if (
      this.state.pageRefresh !== k ||
      this.state.current_item_id !== p.storeItem
    ) {
      this.setState({ pageRefresh: k, current_item_id: p.storeItem });
      window.scrollTo(0, 0);
      if (p && p.storeItem) {
        this.getVideo(p.storeItem);
      }
    }
  }

  handlePPVCampaignViewer = async (isOrganic, itemId) => {
    try {
      let ppvData = new FormData();
      ppvData.append("campaignId", this.props.payperviewRedirect);
      ppvData.append("itemId", itemId);
      ppvData.append("itemType", "videos");
      let options = {
        url: `${hostNameAndPort}/api/ppv/add${isOrganic}view`,
        method: "post",
        data: ppvData,
      };
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        // Success! Nothing to do
      }
    } catch (error) {
      // Network error
    }
  };

  async getVideo(b) {
    let url = `${hostNameAndPort}/api/videos/${b}`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        this.setState(data.item);

        setTimeout(() => {
          if (moment().diff(moment(this.state.pageViewStart), "seconds") >= 5) {
            if (!!this.props.payperviewRedirect) {
              this.handlePPVCampaignViewer("", data.item.itemId);
              this.props.resetPayperviewRedirect();
            } else {
              this.handlePPVCampaignViewer("organic", data.item.itemId);
            }
          }
        }, 5100);
      } else this.props.history.push("/404");
    } catch (err) {
      //console.log(err)
      this.props.history.push("/404");
    }
  }

  onOpenMediaModal = () => {
    this.setState({ openModal: true });
  };

  onCloseMediaModal = () => {
    // let u = this.props.match.url;
    // let u2 = u.slice(0, u.lastIndexOf('/'));
    // this.props.history.push(u2);
    // this.props.history.push(u);
    this.setState({ openModal: false });
  };

  render() {
    if (!this.state.title) return null;
    return (
      <Box>
        <div className="listing-body">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{this.state.title} | Followone</title>
            <link
              rel="canonical"
              href={
                "https://followone.com/videos/" +
                this.state.category +
                "/" +
                this.state.subcategory +
                "/" +
                this.state.slug
              }
            />
          </Helmet>

          <div className="main-container MediaGalleryPhotos">
            <h1>{this.state.title}</h1>

            <div className="MediaGalleryMeta">
              <span className="mgmLeft">
                {getTranslatedPhrase(this.props.userLanguage, "updated on") +
                  " " +
                  moment(this.state.creationDate).local().format("LLL")}{" "}
                &nbsp; /&nbsp;{" "}
                {getTranslatedPhrase(this.props.userLanguage, "in")}
                {/* <Link to={`/videos/${this.state.category}`}> */}
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  titleCase(this.state.category.replace(/-/g, " "))
                )}
                {/* </Link>  */}
                &nbsp;
                {this.state.subcategory &&
                  this.state.subcategory !== "null" &&
                  "/ " +
                    getTranslatedPhrase(
                      this.props.userLanguage,
                      titleCase(this.state.subcategory.replace(/-/g, " "))
                    ) +
                    " "}
                /&nbsp; {getTranslatedPhrase(this.props.userLanguage, "by")}{" "}
                <Link to={"/" + this.state.authorSlug}>
                  {this.state.authorName}
                </Link>
              </span>
            </div>

            <div className="video-listing_details">
              <span className="resp-video-cat">
                <iframe
                  title={this.state.title}
                  src={this.state.videoURL}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                />
              </span>

              {this.state.description && (
                <div style={{ padding: "2rem" }}>
                  <h4 style={{ marginBottom: "2rem" }}>Video Description </h4>
                  <p>
                    {this.state.description.split("\n").map((v, i) => (
                      <Box key={"line-" + i}>
                        <p>{v}</p>
                      </Box>
                    ))}
                  </p>
                </div>
              )}

              <div className="video-listing_details_comments">
                {!this.state.itemId ? null : (
                  <EngageBox
                    websiteSection={"videos"}
                    itemId={this.state.itemId}
                    authorId={this.state.authorId}
                    withComments={true}
                    clickedToComment={true}
                  />
                )}
              </div>
            </div>
          </div>

          <VideosListingSidebar
            section="videos"
            category={this.state.category}
            itemSlug={this.state.slug}
            itemId={this.state.itemId}
            userIsAuthorized={this.state.userIsAuthorized}
            UsersWhoLikeThis={this.state.likes}
            authorId={this.state.authorId}
            authorName={this.state.authorName}
            authorSlug={this.state.authorSlug}
            authorProfileImage={this.state.authorProfileImage}
            createNewTitle="New Video"
            ItemPreview={VideoPreview}
          />

          <MediaModal
            active={this.state.openModal}
            onClickCloseHandler={this.onCloseMediaModal}
            videoElement={
              <iframe
                width="1600"
                height="900"
                title={this.state.title}
                src={this.state.videoURL}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen={true}
              />
            }
            authorProfileImage={this.state.authorProfileImage}
            authorName={this.state.authorName}
            mediaTitle={this.state.title}
            mediaDescription={this.state.description}
            websiteSection="videos"
            itemId={this.state.itemId}
            authorId={this.state.authorId}
            UsersWhoLikeThis={this.state.likes}
            clickedToComment={true}
          />
        </div>
      </Box>
    );
  }
}

const mapStateToVideoDetailProps = (state, props) => {
  return {
    userIsAuthenticated: state.userIsAuthenticated,
    pageRefresh: state.pageRefresh,
    userLanguage: state.userLanguage,
    payperviewRedirect: state.payperviewRedirect,
  };
};

const mapDispatchToVideoDetailProps = (dispatch) => {
  return {
    resetPayperviewRedirect: () => {
      dispatch({
        type: actionType.PAYPERVIEW_REDIRECT,
        payperviewRedirect: false,
      });
    },
  };
};

export default connect(
  mapStateToVideoDetailProps,
  mapDispatchToVideoDetailProps
)(withRouter(VideoDetail));
