import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const TermsOfServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="main-100 terms-of-service">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms of Service | Followone</title>
        <meta
          name="description"
          content="The official Followone Read Me file."
        />
        <link rel="canonical" href="https://followone.com/terms-of-service" />
      </Helmet>

      <div className="main-100-header">
        <h1>Terms of Use</h1>
        <p>The official 'Read Me' file.</p>
      </div>

      <div className="legal-text">
        <p>
          These Terms of Service ("Terms") govern your access to and use of our
          website, products, and services ("Products"). Please read these Terms
          carefully and contact us if you have any questions. By accessing or
          using our Products, you agree to be bound by these Terms of Service
          and by our Privacy Policy.
        </p>

        <h4>1. Using This Website</h4>
        <p className="mgb-none">
          <strong>a. Who can use this Website</strong>
        </p>

        <p>
          You may use our Products only if you can form a binding contract with
          us, and only in compliance with these Terms and all applicable laws.
          When you create your account, you must provide us with accurate and
          complete information. Any use or access by anyone under the age of 13
          is prohibited. Some of our Products may be software that is downloaded
          to your computer, phone, tablet, or other device. By using our website
          you agree that we may automatically upgrade those Products, and these
          Terms will apply to such upgrades.
        </p>

        <h4>2. Your Content</h4>
        <p className="mgb-none">
          <strong>a. Posting content</strong>
        </p>

        <p>
          We allow you to post content including photos, comments, links, and
          other materials. Anything that you post or otherwise make available on
          our Products is referred to as "User Content." You retain all rights,
          and are solely responsible for the User Content you post.
        </p>

        <p className="mgb-none">
          <strong>b. How other users can use your content</strong>
        </p>

        <p>
          You grant other users a non-exclusive, royalty-free, transferable,
          sublicensable, worldwide license to use, store, display, reproduce,
          share, modify, create derivative works from, perform, and distribute
          your User Content on this site solely for the purposes of operating,
          developing, providing, and using the Products. Nothing in these Terms
          shall restrict other legal rights we may have to User Content. Such as
          under other licenses. We reserve the right to remove or modify User
          Content for any reason, including User Content that we believe
          violates these Terms or our policies.
        </p>

        <p className="mgb-none">
          <strong>c. How long we keep your content</strong>
        </p>

        <p>
          Following termination or deactivation of your account. If you remove
          any User Content from this site, we may retain your User Content for a
          commercially reasonable period for backup, archival, or audit
          purposes. Our users may retain and continue to use, store, display,
          reproduce, re-share, modify, create derivative works from, perform,
          and distribute any of your User Content that other users have stored
          or shared through this site.
        </p>

        <p className="mgb-none">
          <strong>d. Feedback you provide</strong>
        </p>

        <p>
          We value hearing from our users and are always interested in learning
          about ways we can make our website better. If you choose to submit
          comments, ideas, or feedback, you agree that we are free to use them
          without any restriction or compensation to you.
        </p>

        <p className="mgb-none">
          <strong>e. Prohibited content</strong>
        </p>

        <p>
          You are not allowed to post or share any content that violates
          applicable laws or regulations, including but not limited to content
          that is pornographic, promotes violence or illegal activities,
          involves the sale of firearms or drugs, infringes upon intellectual
          property rights, or constitutes hate speech or discrimination. Posting
          such content may result in the termination or suspension of your
          account. We reserve the right to remove any content that we deem to be
          in violation of these terms or our policies.
        </p>

        <h4>3. Security</h4>
        <p>
          We care about the security of our users. While we work to protect the
          security of your content and account, we cannot guarantee that
          unauthorized third parties will not be able to defeat our security
          measures. Please notify us immediately of any compromise or
          unauthorized use of your account.
        </p>

        <h4>4. Termination</h4>
        <p>
          We may terminate or suspend this license at any time, with or without
          cause or notice to you. Upon termination, you continue to be bound by
          Sections 2 and 6-12 of these Terms.
        </p>

        <h4>5. Disclaimers</h4>
        <p>
          Our Products and all included content are provided on an "as is" basis
          without warranty of any kind, whether expressed or implied.
        </p>

        <p>
          THIS WEBSITE SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND
          CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-
          INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING, OR
          USAGE OF TRADE.
        </p>

        <p>
          This Website takes no responsibility and assumes no liability for any
          User Content that you or any other user or third-party posts or
          transmits using our Products. You understand and agree that you may be
          exposed to User Content that is inaccurate, objectionable,
          inappropriate for children, or otherwise unsuited to your purpose.
        </p>

        <h4>6. Limitation of Liability</h4>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, THIS WEBSITE SHALL NOT BE
          LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
          PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED
          DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER
          INTANGIBLE LOSSES, RESULTING FROM (A) YOUR ACCESS TO OR USE OF OR
          INABILITY TO ACCESS OR USE THE PRODUCTS; (B) ANY CONDUCT OR CONTENT OF
          ANY THIRD PARTY ON THE PRODUCTS, INCLUDING WITHOUT LIMITATION, ANY
          DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD
          PARTIES; OR (C) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR
          TRANSMISSIONS OR CONTENT. IN NO EVENT SHALL WE AGGREGATE LIABILITY FOR
          ALL CLAIMS RELATING TO THE PRODUCTS, EXCEED ONE HUNDRED U.S. DOLLARS
          (U.S. $100.00).
        </p>

        <h4>7. Arbitration</h4>
        <p>
          For any dispute with us, you agree to first contact us and attempt to
          resolve the dispute with us informally. If we are not been able to
          resolve the dispute with you informally, we each agree to resolve any
          claim, dispute, or controversy (excluding claims for injunctive or
          other equitable relief) arising out of or in connection with or
          relating to these Terms by binding arbitration by the American
          Arbitration Association ("AAA") under the Commercial Arbitration Rules
          and Supplementary Procedures for Consumer Related Disputes then in
          effect for the AAA, except as provided herein. Unless we agree
          otherwise, the arbitration will be conducted in the country where you
          reside. Each party will be responsible for paying any AAA filing,
          administrative and arbitrator fees in accordance with AAA rules,
          except that we will pay for your reasonable filing, administrative,
          and arbitrator fees if your claim for damages does not exceed $75,000
          and is non-frivolous (as measured by the standards set forth in
          Federal Rule of Civil Procedure 11(b)). The award rendered by the
          arbitrator shall include costs of arbitration, reasonable attorneys'
          fees and reasonable costs for expert and other witnesses, and any
          judgment on the award rendered by the arbitrator may be entered in any
          court of competent jurisdiction. Nothing in this Section shall prevent
          either party from seeking injunctive or other equitable relief from
          the courts for matters related to data security, intellectual
          property, or unauthorized access to the Service. ALL CLAIMS MUST BE
          BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
          CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
          UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE
          THAN ONE PERSON'S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE
          TERMS, WE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
          PARTICIPATE IN A CLASS ACTION LAWSUIT.
        </p>

        <h4>8. Governing Law and Jurisdiction</h4>
        <p>
          These Terms shall be governed by the laws of the State of California,
          without respect to its conflict of laws principles. We each agree to
          submit to the personal jurisdiction of a state court located in San
          Francisco County, California or the United States District Court for
          the Northern District of California, for any actions not subject to
          Section 10 (Arbitration).
        </p>

        <p>
          Our Products are controlled and operated from the United States, and
          we make no representations that they are appropriate or available for
          use in other locations.
        </p>

        <h4>9. General Terms</h4>
        <p>
          Notification Procedures and changes to these Terms. We reserve the
          right to determine the form and means of providing notifications to
          you, and you agree to receive legal notices electronically if we so
          choose. We may revise these Terms from time to time and the most
          current version will always be posted on our website. If a revision,
          in our sole discretion, is material we will notify you. By continuing
          to access or use the Products after revisions become effective, you
          agree to be bound by the revised Terms. If you do not agree to the new
          terms, please stop using the Products.
        </p>

        <p>
          Assignment. These Terms, and any rights and licenses granted
          hereunder, may not be transferred or assigned by you, but may be
          assigned by us without restriction. Any attempted transfer or
          assignment in violation hereof shall be null and void.
        </p>
      </div>
    </div>
  );
};

export default TermsOfServices;
