import React, { useEffect, useState } from "react";
import ImageFeature1 from "../../../assets/static/affiliate_img_1.jpg";
import ImageFeature2 from "../../../assets/static/affiliate-img-2.jpg";
import { connect } from "react-redux";
import Axios from "axios";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import actionType from "../../../redux-store/action-type";
import { hostNameAndPort } from "../../../shared/util/vars";
import { Helmet } from "react-helmet";

import ResellerLogo from "../../../assets/logos/reseller-logo.svg";
import CashInLogo from "../../../assets/logos/cashin-logo.svg";

const initAffiliates = {
  accountType: "",
  userIsAuthenticated: false,
  country: "",
  affiliateId: "",
  resellerId: "",
};

const Affiliates = (props) => {
  const [affiliates, setAffiliates] = useState(initAffiliates);

  useEffect(() => {
    window.scrollTo(0, 0);
    getAccountType();
  }, []);

  useEffect(() => {
    let p = props.userIsAuthenticated;
    if (p !== affiliates.userIsAuthenticated) {
      if (!!p) {
        getAccountType();
      } else {
        setAffiliates((prevAffiliates) => {
          return {
            ...prevAffiliates,
            accountType: "",
          };
        });
      }

      setAffiliates((prevAffiliates) => {
        return {
          ...prevAffiliates,
          userIsAuthenticated: p,
        };
      });
    }
  });

  const getAccountType = async () => {
    let url = `${hostNameAndPort}/api/user/affiliate-info`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        setAffiliates((prevAffiliates) => {
          return {
            ...prevAffiliates,
            ...data.user,
          };
        });
      }
    } catch (err) {
      // Network error
    }
  };

  const onClickApply = () => {
    if (!props.userIsAuthenticated) {
      props.showLoginModal();
    } else if (!!affiliates.resellerId) return;
    else {
      if (!affiliates.accountType || affiliates.accountType !== "personal")
        props.showFollowoneAlert(
          getTranslatedPhrase(
            props.userLanguage,
            "Feature available ony to personal accounts."
          )
        );
      else props.history.push("/cash-in-application");
    }
  };

  const onClickReseller = () => {
    if (!props.userIsAuthenticated) {
      props.showLoginModal();
    } else if (!!affiliates.affiliateId) return;
    else {
      if (!affiliates.country || affiliates.country !== "USA")
        props.showFollowoneAlert(
          getTranslatedPhrase(
            props.userLanguage,
            "Feature available only in USA."
          )
        );
      else props.history.push("/reseller-application");
    }
  };

  return (
    <div className="main-100 affiliates">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Affiliate Programs | Followone</title>
        <meta
          name="description"
          content="Followone is one of the fastest growing social-business marketing platforms, and has been known for its unique approach to business since 2015."
        />
        <link rel="canonical" href="https://followone.com/affiliates" />
      </Helmet>

      <div className="main-100-header">
        <h1>{getTranslatedPhrase(props.userLanguage, "Affiliate Programs")}</h1>
        <p>
          {getTranslatedPhrase(
            props.userLanguage,
            "Followone is one of the fastest growing social-business marketing platforms, and has been known for its unique approach to business since 2015."
          )}
        </p>
      </div>

      <div className="affiliates-container">
        <div className="affiliates-container-program">
          <div className="static-page-feature">
            <img src={ImageFeature1} alt="Join our Cashin Affiliate Program" />

            <p>
              {getTranslatedPhrase(
                props.userLanguage,
                "Love shopping? Get paid to look at ads, deals, coupons, and more! All concerning things you love, from companies in your area. "
              )}
            </p>
          </div>

          <div className="static_section affiliates_section-1">
            {/* <h2>{getTranslatedPhrase(props.userLanguage,'Cashin')}<span className="reg-td">&reg;</span></h2> */}
            <div className="affiliate-logo">
              <img src={CashInLogo} alt="Cashin Logo" />
            </div>

            <p>
              {getTranslatedPhrase(
                props.userLanguage,
                "Cashin is a specially designed new program, that allows you to earn commission based on every coupon, advertisement, or discount, you view."
              )}
            </p>

            {/* <ul>
                            <li>{getTranslatedPhrase(props.userLanguage,'Be the first to know about promotions')}</li>
                            <li>{getTranslatedPhrase(props.userLanguage, 'Get $20 Credit for Signing Up (Limited Offer)')}</li>
                            <li>{getTranslatedPhrase(props.userLanguage, 'Get $0.10 per Ad, Deal or Coupon View')}</li>
                            <li>{getTranslatedPhrase(props.userLanguage, 'Transfer Cash When Reaching $50')}</li>
                        </ul> */}

            <p>
              {getTranslatedPhrase(
                props.userLanguage,
                "To apply you need to be 18 years or older, have a valid ID or state issued Driver’s License, and a PayPal or approved Bank Account. Duplicate accounts are not allowed and will be terminated immediately. Registration is available from the United States only. We are planning to open our affiliate program to other countries in the near future."
              )}
            </p>

            {/* <button
              onClick={onClickApply}
              className={`button button-${
                !affiliates.resellerId ? "regular" : "disabled"
              } dsp-in-blk`}
            >
              {getTranslatedPhrase(
                props.userLanguage,
                !!affiliates.affiliateId && !affiliates.resellerId
                  ? "See Details"
                  : !affiliates.affiliateId && !affiliates.resellerId
                  ? "Apply Now"
                  : "Not available"
              )}
            </button> */}
            <button disabled className="button button-disabled">
              Coming Soon
            </button>
          </div>
        </div>

        <div className="affiliates-container-program">
          <div className="static-page-feature">
            <img
              src={ImageFeature2}
              alt="Join our Reseller Affiliate Program"
            />

            <p>
              {getTranslatedPhrase(
                props.userLanguage,
                "Love our site and want to make some money on the side? Followone offers a way for you to spread the news about our site and get paid for it!"
              )}
            </p>
          </div>

          <div className="static_section affiliates_section-1">
            <div className="affiliate-logo">
              <img src={ResellerLogo} alt="Reseller Logo" />
            </div>

            {/* <h2>{getTranslatedPhrase(props.userLanguage,'Reseller Affiliate Program')}</h2> */}

            <p>
              {getTranslatedPhrase(
                props.userLanguage,
                "Earn commission based on selling Premium, or Enterprise subscription plans! Reach our top Executive level to earn up to $80 per sale."
              )}
            </p>

            {/* <ul>
              <li>
                {getTranslatedPhrase(props.userLanguage, "No Earning Limits")}
              </li>
              <li>
                {getTranslatedPhrase(
                  props.userLanguage,
                  "Earn Up To $80 Per Sale"
                )}
              </li>
              <li>
                {getTranslatedPhrase(
                  props.userLanguage,
                  "Transfer Cash When Reaching $1000"
                )}
              </li>
            </ul> */}

            <p>
              {getTranslatedPhrase(
                props.userLanguage,
                "To apply you need to be 18 years or older, have a valid ID or state issued Driver’s License, and a PayPal or approved Bank Account. Duplicate accounts are not allowed and will be terminated immediately. Registration is available from the United States only. We are planning to open our affiliate program to other countries in the near future."
              )}
            </p>

            {/* <button
              onClick={onClickReseller}
              className={`button button-${
                !affiliates.affiliateId ? "regular" : "disabled"
              } dsp-in-blk`}
            >
              {getTranslatedPhrase(
                props.userLanguage,
                !!affiliates.resellerId && !affiliates.affiliateId
                  ? "See Details"
                  : !affiliates.affiliateId && !affiliates.resellerId
                  ? "Apply Now"
                  : "Not available"
              )}
            </button> */}
            <button disabled className="button button-disabled">
              Coming Soon
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToAffiliatesProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
    userIsAuthenticated: state.userIsAuthenticated,
  };
};

const mapDispatchToAffiliatesProps = (dispatch) => {
  return {
    showLoginModal: () => {
      dispatch({
        type: actionType.LOGIN_MODAL,
        accountLoginModalActive: true,
        returnUrl: "/affiliates",
      });
    },
    showFollowoneAlert: (u) => {
      dispatch({
        type: actionType.ALERT_MODAL,
        alertModalActive: true,
        alertModalMessage: u,
      });
    },
  };
};

export default connect(
  mapStateToAffiliatesProps,
  mapDispatchToAffiliatesProps
)(Affiliates);
