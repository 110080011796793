import React, { Component } from 'react';
import GroupListing from '../../../Profile/Wall/WallListings/WallListing';
import ListLoader from '../../../../shared/util/list-loader';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import Box from '../../../../shared/util/box';
import LoadingSvg from '../../../../shared/uielements/LoadingSpinner/LoadingSvg';


class GroupsListingFeed extends Component {
    state = {
        groupPostList: [],
        groupId: '',
        Loader: null
    }

    componentDidMount() {
        this.setState({ groupId: this.props.groupId });
    }
      
    componentDidUpdate() {
        if (this.props.groupId !== this.state.groupId) {
          this.setState({ groupId: this.props.groupId});
          let formParams = { filter: JSON.stringify({group: this.props.groupId}) };
          this.initLoader(formParams);
        }
    }

    componentWillUnmount() {
      if (this.state.Loader) this.state.Loader.reset();
    }

    initLoader = (formParams) => {
      if (this.state.Loader) {
          this.state.Loader.reset();
          this.setState({ groupPostList: [] });
      }
      let Loader = new ListLoader({
          cb: this.onLoad,
          options: {
              delay: 3000,
              count: 20,
              request: {
                url: '/api/groups/feed',
                method: 'post',
                formParams
              }
          }
      });
      Loader.init();
      this.setState({ Loader, loaded: false });
    }

    onLoad = () => {
        let Loader = this.state.Loader;
        if (!Loader || !Loader.items) {
            this.setState({ loaded: true });
            return;
        }
        this.setState({ groupPostList: [...Loader.items], loaded: true });
    }


    render () {
        if (!this.props.userIsMember && (this.props.privacy !== 'public') ) {
            return getTranslatedPhrase(this.props.userLanguage,'To view the posts, you must join the group.');
        }
        return (<Box>
            <LoadingSvg active={!this.state.loaded} />
            {(this.state.loaded && !this.state.groupPostList.length)?getTranslatedPhrase(this.props.userLanguage,'There is no content. Be the first to post on the group.'):null}
            {this.state.groupPostList.map( (v,i) =>
              <GroupListing key={i}
                  isGroupWall={true}
                  noViewsButton={true}
                  itemId={v._id}
                  userId={v.authorId}
                  activityDate={v.creationDate}
                  itemType="texts"
                  activityType="post"
                  itemAuthorId={v.authorId}
              />
          )}
        </Box>);
    }
}


const mapStateToGroupsListingFeedProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToGroupsListingFeedProps, null)(GroupsListingFeed);