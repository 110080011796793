import React from 'react';
import ListingDetail from '../../../shared/templates/ListingTemplate/listing-detail';

export default function FoodListingDetail(props) {
  return (
    <ListingDetail
      section="food"
      createNewTitle="New Food Listing"
    />
  );
}