import React, { Component } from "react";
import Backdrop from "../../../uielements/Backdrop/Backdrop";
import FormFieldError from "../../../uielements/ErrorMessages/FormFieldError";
import moment from "moment";
import actionType from "../../../../redux-store/action-type";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import LoadingSvg from "../../../uielements/LoadingSpinner/LoadingSvg";
import { hostNameAndPort } from "../../../util/vars";
import Axios from "axios";
import { getTranslatedPhrase } from "../../../util/lang";
import { titleCase } from "../../../util/string";
import CountryDropDown from "./CountryDropDown";
import slugify from "react-slugify";

const EntityNameInput = (props) => {
  if (props.accountType === "personal" || !props.accountType) {
    return null;
  }
  return (
    <input
      type="text"
      placeholder={props.label}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

class accountInformationModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = this.state = {
      errorMessage: "",
      firstName: "",
      lastName: "",
      entityName: "",
      slug: "",
      email: "",
      password: "",
      gender: "",
      city: "",
      country: "",
      confirmPassword: "",
      birthdate: moment(),
      accountType: "personal",
      agreeWithTerms: false,
      waitingForAnswer: false,
      profileInfo: false,
      createAccountState: "disabled",
      nextButtonState: "disabled",
    };
  }

  componentDidUpdate() {
    if (this.props.signUpAccountType !== this.state.accountType) {
      this.setState({ accountType: this.props.signUpAccountType });
    }
  }

  onCreateAccountHandler = () => {
    if (!this.profileInfoIsVerified()) {
      return;
    }
    let userInfo = Object.assign({}, this.state);
    delete userInfo.errorMessage;
    delete userInfo.confirmPassword;
    delete userInfo.agreeWithTerms;
    delete userInfo.waitingForAnswer;

    const randomNo =
      Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000;

    userInfo.username = userInfo.email;

    userInfo.slug = slugify(
      userInfo.firstName.toLowerCase() +
        "-" +
        userInfo.lastName.toLowerCase() +
        "-" +
        randomNo
    );

    let signUpInfo = new FormData();
    for (let i in userInfo) {
      signUpInfo.append(i, userInfo[i]);
    }
    signUpInfo.append("referredBy", this.props.referredBy || "");

    this.setState({ waitingForAnswer: true });
    let options = {
      url: `${hostNameAndPort}/signup`,
      method: "post",
      data: signUpInfo,
    };
    Axios(options)
      .then((result) => {
        let { data } = result;
        this.setState({ waitingForAnswer: false });
        if (!data.error) {
          this.props.onClickCloseHandler();
          this.setState(this.initialState);
          this.automaticUserLogin(data.login);
        } else {
          this.setState({ errorMessage: data.error });
        }
      })
      .catch((err) => {
        this.setState({
          ...this.initialState,
          errorMessage: "Network error ERRFEACCINMD01",
        });
      });
  };

  onNextPage = () => {
    if (!this.acctInfoIsVerified()) {
      return;
    }

    let signUpInfo = new FormData();
    signUpInfo.append("email", this.state.email);

    let options = {
      url: `${hostNameAndPort}/verify-email`,
      method: "post",
      data: signUpInfo,
    };
    Axios(options)
      .then((result) => {
        let { data } = result;
        if (!data.error) {
          this.setState({ profileInfo: true });
        } else {
          this.setState({ errorMessage: data.error });
          this.setState({ profileInfo: false });
        }
      })
      .catch((err) => {
        this.setState({
          ...this.initialState,
          errorMessage: "Network error ERRFEACCINMD01",
        });
      });
  };

  automaticUserLogin = (loginInfo) => {
    let a = atob(loginInfo).split(",");
    let options = {
      url: `${hostNameAndPort}/login`,
      method: "post",
      data: `username=${a[0]}&password=${a[1]}`,
    };
    Axios(options)
      .then((result) => {
        let { data } = result;
        if (!data.error) {
          this.setState({ errorMessage: "", username: "", password: "" });
          this.props.onClickCloseHandler();
          this.props.onNewAccountCreated(data.user);
          this.props.history.push(this.props.returnUrl);
          this.props.onUpdateReferredBy();
        } else {
          this.setState({ errorMessage: "Email or password does not match" });
        }
      })
      .catch((err) => {
        this.setState({ errorMessage: "Network error ERRFEACCINMD02" });
      });
  };

  acctInfoIsVerified() {
    let _ = this.state;
    let errorMsg = "",
      _verified = true;
    let rp = /^[*|'";:,?`~!@#$%^&=)(><}{\\]/g;
    let rn = /^[*|";:,?`~!@#$%^&=)(><}{\\0-9]/g;
    let re = /^[-_.a-z0-9]+@[a-z][a-z0-9.]*[a-z]\.[a-z]{2,10}/;

    if (rn.test(_.firstName)) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "Your first name cannot include special characters."
      );
      _verified = false;
    }
    if (rn.test(_.lastName)) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "Your last name cannot include special characters."
      );
      _verified = false;
    }
    if (!re.test(_.email.toLowerCase())) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "Please enter a valid email address."
      );
      _verified = false;
    }
    if (rp.test(_.password)) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "Your password can only contain letters and numbers."
      );
      _verified = false;
    }
    if (_.password.length < 8) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "Your password must be longer than 8 characters."
      );
      _verified = false;
    }
    if (_.password !== _.confirmPassword) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "Your passwords do not match."
      );
      _verified = false;
    }
    this.setState({ errorMessage: errorMsg });
    return _verified;
  }

  profileInfoIsVerified() {
    let _ = this.state;
    let errorMsg = "",
      _verified = true;
    let rp = /^[*|'";:,?`~!@#$%^&=)(><}{\\]/g;
    let rn = /^[*|";:,?`~!@#$%^&=)(><}{\\0-9]/g;
    let re = /^[-_.a-z0-9]+@[a-z][a-z0-9.]*[a-z]\.[a-z]{2,10}/;

    if (
      !_.country ||
      _.country ===
        getTranslatedPhrase(this.props.userLanguage, "Select Country")
    ) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "Please select your country."
      );
      _verified = false;
    }
    if (!_.city) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "Please add your city."
      );
      _verified = false;
    }

    if (!_.gender) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "You did not choose gender."
      );
      _verified = false;
    }

    const age = moment().diff(moment(_.birthdate), "year");

    if (age < 16) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "You must be at least 16 years or older to create an account."
      );
      _verified = false;
    }

    if (age > 100) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "Your birthdate is incorrect."
      );
      _verified = false;
    }

    if (!_.agreeWithTerms) {
      errorMsg = getTranslatedPhrase(
        this.props.userLanguage,
        "You must agree with the terms of service."
      );
      _verified = false;
    }
    this.setState({ errorMessage: errorMsg });
    return _verified;
  }

  onChangeAccountInfoHandler = (e, propName, limit) => {
    let n = e.target.value;
    if (n.length < limit) {
      this.setState(
        {
          [propName]: n,
          errorMessage: "",
        },
        () => {
          let item = this.state;
          if (
            item.firstName &&
            item.lastName &&
            item.email &&
            item.password &&
            item.confirmPassword
          ) {
            this.setState({ nextButtonState: "" });
          } else {
            this.setState({ nextButtonState: "disabled" });
          }
        }
      );
    }
  };

  onChangeProfileInfoHandler = (e, propName, limit) => {
    let n = e.target.value;
    if (n.length < limit) {
      this.setState(
        {
          [propName]: n,
          errorMessage: "",
        },
        () => {
          let item = this.state;
          if (
            // item.slug &&
            item.city &&
            item.country &&
            item.birthdate &&
            item.gender
          ) {
            this.setState({ createAccountState: "" });
          } else {
            this.setState({ createAccountState: "disabled" });
          }
        }
      );
    }
  };

  toggleAgreeWithTerms = (e) => {
    if (e.target.checked) {
      this.setState({ agreeWithTerms: true, errorMessage: "" });
    } else {
      this.setState({ agreeWithTerms: false, errorMessage: "" });
    }
  };

  render() {
    if (!this.props.active) {
      return null;
    }

    return (
      <div className="modal-wrapper" style={{ zIndex: "99999" }}>
        <Backdrop show={this.props.active} clicked={() => null} />
        <div className="modal account-info-modal">
          <div className="modal_breadcrumb">
            {getTranslatedPhrase(this.props.userLanguage, "Account Type")}{" "}
            &nbsp;|&nbsp;
            <span>
              {getTranslatedPhrase(
                this.props.userLanguage,
                "Account Information"
              )}
            </span>
          </div>

          <div className="modal_body">
            {/* NOTE: ADD VERIFICATION TO ALL INPUTS */}

            <div className="modal_body_functions">
              {!this.state.profileInfo ? (
                <>
                  <h3>
                    {getTranslatedPhrase(
                      this.props.userLanguage,
                      "Account Info"
                    )}
                  </h3>

                  <EntityNameInput
                    accountType={this.state.accountType}
                    label={
                      !!this.state.accountType &&
                      getTranslatedPhrase(
                        this.props.userLanguage,
                        titleCase(this.state.accountType.replace(/-/g, " ")) +
                          " Name"
                      )
                    }
                    value={this.state.entityName}
                    onChange={(e) =>
                      this.onChangeAccountInfoHandler(e, "entityName", 50)
                    }
                  />

                  <div className="flex">
                    <input
                      type="text"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "First Name"
                      )}
                      value={this.state.firstName}
                      onChange={(e) =>
                        this.onChangeAccountInfoHandler(e, "firstName", 30)
                      }
                    />
                    <input
                      type="text"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "Last Name"
                      )}
                      value={this.state.lastName}
                      onChange={(e) =>
                        this.onChangeAccountInfoHandler(e, "lastName", 30)
                      }
                    />
                  </div>

                  <input
                    type="email"
                    placeholder={getTranslatedPhrase(
                      this.props.userLanguage,
                      "Email"
                    )}
                    value={this.state.email.toLowerCase()}
                    onChange={(e) =>
                      this.onChangeAccountInfoHandler(e, "email", 80)
                    }
                  />

                  <div className="flex">
                    <input
                      type="password"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "Password"
                      )}
                      value={this.state.password}
                      onChange={(e) =>
                        this.onChangeAccountInfoHandler(e, "password", 60)
                      }
                    />
                    <input
                      type="password"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "Verify Password"
                      )}
                      value={this.state.confirmPassword}
                      onChange={(e) =>
                        this.onChangeAccountInfoHandler(
                          e,
                          "confirmPassword",
                          60
                        )
                      }
                    />
                  </div>

                  <FormFieldError>
                    {getTranslatedPhrase(
                      this.props.userLanguage,
                      this.state.errorMessage
                    )}
                  </FormFieldError>

                  <div className="flex" style={{ marginTop: "16px" }}>
                    <button
                      className="button button-white blk-ctr-align"
                      onClick={this.props.onClickBackHandler}
                    >
                      {getTranslatedPhrase(this.props.userLanguage, "Back")}
                    </button>
                    <button
                      className="button button-regular blk-ctr-align"
                      disabled={this.state.nextButtonState}
                      onClick={this.onNextPage}
                    >
                      {getTranslatedPhrase(this.props.userLanguage, "Next")}
                    </button>
                  </div>
                </>
              ) : null}

              {/* & this.state.firstName & this.state.lastName */}

              {this.state.profileInfo ? (
                <>
                  {/* <h3>
                    {getTranslatedPhrase(
                      this.props.userLanguage,
                      "Profile Info"
                    )}
                  </h3> */}

                  {/* <div className="profile-url">
                    <input
                      type="text"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "Profile URL"
                      )}
                      value={this.state.slug.toLowerCase()}
                      onChange={(e) =>
                        this.onChangeProfileInfoHandler(e, "slug", 50)
                      }
                    />
                    {!this.state.slug ? (
                      <p className="profile-url-ex">
                        https://followone.com/profileurl
                      </p>
                    ) : (
                      <p className="profile-url-ex">{`https://followone.com/${this.state.slug.toLowerCase()}`}</p>
                    )}
                  </div> */}

                  <h3>
                    {getTranslatedPhrase(
                      this.props.userLanguage,
                      "Where do you live"
                    )}
                    ?
                  </h3>

                  <div className="flex">
                    <input
                      type="text"
                      value={this.state.city}
                      onChange={(e) =>
                        this.onChangeProfileInfoHandler(e, "city", 80)
                      }
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "City"
                      )}
                    />
                    <CountryDropDown
                      onChangeCountry={(e) =>
                        this.onChangeProfileInfoHandler(e, "country", 80)
                      }
                      countryPlaceholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "Select Country"
                      )}
                    />
                  </div>

                  <div className="flex">
                    <div className="birthday">
                      <label className="main-label" htmlFor="enterBirthDate">
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "Enter Birthdate"
                        )}{" "}
                        :
                      </label>
                      <input
                        id="enterBirthDate"
                        type="date"
                        className="birthdate-input"
                        title={getTranslatedPhrase(
                          this.props.userLanguage,
                          "Birthdate"
                        )}
                        value={this.state.birthdate}
                        onChange={(e) =>
                          this.onChangeProfileInfoHandler(e, "birthdate", 30)
                        }
                      />
                    </div>

                    <div className="gender">
                      <label
                        className="main-label"
                        htmlFor="selectGender"
                        style={{ color: "#fff" }}
                      >
                        -
                      </label>
                      <div
                        className="form-input-select"
                        style={{ width: "100%" }}
                      >
                        <select
                          className="form-input-full"
                          value={this.state.gender}
                          onChange={(e) =>
                            this.onChangeProfileInfoHandler(e, "gender", 50)
                          }
                        >
                          <option value="Select Gender">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          {/* <option value="other">Other</option> */}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="txtc fnts modal_body_functions_agree-terms form-input-lists">
                    <input
                      type="checkbox"
                      id="createAccountTerms"
                      checked={this.state.agreeWithTerms}
                      onChange={this.toggleAgreeWithTerms}
                    />
                    <label htmlFor="createAccountTerms">
                      <span>
                        <i className="fal fa-check" />
                      </span>{" "}
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "I have read and agree to the"
                      )}{" "}
                      <Link target="_blank" to="/terms-of-service">
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "terms of service"
                        )}
                      </Link>{" "}
                      and{" "}
                      <Link target="_blank" to="/privacy">
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "privacy policy"
                        )}
                      </Link>
                      .
                    </label>
                  </div>
                  <FormFieldError>
                    {getTranslatedPhrase(
                      this.props.userLanguage,
                      this.state.errorMessage
                    )}
                  </FormFieldError>
                  <div className="flex">
                    <button
                      className="button button-white blk-ctr-align"
                      onClick={() => this.setState({ profileInfo: false })}
                    >
                      {getTranslatedPhrase(this.props.userLanguage, "Back")}
                    </button>
                    <button
                      className="button button-regular blk-ctr-align"
                      disabled={this.state.createAccountState}
                      onClick={this.onCreateAccountHandler}
                    >
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Create Account"
                      )}
                    </button>
                  </div>

                  <LoadingSvg active={this.state.waitingForAnswer} />
                </>
              ) : null}
            </div>
          </div>

          <span
            className="modal_close"
            onClick={this.props.onClickCloseHandler}
          >
            <i className="fal fa-times" />
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToAccountInformationModalProps = (state, props) => {
  return {
    active: state.accountInformationModalActive,
    referredBy: state.referredBy,
    signUpAccountType: state.signUpAccountType,
    returnUrl: state.returnUrl,
    userLanguage: state.userLanguage,
  };
};

const mapDispatchToAccountInformationModalProps = (dispatch) => {
  return {
    onClickCloseHandler: () => {
      dispatch({
        type: actionType.ACCOUNT_INFORMATION_MODAL,
        accountInformationModalActive: false,
      });
    },
    onClickBackHandler: () => {
      dispatch({
        type: actionType.ACCOUNT_INFORMATION_MODAL,
        accountInformationModalActive: false,
      });
      dispatch({
        type: actionType.ACCOUNT_TYPE_MODAL,
        accountTypeModalActive: true,
      });
    },
    onNewAccountCreated: (u) => {
      dispatch({
        type: actionType.USER_LOGIN,
        userIsAuthenticated: true,
        loggedInUser: u,
      });
    },
    onUpdateReferredBy: () => {
      dispatch({ type: actionType.REFERRED_BY, referredBy: "" });
    },
  };
};

export default connect(
  mapStateToAccountInformationModalProps,
  mapDispatchToAccountInformationModalProps
)(withRouter(accountInformationModal));
