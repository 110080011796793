import React from 'react';
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import Box from "../../../../shared/util/box";
import moment from "moment";
import CBALogo from "../Assets/cba-listing.jpg";
import NWMLSLogo from "../Assets/nwmls-logo.svg";
import { connect } from "react-redux";

const RealEstateDescription = (props) => {
    return (
        <>

            <h4>{getTranslatedPhrase(props.userLanguage, 'Description')}</h4>
            {(props.description || props.description !== '')
                ? <p>{props.description.split('\n').map((v, i) => (<Box key={'line-' + i}>{v}<br /></Box>))}</p>
                : <p>Description not provided.</p>
            }


            {props.isMlsNw &&
                <>
                    {props.directions &&
                        <>
                            <p className="mgt-md" style={{ marginBottom: 0 }}>
                                <strong>{getTranslatedPhrase(props.userLanguage, 'DIRECTIONS')}</strong></p>
                            <p>{props.directions}</p>
                        </>
                    }

                    <p className="mgt-md" style={{ marginBottom: 0 }}><strong>{getTranslatedPhrase(props.userLanguage, 'FEATURES')}</strong></p>
                    <p>
                        {props.entryLocation && <span>Entrance: <strong>{props.entryLocation}</strong><br /></span>}
                        {props.appliances && <span>Appliances: <strong>{props.appliances.replace(/,/g, ', ')}</strong><br /></span>}
                        {props.interiorFeatures && <span>Interior: <strong>{props.interiorFeatures.replace(/,/g, ', ')}</strong><br /></span>}
                        {props.exteriorFeatures && <span>Exterior: <strong>{props.exteriorFeatures.replace(/,/g, ', ')}</strong><br /></span>}
                        {props.flooring && <span>Flooring: <strong>{props.flooring.replace(/,/g, ', ')}</strong> <br /></span>}
                        {props.furnished && <span>Furnished: <strong>{props.furnished.replace(/,/g, ', ')}</strong><br /></span>}
                        {props.powerType && <span>Power Type: <strong>{props.powerType.replace(/,/g, ', ')}</strong><br /></span>}
                        {props.utilities && <span>Utilities: <strong>{props.utilities.replace(/,/g, ', ')}</strong><br /></span>}
                        {props.heatingType && <span>Heating Type: <strong>{props.heatingType.replace(/,/g, ', ')}</strong><br /></span>}
                        {props.fireplace &&
                            <span>Fireplace:
                                <strong>{' ' + props.fireplace}</strong>
                                <strong>{props.fireplacesTotal ? ', ' + props.fireplacesTotal : ''}</strong>
                                <strong>{props.fireplaceFeatures ? ', ' + props.fireplaceFeatures : ''}</strong>
                                <br /></span>
                        }

                        {props.basement && <span>Basement: <strong>{props.basement}</strong><br /></span>}
                        {props.openParking && <span>Open Parking: <strong>{props.openParking}</strong><br /></span>}
                        {props.carport && <span>Carport: <strong>{props.carport}</strong><br /></span>}
                        {!!props.coveredSpaces && <span>Covered Spaces: <strong>{props.coveredSpaces}</strong><br /></span>}
                        {props.siteFeatures && <span>Site Features: <strong>{props.siteFeatures.replace(/,/g, ', ')}</strong><br /></span>}
                        {props.electricOnPropertyYN && <span>Electric on Property: <strong>{props.electricOnPropertyYN}</strong><br /></span>}
                        {props.irrigationWaterRightsYN && <span>Irrigation Water Rights: <strong>{props.irrigationWaterRightsYN} </strong><br /></span>}
                        {props.irrigationSource && <span>Irrigation Source: <strong>{props.irrigationSource}</strong><br /></span>}
                        {props.viewDescription && <span>View: <strong>{props.viewDescription.replace(/,/g, ', ')}</strong><br /></span>}
                        {props.signOnPropertyYN && <span>Sign On Property: <strong>{props.signOnPropertyYN}</strong></span>}
                    </p>

                    {(props.buildingFeatures || props.buildingFeatures) &&
                        <>
                            <p className="mgt-md" style={{ marginBottom: 0 }}>
                                <strong>{getTranslatedPhrase(props.userLanguage, 'BUILDING')}</strong></p>
                            <p>{props.buildingInformation.replace(/,/g, ', ')}</p>
                            <p>{props.buildingFeatures.replace(/,/g, ', ')}</p>

                        </>
                    }

                    {props.communityFeatures &&
                        <>
                            <p className="mgt-md" style={{ marginBottom: 0 }}>
                                <strong>{getTranslatedPhrase(props.userLanguage, 'COMMUNITY')}</strong></p>
                            <p>{props.communityFeatures.replace(/,/g, ', ')}</p>
                        </>
                    }

                    {props.offers &&
                        <>
                            <p className="mgt-md" style={{ marginBottom: 0 }}>
                                <strong>{getTranslatedPhrase(props.userLanguage, 'OFFERS')}</strong></p>
                            <p>{props.offers.replace(/,/g, ', ')}.</p>
                        </>
                    }

                    {props.listingTerms &&
                        <>
                            <p className="mgt-md" style={{ marginBottom: 0 }}>
                                <strong>{getTranslatedPhrase(props.userLanguage, 'LISTING TERMS')}</strong></p>
                            <p>{props.listingTerms.replace(/,/g, ', ')}</p>
                        </>
                    }

                    {props.specialListingConditions &&
                        <>
                            <p className="mgt-md" style={{ marginBottom: 0 }}>
                                <strong>{getTranslatedPhrase(props.userLanguage, 'SPECIAL LISTING CONDITIONS')}</strong></p>
                            <p>{props.specialListingConditions}</p>
                        </>
                    }

                    {props.associationFeeFrequency &&
                        <>
                            <p className="mgt-md" style={{ marginBottom: 0 }}><strong>{getTranslatedPhrase(props.userLanguage, 'ASSOCIATION INFO')}</strong></p>
                            <p>
                                <span>Fee Frequency: <strong>{props.associationFeeFrequency}</strong></span><br />
                                {props.associationFee && <span>Fee Amount: <strong>${props.associationFee}</strong></span>}
                            </p>

                        </>
                    }

                    <p className="mgt-md" style={{ marginBottom: 0 }}><strong>{getTranslatedPhrase(props.userLanguage, 'NEARBY SCHOOLS')}</strong></p>
                    <p>
                        Highschool Distrit: <strong>{props.highSchoolDistrict ? props.highSchoolDistrict : 'Not Available'}</strong><br />
                        Elementary School: <strong>{props.elementarySchool ? props.elementarySchool : 'Not Available'}</strong><br />
                        Middle School: <strong>{props.middleSchool ? props.middleSchool : 'Not Available'}</strong><br />
                        High School: <strong>{props.highSchool ? props.highSchool : 'Not Available'}</strong>
                    </p>
                </>
            }

            {!props.callForPricing && !!props.addDiscount && props.addDiscount === "deal" && (
                <>
                    <p className="mgt-md" style={{ marginBottom: 0 }}><strong>{getTranslatedPhrase(props.userLanguage, 'Discount Terms & Conditions')}</strong></p>
                    <p>
                        {moment(props.discountId.endDate).isAfter(moment("2190-01-01"))
                            ? getTranslatedPhrase(props.userLanguage, 'Deal expires at the discretion of the seller.')
                            : getTranslatedPhrase(props.userLanguage, 'Discount valid through: ') + ": " + moment(props.discountId.endDate).format('L')}
                    </p>
                </>
            )}


            {props.livingQuartersDescription && (
                <>
                    <p className="mgt-md" style={{ marginBottom: 0 }}><strong>{getTranslatedPhrase(props.userLanguage, 'Living Quarters Description')}</strong></p>
                    <p>{props.livingQuartersDescription}</p>
                </>
            )}



            {props.agentDescription && (
                <>
                    <p className="mgt-md" style={{ marginBottom: 0 }}><strong>{getTranslatedPhrase(props.userLanguage, 'AGENT DESCRIPTION')}</strong></p>
                    <p>{props.agentDescription}</p>
                </>
            )}



            {props.showingInstruction && (
                <>
                    <p className="mgt-md" style={{ marginBottom: 0 }}><strong>{getTranslatedPhrase(props.userLanguage, 'SHOWING INSTRUCTION')}</strong></p>
                    <p>{props.showingInstruction.replace(/,/g, ', ')}.</p>
                </>
            )}

            {props.website && (
                <>
                    <p className="mgt-md" style={{ marginBottom: 0 }}><strong>{getTranslatedPhrase(props.userLanguage, 'WEBSITE')}</strong></p>
                    <p><a href={`${props.website}`} rel="noopener noreferrer nofollow" target="_blank">See Website</a></p>
                </>
            )}



            {(props.isMlsCom || props.isMlsBiz || props.isMlsNw) && (
                <>
                    <p className="mgt-md" style={{ marginBottom: 0 }}><strong>{getTranslatedPhrase(props.userLanguage, 'DISCLAIMER')}</strong></p>
                    <p>The information contained in this listing has not been verified by Followone Real Estate Inc. and should be verified by the buyer.</p>
                </>
            )}

            {(props.isMlsCom || props.isMlsBiz) &&
                <img style={{ width: '150px' }} className="mgt-md" src={CBALogo} alt="Listing provided by CBA" />
            }

            {props.isMlsNw &&
                <img style={{ width: '70px' }} className="mgt-md" src={NWMLSLogo} alt="Listing provided by NWMLS" />
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return { userLanguage: state.userLanguage, };
};

export default connect(mapStateToProps)(RealEstateDescription);