import React, { Component } from "react";
import { connect } from "react-redux";
// import { getTranslatedPhrase } from '../../shared/util/lang';
import Box from "../../shared/util/box";
import Helmet from "react-helmet";
import RealEstateSearch from "./Components/RealEstate";
import AutoSearch from "./Components/Auto";
import StoreSearch from "./Components/Store";
import TravelSearch from "./Components/Travel";
import JobsSearch from "./Components/Jobs";
import ServicesSearch from "./Components/Services";
import HomeSignUp from "./Components/SignUp";
import FoodsSearch from "./Components/Foods";
import HomeHeader from "./Components/HomeHeader";
import Healthcare from "./Components/Healthcare";
// import LatestNews from './Components/News';

class home extends Component {
  render() {
    return (
      <Box>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Home | Followone</title>
          <meta
            name="description"
            content="Sell products, cars, properties & travel packages, post jobs & services, find deals & coupons and connect with customers, friends & family."
          />
          <link rel="canonical" href={"https://followone.com/"} />
        </Helmet>

        <div className="main-100">
          <HomeHeader />

          <div className="new-home">
            <div className="home-grid">
              <AutoSearch />
              <RealEstateSearch />
              <StoreSearch />
              <ServicesSearch />
            </div>

            {!this.props.userIsAuthenticated ? (
              <HomeSignUp />
            ) : (
              <div className="home-divider" />
            )}

            <div className="home-grid">
              <JobsSearch />
              <TravelSearch />
              <FoodsSearch />
              <Healthcare />
            </div>

            {/* <LatestNews /> */}
          </div>

          {/*<div className="home-box home-box_hand-picked">*/}
          {/*    <div className="home-box_header">*/}
          {/*        <h2>{getTranslatedPhrase(this.props.userLanguage,'Hand-Picked for You')}</h2>*/}
          {/*        <p>{getTranslatedPhrase(this.props.userLanguage,'Check our latest top trending products.')}</p>*/}
          {/*    </div>*/}

          {/*    <HomeProducts />*/}
          {/*</div>*/}

          {/*<div className="home-box home-box_featured-brands">*/}
          {/*    <div className="home-box_header">*/}
          {/*        <h2>{getTranslatedPhrase(this.props.userLanguage,'Featured Brands')}</h2>*/}
          {/*        <p>{getTranslatedPhrase(this.props.userLanguage,'Discover new trends & styles from our top brands.')}</p>*/}
          {/*    </div>*/}

          {/*    <HomeBrands />*/}
          {/*</div>*/}
        </div>
      </Box>
    );
  }
}

const mapStateToHomeProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
    userIsAuthenticated: state.userIsAuthenticated,
  };
};

export default connect(mapStateToHomeProps, null)(home);
