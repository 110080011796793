import React from 'react';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
import LoadingSvg from '../../../uielements/LoadingSpinner/LoadingSvg';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';
import { getTranslatedPhrase } from '../../../util/lang';
import Axios from 'axios';
import { hostNameAndPort } from '../../../util/vars';



class EnterpriseModal extends React.Component {
  state = {
    temp: {
      mainTitle: 'Enterprise Account',
      errorMessage: '',
      postListingPressed: false
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.getEnterpriseInfo();
    }, 200);
  }

  getEnterpriseInfo = async () => {
    try {
      let url = `${hostNameAndPort}/api/user/enterprise-info/${this.props.userId}`;
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        this.setState(data);
      }
    } catch (err) {
      // Server error
    }

  }

  handleStoreListings = (e) => {
    if (e.target.value.length < 20)
      this.setState({ storeListings: e.target.value });
  }

  handleFoodListings = (e) => {
    if (e.target.value.length < 20)
      this.setState({ foodListings: e.target.value });
  }

  handleAutoListings = (e) => {
    if (e.target.value.length < 20)
      this.setState({ autoListings: e.target.value });
  }

  handleRealEstateListings = (e) => {
    if (e.target.value.length < 20)
      this.setState({ realestateListings: e.target.value });
  }

  handleTravelListings = (e) => {
    if (e.target.value.length < 20)
      this.setState({ travelListings: e.target.value });
  }

  handleJobsListings = (e) => {
    if (e.target.value.length < 20)
      this.setState({ jobsListings: e.target.value });
  }

  handleServicesListings = (e) => {
    if (e.target.value.length < 20)
      this.setState({ servicesListings: e.target.value });
  }

  handlePrice = (e) => {
    if (e.target.value.length < 20)
      this.setState({ enterprisePrice: e.target.value });
  }

  createEnterpriseAccount = async () => {
    try {
      let _data = new FormData();
      _data.append('storeListings', this.state.storeListings);
      _data.append('foodListings', this.state.foodListings);
      _data.append('autoListings', this.state.autoListings);
      _data.append('realestateListings', this.state.realestateListings);
      _data.append('travelListings', this.state.travelListings);
      _data.append('jobsListings', this.state.jobsListings);
      _data.append('servicesListings', this.state.servicesListings);
      _data.append('price', this.state.enterprisePrice);
      _data.append('userId', this.props.userId);
      let options = {
        url: `${hostNameAndPort}/api/admin/create-enterprise-account`,
        method: 'post',
        data: _data
      };
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        this.props.onClose();
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    if (!this.props.active) {
      return null;
    }
    return (
      <div className="modal-wrapper">
        <Backdrop show={this.props.active} clicked={(e) => this.props.onClose()} />
        <div className="modal">
          <div className="modal_body">
            <h4 className="mgt-sm">{getTranslatedPhrase(this.props.userLanguage, this.state.temp.mainTitle)}</h4>

            <div className="modal_body_functions create-coupon-modal">
              <br />

              {/* <div className="row">
                                <div className="col span-2-of-2">
                                    <input type="text" className="form-input-text" value={this.state.title} 
                                        onChange={(e) => this.onChangeCouponProp(e, 'title', 15, 'Coupon Code')}
                                        placeholder={getTranslatedPhrase(this.props.userLanguage,'Coupon Code')} />  
                                </div>
                            </div> */}

              <div className="category-enterprise-list-colors">
                <div className="row">
                  <div className="col span-6-of-9 category-enterprise-list">
                    <span >{getTranslatedPhrase(this.props.userLanguage, 'Store Listings')}</span>
                  </div>


                  <div className="col span-3-of-9">
                    <input className="form-input-text form-input-full " onChange={this.handleStoreListings} value={this.state.storeListings} />
                  </div>
                </div>

                <div className="row">
                  <div className="col span-6-of-9 category-enterprise-list">
                    <span>{getTranslatedPhrase(this.props.userLanguage, 'Food Listings')}</span>
                  </div>


                  <div className="col span-3-of-9">
                    <input className="form-input-text form-input-full " onChange={this.handleFoodListings} value={this.state.foodListings} />
                  </div>
                </div>

                <div className="row">
                  <div className="col span-6-of-9 category-enterprise-list">
                    <span>{getTranslatedPhrase(this.props.userLanguage, 'Auto Listings')}</span>
                  </div>


                  <div className="col span-3-of-9">
                    <input className="form-input-text form-input-full " onChange={this.handleAutoListings} value={this.state.autoListings} />
                  </div>
                </div>

                <div className="row">
                  <div className="col span-6-of-9 category-enterprise-list">
                    <span>{getTranslatedPhrase(this.props.userLanguage, 'Real Estate Listings')}</span>
                  </div>


                  <div className="col span-3-of-9">
                    <input className="form-input-text form-input-full " onChange={this.handleRealEstateListings} value={this.state.realestateListings} />
                  </div>
                </div>

                <div className="row">
                  <div className="col span-6-of-9 category-enterprise-list">
                    <span>{getTranslatedPhrase(this.props.userLanguage, 'Travel Listings')}</span>
                  </div>


                  <div className="col span-3-of-9">
                    <input className="form-input-text form-input-full " onChange={this.handleTravelListings} value={this.state.travelListings} />
                  </div>
                </div>

                <div className="row">
                  <div className="col span-6-of-9 category-enterprise-list">
                    <span>{getTranslatedPhrase(this.props.userLanguage, 'Jobs Listings')}</span>
                  </div>


                  <div className="col span-3-of-9">
                    <input className="form-input-text form-input-full " onChange={this.handleJobsListings} value={this.state.jobsListings} />
                  </div>
                </div>

                <div className="row">
                  <div className="col span-6-of-9 category-enterprise-list">
                    <span>{getTranslatedPhrase(this.props.userLanguage, 'Services Listings')}</span>
                  </div>


                  <div className="col span-3-of-9">
                    <input className="form-input-text form-input-full " onChange={this.handleServicesListings} value={this.state.servicesListings} />
                  </div>
                </div>
              </div>

              <br />

              <div className="row">
                <div className="col span-6-of-9 category-enterprise-list">
                  <span style={{ fontWeight: "bold" }}>{getTranslatedPhrase(this.props.userLanguage, 'PRICE')}</span>
                </div>


                <div className="col span-3-of-9">
                  <input className="form-input-text form-input-full " onChange={this.handlePrice} value={this.state.enterprisePrice} />
                </div>
              </div>


              <br />

              <FormFieldError>{this.state.temp.errorMessage}</FormFieldError>
              <LoadingSvg active={this.state.temp.postListingPressed} />

              <button
                className="button button-regular button-small mgt-sm dsp-in-blk"
                onClick={this.createEnterpriseAccount}>{getTranslatedPhrase(this.props.userLanguage, 'Create Enterprise Account')}
              </button>
            </div>
          </div>

          <span onClick={(e) => this.props.onClose()} className="modal_close"><i className="fal fa-times" /></span>
        </div>
      </div>
    );
  }
}

export default EnterpriseModal;