import React, { Component } from 'react';
import GroupHeader from './GroupHeader';
import GroupListingFeed from './GroupListingFeed';
import PostSomethingNew from '../../../Profile/Wall/PostSomethingNew/PostSomethingNew';
import WallListing from '../../../Profile/Wall/WallListings/WallListing';
import GroupListingSidebar from './GroupListingSidebar';

import Box from '../../../../shared/util/box';
import { hostNameAndPort } from '../../../../shared/util/vars';
import { titleCase } from '../../../../shared/util/string';
import Axios from 'axios';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import { Helmet } from "react-helmet";


class GroupsListing extends Component {
    state = {
        title: '',
        profileImage: '',
        itemId: '',
        coverImage: '',
        category: 'all',
        subcategory: 'all',
        description: '',
        privacy: 'public',
        hashTags: '',
        slug: '',
        newPosts: [],
        tabList: {},
        stats: {},
        userIsMember: false,
        current_item_id: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let p = this.props.match.params || {};
        this.setState({pageRefresh: this.props.pageRefresh, current_item_id: p.storeItem, pageViewStart: moment()});
        if (p && p.storeItem) {
            setTimeout(() => {
                this.getGroup(p.storeItem);
            }, 100);
        }
    }

    componentDidUpdate() {
        let k = this.props.pageRefresh;
        let p = this.props.match.params || {};
        if (this.state.pageRefresh !== k || this.state.current_item_id !== p.storeItem) {
            this.setState({pageRefresh: k, current_item_id: p.storeItem});
            window.scrollTo(0, 0);
            if (p && p.storeItem) {
                this.getGroup(p.storeItem);
            }
        }
    }

    handlePPVCampaignViewer = async (isOrganic, itemId) => {
        try {
            let ppvData = new FormData();
            ppvData.append('campaignId', this.props.payperviewRedirect);
            ppvData.append('itemId', itemId);
            ppvData.append('itemType', 'groups');
            let options = {
                url: `${hostNameAndPort}/api/ppv/add${isOrganic}view`,
                method: 'post',
                data: ppvData
            };
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                // Success! Nothing to do
            }
        } catch (error) {
            // Network error
        }
    }

    async getGroup(b) {
        let url = `${hostNameAndPort}/api/groups/${b}`;
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                this.setState(data.item);

                setTimeout(() => {
                    if (moment().diff(moment(this.state.pageViewStart), 'seconds') >= 5) {
                        if (!!this.props.payperviewRedirect) {
                            this.handlePPVCampaignViewer('', data.item.itemId);
                            this.props.resetPayperviewRedirect();
                        } else {
                            this.handlePPVCampaignViewer('organic', data.item.itemId);
                        }
                    }
                },5100);
            } else this.props.history.push('/404');
        } catch (err) {
            //console.log(err)
            this.props.history.push('/404');
        }
    }

    onPostNewStatus = (a) => {
        if (!!a) {
            this.setState({ newPosts: [a, ...this.state.newPosts] });
        }
    }

    onChangeTabHandler = () => {
        // tbd
    }

    render() {
        return (<Box>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{this.state.title} | Followone</title>
                <meta name="description" content={this.state.description.substring(0, 200)} />
                <link rel="canonical" href={"https://followone.com/groups/" + this.state.category + "/" + this.state.subcategory + "/" + this.state.slug} />
            </Helmet>

            <div className="group-listing-header">
                <GroupHeader
                    coverImage={this.state.coverImage}
                    profileImage={this.state.profileImage}
                    userName={this.state.title}
                    stats={this.state.stats}
                    viewCount={this.state.viewCount}
                    tabList={this.state.tabList}
                    onChangeTab={this.onChangeTabHandler}
                />
            </div>


            <div className="txtc group-body">
                <div className="profile_content">
                    {this.state.userIsMember && (
                        <PostSomethingNew
                            groupId={this.state.itemId}
                            onPostNewText={this.onPostNewStatus}
                        />)}

                    {this.state.newPosts.map((v, i) => (<WallListing noViewsButton={true} isGroupWall={true} key={i} {...v} />))}

                    <GroupListingFeed
                        privacy={this.state.privacy}
                        userIsMember={this.state.userIsMember}
                        groupId={this.state.itemId}
                    />

                </div>

                <GroupListingSidebar
                    section="groups"
                    members={this.state.members}
                    category={this.state.category}
                    itemSlug={this.state.slug}
                    itemId={this.state.itemId}
                    userIsAuthorized={this.state.userIsAuthorized}
                    userIsMember={this.state.userIsMember}
                    description={this.state.description}
                    groupDetails={{
                        'Created By': this.state.authorName,
                        'Date': moment(this.state.creationDate).local().format('ll'),
                        'Category': getTranslatedPhrase(this.props.userLanguage, titleCase(this.state.category.replace(/-/g, ' '))),
                        'Member Count': '' + this.state.memberCount
                    }}
                />
            </div>
        </Box>
        );
    }
}


const mapStateToGroupsListingProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        userIsAuthenticated: state.userIsAuthenticated,
        payperviewRedirect: state.payperviewRedirect
    };
};

export default connect(mapStateToGroupsListingProps, null)(withRouter(GroupsListing));
