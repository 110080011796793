import React, { Component } from "react";

class ListingMap extends Component {

  state = {
    a: ''
  }

  componentDidMount() {
    let a = this.props.address.trim();
    if (!a) {
      a = "0"; //Empire State Building';
    }
    a = a.replace(/\W(ste|Ste|Suite|SUITE|apt|Apt|APT|#|bldg).?\s?[a-z0-9A-Z]+/g, "");
    a = a.replace(/\s\s*/g, "+");

    this.setState({ a: a });
  }

  render() {

    let url = `https://www.google.com/maps/embed/v1/place?key=AIzaSyC3sPCT7W11tTP8LCANvOBoKe0dK08AYoI&q=${this.state.a}`

    return (
      <div className="product-listing-map">
        <div className="ProductDetailHeaderBox">
          <iframe
            frameBorder="0"
            style={{ border: "0" }}
            title="locationMap"
            src={url}
            allowFullScreen
          />
        </div>
      </div>
    );
  }
}

export default ListingMap;
