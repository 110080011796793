import React from 'react';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import PhotoPreview from './PhotoGalleryPreview';
import PhotosSideBar from './PhotosSideBar';
import PhotosSideBarMobile from './PhotosSideBarMobile';
import PhotosFeatured from './PhotosFeatured';

function PhotosCategory() {
  return (
    <CategoryPage
      section="photos"
      description="Post Your Favorite Photos or Browse Others"
      ItemPreview={PhotoPreview}
      // SideBar={PhotosSideBar}
      SideBarMobile={PhotosSideBarMobile}
      FeaturedItems={PhotosFeatured}
    />);
}

export default PhotosCategory;