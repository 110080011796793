import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import Axios from 'axios';
import { getTranslatedPhrase } from '../../../../../shared/util/lang';
import {connect} from 'react-redux';

class WallGroup extends Component {
    state = {
        groupNotFound: true,
        featuredImage: '',
        category: '',
        slug: '',
        title: 'Grab A Coffee To Go'
    }
      

    componentDidMount() {
        let b = this.props.groupId;
        if (!b) {
            this.setState({ groupNotFound: true });
            return;
        }
        let url = `${hostNameAndPort}/api/groups/${b}`;
        Axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {                
                data.item.groupNotFound = false;
                this.setState(data.item);
                let _wallAction;
                switch (this.props.activityType) {
                    case 'comment':
                    _wallAction = 'commented on a group';
                    break;
                    case 'like':
                    _wallAction = 'liked a group';
                    break;
                    case 'share':
                    _wallAction = 'shared a group';
                    break;
                    case 'post':
                    _wallAction = 'started a group';
                    break;
                    default:
                    break;
                }
                this.props.onItemLoaded({
                    itemFailedLoading: false,
                    wallAction: _wallAction,
                });
            }
            else {
                this.props.onItemLoaded({ itemFailedLoading: true });
            }
        })
        .catch(err => {
            //console.log('Network error');
            this.props.onItemLoaded({ itemFailedLoading: true });
        });
    }

    render () {
        if (this.state.groupNotFound) {
            return '';
        }

        let groupSlug = '/groups/'+this.state.category+'/'+this.state.slug;

        const pStyle = {
            marginRight: '7px'
        };
          

        return (
            <div className="category-list_item group-item-preview"> 
                <Link to={groupSlug}><img src={this.state.profileImage} alt={this.state.title} /></Link>

                <div className="group-item-preview_details">
                    <div className="group-item-preview_details_title">
                        <span>{this.state.title}</span>
                    </div> 

                    <div className="group-item-preview_details_content">
                        <div className="group-stats">
                            <span className="group-stats-members"><i className="fal fa-users" style={pStyle} /> {this.state.memberCount} </span>
                            {/* <EngageButtons>
                                <ViewsButton isClicked={false} noViews={this.state.viewCount} />
                            </EngageButtons> */}
                        </div>
                        <Link to={groupSlug}>
                            <button className="button button-clear button-small dsp-in-blk">{getTranslatedPhrase(this.props.userLanguage,'See Group')}</button>
                        </Link>
                    </div>
                </div>
            </div> 
        );
    }
}

const mapStateToWallGroupProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToWallGroupProps, null)(WallGroup);