import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';

const defaultClick = () => null;
class Followone extends Component {

    render() {
        if (!this.props.show) {
            return null;
        }

        if (!this.props.unFollowButton) {
            if (!this.props.requestIsSent) {
                return (
                    <button className="button button-small button-followone" onClick={this.props.onClick || defaultClick}><i className="fal fa-plus" />&nbsp; {getTranslatedPhrase(this.props.userLanguage, 'Follow')}<span>{getTranslatedPhrase(this.props.userLanguage, 'one')}</span></button>
                );
            } else {
                return (
                    <button className="button button-small button-followone" onClick={defaultClick}>{getTranslatedPhrase(this.props.userLanguage, 'Request Sent')}</button>
                );
            }
        }
        else {
            return (
                <button className="button button-regular button-followone" onClick={this.props.onClick || defaultClick}>{getTranslatedPhrase(this.props.userLanguage, 'Unfollow')}</button>
            );
        }
    }
}

const mapStateToFollowoneProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToFollowoneProps, null)(Followone);