import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import actionType from '../../../../redux-store/action-type';


class Referral extends React.Component {
  componentDidMount() {
    let m = this.props.match.params.userid;
    this.props.onUpdateReferredBy(m);
    this.props.history.push('/');
    this.props.showLoginModal();
  }

  render () {
    return null;
  }
}

const mapStateToReferralProps = (
  state,
  props
) => {
  return {
      userLanguage: state.userLanguage
  };
};

const mapDispatchToReferralProps = (
  dispatch
) => {
  return {
      onUpdateReferredBy: (referredBy) => {
          dispatch({type: actionType.REFERRED_BY, referredBy});
      },
      showLoginModal: () => {
        dispatch({type: actionType.LOGIN_MODAL, accountLoginModalActive: false, returnUrl: '/subscription-settings' });
        dispatch({type: actionType.ACCOUNT_TYPE_MODAL, accountTypeModalActive: true});
      },
  };
};

export default connect(mapStateToReferralProps,mapDispatchToReferralProps)(withRouter(Referral));