import React, { Component } from "react";
import { Link } from "react-router-dom";
import actionType from "../../../../redux-store/action-type";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../util/lang";

class footerCopyright extends Component {
  state = {
    userLanguage: "en_EN",
  };

  componentDidMount() {
    let cookie = document.cookie;
    if (
      cookie === undefined ||
      !/\s*userLanguage\s*=\s*.*(;\s*|$)/.test(cookie)
    ) {
      document.cookie = `userLanguage=${this.state.userLanguage};max-age=2592000000;http-only=true`;
    } else {
      let a = document.cookie;
      let v = a.substring(a.indexOf("userLanguage=") + 13);
      v = v.substring(0, v.indexOf(";")).trim();
      this.setState({ userLanguage: v });
      this.props.onUpdateUserLanguage(v);
    }
  }

  onChangeLanguage = (e) => {
    let v = e.target.value;
    let a = ["en_EN", "ro_RO", "it_IT", "ru_RU"];
    if (a.indexOf(v) < 0) {
      return;
      /**
            ar_EG  bs  de  en  fa  fil  hi_IN  hu  nb_NO  pl  ro  sq  uk  zh_CN
            bg     da  el  es  fi  fr   hr_HR  it  nl     pt  ru  sr  vi  zh_TW
            */
    }
    this.setState({ userLanguage: v });
    document.cookie = `userLanguage=${v};max-age=2592000000;http-only=true`;
    this.props.onUpdateUserLanguage(v);
  };

  render() {
    return (
      <div className="footer-copyright">
        <Link to="/privacy">
          {getTranslatedPhrase(this.state.userLanguage, "Privacy Policy")}
        </Link>
        <span>-</span>{" "}
        <Link to="/cookies">
          {getTranslatedPhrase(this.state.userLanguage, "Cookies Policy")}
        </Link>
        <span>-</span>{" "}
        <Link to="/terms-of-service">
          {getTranslatedPhrase(this.state.userLanguage, "Terms of Use")}
        </Link>
        <span>-</span>{" "}
        <Link to="/copyright">
          {getTranslatedPhrase(this.state.userLanguage, "Copyright")}
        </Link>
        <span>-</span>{" "}
        <Link to="/contact">
          {getTranslatedPhrase(this.state.userLanguage, "Contact")}
        </Link>
        <span>-</span>
        <div className="form-input-select mgl-sm dsp-in-blk">
          <select
            className="form-input-full"
            value={this.state.userLanguage}
            onChange={this.onChangeLanguage}
          >
            <option value="en_EN">English</option>
            <option value="ro_RO">Română</option>
            <option value="it_IT">Italiana</option>
            <option value="ru_RU">русский</option>
            <option value="ar_EG">عربى (Coming Soon)</option>
            <option value="bg">български (Coming Soon)</option>
            <option value="bs">Bosanski (Coming Soon)</option>
            <option value="da">Dansk (Coming Soon)</option>
            <option value="de">Deutsch (Coming Soon)</option>
            <option value="el">Ελληνικά (Coming Soon)</option>
            <option value="es">Español (Coming Soon)</option>
            <option value="fa">فارسی (Coming Soon)</option>
            <option value="fi">Suomalainen (Coming Soon)</option>
            <option value="fr">Francais (Coming Soon)</option>
            <option value="hi_IN">हिन्दी (Coming Soon)</option>
            <option value="hr_HR">Hrvatski (Coming Soon)</option>
            <option value="hu">Magyar (Coming Soon)</option>
            <option value="nb_NO">Norsk (Coming Soon)</option>
            <option value="nl">Nederlands (Coming Soon)</option>
            <option value="pl">Polskie (Coming Soon)</option>
            <option value="pt">Portuguesa (Coming Soon)</option>
            <option value="sq">Shqiptar (Coming Soon)</option>
            <option value="sr">Српски (Coming Soon)</option>
            <option value="uk">українська (Coming Soon)</option>
            <option value="vi">Tiếng Việt (Coming Soon)</option>
            <option value="zh_CN">中文 (Coming Soon)</option>
          </select>
        </div>
        <br />
        <span className="dsp-blk mgt-sm">
          ©{new Date().getFullYear()} Followone Inc., Followone Group Inc.,
          Followone Real Estate Inc. v1.420
        </span>
      </div>
    );
  }
}

const mapStateToFooterCopyrightProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
  };
};

const mapDispatchToFooterCopyrightProps = (dispatch) => {
  return {
    onUpdateUserLanguage: (v) => {
      dispatch({ type: actionType.UPDATE_USER_LANGUAGE, userLanguage: v });
    },
  };
};

export default connect(
  mapStateToFooterCopyrightProps,
  mapDispatchToFooterCopyrightProps
)(footerCopyright);
