import React, { useEffect, useState } from "react";
import ImageFeature1 from '../../../assets/static/press_img_1.jpg';
import epsLogo from './followone-logo.eps';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { hostNameAndPort } from "../../../shared/util/vars";
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Img01 from './images/pr-img-01.jpg';
import Img02 from './images/pr-img-02.jpg';
import Img03 from './images/pr-img-03.jpg';
import Img04 from './images/pr-img-04.jpg';
import Img05 from './images/pr-img-05.jpg';
import Img06 from './images/pr-img-06.jpg';
import Img07 from './images/pr-img-07.jpg';
import Img08 from './images/pr-img-08.jpg';
import Img09 from './images/pr-img-09.jpg';
import Img10 from './images/pr-img-10.jpg';
import Img11 from './images/pr-img-11.jpg';
import Img12 from './images/pr-img-12.jpg';
import Img13 from './images/pr-img-13.jpg';
import Img14 from './images/pr-img-14.jpg';

const Press = props => {

    const [items, setItems] = useState([]);
    const loggedInUser = useSelector(state => state.loggedInUser);
    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500)

    }, [])

    useEffect(() => {
        const getItems = async () => {

            const pressData = new FormData();

            pressData.append('start', 0);
            pressData.append('count', 100);
            pressData.append('filter', '{}')

            let result = await axios({ url: `${hostNameAndPort}/api/press/items`, method: 'post', data: pressData });

            let data = result.data;

            if (!data.error) {
                setItems(data.items)
            }
        }

        getItems();
    }, [])

    const onEditPress = (slug) => {
        history.push('/press/edit-press?p=' + slug)
    }

    const toggleParagraph = (e) => {
        let d = e.target.previousSibling;
        if (!!d) {
            d.className = (!d.className) ? 'press_the-release_show-less' : '';
        }
        e.target.innerHTML = (e.target.innerHTML === 'Show More') ? 'Show Less' : "Show More";
    }

    return (
        <div className="main-100 press">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Press | Followone</title>
                <meta name="description" content="Discover Followone, be the first to hear the news!" />
                <link rel="canonical" href="https://followone.com/press" />
            </Helmet>

            <div className="main-100-header">
                <h1>Press</h1>
                <p>Discover us, be the first to hear the news!</p>
            </div>

            <div className="static-page-feature">
                <img src={ImageFeature1} alt="Join our Affiliate Program" />

                <p>We always have exciting news to share! </p>
            </div>

            {/* <div className="static-section press_section-1">
                    <h2>As Seen In</h2>
                </div> */}

            {loggedInUser && loggedInUser.userLevel === 'admin' &&
                <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
                    <Link to="/press/new-press/" className="button button-regular">Add New Press</Link>
                </div>
            }

            <div className="static_section press_section-2">

                <h2>Press Releases</h2>



                {/* PRESSES FORM DB */}

                {items.map((v, i) => {

                    setTimeout(() => {
                        let myDiv = document.getElementById("pressContentDiv" + i);
                        if (myDiv) myDiv.innerHTML = v.pressContent;
                    }, 200);

                    return (
                        <div className="press_the-release">

                            <img className="pr-img" src={v.featuredImage} alt="Followone Press Release" />

                            <div className="press_the-release-box">
                                <h4>{v.title}</h4>
                                <p className="press_the-release_meta">{v.location} - {moment(v.creationDate).format('LL')}</p>

                                <div id={`pressContentDiv${i}`}>
                                </div>

                                <div className="press_the-release_contact">
                                    Media Contact<br />
                                    <span>Company Name:</span> {v.companyName}<br />
                                    <span>Contact Person:</span> {v.contactPerson}<br />
                                    <span>Email:</span> {v.contactEmail}<br />
                                    <span>Website:</span> {v.website}
                                </div>

                                {loggedInUser && loggedInUser.userLevel === 'admin' &&
                                    <button onClick={e => onEditPress(v.slug)} className="button button-regular">Edit</button>
                                }
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="static-section press_section-3">
                <h2>Guidelines of Brand Usage</h2>

                <div className="press_the-release brand-usage">
                    <p>Followone&reg; is a trademark of Followone Inc.</p>
                    <p>When mentioning us, please place an &reg; sign next to our name, as our brand name trademarked.</p>
                    <p>We are not affiliated to Followone.org in any way.</p>

                    <a href={epsLogo} className="button button-regular mgt-md dsp-in-blk">Download our Logo</a>
                </div>
            </div>
        </div >
    );
}

export default Press;