import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import Axios from 'axios';

class WallPhoto extends Component {
    state = {
        photoNotFound: true,
        featuredImage: '',
        title: 'Grab A Coffee To Go',
        slug: '',
    }

    componentDidMount() {
        let b = this.props.photoGalleryId;
        if (!b) {
            this.setState({ photoNotFound: true });
            return;
        }
        let url = `${hostNameAndPort}/api/photos/${b}`;
        Axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {                
                data.item.creationDate = (new Date(data.item.creationDate)).toDateString();
                data.item.photoNotFound = false;
                this.setState(data.item);
                let _wallAction;
                switch (this.props.activityType) {
                    case 'comment':
                    _wallAction = 'commented on a photo gallery';
                    break;
                    case 'like':
                    _wallAction = 'liked a photo gallery';
                    break;
                    case 'share':
                    _wallAction = 'shared a photo gallery';
                    break;
                    case 'post':
                    _wallAction = 'posted a photo gallery';
                    break;
                    default:
                    break;
                }
                this.props.onItemLoaded({
                    itemFailedLoading: false,
                    wallAction: _wallAction,
                });
            }
            else {
                this.props.onItemLoaded({ itemFailedLoading: true });
            }
        })
        .catch(err => {
            //console.log('Network error');
            this.props.onItemLoaded({ itemFailedLoading: true });
        });
    }

    render () {
        if (this.state.photoNotFound) {
            return null;
        }
        return (
            <div className="WallPhoto">
                <Link to={'/photos/'+this.state.category+'/'+this.state.slug}>
                    <img src={this.state.featuredImage} alt={this.state.title} />
                </Link>
            </div>
        );
    }
}

export default WallPhoto;
