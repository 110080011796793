import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { Link } from 'react-router-dom';

import ImageFeature1 from '../../../assets/static/investors_img_1.jpg';
import ImageFeature2 from '../../../assets/static/investors_img_2.jpg';
import ImageFeature3 from '../../../assets/static/investors_img_3.jpg';
import ImageFeature4 from '../../../assets/static/investors_img_4.jpg';
import ImageFeature5 from '../../../assets/static/investors_img_5.jpg';
import ImageFeature6 from '../../../assets/static/investors_img_6.jpg';
import ImageFeature7 from '../../../assets/static/investors_img_7.jpg';
import { Helmet } from "react-helmet";

const Investors = props => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    const userLanguage = useSelector(state => state.userLanguage);
    
    return (
        <div className="main-100 investors">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Investors | Followone</title>
                <meta name="description" content="Followone has the fastest growing portfolio of career communities, job seekers, and businesses, that are reaching out to communities professionally." />
                <link rel="canonical" href="https://followone.com/investors" />
            </Helmet>

                <div className="main-100-header">
                    <h1>{getTranslatedPhrase(userLanguage,'Investors')}</h1>
                    <p>{getTranslatedPhrase(userLanguage,'Followone has the fastest growing portfolio of career communities, job seekers, and businesses, that are reaching out to communities professionally.')}</p>
                </div>

                <div className="static-page-feature">
                    <img src={ImageFeature1} alt="Followone Desk" />
                    <p>{getTranslatedPhrase(userLanguage,'Followone Media offers investors the seed capital to invest in innovative businesses, and brands built by professionals. All while they watch their team grow, and also benefit, from being stake holders of growing firms. These firms grow their businesses routinely, connect and interact with professionals in their network, and promote their brand using our social media marketplace. All these things are important factors for today\'s investors.')}</p>
                </div>

                <div className="static_section investors_section-1">
                    <div>
                        <img src={ImageFeature2} alt="" />
                        <p>{getTranslatedPhrase(userLanguage,'We attract a large, diverse, audience of startups through our network.')}</p>
                    </div>

                    <div>
                        <img src={ImageFeature3} alt="" />
                        <p>{getTranslatedPhrase(userLanguage,'Our platform consists of social networking, e-commerce, and jobs listing.')}</p>
                    </div>

                    <div>
                        <img src={ImageFeature4} alt="" />
                        <p>{getTranslatedPhrase(userLanguage,'Our network is spread across the U.S, Canada, and other European countries.')}</p>
                    </div>
                </div>


                <h2>{getTranslatedPhrase(userLanguage,'Why Invest? See our Benefits')}</h2>


                <div className="static_section investors_section-2">
                    <div>
                        <img src={ImageFeature5} alt="" />
                        <p>{getTranslatedPhrase(userLanguage,'We have databases operated by Affiliate Marketers across the Globe.')}</p>
                    </div>

                    <div>
                        <img src={ImageFeature6} alt="" />
                        <p>{getTranslatedPhrase(userLanguage,'We have resources that provide us accurate data for our audiences.')}</p>
                    </div>

                    <div>
                        <img src={ImageFeature7} alt="" />
                        <p>{getTranslatedPhrase(userLanguage,'Our data allows us to precisely target our customers interests to our advertisers.')}</p>
                    </div>
                </div>

                <div className="static_section investors_section-3">
                    <p>{getTranslatedPhrase(userLanguage,'To learn more about our services or ask questions related to this service, contact our support team today.')}</p>
                    <Link to="/contact" className="button button-regular dsp-in-blk">{getTranslatedPhrase(userLanguage,'Contact Us')}</Link>
                </div>

        </div>
    );
};

export default Investors;
