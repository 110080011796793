import React, { Component } from 'react';
import ListingHeader from './components/ListingHeader';
import ListingMeta from './components/ListingMeta';
import ListingDescription from './components/ListingDescription';
import ListingSpecs from './components/ListingSpecs';
import ListingReviews from './components/ListingReviews';
import ListingComments from './components/ListingComments';
import ListingPhotos from './components/ListingPhotos';
import ListingPolicies from './components/ListingPolicies';
import ListingMap from './components/ListingMap';
import ListingSidebar from './listing-sidebar';
import Axios from 'axios';
import { connect } from 'react-redux';
import { hostNameAndPort } from '../../util/vars';
import { getTranslatedPhrase } from '../../util/lang';
import { titleCase } from '../../util/string';
import { formatPriceAndCurrency } from '../../util/number';
import moment from 'moment';
import actionType from '../../../redux-store/action-type';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';
import LoadingSvg from '../../uielements/LoadingSpinner/LoadingSvg';

class ListingDetail extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state = {
            authorSlug: '',
            specList: [],
            contact: {},
            theAddress: '',
            itemId: '',
            itemIsShared: false,
            reviews: [],
            shareCount: 0,
            shipping: '',
            description: '',
            category: 'all',
            subcategory: 'all',
            authorName: '',
            brandName: '',
            likes: [],
            averageRating: 0,
            userIsAuthorized: false,
            featuredImage: '',
            allPhotos: [],
            afterDiscountPrice: '0',
            addDiscount: '',
            clickedToComment: true,
            takeMeToReviews: true,
            title: '',
            startDate: "",
            endDate: "",
            current_item_id: "",
            navState: {
                specsIsClicked: true
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let p = this.props.match.params || {};
        this.setState({ pageRefresh: this.props.pageRefresh, current_item_id: p.storeItem, pageViewStart: moment() });
        if (p && p.storeItem) {
            setTimeout(() => {
                this.getListing(p.storeItem);
            }, 100);
        }
    }

    componentDidUpdate() {

        // console.log('history', this.props.history)

        let k = this.props.pageRefresh;
        let p = this.props.match.params || {};

        // console.log('CURRENT ITEM:', this.state.current_item_id, p.storeItem, this.state.pageRefresh, this.props.pageRefresh)
        // console.log('PROPS', this.props);

        // if (this.props.pageRefresh !== this.props.section + p.storeItem) {
        //     this.props.pageShouldRefresh(this.props.section + p.storeItem);
        // }


        if (this.state.pageRefresh !== k || this.state.current_item_id !== p.storeItem) {
            this.setState({ pageRefresh: k, current_item_id: p.storeItem });
            window.scrollTo(0, 0);
            if (p && p.storeItem) {
                this.getListing(p.storeItem);
            }
        }
        let reviewsAnchor = document.getElementById('takeMeStraightToReviews');
        if (this.props.location.hash && reviewsAnchor && this.state.takeMeToReviews) {
            let aw = document.createElement('a');
            aw.href = this.props.location.hash;
            aw.click();
            this.setState({ takeMeToReviews: false });
        }
    }

    handlePPVCampaignViewer = async (isOrganic, itemId) => {
        try {
            let ppvData = new FormData();
            ppvData.append('campaignId', this.props.payperviewRedirect);
            ppvData.append('itemId', itemId);
            ppvData.append('itemType', this.props.section);
            let options = {
                url: `${hostNameAndPort}/api/ppv/add${isOrganic}view`,
                method: 'post',
                data: ppvData
            };
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                // Success! Nothing to do
                // console.log('success')
            } //else console.log(data.error)
        } catch (error) {
            // console.log(error)
            // Network error
        }
    }


    getListing = async (b) => {
        let url = `${hostNameAndPort}/api/${this.props.section}/${b}`;
        try {
            let result = await Axios(url);
            let { data } = result;

            // console.log('I received: ', data);

            if (!data.error) {
                data.item.creationDate = (new Date(data.item.creationDate)).toDateString();
                if (data.item.brandName === 'null') data.item.brandName = '';
                if (!data.item.specList) {
                    data.item.specList = [];
                }
                let moreSpecs = data.item.moreSpecs || [];
                if (this.props.section === 'autos' && data.item.category === 'cars') {
                    moreSpecs = [{
                        specName: 'Year',
                        specValue: data.item.autoYear
                    }, {
                        specName: 'Make',
                        specValue: titleCase(data.item.autoMake.replace(/-/g, ' '))
                    }, {
                        specName: 'Model',
                        specValue: data.item.autoModel
                    }];
                    if (!!data.item.autoTrim) {
                        moreSpecs.push({
                            specName: 'Trim',
                            specValue: data.item.autoTrim
                        });
                    }
                } else if (this.props.section === 'autos') {
                    data.item.specList.splice(0, 5);
                    data.item.specList.push({
                        specName: 'Sold By',
                        specValue: titleCase(data.item.seller)
                    });
                }
                if (this.props.section === 'realestate' && data.item.specList.length > 1) {
                    if (data.item.bathCount && !!data.item.bathCount && data.item.bathCount !== '0')
                        data.item.specList[1].specValue = data.item.bathCount;
                    if (data.item.bedCount && !!data.item.bedCount && data.item.bedCount !== '0')
                        data.item.specList[0].specValue = data.item.bedCount;
                }
                if (['events', 'travel'].indexOf(this.props.section) >= 0) {
                    moreSpecs.push({
                        specName: 'Start Date',
                        specValue: moment(data.item.startDate).format('LL')
                    });
                    moreSpecs.push({
                        specName: 'End Date',
                        specValue: moment(data.item.endDate).format('LL')
                    });
                }
                data.item.specList = data.item.specList.map(v => ({ specName: v.specName, specValue: (v.specValue === true) ? 'Yes' : v.specValue }))
                data.item.specList = [...data.item.specList, ...moreSpecs];
                data.item.allPhotos = [data.item.featuredImage, ...data.item.extraPhotos];
                let theAddress = '';
                let a = data.item;
                if (!!a['address']) {
                    theAddress = a['address'] + ',';
                }
                if (!!a['unit']) {
                    theAddress = theAddress + ' #' + a['unit'] + ',';
                }
                if (!!a['city']) {
                    theAddress = theAddress + ' ' + a['city'];
                }
                if (!!a['state'] && a['state'] !== 'n/a') {
                    theAddress = theAddress + ' ' + a['state'];
                }
                if (!!a['zipCode']) {
                    theAddress = theAddress + ', ' + a['zipCode'];
                }
                if (!!a['country']) {
                    theAddress = theAddress + ', ' + a['country'];
                }
                data.item.theAddress = theAddress;
                data.item.price = Number(data.item.price) / 100;
                if (data.item.discountId) {
                    if (moment(data.item.discountId.startDate).isAfter(moment(), 'days') || moment(data.item.discountId.endDate).isBefore(moment(), 'days')) {
                        data.item.addDiscount = '';
                        data.item.discountId = '';
                    } else if (data.item.discountId.couponCurrency !== '%') data.item.discountId.couponValue = Number(data.item.discountId.couponValue) / 100;
                }
                data.item.current_item_id = b;
                data.item.pageViewStart = moment();
                this.setState(data.item);
                // let p = data.item.price;
                // let c = data.item.currency;
                // let d = data.item.discountId;
                // setTimeout(() => {
                //     this.computeAfterDiscountPrice(p, c, d);
                // },200)
                this.computeAfterDiscountPrice(data.item.price, data.item.currency, data.item.discountId);

                setTimeout(() => {
                    if (moment().diff(moment(this.state.pageViewStart), 'seconds') >= 5) {
                        if (!!this.props.payperviewRedirect) {
                            this.handlePPVCampaignViewer('', data.item.itemId);
                            this.props.resetPayperviewRedirect();
                        } else {
                            this.handlePPVCampaignViewer('organic', data.item.itemId);
                        }
                    }
                }, 5100);

            } else this.props.history.push('/404');
        } catch (err) {
            // console.log('Network error',err);
            this.props.history.push('/404');
        }
    }

    computeAfterDiscountPrice = (p, c, d) => {
        // let c = s.currency;
        // let p = s.price;
        // let d = s.addDiscount;
        if (!!d) {
            let theDiscount = d.couponValue;
            if (!d.couponCurrency || d.couponCurrency === '%') {
                p = Math.round((p - p * theDiscount / 100) * 100) / 100;
            } else {
                // p = p - Number(theDiscount);
                // theDiscount = theDiscount / 100;
                p = Math.round((p - Number(theDiscount)) * 100) / 100;
            }
            if (p > 0) {
                this.setState({ afterDiscountPrice: formatPriceAndCurrency(p, c, ',') });
            } else {
                this.setState({ afterDiscountPrice: '0' });
            }
        } else {
            this.setState({ afterDiscountPrice: '' });
        }
    }

    onChangeTab = (t) => {
        this.setState(t);
    }

    getCouponCode = (v, cc, pc) => {
        if (cc !== '%') {
            return formatPriceAndCurrency(v, pc, ',');
        }
        return v + cc;
    }

    onAddReview = (rating) => {
        let reviewCount = this.state.reviewCount + 1;
        let averageRating = (this.state.averageRating * (reviewCount - 1) + rating) / reviewCount;
        this.setState({ averageRating, reviewCount });
    }

    resetClickToComment = () => {
        this.setState({ clickedToComment: !this.state.clickedToComment });
    }

    render() {
        if (!this.state.title) return (<LoadingSvg active={true} />);

        let eventDetails = {};

        if (this.props.section === 'events') {
            eventDetails = {
                EventAddress: this.state.theAddress,
                buildingName: this.state.buildingName,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                startTime: this.state.startTime,
                endTime: this.state.endTime
            };
        }

        if (this.state.brandName) {
            this.state.specList.unshift({ specName: 'Brand Name', specValue: this.state.brandName });
        }

        // console.log(this.state);

        return (

            <>

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.title} | Followone</title>
                    <meta name="description" content={this.state.description.substring(0, 200)} />
                    <link rel="canonical" href={"https://followone.com/" + this.props.section + "/" + this.state.category + "/" + this.state.subcategory + "/" + this.state.slug} />
                </Helmet>


                <div className="listing-body">

                    <div className="main-container store-product">

                        <ListingHeader
                            address={this.state.theAddress}
                            photos={this.state.allPhotos}
                            websiteSection={this.props.section}
                            authorId={this.state.authorId}
                            productCondition={this.state.productCondition}
                            itemId={this.state.itemId}
                            pickUpLocation={this.state.pickUp === 'sell-local' ? 'buy-local' : this.state.pickUp}
                            pickUp={this.state.pickUp}
                            productCost={this.state.price}
                            numberToCall={this.state.numberToCall}
                            callForPricing={this.state.callForPricing}
                            afterDiscountPrice={this.state.afterDiscountPrice}
                            productCurrency={this.state.currency}
                            productRepeat={this.state.rentOrSell && this.state.rentOrSell === 'rent' ? 'month' : ''}
                            bedCount={this.state.bedCount}
                            bathCount={this.state.bathCount}
                            rentOrSell={this.state.rentOrSell}
                            category={this.state.category}

                            websiteSection={this.props.section}
                            shareItemId={this.state.itemId}
                            itemIsShared={this.state.itemIsShared}
                            shareCount={this.state.shareCount}
                            viewCount={this.state.viewCount}
                            authorId={this.state.authorId}
                            productDetailTitle={this.state.title}
                            brandName={this.state.brandName}
                            authorSlug={this.state.authorSlug}
                            authorName={this.state.authorName}
                            authorProfileImage={this.state.authorProfileImage}
                            categoryName={this.state.category}
                            subcategory={this.state.subcategory || 'other'}
                            PropertyDetailAddress={this.state.theAddress}
                            noLove={this.state.likeCount}
                            productIsLoved={this.state.itemIsLiked}
                            productStars={Math.round(this.state.averageRating)}

                            reviewCount={this.state.reviewCount}
                            sellerPhone={this.state.numberToCall ? this.state.numberToCall : this.state.contact['Customer Service Phone #']}
                            sellerEmail={this.state.sellerEmail ? this.state.sellerEmail : this.state.contact['Customer Service Email']}
                            userIsAuthenticated={this.props.userIsAuthenticated}
                            pickUpLocation={this.state.pickUp === 'sell-local' ? 'buy-local' : this.state.pickUp}
                            ticketSaleURL={this.state.ticketSaleURL}
                            buttonLabel="Buy now"
                            numberToCall={this.state.numberToCall}
                            onChangeTab={this.onChangeTab}
                            pickUp={this.state.pickUp}
                            {...eventDetails}
                        />

                        {/* <ListingNav onChangeTab={this.onChangeTab} 
                        noPhotos={this.state.allPhotos.length} 
                        noReviews={this.state.reviews.length} showPolicies={true} customStart={'specs'} /> */}

                        <div className="product-main-details">

                            {this.state.pickUp !== 'sell-online' &&
                                <ListingMeta
                                    websiteSection={this.props.section}
                                    shareItemId={this.state.itemId}
                                    itemIsShared={this.state.itemIsShared}
                                    shareCount={this.state.shareCount}
                                    viewCount={this.state.viewCount}
                                    authorId={this.state.authorId}
                                    productDetailTitle={this.state.title}
                                    brandName={this.state.brandName}
                                    authorSlug={this.state.authorSlug}
                                    authorName={this.state.authorName}
                                    authorProfileImage={this.state.authorProfileImage}
                                    categoryName={this.state.category}
                                    subcategory={this.state.subcategory || 'other'}
                                    productCost={this.state.price}
                                    productRepeat={this.state.rentOrSell && this.state.rentOrSell === 'rent' ? 'month' : ''}
                                    afterDiscountPrice={this.state.afterDiscountPrice}
                                    PropertyDetailAddress={this.state.theAddress}
                                    productCurrency={this.state.currency}
                                    callForPricing={this.state.callForPricing}
                                    noLove={this.state.likeCount}
                                    address={this.state.theAddress}
                                    productIsLoved={this.state.itemIsLiked}
                                    productStars={Math.round(this.state.averageRating)}
                                    reviewCount={this.state.reviewCount}
                                    sellerPhone={this.state.numberToCall ? this.state.numberToCall : this.state.contact['Customer Service Phone #']}
                                    sellerEmail={this.state.sellerEmail ? this.state.sellerEmail : this.state.contact['Customer Service Email']}
                                    userIsAuthenticated={this.props.userIsAuthenticated}
                                    pickUpLocation={this.state.pickUp === 'sell-local' ? 'buy-local' : this.state.pickUp}
                                    ticketSaleURL={this.state.ticketSaleURL}
                                    buttonLabel="Buy now"
                                    numberToCall={this.state.numberToCall}
                                    onChangeTab={this.onChangeTab}
                                    pickUp={this.state.pickUp}
                                    {...eventDetails}
                                />
                            }
                            {!this.state.callForPricing && !!this.state.addDiscount && this.state.addDiscount === 'coupon' && (<>
                                <div className="listing-coupon">
                                    <div className="coupon-code-text-box">
                                        <span>
                                            {getTranslatedPhrase(this.props.userLanguage, 'SAVE')}
                                            {this.getCouponCode(this.state.discountId.couponValue, this.state.discountId.couponCurrency, this.state.currency)}
                                        </span>
                                        <br />
                                        {this.state.addDiscount === 'coupon' && getTranslatedPhrase(this.props.userLanguage, 'Coupon Code') + ' :'}

                                    </div>

                                    <div className="coupon-code-box">
                                        <p className="listing-coupon-code">
                                            <span className="listing-main-code">
                                                {this.state.addDiscount === 'coupon' && (<>{this.state.discountId.title}<br /></>)}
                                                <span className="listing-coupon-terms">{moment(this.state.discountId.endDate).isAfter(moment("2190-01-01")) ? getTranslatedPhrase(this.props.userLanguage, 'No expiration date') : getTranslatedPhrase(this.props.userLanguage, 'Ends') + ": " + moment(this.state.discountId.endDate).format('L')}</span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="listing-coupon-divider" />
                            </>)}


                            {/*{!this.state.callForPricing && !!this.state.addDiscount && this.state.addDiscount === "deal" &&  (*/}
                            {/*  <>*/}
                            {/*      <div className="deal-listing-page-box">*/}
                            {/*          <p>*/}
                            {/*            {moment(this.state.discountId.endDate).isAfter(moment("2190-01-01"))*/}
                            {/*              ? getTranslatedPhrase(this.props.userLanguage, 'Deal expires at the discretion of the seller.')*/}
                            {/*              : getTranslatedPhrase(this.props.userLanguage, 'Discount valid through: ')+ ": " + moment(this.state.discountId.endDate).format('L')}*/}
                            {/*          </p>*/}
                            {/*      </div>*/}
                            {/*  </>)}*/}

                            <ListingSpecs specList={this.state.specList} show={true} />

                            <ListingDescription
                                productDescription={this.state.description}
                                show={true}
                                addDiscount={this.state.addDiscount}
                                callForPricing={this.state.callForPricing}
                                discountId={this.state.discountId} />
                        </div>

                        <ListingPhotos photoList={this.state.allPhotos} show={true} />

                        {this.props.section !== 'realestate' &&
                            <>
                                <a href="#reviews" id="takeMeStraightToReviews" name="reviews" title="Reviews" ><span style={{ display: "none" }}>Go To Reviews</span></a>
                                {(this.props.section === 'events') ? (
                                    <ListingComments
                                        show={true}
                                        userIsAuthenticated={this.props.userIsAuthenticated}
                                        websiteSection="events"
                                        itsTimeToFocus={this.state.clickedToComment}
                                        clickedToComment={this.state.clickedToComment}
                                        resetClickToComment={this.resetClickToComment}
                                        itemId={this.state.itemId}
                                        authorId={this.state.authorId}
                                    />
                                ) : (
                                    <ListingReviews
                                        reviewsList={this.state.reviews}
                                        show={true}
                                        websiteSection={this.props.section}
                                        itemId={this.state.itemId}
                                        authorId={this.state.authorId}
                                        onUpdateReviews={this.onAddReview}
                                    />
                                )}
                            </>
                        }

                        {this.props.section !== 'realestate' && this.props.section !== 'travel' && <ListingPolicies policyUserId={this.state.authorId} show={true} />}

                        {this.state.pickUp !== 'sell-online' &&
                            <div className="Listing-Mobile-Map">
                                <ListingMap address={this.state.theAddress} />
                            </div>
                        }
                    </div>

                    <ListingSidebar
                        section={this.props.section}
                        category={this.state.category}
                        itemSlug={this.state.slug}
                        itemId={this.state.itemId}
                        authorId={this.state.authorId}
                        authorSlug={this.state.authorSlug}
                        authorName={this.state.authorName}
                        authorProfileImage={this.state.authorProfileImage}
                        likeList={this.state.likes}
                        createNewTitle={this.props.createNewTitle}
                        userIsAuthorized={this.state.userIsAuthorized}
                    />

                </div>

            </>

        );
    }
}

const mapStateToListingDetailProps = (
    state,
    props
) => {
    return {
        userIsAuthenticated: state.userIsAuthenticated,
        pageRefresh: state.pageRefresh,
        userLanguage: state.userLanguage,
        payperviewRedirect: state.payperviewRedirect
    };
};

const mapDispatchToListingDetailProps = (
    dispatch
) => {
    return {
        resetPayperviewRedirect: () => {
            dispatch({ type: actionType.PAYPERVIEW_REDIRECT, payperviewRedirect: false });
        },
        pageShouldRefresh: (p) => {
            dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
    };
};

export default connect(mapStateToListingDetailProps, mapDispatchToListingDetailProps)(withRouter(ListingDetail));