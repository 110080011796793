import React, {Component} from 'react';
import footerBrandingLogo from './images/followone-footer-branding.png';
import FooterAppDownload from '../FooterAppDownload/FooterAppDownload';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';

class footerBranding extends Component {
    render() {
        return (
        <div className="footer-branding">
            <img src={footerBrandingLogo} alt={getTranslatedPhrase(this.props.userLanguage,'Followone - be the one')}  className="footer-branding-logo" />

            <FooterAppDownload />
        </div>
        ); 
    }
}

const mapStateToFooterAppBrandingProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToFooterAppBrandingProps, null)(footerBranding); 