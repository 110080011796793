import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';

class GroupsFeaturedPreview extends Component {
    render() {
        let v = this.props;
        return (
        <div className="featured-item featured-groups">
            <Link className="featured-groups_image" to={'/groups/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}><img src={v.profileImage} alt={v.title} /></Link>

            <Link className="featured-groups_details" to={'/groups/'+v.category+'/'+(v.subcategory || 'other')+'/'+v.slug}>
                <span>{v.memberCount} {getTranslatedPhrase(v.userLanguage,'Members')}</span>
                <h3>{v.title}</h3>
            </Link>
        </div>
        );
    }
}


const mapStateToGroupsFeaturedPreviewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToGroupsFeaturedPreviewProps, null)(GroupsFeaturedPreview);
