import React, { useState, useEffect } from 'react';
import LayoutSideBar from '../../util/layout-sidebar';
import CreateNewButton from '../../components/SideBar/CreateNewButton/CreateNewButton';
import OtherItems from '../../components/SideBar/ProductsHolder/ProductsHolder';
import Axios from 'axios';
import { hostNameAndPort } from '../../util/vars';
import UserList from '../../components/SideBar/UserList/UserList';
import CouponsSideBar from '../../components/SideBar/CouponsSideBar/CouponsSideBar';
import ProfileInfoBox from '../../components/SideBar/ProfileInfoBox/ProfileInfoBox';
import BrandLogo from '../../components/SideBar/BrandLogo/BrandLogo';
import AdminPanel from '../../components/SideBar/AdminPanel/AdminPanel';



function ListingSideBar(props) {
  let [itemList, setItemList] = useState([]);
  let [profileDetails, setProfileDetails] = useState({});
  let [toggleSidebar, setToggleSidebar] = useState(false);

  async function getOtherItems() {
    try {
      let k = props.authorId;
      if (!k) return;
      let itemListParams = new FormData();
      if (props.section === 'photos')
        itemListParams.append('filter', JSON.stringify({ author: k, isGallery: true }));
      else itemListParams.append('filter', JSON.stringify({ author: k }));
      itemListParams.append('start', 0);
      itemListParams.append('count', 3);
      let options = {
        url: `${hostNameAndPort}/api/${props.section}/items`,
        method: 'post',
        data: itemListParams
      };
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        let a = data.items.filter(v => v.itemId !== props.itemId);
        a = a.map(v => ({...v, originalWebsiteSection: props.section}));
        if (a.length > 2) {
          a.pop();
        }
        setItemList(a);
      }
    } catch (err) {
      // Error
    }
  }

  useEffect(() => {
    getOtherItems();
  }, [props.itemId]);

  async function getContactInfo() {
    let u = props.authorId;
    if (!u) {
      return;
    }
    let url = `${hostNameAndPort}/api/user/contact/${u}/${props.section}`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        setProfileDetails(data.contact);
      }
    } catch (err) {
      // Network error
    }
  }

  useEffect(() => {
    getContactInfo();
  }, [props.authorId]);

  function onToggleSidebar() {
    setToggleSidebar(true);
    setTimeout(() => {
      setToggleSidebar(false);
    }, 500);
  }

  let {
    category = 'all',
    createNewTitle = 'New Listing',
    likeList = []
  } = props;

  return (<LayoutSideBar toggleSidebar={toggleSidebar}>

    <AdminPanel
      itemType={props.section}
      itemSlug={props.itemSlug}
      description={props.description}
      itemId={props.itemId}
      isFromCarsDB={props.isFromCarsDB || false}
      userIsAuthorized={props.userIsAuthorized}
    />

 
    <BrandLogo
      BrandLogoImg={(<img src={props.authorProfileImage} alt={props.authorName} />)}
      BrandLogoName={props.authorName}
      BrandURL={'/' + props.authorSlug}
    />

    <ProfileInfoBox
      infoBoxTitle="About The Author"
      profileDetails={profileDetails}
    />

    <CreateNewButton
      section={props.section}
      title={createNewTitle}
    />

    <UserList
      UserListTitle="Who Liked This"
      userList={likeList}
      onToggleSidebar={onToggleSidebar}
    />

    <OtherItems
      title="Other Items"
      items={itemList}
      section={props.section}
      onToggleSidebar={onToggleSidebar}
    />

    <CouponsSideBar
      section={props.section}
      category={category}
    />



  </LayoutSideBar>);
}

export default ListingSideBar;