import React, { Component } from 'react';
import EngageWithComments from '../../../../shared/uielements/Engage/EngageBox';

import WallBlog from './WallBlog/WallBlog';
import WallVideo from './WallVideo/WallVideo';
import WallPhoto from './WallPhoto/WallPhoto';
import WallText from './WallText/WallText';
import WallProduct from './WallProduct/WallProduct';
import WallProperty from './WallProperty/WallProperty';
import WallService from './WallService/WallService';
import WallEvent from './WallEvent/WallEvent';
import WallGroup from './WallGroup/WallGroup';
import WallTravel from './WallTravel/WallTravel';
import WallAuto from './WallAuto/WallAuto';
import WallJobPosting from './WallJobPosting/WallJobPosting';

import { Link } from 'react-router-dom';
import { howRecentIsThis } from '../../../../shared/util/date';
import { hostNameAndPort } from '../../../../shared/util/vars';
import Axios from 'axios';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../../shared/util/lang';


class WallListing extends Component {
    state = {
        listingToShow: '',
        wallAction: '',
        authorName: '',
        activitySlug: '',
        activityUserId: '',
        activityUserName: '',
        activityUserProfileImage: `/uploads/default/default-profile-image.jpg`,
        activityUserAccountType: 'personal',
        activityDate: '',
        itemId: '',
        userId: '',
        hideCommentsButton: false
    }

    componentDidMount() {
        let a = this.props.itemId;
        let b = this.props.userId;
        // if (!!a && this.state.itemId !== a) {
            this.setState({ itemId: a, userId: b });
            this.setListingToShow();
            this.getUser();
        // }
    }

    componentDidUpdate() {
        let a = this.props.itemId;
        let b = this.props.userId;
        if (this.state.itemId !== a || this.state.userId !== b) {
            this.setState({ itemId: a, userId: b });
            this.setListingToShow();
            this.getUser();
        }
    }

    setListingToShow = () => {
        let listingToShow;
        let hideCommentsButton = false;
        switch (this.props.itemType) {
            case 'blogs':            
            listingToShow = (<WallBlog activityType={this.props.activityType} blogId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'photos':
            listingToShow = (<WallPhoto activityType={this.props.activityType} photoGalleryId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'videos':
            listingToShow = (<WallVideo activityType={this.props.activityType} videoId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'texts':
            listingToShow = (<WallText isGroupWall={!!this.props.isGroupWall} activityType={this.props.activityType} textId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'store': 
            hideCommentsButton = true;
            listingToShow = (<WallProduct activityType={this.props.activityType} storeItemId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'autos': 
            hideCommentsButton = true;
            listingToShow = (<WallAuto activityType={this.props.activityType} storeItemId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'realestate':
            hideCommentsButton = true;
            listingToShow = (<WallProperty activityType={this.props.activityType} realestateId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'jobs':
            hideCommentsButton = true;
            listingToShow = (<WallJobPosting activityType={this.props.activityType} jobpostingId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'services':
            hideCommentsButton = true;
            listingToShow = (<WallService activityType={this.props.activityType} serviceId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'events':
            listingToShow = (<WallEvent activityType={this.props.activityType} eventId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'groups':
            hideCommentsButton = true;
            listingToShow = (<WallGroup activityType={this.props.activityType} groupId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'travel':
            hideCommentsButton = true;
            listingToShow = (<WallTravel activityType={this.props.activityType} travelId={this.props.itemId} onItemLoaded={this.getItemMeta} />);
                break;
            case 'users':
                // Talk to Felix about this
                break;
            default:
                listingToShow = null;
                break;
        }
        this.setState({ listingToShow, hideCommentsButton });
    }

    getUser = async () => {
        let u = this.props.userId;
        if (!u) return;
        try {
            let _data = new FormData();
            _data.append('id',u);
            let options =  {
                url: hostNameAndPort+'/api/user/whoami',
                method: 'post',
                data: _data
            };
            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                this.setState({
                    activityUserId: u,
                    activitySlug: data.user.slug,
                    activityUserName: data.user.userName,
                    activityUserProfileImage: data.user.profileImage,
                    //activityUserAccountType: data.user.accountType,
                    activityDate: this.props.activityDate
                });
            }
        } catch(err) {
            // Network error
        }
    }

    getItemMeta = (s) => {
        this.setState(s);
    }

    render () {
        if (!this.props.itemId || !this.state.listingToShow || !!this.state.itemFailedLoading) {
            return '';
        }
        return (
            <div className="WallListing">
                <header>
                    <div className="WallListingUserProfileImage">
                        <Link to={'/'+this.state.activitySlug}>
                            <img src={this.state.activityUserProfileImage} alt={this.state.activityUserName}/>
                        </Link>
                    </div>
                    <div className="left">
                        <p className="WallListingUserInfo"><strong>{this.state.activityUserName}</strong> {getTranslatedPhrase(this.props.userLanguage,this.state.wallAction)} </p>
                        <p className="WallListingMeta">
                        {howRecentIsThis(this.state.activityDate,this.props.userLanguage)}
                        {/*} {(this.state.wallListingCategory)?'in ':''}
                        {(this.state.wallListingCategory)?(<a>{this.state.wallListingCategory}</a>):''} */}
                        </p>
                    </div>
                    <div className="clb" />
                </header>

                <div className="WallListingContent">
                    {this.state.listingToShow}
                </div>
 
                <footer>
                    <EngageWithComments 
                        itemId={this.props.itemId}
                        websiteSection={this.props.itemType} 
                        authorId={this.props.itemAuthorId}
                        withComments={!this.state.hideCommentsButton}
                        noViewsButton={this.props.noViewsButton}
                        noFavoritesButton={this.props.itemType==='text'}
                    />
                </footer>
            </div>
        );
    }
}


const mapStateToWallListingProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToWallListingProps, null)(WallListing);
