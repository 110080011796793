import React, { useEffect, useState } from 'react';
import SectionLoader from '../../../shared/util/section-loader';
import { useSelector, connect } from 'react-redux';
import { withRouter } from 'react-router';
import { hostNameAndPort } from '../../../shared/util/vars';
import Axios from 'axios';
import LoadingSvg from '../../../shared/uielements/LoadingSpinner/LoadingSvg';
import PageNumbers from '../../../shared/uielements/Pagination/page-numbers';
import actionType from '../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { currencyList } from '../../../shared/util/string';


function ItemList(props) {
  const [items, setItems] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [itemListLoaded, setItemListLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const filter = { noDistance: 1 };
  const [filterHasChanged, setFilterHasChanged] = useState(false);
  const [Loader, setLoader] = useState(null);
  const [categories, setCategories] = useState(null);
  let [section, setSection] = useState(new Array(18));
  const [category, setCategory] = useState(new Array(18));
  const [subcategory, setSubcategory] = useState(new Array(18));
  let [categoryList, setCategoryList] = useState(new Array(18).fill([]));
  let [subcategoryList, setSubcategoryList] = useState(new Array(18).fill([]));
  const [categoriesStartedLoading, setCategoriesStartedLoading] = useState(false);
  const [price, setPrice] = useState(new Array(18));
  const [currency, setCurrency] = useState(new Array(18));
  const [randomNumber, setRandomNumber] = useState('0.0123234333322');
  const SECTIONS = ['store', 'food', 'autos', 'realestate', 'jobs', 'services', 'travel', 'blogs', 'photos', 'videos', 'events', 'groups'];
  const loggedInUser = useSelector(state => state.loggedInUser);


  function initLoader(page, formParams) {
    setLoader(new SectionLoader({
      section: 'admin/fetch-items',
      cb: onLoad,
      options: {
        page,
        pageCount: 18,
        formParams
      }
    }));
  }

  function onLoad(err) {
    if (err && (process.env.NODE_ENV === 'development')) console.log(err);
    window.scrollTo(0, 0);
    // if (Loader && Loader.totalCount) setItemCount(Loader.totalCount);
    // if (Loader && Loader.items) setItems([...Loader.items]);
    setItemListLoaded(true);
  }

  async function getCategories() {
    try {
      if (categoriesStartedLoading) return;
      let cl = {};
      for (let i=0;i<SECTIONS.length;i++) {
        let s = SECTIONS[i];
        let url = `${hostNameAndPort}/api/${s}/categories`;
        let result = await Axios(url);
        let { data } = result;
        if (!data.error) {
          cl[s] = [...data.categories];
        }
      }
      setCategories(cl);
    } catch (err) {
      // server, network error
    }
  }

  // We fill in the categories and subcategories
  let categoriesLength = categories && typeof categories === 'object' && Object.keys(categories).length;
  let itemsLength = items && items.length;
  useEffect(() => {
    if (categoriesLength === SECTIONS.length && itemsLength) {
      let _section = new Array(itemsLength);
      let _category = new Array(itemsLength);
      let _subcategory = new Array(itemsLength);
      let _categoryList = new Array(itemsLength);
      let _price = new Array(itemsLength);
      let _currency = new Array(itemsLength);
      let _subcategoryList = new Array(itemsLength);
      for (let i=0;i<itemsLength;i++) {
        _section[i] = items[i].section;
        _category[i] = items[i].category;
        _subcategory[i] = items[i].subcategory;
        _price[i] = items[i].price;
        _currency[i] = items[i].currency;
        _categoryList[i] = categories[items[i].section].filter(v => (!/^\s*-/g.test(v)));

        let scl = [];
        let j = 0;
        if (!categories) continue;
        while (j < categories[items[i].section].length && categories[items[i].section][j] !== items[i].category) j++;
        j++;
        while (j < categories[items[i].section].length && /^\s*-/g.test(categories[items[i].section][j])) {
          scl.push(categories[items[i].section][j]);
          j++;
        }
        _subcategoryList[i] = scl;
      }
      setSection(_section);
      setCategory(_category);
      setPrice(_price);
      setCurrency(_currency);
      setSubcategory(_subcategory);
      setCategoryList(_categoryList);
      setSubcategoryList(_subcategoryList);
    }
  }, [categoriesLength, itemsLength])

  useEffect(() => {
    // Get the current page
    const urlParams = new URLSearchParams(window.location.search);
    const currentPageLocal = Number(urlParams.get('page')) || 1;

    if (!categories) {
      setCategoriesStartedLoading(true);
      getCategories();
    }
    let formParams = {
      filter: JSON.stringify(filter),
    };
    if (filterHasChanged) {
      setSection(new Array(18));
      setCategory(new Array(18));
      setSubcategory(new Array(18));
      setPrice(new Array(18));
      setCategoryList(new Array(18).fill([]));
      setSubcategoryList(new Array(18).fill([]));
      setItemCount(0);
      setItems([]);
      if (!Loader) {
        initLoader(currentPageLocal,formParams);
        return;
      }
      Loader.updateFormParams(formParams);
      setFilterHasChanged(false);
    } else if (currentPageLocal !== currentPage) {
      setCurrentPage(currentPageLocal);
      setItemListLoaded(false);
      setSection(new Array(18));
      setCategory(new Array(18));
      setSubcategory(new Array(18));
      setPrice(new Array(18));
      setCategoryList(new Array(18).fill([]));
      setSubcategoryList(new Array(18).fill([]));
      setItemCount(0);
      setItems([]);
      if (!Loader) {
          initLoader(currentPageLocal,formParams);
          return;
      }
      Loader.updatePage(currentPageLocal);
    }

  }, [loggedInUser, currentPage, filter, randomNumber]);

  function onUpdatePageNumber(n = 1) {
    props.history.push(props.match.url + '?page=' + n);
    setRandomNumber(Math.random());
  }

  function onChangeSection(nextSection, i) {
    let s = section;
    s[i] = nextSection;
    setSection(s);
    if (!categories) return;
    let c = categoryList;
    c[i] = categories[nextSection].filter(v => (!/^\s*-/g.test(v)));
    setCategoryList(c);
    setRandomNumber(Math.random());
  }

  function onChangeCategory(nextCategory, i) {
    let c = category;
    c[i] = nextCategory;
    setCategory(c);
    let scl = [];
    let j = 0;
    if (!section[i] || !categories) return;
    while (j < categories[section[i]].length && categories[section[i]][j] !== nextCategory) j++;
    j++;
    while (j < categories[section[i]].length && /^\s*-/g.test(categories[section[i]][j])) {
      scl.push(categories[section[i]][j]);
      j++;
    }
    let a = subcategoryList;
    a[i] = scl;
    setSubcategoryList(a);
    setRandomNumber(Math.random());
  }

  function onChangeSubcategory(nextSubcategory, i) {
    let sc = subcategory;
    sc[i] = nextSubcategory;
    setSubcategory(sc);
    setRandomNumber(Math.random());
  }

  function onEditItem(websiteSection, slug) {
    let a = '/';
        let z = websiteSection;
        switch (z) {
            case 'blogs':
            case 'photos':
            case 'videos':
            case 'events':
            case 'groups':
            case 'services':
            case 'jobs':
            a = a + z + '/edit-' +z.slice(0,-1);
            break;
            case 'store':
            a = '/store/edit-product';
            break;
            case 'food':
            a = '/food/edit-food-listing';
            break;
            case 'autos':
            a = '/autos/edit-auto-listing';
            break;
            case 'realestate':
            a = '/realestate/edit-property';
            break;
            case 'travel':
            a = '/travel/edit-travel';
            break;
            default:
            break;
        }
        let s = '?q=' + slug;
        window.open(window.location.origin+a+s, 'otherWindow' + Date.now());
        // props.history.push({ pathname: a, search: s});
  }

  function onSaveItem(index) {
    let item = items[index];
    let oldSection = item.section;
    item.section = section[index];
    item.category = category[index];
    item.subcategory = subcategory[index];
    item.currency = currency[index];
    if (!!price[index]) {
      item.price = String(price[index]).replace(/,/g,'').replace('.','');
    }
    item.itemId = item._id;
    
    // delete item._id;
    delete item.fullAddress;
    
    if(oldSection !== item.section) {
      delete item.itemId;

      Axios.delete(hostNameAndPort + '/api/' + oldSection + '/' + item.slug)
      .then(result => {
        let {data} = result;
        if (!data.error) {
          // no error
        } else props.showAlertModal(getTranslatedPhrase(props.userLanguage, data.error));
      })
      .catch(console.log)
    }

    let discountProps = {
      discountCurrency: item.discountId.couponCurrency,
      discountValue: item.discountId.couponValue,
      startDate: item.discountId.startDate,
      endDate: item.discountId.endDate
    };

    item.discountProps = JSON.stringify(discountProps);
    item.specList = JSON.stringify(item.specList || []);
    if (!!item.address) item.newAddress = 'new-address';
    // console.log(item)
    let _data = new FormData();
    for (let i in item) {
      _data.append(i, item[i]);
    }
    Axios({
      url: hostNameAndPort + '/api/' + item.section + '/update',
      method: 'post',
      data: _data
    })
    .then(result => {
      let {data} = result;
      if (!data.error) {
        let itemsLocal = items;
        itemsLocal[index].itemId = data.itemId;
        itemsLocal[index].slug = data.slug;
        itemsLocal[index].section = section[index];
        itemsLocal[index].category = category[index];
        itemsLocal[index].subcategory = subcategory[index];
        itemsLocal[index].specList = JSON.parse(item.specList);
        setItems(itemsLocal);
      } else props.showAlertModal(getTranslatedPhrase(props.userLanguage, data.error));
    })
    .catch(console.log);
    props.showAlertModal(getTranslatedPhrase(props.userLanguage, 'Your changes have been saved.'));
  }

  function onChangePriceItem(value, i) {
    value = value.replace(/,/g, '').replace('.', '');
    if (String(value).length > 12) return;
    let _price = [...price];
    _price[i] = value;
    setPrice(_price);
  }

  function displayPriceValue(value) {
      if (isNaN(value)) return '';
      value = String(value).split('').reverse();
      let result = [];
      for (let i=0;i<value.length;i++) {
          if (i === 2) result.push('.');
          else if ((i > 2) && (i % 3 === 2)) result.push(',');
          result.push(value[i]);
      }
      if (result.length > 1) 
          while (result[result.length-1] === ',' || ((result[result.length-1] === '0') && (result[result.length-2] !== '.')))
              result.pop();
      if (value.length && value.length <= 2) result.push('.', '0');
      result = result.reverse().join('');
      return result;
  }

  function onChangeCurrency(value,i) {
    let _currency = [...currency];
    _currency[i] = value;
    setCurrency(_currency);
  }


  // if (!loggedInUser) props.history.push('/');
  // let itemCount = !Loader?0:Loader.totalCount;
  // let items = !Loader?[]:Loader.items;

  if (Loader && Loader.items && Loader.items.length && !items.length) {
    setItems([...Loader.items]);
    setItemCount(Loader.totalCount);
  }
  // console.log('render',items)

  return(
      <div className="main-100 super-admin">

          <div className="main-100-header">
              <h1>Manage Items</h1>
              <p>This is the administration page, so be careful what you do!</p>
          </div>

              <div className="manage-coupons-box">
                  <h4>There are {itemCount} items</h4>

                  {/* <div className="row super-admin-nav">
                      <div className="col span-1-of-2 super-admin-filter">
                          <button className="button button-regular button-small" onClick={this.onClickSubscriptionFilter}>{this.state.subscriptionFilter}</button>
                          <button className="button button-regular button-small" onClick={this.onClickAccountTypeFilter}>{this.state.accountTypeFilter}</button>
                          <button className="button button-regular button-small" onClick={this.onClickCountryFilter}>{this.state.countryFilter}</button>
                      </div>
                      
                      
                      <div className="col span-1-of-2 super-admin-search">
                          <input type="text" placeholder="Keywords" value={this.state.keywords} onChange={this.onChangeKeywordsHandler} />
                          <button className="button button-regular button-small" onClick={this.onClickSearchUsers}>Go</button>
                      </div>
                  </div> */}

                  <LoadingSvg active={!itemListLoaded} />
                  <div>Random number: {randomNumber}</div>

                  <table className="super-admin">
                  <tbody>
                      <tr className="table-header">
                          <td>Image</td>
                          <td>Title</td>
                          <td>Section</td>
                          <td>Category</td>
                          <td>Subcategory</td>
                          <td>Price</td>
                          <td>Currency</td>
                          <td>Admin</td>
                          <td>Admin</td>
                      </tr>

                      {items.map((v,i) => (<tr key={v.slug} style={{backgroundColor: "white" }}>
                          <td><img src={v.featuredImage} height="48" width="75" alt={v.title} /></td>
                          <td>{v.title}</td>
                          <td>
                            
                            <div className="form-input-select">
                               <select value={section[i]} onChange={e => onChangeSection(e.target.value,i)} className="form-input-full">
                                  {SECTIONS.map((s,i) => (<option key={s+i} value={s}>{s}</option>))}
                                </select>
                            </div>
                           
                          </td>

                          <td>
                            <div className="form-input-select">
                              <select value={category[i]} onChange={e => onChangeCategory(e.target.value,i)} className="form-input-full">
                                <option key="primul" value="all">all</option>
                                {categoryList[i].map((c,j) => (<option key={c+j} value={c}>{c.replace(/-/g,' ')}</option>))}
                             </select>
                            </div>
                            
                          </td>
                          <td>
                            <div className="form-input-select">
                              <select value={subcategory[i]} onChange={e => onChangeSubcategory(e.target.value,i)} className="form-input-full">
                                <option key="primul-1" value="all">all</option>
                                {subcategoryList[i].map((c,j) => (<option key={c+j} value={c.replace(/^\s*-\s*/, '')}>{c.replace(/-/g,' ')}</option>))}
                              </select>
                            </div>
                            
                          </td>
                          <td><div className=""><input type="text" style={{width:'130px'}} className="form-input-text form-input-full" value={displayPriceValue(price[i])} onChange={e => onChangePriceItem(e.target.value, i)}/></div></td>
                          <td>
                            <div className="form-input-select">
                              <select value={currency[i]} onChange={e => onChangeCurrency(e.target.value,i)} className="form-input-full">
                                {currencyList.map((c,j) => (<option key={c+j} value={c}>{c}</option>))}
                              </select>
                            </div>
                            
                          </td>
                          <td><button onClick={() => onEditItem(items[i].section, items[i].slug)}>Edit</button></td>
                          <td><button onClick={() => onSaveItem(i)}>Save</button></td>
                        </tr>) )}
                  </tbody>
                  </table>

              </div>
          
              <PageNumbers 
                  count={Math.ceil(itemCount/18)} 
                  activePage={currentPage}
                  onClick={onUpdatePageNumber} />


          <div className="clb" />

      </div>
  );
}

const mapStateToItemListProps = (
  state,
  props
) => {
  return {
      userLanguage: state.userLanguage,
  };
};

const mapDispatchToItemListProps = dispatch => {
  return {
      showAlertModal: (u) => dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: u })
  };
};

export default connect(mapStateToItemListProps, mapDispatchToItemListProps)(withRouter(ItemList));