import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import Axios from 'axios';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../../../shared/util/lang';

class WallBlog extends Component {
    state = {
        blogNotFound: true,
        featuredImage: '',
        title: 'Grab A Coffee To Go',
        blogContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec mi ut lectus viverra ornare. Duis pellentesque vehicula diam sed molestie. Fusce ultricies arcu id posuere condimentum. Etiam imperdiet risus et condimentum scelerisque'
    }

    componentDidMount() {
        let b = this.props.blogId;
        if (!b) {
            this.setState({ blogNotFound: true });
            return;
        }
        let url = `${hostNameAndPort}/api/blogs/${b}`;
        Axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {                
                data.item.creationDate = (new Date(data.item.creationDate)).toDateString();
                //data.item.blogContent = (data.item.blogContent).split('\n');
                data.item.blogNotFound = false;
                this.setState(data.item);
                let _wallAction;
                switch (this.props.activityType) {
                    case 'comment':
                    _wallAction = 'commented on a blog';
                    break;
                    case 'like':
                    _wallAction = 'liked a blog'; 
                    break;
                    case 'share':
                    _wallAction = 'shared a blog';
                    break;
                    case 'post':
                    _wallAction = 'posted a blog';
                    break;
                    default:
                    break;
                }
                this.props.onItemLoaded({
                    itemFailedLoading: false,
                    wallAction: _wallAction,
                });
            }
            else {
                this.props.onItemLoaded({ itemFailedLoading: true });
            }
        })
        .catch(err => {
            //console.log('Network error');
            this.props.onItemLoaded({ itemFailedLoading: true });
        });
    }

    render () {
        if (this.state.blogNotFound) {
            return '';
        }
        return (
            <div className="WallBlog">
                <Link to={'/blogs/'+this.state.category+'/'+this.state.slug}>
                    <img src={this.state.featuredImage} alt={this.state.title} />
                </Link>

                <div className="WallBlogPreview">
                    <h4>{this.state.title}</h4>

                    <p>{(this.state.blogContent).substring(0, 100) + '...'}</p>

                    <button className="regularButton">
                        <Link to={'/blogs/'+this.state.category+'/'+this.state.slug}>{getTranslatedPhrase(this.props.userLanguage,'Read More')}</Link>
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToWallBlogProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToWallBlogProps, null)(WallBlog);
