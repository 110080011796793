import React, { Component } from "react";
// import InputRange from 'react-input-range';
import Select from "react-select";
import moment from "moment";
import Axios from "axios";
import { getTranslatedPhrase } from "../../../util/lang";
import { formatPrice, formatPriceAndCurrency } from "../../../util/number";
import { titleCase } from "../../../util/string";
import { hostNameAndPort } from "../../../util/vars";

import FormFieldError from "../../../uielements/ErrorMessages/FormFieldError";
import ErrorMessages from "../../../uielements/ErrorMessages/ErrorMessages";
import LoadingSvg from "../../../uielements/LoadingSpinner/LoadingSvg";
import PaymentModal from "../../Modals/Advertise/SubscriptionModal";
import SpecialItem from "../SpecialItem/SpecialItem";

class PayPerView extends Component {
  constructor(props) {
    super(props);
    this.initialState = this.state = {
      campaignId: "",
      ages: { min: 18, max: 75 },
      title: "",
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      gender: {
        male: false,
        female: false,
      },
      countryList: [],
      cityList: {},
      stateList: [],
      viewsPerCountry: {},
      countries: [],
      states: [],
      cities: [],
      budget: 0,
      budgetMax: 0,
      desiredViews: 0,
      totalCost: 0,
      pricePerView: 1,
      openPaymentModal: false,
      itemId: "",
      itemType: "",
      isFromCarsDB: false,
      isFromRealestateDB: false,
      alreadyPublished: false,
      item: null,
      temp: {
        errorMessage: "",
        saveCampaignPressed: false,
        campaignSaved: false,
        mainTitle: "Create Pay Per View Campaign",
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let l = this.props.location;
    if (l.pathname.indexOf("/create-ppv-campaign") >= 0 && l.search) {
      let searchParams = new URLSearchParams(l.search);
      let i = searchParams.get("item");
      let t = searchParams.get("cat");
      let c = searchParams.get("cars");
      if (!c || c === "false") c = false;
      else c = true;
      let r = searchParams.get("re");
      if (!r || r === "false") r = false;
      else r = true;
      let edit = searchParams.get("edit");
      if (!!edit) {
        this.loadCampaign(edit);
      } else {
        this.setState({
          itemId: i,
          itemType: t,
          isFromCarsDB: c,
          isFromRealestateDB: r,
          temp: {
            ...this.state.temp,
            mainTitle: "Create Pay Per View Campaign",
          },
        });
      }
      this.loadComponent(i, t, c, r);
      this.getViewsPerCountry();
      this.props.history.replace(this.props.match.url);
    } else {
      this.props.history.replace("/");
    }
  }

  loadCampaign = async (id) => {
    try {
      let url = `${hostNameAndPort}/api/campaigns/${id}`;
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        data.campaign.startDate = moment(data.campaign.startDate).format(
          "YYYY-MM-DD"
        );
        data.campaign.endDate = moment(data.campaign.endDate).format(
          "YYYY-MM-DD"
        );
        data.campaign.totalCost =
          data.campaign.desiredViews * data.campaign.pricePerView;
        this.setState(data.campaign);
      }
    } catch (err) {
      //
    }
  };

  getViewsPerCountry = async () => {
    try {
      let url = `${hostNameAndPort}/api/campaigns/users`;
      let result = await Axios(url);
      let { data } = result;

      if (!data.error) {
        let c = data.countryList;
        let cl = [];
        for (let i in c) {
          cl.push(c[i].country);
        }
        this.setState({ viewsPerCountry: c, countryList: cl });
      }
    } catch (err) {
      // error
    }
  };

  loadComponent = async (i, t, fromCars, fromRealestate) => {
    if (!i) return null;
    try {
      if (!!fromCars) t = "cars";
      if (!fromRealestate && t === "realestate") t = "realestate-old";
      let url = `${hostNameAndPort}/api/${t}/${i}`;
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        if (!data.item.currency) data.item.currency = "USD";
        if (!data.item.slug) data.item.slug = data.item.id;
        if (!data.item.featuredImage && !!data.item.photo_url)
          data.item.featuredImage = (data.item.photo_url.split("|") || [
            "https://cdn-westus2.followone.com/images/uploads/default/default-product-image.jpg",
          ])[0].trim();
        data.item.price = Number(data.item.price) / 100;
        this.setState({ item: data.item });
      }
    } catch (err) {
      // error
    }
  };

  onChangeProp = (e, _propName, _sizeLimit, _propMsg) => {
    if (e.target.value.length > _sizeLimit) {
      this.setState({
        temp: {
          ...this.state.temp,
          errorMessage: `${getTranslatedPhrase(
            this.props.userLanguage,
            _propMsg
          )} ${getTranslatedPhrase(
            this.props.userLanguage,
            "must be smaller than"
          )} ${_sizeLimit} ${getTranslatedPhrase(
            this.props.userLanguage,
            "characters"
          )}`,
        },
      });
      return;
    }
    this.setState({
      [_propName]: e.target.value,
      temp: { ...this.state.temp, campaignSaved: false, errorMessage: "" },
    });
  };

  requiredFieldsAreFilled() {
    let res = true,
      _ = this.state;
    let _err = "";

    if (!_.title) {
      _err =
        getTranslatedPhrase(this.props.userLanguage, "Title") +
        " " +
        getTranslatedPhrase(this.props.userLanguage, "is required");
      res = false;
    }
    if (!_.budgetMax || !_.budget) {
      _err = getTranslatedPhrase(this.props.userLanguage, "Choose the budget");
      res = false;
    }
    // if ( !_.gender ) {
    //     _err = getTranslatedPhrase(this.props.userLanguage,'Gender')+' '+getTranslatedPhrase(this.props.userLanguage,'is required');
    //     res = false;
    // }
    if (
      !_.states.length &&
      !Object.keys(_.cities).length &&
      !_.countries.length
    ) {
      _err = getTranslatedPhrase(
        this.props.userLanguage,
        "Choose the country, the states and the cities of your campaign."
      );
      res = false;
    }
    if (!_.startDate) {
      _err =
        getTranslatedPhrase(this.props.userLanguage, "Start Date") +
        " " +
        getTranslatedPhrase(this.props.userLanguage, "is required");
      res = false;
    }
    if (!_.endDate) {
      _err =
        getTranslatedPhrase(this.props.userLanguage, "End Date") +
        " " +
        getTranslatedPhrase(this.props.userLanguage, "is required");
      res = false;
    }
    if (res) {
      // Check the dates
      if (moment(_.endDate).isBefore(moment(_.startDate), "days")) {
        _err = getTranslatedPhrase(
          this.props.userLanguage,
          "End date must be after start date"
        );
        res = false;
      }
      if (moment(_.startDate).isBefore(moment(), "days")) {
        _err = getTranslatedPhrase(
          this.props.userLanguage,
          "Start date cannot be in the past"
        );
        res = false;
      }
    }
    if (_.temp.campaignSaved && res) {
      _err =
        getTranslatedPhrase(this.props.userLanguage, "Campaign") +
        " " +
        getTranslatedPhrase(this.props.userLanguage, "is already saved");
      res = false;
    }
    this.setState({ temp: { ...this.state.temp, errorMessage: _err } });
    return res;
  }

  onClearDraftHandler = () => {
    let savedItem = {
      item: this.state.item,
      itemId: this.state.itemId,
      itemType: this.state.itemType,
      viewsPerCountry: this.state.viewsPerCountry,
      viewsPerState: this.state.viewsPerState,
      viewsPerCity: this.state.viewsPerCity,
      countryList: this.state.countryList,
      stateList: this.state.stateList,
      cityList: this.state.cityList,
    };
    this.setState(this.initialState);
    this.setState(savedItem);
  };

  onPublishHandler = () => {
    if (!this.requiredFieldsAreFilled()) {
      return;
    }
    // First, pay and then publish campaign
    this.setState({ openPaymentModal: true });
  };

  onCloseModalHandler = () => {
    this.setState({ openPaymentModal: false });
  };

  onSubmitPaymentToServer = async ({ stripeToken, gpaymentId }) => {
    try {
      this.onCloseModalHandler();
      this.setState({
        temp: { ...this.state.temp, saveCampaignPressed: true },
      });

      let newCampaignData = new FormData();
      newCampaignData.append("stripeToken", stripeToken.id || "");
      newCampaignData.append("gpaymentId", gpaymentId.id || "");
      newCampaignData.append("amount", this.state.budget);
      newCampaignData.append("itemId", this.state.itemId);
      newCampaignData.append("websiteSection", this.state.itemType);
      newCampaignData.append("isFromCarsDB", this.state.isFromCarsDB);
      newCampaignData.append(
        "isFromRealestateDB",
        this.state.isFromRealestateDB
      );
      newCampaignData.append("startDate", this.state.startDate);
      newCampaignData.append("endDate", this.state.endDate);
      newCampaignData.append("promoType", "ppv-campaign");

      let options = {
        url: `${hostNameAndPort}/api/pay/ppvcampaign`,
        method: "post",
        data: newCampaignData,
      };
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        this.setState({ alreadyPublished: true });
        this.onFinishPublishing();
      } else
        this.setState({
          temp: {
            ...this.state.temp,
            errorMessage: data.error,
            campaignSaved: false,
            saveCampaignPressed: false,
          },
        });
    } catch (err) {
      this.setState({
        temp: {
          ...this.state.temp,
          errorMessage: "" + err,
          campaignSaved: false,
          saveCampaignPressed: false,
        },
      });
    }
  };

  onFinishPublishing = async () => {
    try {
      // this.setState({ temp: { ...this.state.temp, saveCampaignPressed: true } });
      if (!this.state.alreadyPublished) return;

      let nbd = Object.assign({}, this.state);
      nbd.title = titleCase(nbd.title);
      delete nbd.item;
      delete nbd.viewsPerCountry;
      delete nbd.viewsPerState;
      delete nbd.viewsPerCity;
      delete nbd.countryList;
      delete nbd.stateList;
      delete nbd.cityList;
      delete nbd.temp;
      // console.log(nbd);
      // setTimeout( () => {
      //     this.setState({ temp: { ...this.state.temp, saveCampaignPressed: false, errorMessage: 'Campaign page testing.' } });
      // }, 2000);
      // return;

      let newCampaignData = new FormData();

      newCampaignData.append("ages", JSON.stringify(nbd.ages));
      newCampaignData.append("states", JSON.stringify(nbd.states));
      newCampaignData.append("countries", JSON.stringify(nbd.countries));
      newCampaignData.append("cities", JSON.stringify(nbd.cities));
      newCampaignData.append("gender", JSON.stringify(nbd.gender));
      newCampaignData.append("startDate", nbd.startDate);
      newCampaignData.append("endDate", nbd.endDate);
      newCampaignData.append("budget", nbd.budget);
      newCampaignData.append("budgetMax", nbd.budgetMax);
      newCampaignData.append("itemType", nbd.itemType);
      newCampaignData.append("itemId", nbd.itemId);
      newCampaignData.append("isFromCarsDB", nbd.isFromCarsDB);
      newCampaignData.append("isFromRealestateDB", nbd.isFromRealestateDB);
      newCampaignData.append("title", nbd.title);
      newCampaignData.append("campaignId", nbd.campaignId);

      let options = {
        url: hostNameAndPort + "/api/campaigns/update",
        method: "post",
        data: newCampaignData,
      };
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        this.setState({
          campaignId: data.campaignId,
          temp: {
            campaignSaved: true,
            mainTitle: "Edit Pay Per View Campaign",
            errorMessage: "",
            saveCampaignPressed: false,
          },
        });
      } else {
        this.setState({
          temp: {
            ...this.state.temp,
            campaignSaved: false,
            errorMessage: data.error,
            saveCampaignPressed: false,
          },
        });
      }
    } catch (err) {
      this.setState({
        temp: {
          ...this.state.temp,
          errorMessage: "Network error ERRFEPPV01",
          campaignSaved: false,
          saveCampaignPressed: false,
        },
      });
    }
  };

  onChangeCountries = (countries) => {
    let stateList = this.state.viewsPerCountry
      .map((v) => {
        let s = {};
        for (let i in countries) {
          if (countries[i].value === v.country) {
            for (let j in v.stateList) {
              s[v.stateList[j].state] = 1;
            }
          }
        }
        return Object.keys(s);
      })
      .reduce((a, v) => [...a, ...v], []);
    if (stateList.length === 1 && stateList[0] === "N/A") {
      // if there are no states in the country, then choose N/A automatically
      this.onChangeStates([{ value: "N/A", label: "N/A" }], countries);
      this.setState({
        countries,
        stateList,
        cities: [],
        budget: 0,
        budgetMax: 0,
        desiredViews: 0,
        totalCost: 0,
        temp: { ...this.state.temp, errorMessage: "" },
      });
    } else {
      if (stateList.length > 0) {
        stateList.unshift("Select All");
      }
      this.setState({
        countries,
        stateList,
        states: [],
        cityList: {},
        cities: [],
        budget: 0,
        budgetMax: 0,
        desiredViews: 0,
        totalCost: 0,
        temp: { ...this.state.temp, errorMessage: "" },
      });
    }
  };

  onChangeStates = (states, countries) => {
    if (!countries) {
      countries = this.state.countries;
    }

    let allAreSelected = false;
    for (let i in states) {
      if (states[i].value === "Select All") {
        allAreSelected = true;
      }
    }
    let firstTime = true;
    let cityList = this.state.viewsPerCountry
      .map((v) => {
        let c = {};
        for (let i in countries) {
          if (countries[i].value === v.country) {
            for (let j in v.stateList) {
              for (let k in states) {
                if (
                  states[k].value === v.stateList[j].state ||
                  allAreSelected
                ) {
                  if (firstTime) {
                    c["Select All"] = 0;
                    firstTime = false;
                  }
                  c = { ...c, ...v.stateList[j].cityList };
                  // break;
                }
              }
            }
          }
        }
        return c;
      })
      .reduce((a, v) => ({ ...a, ...v }), {});
    if (Object.keys(cityList).length > 0) {
      let desiredViews = 0;
      for (let i in cityList) {
        desiredViews += cityList[i];
      }
      cityList["Select All"] = desiredViews;
    }
    this.setState({
      states,
      cityList,
      cities: [],
      budget: 0,
      budgetMax: 0,
      desiredViews: 0,
      totalCost: 0,
      temp: { ...this.state.temp, errorMessage: "" },
    });
  };

  onChangeCities = (cities) => {
    let bmx = 0;
    let c = cities;
    let allAreSelected = false;
    for (let i in c) {
      if (c[i].value === "Select All") {
        allAreSelected = true;
        break;
      }
    }
    if (allAreSelected)
      for (let j in this.state.cityList) {
        if (j === "Select All") continue;
        if (this.state.gender.male) bmx += this.state.cityList[j].male;
        if (this.state.gender.female) bmx += this.state.cityList[j].female;
      }
    else
      for (let i in c) {
        for (let j in this.state.cityList) {
          if (c[i].value === j) {
            if (this.state.gender.male) bmx += this.state.cityList[j].male;
            if (this.state.gender.female) bmx += this.state.cityList[j].female;
          }
        }
      }
    this.setState({
      cities,
      budget: 0,
      budgetMax: bmx,
      desiredViews: 0,
      totalCost: 0,
      temp: { ...this.state.temp, errorMessage: "" },
    });
  };

  onChangeBudget = ({ target: { value } }) => {
    if (value <= this.state.budgetMax && value >= 0)
      this.setState({
        budget: value,
        desiredViews: value,
        totalCost: value * this.state.pricePerView,
        temp: { ...this.state.temp, errorMessage: "" },
      });
  };

  onChangeAges = (ages) => {
    this.setState({ ages, temp: { ...this.state.temp, errorMessage: "" } });
  };

  onToggleGender = (g) => {
    let sg = this.state.gender;
    if (sg.hasOwnProperty(g)) {
      sg[g] = !sg[g];
    }
    this.setState({
      gender: sg,
      temp: { ...this.state.temp, errorMessage: "" },
    });
    this.onChangeCities(this.state.cities);
  };

  render() {
    return (
      <div className="appv main-100">
        <div className="main-100-header">
          <h1>{this.state.temp.mainTitle}</h1>
          <p>
            {getTranslatedPhrase(
              this.props.userLanguage,
              "Guaranteed unique views!"
            )}
          </p>
        </div>

        <div className="appv-preview">
          <h4>{getTranslatedPhrase(this.props.userLanguage, "AD PREVIEW")}</h4>

          <div className="appv-preview-ad">
            <SpecialItem
              itemType={
                this.state.itemType === "autos" && !!this.state.id
                  ? "cars"
                  : this.state.itemType
              }
              {...this.state.item}
            />
          </div>
        </div>

        <div className="appv-settings">
          <h4>
            {getTranslatedPhrase(this.props.userLanguage, "Campaign Details")}
          </h4>

          <div className="row row-campaign-info">
            <div className="col span-4-of-8">
              <label className="main-label">
                {getTranslatedPhrase(this.props.userLanguage, "Campaign Title")}
              </label>
              <input
                type="text"
                className="form-input-text"
                placeholder="Campaign Title"
                value={this.state.title}
                onChange={(e) => this.onChangeProp(e, "title", 50, "Title")}
              />
            </div>

            <div className="col span-2-of-8">
              <label className="main-label">
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  "Starts At 12:00 AM On"
                )}
              </label>
              <input
                type="date"
                className="form-input-text"
                placeholder="Start Date"
                value={this.state.startDate}
                onChange={(e) =>
                  this.onChangeProp(e, "startDate", 20, "Start Date")
                }
              />
            </div>

            <div className="col span-2-of-8">
              <label className="main-label">
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  "Ends At 12:00 AM On"
                )}
              </label>
              <input
                type="date"
                className="form-input-text"
                placeholder="End Date"
                value={this.state.endDate}
                onChange={(e) =>
                  this.onChangeProp(e, "endDate", 20, "End Date")
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col span-2-of-2">
              <label className="main-label">
                {getTranslatedPhrase(this.props.userLanguage, "Select Gender")}
              </label>

              <div className="form-input-lists">
                <input
                  name="choosePPVGenderFemale"
                  checked={this.state.gender.female}
                  onChange={() => null}
                  id="choosePPVGenderFemale"
                  type="radio"
                  title="Female"
                  onClick={(e) => this.onToggleGender("female")}
                />
                <label htmlFor="choosePPVGenderFemale">
                  <span>
                    <i className="fal fa-check" />
                  </span>
                  {getTranslatedPhrase(this.props.userLanguage, "Female")}
                </label>
              </div>

              <div className="form-input-lists">
                <input
                  name="choosePPVGenderMale"
                  checked={this.state.gender.male}
                  onChange={() => null}
                  id="choosePPVGenderMale"
                  type="radio"
                  title="Male"
                  onClick={(e) => this.onToggleGender("male")}
                />
                <label htmlFor="choosePPVGenderMale">
                  <span>
                    <i className="fal fa-check" />
                  </span>
                  {getTranslatedPhrase(this.props.userLanguage, "Male")}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col span-2-of-2">
              <label className="main-label">
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  "Choose Age Range"
                )}
              </label>

              {/* <InputRange
                                maxValue={75}
                                minValue={18}
                                value={this.state.ages}
                                onChange={this.onChangeAges} /> */}
            </div>
          </div>

          <div className="row row-campaign-location">
            <div className="col span-1-of-3">
              <label className="main-label">
                {getTranslatedPhrase(this.props.userLanguage, "Select Country")}
              </label>
              <Select
                options={this.state.countryList.map((v) => ({
                  value: v,
                  label: v,
                }))}
                closeMenuOnSelect={false}
                isMulti
                onChange={this.onChangeCountries}
              />
            </div>

            <div className="col span-1-of-3">
              <label className="main-label">
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  "Select State/Province"
                )}
              </label>
              <Select
                options={this.state.stateList.map((v) => ({
                  value: v,
                  label: v.replace("_", " "),
                }))}
                closeMenuOnSelect={false}
                isMulti
                value={this.state.states}
                onChange={(s) => this.onChangeStates(s, null)}
              />
            </div>

            <div className="col span-1-of-3">
              <label className="main-label">
                {getTranslatedPhrase(this.props.userLanguage, "Select City")}
              </label>
              <Select
                options={Object.keys(this.state.cityList).map((v) => ({
                  value: v,
                  label: /_N\/A$/.test(v)
                    ? v.replace("_N/A", "")
                    : v.replace("_", " "),
                }))}
                closeMenuOnSelect={false}
                isMulti
                value={this.state.cities}
                onChange={this.onChangeCities}
              />
            </div>
          </div>

          {/* 
                    <div className="row">
                        <div className="col span-2-of-2">
                            <label className="main-label">{getTranslatedPhrase(this.props.userLanguage,'Budget ($) / User Reach')}</label>

                            <InputRange
                                maxValue={this.state.budgetMax}
                                minValue={0}
                                value={this.state.budget}
                                onChange={this.onChangeBudget} />
                        </div>
                    </div> */}

          <div className="row">
            <div className="col span-1-of-2">
              <label className="main-label">
                <strong>{this.state.budgetMax} viewers can be reached.</strong>{" "}
                <br />
                How many viewers would you like to reach with this ad?
              </label>

              <input
                type="number"
                onChange={this.onChangeBudget}
                value={this.state.budget}
                className="form-input-text"
                placeholder="Enter the Number of Users to Reach"
              />

              <span className="appv-price-disclaimer">
                Price: $1 / Guaranteed View
              </span>
            </div>
          </div>

          <div className="total-cost">
            <div>
              {getTranslatedPhrase(this.props.userLanguage, "Total Views")}:{" "}
              <br />
              <span>{formatPrice(this.state.desiredViews, ",")}</span>
            </div>
            <div>
              {getTranslatedPhrase(this.props.userLanguage, "Total Cost")}:{" "}
              <br />
              <span>
                {formatPriceAndCurrency(this.state.totalCost, "USD", ",")}
              </span>
            </div>
          </div>

          <div className="ppv-actions">
            <FormFieldError>{this.state.temp.errorMessage}</FormFieldError>
            <LoadingSvg active={this.state.temp.saveCampaignPressed} />
            <button
              className="button button-regular"
              onClick={this.onPublishHandler}
            >
              {getTranslatedPhrase(this.props.userLanguage, "Start Campaign")}
            </button>
            <ErrorMessages
              errorMessageClass={"error-message error-message-info"}
              errorMessageTitle={getTranslatedPhrase(
                this.props.userLanguage,
                "Success"
              )}
              errorMessageBody={
                getTranslatedPhrase(this.props.userLanguage, "Campaign") +
                " " +
                getTranslatedPhrase(
                  this.props.userLanguage,
                  "has been published"
                )
              }
              show={this.state.temp.campaignSaved}
            ></ErrorMessages>
          </div>
        </div>

        <PaymentModal
          active={this.state.openPaymentModal}
          promoCharge={this.state.budget}
          addNewCardOnly={false}
          onCloseModal={this.onCloseModalHandler}
          onSubmitUserInfo={this.onSubmitPaymentToServer}
          onMoveForward={() => null}
        />
      </div>
    );
  }
}

export default PayPerView;
