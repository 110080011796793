import React, { Component } from 'react';
import Box from '../../../util/box';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
import EngageWithComments from '../../../uielements/Engage/EngageBox';
import { hostNameAndPort } from '../../../util/vars';
import Axios from 'axios';


class MediaModal extends Component {
    state = {
        itemId: '',
        active: false
    }

    componentDidMount() {
        if (this.props.active) this.setState({ itemId: this.props.itemId });
    }

    componentDidUpdate() {
        if (!this.props.photoElement) return;
        let p = this.props.itemId;
        if (this.state.itemId !== p) {
            this.setState({ itemId: p });
            if (this.state.active) this.addViewToPhoto();
        } else if (this.state.active !== this.props.active) {
            if (!this.state.active && this.props.active) {
                this.addViewToPhoto();
                this.setState({ active: this.props.active });
            } else this.setState({ itemId: '', active: false });
        }
    }

    addViewToPhoto = async () => {
        try {
            let photoData = new FormData();
            photoData.append('itemId', this.props.itemId);
            let options = {
                url: `${hostNameAndPort}/api/engage/addview/photos`,
                method: 'post',
                data: photoData
            };
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                // No error
            }
        } catch (err) {
            // Didn't work
        }
    }

    render() {
        if (!this.props.active) {
            return null;
        }


        // console.log('desc before', this.props.mediaDescription);

        let mediaDescContent = (this.props.mediaDescription || '').replace(/((www|http)[A-Za-z0-9:./]*|(\.com|\.org|\.biz|\.net|\.io))/g, '');

        // console.log('desc after', mediaDescContent);

        return (<div className="modal-wrapper">
            <Backdrop show={this.props.active} clicked={this.props.onClickCloseHandler} />
            <div className="modal modal_wide">
                <div className="media-modal">
                    <div className="media-modal_box">

                        {!this.props.photoElement ? null : (
                            <div className="media-modal_box_image">
                                {this.props.photoElement}
                                {/* Previous Image */}
                                {(!this.props.showArrows) ? null : (
                                    <button className="media-modal_box_image_left-arrow" onClick={this.props.goBack}><i className="fal fa-angle-left" /></button>)}
                                {/* Next Image */}
                                {(!this.props.showArrows) ? null : (
                                    <button className="media-modal_box_image_right-arrow" onClick={this.props.goForward}><i className="fal fa-angle-right" /></button>)}
                            </div>)}

                        {!this.props.videoElement ? null : (
                            <div className="media-modal_box_video">
                                {this.props.videoElement}
                            </div>)}

                    </div>

                    <div className="media-modal_desc">
                        <div className="media-modal_desc_author">
                            <img src={this.props.authorProfileImage} alt={this.props.authorName} />
                            <h3>{this.props.authorName}</h3>
                            <div className="clb" />
                        </div>

                        <div className="media-modal_desc_title">
                            <h4>{this.props.mediaTitle}</h4>
                        </div>

                        <div className="media-modal_desc_text">
                            {mediaDescContent.split("\n").map((v, i) => (
                                <Box key={"line-" + i}>
                                    <p>{v}</p>
                                </Box>
                            ))}
                        </div>

                        <EngageWithComments
                            {...this.props}
                            withComments={true}
                        />

                    </div>
                </div>

                <button className="modal_close" onClick={this.props.onClickCloseHandler}><i className="fal fa-times" /></button>
            </div>
        </div>);
    }
}

export default MediaModal;