import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { getTranslatedPhrase } from "../../../util/lang";
import Modal from '../../../uielements/Modal/Modal';
import { populateCountries, populateStates } from "../../../util/countries";
import actionType from "../../../../redux-store/action-type";
import { hostNameAndPort } from "../../../util/vars";
import Axios from "axios";
import IPData from 'ipdata';
import { useHistory } from 'react-router-dom';


const WebsiteLocation = (props) => {
    const searchQuery = useSelector(state => state.searchQuery);
    const userLanguage = useSelector(state => state.userLanguage);
    const [myTimer, setMyTimer] = useState(Date.now());
    const [openModal, setOpenModal] = useState('location-modal-closed');
    const [again, setRenderAgain] = useState(false);
    const [newIp, setNewIp] = useState('');
    const [location, setLocation] = useState({}); 
    const [errorLoc, setErrorLoc] = useState(false);


    const [countriesLoaded, setCountriesLoaded] = useState(false);
    const [countryElementId, setCountryElementId] = useState('');
    const [stateElementId, setStateElementId] = useState('');
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [manualLocation, setManualLocation] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const history = useHistory();


    useEffect(() => {
        // console.log('aici')

        if (countryElementId
            && stateElementId
            && document.getElementById('stateElementForSiteLocation')
            && document.getElementById('stateElementForSiteLocation').innerHTML === ''
            && document.getElementById('countryElementForSiteLocation')
            && document.getElementById('countryElementForSiteLocation').selectedIndex) {
            populateCountries(countryElementId, stateElementId);
            let cc = document.getElementById('countryElementForSiteLocation');
            let i = 0;
            for (; i < cc.options.length; i++)
                if (cc.options[i].value === country) break;
            if (i < cc.options.length) cc.selectedIndex = i;
            else cc.selectedIndex = 0;
            populateStates(countryElementId, stateElementId);
            cc = document.getElementById('stateElementForSiteLocation');
            i = 0;
            for (; i < cc.options.length; i++)
                if (cc.options[i].value === region) break;
            if (i < cc.options.length) cc.selectedIndex = i;
            else cc.selectedIndex = 0;
        }
    }, [country, region, location, openModal, manualLocation])


    const cacheConfig = {
        max: 1000, // max size
        maxAge: 30 * 60 * 1000, // max age in ms (i.e. 30 minutes)
    };

    const ipdata = new IPData('864d032dc1dd89b953a040cb51e523a20a90dfa144bfd51c9daf4995');

    let searchQueryParsed = JSON.parse(searchQuery || '{}');
    const countryUpdated = searchQueryParsed.countryUpdated;
    const cannotChangeCountry = Date.now() - myTimer < 10000;

    function delete_cookie(name, path, domain) {
        if (get_cookie(name)) {
            document.cookie = name + "=" +
                ((path) ? ";path=" + path : "") +
                ((domain) ? ";domain=" + domain : "") +
                ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
        }
    }

    function get_cookie(name) {
        let a = document.cookie.split(';').filter(c => {
            return c.trim().startsWith(name + '=');
        });
        if (!a.length) a = [''];
        else a[0] = a[0].replace(/.*=([-.A-Za-z0-9]*);?/, '$1');
        return a[0];
    }

    const getIp = async () => {
        ipdata.lookup()
            .then(function (info) {
                setLocation({
                    ip: info.ip,
                    country: info.country_name,
                    region: info.region,
                    lng: info.longitude,
                    lat: info.latitude,
                })
            })
    }


    useEffect(() => {
        let cookie = document.cookie;
        let data;

        if (cookie.match(/fo_userCountry=([^;]*)(;|$)/)) {
            data = cookie.match(/fo_userCountry=([^;]*)(;|$)/);
            let country = data[1] || 'USA';
            setCountry(country);
        }


        if (cookie.match(/fo_region=([^;]*)(;|$)/)) {
            data = cookie.match(/fo_region=([^;]*)(;|$)/);
            let region = data[1] || '';
            setRegion(region);
        }


        if (cookie.match(/manuallocation=true/)) {
            setManualLocation(true);
        }


        getIp();
    }, [])




    // useEffect(() => {
    //     let cookie = document.cookie;
    //     let data;

    //     if (cookie.match(/fo_userCountry=([^;]*)(;|$)/)) {
    //         data = cookie.match(/fo_userCountry=([^;]*)(;|$)/);
    //         let country = data[1] || 'USA';
    //         setCountry(country);
    //     }


    //     if (cookie.match(/fo_region=([^;]*)(;|$)/)) {
    //         data = cookie.match(/fo_region=([^;]*)(;|$)/);
    //         let region = data[1] || '';
    //         setRegion(region);
    //     }
    // }, [country, region])



    useEffect(() => {

        const getNewIp = () => {
            ipdata.lookup()
                .then(function (info) {
                    setNewIp(info.ip)
                }).catch(err => {
                    console.log(err);
                    setErrorLoc(true);
                    updateModal('location-modal-open');
                });
        }

        getNewIp();

        let cookie = document.cookie;
        let regionCookie = get_cookie('fo_region');
        let lngCookie = get_cookie('fo_lng');
        let latCookie = get_cookie('fo_lat');

        // Andrei Check Previous Version: lngCookie === '0' && !latCookie

        if (location.ip) {
            if ((cookie === undefined || !get_cookie('fo_userCountry') || !regionCookie || regionCookie === 'undefined' || !lngCookie || lngCookie === '0' || !latCookie || latCookie === '0' || newIp !== location.ip) && !manualLocation) {
                console.log('Getting location...')
                Axios(`${hostNameAndPort}/api/cities/get-country/${location.ip}/${location.country}/${location.region}/${location.lng}/${location.lat}`)
                    .then(result => {
                        let { data } = result;
                        if (!data.error) {
                            setErrorLoc(false);
                            // console.log('location data', data);
                            let country = data.country;
                            if (country === 'United States') country = 'USA';
                            delete_cookie('fo_userCountry');
                            delete_cookie('fo_region');
                            delete_cookie('fo_lng');
                            delete_cookie('fo_lat');

                            console.log('location.country', data)

                            let gotCountry = location.country;

                            if (gotCountry === 'US') {
                                gotCountry = 'USA'
                            }

                            setCountry(gotCountry);

                            // console.log('Location', location.region, gotCountry);

                            setRegion(location.region);
                            document.cookie = `fo_userCountry=${country};Max-Age=2592000000;Http-Only=true`;
                            document.cookie = `fo_region=${data.region};Max-Age=2592000000;Http-Only=true`;
                            document.cookie = `fo_lng=${data.longitude};Max-Age=2592000000;Http-Only=true`;
                            document.cookie = `fo_lat=${data.latitude};Max-Age=2592000000;Http-Only=true`;
                            props.updateGeoPosition(data.longitude || 0, data.latitude || 0);
                            props.onSubmitSearchQuery(JSON.stringify({ keywords: '', country, region: data.region, countryUpdated: country }));
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        setErrorLoc(true);
                        updateModal('location-modal-open');
                    });
            } else {
                let data = cookie.match(/fo_userCountry=([^;]*)(;|$)/);
                let country = data[1] || 'USA';
                setCountry(country);
                data = cookie.match(/fo_region=([^;]*)(;|$)/);
                let region = data[1] || '';
                setRegion(region);
                if (country === 'United States') country = 'USA';
                props.onSubmitSearchQuery(JSON.stringify({ keywords: '', country, region, countryUpdated: country }));
                let lng, lat;
                data = cookie.match(/fo_lng=([^;]*)(;|$)/);

                if (data) {
                    lng = Number(data[1]) || 0;
                }

                data = cookie.match(/fo_lat=([^;]*)(;|$)/);

                if (data) {
                    lat = Number(data[1]) || 0;
                }
                
                props.updateGeoPosition(lng, lat);
            }
        }

    }, [location]);


    function updateModal(value) {
        setOpenModal(value);
    }


    const onChangeCountry = (e) => {
        setCountry(e);
        setRegion('');
    }

    const onChangeRegion = (e) => {
        setRegion(e);
    }

    const onManuallySelectLocation = () => {

        if (country && region && country !== '-1') {
            setError(false);
            delete_cookie('fo_userCountry');
            delete_cookie('fo_region');

            document.cookie = `fo_userCountry=${country}; Max-Age=2592000000;Http-Only=true`;
            document.cookie = `fo_region=${region}; Max-Age=2592000000;Http-Only=true`;
            document.cookie = `manuallocation=true; Max-Age=2592000000; Http-Only=true`;

            setOpenModal('location-modal-closed');
            history.push('/');
            setErrorLoc(false);
            window.location.reload(false);
        } else {
            setError(true);

            if (!country || country === '-1') {
                setErrorMessage('Please select a country.');
            }

            if (!region) {
                setErrorMessage('Please select a region/state.');
            }
        }


    }

    const onAutomaticallyGetLocation = () => {
        delete_cookie('manuallocation');
        delete_cookie('fo_userCountry');
        delete_cookie('fo_region');
        history.push('/');
        window.location.reload(false);
        setErrorLoc(false);
    }

    // console.log('Country: ', country, 'Region: ', region)

    return (<>
        <div className="website-location">
            <button onClick={() => updateModal('location-modal-open')}><i className="fal fa-map-marker-alt" /> <span>{country}</span></button>
        </div>

        <div className={openModal}>
            <Modal
                title="Select Default Location"
                closeModal={() => updateModal('location-modal-closed')}
                modalClass="small"
            >
                <div className="adv-search mgt-md">
                    <div className="adv-search-content-box">

                        {errorLoc && <p style={{ padding: '0 1rem', marginBottom: '2rem' }}>Sorry, we could not detect your location, please select your location manually.</p>}

                        <div className="form-input-select">
                            <select
                                className="form-input-full"
                                value={country}
                                onChange={e => onChangeCountry(e.target.value)}
                                id="countryElementForSiteLocation"
                                ref={n => {
                                    if (!countryElementId) setCountryElementId("countryElementForSiteLocation");
                                    if (countryElementId && stateElementId && !countriesLoaded) {
                                        populateCountries(countryElementId, stateElementId);
                                        setCountriesLoaded(true);
                                    }
                                }}

                            />
                        </div>

                        <div className="form-input-select" style={{ marginTop: '1rem' }}>
                            <select
                                className="form-input-full"
                                id="stateElementForSiteLocation"
                                value={region}
                                onChange={e => onChangeRegion(e.target.value)}
                                ref={n => {
                                    if (!stateElementId) setStateElementId("stateElementForSiteLocation");
                                    if (countryElementId && stateElementId && !countriesLoaded) {
                                        populateCountries(countryElementId, stateElementId);
                                        setCountriesLoaded(true);
                                    }
                                }}

                            />
                        </div>

                        {error && <div style={{ marginTop: '1rem' }}>{errorMessage}</div>}


                        <div style={{ marginTop: '2rem' }}>
                            <button onClick={onManuallySelectLocation} className="button button-regular">Save Location</button>
                            {!errorLoc && <>
                                <div style={{ marginTop: '1rem', marginBottom: '1rem' }}><strong>or</strong></div>
                                <button onClick={onAutomaticallyGetLocation} className="button button-regular">Detect My Location</button>
                            </>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </div>

    </>)
}

const mapDispatchToWebsiteLocationProps = (
    dispatch
) => {
    return {
        updateGeoPosition: (lng, lat) => {
            dispatch({ type: actionType.CURRENT_GEOLOCATION, clientLongitude: lng, clientLatitude: lat });
        },
        onSubmitSearchQuery: (s) => {
            dispatch({ type: actionType.VIEW_SEARCH_RESULTS, searchQuery: s });
        }
    };
};

export default connect(null, mapDispatchToWebsiteLocationProps)(WebsiteLocation);

