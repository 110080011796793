import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import { formatPriceAndCurrency } from "../../../shared/util/number";
import { titleCase } from "../../../shared/util/string";

class AutoStoreFeaturedPreview extends Component {
  render() {
    let v = this.props;
    let featuredImage = !v.photo_url
      ? v.featuredImage
      : (v.photo_url.split("|") || [
          "https://cdn-westus2.followone.com/images/uploads/default/default-product-image.jpg",
        ])[0].trim();
    let linkSlug = "";
    if (!v.slug)
      linkSlug = `/autos/${v.category}/${v.subcategory || "other"}/${v.id}`;
    else
      linkSlug = `/autos/${v.category}/${v.subcategory || "other"}/${v.slug}`;

    return (
      <div className="featured-item featured-store-listing">
        <Link className="featured-store-listing_image" to={linkSlug}>
          <img src={featuredImage} alt={v.title || v.heading} />
        </Link>

        <Link className="featured-store-listing_details" to={linkSlug}>
          <h3>{v.title}</h3>
          <span>
            {getTranslatedPhrase(v.userLanguage, "by")}{" "}
            {titleCase(v.authorName)}
          </span>

          <span className="featured-store-listing_details_price">
            {formatPriceAndCurrency(v.price, v.currency || "USD", ",")}
          </span>
        </Link>
      </div>
    );
  }
}

const mapStateToAutoStoreFeaturedProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
  };
};

export default connect(
  mapStateToAutoStoreFeaturedProps,
  null
)(AutoStoreFeaturedPreview);
