import React, { Component } from "react";
import JoditEditor from '../../../shared/util/thirdparty/JodiEditor/JoditEditor';
import FormFieldError from "../../../shared/uielements/ErrorMessages/FormFieldError";
import ErrorMessages from "../../../shared/uielements/ErrorMessages/ErrorMessages";
import { Link } from "react-router-dom";
import CropImageModal from "../../../shared/util/crop-image-modal";
import moment from "moment";
import { maxUploadFileSize } from "../../../shared/util/file";
import { hostNameAndPort } from "../../../shared/util/vars";
import { titleCase } from "../../../shared/util/string";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import Axios from "axios";
import LoadingSvg from "../../../shared/uielements/LoadingSpinner/LoadingSvg";


//this is from JodiEditor
//const editor = useRef(null);
//const [content, setContent] = useState('')
//const config = {readonly: false }

class EditNews extends Component {

  constructor(props) {
    super(props);
    this.initialState = this.state = {
      mainTitle: "Post News",
      mainButtonLabel: "Publish News",
      itemId: "",
      title: "",
      featuredImage: "",
      file: null,
      scheduleDate: moment().format("YYYY-MM-DD"),
      category: "",
      subcategory: "",
      subcategoriesSelect: [],
      newsContent: "",
      sourceUrl: '',
      privacy: "public",
      hashTags: "",
      errorMessage: "",
      slug: "",
      itemSaved: false,
      openModal: false,
      categoriesSelect: [],
      postListingPressed: false,
      //this is from JodiEditor
      //content:''
    };
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.getNewsCategories();
    let l = this.props.location;
    if (l.pathname.indexOf("/edit-news") >= 0 && l.search) {
      let b = l.search.slice(3);
      this.setState({ slug: b });
      this.props.history.replace(this.props.match.url);
      this.getNewsData(b);
    }
  }

  getNewsCategories = async () => {
    try {
      let url = `${hostNameAndPort}/api/news/categories`;
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        this.setState({ categoriesSelect: data.categories });
      }
    } catch (err) {
      // server, network error
    }
  };

  getNewsData = async b => {
    try {
      let url = `${hostNameAndPort}/api/news/${b}`;
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        data.item.scheduleDate = moment(data.item.scheduleDate)
          .local()
          .format("YYYY-MM-DD");
        data.item.mainTitle = "Edit News";
        data.item.mainButtonLabel = "Update News";

        let image = new Image();
        image.src = data.item.featuredImage;
        image.alt = data.item.title;
        this.state.fImageDivRef.appendChild(image);

        // Subcategories
        let a = this.state.categoriesSelect;
        let b = [];
        let i = 0;
        for (; i < a.length; i++) {
          if (a[i] === data.item.category) break;
        }
        i++;
        while (/^\s*-+/.test(a[i]) && i < a.length) {
          b.push(a[i].replace(/^\s*-+\s*/, ''));
          i++;
        }
        data.item.subcategoriesSelect = [...b];
        this.setState(data.item);
      }
    } catch (err) {
      // server, network error
    }
  };

  onClickToUploadNewPhotoHandler = () => {
    this.setState({ openModal: true });
  };

  getCroppedFeaturedImage = f => {
    this.setState({ openModal: false });
    if (!f) {
      return;
    }
    let _fileName = "cropped-photo.jpg";
    let _file = new File([f], _fileName, { type: "image/jpeg" });
    if (_file.size > maxUploadFileSize) {
      let d = this.state.fImageDivRef;
      if (d.hasChildNodes()) {
        for (let i = 0; i < d.childNodes.length; i++) {
          d.removeChild(d.childNodes[i]);
        }
      }
      this.setState({
        featuredImage: "",
        errorMessage:
          getTranslatedPhrase(
            this.props.userLanguage,
            "The main image must be smaller than"
          ) + " 5 MB"
      });
      return;
    }

    let _reactComp = this;

    let image = new Image();
    let url = URL.createObjectURL(f);
    image.src = url;
    image.alt = _fileName;

    image.onload = function () {
      URL.revokeObjectURL(url);

      if (this.height < 280 || this.width < 440) {
        _reactComp.setState({
          featuredImage: "",
          errorMessage:
            getTranslatedPhrase(
              _reactComp.props.userLanguage,
              "Image size must be at least"
            ) + " 440 x 280 px"
        });
      } else {
        _reactComp.setState({
          featuredImage: _fileName,
          file: _file,
          itemSaved: false,
          errorMessage: ""
        });
        let d = _reactComp.state.fImageDivRef;
        if (d.hasChildNodes()) {
          for (let i = 0; i < d.childNodes.length; i++) {
            d.removeChild(d.childNodes[i]);
          }
        }
        d.appendChild(image);
      }
    };
  };

  onChangePropertyHandler = (e, p, l, d) => {
    if (e.target.value.length > l) {
      return this.setState({
        errorMessage: `${getTranslatedPhrase(
          this.props.userLanguage,
          d
        )} ${getTranslatedPhrase(
          this.props.userLanguage,
          "must be smaller than"
        )} ${l} ${getTranslatedPhrase(this.props.userLanguage, "characters")}`
      });
    }
    if (p === 'category' && e.target.value !== 'select-category') {
      let a = this.state.categoriesSelect;
      let b = [];
      let i = 0;
      for (; i < a.length; i++) {
        if (a[i] === e.target.value) break;
      }
      i++;
      while (/^\s*-+/.test(a[i]) && i < a.length) {
        b.push(a[i].replace(/^\s*-+\s*/, ''));
        i++;
      }
      this.setState({ subcategoriesSelect: [...b] })
      this.onChangePropertyHandler({ target: { value: '' } }, 'subcategory', 50, 'Subcategory');
    }
    this.setState({ [p]: e.target.value, itemSaved: false, errorMessage: "" });
  }

  onChangeNewsContentHandler = e => {
    let value = e.target.innerHTML;
    if (value.length < 10000) {
      if (/<img/ig.test(value)) value = value.replace(/<img.*\/?>/ig, '');
      this.setState({ newsContent: value });
    }
  }

  onChangeScheduleDate = e => {
    let d = e.target.value;
    if (moment(d, "YYYY-MM-DD").isBefore(moment())) {
      return this.setState({
        errorMessage: getTranslatedPhrase(
          this.props.userLanguage,
          "Schedule date cannot be in the past"
        )
      });
    }
    this.setState({ scheduleDate: d, itemSaved: false, errorMessage: "" });
  };

  requiredFieldsAreFilled() {
    let res = true,
      _ = this.state;
    let _err = "";

    if (!_.category || _.category === "select-category") {
      _err = getTranslatedPhrase(
        this.props.userLanguage,
        "Select a valid category."
      );
      res = false;
    }

    if (!_.title) {
      _err =
        getTranslatedPhrase(this.props.userLanguage, "Title") +
        " " +
        getTranslatedPhrase(this.props.userLanguage, "is required.");
      res = false;
    }
    if (!_.newsContent) {
      _err =
        getTranslatedPhrase(this.props.userLanguage, "News content") +
        " " +
        getTranslatedPhrase(this.props.userLanguage, "is required.");
      res = false;
    }
    if (!_.category) {
      this.setState({ category: this.state.categoriesSelect[0] });
    }
    if (!_.featuredImage) {
      _err = getTranslatedPhrase(
        this.props.userLanguage,
        "You need to choose the main photo."
      );
      res = false;
    }
    if (_.itemSaved && res) {
      _err = getTranslatedPhrase(
        this.props.userLanguage,
        "News is already published."
      );
      res = false;
    }
    this.setState({ errorMessage: _err });
    return res;
  }

  onClearDraftHandler = e => {
    window.scrollTo(0, 0);
    let c = this.state.categoriesSelect;
    this.setState(this.initialState);
    this.setState({ categoriesSelect: c });
    let d = this.state.fImageDivRef;
    if (d.hasChildNodes()) {
      for (let i = 0; i < d.childNodes.length; i++) {
        d.removeChild(d.childNodes[i]);
      }
    }
  };

  onPublishHandler = () => {
    if (!this.requiredFieldsAreFilled()) {
      return;
    }
    
    
    this.setState({ postListingPressed: true });

    let nbd = Object.assign({}, this.state);
    nbd.title = titleCase(nbd.title);
    delete nbd.categoriesSelect;
    delete nbd.errorMessage;
    delete nbd.itemSaved;
    delete nbd.mainTitle;
    delete nbd.authorName;
    delete nbd.authorProfileImage;
    delete nbd.openModal;
    delete nbd.fImageDivRef;

    let newNewsData = new FormData();
    for (let i in nbd) {
      newNewsData.append(i, nbd[i]);
    }
    let options = {
      url: `${hostNameAndPort}/api/news/update`,
      method: "post",
      data: newNewsData
    };
    Axios(options)
      .then(result => {
        let { data } = result;
        if (!data.error) {
          this.setState({
            itemId: data.itemId,
            slug: data.slug,
            itemSaved: true,
            errorMessage: "",
            mainTitle: "Edit News",
            mainButtonLabel: "Update News",
            postListingPressed: false
          });
        } else {
          this.setState({
            errorMessage: data.error,
            itemSaved: false,
            postListingPressed: false
          });
        }
      })
      .catch(err => {
        this.setState({
          errorMessage: getTranslatedPhrase(
            this.props.userLanguage,
            "Network error ERRFEBLG01"
          ),
          itemSaved: false,
          postListingPressed: false
        });
      });
  };

  // (<Link target="_blank" to={`/news/${this.state.category}/${this.state.slug}`}>See preview</Link>)
  render() {
    let categoriesSelect = this.state.categoriesSelect.filter(v => (!/^\s*-+/.test(v))).map(v => ({ value: v, text: getTranslatedPhrase(this.props.userLanguage,titleCase(v.replace(/-/g,' '))) })).sort((a,b) => ((a.text>b.text)?1:-1));
    let styleClass = this.state.mainTitle.toLowerCase().replace(/ /g, '');
    let backgroundClass = `layout-background-new layout-background-new-${styleClass}`;

    return (
      <>

        <div className={backgroundClass} />

        <div className="main-100 add-new-page add-blog">
          <div className="main-100-header">
            <h1>
              {getTranslatedPhrase(this.props.userLanguage, this.state.mainTitle)}
            </h1>
            <p>
              {getTranslatedPhrase(
                this.props.userLanguage,
                "Share your thoughts."
              )}
            </p>
          </div>

          <div className="add-listing add-listing_full-width">
            <div className="row">
              <div className="col span-2-of-2">
                <input
                  type="text"
                  placeholder={getTranslatedPhrase(
                    this.props.userLanguage,
                    "Title"
                  ) + ' *'}
                  value={this.state.title}
                  onChange={e =>
                    this.onChangePropertyHandler(e, "title", 100, "Title")
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col span-2-of-2">
                <input
                  type="text"
                  placeholder={getTranslatedPhrase(
                    this.props.userLanguage,
                    "Source URL"
                  )}
                  value={this.state.sourceUrl}
                  onChange={e =>
                    this.onChangePropertyHandler(e, "sourceUrl", 2000, "Source URL")
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col span-2-of-2">


              <JoditEditor 
                onBlur={this.onChangeNewsContentHandler}
                onChange={() => null}
                value={this.state.newsContent}
              />

                {/* <textarea
                  className="textarea_content"
                  placeholder={getTranslatedPhrase(this.props.userLanguage, "Write something awesome.") + ' *'}
                  onChange={e =>this.onChangePropertyHandler(e,"newsContent", 10000, "news content")}
                  value={this.state.newsContent}
                ></textarea> */}
              </div>
            </div>

            <div className="row row-border-divider" />

            <div className="row">
              {/* <div className="col span-1-of-3">
                            <label>{getTranslatedPhrase(this.props.userLanguage,'Privacy Settings')} *</label>
                            <div className="form-input-select"> 
                                <select value={this.state.privacy} onChange={(e) => this.onChangePropertyHandler(e, 'privacy', 10, 'Privacy')}>
                                    <option value="public">{getTranslatedPhrase(this.props.userLanguage,'Public')}</option>
                                    <option value="followers">{getTranslatedPhrase(this.props.userLanguage,'Followers')}</option>
                                    <option value="private">{getTranslatedPhrase(this.props.userLanguage,'Private')}</option>
                                </select>
                            </div>
                        </div> */}

              <div className="col span-1-of-3">
                <label>
                  {getTranslatedPhrase(this.props.userLanguage, "Category")} *
              </label>
                <div className="form-input-select">
                  <select
                    value={this.state.category}
                    onChange={e =>
                      this.onChangePropertyHandler(e, "category", 50, "Category")
                    }
                  >
                    <option value="select-category">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Select Category"
                      )}{" "}
                    </option>
                    {categoriesSelect.map((v, i) => (
                      <option value={v.value} key={i}>
                        {v.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {!this.state.subcategoriesSelect || !this.state.subcategoriesSelect.length ? null : (
                <div className="col span-1-of-3">
                  <label>
                    {getTranslatedPhrase(this.props.userLanguage, "Subcategory")} *
              </label>
                  <div className="form-input-select">
                    <select
                      value={this.state.subcategory}
                      onChange={e =>
                        this.onChangePropertyHandler(e, "subcategory", 50, "Subcategory")
                      }
                    >
                      <option value="select-subcategory">
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "Select Subcategory"
                        )}{" "}
                      </option>
                      {this.state.subcategoriesSelect.map((v, i) => (
                        <option value={v} key={'sub' + i}>
                          {getTranslatedPhrase(
                            this.props.userLanguage,
                            titleCase(v.replace(/-/g, " "))
                          )}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="col span-1-of-3">
                <label>
                  {getTranslatedPhrase(this.props.userLanguage, "Schedule Later")}
                </label>
                <input
                  type="date"
                  value={this.state.scheduleDate}
                  onChange={this.onChangeScheduleDate}
                />
              </div>
            </div>

            <div className="row row-border-divider" />

            <div className="row">
              <div className="col span-2-of-2">
                <label>
                  {getTranslatedPhrase(this.props.userLanguage, "Add Main Image")}{" "}
                  *
              </label>
                <div
                  className="show-featured-image"
                  ref={n => {
                    if (!this.state.fImageDivRef)
                      this.setState({ fImageDivRef: n });
                  }}
                />
                <button
                  className="button button-clear setFeaturedImageBtn"
                  onClick={this.onClickToUploadNewPhotoHandler}
                >
                  {getTranslatedPhrase(
                    this.props.userLanguage,
                    "Upload New Photo"
                  )}
                </button>
              </div>
            </div>

            <div className="row row-border-divider" />

            <div className="row">
              <div className="col span-2-of-2">
                <textarea
                  className="textarea_keywords"
                  placeholder={
                    getTranslatedPhrase(this.props.userLanguage, "Keywords") +
                    ", " +
                    getTranslatedPhrase(this.props.userLanguage, "Example") +
                    ": " +
                    getTranslatedPhrase(
                      this.props.userLanguage,
                      "health, food, exercise, fruits, strawberries, smoothie"
                    ) +
                    "..."
                  }
                  onChange={e =>
                    this.onChangePropertyHandler(e, "hashTags", 1000, "keywords")
                  }
                  value={this.state.hashTags}
                />
              </div>
            </div>
          </div>

          <div className="submit-listing">
            <FormFieldError>{this.state.errorMessage}</FormFieldError>
            <LoadingSvg active={this.state.postListingPressed} />
            <div className="submit-listing-button">
              <button
                className="button button-white"
                onClick={this.onClearDraftHandler}
              >
                <i className="fal fa-plus" />{" "}
                {getTranslatedPhrase(this.props.userLanguage, "Add New")}
              </button>
              <button
                className="button button-regular"
                onClick={this.onPublishHandler}
              >
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  this.state.mainButtonLabel
                )}
              </button>
            </div>

            <ErrorMessages
              errorMessageClass={"error-message error-message-info"}
              errorMessageTitle={getTranslatedPhrase(
                this.props.userLanguage,
                "Success"
              )}
              errorMessageBody={getTranslatedPhrase(
                this.props.userLanguage,
                "Your draft has been published"
              )}
              show={this.state.itemSaved}
            >
              <Link
                className="button button-regular button-small dsp-in-blk mgt-sm"
                to={"/news/" + this.state.category + "/" + (this.state.subcategory || 'other') + "/" + this.state.slug}
              >
                {getTranslatedPhrase(this.props.userLanguage, "See Your News")}
              </Link>
            </ErrorMessages>
          </div>

          <CropImageModal
            active={this.state.openModal}
            onCropPhotoCompleted={this.getCroppedFeaturedImage}
            authorProfileImage={this.state.authorProfileImage}
            authorName={this.state.authorName}
            desiredWidth={440}
            desiredHeight={280}
          />
        </div>
      </>
    );
  }
}

const mapStateToEditNewsProps = (state, props) => {
  return {
    userLanguage: state.userLanguage
  };
};

export default connect(mapStateToEditNewsProps, null)(EditNews);
