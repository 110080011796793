import React from 'react';
import BlogPreview from './BlogPreview';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import BlogsSideBar from './BlogsSideBar';
import BlogsSideBarMobile from './BlogsSideBarMobile';
import BlogsFeatured from './BlogsFeatured';

function BlogsCategory() {

  return (
    <CategoryPage
      section="blogs"
      description="Read Blogs or Write Your Own"
      ItemPreview={BlogPreview}
      SideBar={BlogsSideBar}
      SideBarMobile={BlogsSideBarMobile}
      FeaturedItems={BlogsFeatured}
    />);
}

export default BlogsCategory;