import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { howRecentIsThis } from "../../../shared/util/date";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import { titleCase } from "../../../shared/util/string";
import { connect } from "react-redux";
import actionType from "../../../redux-store/action-type";
import EngageBox from "../../../shared/uielements/Engage/EngageBox";
import { Link } from "react-router-dom";
import ListingPreviewAdminPanel from "../../../shared/components/SideBar/AdminPanel/ListingPreviewAdminPanel";
import EngageButtons from "../../../shared/uielements/Engage/EngageButtons/EngageButtons";
import FavoritesButton from "../../../shared/uielements/Engage/EngageButtons/FavoritesButton";
import LoveButton from "../../../shared/uielements/Engage/EngageButtons/LoveButton";
import ViewsButton from "../../../shared/uielements/Engage/EngageButtons/ViewsButton";

class JobsPostPreview extends Component {
  onClickItem = (e) => {
    let urlSlug = `/${this.props.section || "jobs"}/${this.props.category}/${
      this.props.subcategory || "other"
    }/${this.props.slug}`;
    if (this.props.onClick !== undefined) this.props.onClick();
    // this.props.history.push(urlSlug || '/jobs');
    this.props.pageShouldRefresh(urlSlug);
  };

  render() {
    let jobType = this.props.jobType;
    if (jobType) jobType = jobType.replace(/-/g, " ");
    let experienceLevel = this.props.experienceLevel;
    if (experienceLevel) experienceLevel = experienceLevel.replace(/-/g, " ");

    let { onClickOnFavorite = () => null } = this.props;

    let linkSlug = `/${this.props.section || "jobs"}/${this.props.category}/${
      this.props.subcategory || "other"
    }/${this.props.slug}`;

    let featureImage = this.props.featuredImage;

    if (featureImage.startsWith("https://followone.blob.core.windows.net")) {
      featureImage = featureImage.replace(
        "https://followone.blob.core.windows.net",
        "https://cdn-westus2.followone.com"
      );
    }

    return (
      <div className="category-list_item">
        <Link
          to={linkSlug}
          className="store-listing-preview-img-bkg"
          onClick={this.onClickItem}
          style={{ backgroundImage: `url(${featureImage})` }}
        />

        <div className="slp">
          <div className="slp-pricing-header">
            <div className="slp-pricing-header-option">
              <i className="fal fa-business-time" />
              <p>
                {getTranslatedPhrase(this.props.userLanguage, "Type")}:<br />
                <strong>{titleCase(jobType)}</strong>
              </p>
            </div>

            <div className="slp-divider" />

            <div className="slp-pricing-header-option">
              <i className="fal fa-map-marker-alt" />
              <p>
                {getTranslatedPhrase(this.props.userLanguage, "Location")}:
                <br />
                <strong>
                  {this.props.city} {this.props.country}
                </strong>
              </p>
            </div>

            <div className="slp-divider" />

            <div className="slp-pricing-header-option">
              <i className="fal fa-calendar-alt" />
              <p>
                {getTranslatedPhrase(this.props.userLanguage, "Posted")}:<br />
                <strong>
                  {howRecentIsThis(
                    this.props.creationDate,
                    this.props.userLanguage
                  )}
                </strong>
              </p>
            </div>

            {/*<div className="slp-divider" />*/}

            {/*      <div className="slp-pricing-header-option">*/}
            {/*          <i className="fal fa-bath" />*/}
            {/*          <p>*/}
            {/*              {getTranslatedPhrase(this.props.userLanguage, 'Experience')}:<br />*/}
            {/*              <strong>{titleCase(experienceLevel)}</strong>*/}
            {/*          </p>*/}
            {/*      </div>*/}
          </div>

          <p className="slp-title">{this.props.title}</p>
          <p className="slp-brand">
            <span>{getTranslatedPhrase(this.props.userLanguage, "by")}</span>
            &nbsp;
            <strong>{this.props.authorName}</strong>&nbsp;/&nbsp;
            <span>{getTranslatedPhrase(this.props.userLanguage, "in")}</span>
            &nbsp;
            <strong className="capitalize">
              {this.props.category.replace(/-/g, " ")}
            </strong>
          </p>

          <div className="slp-buttons">
            {/* <button onClick={this.onClickItem} className="button button-clear button-small">{getTranslatedPhrase(this.props.userLanguage,'Learn More')}</button> */}
            <Link
              to={linkSlug}
              onClick={this.onClickItem}
              className="button button-clear button-small"
            >
              {getTranslatedPhrase(this.props.userLanguage, "Learn More")}
            </Link>
            <ListingPreviewAdminPanel
              itemType={"jobs"}
              itemSlug={this.props.slug}
              itemId={this.props.itemId}
              userIsAuthorized={
                this.props.isAuthor ||
                (this.props.loggedInUser &&
                  (this.props.loggedInUser.userLevel === "admin" ||
                    this.props.loggedInUser.userLevel === "customer-service"))
              }
            />
          </div>

          <div className="slp-social">
            <EngageBox
              websiteSection={"jobs"}
              itemId={this.props.itemId}
              authorId={this.props.authorId}
              onClickOnFavorite={onClickOnFavorite}
            />
          </div>

          {/* {!this.props.onlyFavoritesButton?(
                      <div className="slp-social">
                      <EngageBox
                        websiteSection={"jobs"}
                        itemId={this.props.itemId}
                        authorId={this.props.authorId}
                        onClickOnFavorite={onClickOnFavorite}
                      /></div>
                    ):(<div className="slp-social"><EngageButtons>
                        <LoveButton
                          isDisabled={true}
                          noLove={this.props.likeCount}
                          isClicked={this.props.itemIsLiked}
                          onClick={() => null}
                        />
                        <ViewsButton
                          isDisabled={true}
                          noViews={this.props.viewCount}
                          isClicked={false}
                        />
                        <FavoritesButton
                          isDisabled={false}
                          isClicked={true}
                          onClick={onClickOnFavorite}
                        />
                    </EngageButtons></div>)} */}
        </div>
      </div>
    );
  }
}

const mapStateToJobsPostPreviewProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
    loggedInUser: state.loggedInUser,
  };
};

const mapDispatchToJobsPostPreviewProps = (dispatch) => {
  return {
    pageShouldRefresh: (p) => {
      dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
    },
  };
};

export default connect(
  mapStateToJobsPostPreviewProps,
  mapDispatchToJobsPostPreviewProps
)(withRouter(JobsPostPreview));
