import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import actionType from "../../../redux-store/action-type";
import { getTranslatedPhrase } from "../../util/lang";
import { formatPriceAndCurrency } from "../../util/number";

import { Link } from "react-router-dom";
import RateButton from "../../uielements/Engage/EngageButtons/RateButton";
import EngageBox from "../../uielements/Engage/EngageBox";
import EngageButtons from "../../uielements/Engage/EngageButtons/EngageButtons";
import FavoritesButton from "../../uielements/Engage/EngageButtons/FavoritesButton";
import LoveButton from "../../uielements/Engage/EngageButtons/LoveButton";
import ViewsButton from "../../uielements/Engage/EngageButtons/ViewsButton";

// import PaperImage from '../../../assets/rip-papers.png';
import moment from "moment";
import ListingPreviewAdminPanel from "../../components/SideBar/AdminPanel/ListingPreviewAdminPanel";
import { titleCase } from "../../util/string";

class ListingPreview extends Component {
  state = {
    afterDiscountPrice: "",
    oldAfterDiscountPrice: "",
  };

  componentDidMount() {
    let c = this.props.currency;
    let p = this.props.price;
    let d = this.props.discountId;
    if (!!d) {
      let theDiscount = d.couponValue;
      if (d.couponCurrency) {
        if (d.couponCurrency === "%") {
          p = Math.round((p - (p * theDiscount) / 100) * 100) / 100;
        } else {
          theDiscount = theDiscount / 100; // Math.floor(theDiscount / 100) + (theDiscount % 100) / 100;
          p = Math.round((p - theDiscount) * 100) / 100;
        }
      }
      if (p > 0) {
        this.setState({
          afterDiscountPrice: formatPriceAndCurrency(p, c, ","),
        });
      }
    }
  }
  componentDidUpdate() {
    let c = this.props.currency;
    let p = this.props.price;
    let d = this.props.discountId;
    if (!!d) {
      let theDiscount = Number(d.couponValue);
      if (d.couponCurrency) {
        if (d.couponCurrency === "%") {
          p = Math.round((p - (p * theDiscount) / 100) * 100) / 100;
        } else {
          theDiscount = theDiscount / 100;
          p = Math.round((p - theDiscount) * 100) / 100;
        }
      }
      if (
        p > 0 &&
        (this.state.oldAfterDiscountPrice !== this.state.afterDiscountPrice ||
          !this.state.afterDiscountPrice)
      ) {
        this.setState({
          oldAfterDiscountPrice: this.state.afterDiscountPrice,
          afterDiscountPrice: formatPriceAndCurrency(p, c, ","),
        });
      }
    }
  }

  getDuration(b, e) {
    let d = moment(e).diff(moment(b), "days") + 1;
    if (d === 1) return "1 day";
    else return d + " days";
  }

  getPropertySpecs(specs) {
    let result = "";
    if (Number(specs.bedCount) > 0) {
      result = result + specs.bedCount + " Bed";
      if (Number(specs.bedCount) > 1) result = result + "s";
    }
    if (Number(specs.bathCount) > 0) {
      if (result) result = result + " | ";
      result = result + specs.bathCount + " Bath";
      if (Number(specs.bathCount) > 1) result = result + "s";
    }
    if (specs.floorSize) {
      if (result) result = result + " | ";
      result = result + specs.floorSize;
      if (Number(specs.floorSize) > 0) result = result + " sqft";
    }
    return result;
  }

  onClickItem = (e) => {
    let section = this.props.section;
    if (section === "cars") section = "autos";
    let urlSlug = `/${section || "store"}/${this.props.category}/${
      this.props.subcategory || "other"
    }/${this.props.slug}`;
    if (this.props.onClick !== undefined) this.props.onClick();
    if (this.props.onToggleSidebar !== undefined) this.props.onToggleSidebar();
    // this.props.history.push(urlSlug || '/store');
    this.props.pageShouldRefresh(urlSlug);
  };

  render() {
    let thisCouponValue = !this.props.discountId
      ? 0
      : this.props.discountId.couponCurrency === "%"
      ? Number(this.props.discountId.couponValue)
      : Math.floor(Number(this.props.discountId.couponValue) / 100) +
        (Number(this.props.discountId.couponValue) % 100) / 100;
    let saleButtonCta =
      !!this.props.callForPricing || !this.props.addDiscount
        ? this.props.rentOrSell && this.props.rentOrSell === "rent"
          ? this.props.category === "commercial"
            ? getTranslatedPhrase(this.props.userLanguage, "Lease Now")
            : getTranslatedPhrase(this.props.userLanguage, "Rent Now")
          : getTranslatedPhrase(this.props.userLanguage, "Shop Now")
        : this.props.addDiscount === "coupon"
        ? getTranslatedPhrase(this.props.userLanguage, "Get") +
          " " +
          formatPriceAndCurrency(
            thisCouponValue,
            this.props.discountId.couponCurrency,
            ","
          ) +
          " " +
          getTranslatedPhrase(this.props.userLanguage, "Coupon")
        : getTranslatedPhrase(this.props.userLanguage, "Get Deal");
    if (
      this.props.section === "services" &&
      saleButtonCta === getTranslatedPhrase(this.props.userLanguage, "Shop Now")
    )
      saleButtonCta = getTranslatedPhrase(
        this.props.userLanguage,
        "Get Service"
      );

    let buttonClassname = "button button-clear button-small";
    if (!this.props.callForPricing && !!this.props.addDiscount)
      buttonClassname = "button button-sale button-small";

    let { onClickOnFavorite = () => null } = this.props;

    let section = this.props.section;
    if (section === "cars") section = "autos";
    let linkSlug = `/${section || "store"}/${this.props.category}/${
      this.props.subcategory || "other"
    }/${this.props.slug}`;
    let imageSrc =
      (this.props.featuredImage || "").indexOf("http") >= 0
        ? this.props.featuredImage
        : this.props.featuredImage;

    if (imageSrc.startsWith("https://followone.blob.core.windows.net")) {
      imageSrc = imageSrc.replace(
        "https://followone.blob.core.windows.net",
        "https://cdn-westus2.followone.com"
      );
    }

    return (
      <div className="category-list_item store-item-preview">
        {/* <button onClick={this.onClickItem}>
                    <img src={this.props.featuredImage} alt={this.props.title} />
                </button> */}

        <Link
          to={linkSlug}
          className="store-listing-preview-img-bkg"
          onClick={this.onClickItem}
          style={{ backgroundImage: `url(${imageSrc})` }}
        />

        <div className="slp">
          <div className="slp-pricing-header">
            <div className="slp-pricing-header-price">
              {["realestate"].indexOf(this.props.section) < 0 ? null : (
                <p>
                  {this.props.rentOrSell === "sell" && "Sale Price"}
                  {this.props.rentOrSell === "rent" &&
                    this.props.category !== "commercial" &&
                    "Rent"}
                  {this.props.rentOrSell === "rent" &&
                    this.props.category === "commercial" &&
                    "Lease"}
                </p>
              )}

              {this.props.section === "services" &&
              this.props.callForPricing !== "call-for-pricing" &&
              this.props.volunteer === "volunteer" ? (
                <p className="store-listing-preview_price store-listing-preview_price-call">
                  Volunteer
                </p>
              ) : !!this.props.callForPricing &&
                this.props.callForPricing === "call-for-pricing" ? (
                <p className="store-listing-preview_price store-listing-preview_price-call">
                  {getTranslatedPhrase(
                    this.props.userLanguage,
                    "Call For Pricing"
                  )}
                </p>
              ) : !this.props.addDiscount ? (
                <p className="store-listing-preview_price">
                  {formatPriceAndCurrency(
                    this.props.price,
                    this.props.currency,
                    ","
                  )}
                  <span className="store-listing-preview_price-attr">
                    {this.props.rentOrSell && this.props.rentOrSell === "rent"
                      ? " / " +
                        getTranslatedPhrase(this.props.userLanguage, "month")
                      : ""}
                    {!this.props.rateType
                      ? ""
                      : " / " +
                        getTranslatedPhrase(
                          this.props.userLanguage,
                          titleCase(this.props.rateType)
                        )}
                  </span>
                </p>
              ) : (
                <p className="store-listing-preview_price">
                  {!this.state.afterDiscountPrice ? (
                    getTranslatedPhrase(this.props.userLanguage, "FREE")
                  ) : (
                    <>
                      {this.state.afterDiscountPrice}
                      <span className="store-listing-preview_price-attr">
                        {this.props.rentOrSell &&
                        this.props.rentOrSell === "rent"
                          ? " / " +
                            getTranslatedPhrase(
                              this.props.userLanguage,
                              "month"
                            )
                          : ""}
                        {!this.props.rateType
                          ? ""
                          : " / " +
                            getTranslatedPhrase(
                              this.props.userLanguage,
                              titleCase(this.props.rateType)
                            )}{" "}
                      </span>
                    </>
                  )}{" "}
                  <span className="deals-was-price">
                    {formatPriceAndCurrency(
                      this.props.price,
                      this.props.currency,
                      ","
                    )}
                    {/*<span className="small-font">*/}
                    {/* {(this.props.rentOrSell && this.props.rentOrSell ==='rent')*/}
                    {/*   ? ' / ' + getTranslatedPhrase(this.props.userLanguage,'m')*/}
                    {/*   : '' }*/}
                    {/*    {(!this.props.rateType)*/}
                    {/*      ? ''*/}
                    {/*      : ' / ' + getTranslatedPhrase(this.props.userLanguage, titleCase(this.props.rateType))*/}
                    {/*    }*/}
                    {/*  </span>*/}
                  </span>
                </p>
              )}
            </div>

            {["autos", "store"].indexOf(this.props.section) < 0 ? null : (
              <>
                <div className="slp-divider" />
                <div className="slp-pricing-header-option">
                  {this.props.productCondition === "Used" && (
                    <i className="fal fa-box-open" />
                  )}
                  {this.props.productCondition === "New" && (
                    <i className="fal fa-box-alt" />
                  )}
                  <p>
                    {getTranslatedPhrase(this.props.userLanguage, "Condition")}:
                    <br />{" "}
                    <strong>
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        this.props.productCondition
                      )}
                    </strong>
                  </p>
                </div>
              </>
            )}

            {["store"].indexOf(this.props.section) < 0 ? null : (
              <>
                <div className="slp-divider" />
                <div className="slp-pricing-header-option">
                  {this.props.pickUp === "sell-online" && (
                    <i className="fal fa-truck" />
                  )}
                  {(this.props.pickUp === "sell-local" ||
                    this.props.pickUp === "local-only") && (
                    <i className="fal fa-map-marker-alt" />
                  )}
                  <p>
                    {getTranslatedPhrase(this.props.userLanguage, "Delivery")}:
                    <br />
                    {this.props.pickUp === "sell-online" && (
                      <strong>
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "Shipping"
                        )}
                      </strong>
                    )}
                    {(this.props.pickUp === "sell-local" ||
                      this.props.pickUp === "local-only") && (
                      <strong>
                        {getTranslatedPhrase(this.props.userLanguage, "Pickup")}
                      </strong>
                    )}
                  </p>
                </div>
              </>
            )}

            {["realestate"].indexOf(this.props.section) < 0 ? null : (
              <>
                <div className="slp-divider" />
                <div className="slp-pricing-header-option">
                  <i className="fal fa-bed-alt" />
                  <p>
                    {getTranslatedPhrase(this.props.userLanguage, "Bedrooms")}:
                    <br /> <strong>{this.props.bedCount}</strong>
                  </p>
                </div>
                <div className="slp-divider" />
                <div className="slp-pricing-header-option">
                  <i className="fal fa-bath" />
                  <p>
                    {getTranslatedPhrase(this.props.userLanguage, "Bathrooms")}:
                    <br /> <strong>{this.props.bathCount}</strong>
                  </p>
                </div>
              </>
            )}

            {["travel"].indexOf(this.props.section) < 0 ? null : (
              <>
                <div className="slp-divider" />
                <div className="slp-pricing-header-option">
                  <i className="fal fa-plane-departure" />
                  <p>
                    {getTranslatedPhrase(this.props.userLanguage, "Departure")}:
                    <br />{" "}
                    <strong>
                      {moment(this.props.startDate).format("MMM Do YY")}
                    </strong>
                  </p>
                </div>
                <div className="slp-divider" />
                <div className="slp-pricing-header-option">
                  <i className="fal fa-calendar-alt" />
                  <p>
                    {getTranslatedPhrase(this.props.userLanguage, "Duration")}:
                    <br />{" "}
                    <strong>
                      {this.getDuration(
                        this.props.startDate,
                        this.props.endDate
                      )}
                    </strong>
                  </p>
                </div>
              </>
            )}
          </div>

          <p className="slp-title">{this.props.title}</p>
          <p className="slp-brand">
            <span>{getTranslatedPhrase(this.props.userLanguage, "By")}</span>{" "}
            {this.props.authorName}
          </p>

          {["realestate"].indexOf(this.props.section) < 0 ? null : (
            <p className="slp-brand" style={{ marginTop: ".4rem" }}>
              <span
                style={{
                  display: "inline-block",
                  marginRight: ".2rem",
                  marginLeft: ".3rem",
                }}
              >
                <i className="fal fa-map-marker-alt" />
              </span>{" "}
              <strong>
                {titleCase(this.props.city)}, {this.props.state}
              </strong>
            </p>
          )}

          {["events", "jobs", "realestate"].indexOf(this.props.section) >=
          0 ? null : (
            <div className="slp-rating">
              <EngageButtons>
                <RateButton
                  noStars={this.props.averageRating}
                  noReviews={this.props.reviewCount}
                />
                {this.props.reviewCount === 1 ? "Review" : "Reviews"}
              </EngageButtons>
            </div>
          )}

          <div className="slp-buttons">
            <Link
              to={linkSlug}
              onClick={this.onClickItem}
              className={buttonClassname}
            >
              {saleButtonCta}
            </Link>
            <ListingPreviewAdminPanel
              itemType={this.props.section}
              itemSlug={this.props.slug}
              itemId={this.props.itemId}
              userIsAuthorized={
                this.props.isAuthor ||
                (this.props.loggedInUser &&
                  (this.props.loggedInUser.userLevel === "admin" ||
                    this.props.loggedInUser.userLevel === "customer-service"))
              }
            />
          </div>

          <div className="slp-social">
            <EngageBox
              websiteSection={this.props.section}
              itemId={this.props.itemId}
              authorId={this.props.authorId}
              onClickOnFavorite={onClickOnFavorite}
            />
          </div>

          {/* {!this.props.onlyFavoritesButton ? (
                        <div className="slp-social">
                            <EngageBox
                                websiteSection={this.props.section}
                                itemId={this.props.itemId}
                                authorId={this.props.authorId}
                                onClickOnFavorite={onClickOnFavorite}
                            />
                        </div>

                    ) : (
                            <div className="slp-social">
                                <EngageButtons>
                                    <LoveButton
                                        isDisabled={true}
                                        noLove={this.props.likeCount}
                                        isClicked={this.props.itemIsLiked}
                                        onClick={() => null}
                                    />
                                    <ViewsButton
                                        isDisabled={true}
                                        noViews={this.props.viewCount}
                                        isClicked={false}
                                    />
                                    <FavoritesButton
                                        isDisabled={false}
                                        isClicked={true}
                                        onClick={onClickOnFavorite}
                                    />
                                </EngageButtons>
                            </div>
                        )} */}
        </div>
      </div>
    );
  }
}

const mapStateToListingPreviewProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
    loggedInUser: state.loggedInUser,
  };
};

const mapDispatchToListingPreviewProps = (dispatch) => {
  return {
    pageShouldRefresh: (p) => {
      dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
    },
  };
};

export default connect(
  mapStateToListingPreviewProps,
  mapDispatchToListingPreviewProps
)(withRouter(ListingPreview));
