import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PageNumbers from '../../../shared/uielements/Pagination/page-numbers';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { countryList, titleCase } from '../../../shared/util/string';
import SectionLoader from '../../../shared/util/section-loader';
import { Helmet } from "react-helmet";
import CategoryLoader from "../../../shared/templates/CategoryTemplate/category-loader";

// const MAX_TIME = 7000;
const REQUEST_IS_SENT_TIMEOUT = 1000;
let requestIsSentOnAutoCategoryPage = false;

class CategoryPage extends Component {
  state = {
    filter: '',
    value: '',
    subcategory: '',
    currentPage: 1,
    itemsPerPage: 20,
    loadedNew: false,
    loadedOld: false,
    pageRefresh: '',
    totalCountAutos: 0,
    totalCountCars: 0,
    itemsAutos: [],
    itemsCars: [],
    LoaderAutos: null,
    LoaderCars: null,
    // userCountry: 'No Country',
    isProfilePageConfirmed: false,
  }

  componentDidMount() {
    // Trigger the update
    this.setState({
      value: 'all',
      subcategory: '',
      category: this.props.section,
      startTimePassed: Date.now(),
      isProfilePageConfirmed: this.props.isProfilePage,
    });

  }

  componentDidUpdate(prevProps, prevState) {


    try {
      // PROFILE PAGE
      if ((this.state.Loader && !this.state.isProfilePageConfirmed && this.props.isProfilePage) || this.props.section !== this.state.category) {

        let updatedSection = this.props.section;

        if (updatedSection === 'autos' && this.props.dealerId) {
          updatedSection = 'cars'
        }

        this.state.Loader.updateSection(updatedSection, true)
        this.setState({ isProfilePageConfirmed: true })

        if (this.props.section !== this.state.category) {
          this.setState({
            isProfilePageConfirmed: false,
            category: this.props.section
          })
        }
        return;
      }



      // Check for latitude and longitude
      // let timePassed = Date.now() - this.state.startTimePassed;
      // if (this.props.clientLatitude === 0 && this.props.clientLongitude === 0 && timePassed < 5000) {
      //   return;
      // }

      if (this.state.itemsAutos.length !== this.state.totalCountAutos && this.state.LoaderAutos.items.length === this.state.totalCountAutos) {
        this.setState({ itemsAutos: this.state.LoaderAutos.items });
        return;
      }

      let s_q = JSON.parse(this.props.searchQuery || '{}');
      // let countryUpdated = s_q.countryUpdated;
      // let currentCountry = s_q.country;
      // let currentRegion = s_q.region;


      // Get the current page
      const urlParams = new URLSearchParams(window.location.search);
      const currentPage = Number(urlParams.get('page')) || 1;
      let m = this.props.match, formParams = {}, formParams_Cars = {};

      // When the category changes
      let localCategory, localSubcategory;
      if (typeof m.params.storeCategory !== 'undefined') {
        localCategory = m.params.storeCategory;
      } else {
        localCategory = '';
      }

      // When the subcategory changes
      if (typeof m.params.storeSubcategory !== 'undefined') {
        localSubcategory = m.params.storeSubcategory;
      } else {
        localSubcategory = '';
      }
      let categoryChanged = (m.url.indexOf('/autos/search/') < 0) && ((this.state.value !== localCategory) || (this.state.subcategory !== localSubcategory));

      // When the search query changes
      let q = '';

      // console.log(m.params);

      if (typeof m.params.storeQuery !== 'undefined') {
        q = m.params.storeQuery;
      }

      let nextState = {};


      if (categoryChanged) {
        nextState = {
          filter: 'category',
          value: localCategory,
          subcategory: localSubcategory
        };

        this.setState(nextState);
        // setTimeout(() => {
        //   if (!this.state.loadedOld || !this.state.loadedNew)
        //     this.setState({ loadedOld: true, loadedNew: false });
        // }, MAX_TIME);

        let f = {
          category: localCategory,
          subcategory: localSubcategory,
          // country: countryUpdated
        };

        // console.log('1', f.category)

        // PROFILE PAGE
        if (this.state.isProfilePageConfirmed) {
          if (this.props.dealerId !== '') {
            f.dealer = this.props.dealerId;
            f.author = this.props.userId;
            f.category = null;
            f.profilePageAuto = true;
          } else {
            f.author = this.props.userId;
            f.category = null;
            f.profilePageAuto = true;
          }
        }


        let f_Cars = {
          showInactive: true,
          category: localCategory,
          subcategory: localSubcategory,
          // country: countryUpdated
        };

        // console.log('2', f.category)

        // PROFILE PAGE
        if (this.state.isProfilePageConfirmed) {
          if (this.props.dealerId !== '') {
            f_Cars.dealer = this.props.dealerId;
            // f_Cars.author = this.props.userId;
            f_Cars.category = null;
            f_Cars.profilePageAuto = true;
          } else {
            f_Cars.author = this.props.userId;
            f_Cars.category = null;
            f_Cars.profilePageAuto = true;
          }
        }


        // if (currentCountry === countryUpdated) {
        // f.state = currentRegion;
        // f_Cars.state = currentRegion;
        // }

        for (const [key, value] of urlParams.entries()) {
          f[key] = value;
          f_Cars[key] = value;
        }


        if (this.state.isProfilePageConfirmed) {
          f.category = null;
          f.profilePageAuto = true;
          f_Cars.category = null;
          f_Cars.profilePageAuto = true;
        }

        // if (currentCountry === countryUpdated) {
        //   delete f.country;
        //   delete f_Cars.country;
        // }
        formParams = {
          filter: JSON.stringify(f),
          page: currentPage,
          // geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude })
        };

        formParams_Cars = {
          filter: JSON.stringify(f_Cars),
          page: currentPage,
          // geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude }),
          // noCount: true
        };

        if (!this.state.LoaderAutos && !requestIsSentOnAutoCategoryPage) {
          let nextState;

          // if (countryUpdated === 'USA' || countryUpdated === 'Canada') {
          //   nextState = { loadedNew: false, loadedOld: false };
          // }
          // else {
          //   nextState = {
          //     loadedNew: true,
          //     loadedOld: false,
          //   };
          // }

          nextState = {
            loadedNew: true,
            loadedOld: false,
          };

          this.setState(nextState);

          // console.log('new cat init loader')
          // this.initLoader(currentPage, formParams, formParams_Cars, countryUpdated === 'USA' || countryUpdated === 'Canada');
          this.initLoader(currentPage, formParams, formParams_Cars, false);
          requestIsSentOnAutoCategoryPage = true;
          setTimeout(() => {
            requestIsSentOnAutoCategoryPage = false;
          }, REQUEST_IS_SENT_TIMEOUT);
          return;
        }

        if (this.state.LoaderAutos && this.state.LoaderCars) {
          // if (countryUpdated === 'USA' || countryUpdated === 'Canada') {
          //   this.state.LoaderAutos.pageCount = 5;
          //   // this.state.LoaderCars.pageCount = this.state.itemsPerPage - 5;
          //   this.state.LoaderCars.pageCount = this.state.itemsPerPage;
          // } else {
          //   this.state.LoaderAutos.pageCount = this.state.itemsPerPage;
          //   this.state.LoaderCars.pageCount = 0;
          // }

          // Added by Felix 
          this.state.LoaderAutos.pageCount = this.state.itemsPerPage;
          this.state.LoaderCars.pageCount = this.state.itemsPerPage;
        }

        if (!requestIsSentOnAutoCategoryPage) {
          let nextState;

          // if (countryUpdated === 'USA' || countryUpdated === 'Canada') nextState = { loadedNew: false, loadedOld: false };
          // else nextState = {
          //   loadedOld: false,
          //   loadedNew: true,
          //   totalCountCars: 0,
          //   itemsCars: []
          // };

          nextState = {
            loadedOld: false,
            loadedNew: true,
            totalCountCars: 0,
            itemsCars: []
          };

          this.setState(nextState);
          // console.log('new cat update form params')
          // if (f_Cars.country && (f_Cars.country === 'USA' || f_Cars.country === 'Canada')) {
          //   this.state.LoaderCars.updateFormParams(formParams_Cars);
          // }

          // Added by Felix
          if (this.state.LoaderCars) {
            this.state.LoaderCars.updateFormParams(formParams_Cars);
          }

          this.state.LoaderAutos.updateFormParams(formParams); // UN-COMMENT
          requestIsSentOnAutoCategoryPage = true;
          setTimeout(() => {
            requestIsSentOnAutoCategoryPage = false;
          }, REQUEST_IS_SENT_TIMEOUT);
        }

      } else if (q && (this.state.value !== q)) {

        let nextState = {
          filter: 'keywords',
          value: q
        };

        this.setState(nextState);

        let f = {
          // keywords: q,
          // country: countryUpdated
        };

        // PROFILE PAGE
        if (this.state.isProfilePageConfirmed) {
          if (this.props.dealerId !== '') {
            f.dealer = this.props.dealerId;
            f.author = this.props.userId;
            f.category = null;
            f.profilePageAuto = true;
          } else {
            f.author = this.props.userId;
            f.category = null;
            f.profilePageAuto = true;
          }
        }

        let f_Cars = {
          showInactive: true,
          // country: countryUpdated
        };

        // PROFILE PAGE
        if (this.state.isProfilePageConfirmed) {
          if (this.props.dealerId !== '') {
            f_Cars.dealer = this.props.dealerId;
            // f_Cars.author = this.props.userId;
            f_Cars.category = null;
            f_Cars.profilePageAuto = true;
          } else {
            f_Cars.author = this.props.userId;
            f_Cars.category = null;
            f_Cars.profilePageAuto = true;
          }
        }

        for (const [key, value] of urlParams.entries()) {
          f[key] = value;
          f_Cars[key] = value;
        }
        // if (currentCountry === f.country && !f.state) {
        // f.state = currentRegion;
        // f_Cars.state = currentRegion;
        // }


        if (this.state.isProfilePageConfirmed) {
          f.category = null;
          f.profilePageAuto = true;
          f_Cars.category = null;
          f_Cars.profilePageAuto = true;
        }

        formParams = {
          filter: JSON.stringify(f),
          page: currentPage,
          // geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude })
        };
        formParams_Cars = {
          filter: JSON.stringify(f_Cars),
          page: currentPage,
          // geolocation: JSON.stringify({ lat: this.props.clientLatitude, lng: this.props.clientLongitude }),
          // noCount: true
        };

        if (!this.state.LoaderAutos && !requestIsSentOnAutoCategoryPage) {
          // console.log('new search init loader')
          // this.initLoader(currentPage, formParams, formParams_Cars, f.country === 'USA' || f.country === 'Canada');
          this.initLoader(currentPage, formParams, formParams_Cars, false);
          requestIsSentOnAutoCategoryPage = true;
          setTimeout(() => {
            requestIsSentOnAutoCategoryPage = false;
          }, REQUEST_IS_SENT_TIMEOUT);
          return;
        }
        if (this.state.LoaderAutos && this.state.LoaderCars) {
          // if (f.country === 'USA' || f.country === 'Canada') {
          //   this.state.LoaderAutos.pageCount = 5;
          //   // this.state.LoaderCars.pageCount = this.state.itemsPerPage - 5;
          //   this.state.LoaderCars.pageCount = this.state.itemsPerPage;
          // } else {
          //   this.state.LoaderAutos.pageCount = this.state.itemsPerPage;
          //   this.state.LoaderCars.pageCount = 0;
          // }

          this.state.LoaderCars.pageCount = this.state.itemsPerPage;
          this.state.LoaderAutos.pageCount = this.state.itemsPerPage;
        }

        if (!requestIsSentOnAutoCategoryPage) {
          let nextState;
          // if (f.country === 'USA' || f.country === 'Canada') nextState = { loadedNew: false, loadedOld: false };
          // else nextState = {
          //   loadedOld: false,
          //   loadedNew: true,
          //   totalCountCars: 0,
          //   itemsCars: []
          // };

          nextState = {
            loadedOld: false,
            loadedNew: true,
            totalCountCars: 0,
            itemsCars: []
          };

          this.setState(nextState);
          // console.log('new search update form params')
          // if (f_Cars.country && (f_Cars.country === 'USA' || f_Cars.country === 'Canada')) {
          //   this.state.LoaderCars.updateFormParams(formParams_Cars);
          // }

          if (this.state.LoaderCars) {
            this.state.LoaderCars.updateFormParams(formParams_Cars);
          }

          this.state.LoaderAutos.updateFormParams(formParams); // UN-COMMENT
          requestIsSentOnAutoCategoryPage = true;
          setTimeout(() => {
            requestIsSentOnAutoCategoryPage = false;
          }, REQUEST_IS_SENT_TIMEOUT);
        }
      } else if (currentPage !== this.state.currentPage) {
        if (currentPage < this.state.currentPage && this.state.LoaderAutos && this.state.LoaderCars) {
          // if (countryUpdated === 'USA' || countryUpdated === 'Canada') {
          //   this.state.LoaderAutos.pageCount = 5;
          //   // this.state.LoaderCars.pageCount = this.state.itemsPerPage - 5;
          //   this.state.LoaderCars.pageCount = this.state.itemsPerPage;
          // } else {
          //   this.state.LoaderAutos.pageCount = this.state.itemsPerPage;
          //   this.state.LoaderCars.pageCount = 0;
          // }

          this.state.LoaderCars.pageCount = this.state.itemsPerPage;
          this.state.LoaderAutos.pageCount = this.state.itemsPerPage;
        }
        this.setState({
          currentPage
        });
        // setTimeout(() => {
        //   if (!this.state.loadedOld || !this.state.loadedNew)
        //     this.setState({ loadedOld: true, loadedNew: false });
        // }, MAX_TIME);
        if (!requestIsSentOnAutoCategoryPage) {
          let nextState;
          // if (countryUpdated === 'USA' || countryUpdated === 'Canada') nextState = { loadedNew: false, loadedOld: false };
          // else nextState = {
          //   loadedOld: false,
          //   loadedNew: true,
          //   totalCountCars: 0,
          //   itemsCars: []
          // };

          nextState = {
            loadedOld: false,
            loadedNew: true,
            totalCountCars: 0,
            itemsCars: []
          };

          this.setState(nextState);
          // console.log('new page update form params')
          this.state.LoaderCars.updatePage(currentPage);
          this.state.LoaderAutos.updatePage(currentPage); // UN-COMMENT
          requestIsSentOnAutoCategoryPage = true;
          setTimeout(() => {
            requestIsSentOnAutoCategoryPage = false;
          }, REQUEST_IS_SENT_TIMEOUT);
        }
      }
    } catch (err) {
      if (process.env.NODE_ENV === 'development') console.log(err);
    }
  }

  initLoader = (page, formParams_Autos, formParams_Cars, fromUSA) => {
    let itemsPerPage = this.state.itemsPerPage;
    let pageCount = 0;
    if (!fromUSA) {
      pageCount = itemsPerPage;
    } else {
      pageCount = 5;
    }
    let nextState = {};
    if (!fromUSA) {
      nextState = {
        LoaderAutos: new SectionLoader({
          section: 'autos',
          cb: this.onLoadAutos,
          options: {
            page,
            pageCount: itemsPerPage,
            formParams: formParams_Autos
          }
        }),
        LoaderCars: new SectionLoader({
          section: 'cars',
          cb: this.onLoadCars,
          options: {
            page,
            // noItems: true,
            // noCount: true,
            pageCount: itemsPerPage,
            formParams: formParams_Cars
          }
        })
      }
    } else {
      nextState = {
        LoaderAutos: new SectionLoader({
          section: 'autos',
          cb: this.onLoadAutos,
          options: {
            page,
            // noItems: true, // REMOVE
            // noCount: true, // REMOVE
            pageCount,
            formParams: formParams_Autos
          }
        }),
        LoaderCars: new SectionLoader({
          section: 'cars',
          cb: this.onLoadCars,
          options: {
            page,
            // pageCount: itemsPerPage, // REMOVE
            // noCount: true, // REMOVE
            // pageCount: itemsPerPage - pageCount, // UN-COMMENT
            pageCount: itemsPerPage, // UN-COMMENT
            formParams: formParams_Cars
          }
        })
      }
    }
    this.setState(nextState);
  }

  onLoadAutos = (err) => {
    if (err && (process.env.NODE_ENV === 'development')) console.log(err);
    // window.scrollTo(0, 0);
    let nextState;
    if (!this.state.LoaderAutos)
      nextState = {
        totalCountAutos: 0,
        itemsAutos: [],
        // loadedOld: true
      };
    else nextState = {
      totalCountAutos: this.state.LoaderAutos.totalCount,
      itemsAutos: this.state.LoaderAutos.items,
      loadedOld: true
    };
    // const urlParams = new URLSearchParams(window.location.search);
    // const c = urlParams.get('country') || getCountryFilter(this.props.searchQuery);
    // if (c !== 'USA' && c !== 'Canada') nextState.loaded = true;

    // if (this.state.LoaderAutos && !this.state.LoaderAutos.items.length && !this.state.LoaderCars) nextState.loaded = false;
    this.setState(nextState);
  }

  onLoadCars = (err) => {
    if (err && (process.env.NODE_ENV === 'development')) {
      // console.log(err);
    }
    // window.scrollTo(0, 0);
    let nextState, totalCountCars = 0;
    if (this.state.LoaderCars && this.state.LoaderCars.items.length) totalCountCars = 20;


    if (!this.state.LoaderCars)
      nextState = {
        // loadedNew: true,
        totalCountCars: 0,
        itemsCars: []
      };
    else nextState = {
      loadedNew: true,
      totalCountCars: this.state.LoaderCars.totalCount,
      itemsCars: this.state.LoaderCars.items
    };

    // const urlParams = new URLSearchParams(window.location.search);
    // const c = urlParams.get('country') || getCountryFilter(this.props.searchQuery);
    // if (c === 'USA' || c === 'Canada') nextState.loaded = true;
    // if (this.state.LoaderCars && this.state.LoaderAutos && !this.state.LoaderAutos.items.length && this.state.LoaderAutos.pageCount) {
    //   nextState.loaded = false;
    // }

    // let s_q = JSON.parse(this.props.searchQuery || '{}');
    // let countryUpdated = s_q.countryUpdated;
    // if (this.state.LoaderCars && this.state.LoaderAutos && !this.state.LoaderAutos.items.length && this.state.LoaderAutos.pageCount && (countryUpdated === 'USA' || countryUpdated === 'Canada')) {
    //   this.setState({ 
    //     totalCountCars: 0,
    //     itemsCars: []
    //   });
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const currentPage = Number(urlParams.get('page')) || 1;
    //   let nextPage = Math.floor(this.state.LoaderCars.pageCount * currentPage / this.state.itemsPerPage) + 1;
    //   this.state.LoaderCars.pageCount = this.state.itemsPerPage;
    //   this.state.LoaderAutos.pageCount = 0;
    //   this.state.LoaderCars.updatePage(nextPage, true);
    // } else 
    this.setState(nextState);
  }

  onUpdatePageNumber = (n = 1) => {
    const urlParams = new URLSearchParams(window.location.search);
    let s = 'page=' + n + '&';
    for (const [key, value] of urlParams.entries())
      if (key === 'category' || key === 'subcategory') s = s + key + '=' + value.replace(/&/g, '%26') + '&';
      else if (key !== 'page') s = s + key + '=' + value + '&';
    s = s.substring(0, s.length - 1);

    // PROFILE PAGE
    if (this.state.isProfilePageConfirmed) {
      this.props.history.push(this.props.match.url + '?' + encodeURI(s));
    } else {
      this.props.history.push(this.props.match.url + '?' + encodeURI(s));
    }

    window.scrollTo(0, 0);
  }

  onUpdateCategory = (value) => {
    const urlParams = new URLSearchParams(window.location.search);
    const PARAMS = ['country', 'state'];
    let s = '';
    for (const [key, value] of urlParams.entries())
      if (PARAMS.indexOf(key) >= 0) s = s + key + '=' + value + '&';

    s = s + `category=${value.replace(/&/g, '%26')}&subcategory=`;
    if (!value || value === 'all') {


      // PROFILE PAGE
      if (this.state.isProfilePageConfirmed) {
        this.props.history.push('/' + this.props.slug + '/' + this.props.section + '/?' + encodeURI(s));
      } else {
        this.props.history.push(`/${this.props.section}?` + encodeURI(s));
      }

    } else {

      // PROFILE PAGE
      if (this.state.isProfilePageConfirmed) {
        this.props.history.push('/' + this.props.slug + '/' + this.props.section + '/' + value + '?' + encodeURI(s));
      } else {
        this.props.history.push(`/${this.props.section}/${value}?` + encodeURI(s));
      }
    }
    window.scrollTo(0, 0);
  }

  onUpdateSubcategory = (category, value) => {
    const urlParams = new URLSearchParams(window.location.search);
    const PARAMS = ['country', 'state'];
    let s = '';
    for (const [key, value] of urlParams.entries())
      if (PARAMS.indexOf(key) >= 0) s = s + key + '=' + value + '&';

    s = s + `category=${category.replace(/&/g, '%26')}&subcategory=${value.replace(/&/g, '%26')}`;
    if (!value || value === 'all') {

      // PROFILE PAGE
      if (this.state.isProfilePageConfirmed) {
        this.props.history.push('/' + this.props.slug + '/' + this.props.section + '/' + category + '?' + encodeURI(s));
      } else {
        this.props.history.push(`/${this.props.section}/${category}?` + encodeURI(s));
      }
    } else {


      // PROFILE PAGE
      if (this.state.isProfilePageConfirmed) {
        this.props.history.push('/' + this.props.slug + '/' + this.props.section + '/' + category + '/' + value + '?' + encodeURI(s));
      } else {
        this.props.history.push(`/${this.props.section}/${category}/${value}?` + encodeURI(s));
      }
    }
    window.scrollTo(0, 0);
  }

  onSubmitSearchHandler = (q) => {
    if (!q || !q.keywords) return;
    let s = '';
    for (let i in q) s = s + i + '=' + q[i] + '&';
    s = s.substring(0, s.length - 1);


    // PROFILE PAGE
    if (this.state.isProfilePageConfirmed) {
      this.props.history.push('/' + this.props.slug + '/autos/search/' + encodeURI(q.keywords) + '?' + encodeURI(s));
    } else {
      this.props.history.push('/autos/search/' + encodeURI(q.keywords) + '?' + encodeURI(s));

    }
  }

  getPageTitle = () => {
    let s = this.state.value;
    if (!s || s === 'all') {
      s = 'Latest Autos';
    }
    if (this.state.filter === 'keywords') {
      if (s.startsWith('filters-')) s = s.replace(/^filters-/, '').replace(/-?[0-9]+$/, '');
      let a = ['🚗', '🚲', '🏍'];
      if (!s) s = 'Latest Autos';
      else s = getTranslatedPhrase(this.props.userLanguage, 'Search for') + ' "' + s + '"';
    }
    return s;
  }

  render() {
    let totalCount = this.state.totalCountAutos + this.state.totalCountCars;

    // let totalCount = this.state.totalCountCars;

    // console.log('total count', totalCount)

    let items = [...this.state.itemsCars, ...this.state.itemsAutos];

    const urlParams = new URLSearchParams(window.location.search);
    let fromUSA = 0;
    let s_q = JSON.parse(this.props.searchQuery || '{}');
    let countryUpdated = s_q.countryUpdated;
    for (const [key, value] of urlParams.entries()) {
      if (key === 'country') {
        if (value === 'USA' || value === 'Canada') { fromUSA = 1; break; } else { fromUSA = 2; break; }
      }
    }
    if (fromUSA === 0)
      if (countryUpdated === 'USA' || countryUpdated === 'Canada') fromUSA = 1;
    if (fromUSA === 1) {
      // console.log('totalCount',items.length < this.state.itemsPerPage,totalCount,this.state.itemsPerPage * (this.state.currentPage - 1) + items.length, this.state.itemsCars.length , this.state.LoaderCars && this.state.LoaderCars.pageCount)
      // if (items.length < this.state.itemsPerPage && totalCount !== this.state.itemsPerPage * (this.state.currentPage - 1) + items.length && this.state.itemsCars.length < this.state.LoaderCars.pageCount) {
      //   totalCount = this.state.itemsPerPage * (this.state.currentPage - 1) + items.length;
      // } else if (this.state.itemsCars.length) totalCount = this.state.itemsPerPage * this.state.currentPage + 1;
    } else {
      // console.log('totalCount2',this.state.totalCountAutos ,items.length, this.state.LoaderAutos && this.state.LoaderAutos.pageCount , this.state.totalCountCars, this.state.LoaderCars && this.state.LoaderCars.pageCount)
      // if (this.state.LoaderAutos && this.state.LoaderCars && items.length < this.state.LoaderAutos.pageCount) {//} && this.state.totalCountCars <= this.state.LoaderCars.pageCount) {
      //   totalCount = this.state.itemsPerPage * (this.state.currentPage - 1) + items.length;
      // }// else if (this.state.LoaderAutos && this.state.itemsAutos.length < this.state.LoaderAutos.pageCount) totalCount = this.state.itemsPerPage * (this.state.currentPage - 1) + items.length;
      // console.log('totalCount3',totalCount)
    }

    let loaded = this.state.loadedOld && this.state.loadedNew;

    let filter = this.state.filter;
    let filterValue = this.state.value;
    let pageTitleParts = ['No autos that match this criteria were found.'];
    if (filter === 'category' && filterValue && filterValue !== 'all') pageTitleParts = ['No autos that match this criteria (category: ', titleCase(filterValue.replace(/-/g, ' ')), ') were found.'];
    if (filter === 'keywords') {
      let a = filterValue.split('-');
      let count = a.length;
      if (count >= 3) {
        filterValue = '';
        for (let i = 1; i < count - 1; i++) filterValue = filterValue + ' ' + a[i];
        filterValue = a[1].trim().replace(/[\(\)]/g, '').replace(/(.\*)/g, ' ');
        pageTitleParts = ['No autos that match this criteria (keywords: ', titleCase(filterValue), ') were found.'];
      } else if (count === 2) {
        pageTitleParts = ['No autos that match your filters were found.'];
      }
    }

    let isCategoryHome = true;
    if (filter !== 'category') {
      for (const [key, value] of urlParams.entries()) {
        if (key !== 'keywords') {
          if ((key === 'priceAscending' && value === '0') || (key === 'viewsDescending' && value === 'false') ||
            (key === 'priceRange' && value === 'false') || (key === 'withCoupons' && value === 'false') || (key === 'rating' && value === '0')) continue;
          if ((key === 'page' && value && value !== '1') || (key === 'category' && value && value !== 'null' && value !== 'all') || (key !== 'category' && value && value !== 'null' && value !== 'undefined')) {
            isCategoryHome = false;
            break;
          }
        } else {
          let a = value.split('-');
          if (a.length === 3) {
            isCategoryHome = false;
            break;
          }
        }
      }
    } else if (filterValue && filterValue !== 'all' && filterValue !== 'undefined') isCategoryHome = false;

    // console.log('render',this.props.searchQuery)

    if (totalCount > 500) {
      totalCount = 500;
    }

    // console.log('pagination - total math', Math.ceil(totalCount / this.state.itemsPerPage), totalCount, this.state.itemsPerPage);

    console.log(this.props.slug, this.props.section, this.props.isProfilePage)

    return (<>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{titleCase('autos')} | Followone</title>
        <meta name="description" content={this.props.description} />
        <link rel="canonical" href="https://followone.com/autos" />
      </Helmet>


      {/* <this.props.SideBarMobile
        section={'autos'}
        category={this.state.value}
        onSubmitSearch={this.onSubmitSearchHandler}
        onChangeCategory={this.onUpdateCategory}
        onChangeSubcategory={this.onUpdateSubcategory}

        isProfilePage={this.props.isProfilePage}
        slug={this.props.slug}
        section={this.props.section}
      /> */}

      <div className="category-body">
        {/* {!loaded 
          ? (<div className="main-container store-home">
            <CategoryLoader />
          </div>)
          : (<div className="main-container store-home">
            {(!totalCount && !items.length && loaded) ? isCategoryHome ? getTranslatedPhrase(this.props.userLanguage, 'No autos were found in your area. Use Advanced Search to look for autos in other areas.') : pageTitleParts.map(v => (getTranslatedPhrase(this.props.userLanguage, v))) : null}
            <div className="category-list">
              {items.map((v, i) => (<this.props.ItemPreview itemType={(this.state.itemType === 'autos' && !!this.state.id) ? 'cars' : 'autos'} key={'autos/' + v.title + '-' + i} {...v} />))}
            </div>

            <PageNumbers
              count={Math.ceil(totalCount / this.state.itemsPerPage)}
              activePage={this.state.currentPage}
              onClick={this.onUpdatePageNumber} />
          </div>)
        } */}


        {!loaded && !totalCount && !items.length
          ? (<div className="main-container store-home">
            <CategoryLoader />
          </div>)
          : (<div className="main-container store-home">

            <div className="category-list">
              {items.map((v, i) => (<this.props.ItemPreview itemType={(this.state.itemType === 'autos' && !!this.state.id) ? 'cars' : 'autos'} key={'autos/' + v.title + '-' + i} {...v} />))}
            </div>

            <PageNumbers
              count={Math.ceil(totalCount / this.state.itemsPerPage)}
              activePage={this.state.currentPage}
              onClick={this.onUpdatePageNumber} />
          </div>)
        }



        <this.props.SideBar
          section={'autos'}
          category={this.state.value}
          onSubmitSearch={this.onSubmitSearchHandler}
          onChangeCategory={this.onUpdateCategory}
          onChangeSubcategory={this.onUpdateSubcategory}

          isProfilePage={this.props.isProfilePage}
          slug={this.props.slug}
          section={this.props.section}
        />

      </div>

    </>);
  }
}

const mapStateToStoreCategoryProps = (
  state,
  props
) => {
  return {
    userIsAuthenticated: state.userIsAuthenticated,
    pageRefresh: state.pageRefresh,
    userLanguage: state.userLanguage,
    currentGeolocation: state.currentGeolocation,
    searchQuery: state.searchQuery,
    clientLongitude: state.clientLongitude,
    clientLatitude: state.clientLatitude
  };
};


export default connect(mapStateToStoreCategoryProps, null)(withRouter(CategoryPage));


