import React, { Component } from 'react';
import { connect } from 'react-redux';
import actionType from '../../../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../../util/lang';

class signedOutHeaderTools extends Component {
    state = { loaded: false }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ loaded: true });
        },1500);
    }
    render() {
        if (this.props.userIsAuthenticated) return null;
        if (!this.props.userIsAuthenticated && !this.state.loaded) {
            return (<div className="header-tools">
            <div>Loading...</div></div>);
        }
        return (
            <div className="header-tools">
                <div><button className="tool-text mgr-sm" onClick={this.props.onClickLoginButtonHandler}>{getTranslatedPhrase(this.props.userLanguage,'Log In')}</button></div>
                <div><button className="tool-text" onClick={this.props.onClickSignInButtonHandler}>{getTranslatedPhrase(this.props.userLanguage,'Sign Up')}</button></div>
            </div>
        );
    }
}

const mapStateToSignedOutHeaderToolsProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        userIsAuthenticated: state.userIsAuthenticated
    };
};

const mapDispatchTosignedOutHeaderToolsProps = (
    dispatch
) => {
    return {
        onClickLoginButtonHandler: () => dispatch({type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: window.location.pathname }),
        onClickSignInButtonHandler: () => dispatch({type: actionType.ACCOUNT_TYPE_MODAL, accountTypeModalActive: true})
    };
};

export default connect(mapStateToSignedOutHeaderToolsProps, mapDispatchTosignedOutHeaderToolsProps)(signedOutHeaderTools);