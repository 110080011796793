import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { hostNameAndPort } from '../../../shared/util/vars';
import { formatPriceAndCurrency } from "../../../shared/util/number";
import actionType from '../../../redux-store/action-type';

import ListingPhotos from '../../../shared/templates/ListingTemplate/components/ListingPhotos';
import ListingMap from '../../../shared/templates/ListingTemplate/components/ListingMap';
import ListingSidebar from './Property-sidebar';
import LoadingSvg from '../../../shared/uielements/LoadingSpinner/LoadingSvg';
import RealEstateListingIcons from "./Components-Listing/real-estate-listing-icons";
import RealEstateCoupons from "./Components-Listing/real-estate-coupons";
import RealEstateSeller from "./Components-Listing/real-estate-seller";
import RealEstateDescription from "./Components-Listing/real-estate-description";
import RealEstateSpecs from "./Components-Listing/real-estate-specs";
import RealEstateHeader from "./Components-Listing/real-estate-header";

import {
    templateMlsCom,
    templateMlsBiz,
    templateMlsNw,
    templateFollowone
} from './Components-Listing/real-estate-data-templates';

import RealEstateListingMeta from "./Components-Listing/real-estate-listing-meta";
import { getTranslatedPhrase } from '../../../shared/util/lang';


function PropertyListingDetail(props) {

    const [listing, setListing] = useState({
        contact: {}
    });
    const [listingCopy, setListingCopy] = useState({});
    const [pageViewStart, setPageViewStart] = useState(moment());

    const userLanguage = useSelector(state => state.userLanguage);
    const loggedInUser = useSelector(state => state.loggedInUser);
    const payperviewRedirect = useSelector(state => state.payperviewRedirect);
    const { storeItem } = props.match.params || {};
    let authorId = (!listing.ListingID && !listing.ListingId) ? listing.authorId : (!!listing.ListingID) ? listing.PrimaryOfficeID : listing.ListOfficeMlsId;
    let id = (!listing.ListingID && !listing.ListingId) ? listing.slug : (listing.ListingID) ? String(listing.ListingID) : listing.ListingId;


    useEffect(() => {
        window.scrollTo(0, 0);
        if (storeItem) {
            setPageViewStart(moment());
            getListing(storeItem);
            // setTimeout(() => {
            //     getListing(storeItem);
            // }, 100);
        }
    }, [storeItem]);// pageRefresh?

    async function handlePPVCampaignViewer(isOrganic, itemId, itemType, socialItemId) {
        try {
            let ppvData = new FormData();
            ppvData.append('campaignId', payperviewRedirect);
            ppvData.append('itemId', itemId);
            ppvData.append('socialItemId', socialItemId);
            ppvData.append('itemType', itemType);
            let options = {
                url: `${hostNameAndPort}/api/ppv/add${isOrganic}view`,
                method: 'post',
                data: ppvData
            };
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                // Success! Nothing to do
                // console.log('success')
            } //else console.log(data.error)
        } catch (error) {
            // console.log(error)
            // Network error
        }
    }

    async function getListing(b) {
        let url = `${hostNameAndPort}/api/realestate/${b}`;
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {

                if (!data.item.isMlsCom) data.item.isMlsCom = '';
                if (!data.item.isMlsBiz) data.item.isMlsBiz = '';
                if (!data.item.SourceSystemName) data.item.SourceSystemName = '';
                if (!data.item.ListingID) data.item.ListingID = '';
                if (!data.item.PrimaryOfficeID) data.item.PrimaryOfficeID = '';
                if (!data.item.ListingId) data.item.ListingId = '';
                if (!data.item.ListOfficeMlsId) data.item.ListOfficeMlsId = '';
                // if (!data.item.isMlsResidential) data.item.isMlsResidential = false;
                if (!data.item.isMlsCom && !data.item.isMlsBiz && !data.item.isMlsNwResi) {
                    data.item.creationDate = (new Date(data.item.creationDate)).toDateString();
                    if (data.item.brandName === 'null') data.item.brandName = '';
                    if (!data.item.specList) {
                        data.item.specList = [];
                    }
                    let moreSpecs = data.item.moreSpecs || [];

                    if (data.item.specList.length > 1) {
                        if (data.item.bathCount && !!data.item.bathCount && data.item.bathCount !== '0')
                            data.item.specList[1].specValue = data.item.bathCount;
                        if (data.item.bedCount && !!data.item.bedCount && data.item.bedCount !== '0')
                            data.item.specList[0].specValue = data.item.bedCount;
                    }

                    data.item.specList = data.item.specList.map(v => ({
                        specName: v.specName,
                        specValue: (v.specValue === true) ? 'Yes' : v.specValue
                    }));

                    let f1 = 0, f2 = 0;
                    for (let i = 0; i < data.item.specList.length; i++) {
                        if (data.item.specList[i].specName === 'HOA/Month') f1 = i;
                        if (data.item.specList[i].specName === 'USD') f2 = i;
                    }
                    if (f1 && f2) {
                        data.item.specList[f1].specValue = data.item.specList[f2].specValue;
                        data.item.specList[f2].specValue = false;
                    }

                    data.item.specList = [...data.item.specList, ...moreSpecs];
                    data.item.allPhotos = [data.item.featuredImage, ...data.item.photos];
                    let theAddress = '';
                    let a = data.item;
                    if (!!a['address']) {
                        theAddress = a['address'] + ',';
                    }
                    if (!!a['unit']) {
                        theAddress = theAddress + ' #' + a['unit'] + ',';
                    }
                    if (!!a['city']) {
                        theAddress = theAddress + ' ' + a['city'];
                    }
                    if (!!a['state'] && a['state'] !== 'n/a') {
                        theAddress = theAddress + ' ' + a['state'];
                    }
                    if (!!a['zipCode']) {
                        theAddress = theAddress + ', ' + a['zipCode'];
                    }
                    if (!!a['country']) {
                        theAddress = theAddress + ', ' + a['country'];
                    }
                    data.item.theAddress = theAddress;
                    data.item.price = Number(data.item.price) / 100;
                    if (data.item.discountId) {
                        if (moment(data.item.discountId.startDate).isAfter(moment(), 'days') || moment(data.item.discountId.endDate).isBefore(moment(), 'days')) {
                            data.item.addDiscount = '';
                            data.item.discountId = '';
                        } else if (data.item.discountId.couponCurrency !== '%') data.item.discountId.couponValue = Number(data.item.discountId.couponValue) / 100;
                    }
                }

                if (data.item.brandName) {
                    data.item.specList.unshift({ specName: 'Brand Name', specValue: data.item.brandName });
                }

                if (!data.item.isMlsCom && !data.item.isMlsBiz && data.item.SourceSystemName !== "NWMLS") {
                    let d = data.item.discountId;
                    let p = 0;
                    if (!!d) {
                        let theDiscount = Number(d.couponValue);
                        if (!d.couponCurrency || d.couponCurrency === '%') {
                            p = Math.round((data.item.price - data.item.price * theDiscount / 100) * 100) / 100;
                        } else {
                            p = Math.round((data.item.price - Number(theDiscount)) * 100) / 100;
                        }
                        if (p > 0) {
                            data.item.afterDiscountPrice = formatPriceAndCurrency(p, data.item.currency, ',');
                        } else {
                            data.item.afterDiscountPrice = '0';
                        }
                    } else {
                        data.item.afterDiscountPrice = '';
                    }
                }
                setPageViewStart(moment());
                setListingCopy(data.item);

                if (data.item.isMlsCom) {
                    setListing(templateMlsCom(data.item));
                } else if (data.item.isMlsBiz) {
                    setListing(templateMlsBiz(data.item));
                } else if (data.item.SourceSystemName === "NWMLS") {
                    setListing(templateMlsNw(data.item));
                } else {
                    setListing(templateFollowone(data.item))
                }

                let itemId = data.item.itemId;
                let socialRealestateId = data.item.socialRealestateId;

                setTimeout(() => {
                    if (moment().diff(moment(pageViewStart), 'seconds') >= 5) {
                        if (!!payperviewRedirect) {
                            handlePPVCampaignViewer('', itemId, !socialRealestateId ? 'realestate-old' : 'realestate', socialRealestateId);
                            props.resetPayperviewRedirect();
                        } else {
                            handlePPVCampaignViewer('organic', itemId, !socialRealestateId ? 'realestate-old' : 'realestate', socialRealestateId);
                        }
                    }
                }, 5100);

            } else props.history.push('/404');
        } catch (err) {
            console.log('Network error', err);
            props.history.push('/404');
        }
    }

    function onClickContact() {
        if (!loggedInUser) {
            props.onShowLoginModal();
            return;
        }

        // Andrei Check ~ || && error
        // if (loggedInUser.userId === authorId || loggedInUser.ListOfficeMlsId === listing.ListOfficeMlsId && loggedInUser.PrimaryOfficeID === listing.PrimaryOfficeID) {
        //     props.showAlertModal(getTranslatedPhrase(userLanguage, 'This feature is not available!'));
        //     return;
        // }

        if (loggedInUser.userId === authorId) {
            this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, 'This feature is not available!'));
            return;
        }

        let u = {
            userId: authorId,
            userName: listing.authorName,
            profileImage: listing.authorProfileImage,
            slug: listing.authorSlug
        };
        // Leave the ^ there because it is useful
        if (listing.websiteSection === 'realestate') u.userId = '^' + u.userId;
        let m = window.location.href;
        let w = listing.websiteSection;
        switch (w) {
            case "realestate":
            case "realestate-old":
                w = "property";
                break;
            case "store":
                w = "product";
                break;
            case "travel":
                w = "travel listing";
                break;
            case "events":
                w = "event";
                break;
            default:
                break;
        }
        props.updateMessagesPageProps(u, getTranslatedPhrase(userLanguage, "I am writing in regards to this") + " " + getTranslatedPhrase(userLanguage, w) + `: ${m}`);
        props.history.push("/chat");
    };



    if (!listing.specList) {
        listing.specList = [];
    }
    if (!listing.subcategory) listing.subcategory = 'all';
    if (!listing.title || id !== storeItem) return (<LoadingSvg active={true} />);


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{listing.title} | Followone</title>
                <meta name="description" content={(listing.description || '').substring(0, 200)} />
                <link rel="canonical" href={`https://followone.com/realestate/${listing.category}/${listing.subcategory.replace(/\//g, '_')}/${listing.slug}`} />
            </Helmet>

            <div className="listing-body">
                <div className="main-container store-product">

                    <RealEstateHeader
                        // address={listing.address}
                        photos={listing.photos}
                        websiteSection={listing.websiteSection}
                        authorId={listing.authorId}
                        itemId={!listing.ListingID && !listing.ListingId ? listing.itemId : listing.socialRealestateId}
                        favoriteItemId={!listing.ListingID && !listing.ListingId ? '' : listing.itemId}
                        productCost={listing.price}
                        numberToCall={listing.numberToCall}
                        callForPricing={listing.callForPricing}
                        afterDiscountPrice={listing.afterDiscountPrice}
                        productCurrency={listing.currency}
                        productRepeat={listing.rentOrSell && listing.rentOrSell === 'rent' ? 'month' : ''}
                        rentOrSell={listing.rentOrSell}
                        category={listing.category}
                        isMlsCom={listing.isMlsCom}
                        isMlsBiz={listing.isMlsBiz || listing.category === 'business-opportunities'}
                        mlsProp={listing.mls_prop_name}
                        floorSize={listing.floorSize}
                        floorSizeUnit={listing.floorSizeUnit}
                        listingType={listing.listingType}
                        listedDate={listing.listedDate}
                        cumulativeDaysOnMarket={listing.cumulativeDaysOnMarket}
                        availableStatus={listing.availableStatus}
                        subcategory={listing.subcategory}
                        address={listing.address || ''}
                        bedrooms={listing.bedrooms}
                        bathrooms={listing.bathrooms}
                    />

                    <div className="product-main-details">

                        <RealEstateListingMeta
                            websiteSection={listing.websiteSection}
                            isMlsCom={listing.isMlsCom}
                            isMlsBiz={listing.isMlsBiz}
                            propertyName={listing.propertyName}
                            businessName={listing.businessName}
                            shareItemId={listing.itemId}
                            itemIsShared={listing.itemIsShared}
                            shareCount={listing.shareCount}
                            viewCount={listing.viewCount}
                            authorId={listing.authorId}
                            productDetailTitle={listing.title}
                            authorSlug={listing.authorSlug}
                            authorName={listing.authorName}
                            authorProfileImage={listing.authorProfileImage}
                            categoryName={listing.category}
                            subcategory={listing.subcategory || 'other'}
                            productCost={listing.price}
                            productRepeat={listing.rentOrSell && listing.rentOrSell === 'rent' ? 'month' : ''}
                            afterDiscountPrice={listing.afterDiscountPrice}
                            PropertyDetailAddress={listing.address}
                            productCurrency={listing.currency}
                            callForPricing={listing.callForPricing}
                            noLove={listing.likeCount}
                            productIsLoved={listing.itemIsLiked}
                            sellerPhone={(listing.contact && listing.contact['Customer Service Phone #']) || listing.sellerPhone}
                            sellerEmail={(listing.contact && listing.contact['Customer Service Email']) || listing.sellerEmail}
                            buttonLabel="Buy Now"
                            numberToCall={listing.numberToCall}
                            officeName={listing.officeName}
                            officeAddress={listing.officeAddress}
                            officeID={listing.officeId}
                            streetName={listing.streetName}
                        />

                        <RealEstateCoupons
                            callForPricing={listing.callForPricing}
                            addDiscount={listing.addDiscount}
                            discountId={listing.discountId}
                            currency={listing.currency}
                        />

                        <RealEstateSeller
                            onClickContact={onClickContact}
                            loggedInUser={loggedInUser}
                            isMlsNw={listing.isMlsNw}
                            authorId={listing.authorId}
                            seller1ID={listing.seller1ID}
                            seller1name={listing.seller1name}
                            seller1phone={listing.seller1phone}
                            seller1phoneExt={listing.seller1phoneExt}
                            seller1email={listing.seller1email}
                            officeName={listing.officeName}
                            officeAddress={listing.officeAddress}
                            seller2ID={listing.seller2ID}
                            seller2name={listing.seller2name}
                            seller2phone={listing.seller2phone}
                            seller2email={listing.seller2email}
                            seller3ID={listing.seller3ID}
                            seller3name={listing.seller3name}
                            seller3phone={listing.seller3phone}
                            seller3email={listing.seller3email}
                        />

                        <RealEstateSpecs
                            isMlsBiz={listing.isMlsBiz}
                            specList={listing.specList}
                            financialInfoSpecList={listing.financialInfoSpecList}
                            businessOppSpecList={listing.businessOppSpecList}
                            availableStatus={listing.availableStatus}
                            soldInfo={listing.soldInfo}
                        />

                        <div className="ProductDetailBox ProductDetailDescription">

                            <RealEstateListingIcons
                                availableStatus={listing.availableStatus}
                                beerWineLic={listing.beerWineLic}
                                classHLic={listing.classHLic}
                                proLic={listing.proLic}
                                gamblingLic={listing.gamblingLic}
                                noSpecLic={listing.noSpecLic}
                                listingType={listing.listingType}
                                propertyType={listing.propertyType}
                                floors={listing.floors}
                                daysOpen={listing.daysOpen}
                                phoneSystem={listing.phoneSystem}
                                seating={listing.seating}
                                livingQuarters={listing.livingQuarters}
                                parking={listing.parking}
                                openParkingSpaces={listing.openParkingSpaces}
                                bedrooms={listing.bedrooms}
                                bathrooms={listing.bathrooms}
                                halfBathrooms={listing.halfBathrooms}
                                hasGarage={listing.hasGarage}
                                hasView={listing.hasView}
                                waterFront={listing.waterFront}
                                hasHeating={listing.hasHeating}
                                petsAllowed={listing.petsAllowed}
                            />

                            <RealEstateDescription
                                description={listing.description || ''}
                                communityFeatures={listing.communityFeatures}
                                specialListingConditions={listing.specialListingConditions}
                                callForPricing={listing.callForPricing}
                                addDiscount={listing.addDiscount}
                                discountId={listing.discountId}
                                livingQuartersDescription={listing.livingQuartersDescription}
                                agentDescription={listing.agentDescription}
                                showingInstruction={listing.showingInstruction}
                                website={listing.webisteURL}
                                isMlsCom={listing.isMlsCom}
                                isMlsBiz={listing.isMlsBiz}
                                isMlsNw={listing.isMlsNw}
                                directions={listing.directions}
                                listingTerms={listing.listingTerms}
                                offers={listing.offers}

                                highSchoolDistrict={listing.highSchoolDistrict}
                                elementarySchool={listing.elementarySchool}
                                middleSchool={listing.middleSchool}
                                highSchool={listing.highSchool}

                                appliances={listing.appliances}
                                inclusions={listing.inclusions}
                                interiorFeatures={listing.interiorFeatures}
                                flooring={listing.flooring}
                                furnished={listing.furnished}
                                entryLocation={listing.entryLocation}
                                exteriorFeatures={listing.exteriorFeatures}
                                powerType={listing.powerType}
                                utilities={listing.utilities}
                                fireplace={listing.fireplace}
                                fireplaceFeatures={listing.fireplaceFeatures}
                                fireplacesTotal={listing.fireplacesTotal}
                                basement={listing.basement}
                                openParking={listing.openParking}
                                carport={listing.carport}
                                coveredSpaces={listing.coveredSpaces}
                                associationFeeFrequency={listing.associationFeeFrequency}
                                associationFee={listing.associationFee}
                                electricOnPropertyYN={listing.electricOnPropertyYN}
                                irrigationWaterRightsYN={listing.irrigationWaterRightsYN}
                                viewDescription={listing.viewDescription}
                                irrigationSource={listing.irrigationSource}
                                buildingFeatures={listing.buildingFeatures}
                                signOnPropertyYN={listing.signOnPropertyYN}

                                heatingType={listing.heatingType}
                                buildingInformation={listing.buildingInformation || ''}
                                siteFeatures={listing.siteFeatures}
                            />

                        </div>
                    </div>


                    {!listing.isMlsCom && !listing.isMlsBiz &&
                        <ListingPhotos photoList={listing.photos} show={true} />
                    }

                    <div className="Listing-Mobile-Map">
                        <ListingMap address={listing.address || ''} />
                    </div>
                </div>

                <ListingSidebar
                    section={listing.websiteSection}
                    category={listing.category}
                    itemSlug={listing.slug}
                    itemId={listing.itemId}
                    ListingID={listing.ListingID}
                    ListingId={listing.ListingId}
                    authorId={authorId}
                    authorSlug={listing.authorSlug}
                    authorName={listing.authorName}
                    authorProfileImage={listing.authorProfileImage}
                    likeList={listing.likes}
                    listingDetails={listingCopy}
                    createNewTitle="New Property"
                    userIsAuthorized={listing.userIsAuthorized}
                />
            </div>
        </>
    );
}

const mapDispatchToListingDetailProps = (
    dispatch
) => {
    return {
        resetPayperviewRedirect: () => dispatch({ type: actionType.PAYPERVIEW_REDIRECT, payperviewRedirect: false }),
        updateMessagesPageProps: (u, m) => dispatch({ type: actionType.MESSAGES_PAGE, messagesDefaultUser: u, messagesDefaultMessage: m }),
        onShowLoginModal: () => dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: window.location.pathname }),
        showAlertModal: (m) => dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: m })
    };
};

export default connect(null, mapDispatchToListingDetailProps)(withRouter(PropertyListingDetail));