import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import Axios from 'axios';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../../../shared/util/lang';
import { formatPriceAndCurrency } from '../../../../../shared/util/number';
import RateButton from '../../../../../shared/uielements/Engage/EngageButtons/RateButton';
import EngageButtons from '../../../../../shared/uielements/Engage/EngageButtons/EngageButtons';
import moment from 'moment';

class WallEvent extends Component {
    state = {
        eventNotFound: true,
        reviews: [],
        // userLanguage: '',
    }

    componentDidMount() {
        // let p = this.props.userLanguage;
        // if (!p || (this.state.userLanguage === p) ) {
        //     return;
        // }
        // this.setState({ userLanguage: p});
        let b = this.props.eventId;
        if (!b) {
            this.setState({ eventNotFound: true });
            return;
        }
        let url = `${hostNameAndPort}/api/events/${b}`;
        Axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {                
                data.item.creationDate = (new Date(data.item.creationDate)).toDateString();
                data.item.eventNotFound = false;
                this.setState(data.item);
                let _wallAction;
                switch (this.props.activityType) {
                    case 'comment':
                    _wallAction = 'commented on an event';
                    break;
                    case 'like':
                    _wallAction = 'liked an event';
                    break;
                    case 'share':
                    _wallAction = 'shared an event';
                    break;
                    case 'post': 
                    _wallAction = 'posted a new event';
                    break;
                    case 'review':
                    _wallAction = 'rated an event';
                    break;
                    default:
                    break;
                }
                this.props.onItemLoaded({
                    itemFailedLoading: false, 
                    wallAction: _wallAction,
                });
            }
            else {
                this.props.onItemLoaded({ itemFailedLoading: true });
            }
        })
        .catch(err => {
            //console.log('Network error');
            this.props.onItemLoaded({ itemFailedLoading: true });
        });
    }

    render () {
        if (this.state.eventNotFound) {
            return '';
        }
        let eventSlug = `/events/${this.state.category}/${this.state.slug}`;
        return (
            <div className="category-list_item event-item-preview">
                <Link to={eventSlug}>
                    <img src={this.state.featuredImage} alt={this.state.title} />
                </Link>

                <div className="event-item-preview_details">
                    <div className="event-item-preview_details_date">
                        <span>{moment(this.state.startDate).local().format('ddd, MMM').toUpperCase()}</span>
                        <span>{moment(this.state.startDate).local().format('D')}</span>
                    </div>

                    <div className="event-item-preview_details_title">
                        <span>{this.state.title}</span>
                        <EngageButtons>
                            <RateButton noStars={this.state.averageRating} noReviews={this.state.reviewCount} />
                        </EngageButtons>

                        <p className="store-listing-preview_address">{this.state.city}</p>
                        <p className="store-listing-preview_price"> {this.state.price==='0'?getTranslatedPhrase(this.props.userLanguage,'FREE'):formatPriceAndCurrency(this.state.price, this.state.currency, ',')} </p>

                        <Link to={eventSlug}>
                            <button className="button button-regular button-clear button-small">{getTranslatedPhrase(this.props.userLanguage,'Learn More')}</button>
                        </Link>
                    </div> 

                    <div className="clb" />
                </div>

                

            </div> 
        );
    }
}


const mapStateToWallEventProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToWallEventProps, null)(WallEvent);
