import React, {useEffect, useState} from 'react';
import {getTranslatedPhrase} from '../../../../shared/util/lang';
// import IDFront from '../Assets/id-example-front.jpg';
// import IDBack from '../Assets/id-example-back.jpg';
import Box from '../../../../shared/util/box';
import FormFieldError from '../../../../shared/uielements/ErrorMessages/FormFieldError';
import ErrorMessages from '../../../../shared/uielements/ErrorMessages/ErrorMessages';
import LoadingSvg from '../../../../shared/uielements/LoadingSpinner/LoadingSvg';
import {Link} from 'react-router-dom';
import ResellerTerms from '../Terms/ResellerTerms';
import {hostNameAndPort} from '../../../../shared/util/vars';
import Axios from 'axios';
import {connect, useSelector} from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import {Helmet} from "react-helmet";

const resellerInit = {
    accountType: '',
    username: '',
    resellerId: '',
    applicationSaved: false,
    submitWaiting: false,
    agreeWithTerms: false,
    paymentGateway: 'paypal',
    address: '',
    entityName: '',
    tabClicked: [false, true]
}

const ResellerApplication = (props) => {

    const [reseller, setReseller] = useState(resellerInit);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('Your application has been submitted.');
    const loggedInUser = useSelector(state => state.loggedInUser);
    const userLanguage = useSelector(state => state.userLanguage);


    useEffect(() => {
        window.scrollTo(0, 0);
        getAffiliateData();
        getAffiliateAddressData();
    }, [])

    useEffect(() => {
        if (loggedInUser)
            setReseller(prevReseller => {
                return {...prevReseller, ...loggedInUser };
            });
    }, [loggedInUser])

    const getAffiliateData = async () => {
        let url = `${hostNameAndPort}/api/reseller`;
        try {
            let result = await Axios(url);
            let {data} = result;
            if (!data.error) {
                data.reseller.agreeWithTerms = true;
                setReseller(prevReseller => {
                    return {...prevReseller, ...data.reseller };
                });
            }
        } catch (err) {
            // Network error
        }
    }

    const getAffiliateAddressData = async () => {
        let url = `${hostNameAndPort}/api/reseller/fetchaddress`;
        try {
            let result = await Axios(url);
            let {data} = result;
            if (!data.error) {
                setReseller(prevReseller => {
                    return {...prevReseller, ...data.reseller };
                });
            }
        } catch (err) {
            // Network error
        }
    }

    // const getUserDataFromServer = async () => {
    //     let user = this.props.loggedInUser;
    //     if (user) {
    //         setReseller(prevReseller => {
    //             return {...prevReseller, ...user };
    //         });
    //     }
    // }

    const toggleAgreeWithTerms = () => {
        setErrorMessage('');
        setReseller(prevReseller => {
            return {...prevReseller, agreeWithTerms: !prevReseller.agreeWithTerms };
        });

    }

    const onChangeValue = (e, _propName, _sizeLimit, _propMsg) => {
        if (e.target.value.length > _sizeLimit) {
            setErrorMessage(`${getTranslatedPhrase(userLanguage, _propMsg)} ${getTranslatedPhrase(userLanguage, 'must be smaller than')} ${_sizeLimit} ${getTranslatedPhrase(userLanguage, 'characters')}`);
            return;
        }

        const value = e.target.value;
        setErrorMessage('');
        setReseller(prevReseller => {
            return {...prevReseller, [_propName]: value, applicationSaved: false };
        });
    }

    const onChangePaymentType = (paymentGateway) => {
        setReseller(prevReseller => {
            return {...prevReseller, paymentGateway, applicationSaved: false };
        });
    }

    const formIsValid = () => {
        let err = '';
        let _ = reseller;
        if (_.paymentGateway === 'paypal' && !_.username) err = `Paypal information missing.`;
        if (err) {
            setErrorMessage(err);
            return false;
        }
        return true;
    }

    const onSubmitCashInApplication = async (i) => {
        try {
            let t = reseller.tabClicked;
            for (let j in t) t[j] = false;
            t[i] = true;
            setReseller(prevReseller => {
                return {...prevReseller, tabClicked: t };
            });

            if (!reseller.agreeWithTerms) {
                setErrorMessage('You must agree with the terms.');
                return;
            }
            if (reseller.applicationSaved) {
                setErrorMessage('Application has already been saved');
                return;
            }
            if (!formIsValid()) return;
            let resellerData = new FormData();
            let nbd = Object.assign({}, reseller);
            for (let i in nbd) {
                resellerData.append(i, nbd[i]);
            }

            let options = {
                url: hostNameAndPort + '/api/reseller/update',
                method: 'post',
                data: resellerData
            };

            setReseller(prevReseller => {
                return {...prevReseller, submitWaiting: true };
            });

            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                setReseller(prevReseller => {
                    return {...prevReseller, resellerId: data.resellerId, submitWaiting: false, applicationSaved: true };
                });
                setSuccessMessage('Your application has been submitted.');

            } else {
                setErrorMessage(data.error);
                setReseller(prevReseller => {
                    return {...prevReseller, submitWaiting: false, applicationSaved: false };
                });
            }
        } catch (err) {
            setErrorMessage('Network error ERRFERESAPP01');
            setReseller(prevReseller => {
                return {...prevReseller, submitWaiting: false, applicationSaved: false };
            });
        }
    }

    const onSubmitOptOut = async (i) => {
        try {
            let t = reseller.tabClicked;
            for (let j in t) t[j] = false;
            t[i] = true;
            setReseller(prevReseller => {
                return {...prevReseller, tabClicked: t };
            });

            let options = {
                url: `${hostNameAndPort}/api/reseller`,
                method: 'delete'
            };

            setReseller(prevReseller => {
                return {...prevReseller, submitWaiting: true };
            });

            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                setSuccessMessage('You have been opted out')
                setReseller(prevReseller => {
                    return {...prevReseller, submitWaiting: false, resellerId: '', username: '' };
                });
            } else {
                setErrorMessage(data.error);
                setReseller(prevReseller => {
                    return {...prevReseller, submitWaiting: false };
                });
            }
        } catch (err) {
            setErrorMessage('Network error ERRFERESAPP02');
            setReseller(prevReseller => {
                return {...prevReseller, submitWaiting: false };
            });
        }
    }

    const onUpdateInfo = () => {
        props.history.push('/profile-update');
    }

    return (
        <div className="main-100 cash-in-application">

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Reseller Application | Followone</title>
                <meta name="description" content="Earn up to $80 per sale with Followone Reseller Affiliate Program."/>
                <link rel="canonical" href="https://followone.com/reseller-application"/>
            </Helmet>

            <div className="main-100-header">
                <h1>{getTranslatedPhrase(userLanguage, 'Reseller Affiliate Program ')}</h1>
                <p>{getTranslatedPhrase(userLanguage, 'Earn up to $80 per sale with our Reseller Affiliate Program.')}</p>
            </div>

            {/* <div className="cash-in-application-box">
                <h4>ID Verification</h4>
                <p>In order to be part of the Reseller Affiliate Program you need to be 18 years of age or older, we also use your ID or Driver License to verify your identity.</p>

                <div className="personal-id">
                    <div className="personal-id-box">
                        <button className="button button-clear"> Upload ID Front</button>
                        <img src={IDFront} />
                    </div>

                    <div className="personal-id-box">
                        <button className="button button-clear">Upload ID Back</button>
                        <img src={IDBack} />
                    </div>
                </div>
            </div>  */}


            <div className="cash-in-application-flex">
                <div className="cash-in-application-box">
                    <h4>SELECT ONE METHOD OF GETTING PAID</h4>
                    <p>Once you have a minimum of $1000 you can deposit your cash into the Paypal account you provide
                        below, or receive a check by mail.</p>


                    <div className="select-affiliate-payment">
                        <div onClick={(e) => onChangePaymentType('paypal')}
                             className={`affiliate-payment-box affiliate-payment-paypal ${(reseller.paymentGateway === 'paypal') ? 'affiliate-payment-box-selected' : ''}`}>
                            <h4><i className="fab fa-paypal"/> PayPal</h4>

                            <p>Transfer my cash to my PayPal:</p>

                            <label>Paypal Email or Phone Number</label>
                            <input type="text" className="form-input-text"
                                   onChange={(e) => onChangeValue(e, 'username', 50, 'Paypal username')}
                                   value={reseller.username}/>

                            {(reseller.paymentGateway !== 'paypal') ? '' : (<Box>
                                {!!reseller.resellerId && (
                                    <button onClick={(e) => onSubmitCashInApplication(0)}>Update Paypal
                                        Payment</button>)}
                                <FormFieldError>{reseller.tabClicked[0] && errorMessage}</FormFieldError>
                                {reseller.tabClicked[0] && reseller.applicationSaved && (
                                    <FormFieldError>{getTranslatedPhrase(userLanguage, 'Your Paypal information has been updated.')}</FormFieldError>)}
                            </Box>)}

                            <div className="affiliate-payment-check">{(reseller.paymentGateway === 'paypal') && (
                                <i className="far fa-check"/>)}</div>
                        </div>


                        <div onClick={(e) => onChangePaymentType('check')}
                             className={`affiliate-payment-box affiliate-payment-check ${(reseller.paymentGateway === 'check') ? 'affiliate-payment-box-selected' : ''}`}>
                            <h4><i className="fal fa-money-check"/> Check</h4>

                            <p>Mail my cash to:</p>

                            <table>
                                <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td><strong>{reseller.entityName}</strong></td>
                                </tr>

                                <tr>
                                    <td>Address</td>
                                    <td><strong>{reseller.address.split(',').map((v, i) => (
                                        <Box key={'line-' + i}>{v}<br/></Box>))}</strong></td>
                                </tr>
                                </tbody>
                            </table>

                            <button onClick={onUpdateInfo}>Update Information</button>

                            <div className="affiliate-payment-check">{(reseller.paymentGateway === 'check') && (
                                <i className="far fa-check"/>)}</div>
                        </div>
                    </div>

                </div>


                <div className="cash-in-application-box">
                    <h4>Level Up & Earn More</h4>

                    <p>All Reseller Affiliate Members start at Silver Level. To earn more money, advance to the next
                        level by achieving level's sales goal.</p>

                    <div className="reseller-levels">
                        <table>
                            <tbody>
                            <tr className="tr-header">
                                <td>Levels</td>
                                <td>Premium Membership <br/> Comission / Sale</td>
                                <td># of Sales / Level</td>
                            </tr>

                            <tr className="tr-silver">
                                <td>Silver</td>
                                <td className="txtc"><strong>$20</strong></td>
                                <td className="txtr"><strong>0 - 500 Sales</strong></td>
                            </tr>

                            <tr className="tr-gold">
                                <td>Gold</td>
                                <td className="txtc"><strong>$40</strong></td>
                                <td className="txtr"><strong>501 - 2500 Sales</strong></td>
                            </tr>

                            <tr className="tr-diamond">
                                <td>Diamond</td>
                                <td className="txtc"><strong>$60</strong></td>
                                <td className="txtr"><strong>2501 - 5000 Sales</strong></td>
                            </tr>

                            <tr className="tr-executive">
                                <td>Executive</td>
                                <td className="txtc"><strong>$80</strong></td>
                                <td className="txtr">5001+ Sales</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div className="cash-in-application-box">
                <h4>Terms & Conditions</h4>

                <ResellerTerms/>

                <div className="cash-in-agreement">
                    <div className="form-input-lists form-input-lists_row mgb-sm">
                        <input id="Check" type="checkbox" checked={reseller.agreeWithTerms} onChange={() => null}
                               onClick={toggleAgreeWithTerms}/>
                        <label htmlFor="Check">
                            <i className="fal fa-check"/> <span>I have read and agreed with all terms and conditions mentioned above and I declare that I am 18 years of age or older.</span>
                        </label>
                    </div>
                </div>

                <div className="submit-listing">
                    <FormFieldError>{reseller.tabClicked[1] && errorMessage}</FormFieldError>

                    <ErrorMessages
                        errorMessageClass={"error-message error-message-info"}
                        errorMessageTitle={getTranslatedPhrase(userLanguage, 'Success')}
                        errorMessageBody={getTranslatedPhrase(userLanguage, successMessage)}
                        show={reseller.tabClicked[1] && reseller.applicationSaved}>
                        {!!reseller.resellerId && (
                            <Link className="button button-regular button-small dsp-in-blk mgt-sm"
                                  to="/reseller-affiliate-dashboard">{getTranslatedPhrase(userLanguage, 'See Your Dashboard')}</Link>)}
                    </ErrorMessages>

                    <LoadingSvg active={reseller.submitWaiting}/>
                    {!reseller.resellerId ? (
                        <button onClick={(e) => onSubmitCashInApplication(1)}
                                className="button button-regular">Submit</button>) : (
                        <button onClick={(e) => onSubmitOptOut(1)} className="button button-sale">Opt Out</button>
                    )}

                </div>
            </div>
        </div>
    );
}



const mapDispatchToResellerApplicationProps = (
    dispatch
) => {
    return {
        showAlertModal: (u) => dispatch({type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: u})
    };
};

export default connect(null, mapDispatchToResellerApplicationProps)(ResellerApplication);

