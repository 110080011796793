import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Axios from 'axios';
import { Helmet } from "react-helmet";
import { hostNameAndPort } from '../../../shared/util/vars';
import { connect } from 'react-redux';
import actionType from '../../../redux-store/action-type';

// Static Images
import AccreditedLogo from "../../../assets/business/foaccredited.svg";
import ZakListHeader from './assets/zaklist-header.jpg';
import CarzLookHeader from './assets/carzlook-header.jpg';
import CarVerveHeader from './assets/carverve-header.jpg';
import ZakListIcon from './assets/zaklist-icon.png';
import CarzLookIcon from './assets/carzlook-icon.png';
import CarVerveIcon from './assets/carverve-icon.png';
import ZakListLogo from './assets/zaklist-logo.png';
import CarzLookLogo from './assets/carzlook-logo.png';
import CarVerveLogo from './assets/carverve-logo.png';
import Header1 from './assets/dealer-header-1.jpg';
import Header2 from './assets/dealer-header-2.jpg';
import Header3 from './assets/dealer-header-3.jpg';
import ZakListHeaderLg from './assets/zaklist-header-lg.jpg';
import CarzLookHeaderLg from './assets/carzlook-header-lg.jpg';
import CarVerveHeaderLg from './assets/carverve-header-lg.jpg';
import ZakListShots from './assets/zaklist-shots.jpg';
import CarzLookShots from './assets/carzlook-shots.jpg';
import CarVerveShots from './assets/carverve-shots.jpg';

const Dealer = props => {

    const history = useHistory();
    const params = useParams();

    const [errorMsg, setErrorMsg] = useState('');
    const [dealerId, setDealerId] = useState();
    const [dealer, setDealer] = useState();
    const [dealerName, setDealerName] = useState();
    const [username, setUsername] = useState();
    const [password, setPasssword] = useState();

    const currentDealer = params.dealerId;

    useEffect(() => {
        if (params.dealerId) {

            const theDealerId = params.dealerId.replace(/\D/g,'');

            setDealerId(theDealerId)
            setPasssword(theDealerId)
            getDealer(theDealerId); 
        }
    }, [currentDealer])


    const getDealer = (dealerId) => {
        let options = {
            url: `${hostNameAndPort}/api/user/dealer-by-id/${dealerId}`,
            method: 'get',
        };

        Axios(options)
        .then(result => {
            let { data } = result;
            if (!data.error) {
                setErrorMsg('');
                console.log(data);

                setUsername(data.user.email);
                setDealerName(data.user.userName);
                setDealer(data.user);
            }
            else {
                let emc = data.error;
                setErrorMsg(emc);
            }
        })
        .catch(err => {
            setErrorMsg(err);
        });
    }


    const onLoginDealer = async (e) => {
        e.preventDefault();
        
        let options = {
            url: `${hostNameAndPort}/login`,
            method: 'post',
            data: `username=${username}&password=${password}`,
        };

        await Axios(options)
            .then(result => {
                let { data } = result;
                if (!data.error) {
                    setErrorMsg('');
                    setUsername('');
                    setPasssword(null);

                    props.onLogin(data.user);
                    history.push(props.returnUrl);
                    props.refreshCurrentPage();
                }
                else {
                    let emc = data.error;
                    setErrorMsg(emc);
                }
            })
            .catch(err => {
                setErrorMsg(err);
            });
    }

    const openContact = (e) => {
        e.preventDefault();
        window.open( 'https://form.jotform.com/221157757431052', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' );
      }

    if(currentDealer && !dealer) {
        return <div>Loading...</div>
    }

    console.log(dealer);
        
    return (
        <div className="main-100 dealer">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Dealer Membership | Followone</title>
                <meta name="description" content="We are here for you. If you can't find what you are looking for, contact us." />
                <link rel="canonical" href="https://followone.com/help" />
            </Helmet>

            <div className="main-100-header">
                <h1>Dealer Membership</h1>
                <p>One Brand, Multiple Applications to grow your dealership.</p>
            </div>


            {dealerId &&
             <div className="dealer-form dealer-form-footer">

                    <img src={Header2} alt="Followone Dealer Program" />

                    <div className="dealer-jotform">
                        <div className="content">

                            <div className="dealer-cost">
                                <p>
                                    As LOW as<br />
                                    <span className="dealer-price">$8.3</span> / day<br />
                                    <span className="dealer-terms">Paid Yearly</span>
                                </p> 
                            </div>

                            <h2>Get Started</h2>

                            <h4>30 Days Money Back Guarantee</h4>

                            <div>
                                {!dealerId 
                                ? <button onClick={e => openContact(e)}>Apply Now</button>
                                : <button onClick={onLoginDealer}>Sign In</button> }
                            </div>

                            {dealerId && 
                                <div className="disclaimer">
                                    <p>This is a temporary sign in generated specificaly for <strong className="capitalize"><a className="inline-link" target="_blank" rel="noreferrer" href={`/${dealer.slug}`}>{dealerName}</a></strong>. After you sign in follow these 3 steps to activate your account:</p>

                                    <ul>
                                        <li>1. Update Your Password</li>
                                        <li>2. Update Your Email Address</li>
                                        <li>3. Activate You Dealer Membership</li>
                                    </ul>

                                    <p>NOTE: After you change your password this link will be de-activated.</p>

                                    <p style={{marginTop: '1rem'}}>For questions please contact us:</p>
                                    <p><span>Local:</span> <strong>(425) 365-7993</strong><br /> <span>Toll-FREE:</span> <strong>(833) 531-2151</strong><br /> <span>Email:</span> <strong>dealer@followone.com</strong></p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            {!dealerId &&
                <>
                    <div className="dealer-activate">
                        <img src={Header1} />

                        <div className="dealer-activate-details">
                            <div className="dealer-cost">
                                <p>
                                    As LOW as<br />
                                    <span className="dealer-price">$8.3</span> / day<br />
                                    <span className="dealer-terms">Paid Yearly</span>
                                </p> 
                            </div>

                            <div className="dealer-contact">
                                <h2>Join the Auto Revolution</h2>
                                <p className="invite">To <strong>ACTIVATE</strong> your <strong>DEALER ACCOUNT</strong><br /> please contact us or <strong>APPLY</strong> bellow:</p>
                                <p><span>Local:</span> <a href="tel:4253657993">(425) 365-7993</a></p>
                                <p><span>Toll-FREE:</span> <a href="tel:8335312151">(833) 531-2151</a></p>
                                <p><span>Email:</span> <a href="mailto:dealer@followone.com">dealer@followone.com</a></p>
                            </div>
                        </div> 
                    </div>

                    <div className="dealer-form">
                        <img src={Header2} />

                        <div className="dealer-jotform">
                            <div className="content">
                                <h2>Get Started</h2>

                                <h4>30 Days Money Back Guarantee</h4>

                                <div>
                                    {!dealerId 
                                    ? <a href="javascript:void( window.open( 'https://form.jotform.com/221157757431052', 'blank', 'scrollbars=yes, toolbar=no, width=700, height=500' ) )"> Apply Now </a>
                                    : <button onClick={onLoginDealer}>Sign In</button> }
                                </div>

                                {dealerId && 
                                    <div className="disclaimer">
                                        <p>This is a temporary login generated specificaly for <strong className="capitalize">{dealerName}</strong>. After you login follow these 3 steps to activate your account:</p>

                                        <ul>
                                            <li>1. Update Your Password</li>
                                            <li>2. Update Your Email Address</li>
                                            <li>3. Activate You Dealer Membership</li>
                                        </ul>

                                        <p>NOTE: After you change your password this link will be de-activated.</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }

            <h2 className="section-h">DEDICATED AUTO PLATFORMS</h2>

            <div className="dealer-platforms">
                
                <p className="tag">Get access to our dedicated dealer platforms as part of the dealer membership.</p>
                
                <div className="dealer-platforms-items">
                    <div className="dealer-platforms-item">
                        <a target="_blank" href="https://zaklist.com/"><img src={ZakListHeader} alt="ZakList by Followone" /></a>

                        <div className="content">

                            <img className="auto-logo" src={ZakListLogo} alt="ZakList by Followone" />

                            <a target="_blank" className="web-link" href="https://zaklist.com/">ZakList.com <i className="fa-light fa-circle-arrow-right"></i></a>

                            <div className="apps">
                                <p>DOWNLOAD THE APPS</p>

                                <div>
                                    <img src={ZakListIcon} alt="ZakList by Followone" />
                                    <a target="_blank" href="https://apps.apple.com/us/app/zaklist/id1617155828"><i className="fa-brands fa-app-store"></i></a>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.brommko.android.zaklist&hl=en_US&gl=US"><i className="fa-brands fa-google-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dealer-platforms-item">
                        <a target="_blank" href="https://carverve.com/"><img src={CarVerveHeader} alt="CarVerve by Followone" /></a>

                        <div className="content">

                            <img className="auto-logo" src={CarVerveLogo} alt="CarVerve by Followone" />

                            <a target="_blank" className="web-link" href="https://carverve.com/">CarVerve.com <i className="fa-light fa-circle-arrow-right"></i></a>

                            <div className="apps">
                                <p>DOWNLOAD THE APPS</p>
                                <div>
                                    <img src={CarVerveIcon} alt="CarVerve by Followone" />
                                    <a target="_blank" href="https://apps.apple.com/us/app/carverve/id1617156389"><i className="fa-brands fa-app-store"></i></a>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.brommko.android.carverve&hl=en_US&gl=US"><i className="fa-brands fa-google-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dealer-platforms-item">
                        <a target="_blank" href="https://carzlook.com/"><img src={CarzLookHeader} alt="CarzLook by Followone" /></a>

                        <div className="content">

                            <img className="auto-logo" src={CarzLookLogo} alt="CarzLook by Followone" />

                            <a target="_blank" className="web-link" href="https://carzlook.com/">CarzLook.com <i className="fa-light fa-circle-arrow-right"></i></a>

                            <div className="apps">
                                <p>DOWNLOAD THE APPS</p>
                                <div>
                                    <img src={CarzLookIcon} alt="CarzLook by Followone" />
                                    <a target="_blank" href=""><i className="fa-brands fa-app-store"></i></a>
                                    <a target="_blank" href=""><i className="fa-brands fa-google-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h2 className="section-h">DEALER MEMBERSHIP PLAN BENEFITS </h2>

            <div className="dealer-benefits">
                <div className="dealer-benefits-item">
                    <i className="fa-light fa-car" style={{color: '#bfbfbf'}}></i>
                    <h4>SHOWCASE YOUR INVENTORY</h4>
                    <p>We manange dealer’s inventory on Followone, ZakList, CarzLook and CarVerve as part of the dealer membership, saving the dealer aproximatly $60k / year allowing the dealer to focus on sales.</p>
                </div>

                <div className="dealer-benefits-item">
                    <i className="fa-light fa-magnifying-glass" style={{color: '#74934b'}}></i>
                    <h4>INCREASE FINDABILITY</h4>
                    <p>Show up organically on Google, Bing and Yahoo search. The more you stay on our website, the better you'll rank.</p>
                </div>

                <div className="dealer-benefits-item">
                    <i className="fa-light fa-link" style={{color: '#397dc0'}}></i>
                    <h4>DRIVING TRAFFIC TO YOU</h4>
                    <p>We link back to your website and improve your website's Search Engine Optimization and position on search engines like Google, Bing or Yahoo.</p>
                </div>

                <div className="dealer-benefits-item">
                    <i className="fa-light fa-map-location-dot" style={{color: '#5dbe7e'}}></i>
                    <h4>GET EXPOSURE LOCALLY</h4>
                    <p>We focus on bringing you local customers. Our dedicated auto platforms bring you ready to buy customers.</p>
                </div>

                <div className="dealer-benefits-item">
                    <i className="fa-light fa-tags" style={{color: '#ee3130'}}></i>
                    <h4>PROMOTE DEALS & INVENTORY</h4>
                    <p>Add special deals to your products and services. Our platforms offer you unique advertising methods to get your inventory in front.</p>
                </div>

                <div className="dealer-benefits-item">
                    <img src={AccreditedLogo} alt="This business is accredited by Followone" />
                    <h4>BOOST CREDIBILITY</h4>
                    <p>Get the Followone Accredited logo on your profile page and a window sticker.</p>
                </div>

                <div className="dealer-benefits-item">
                    <i className="fa-light fa-id-card" style={{color: '#444444'}}></i>
                    <h4>ONE LOGIN</h4>
                    <p>Access all websites with one login, same user ID and Password.</p>
                </div>

                <div className="dealer-benefits-item">
                    <i className="fa-light fa-photo-film" style={{color: '#66bdd1'}}></i>
                    <h4>CONNECT WITH CLIENTS</h4>
                    <p>Enrich your profile page by adding products, services, jobs, photos, videos, blogs & events. </p>
                </div>

                <div className="dealer-benefits-item">
                    <i className="fa-light fa-users" style={{color: '#29ad7e'}}></i>
                    <h4>BUILD RELATIONSHIPS</h4>
                    <p>Get closer to your customers by turning them into your followers, our chat system allowes you to add a personal touch and answer their questions on the fly.</p>
                </div>
            </div>

            <div className="website-presentation">

                  <img className="logo-img" src={ZakListLogo} />

                  <img className="main-img" src={ZakListHeaderLg} />

                  <div className="content">
                    <p>Shopping for cars should be easy. ZakList, powered by Followone, makes it easy for buyers to browse a huge collection of vehicles.</p>

                    <p>ZakList provides dealers a wide range of tools to help them promote their inventory, boost online visibility and increase sales.</p>
                        
                    <img className="shot-img" src={ZakListShots} />
                  </div>
            </div>

            <div className="website-presentation">
                  <img className="logo-img" src={CarVerveLogo} />

                  <img className="main-img" src={CarVerveHeaderLg} />

                  <div className="content">
                    <p>CarVerve, by definition performance, powered by Followone, allows car buyers to easily find their next vehicle. Our focus is one goal: to deliver a unqiue experience of discovering your next ride.</p>

                    <p>CarVerve offer a special package just for dealers. On CarVerve we manange the entire dealer's inventory, showing buyers everything a dealer has to offer.</p>

                    <img className="shot-img" src={CarVerveShots} />
                  </div>
            </div>

            <div className="website-presentation">

                <img className="logo-img" src={CarzLookLogo} />

                  <img className="main-img" src={CarzLookHeaderLg} />

                  <div className="content">
                    <p>Our vision is simple, we created a tool dedicated to selling and buying cars.</p>

                    <p>Buyers can browse dealers inventory directly from CarzLook.com and our tools makes it easy for them to connect directly with dealer's representative. Our unique set of tools brings buyers and dealers closer.</p>
                    
                    <img className="shot-img" src={CarzLookShots} />
                  </div>
            </div>


            <div className="dealer-form dealer-form-footer">

                <img src={Header3} />

                <div className="dealer-jotform">
                    <div className="content">

                        <div className="dealer-cost">
                            <p>
                                As LOW as<br />
                                <span className="dealer-price">$8.3</span> / day<br />
                                <span className="dealer-terms">Paid Yearly</span>
                            </p> 
                        </div>

                        <h2>Get Started</h2>

                        <h4>30 Days Money Back Guarantee</h4>

                        <div>
                            {!dealerId 
                            ? <button onClick={e => openContact(e)}>Apply Now</button>
                            : <button onClick={onLoginDealer}>Sign In</button> }
                        </div>

                        {dealerId && 
                            <div className="disclaimer">
                                <p>This is a temporary sign in generated specificaly for <strong className="capitalize">{dealerName}</strong>. After you sign in follow these 3 steps to activate your account:</p>

                                <ul>
                                    <li>1. Update Your Password</li>
                                    <li>2. Update Your Email Address</li>
                                    <li>3. Activate You Dealer Membership</li>
                                </ul>

                                <p>NOTE: After you change your password this link will be de-activated.</p>

                                <p style={{marginTop: '1rem'}}>For questions please contact us:</p>
                                <p><span>Local:</span> <strong>(425) 365-7993</strong><br /> <span>Toll-FREE:</span> <strong>(833) 531-2151</strong><br /> <span>Email:</span> <strong>dealer@followone.com</strong></p>
                            </div>
                         }
                    </div>
                </div>
            </div>
        </div>
    )
}


const mapStateToAccountLoginModalProps = (
    state,
    props
) => {
    return {
        active: state.accountLoginModalActive,
        userLanguage: state.userLanguage,
        returnUrl: state.returnUrl
    };
};

const mapDispatchToAccountLoginModalProps = (
    dispatch
) => {
    return {
        onLogin: (u) => {
            dispatch({ type: actionType.USER_LOGIN, userIsAuthenticated: true, loggedInUser: u });
        },
        // onClickCloseHandler: () => {
        //     dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: false });
        // },
        // switchToSignUpModal: () => {
        //     dispatch({ type: actionType.ACCOUNT_TYPE_MODAL, accountTypeModalActive: true });
        //     setTimeout(() => {
        //         dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: false });
        //     }, 50);
        // },
        refreshCurrentPage: () => dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: 'new-login-' + Date.now() })
    };
};


export default connect(mapStateToAccountLoginModalProps, mapDispatchToAccountLoginModalProps)(Dealer);