import React, { Component } from 'react';
// import ProfileSettingsNav from '../ProfileNav/ProfileSettingsNav';
import ThePlans from './Components/ThePlans';
import SettingsMenu from "../SettingsMenu";

class SubscriptionSettings extends Component {
    render () {
        return (
            <SettingsMenu SubscriptionLiActive="active">


                        <ThePlans />

            </SettingsMenu>
        );
    }
}

export default SubscriptionSettings;
