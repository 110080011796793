import React from 'react';

const SellerFindBusinesses = props => {
    return (
      <div className="seller-findbiz">
          <div className="seller-findbiz-search">
              <div className="form-input-select" >
                  <select className="form-input-full">
                      <option value="section">Section</option>
                      <option value="option1">...</option>
                  </select>
              </div>

              <div className="form-input-select" >
                  <select className="form-input-full">
                      <option value="country">Country</option>
                      <option value="option1">...</option>
                  </select>
              </div>

              <div className="form-input-select" >
                  <select className="form-input-full">
                      <option value="state">State</option>
                      <option value="option1">...</option>
                  </select>
              </div>

              <div className="form-input-select" >
                  <select className="form-input-full">
                      <option value="city">City</option>
                      <option value="option1">...</option>
                  </select>
              </div>
          </div>

          <div className="seller-findbiz-results">
              <table>
                  <thead>
                      <tr>
                          <td>Name</td>
                          <td>Address</td>
                          <td>Listings</td>
                          <td>Subscription</td>
                          <td>Seller</td>
                          <td>Actions</td>
                      </tr>
                  </thead>

                  <tbody>
                    <tr className="biz-info">
                        <td>Followone</td>
                        <td>
                            <span>777 108th AVE NE<br />Bellevue WA, 98004</span>
                        </td>
                        <td>4</td>
                        <td>Basic</td>
                        <td>John Disckson</td>
                        <td>
                            <button><i className="fal fa-plus" /></button>
                            {/*<button><i className="fal fa-check"></i></button>*/}
                            {/*<button><i className="fal fa-times"></i></button>*/}
                            <button onClick={props.toggleModal}><i className="fal fa-info" /></button>
                        </td>
                    </tr>

                    <tr className="biz-info">
                        <td>Honda of Bellevue</td>
                        <td>
                            <span>13291 SE 36th ST<br />Bellevue WA, 98006</span>
                        </td>
                        <td>256</td>
                        <td>Social</td>
                        <td>N/A</td>
                        <td>
                            <button><i className="fal fa-plus" /></button>
                            {/*<button><i className="fal fa-check"></i></button>*/}
                            {/*<button><i className="fal fa-times"></i></button>*/}
                            <button onClick={props.toggleModal}><i className="fal fa-info" /></button>
                        </td>
                    </tr>
                  </tbody>
              </table>
          </div>

          {/*  Add Pagination  */}
      </div>
    )
}

export default SellerFindBusinesses;