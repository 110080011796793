import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import Axios from "axios";
import { connect } from "react-redux";
import { hostNameAndPort } from "../../../../shared/util/vars";
import ListLoader from "../../../../shared/util/list-loader";
import { getTranslatedPhrase } from "../../../../shared/util/lang";

const params = {
  slidesPerView: "auto",
  initialSlide: 1,
  freeMode: true,
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true,
  },
};

const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

class ProfileNavigation extends Component {
  state = {
    items: {},
    labels: [
      "store",
      "food",
      "autos",
      "realestate",
      "jobs",
      "services",
      "travel",
      "blogs",
      "photos",
      "videos",
      "events",
      "groups",
    ],
    tabs: [
      "Products",
      "Auto Listings",
      "Foods",
      "Properties",
      "Jobs",
      "Services",
      "Travel",
      "Videos",
      "Blogs",
      "Photo Galleries",
      "Events",
      "Groups",
    ],
    tabNumbers: {},
    Loader: null,
    photos: [],
    user: {},
  };

  componentDidMount() {
    let s = window.location.pathname;

    let pathSize = s.split("/").length;

    if (pathSize > 2) {
      s = s.split("/")[1];
    } else if (pathSize <= 2) {
      s = s.slice(1);
    }

    this.initTabs();
    this.getNavigationItems(s);
  }

  componentDidUpdate() {
    let g = this.props.userId;
    let p = this.props.userIsAuthenticated;

    if (this.state.userIsAuthenticated !== p || this.state.userId !== g) {
      this.updateTabs();
      this.setState({ userId: g, userIsAuthenticated: p });

      let s = window.location.pathname;

      let pathSize = s.split("/").length;

      if (pathSize > 2) {
        s = s.split("/")[1];
      } else if (pathSize <= 2) {
        s = s.slice(1);
      }

      this.getNavigationItems(s);

      // if (!this.state.Loader || this.props.userId !== this.state.userId) this.initLoader();
    }
  }

  componentWillUnmount() {
    if (this.state.Loader) this.state.Loader.reset();
  }

  initLoader = () => {
    // let Loader = new ListLoader({
    //     cb: this.onLoad,
    //     options: {
    //         delay: 200000,
    //         count: 4,
    //         request: {
    //             url: '/api/user/preview/items',
    //             method: 'post',
    //             formParams: { filter: JSON.stringify({ author: this.props.userId, dealer: this.props.dealerId, realestateAgency: this.props.realestateAgency }) }
    //         }
    //     }
    // });
    // Loader.init();
    // this.setState({ Loader });
  };

  onLoad = () => {
    // if (!this.state.Loader) return;
    // this.setState({ items: this.state.Loader.items });
  };

  getNavigationItems = async (u) => {
    try {
      let _data = new FormData();
      _data.append("user", u);

      let options = {
        url: `${hostNameAndPort}/api/user/profile/navigation`,
        method: "post",
        data: _data,
      };

      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        // console.log('data', data)

        this.setState({ items: data.items });
      } else {
        console.log(data.error);
      }
    } catch (err) {
      console.log(err);
    }
  };

  initTabs() {
    let _tabs = this.state.tabs;
    // let a = {};
    let b = {};
    for (let i in _tabs) {
      // a[_tabs[i]] = false;
      b[_tabs[i]] = 0;
    }

    this.setState({
      // tabActive: a,
      tabNumbers: b,
    });
  }

  onClickHandler = (section) => {
    this.props.history.push(`/${this.props.slug}/${section}`);
  };

  updateTabs = async () => {
    try {
      let filter = JSON.stringify({
        author: this.props.userId,
        dealer: this.props.dealerId,
        realestateAgency: this.props.realestateAgency,
      });
      let _data = new FormData();
      _data.append("filter", filter);

      let opts = {
        url: hostNameAndPort + "/api/user/preview/count",
        method: "post",
        data: _data,
      };
      let result = await Axios(opts);
      let { data } = result;
      if (!data.error) {
        // console.log(data);

        let a = {};
        let isFirstItem = true;
        for (let i in data.count) {
          if (data.count[i]) {
            if (isFirstItem) {
              a[i] = true;
              isFirstItem = false;
            }
          } else a[i] = false;
        }

        this.setState({
          tabActive: a,
          tabNumbers: data.count,
        });
      }
    } catch (err) {
      if (process.env.NODE_ENV) console.log(err);
    }
  };

  render() {
    let { items } = this.state;
    if (!items)
      items = {
        blogs: [],
        photos: [],
        videos: [],
        store: [],
        food: [],
        autos: [],
        realestate: [],
        jobs: [],
        services: [],
        travel: [],
        events: [],
        groups: [],
      };

    // let photosCount = 0;

    // if (items.photos) {
    //     photosCount = items.photos.length;
    // }

    return (
      <div className="profile_header">
        <div className="logged-in-header profile-navigation">
          <div className="logged-in-header_to-do">
            <div className="logged-in-header_to-do_swiper">
              {typeof this.props.isProfileOwner === "undefined" ? null : (
                <Swiper {...params}>
                  <div className="profile-nav-box-title profile-nav-box-title-1">
                    <span>
                      {getTranslatedPhrase(this.props.userLanguage, "Profile")}
                    </span>
                  </div>

                  <div className="profile-nav-box">
                    <button
                      className="home-swiper-button about-gradient"
                      onClick={() => this.onClickHandler("about")}
                    >
                      <i className="fal fa-book-open" />{" "}
                      <span>
                        {getTranslatedPhrase(this.props.userLanguage, "About")}
                      </span>
                    </button>
                  </div>

                  <ReviewsSwiperItem
                    accountType={this.props.accountType}
                    slug={this.props.slug}
                    userLanguage={this.props.userLanguage}
                    reviewCount={this.props.stats.noReviews}
                    onClickHandler={this.onClickHandler}
                  />
                  <FavoritesSwiperItem
                    slug={this.props.slug}
                    isProfileOwner={this.props.isProfileOwner}
                    userLanguage={this.props.userLanguage}
                    totalFavorites={this.props.allFavCount}
                    onClickHandler={this.onClickHandler}
                  />
                  <SettingsSwiperItem
                    isProfileOwner={this.props.isProfileOwner}
                    userLanguage={this.props.userLanguage}
                  />

                  <div className="profile-nav-box-title profile-nav-box-title-3">
                    <span>
                      {getTranslatedPhrase(this.props.userLanguage, "Business")}
                    </span>
                  </div>

                  <SectionSwiperItem
                    section="store"
                    sectionLabel="Products"
                    newItemUrl="/store/new-product"
                    newItemLabel={getTranslatedPhrase(
                      this.props.userLanguage,
                      "Sell Products"
                    )}
                    sectionClassName="store-gradient"
                    iconClassName="fal fa-shopping-bag"
                    items={items.store}
                    itemCount={this.state.tabNumbers["Products"]}
                    isProfileOwner={this.props.isProfileOwner}
                    onClickHandler={this.onClickHandler}
                  />

                  <SectionSwiperItem
                    section="autos"
                    sectionLabel="Auto Listings"
                    newItemUrl="/autos/new-auto-listing"
                    newItemLabel={getTranslatedPhrase(
                      this.props.userLanguage,
                      "Sell Cars"
                    )}
                    sectionClassName="auto-gradient"
                    iconClassName="fal fa-car"
                    items={items.autos}
                    itemCount={this.state.tabNumbers["Auto Listings"]}
                    isProfileOwner={this.props.isProfileOwner}
                    onClickHandler={this.onClickHandler}
                  />

                  {/* <SectionSwiperItem section="food" sectionLabel="Foods" newItemUrl="/food/new-food-listing" newItemLabel={getTranslatedPhrase(this.props.userLanguage, "Post Foods")} sectionClassName="food-gradient" iconClassName="fal fa-utensils"
                                        items={items.food} itemCount={this.state.tabNumbers['Foods']} isProfileOwner={this.props.isProfileOwner} onClickHandler={this.onClickHandler} /> */}

                  <SectionSwiperItem
                    section="realestate"
                    sectionLabel="Properties"
                    newItemUrl="/realestate/new-property"
                    newItemLabel={getTranslatedPhrase(
                      this.props.userLanguage,
                      "Sell Properties"
                    )}
                    sectionClassName="real-estate-gradient"
                    iconClassName="fal fa-building"
                    items={items.realestate}
                    itemCount={this.state.tabNumbers["Properties"]}
                    isProfileOwner={this.props.isProfileOwner}
                    onClickHandler={this.onClickHandler}
                  />

                  <SectionSwiperItem
                    section="jobs"
                    sectionLabel="Jobs"
                    newItemUrl="/jobs/new-job"
                    newItemLabel={getTranslatedPhrase(
                      this.props.userLanguage,
                      "Post Jobs"
                    )}
                    sectionClassName="jobs-gradient"
                    iconClassName="fal fa-briefcase"
                    items={items.jobs}
                    itemCount={this.state.tabNumbers["Jobs"]}
                    isProfileOwner={this.props.isProfileOwner}
                    onClickHandler={this.onClickHandler}
                  />

                  <SectionSwiperItem
                    section="services"
                    sectionLabel="Services"
                    newItemUrl="/services/new-service"
                    newItemLabel={getTranslatedPhrase(
                      this.props.userLanguage,
                      "Add Services"
                    )}
                    sectionClassName="services-gradient"
                    iconClassName="fal fa-wrench"
                    items={items.services}
                    itemCount={this.state.tabNumbers["Services"]}
                    isProfileOwner={this.props.isProfileOwner}
                    onClickHandler={this.onClickHandler}
                  />

                  {/* <SectionSwiperItem
                    section="travel"
                    sectionLabel="Travel Packages"
                    newItemUrl="/travel/new-travel"
                    newItemLabel={getTranslatedPhrase(
                      this.props.userLanguage,
                      "Add Travel Packages"
                    )}
                    sectionClassName="travel-gradient"
                    iconClassName="fal fa-bed"
                    items={items.travel}
                    itemCount={this.state.tabNumbers["Travel"]}
                    isProfileOwner={this.props.isProfileOwner}
                    onClickHandler={this.onClickHandler}
                  /> */}

                  <div className="profile-nav-box-title profile-nav-box-title-2">
                    <span>
                      {getTranslatedPhrase(this.props.userLanguage, "Social")}
                    </span>
                  </div>

                  <SectionSwiperItem
                    section="blogs"
                    sectionLabel="Blogs"
                    newItemUrl="/blogs/new-blog"
                    newItemLabel={getTranslatedPhrase(
                      this.props.userLanguage,
                      "Write a Blog"
                    )}
                    sectionClassName="blog-gradient"
                    iconClassName="fal fa-blog"
                    items={items.blogs}
                    itemCount={this.state.tabNumbers["Blogs"]}
                    isProfileOwner={this.props.isProfileOwner}
                    onClickHandler={this.onClickHandler}
                  />

                  <SectionSwiperItem
                    section="photos"
                    sectionLabel="Photo Galleries"
                    newItemUrl="/photos/new-photo"
                    newItemLabel={getTranslatedPhrase(
                      this.props.userLanguage,
                      "Add Gallery"
                    )}
                    sectionClassName="photos-gradient"
                    iconClassName="fal fa-camera-retro"
                    items={items.photos}
                    itemCount={this.state.tabNumbers["Photo Galleries"]}
                    isProfileOwner={this.props.isProfileOwner}
                    onClickHandler={this.onClickHandler}
                  />

                  <SectionSwiperItem
                    section="videos"
                    sectionLabel="Videos"
                    newItemUrl="/videos/new-video"
                    newItemLabel={getTranslatedPhrase(
                      this.props.userLanguage,
                      "Add Video"
                    )}
                    sectionClassName="videos-gradient"
                    iconClassName="fal fa-video"
                    items={items.videos}
                    itemCount={this.state.tabNumbers["Videos"]}
                    isProfileOwner={this.props.isProfileOwner}
                    onClickHandler={this.onClickHandler}
                  />

                  <SectionSwiperItem
                    section="events"
                    sectionLabel="Events"
                    newItemUrl="/events/new-event"
                    newItemLabel={getTranslatedPhrase(
                      this.props.userLanguage,
                      "Add an Event"
                    )}
                    sectionClassName="events-gradient"
                    iconClassName="fal fa-calendar-alt"
                    items={items.events}
                    itemCount={this.state.tabNumbers["Events"]}
                    isProfileOwner={this.props.isProfileOwner}
                    onClickHandler={this.onClickHandler}
                  />

                  {/* <SectionSwiperItem section="groups" sectionLabel="Groups" newItemUrl="/groups/new-group" newItemLabel={getTranslatedPhrase(this.props.userLanguage,"Start a Group")} sectionClassName="groups-gradient" iconClassName="fal fa-users" 
                                    items={items.groups} itemCount={this.state.tabNumbers['Groups']} isProfileOwner={this.props.isProfileOwner} onClickHandler={this.onClickHandler} /> */}

                  <div className="profile-nav-box profile-nav-box-last" />
                </Swiper>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function ReviewsSwiperItem(props) {
  if (props.accountType === "personal") return null;
  return (
    <div className="swiper-slide profile-nav-box">
      <Link
        to={"/" + props.slug + "/reviews"}
        className="home-swiper-button reviews-gradient"
        onClick={() => {
          props.onClickHandler("about");
          let a = document.createElement("A");
          // a.href = "#reviews";
          a.click();
        }}
      >
        <i className="fal fa-star" />{" "}
        <span>
          {!props.reviewCount ? null : `${props.reviewCount}`}{" "}
          {getTranslatedPhrase(props.userLanguage, "Reviews")}
        </span>
      </Link>
    </div>
  );
}

function FavoritesSwiperItem(props) {
  // {!props.totalFavorites?null:`(${props.totalFavorites})`}
  if (!props.isProfileOwner) return null;
  return (
    <div className="swiper-slide profile-nav-box">
      <Link
        to={`/${props.slug}/favorites`}
        className="home-swiper-button favorites-gradient"
        onClick={() => props.onClickHandler("favorites")}
      >
        <i className="fal fa-bookmark" />{" "}
        <span>
          {props.totalFavorites}{" "}
          {getTranslatedPhrase(props.userLanguage, "Favorites")}
        </span>
      </Link>
    </div>
  );
}

function SettingsSwiperItem(props) {
  if (!props.isProfileOwner) return null;
  return (
    <div className="swiper-slide profile-nav-box">
      <Link
        to="/profile-update"
        className="home-swiper-button settings-gradient"
      >
        <i className="fal fa-cog" />
        <span className="viewer-cta">
          {getTranslatedPhrase(props.userLanguage, "Settings")}
        </span>
      </Link>
    </div>
  );
}

function SectionSwiperItem(props) {
  function displayThumbnail(items, pos) {
    if (!items || !items[pos]) return null;
    return (
      <div className="square-menu-item_box">
        <img src={items[pos].featuredImage} alt={items[pos].title} />
      </div>
    );
  }

  function displayThumbnailPlaceholder(item, pos, iconClassName) {
    if (!item || !item[pos])
      return (
        <div className="square-menu-item_box">
          <i className={iconClassName} />
        </div>
      );
    else return null;
  }

  if (!props.itemCount)
    if (!props.isProfileOwner) {
      return (
        <div className="swiper-slide profile-nav-box">
          <button className={"home-swiper-button " + props.sectionClassName}>
            <i className={props.iconClassName} />
            <span className="viewer-cta">
              0 {getTranslatedPhrase(props.userLanguage, props.sectionLabel)}
            </span>
          </button>
        </div>
      );
    } else
      return (
        <div className="swiper-slide profile-nav-box">
          <Link
            to={props.newItemUrl || "/"}
            className={"home-swiper-button " + props.sectionClassName}
          >
            <i className={props.iconClassName} />
            <span className="viewer-cta">
              {getTranslatedPhrase(props.userLanguage, props.newItemLabel)}
            </span>
          </Link>
        </div>
      );

  return (
    <div
      className="swiper-slide profile-nav-box"
      onClick={(e) => props.onClickHandler(props.section)}
    >
      <button className="square-menu-item">
        {displayThumbnail(props.items, 0)}
        {displayThumbnail(props.items, 1)}
        {displayThumbnail(props.items, 2)}
        {displayThumbnail(props.items, 3)}

        {displayThumbnailPlaceholder(props.items, 3, props.iconClassName)}
        {displayThumbnailPlaceholder(props.items, 2, props.iconClassName)}
        {displayThumbnailPlaceholder(props.items, 1, props.iconClassName)}

        <div className={"square-menu-item-icon " + props.sectionClassName} />
        <div className="square-menu-item-text">
          <i className={props.iconClassName} />
          <span>
            {props.itemCount}{" "}
            {getTranslatedPhrase(props.userLanguage, props.sectionLabel)}
          </span>
        </div>
      </button>
    </div>
  );
}

const mapStateToProfileNavigation = (state, props) => {
  return {
    userLanguage: state.userLanguage,
  };
};

export default connect(mapStateToProfileNavigation)(
  withRouter(ProfileNavigation)
);
