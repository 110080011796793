import React, { Component } from 'react';
import { hostNameAndPort } from '../../../../shared/util/vars';
import Axios from 'axios';
import {connect} from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../../shared/util/lang';


class PostSomethingNew extends Component {
    state = {
        statusText: '',
        textPrivacy: 'public'
    }

    onChangeStatusTextHandler = (e) => {
        if ( e.target.value.length > 10000 ) {
            return;
        }
        this.setState({ statusText: e.target.value });
    }

    onChangeTextPrivacy = (e) => {
        this.setState({ textPrivacy: e.target.value });
    }

    onPublishText = () => {
        if (!this.state.statusText) {
            return;
        } 
        let _textData = new FormData();
        _textData.append('groupId', this.props.groupId || '');
        _textData.append('textContent', this.state.statusText);
        _textData.append('privacy', this.state.textPrivacy);
        let options = {
                url: `${hostNameAndPort}/api/texts/update`,
                method: 'post',
                data: _textData
            };
        Axios(options)
        .then(result => {
            let {data} = result;
            if (!data.error) {
                this.setState({statusText: ''});
                this.props.onPostNewText(data.activity);
                this.props.pageShouldRefresh(data.activity.activityDate);
            }
        })
        .catch(err => {
            // Network error
        });
    }
    
    render () {
        return (
            <div className="PostSomethingNew">
                <textarea placeholder={getTranslatedPhrase(this.props.userLanguage,'Post something great...')} value={this.state.statusText} onChange={this.onChangeStatusTextHandler} />

                <div className="PostSomethingNewTools">
                    {/*<div className="left">
                        <button><i className="fal fa-camera-retro"></i></button>
                        <button><i className="fal fa-link"></i></button>
                        <button><i className="fal fa-calendar-alt"></i></button>
                        <button><i className="fal fa-map-marker-alt"></i></button>
                        <button><i className="fal fa-video"></i></button>
                        <button><i className="fal fa-language"></i></button>
                    </div>*/}

                    <div className="right">                            
                        <i className="fal fa-users" />
                        <span className="selectDesign">
                            <select value={this.state.textPrivacy} onChange={this.onChangeTextPrivacy}>
                                <option value="public">{getTranslatedPhrase(this.props.userLanguage,'Public')}</option>
                                <option value="followers">{getTranslatedPhrase(this.props.userLanguage,'Followers')}</option>
                            </select>
                        </span>
                        <button className="button button-regular" onClick={this.onPublishText}>{getTranslatedPhrase(this.props.userLanguage,'Post')}</button>
                    </div>

                    <div className="clb" />
                </div>
            </div>
        );
    }  
}   

//export default PostSomethingNew;

const mapStateToPostSomethingNewProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};


const mapDispatchToPostSomethingNewProps = (
    dispatch
) => {
    return {
        pageShouldRefresh: (p) => {
            dispatch({type: actionType.PAGE_REFRESH, pageRefresh: p });
        },
    };
};

export default connect(mapStateToPostSomethingNewProps, mapDispatchToPostSomethingNewProps)(PostSomethingNew);