import React from "react";

export default function ContactListItem(props) {
  let style;

  let { entityName, profileImage, isOnline } = props.data;

  if (!profileImage)
    profileImage =
      "https://cdn-westus2.followone.com/images/default-profile-image.jpg";

  return (
    <div
      className="conversation-list-item"
      onClick={props.onClick}
      style={style}
    >
      <img
        className="conversation-photo"
        src={profileImage}
        style={{ width: "3rem", height: "3rem" }}
        alt="conversation"
      />
      <div className="conversation-info">
        <h1 className="conversation-title">{entityName}</h1>
      </div>
      {!isOnline ? null : <i className="fas fa-circle" />}
    </div>
  );
}
