import React, { useState } from 'react';
import ServicePreview from './ServicePreview';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import ServicesSideBar from './ServicesSideBar';
import ServicesSideBarMobile from './ServicesSideBarMobile';
import ServicesFeatured from './ServicesFeatured';
import ServicesHeader from '../../Home/Components/Services';
import feaImage from '../../../assets/category/followone_cat_feature_services.jpg';

function ServicesCategory() {
  const [categoryList, setCategoryList] = useState([]);

  function onUpdateCategories(c) {
    if (!categoryList.length)
      setCategoryList(c);
  }

  return (
    <>
      <div className="category-search-panel" style={{ backgroundImage: `url(${feaImage})` }}>
        <ServicesHeader category section="services" categoryList={categoryList} />
      </div>
      <CategoryPage
        section="services"
        description="Offer Your Services or Hire Someone Else’s"
        ItemPreview={ServicePreview}
        SideBar={(props) => (<ServicesSideBar onLoadCategories={onUpdateCategories} {...props} />)}
        SideBarMobile={(props) => (<ServicesSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
        FeaturedItems={ServicesFeatured}
      />
    </>
  );
}

export default ServicesCategory;