import React from 'react';

const SellerRevenue = () => {
    return (
      <>
          <div className="seller-rev">
              <div className="seller-rev-box">
                  <p className="seller-rev-title">Upcoming Revenue</p>
                  <p className="seller-rev-amount">$3,245.78</p>

                  <table>
                      <tbody>
                          <tr>
                              <td className="premium">Premium</td>
                              <td className="enterprise">Enterprise</td>
                          </tr>
                          <tr>
                              <td><span>Qty:</span> 1</td>
                              <td><span>Qty:</span> 1</td>
                          </tr>
                          <tr>
                              <td>$299</td>
                              <td>$499</td>
                          </tr>
                      </tbody>
                  </table>
              </div>

              <div className="seller-rev-box">
                  <p className="seller-rev-title">2020 Revenue</p>
                  <p className="seller-rev-amount">$24,000.00</p>

                  <table>
                      <tbody>
                          <tr>
                              <td className="premium">Premium</td>
                              <td className="enterprise">Enterprise</td>
                          </tr>
                          <tr>
                              <td><span>Qty:</span> 20</td>
                              <td><span>Qty:</span> 30</td>
                          </tr>
                          <tr>
                              <td>$5,999</td>
                              <td>$14,999</td>
                          </tr>
                      </tbody>
                  </table>
              </div>

              <div className="seller-rev-box">
                  <p className="seller-rev-title">Total Revenue</p>
                  <p className="seller-rev-amount">$129,000.00</p>

                  <table>
                      <tbody>
                          <tr>
                              <td className="premium">Premium</td>
                              <td className="enterprise">Enterprise</td>
                          </tr>
                          <tr>
                              <td><span>Qty:</span> 80</td>
                              <td><span>Qty:</span> 60</td>
                          </tr>
                          <tr>
                              <td>$29,999</td>
                              <td>$34,999</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>

          <div className="seller-payment">
              <p>Your current paycheck is: <span>$2,780.00</span></p>
              <button className="button button-white">Request Paycheck</button>
          </div>
      </>
    )
}

export default SellerRevenue;