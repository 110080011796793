import React, { useState, useEffect } from "react";
import LayoutSideBar from "../../../shared/util/layout-sidebar";
import CreateNewButton from "../../../shared/components/SideBar/CreateNewButton/CreateNewButton";
import OtherItems from "../../../shared/components/SideBar/ProductsHolder/ProductsHolder";
import Axios from "axios";
import { hostNameAndPort } from "../../../shared/util/vars";
import UserList from "../../../shared/components/SideBar/UserList/UserList";
import CouponsSideBar from "../../../shared/components/SideBar/CouponsSideBar/CouponsSideBar";
import ProfileInfoBox from "../../../shared/components/SideBar/ProfileInfoBox/ProfileInfoBox";
import BrandLogo from "../../../shared/components/SideBar/BrandLogo/BrandLogo";
import AdminPanel from "../../../shared/components/SideBar/AdminPanel/AdminPanel";
import moment from "moment";

function ListingSideBar(props) {
  let [itemList, setItemList] = useState([]);
  let [profileDetails, setProfileDetails] = useState({});
  let [toggleSidebar, setToggleSidebar] = useState(false);
  const { section, itemId, itemSlug, authorId, authorSlug, carId } = props;
  const [authorProfileImage, setAuthorProfileImage] = useState(
    props.authorProfileImage
  );
  const [authorName, setAuthorName] = useState(props.authorName);
  const [authorSlugLocal, setAuthorSlug] = useState(props.authorSlug);
  const [howManyTimes, setHowManyTimes] = useState(0);

  async function getOtherItems() {
    try {
      let k = authorId;
      if (!k) return;
      let itemListParams = new FormData();
      if (section === "cars")
        itemListParams.append("filter", JSON.stringify({ dealer: k }));
      else itemListParams.append("filter", JSON.stringify({ author: k }));
      itemListParams.append("start", 0);
      itemListParams.append("count", 3);
      let options = {
        url: `${hostNameAndPort}/api/${section}/items`,
        method: "post",
        data: itemListParams,
      };
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        let a;
        if (!carId) a = data.items.filter((v) => v.itemId !== itemId);
        else a = data.items.filter((v) => v.id !== carId);
        a = a.map((v) => ({ ...v, originalWebsiteSection: section }));
        if (a.length > 2) {
          a.pop();
        }
        setItemList(a);
      }
    } catch (err) {
      // Error
    }
  }

  useEffect(() => {
    getOtherItems();
  }, [itemId]);

  async function getContactInfo() {
    let u = authorId;
    if (!u) {
      return;
    }
    let url = `${hostNameAndPort}/api/user/contact/${u}/autos`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        setProfileDetails(data.contact);
      }
    } catch (err) {
      // Network error
    }
  }

  // useEffect(() => {
  //   getContactInfo();
  // }, [authorId]);

  async function createNewDealer() {
    try {
      let _data = new FormData();
      _data.append("item", JSON.stringify(props.carDetails));
      let options = {
        url: `${hostNameAndPort}/api/cars/add-dealer`,
        method: "post",
        data: _data,
      };
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        let { profileImage = "", userName = "", slug = "" } = data.dealer;
        setAuthorName(userName);
        setAuthorProfileImage(profileImage);
        setAuthorSlug(slug);
        data.dealer.entityContact = {
          profileID: slug,
          Joined: moment(new Date(data.dealer.entityContact.Joined)).format(
            "Y"
          ),
          ...data.dealer.entityContact,
        };
        setProfileDetails(data.dealer.entityContact);
      }
    } catch (err) {
      // Network error
    }
  }

  useEffect(() => {
    if (howManyTimes < 1)
      if (!authorSlug) {
        createNewDealer();
      } else {
        getContactInfo();
      }
    setHowManyTimes(howManyTimes + 1);
  }, [authorSlug]);

  function onToggleSidebar() {
    setToggleSidebar(true);
    setTimeout(() => {
      setToggleSidebar(false);
    }, 500);
  }

  let {
    category = "all",
    createNewTitle = "New Listing",
    likeList = [],
  } = props;

  return (
    <LayoutSideBar toggleSidebar={toggleSidebar}>
      <AdminPanel
        itemType={section}
        itemSlug={itemSlug}
        itemId={itemId}
        isFromCarsDB={props.isFromCarsDB}
        userIsAuthorized={props.userIsAuthorized}
      />

      <BrandLogo
        BrandLogoImg={<img src={authorProfileImage} alt={authorName} />}
        BrandLogoName={authorName}
        BrandURL={"/" + authorSlugLocal}
      />

      <ProfileInfoBox
        infoBoxTitle="About The Author"
        profileDetails={profileDetails}
      />

      <CreateNewButton section={section} title={createNewTitle} />

      <UserList
        UserListTitle="Who Liked This"
        userList={likeList}
        onToggleSidebar={onToggleSidebar}
      />

      <OtherItems
        title="Other Items"
        items={itemList}
        section={section}
        onToggleSidebar={onToggleSidebar}
      />

      <CouponsSideBar section={section} category={category} />
    </LayoutSideBar>
  );
}

export default ListingSideBar;
