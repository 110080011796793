import React, { Component } from 'react';
// import ProfileSettingsNav from '../ProfileNav/ProfileSettingsNav';
import ProfileSettingsBox from '../ProfileSettingsBox';
import FormFieldError from '../../../../shared/uielements/ErrorMessages/FormFieldError';
import LoadingSvg from '../../../../shared/uielements/LoadingSpinner/LoadingSvg';
import ErrorMessages from '../../../../shared/uielements/ErrorMessages/ErrorMessages';
import { hostNameAndPort } from '../../../../shared/util/vars';
import Axios from 'axios';
import { connect } from 'react-redux';
import actionType from '../../../../redux-store/action-type';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import SubscriptionModal from '../../../../shared/components/Modals/Advertise/SubscriptionModal';
import { Link } from 'react-router-dom';
import SettingsMenu from "../SettingsMenu";
import { withRouter } from "react-router-dom";

class AccountSettings extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state = {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            email: '',
            oldEmail: '',
            showTermsAndConditions: false,
            termsAccepted: false,
            spinnerForEmailUpdate: false,
            errorMessageEmailUpdate: '',
            emailAddressSaved: false,
            slug: '',
            spinnerForSlug: false,
            errorMessageSlug: '',
            userSlugSaved: false,
            accountType: '',
            errorMessage: '',
            changePasswordSaved: false,
            newPasswordWaitingForAnswer: false,
            accountInfoUpdated: false,
            accountTypeUpdated: false,
            //profileInfoSaved: false
            openPaymentModal: false,
            paymentList: [],
            isDealer: '',
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.getUserDataFromServer();
            this.getPaymentFromServer();
        }, 500);
    }

    getPaymentFromServer = async () => {
        let url = `${hostNameAndPort}/api/pay/getpaymentforsubscriber`;
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                this.setState({ paymentList: data.paymentList });
            }
        } catch (err) {
            // Network error
        }
    }

    getUserDataFromServer = async () => {
        let url = `${hostNameAndPort}/api/user/whoami`;
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                data.user.oldEmail = data.user.email;
                this.setState(data.user);
            } else {
                this.props.history.push('/');
            }
        } catch (err) {
            // Network error
            this.props.history.push('/');
        }
    }

    onUpdatePasswordHandler = async () => {
        if (!this.infoIsVerified()) {
            return;
        }
        if (this.state.changePasswordSaved) {
            this.setState({ errorMessage: 'Your information is already saved' });
            return;
        }

        let _data = new FormData();

        if (this.state.dealer_id && !this.state.defaultPasswordChanged) {
            _data.append('password', this.state.dealer_id);
            _data.append('isDealer', true);
        } else {
            _data.append('password', this.state.currentPassword);
        }

        
        _data.append('newPassword', this.state.newPassword);
        this.setState({ newPasswordWaitingForAnswer: true });
        let options = {
            url: hostNameAndPort + '/api/user/update-pass',
            method: 'post',
            data: _data
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.setState({
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                    changePasswordSaved: true,
                    newPasswordWaitingForAnswer: false
                });
                //this.getUserDataFromServer();
            }
            else {
                this.setState({ errorMessage: 'Password could not be changed', newPasswordWaitingForAnswer: false });
            }
        } catch (err) {
            // Network error
            this.setState({ errorMessage: 'Network error ERRFEACCSET01', newPasswordWaitingForAnswer: false });
        }
    }

    infoIsVerified() {
        let _ = this.state;
        let errorMsg = '', _verified = true;
        let rp = /[*|'";:,?`~!@#$%^&=)(><}{\\]/g;
        //let rn = /[*|";:,?`~!@#$%^&=)(><}{\\0-9]/g;
        //let re = /[A-Za-z0-9.]*@[-a-z0-9.]+/g;

        // if ( !_.email.match(re) ) {
        //     errorMsg = Please choose a different email address';
        //     _verified = false;
        // }
        if (rp.test(_.newPassword)) {
            errorMsg = getTranslatedPhrase(this.props.userLanguage, 'Your password can only contain letters and numbers');
            _verified = false;
        }
        // if (!_.currentPassword || (_.currentPassword.length < 8)) {
        //     errorMsg = getTranslatedPhrase(this.props.userLanguage, 'Your old password cannot be this short');
        //     _verified = false;
        // }
        if (_.newPassword.length < 7) {
            errorMsg = getTranslatedPhrase(this.props.userLanguage, 'Your new password must be longer than 8 characters');
            _verified = false;
        }
        if (_.newPassword !== _.confirmPassword) {
            errorMsg = getTranslatedPhrase(this.props.userLanguage, 'Your passwords do not match');
            _verified = false;
        }
        this.setState({ errorMessage: errorMsg });
        return _verified;
    }

    // onUpdateAccountType = async () => {
    //     let _data = new FormData();
    //     _data.append('newAccountType', this.state.accountType);
    //     let options = {
    //         url: `${hostNameAndPort}/api/user/updateaccounttype`,
    //         method: 'post',
    //         data: _data
    //     }
    //     try { let result = await Axios(options);
    //         let {data} = result;
    //         if (!data.error) {
    //             this.props.onUpdateUserProfile();
    //             this.setState({ accountTypeUpdated: true });
    //         }            
    //     } catch(err) {
    //         // Network error            
    //     }
    // }

    onChangeEmailAddress = (e) => {
        if (e.target.value.length > 80) {
            this.setState({ errorMessageEmailUpdate: 'Email address is too long' });
            return;
        }
        this.setState({ email: e.target.value, errorMessageEmailUpdate: '', emailAddressSaved: false });
        if (!this.state.showTermsAndConditions) {// && /.*@followone.com$/.test(this.state.oldEmail) ) {
            this.setState({ showTermsAndConditions: true });
        }
    }

    toggleAgreeWithTerms = (e) => {
        this.setState({ termsAccepted: !this.state.termsAccepted });
    }

    onLogOut = () => {
        let url = `${hostNameAndPort}/logout`;
        Axios(url)
            .then(result => {
                let { data } = result;
                if (!data.error) {
                    this.props.history.push("/");
                    this.props.onEndAuthentication();
                }
            });
    }


    onUpdateEmailAddress = async () => {
        //if ( /.*@followone.com$/.test(this.state.oldEmail) && 
        if (!this.state.termsAccepted) {
            this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, 'You must agree with the terms of use.'))
            return;
        }
        let _data = new FormData();

        if (this.state.dealer_id && !this.state.defaultUserChanged) {
            _data.append('email', this.state.email);
            _data.append('isDealer', true);
        } else {
            _data.append('email', this.state.email);
        }
        
        let options = {
            url: `${hostNameAndPort}/api/user/update-email`,
            method: 'post',
            data: _data
        }
        this.setState({ spinnerForEmailUpdate: true });
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.props.onUpdateUserProfile();
                this.setState({ errorMessageEmailUpdate: '', spinnerForEmailUpdate: false, emailAddressSaved: true, oldEmail: this.state.email, termsAccepted: false });
            } else {
                this.setState({ errorMessageEmailUpdate: data.error, spinnerForEmailUpdate: false, emailAddressSaved: false, termsAccepted: false });
            }
        } catch (err) {
            this.setState({ errorMessageEmailUpdate: 'Network error ERRFEACCSET02', spinnerForEmailUpdate: false, emailAddressSaved: false, termsAccepted: false });
        }

        this.onLogOut();

    }

    onChangeSlug = (e) => {
        let trimSlug = e.target.value;
        if (trimSlug.includes('Followone.com/')) {
            trimSlug = trimSlug.replace('Followone.com/', '');
        } else {
            trimSlug = '';
        }
        if (trimSlug.length > 50) {
            this.setState({ errorMessageSlug: 'Profile address is too long' });
            return;
        }
        this.setState({ slug: trimSlug, errorMessageSlug: '' });
    }

    onUpdateUserSlug = async () => {
        let _data = new FormData();
        _data.append('slug', this.state.slug);
        let options = {
            url: `${hostNameAndPort}/api/user/update-slug`,
            method: 'post',
            data: _data
        }
        this.setState({ spinnerForSlug: true });
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.props.onUpdateUserProfile();
                this.setState({ errorMessageSlug: '', spinnerForSlug: false, userSlugSaved: true });
            } else {
                this.setState({ errorMessageSlug: data.error, spinnerForSlug: false, userSlugSaved: false });
            }
        } catch (err) {
            this.setState({ errorMessageSlug: 'Network error ERRFEACCSET03', spinnerForSlug: false, userSlugSaved: false });
        }

        this.onLogOut();
    }

    onChangeOldPassword = (e) => {
        if (e.target.value.length > 512) {
            this.setState({ errorMessage: 'Current password is too long' });
            return;
        }
        this.setState({ currentPassword: e.target.value, errorMessage: '', changePasswordSaved: false, accountInfoUpdated: false });
    }

    onChangeNewPassword = (e) => {
        if (e.target.value.length > 512) {
            this.setState({ errorMessage: 'New password is too long' });
            return;
        }
        this.setState({ newPassword: e.target.value, errorMessage: '', changePasswordSaved: false, accountInfoUpdated: false });
    }

    onChangeConfirmPassword = (e) => {
        if (e.target.value.length > 512) {
            this.setState({ errorMessage: 'Confirmed password is too long' });
            return;
        }
        this.setState({ confirmPassword: e.target.value, errorMessage: '', changePasswordSaved: false, accountInfoUpdated: false });
    }

    onChangeAccountType = (e) => {
        this.setState({ accountType: e.target.value, accountTypeUpdated: false, accountInfoUpdated: false });
    }

    onUpdatePrimaryCard = async (i) => {
        let a = this.state.paymentList;
        let j = 0;
        while (j < a.length) {
            if (a[j].isPrimaryCard) {
                break;
            }
            j++;
        }
        let o = {};
        if (j < a.length) {
            o = a[j];
        }
        let _data = new FormData();
        _data.append('oldPrimary', JSON.stringify(o));
        _data.append('newPrimary', JSON.stringify(a[i]));
        let options = {
            url: `${hostNameAndPort}/api/pay/updateprpmtforsubscriber`,
            method: 'post',
            data: _data
        }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                if (j < a.length) {
                    a[j].isPrimaryCard = false;
                }
                a[i].isPrimaryCard = true;
                this.setState({ paymentList: a, accountInfoUpdated: true });
            } else {
                this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEACCSET04'));
            }
        } catch (err) {
            this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEACCSET05'));
        }
    }

    onDeleteCard = async (i) => {
        let a = this.state.paymentList;
        let _data = new FormData();
        _data.append('payment', JSON.stringify(a[i]));
        let options = {
            url: `${hostNameAndPort}/api/pay/deletepaymentforsubscriber`,
            method: 'post',
            data: _data
        }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                a.splice(i, 1);
                this.setState({ paymentList: a });
            } else {
                this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEACCSET06'));
            }
        } catch (err) {
            this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEACCSET07'));
        }
    }

    onCloseModalHandler = () => {
        this.setState({ openPaymentModal: false });
    }

    onAddPayment = () => {
        this.setState({ openPaymentModal: true });
    }

    onSubmitPaymentToServer = async ({ stripeToken }) => {
        this.onCloseModalHandler();

        let _data = new FormData();
        _data.append('stripeToken', stripeToken.id);
        let options = {
            url: `${hostNameAndPort}/api/pay/addsubscriber`,
            method: 'post',
            data: _data
        }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                let a = this.state.paymentList;
                a.push(data.newSubscriber);
                this.setState({ paymentList: a });
            } else this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, data.error));
        } catch (err) {
            // Network error            
        }
    }

    

    render() {

        // console.log(this.state);

        return (
            <SettingsMenu AccountLiActive="active">
                <>
                    <div className="account-settings-options">

                        <ProfileSettingsBox>
                            <h4>{getTranslatedPhrase(this.props.userLanguage, 'Update Your Password')}</h4>

                            {(this.state.defaultPasswordChanged || !this.state.isDealer) && <input type="password" className="form-input-text form-input-full mgb-sm" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Current Password')} value={this.state.currentPassword} onChange={this.onChangeOldPassword} /> }

                            <div className="flx flx-2">
                                <input type="password" className="form-input-text form-input-full flx-item" placeholder={getTranslatedPhrase(this.props.userLanguage, 'New Password')} value={this.state.newPassword} onChange={this.onChangeNewPassword} />
                                <input type="password" className="form-input-text form-input-full flx-item" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Confirm New Password')} value={this.state.confirmPassword} onChange={this.onChangeConfirmPassword} />
                            </div>


                            <FormFieldError>{getTranslatedPhrase(this.props.userLanguage, this.state.errorMessage)}</FormFieldError>
                            <button className="button button-regular mgt-md" onClick={this.onUpdatePasswordHandler}>{getTranslatedPhrase(this.props.userLanguage, 'Update')}</button>
                            <LoadingSvg active={this.state.newPasswordWaitingForAnswer} />
                            <ErrorMessages
                                errorMessageClass={"error-message error-message-info"}
                                errorMessageTitle={getTranslatedPhrase(this.props.userLanguage, 'Success')}
                                errorMessageBody={getTranslatedPhrase(this.props.userLanguage, 'Your password has been updated')} show={this.state.changePasswordSaved} />
                        </ProfileSettingsBox>

                        <ProfileSettingsBox>
                            <h4>{getTranslatedPhrase(this.props.userLanguage, 'Account Email')}</h4>
                            <input type="email" className="form-input-text form-input-full" placeholder="your@email.com" value={this.state.email} onChange={this.onChangeEmailAddress} />

                            {/* Show terms if email has @followone.com */}
                            {!this.state.showTermsAndConditions ? null : (<div className="txtc fnts modal_body_functions_agree-terms form-input-lists">
                                <input type="checkbox" id="createAccountTerms-2" checked={this.state.termsAccepted} onChange={this.toggleAgreeWithTerms} />
                                <label htmlFor="createAccountTerms-2">
                                    <span><i className="fal fa-check" /></span> {getTranslatedPhrase(this.props.userLanguage, 'I have read and agree to the')} <Link target="_blank" to="/terms-of-service">{getTranslatedPhrase(this.props.userLanguage, 'terms of use')}</Link> and <Link target="_blank" to="/privacy">{getTranslatedPhrase(this.props.userLanguage, 'privacy policy')}</Link>.
                                    <br /> Upon updating your email address you will be logged out and you will have to login with your new email address.
                                    </label>
                            </div>)}
                            {/*<input type="text" placeholder="(UTC-8) Pacific Time (US & Canada)" />*/}
                            <FormFieldError>{getTranslatedPhrase(this.props.userLanguage, this.state.errorMessageEmailUpdate)}</FormFieldError>
                            <button className="button button-regular mgt-md" onClick={this.onUpdateEmailAddress}>{getTranslatedPhrase(this.props.userLanguage, 'Update')}</button>
                            <LoadingSvg active={this.state.spinnerForEmailUpdate} />
                            <ErrorMessages
                                errorMessageClass={"error-message error-message-info"}
                                errorMessageTitle={getTranslatedPhrase(this.props.userLanguage, 'Success')}
                                errorMessageBody={getTranslatedPhrase(this.props.userLanguage, 'Your email address has been updated')} show={this.state.emailAddressSaved} />
                        </ProfileSettingsBox>

                        <ProfileSettingsBox>
                            <h4>{getTranslatedPhrase(this.props.userLanguage, 'Your Profile URL')}</h4>
                            <input type="text" onChange={this.onChangeSlug}
                                value={'Followone.com/' + this.state.slug}
                                className="form-input-text form-input-full"
                                placeholder="Followone.com/youraccounturl" />
                            <FormFieldError>{getTranslatedPhrase(this.props.userLanguage, this.state.errorMessageSlug)}</FormFieldError>
                            <p className="info">Upon updating your profile url you will be logged out and you will have to sign in again.</p>
                            <button className="button button-regular mgt-md" onClick={this.onUpdateUserSlug}>{getTranslatedPhrase(this.props.userLanguage, 'Update')}</button>
                            <LoadingSvg active={this.state.spinnerForSlug} />
                            <ErrorMessages
                                errorMessageClass={"error-message error-message-info"}
                                errorMessageTitle={getTranslatedPhrase(this.props.userLanguage, 'Success')}
                                errorMessageBody={getTranslatedPhrase(this.props.userLanguage, 'Your profile address has been updated')} show={this.state.userSlugSaved} />
                        </ProfileSettingsBox>


                        {/* Account type removed */}



                        <ProfileSettingsBox>
                            <h4>{getTranslatedPhrase(this.props.userLanguage, 'Payment')} </h4>
                            {/* <p className="payment-cta">{getTranslatedPhrase(this.props.userLanguage,'Add and select main card.')} </p> test*/}

                            <table className="payment-table"><tbody>
                                {this.state.paymentList.map((v, j) => (<tr key={j}>
                                    <td>
                                        {!v.isPrimaryCard ? (
                                            <button onClick={() => this.onUpdatePrimaryCard(j)}><i className="fal fa-circle" /></button>) : <i title="Primary Card" className="fal fa-check-circle" />}
                                    </td>

                                    <td>{v.brand} Card {getTranslatedPhrase(this.props.userLanguage, 'ending in')} {v.last4}</td>

                                    <td>
                                        {!v.isPrimaryCard ? (
                                            <button className="make-primary" onClick={() => this.onUpdatePrimaryCard(j)} />) : <span className="primary-card">{getTranslatedPhrase(this.props.userLanguage, 'Main Card')}</span>}
                                    </td>

                                    <td>
                                        <button onClick={() => this.onDeleteCard(j)}><i className="fal fa-trash-alt" /></button>
                                    </td>
                                </tr>))}
                            </tbody></table>

                            <button className="button button-regular mgt-md" onClick={this.onAddPayment}>{getTranslatedPhrase(this.props.userLanguage, 'Add New Card')}</button>

                            <ErrorMessages
                                errorMessageClass={"error-message error-message-info"}
                                errorMessageTitle={getTranslatedPhrase(this.props.userLanguage, 'Success')}
                                errorMessageBody={getTranslatedPhrase(this.props.userLanguage, 'Your account information has been updated')} show={this.state.accountInfoUpdated} />
                        </ProfileSettingsBox>




                        








                        {/*<div className="SettingSection">
                                <h4>Link Your Facebook and Twitter Accounts</h4>
                                <p>Linking your Facebook and Twitter account will allow you to publish content to your Facebook and Twitter walls.</p>

                                <div>
                                    <button className="button button-regular loginFbButton"><i className="fab fa-facebook-f"></i> <strong>Login</strong> with <strong>Facebook</strong></button>
                                    <button className="button button-regular loginTwButton"><i className="fab fa-twitter"></i> <strong>Login</strong> with <strong>Twitter</strong></button>
                                </div>
                            </div>*/}

                    </div>

                    <SubscriptionModal
                        active={this.state.openPaymentModal}
                        promoCharge={0}
                        addNewCardOnly={true}
                        submitButtonLabel="Save Card"
                        onCloseModal={this.onCloseModalHandler}
                        onSubmitUserInfo={this.onSubmitPaymentToServer}
                        onMoveForward={() => null}
                    />
                </>
            </SettingsMenu>
        );
    }
}


const mapStateToAccountSettingsProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        userIsAuthenticated: state.userIsAuthenticated
    };
};

const mapDispatchToBusinessAccountSettingsProps = (
    dispatch
) => {
    return {
        showAlertModal: (u) => {
            dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: u });
        },
        onUpdateUserProfile: () => {
            dispatch({ type: actionType.USER_LOGIN, userIsAuthenticated: false });
            dispatch({ type: actionType.USER_LOGIN, userIsAuthenticated: true });
        },
        onEndAuthentication: () => dispatch({ type: actionType.USER_LOGIN, userIsAuthenticated: false, loggedInUser: null })
    };
};

export default connect(mapStateToAccountSettingsProps, mapDispatchToBusinessAccountSettingsProps)(withRouter(AccountSettings));

/**
                        <ProfileSettingsBox>
                            <h4>{getTranslatedPhrase(this.props.userLanguage,'Change Account Type')} </h4>

                            <div className="form-input-select">
                                <select value={this.state.accountType} onChange={this.onChangeAccountType}>
                                    <option value="personal">{getTranslatedPhrase(this.props.userLanguage,'Personal')}</option>
                                    <option value="business">{getTranslatedPhrase(this.props.userLanguage,'Business')}</option>
                                    <option value="organization">{getTranslatedPhrase(this.props.userLanguage,'Organization')}</option>
                                    <option value="institution">{getTranslatedPhrase(this.props.userLanguage,'Institution')}</option>
                                    <option value="public-figure">{getTranslatedPhrase(this.props.userLanguage,'Public Figure')}</option>
                                </select>
                            </div>

                            <button className="button button-regular mgt-md" onClick={this.onUpdateAccountType}>{getTranslatedPhrase(this.props.userLanguage,'Change')}</button>

                            <ErrorMessages
                                errorMessageClass={"error-message error-message-info"}
                                errorMessageTitle={getTranslatedPhrase(this.props.userLanguage,'Success')}
                                errorMessageBody={getTranslatedPhrase(this.props.userLanguage,'Your account information has been updated')} show={this.state.accountTypeUpdated}/>
                        </ProfileSettingsBox>
 */