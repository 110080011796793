import React, {useState} from 'react';
import SettingsMenu from "../ProfileSettings/SettingsMenu";
import ProfileSettingsBox from "../ProfileSettings/ProfileSettingsBox";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import { connect } from "react-redux";
import SellerRevenue from "./Components/SellerRevenue";
import SellerFindBusinesses from "./Components/SellerFindBusinesses";
import SellerMyBusinesses from "./Components/SellerMyBusinesses";
import Modal from "../../../shared/uielements/Modal/Modal";

const SellerDashboard = props => {

    const [modal, setModal] = useState(false);

    const toggleModal = () => {
        setModal(!modal);
    }

    return (
      <>
          <SettingsMenu SellerDashboardLiActive="active">
              <ProfileSettingsBox>
                  <h4>{getTranslatedPhrase(props.userLanguage, "Revenue")}</h4>
                  <SellerRevenue />
              </ProfileSettingsBox>

              <ProfileSettingsBox>
                  <h4>{getTranslatedPhrase(props.userLanguage, "Find Businesses")}</h4>
                  <SellerFindBusinesses toggleModal={toggleModal} />
              </ProfileSettingsBox>

              <ProfileSettingsBox>
                  <h4>{getTranslatedPhrase(props.userLanguage, "My Businesses")}</h4>
                  <SellerMyBusinesses toggleModal={toggleModal} />
              </ProfileSettingsBox>
          </SettingsMenu>

          { modal &&
            <Modal
              title="Company Details"
              closeModal={toggleModal}
            >
                <div className="seller-co-info">
                    <h4>Followone Inc.</h4>

                    <label>Contact</label>
                    <p><span>Phone:</span> 4255186840</p>
                    <p><span>Email:</span> felix@followone.com</p>
                    <p><span>Seller:</span> Bill Johnson</p>

                    <label>Select Status:</label>
                    <div className="form-input-select" >
                        <select className="form-input-full">
                            <option value="section">Select Status</option>
                            <option value="option1">New</option>
                            <option value="option1">Pending</option>
                            <option value="option1">Sold</option>
                            <option value="option1">No Deal</option>
                            <option value="option1">Don't Contact</option>
                        </select>
                    </div>
                    <label>Notes:</label>
                    <textarea className="form-input-textarea form-input-full" placeholder="Your text goes here..." />
                    <p className="notes-info">Updated 11/16/2020</p>

                    <button className="button button-regular">UPDATE ACCOUNT</button>
                </div>
            </Modal>
          }
      </>
    )
}

const mapStateToPurchaseHistoryProps = (
  state,
  props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToPurchaseHistoryProps, null)(SellerDashboard);
