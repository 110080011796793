import React from 'react';
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import moment from "moment";
import { connect } from "react-redux";
import { formatPriceAndCurrency } from '../../../../shared/util/number';

const RealEstateCoupons = props => {

    function getCouponCode(v, cc, pc) {
        if (cc !== '%') {
            return formatPriceAndCurrency(v, pc, ',');
        }
        return v + cc;
    }

    if (!props.callForPricing && !!props.addDiscount && props.addDiscount === 'coupon') {
        return (
          <>
              <div className="listing-coupon">
                  <div className="coupon-code-text-box">
                    <span>
                        {getTranslatedPhrase(props.userLanguage, 'SAVE')}
                        {getCouponCode(props.discountId.couponValue, props.discountId.couponCurrency, props.currency)}
                    </span>
                      <br />
                      {props.addDiscount === 'coupon' && getTranslatedPhrase(props.userLanguage, 'Coupon Code')+' :'}
                  </div>

                  <div className="coupon-code-box">
                      <p className="listing-coupon-code">
                      <span className="listing-main-code">
                      { props.addDiscount === 'coupon' && (<>{ props.discountId.title }<br /></>)}
                          <span className="listing-coupon-terms">{moment(props.discountId.endDate).isAfter(moment("2190-01-01"))? getTranslatedPhrase(props.userLanguage, 'No expiration date') : getTranslatedPhrase(props.userLanguage, 'Ends')+ ": " + moment(props.discountId.endDate).format('L')}</span>
                      </span>
                      </p>
                  </div>
              </div>
              <div className="listing-coupon-divider" />
          </>
        )
    } else {
        return null;
    }
}


const mapStateToProps = (
  state,
  props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToProps)(RealEstateCoupons);