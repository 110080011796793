import React from 'react';
import SideBar from '../../../shared/templates/CategoryTemplate/category-sidebar';

function GroupsSideBar(props) {
  return (<SideBar {...props} 
    section="groups"
    searchTitle="Search Groups"
    createNewTitle="New Group"
    filterList={[
      { name:'keywords', value: 'Enter Keywords' }, 
    ]}
  />);
}

export default GroupsSideBar;