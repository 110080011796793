import React, { Component } from 'react';
import FormFieldError from '../../../shared/uielements/ErrorMessages/FormFieldError';
import ErrorMessages from '../../../shared/uielements/ErrorMessages/ErrorMessages';
import { Link } from 'react-router-dom';
import { hostNameAndPort } from '../../../shared/util/vars';
import { maxUploadFileSize } from '../../../shared/util/file';
import { titleCase } from '../../../shared/util/string';
import moment from 'moment';
import Box from '../../../shared/util/box';
import CropImageModal from '../../../shared/util/crop-image-modal';
import Axios from 'axios';
import LoadingSvg from '../../../shared/uielements/LoadingSpinner/LoadingSvg';
import { connect } from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';


// class GroupMember extends Component {
//     state = {
//         deleted: false
//     }

//     onDeleteMember = (e) => {
//         let _data = new FormData();
//         _data.append('groupId', this.props.groupId);
//         _data.append('userId', this.props.userId);
//         let options = {
//             url: `${hostNameAndPort}/api/groups/leave`,
//             method: 'post',
//             data: _data
//         }
//         Axios(options)
//         .then(result => {
//             let {data} = result;
//             if (!data.error) {
//                 this.setState({ deleted: true });
//             } 
//         })
//         .catch(err => {
//             alert(getTranslatedPhrase(this.props.userLanguage, 'Network error'));
//         });

//     }

//     render() {
//         if (this.state.deleted) {
//             return null;
//         }
//         return (
//             <div className="group_member_profile-image">
//                 <img src={this.props.profileImage} alt={this.props.userName} />
//                 <span>{this.props.userName}</span>
//                 <div className="delete-member-button" onClick={this.onDeleteMember}><i className="fal fa-trash-alt"></i></div>
//             </div>
//         );
//     }
// }

class GroupsEdit extends Component {
    constructor(props) {
        super(props)
        this.initialState = this.state = {
            title: '',
            itemId: '',
            profileImage: '',
            coverImage: '',
            profileImageFile: null,
            coverImageFile: null,
            category: '',
            subcategory: '',
            subcategoriesSelect: [],
            description: '',
            privacy: 'public',
            hashTags: '',
            slug: '',
            memberInfo: [],
            temp: {
                mainTitle: 'Create a Group',
                mainButtonLabel: 'Publish Group',
                errorMessage: '',
                groupSaved: false,
                openModal: false,
                postListingPressed: false,
                gpImageDivRef: null,
                gcImageDivRef: null,
                expectedHeight: 0,
                expectedWidth: 0,
            },
            categoriesSelect: [],
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getGroupCategories();
        let l = this.props.location;
        if (l.pathname.indexOf('/edit-group') >= 0 && l.search) {
            let b = l.search.slice(3);
            this.setState({ slug: b });
            this.props.history.replace(this.props.match.url);
            this.getGroupData(b);
        }
    }

    getGroupCategories = async () => {
        try {
            let url = `${hostNameAndPort}/api/groups/categories`;
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                this.setState({ categoriesSelect: data.categories });
            }
        } catch (err) {
            // server, network error
        }
    }

    getGroupData = async (b) => {
        try {
            let url = `${hostNameAndPort}/api/groups/${b}`;
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                data.item.creationDate = moment(data.item.creationDate).format('YYYY-MM-DD');
                data.item.temp = { ...this.state.temp, mainTitle: 'Edit Group', mainButtonLabel: 'Update Group' };

                let image = new Image();
                image.src = data.item.profileImage;
                image.alt = data.item.title;
                this.state.temp.gpImageDivRef.appendChild(image);

                image = new Image();
                image.src = data.item.coverImage;
                image.alt = data.item.title;
                this.state.temp.gcImageDivRef.appendChild(image);

                // Subcategories
                let a = this.state.categoriesSelect;
                let b = [];
                let i = 0;
                for (; i < a.length; i++) {
                    if (a[i] === data.item.category) break;
                }
                i++;
                while (/^\s*-+/.test(a[i]) && i < a.length) {
                    b.push(a[i].replace(/^\s*-+\s*/, ''));
                    i++;
                }
                data.item.subcategoriesSelect = [...b];
                this.setState(data.item);
            }
        } catch (err) {
            // server, network error
        }
    }

    getCroppedPhoto = () => null

    onClickToUploadNewCoverImageHandler = () => {
        this.getCroppedPhoto = this.getCroppedCoverImage;
        this.setState({
            temp:
            {
                ...this.state.temp,
                openModal: true,
                expectedWidth: 1400,
                expectedHeight: 280,
            }
        });
    }

    getCroppedCoverImage = (f) => {
        this.setState({ temp: { ...this.state.temp, openModal: false } });
        if (!f) {
            return
        }
        let _fileName = 'group-cover.jpg';
        let _file = new File([f], _fileName, { type: 'image/jpeg' });
        if (_file.size > maxUploadFileSize) {
            let d = this.state.temp.gcImageDivRef;
            if (d.hasChildNodes()) {
                for (let i = 0; i < d.childNodes.length; i++) {
                    d.removeChild(d.childNodes[i]);
                }
            }
            this.setState({ coverImage: '', temp: { ...this.state.temp, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'The featured image must be smaller than') + ' 5 MB' } });
            return;
        }

        let _reactComp = this;

        let image = new Image();
        let url = URL.createObjectURL(f);
        image.src = url;
        image.alt = _fileName;

        image.onload = function () {
            URL.revokeObjectURL(url);

            if (this.width < 1400 || this.height < 280) {
                _reactComp.setState({ coverImage: '', temp: { ..._reactComp.state.temp, errorMessage: getTranslatedPhrase(_reactComp.props.userLanguage, 'Image size must be at least') + ' 1400 x 280 px' } });
            }
            else {
                _reactComp.setState({ coverImage: _fileName, coverImageFile: _file, temp: { ..._reactComp.state.temp, groupSaved: false, errorMessage: '' } });
                let d = _reactComp.state.temp.gcImageDivRef;
                if (d.hasChildNodes()) {
                    for (let i = 0; i < d.childNodes.length; i++) {
                        d.removeChild(d.childNodes[i]);
                    }
                }
                d.appendChild(image);
            }
        };
    }

    onClickToUploadNewProfileImageHandler = () => {
        this.getCroppedPhoto = this.getCroppedProfileImage;
        this.setState({
            temp:
            {
                ...this.state.temp,
                openModal: true,
                expectedWidth: 400,
                expectedHeight: 400,
            }
        });
    }

    getCroppedProfileImage = (f) => {
        this.setState({ temp: { ...this.state.temp, openModal: false } });
        if (!f) {
            return
        }
        let _fileName = 'group-profile.jpg';
        let _file = new File([f], _fileName, { type: 'image/jpeg' });
        if (_file.size > maxUploadFileSize) {
            let d = this.state.temp.gpImageDivRef;
            if (d.hasChildNodes()) {
                for (let i = 0; i < d.childNodes.length; i++) {
                    d.removeChild(d.childNodes[i]);
                }
            }
            this.setState({ profileImage: '', temp: { ...this.state.temp, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'The featured image must be smaller than') + ' 5 MB' } });
            return;
        }

        let _reactComp = this;

        let image = new Image();
        let url = URL.createObjectURL(f);
        image.src = url;
        image.alt = _fileName;

        image.onload = function () {
            URL.revokeObjectURL(url);

            if (this.height < 400 || this.width < 400) {
                _reactComp.setState({ profileImage: '', temp: { ..._reactComp.state.temp, errorMessage: getTranslatedPhrase(_reactComp.props.userLanguage, 'Image size must be at least') + ' 400 x 400 px' } });
            }
            else {
                _reactComp.setState({ profileImage: _fileName, profileImageFile: _file, temp: { ..._reactComp.state.temp, groupSaved: false, errorMessage: '' } });
                let d = _reactComp.state.temp.gpImageDivRef;
                if (d.hasChildNodes()) {
                    for (let i = 0; i < d.childNodes.length; i++) {
                        d.removeChild(d.childNodes[i]);
                    }
                }
                d.appendChild(image);
            }
        };
    }

    onChangeGroupPropertyHandler = (e, p, l, d) => {
        if (e.target.value.length > l) {
            return this.setState({ temp: { ...this.state.temp, errorMessage: `The ${d} must be smaller than ${l} characters` } });
        }
        if (p === 'category' && e.target.value !== 'select-category') {
            let a = this.state.categoriesSelect;
            let b = [];
            let i = 0;
            for (; i < a.length; i++) {
                if (a[i] === e.target.value) break;
            }
            i++;
            while (/^\s*-+/.test(a[i]) && i < a.length) {
                b.push(a[i].replace(/^\s*-+\s*/, ''));
                i++;
            }
            this.setState({ category: e.target.value, subcategory: '', subcategoriesSelect: [...b] });
        }
        this.setState({ [p]: e.target.value, temp: { ...this.state.temp, groupSaved: false, errorMessage: '' } });
    }

    requiredFieldsAreFilled() {
        let res = true, _ = this.state;
        let _err = '';

        if (!_.title) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Group Name') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'is required.');
            res = false;
        }
        if (!_.description) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Description') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'is required.');
            res = false;
        }
        if (!_.category || _.category === 'select-category') {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Category') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'is required.');
            res = false;
        }
        if (!_.profileImage) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Profile Image') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'is required.');
            res = false;
        }
        if (!_.coverImage) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Cover Image') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'is required.');
            res = false;
        }
        if (_.temp.groupSaved && res) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Group is already published');
            res = false;
        }
        this.setState({ temp: { ...this.state.temp, errorMessage: _err } });
        return res;
    }

    onClearDraftHandler = (e) => {
        window.scrollTo(0, 0);
        let d = this.state.temp.gcImageDivRef;
        if (d.hasChildNodes()) {
            for (let i = 0; i < d.childNodes.length; i++) {
                d.removeChild(d.childNodes[i]);
            }
        }
        d = this.state.temp.gpImageDivRef;
        if (d.hasChildNodes()) {
            for (let i = 0; i < d.childNodes.length; i++) {
                d.removeChild(d.childNodes[i]);
            }
        }

        let c = this.state.categoriesSelect;
        this.setState(this.initialState);
        this.setState({ categoriesSelect: c });
    }

    onPublishHandler = () => {
        if (!this.requiredFieldsAreFilled()) {
            return;
        }
        this.setState({ temp: { ...this.state.temp, postListingPressed: true } });

        let nbd = Object.assign({}, this.state);
        nbd.title = titleCase(nbd.title);
        delete nbd.categoriesSelect;
        delete nbd.temp;

        let newGroupData = new FormData();
        for (let i in nbd) {
            newGroupData.append(i, nbd[i]);
        }
        let options = {
            url: `${hostNameAndPort}/api/groups/update`,
            method: 'post',
            data: newGroupData
        }
        Axios(options)
            .then(result => {
                let { data } = result;
                if (!data.error) {
                    this.setState({
                        itemId: data.itemId, slug: data.slug,
                        temp: {
                            ...this.state.temp,
                            groupSaved: true,
                            errorMessage: '',
                            mainTitle: 'Edit Group',
                            mainButtonLabel: 'Update Group',
                            postListingPressed: false
                        }
                    });
                }
                else {
                    this.setState({ temp: { ...this.state.temp, errorMessage: data.error, groupSaved: false, postListingPressed: false } });
                }
            })
            .catch(err => {
                this.setState({ temp: { ...this.state.temp, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEGRPED01'), groupSaved: false, postListingPressed: false } });
            })
    }

    render() {
        let categoriesSelect = this.state.categoriesSelect.filter(v => (!/^\s*-+/.test(v))).map(v => ({ value: v, text: getTranslatedPhrase(this.props.userLanguage,titleCase(v.replace(/-/g,' '))) })).sort((a,b) => ((a.text>b.text)?1:-1));
        let styleClass = this.state.temp.mainTitle.toLowerCase().replace(/ /g, '');
        let backgroundClass = `layout-background-new layout-background-new-${styleClass}`;

        return (<Box>

            <div className={backgroundClass} />

            <div className="main-100 add-new-page add-product">
                <div className="main-100-header">
                    <h1>{getTranslatedPhrase(this.props.userLanguage, this.state.temp.mainTitle)}</h1>
                    <p>{getTranslatedPhrase(this.props.userLanguage, 'Share your passion and excitement.')}</p>
                </div>


                <div className="add-listing add-listing_full-width">

                    <div className="row">
                        <div className="col span-3-of-5">
                            <input type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Group Name') + ' *'}
                                value={this.state.title}
                                onChange={(e) => this.onChangeGroupPropertyHandler(e, 'title', 50, 'Group Name')} />
                        </div>

                        <div className="col span-1-of-5">
                            <div className="form-input-select">
                                <select value={this.state.category} onChange={(e) => this.onChangeGroupPropertyHandler(e, 'category', 50, 'Category')}>
                                    <option value="select-category">{getTranslatedPhrase(this.props.userLanguage, 'Select Category')} *</option>
                                    {categoriesSelect.map((v, i) => (<option value={v.value} key={i}>{v.text}</option>))}
                                </select>
                            </div>
                        </div>

                        {!this.state.subcategoriesSelect || !this.state.subcategoriesSelect.length ? null : (
                            <div className="col span-1-of-5">
                                <div className="form-input-select">
                                    <select value={this.state.subcategory} onChange={(e) => this.onChangeGroupPropertyHandler(e, 'subcategory', 50, 'Subcategory')}>
                                        <option value="select-subcategory">{getTranslatedPhrase(this.props.userLanguage, 'Select Subcategory')} *</option>
                                        {this.state.subcategoriesSelect.map((v, i) => (<option value={v} key={i}>{getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/g, ' ')))}</option>))}
                                    </select>
                                </div>
                            </div>
                        )}

                        {/* <div className="col span-1-of-3">
                            <div className="form-input-select">
                                <select value={this.state.privacy} onChange={(e) => this.onChangeGroupPropertyHandler(e, 'privacy', 50, 'Privacy')}>
                                    <option value="public">{getTranslatedPhrase(this.props.userLanguage,'Public')}</option>
                                    <option value="private">{getTranslatedPhrase(this.props.userLanguage,'Private')}</option>
                                </select>
                            </div>
                        </div> */}
                    </div>

                    <div className="row row-border-divider" />

                    <div className="row">
                        <div className="col span-2-of-2">
                            <label>{getTranslatedPhrase(this.props.userLanguage, 'Description')} *</label>
                            <textarea placeholder={getTranslatedPhrase(this.props.userLanguage, 'Write your group description.')}
                                value={this.state.description}
                                onChange={(e) => this.onChangeGroupPropertyHandler(e, 'description', 10000, 'Description')} >
                            </textarea>
                        </div>
                    </div>


                    <div className="row row-border-divider" />


                    <div className="row">
                        <div className="col span-2-of-2">
                            <label>{getTranslatedPhrase(this.props.userLanguage, 'Add Group Cover Image')} *</label>
                            <div className="show-featured-image" ref={(n) => { if (!this.state.temp.gcImageDivRef) this.setState({ temp: { ...this.state.temp, gcImageDivRef: n } }); }} />
                            <button className="button button-clear setFeaturedImageBtn"
                                onClick={this.onClickToUploadNewCoverImageHandler}>{getTranslatedPhrase(this.props.userLanguage, 'Upload New Photo')}</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col span-2-of-2">
                            <label>{getTranslatedPhrase(this.props.userLanguage, 'Add Group Profile Image')} *</label>
                            <div className="show-featured-image" ref={(n) => { if (!this.state.temp.gpImageDivRef) this.setState({ temp: { ...this.state.temp, gpImageDivRef: n } }); }} />
                            <button className="button button-clear setFeaturedImageBtn"
                                onClick={this.onClickToUploadNewProfileImageHandler}>{getTranslatedPhrase(this.props.userLanguage, 'Upload New Photo')}</button>
                        </div>
                    </div>

                    <div className="row row-border-divider" />


                    <div className="row">
                        <div className="col span-2-of-2">
                            <label>{getTranslatedPhrase(this.props.userLanguage, 'Keywords')}</label>
                            <textarea className="textarea_keywords" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Keywords') + ', ' + getTranslatedPhrase(this.props.userLanguage, 'Example') + ': ' + getTranslatedPhrase(this.props.userLanguage, 'health, food, exercise, fruits, strawberries, smoothie') + '...'}
                                value={this.state.hashTags}
                                onChange={(e) => this.onChangeGroupPropertyHandler(e, 'hashTags', 1000, 'Keywords')} >
                            </textarea>
                        </div>
                    </div>

                    {/* <div className="row row-border-divider"></div>

                    <div className="row">
                        <div className="group-members-area">
                            <label>{getTranslatedPhrase(this.props.userLanguage,'Manage Group Members')}</label>
                            <div className="group-members-list">
                                {this.state.memberInfo.map( (v,i) => (
                                    <GroupMember key={'member'+i} 
                                        userLanguage={this.props.userLanguage}
                                        itemId={this.state.itemId} 
                                        {...v} 
                                    />)
                                )}
                            </div>
                        </div>
                    </div> */}
                </div>



                <div className="submit-listing">
                    <FormFieldError>{this.state.temp.errorMessage}</FormFieldError>
                    <LoadingSvg active={this.state.temp.postListingPressed} />
                    <div className="submit-listing-button">
                        <button className="button button-white" onClick={this.onClearDraftHandler}><i className="fal fa-plus" /> {getTranslatedPhrase(this.props.userLanguage, 'Add New')}</button>
                        <button className="button button-regular" onClick={this.onPublishHandler} >{getTranslatedPhrase(this.props.userLanguage, this.state.temp.mainButtonLabel)}</button>
                    </div>

                    <ErrorMessages
                        errorMessageClass={"error-message error-message-info"}
                        errorMessageTitle={getTranslatedPhrase(this.props.userLanguage,"Success")}
                        errorMessageBody={getTranslatedPhrase(this.props.userLanguage,"Feel free to add more photos")} show={this.state.temp.groupSaved}>
                        <Link className="button button-regular button-small dsp-in-blk mgt-sm"
                            to={'/groups/' + this.state.category + '/' + (this.state.subcategor || 'other') + "/" + this.state.slug}>
                            {getTranslatedPhrase(this.props.userLanguage, 'See Your Listing')}
                        </Link>
                    </ErrorMessages>
                </div>
            </div>

            <CropImageModal
                active={this.state.temp.openModal}
                onCropPhotoCompleted={this.getCroppedPhoto}
                authorProfileImage={this.state.authorProfileImage}
                authorName={this.state.authorName}
                desiredWidth={this.state.temp.expectedWidth}
                desiredHeight={this.state.temp.expectedHeight} />
        </Box>);
    }
}


const mapStateToGroupsEditProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToGroupsEditProps, null)(GroupsEdit);
