import React from 'react';
import './StyleGuide.css';
import { Link } from 'react-router-dom';


const StyleGuide = (props) => {
    return (

        <div className="main-100 style-guide">

            <div className="style-guide_header">
                <h1>STYLE GUIDE</h1>
                <p className="mainCta">Do Something Awesome Today <br /> <span style={{ fontSize: "1rem", color: "#b5b5b5", fontStyle: "italic" }}>- v 3.0.40 | Updated 2/3/2021 -</span></p>
            </div>

            <div className="style-guide_box style-guide_box_type">
                <h2 style={{ marginBottom: "1rem" }}><strong>typo</strong>graphy</h2>

                <div className="row">
                    <div className="col span-1-of-4">
                        <h4 style={{ marginBottom: "20px" }}>Headers</h4>

                        <h1>Header Title</h1>
                        <span className="code-info">h1 / Open Sans / Weight 300 / Color #444 / Size 4.4rem</span>

                        <h2>Header Title</h2>
                        <span className="code-info">h2 / Open Sans / Weight 300 / Color #444 / Size 4.4rem</span>

                        <h3>Header Title</h3>
                        <span className="code-info">h3 / Open Sans / Weight 300 / Color #444 / Size 4.4rem</span>

                        <h4>Header Title</h4>
                        <span className="code-info">h4 / Open Sans / Weight 300 / Color #444 / Size 4.4rem</span>
                    </div>

                    <div className="col span-1-of-4">
                        <h4 style={{ marginBottom: "20px" }}>Paragraph</h4>

                        <p style={{ paddingRight: "20px" }}>Duis feugiat semper lectus, nec eleifend ante fringilla vel. Cras sed est ut ex scelerisque porta. Quisque eros magna, eleifend ut venenatis ut, eleifend sit amet felis. Nam venenatis faucibus mauris, pharetra rhoncus erat auctor sed. Integer eleifend mi eu commodo fringilla. Nunc nec molestie nulla, id convallis libero. Duis nec libero nec ligula euismod suscipit. Vestibulum iaculis nisl et tellus rutrum varius.</p>

                        <span className="code-info">p / Open Sans / Color #444 / Size 1.3rem</span>
                    </div>

                    <div className="col span-1-of-4">
                        <h4 style={{ marginBottom: "20px" }}>Paragraph Small</h4>

                        <p className="fnts" style={{ paddingRight: "20px" }}>Duis feugiat semper lectus, nec eleifend ante fringilla vel. Cras sed est ut ex scelerisque porta. Quisque eros magna, eleifend ut venenatis ut, eleifend sit amet felis. Nam venenatis faucibus mauris, pharetra rhoncus erat auctor sed. Integer eleifend mi eu commodo fringilla. Nunc nec molestie nulla, id convallis libero. Duis nec libero nec ligula euismod suscipit. Vestibulum iaculis nisl et tellus rutrum varius.</p>

                        <span className="code-info">.fnts / Open Sans / Color #444 / Size 1.1rem</span>
                    </div>

                    <div className="col span-1-of-4">
                        <h4 style={{ marginBottom: "20px" }}>Accent Font</h4>

                        <h1 className="accent">Header Title</h1>
                        <span className="code-info">h1 / Play Fair</span>

                        <h2 className="accent">Header Title</h2>
                        <span className="code-info">h2 / Play Fair</span>
                    </div>
                </div>
            </div>


            <div className="style-guide_box style-guide_box_colors">
                <h2><strong>color</strong>palette</h2>

                <div className="row">
                    <div className="col span-1-of-6" style={{ backgroundColor: "#65A2E7" }}>
                        <span className="code-info">#65A2E7 / $color-primary-light</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#496AD4" }}>
                        <span className="code-info">#496AD4 / $color-primary</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#425CAF" }}>
                        <span className="code-info">#425CAF / $color-primary-dark</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#80DC7B" }}>
                        <span className="code-info">#80DC7B / $color-secondary-light</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#6EC568" }}>
                        <span className="code-info">#6EC568 / $color-secondary</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#60AE5B" }}>
                        <span className="code-info">#60AE5B / $color-secondary-dark</span>
                    </div>
                </div>


                <div className="row">
                    <div className="col span-1-of-6" style={{ backgroundColor: "#FFFFFF" }}>
                        <span className="code-info" style={{ color: "#999" }}>#FFFFFF / $color-white</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#F8F8F8" }}>
                        <span className="code-info" style={{ color: "#999" }}>#F8F8F8 / $color-gray-light</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#EDEDED" }}>
                        <span className="code-info" style={{ color: "#999" }}>#EDEDED / $color-border</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#B5B5B5" }}>
                        <span className="code-info">#B5B5B5 / $color-gray-medium</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#444444" }}>
                        <span className="code-info">#444444 / $color-gray-dark</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#000000" }}>
                        <span className="code-info">#000000 / $color-black</span>
                    </div>
                </div>


                <div className="row">
                    <div className="col span-1-of-6" style={{ backgroundColor: "#E67E22" }}>
                        <span className="code-info">#E67E22 / $color-orange</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#EE6565" }}>
                        <span className="code-info">#EE6565 / $color-red</span>
                    </div>

                    <div className="col span-1-of-6" style={{ backgroundColor: "#E04C4C" }}>
                        <span className="code-info">#E04C4C / $color-red-dark</span>
                    </div>
                </div>
            </div>


            <div className="style-guide_box style-guide_box_icons">
                <h2><strong>web</strong>icons</h2>

                <div className="row">
                    <div className="col span-1-of-10">
                        <i className="fal fa-search" />
                        <span className="code-info">.fal .fa-search</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-blog" />
                        <span className="code-info">.fal .fa-blog</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-camera-retro" />
                        <span className="code-info">.fal .fa-camera-retro</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-video" />
                        <span className="code-info">.fal .fa-video</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-paper-plane" />
                        <span className="code-info">.fal .fa-paper-plane</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-times" />
                        <span className="code-info">.fal .fa-times</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-heart" />
                        <span className="code-info">.fal .fa-heart</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-reply" />
                        <span className="code-info">.fal .fa-reply</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-bell" />
                        <span className="code-info">.fal .fa-bell</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-inbox" />
                        <span className="code-info">.fal .fa-inbox</span>
                    </div>
                </div>


                <div className="row">
                    <div className="col span-1-of-10">
                        <i className="fal fa-envelope" />
                        <span className="code-info">.fal .fa-envelope</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-user-plus" />
                        <span className="code-info">.fal .fa-user-plus</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-cog" />
                        <span className="code-info">.fal .fa-cog</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-cogs" />
                        <span className="code-info">.fal .fa-cogs</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-bars" />
                        <span className="code-info">.fal .fa-bars</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-angle-down" />
                        <span className="code-info">.fal .fa-angle-down</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-comment" />
                        <span className="code-info">.fal .fa-comment</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-print" />
                        <span className="code-info">.fal .fa-print</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-share-alt" />
                        <span className="code-info">.fal .fa-share-alt</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-reply-all" />
                        <span className="code-info">.fal .fa-reply-all</span>
                    </div>
                </div>


                <div className="row">
                    <div className="col span-1-of-10">
                        <i className="fal fa-pencil-alt" />
                        <span className="code-info">.fal .fa-pencil-alt</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-minus-circle" />
                        <span className="code-info">.fal .fa-minus-circle</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-plus-circle" />
                        <span className="code-info">.fal .fa-plus-circle</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-rss" />
                        <span className="code-info">.fal .fa-rss</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-sync" />
                        <span className="code-info">.fal .fa-sync</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-user" />
                        <span className="code-info">.fal .fa-user</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-users" />
                        <span className="code-info">.fal .fa-users</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-lightbulb" />
                        <span className="code-info">.fal .fa-lightbulb</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-calendar-alt" />
                        <span className="code-info">.fal .fa-calendar-alt</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-shopping-bag" />
                        <span className="code-info">.fal .fa-shopping-bag</span>
                    </div>
                </div>


                <div className="row">
                    <div className="col span-1-of-10">
                        <i className="fal fa-star" />
                        <span className="code-info">.fal .fa-star</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-building" />
                        <span className="code-info">.fal .fa-building</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-briefcase" />
                        <span className="code-info">.fal .fa-briefcase</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-wrench" />
                        <span className="code-info">.fal .fa-wrench</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-check" />
                        <span className="code-info">.fal .fa-check</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-language" />
                        <span className="code-info">.fal .fa-language</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fal fa-map-marker-alt" />
                        <span className="code-info">.fal .fa-map-marker-alt</span>
                    </div>
                </div>
            </div>


            <div className="style-guide_box style-guide_box_icons">
                <h2><strong>social</strong>icons</h2>

                <div className="row">
                    <div className="col span-1-of-10">
                        <i className="fab fa-facebook-f" />
                        <span className="code-info">.fab .fa-facebook-f</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-instagram" />
                        <span className="code-info">.fab .fa-instagram</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-twitter" />
                        <span className="code-info">.fab .fa-twitter</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-pinterest-p" />
                        <span className="code-info">.fab .fa-pinterest-p</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-tumblr" />
                        <span className="code-info">.fab .fa-tumblr</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-stumbleupon" />
                        <span className="code-info">.fab .fa-stumbleupon</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-linkedin" />
                        <span className="code-info">.fab .fa-linkedin</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-google" />
                        <span className="code-info">.fab .fa-google</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-google-plus-g" />
                        <span className="code-info">.fab .fa-google-plus-g</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-stripe" />
                        <span className="code-info">.fab .fa-stripe</span>
                    </div>
                </div>


                <div className="row">
                    <div className="col span-1-of-10">
                        <i className="fab fa-google-play" />
                        <span className="code-info">.fab .fa-google-play</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-app-store" />
                        <span className="code-info">.fab .fa-app-store</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-apple" />
                        <span className="code-info">.fab .fa-apple</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-html5" />
                        <span className="code-info">.fab .fa-html5</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-css3-alt" />
                        <span className="code-info">.fab .fa-css3-alt</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-sass" />
                        <span className="code-info">.fab .fa-sass</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-node-js" />
                        <span className="code-info">.fab .fa-node-js</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-react" />
                        <span className="code-info">.fab .fa-react</span>
                    </div>

                    <div className="col span-1-of-10">
                        <i className="fab fa-accessible-icon" />
                        <span className="code-info">.fab .fa-accessible-icon</span>
                    </div>
                </div>
            </div>


            <div className="style-guide_box">
                <h2><strong>buttons</strong>links</h2>

                <div className="style-guide_box_buttons">
                    <div className="row">
                        <div className="col span-1-of-2">
                            <h4 style={{ marginBottom: "20px" }}>Buttons Regular</h4>

                            <div>
                                <button className="button button-regular">Button</button>
                                <span className="code-info">.button .button-regular</span>
                            </div>

                            <div>
                                <button className="button button-white">Button</button>
                                <span className="code-info">.button .button-white</span>
                            </div>

                            <div>
                                <button className="button button-clear">Button</button>
                                <span className="code-info">.button .button-clear</span>
                            </div>

                            <div>
                                <button className="button button-sale">Button</button>
                                <span className="code-info">.button .button-sale</span>
                            </div>
                        </div>

                        <div className="col span-1-of-2">
                            <h4 style={{ marginBottom: "20px" }}>Buttons Small</h4>

                            <div>
                                <button className="button button-regular button-small">Button</button>
                                <span className="code-info">add .button-small</span>
                            </div>

                            <div>
                                <button className="button button-white button-small">Button</button>
                                <span className="code-info">add .button-small</span>
                            </div>

                            <div>
                                <button className="button button-clear button-small">Button</button>
                                <span className="code-info">add .button-small</span>
                            </div>

                            <div>
                                <button className="button button-sale button-small">Button</button>
                                <span className="code-info">add .button-small</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="style-guide_box">
                <h2><strong>web</strong>forms</h2>

                <div className="row">
                    <div className="col span-1-of-4" style={{ paddingRight: "40px" }}>
                        <h4 style={{ marginBottom: "20px" }}>FORM</h4>
                        <input type="text" className="form-input-text" placeholder="First Name" />
                        <input type="email" className="form-input-text" placeholder="your@email.com" />
                        <input type="password" className="form-input-text" placeholder="*********" />
                        <div className="form-input-select">
                            <select>
                                <option value="none" selected="selected" disabled>Select Car</option>
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                        <textarea className="form-input-textarea" placeholder="Your text goes here..." />
                    </div>

                    <div className="col span-1-of-4">
                        <h4 style={{ marginBottom: "20px" }}>SINGLE CHECKLIST</h4>

                        <div className="form-input-lists">
                            <input type="radio" name="gender" id="male" value="male" /><label htmlFor="male"><i className="fal fa-check" />Male</label>
                        </div>

                        <div className="form-input-lists">
                            <input type="radio" name="gender" id="female" value="female" /><label htmlFor="female"><i className="fal fa-check" />Female</label>
                        </div>

                        <div className="form-input-lists">
                            <input type="radio" name="gender" id="alien" value="alien" /><label htmlFor="alien"><i className="fal fa-check" />Alien</label>
                        </div>

                        <div className="form-input-lists">
                            <input type="radio" name="gender" id="robot" value="robot" /><label htmlFor="robot"><i className="fal fa-check" />Robot</label>
                        </div>

                        <br />
                        <h4 style={{ marginBottom: "20px" }}>MULTIPLE CHECKLIST</h4>

                        <div className="form-input-lists">
                            <input type="checkbox" name="player" id="soccer" value="soccer" /><label htmlFor="soccer"><i className="fal fa-check" />Soccer</label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="player" id="tennis" value="tennis" /><label htmlFor="tennis"><i className="fal fa-check" />Tennis</label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="player" id="voley" value="voley" /><label htmlFor="voley"><i className="fal fa-check" />Voley</label>
                        </div>

                        <div className="form-input-lists">
                            <input type="checkbox" name="player" id="box" value="box" /><label htmlFor="box"><i className="fal fa-check" />Box</label>
                        </div>
                    </div>

                    <div className="col span-1-of-4">
                        <h4 style={{ marginBottom: "20px" }}>SINGLE CHECKLIST ROW</h4>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="gender" id="yes" value="yes" /><label htmlFor="yes"><i className="fal fa-check" />Yes</label>
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="gender" id="no" value="no" /><label htmlFor="no"><i className="fal fa-check" />No</label>
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="radio" name="gender" id="maybe" value="maybe" /><label htmlFor="maybe"><i className="fal fa-check" />Maybe</label>
                        </div>

                        <h4 style={{ marginBottom: "20px", marginTop: "30px" }}>MULTIPLE CHECKLIST ROW</h4>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="checkbox" name="car" id="ford" value="ford" /><label htmlFor="ford"><i className="fal fa-check" />Ford</label>
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="checkbox" name="car" id="audi" value="audi" /><label htmlFor="audi"><i className="fal fa-check" />Audi</label>
                        </div>

                        <div className="form-input-lists form-input-lists_row">
                            <input type="checkbox" name="car" id="toyota" value="toyota" /><label htmlFor="toyota"><i className="fal fa-check" />Toyota</label>
                        </div>
                    </div>

                    <div className="col span-1-of-4" style={{ paddingRight: "40px" }}>
                        <h4 style={{ marginBottom: "20px" }}>FORM WITH BORDER</h4>
                        <input type="text" className="form-input-text form-input-full mgb-sm" placeholder="First Name" />
                        <input type="email" className="form-input-text form-input-full mgb-sm" placeholder="your@email.com" />
                        <input type="password" className="form-input-text form-input-full mgb-sm" placeholder="*********" />
                        <div className="form-input-select">
                            <select className="form-input-full mgb-sm">
                                <option value="none" selected="selected" disabled>Select Car</option>
                                <option value="volvo">Volvo</option>
                                <option value="saab">Saab</option>
                                <option value="mercedes">Mercedes</option>
                                <option value="audi">Audi</option>
                            </select>
                        </div>
                        <textarea className="form-input-textarea form-input-full" placeholder="Your text goes here..." />
                    </div>
                </div>
            </div>


            <div className="style-guide_box">
                <h2><strong>info</strong>boxes</h2>
            </div>


            <div className="style-guide_box">
                <h2><strong>default</strong>images</h2>
            </div>


            <div className="style-guide_box">
                <h2><strong>web</strong>badges</h2>
            </div>


            <div className="style-guide_box">
                <h2><strong>side</strong>bar</h2>
            </div>


            <div className="style-guide_box style-guide_box_grid">
                <h2><strong>the</strong>grid</h2>

                <div className="row">
                    <div className="col span-2-of-2">
                        <span className="code-info">.span-2-of-2</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col span-1-of-2">
                        <span className="code-info">.span-1-of-2</span>
                    </div>

                    <div className="col span-1-of-2">
                        <span className="code-info">.span-1-of-2</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col span-1-of-3">
                        <span className="code-info">.span-1-of-3</span>
                    </div>

                    <div className="col span-1-of-3">
                        <span className="code-info">.span-1-of-3</span>
                    </div>

                    <div className="col span-1-of-3">
                        <span className="code-info">.span-1-of-3</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col span-1-of-4">
                        <span className="code-info">.span-1-of-4</span>
                    </div>

                    <div className="col span-1-of-4">
                        <span className="code-info">.span-1-of-4</span>
                    </div>

                    <div className="col span-1-of-4">
                        <span className="code-info">.span-1-of-4</span>
                    </div>

                    <div className="col span-1-of-4">
                        <span className="code-info">.span-1-of-4</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col span-1-of-5">
                        <span className="code-info">.span-1-of-5</span>
                    </div>

                    <div className="col span-1-of-5">
                        <span className="code-info">.span-1-of-5</span>
                    </div>

                    <div className="col span-1-of-5">
                        <span className="code-info">.span-1-of-5</span>
                    </div>

                    <div className="col span-1-of-5">
                        <span className="code-info">.span-1-of-5</span>
                    </div>

                    <div className="col span-1-of-5">
                        <span className="code-info">.span-1-of-5</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col span-1-of-6">
                        <span className="code-info">.span-1-of-6</span>
                    </div>

                    <div className="col span-1-of-6">
                        <span className="code-info">.span-1-of-6</span>
                    </div>

                    <div className="col span-1-of-6">
                        <span className="code-info">.span-1-of-6</span>
                    </div>

                    <div className="col span-1-of-6">
                        <span className="code-info">.span-1-of-6</span>
                    </div>

                    <div className="col span-1-of-6">
                        <span className="code-info">.span-1-of-6</span>
                    </div>

                    <div className="col span-1-of-6">
                        <span className="code-info">.span-1-of-6</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col span-1-of-7">
                        <span className="code-info">.span-1-of-7</span>
                    </div>

                    <div className="col span-1-of-7">
                        <span className="code-info">.span-1-of-7</span>
                    </div>

                    <div className="col span-1-of-7">
                        <span className="code-info">.span-1-of-7</span>
                    </div>

                    <div className="col span-1-of-7">
                        <span className="code-info">.span-1-of-7</span>
                    </div>

                    <div className="col span-1-of-7">
                        <span className="code-info">.span-1-of-7</span>
                    </div>

                    <div className="col span-1-of-7">
                        <span className="code-info">.span-1-of-7</span>
                    </div>

                    <div className="col span-1-of-7">
                        <span className="code-info">.span-1-of-7</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col span-1-of-8">
                        <span className="code-info">.span-1-of-8</span>
                    </div>

                    <div className="col span-1-of-8">
                        <span className="code-info">.span-1-of-8</span>
                    </div>

                    <div className="col span-1-of-8">
                        <span className="code-info">.span-1-of-8</span>
                    </div>

                    <div className="col span-1-of-8">
                        <span className="code-info">.span-1-of-8</span>
                    </div>

                    <div className="col span-1-of-8">
                        <span className="code-info">.span-1-of-8</span>
                    </div>

                    <div className="col span-1-of-8">
                        <span className="code-info">.span-1-of-8</span>
                    </div>

                    <div className="col span-1-of-8">
                        <span className="code-info">.span-1-of-8</span>
                    </div>

                    <div className="col span-1-of-8">
                        <span className="code-info">.span-1-of-8</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col span-1-of-9">
                        <span className="code-info">.span-1-of-9</span>
                    </div>

                    <div className="col span-1-of-9">
                        <span className="code-info">.span-1-of-9</span>
                    </div>

                    <div className="col span-1-of-9">
                        <span className="code-info">.span-1-of-9</span>
                    </div>

                    <div className="col span-1-of-9">
                        <span className="code-info">.span-1-of-9</span>
                    </div>

                    <div className="col span-1-of-9">
                        <span className="code-info">.span-1-of-9</span>
                    </div>

                    <div className="col span-1-of-9">
                        <span className="code-info">.span-1-of-9</span>
                    </div>

                    <div className="col span-1-of-9">
                        <span className="code-info">.span-1-of-9</span>
                    </div>

                    <div className="col span-1-of-9">
                        <span className="code-info">.span-1-of-9</span>
                    </div>

                    <div className="col span-1-of-9">
                        <span className="code-info">.span-1-of-9</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col span-1-of-10">
                        <span className="code-info">.span-1-of-10</span>
                    </div>

                    <div className="col span-1-of-10">
                        <span className="code-info">.span-1-of-10</span>
                    </div>

                    <div className="col span-1-of-10">
                        <span className="code-info">.span-1-of-10</span>
                    </div>

                    <div className="col span-1-of-10">
                        <span className="code-info">.span-1-of-10</span>
                    </div>

                    <div className="col span-1-of-10">
                        <span className="code-info">.span-1-of-10</span>
                    </div>

                    <div className="col span-1-of-10">
                        <span className="code-info">.span-1-of-10</span>
                    </div>

                    <div className="col span-1-of-10">
                        <span className="code-info">.span-1-of-10</span>
                    </div>

                    <div className="col span-1-of-10">
                        <span className="code-info">.span-1-of-10</span>
                    </div>

                    <div className="col span-1-of-10">
                        <span className="code-info">.span-1-of-10</span>
                    </div>

                    <div className="col span-1-of-10">
                        <span className="code-info">.span-1-of-10</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col span-1-of-11">
                        <span className="code-info">.span-1-of-11</span>
                    </div>

                    <div className="col span-1-of-11">
                        <span className="code-info">.span-1-of-11</span>
                    </div>

                    <div className="col span-1-of-11">
                        <span className="code-info">.span-1-of-11</span>
                    </div>

                    <div className="col span-1-of-11">
                        <span className="code-info">.span-1-of-11</span>
                    </div>

                    <div className="col span-1-of-11">
                        <span className="code-info">.span-1-of-11</span>
                    </div>

                    <div className="col span-1-of-11">
                        <span className="code-info">.span-1-of-11</span>
                    </div>

                    <div className="col span-1-of-11">
                        <span className="code-info">.span-1-of-11</span>
                    </div>

                    <div className="col span-1-of-11">
                        <span className="code-info">.span-1-of-11</span>
                    </div>

                    <div className="col span-1-of-11">
                        <span className="code-info">.span-1-of-11</span>
                    </div>

                    <div className="col span-1-of-11">
                        <span className="code-info">.span-1-of-11</span>
                    </div>

                    <div className="col span-1-of-11">
                        <span className="code-info">.span-1-of-11</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>

                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>

                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>

                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>

                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>

                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>

                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>

                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>

                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>

                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>

                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>

                    <div className="col span-1-of-12">
                        <span className="code-info">.span-1-of-12</span>
                    </div>
                </div>
            </div>


            <div className="style-guide_box style-guide_box_directory">
                <h2><strong>web</strong>directory</h2>

                <div className="row">
                    <div className="col span-1-of-6">
                        <p className="directory-header">User Pages</p>

                        <div className="directory-links">
                            <Link to='/harry-freeman'>Profile Page</Link>
                            <Link to='/micah-green'>Business Profile Page</Link>
                            <Link to='/notifications'>Notifications</Link>
                            <Link to='/chat'>Chat</Link>
                            <Link to='/followrequests'>Follow Requests</Link>
                            <Link to='/postshistory'>Posts History</Link>
                            <Link to='/purchasehistory'>Purchase History</Link>
                        </div>
                    </div>

                    <div className="col span-1-of-6">
                        <p className="directory-header">Create New</p>

                        <div className="directory-links">
                            <Link to='/blogs/new-blog'>Add Blog</Link>
                            <Link to='/store/new-product'>Add Product</Link>
                            <Link to='/photos/new-photo'>Add Photo/Gallery</Link>
                            <Link to='/videos/new-video'>Add Video</Link>
                            <Link to='/jobs/new-job'>Add Jobs</Link>
                            <Link to='/realestate/new-property'>Add Property</Link>
                            <Link to='/services/new-service'>Add Service</Link>
                            <Link to='/coupons/new-coupon'>Add Coupons</Link>
                            <Link to='/events/new-event'>Add Events</Link>
                            <Link to='/groups/new-group'>Add Groups</Link>
                            <Link to='/travel/new-travel'>Add Travel</Link>
                        </div>
                    </div>

                    <div className="col span-1-of-6">
                        <p className="directory-header">Account Settings</p>

                        <div className="directory-links">
                            <Link to='/profileupdate'>Profile Update</Link>
                            <Link to='/invitefriends'>Invite Friends</Link>
                            <Link to='/accountsettings'>Account Settings</Link>
                            <Link to='/privacysettings'>Privacy</Link>
                            <Link to='/subscriptionsettings'>Subscription</Link>
                            <Link to='/deleteaccount'>Delete Account</Link>
                        </div>
                    </div>

                    <div className="col span-1-of-6">
                        <p className="directory-header">User Generated</p>

                        <div className="directory-links">
                            <Link to='/'>Home</Link>
                            <Link to='/blogs'>Blog Home</Link>
                            <Link to='/blogs/other/food-cravings'>Blog Post</Link>
                            <Link to='/store'>Store Home</Link>
                            <Link to='/store/home-decor/new-wicker-loom-club-chair'>Product Detail Page</Link>
                            <Link to='/photos'>Photo Gallery Category</Link>
                            <Link to='/videos'>Video Gallery Category</Link>
                            <Link to='/photos/sports/my-new-gallery'>Photo Gallery</Link>
                            <Link to='/medialightbox'>Photo/Video Lightbox</Link>
                            <Link to='/jobs'>Jobs Category</Link>
                            <Link to='/jobs/retail/cashier-grocery-store'>Jobs Posting</Link>
                            <Link to='/realestate'>Real Estate Category</Link>
                            <Link to='/realestate/homes/beautiful-house-for-sale'>Real Estate Detail</Link>
                            <Link to='/services'>Services Category</Link>
                            <Link to='/services'>Services Listing</Link>
                            <Link to='/coupons'>Coupon Codes</Link>
                            <Link to='/searchresults'>Search Results Page</Link>
                            <Link to='/events'>Events Home</Link>
                            <Link to='/events/other/first-event'>Events Listing</Link>
                            <Link to='/groups'>Groups Home</Link>
                            <Link to='/groups/other/first-group'>Groups Listing</Link>
                            <Link to='/travel'>Travel Home</Link>
                            <Link to='/travel/other/first-package'>Travel Listing</Link>
                        </div>
                    </div>

                    <div className="col span-1-of-6">
                        <p className="directory-header">Static Pages</p>

                        <div className="directory-links">
                            <Link to='/404'>404</Link>
                            <Link to='/about'>About Us</Link>
                            <Link to='/meettheteam'>Meet The Team</Link>
                            <Link to='/directory'>Directory</Link>
                            <Link to='/help'>Help</Link>
                            <Link to='/careers'>Careers</Link>
                            <Link to='/press'>Press</Link>
                            <Link to='/affiliates'>Affiliates</Link>
                            <Link to='/investors'>Investors</Link>
                            <Link to='/smallbusinesses'>Start-Ups</Link>
                            <Link to='/advertise'>Advertise</Link>
                            <Link to='/contact'>Contact</Link>
                            <Link to='/privacy'>Privacy</Link>
                            <Link to='/termsofservice'>Terms Of Service</Link>
                        </div>
                    </div>

                    <div className="col span-1-of-6">
                        <p className="directory-header">Development</p>

                        <div className="directory-links">
                            <Link to='/modalpreview'>Modal Preview</Link>
                            <Link to='/styleguide'>Styleguide</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default StyleGuide;