import React from 'react';
import ListingDetail from '../../../shared/templates/ListingTemplate/listing-detail';

export default function TravelListingDetail(props) {
  return (
    <ListingDetail 
      section="travel" 
      createNewTitle="New Travel"
    />
  );
}