import { useState, useRef } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Axios from "axios";

import { maxUploadFileSize } from "./file";
import setCanvasPreview from "./set-canvas-preview";
import { hostNameAndPort } from "./vars";
import Box from "./box";
import Backdrop from "../uielements/Backdrop/Backdrop";

const ASPECT_RATIO = 1;

function CropImageModal(props) {
  const hiddenFileInput = useRef();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState();
  const [imgSrc, setImgSrc] = useState("");
  const [error, setError] = useState("");

  const [coverImage, setCoverImage] = useState("");
  const [profileImageFile, setProfileImageFile] = useState("");
  const [coverImageFile, setCoverImageFile] = useState("");
  const [profileImage, setProfileImage] = useState("");

  async function saveProfileImage(prfImage, prfImageFile) {
    let _state = {
      firstName: props.firstName,
      lastName: props.lastName,
      aboutMe: props.aboutMe,
      userName: props.userName,
      education: props.education,
      maritalStatus: props.maritalStatus,
      entityName: props.entityName,
      accountType: props.accountType,
      entityFounded: props.entityFounded,
      userId: props.userId,
      profileImage: prfImage,
      profileImageFile: prfImageFile,
    };

    console.log("state", _state);

    let _data = new FormData();
    for (let i in _state) {
      if (!_state[i]) {
        _state[i] = "";
      }
      _data.append(i, _state[i]);
    }

    _data.append("entityContact", JSON.stringify(props.entityContact));
    _data.append("entityPolicies", JSON.stringify(props.entityPolicies));
    _data.append("entityDetails", JSON.stringify(props.entityDetails));

    let options = {
      url: hostNameAndPort + "/api/user/update",
      method: "post",
      data: _data,
    };

    try {
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        setTimeout(() => {
          props.onUpdateUserProfile(data.user);
        }, 1000);
      } else {
        console.log(data);
      }
    } catch (err) {
      // Network error
      console.log(err);
    }

    props.onCropPhotoCompleted();
  }

  async function saveCoverImage(cvrImage, cvrImageFile) {
    let _state = {
      firstName: props.firstName,
      lastName: props.lastName,
      aboutMe: props.aboutMe,
      userName: props.userName,
      education: props.education,
      maritalStatus: props.maritalStatus,
      entityName: props.entityName,
      accountType: props.accountType,
      entityFounded: props.entityFounded,
      userId: props.userId,
      coverImage: cvrImage,
      coverImageFile: cvrImageFile,
    };

    console.log("state", _state);

    let _data = new FormData();
    for (let i in _state) {
      if (!_state[i]) {
        _state[i] = "";
      }
      _data.append(i, _state[i]);
    }

    _data.append("entityContact", JSON.stringify(props.entityContact));
    _data.append("entityPolicies", JSON.stringify(props.entityPolicies));
    _data.append("entityDetails", JSON.stringify(props.entityDetails));

    let options = {
      url: hostNameAndPort + "/api/user/update",
      method: "post",
      data: _data,
    };

    try {
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        setTimeout(() => {
          props.onUpdateUserProfile(data.user);
        }, 1000);
      } else {
        console.log(data.error);
      }
    } catch (err) {
      // Network error
      console.log(err);
    }

    props.onCropPhotoCompleted();
  }

  async function getCroppedProfileImage(f) {
    if (!f) {
      return;
    }

    let _fileName = "profile-photo.jpg";
    let _file = new File([f], _fileName, { type: "image/jpeg" });

    let image = new Image();
    let url = URL.createObjectURL(f);

    image.src = url;
    image.alt = _fileName;

    image.onload = function () {
      URL.revokeObjectURL(url);

      if (this.width < 400 || this.height < 400) {
        setProfileImage("");
      } else {
        setProfileImage(_fileName);
        setProfileImageFile(_file);
      }
    };

    await saveProfileImage(_fileName, _file);
  }

  async function getCroppedCoverImage(f) {
    if (!f) {
      return;
    }
    let _fileName = "cover-photo.jpg";
    let _file = new File([f], _fileName, { type: "image/jpeg" });

    let image = new Image();
    let url = URL.createObjectURL(f);
    image.src = url;
    image.alt = _fileName;

    image.onload = function () {
      URL.revokeObjectURL(url);

      if (this.width < 1400 || this.height < 280) {
        setCoverImage("");
      } else {
        setCoverImage(_fileName);
        setCoverImageFile(_file);
      }
    };

    await saveCoverImage(_fileName, _file);
  }

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (
          naturalHeight < props.desiredHeight ||
          naturalWidth < props.desiredWidth
        ) {
          setError("Image is too small, please upload a larger image.");
          setImgSrc("");
          return;
        }
      });

      setImgSrc(imageUrl);
    });

    reader.readAsDataURL(file);
  };

  function popUploadImage() {
    hiddenFileInput.current.click();
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (props.desiredWidth / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      props.desiredWidth / props.desiredHeight,
      width,
      height
    );

    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  }

  function onCropChange(pixelCrop, percentCrop) {
    setCrop(percentCrop);
  }

  function onSaveImage() {
    // console.log("IMGREF", imgRef.current);
    // console.log("CANVASREF", previewCanvasRef.current);

    if (!imgRef.current || !previewCanvasRef.current) {
      setError("There was an issue uploading the image, please try again.");
      return;
    }

    // Calculate the new dimensions
    const aspectRatio = imgRef.current.height / imgRef.current.width;
    const newWidth = 1500;
    const newHeight = newWidth * aspectRatio;

    // Set canvas dimensions
    previewCanvasRef.current.width = newWidth;
    previewCanvasRef.current.height = newHeight;

    // Set the preview on the canvas using the current crop
    const ctx = previewCanvasRef.current.getContext("2d");
    ctx.drawImage(imgRef.current, 0, 0, newWidth, newHeight);

    // Get the compressed data URL from the canvas
    const dataUrl = previewCanvasRef.current.toDataURL("image/jpeg", 0.7);
    // console.log("DATA URL", dataUrl);

    if (!dataUrl) {
      console.error(dataUrl);
      setError("Blob could not be created.");
      return;
    }

    // Convert Data URL to Blob
    fetch(dataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        // console.log("BLOB", blob);
        if (!blob) {
          console.error(blob);
          setError("Blob could not be created.");
          return;
        }

        if (props.isProfileImage) {
          console.log("Is Profile Image");
          getCroppedProfileImage(blob);
        } else if (props.isCoverImage) {
          console.log("Is Cover Image");
          getCroppedCoverImage(blob);
        } else {
          props.onCropPhotoCompleted(blob);
        }
      })
      .catch((error) => {
        console.error("Error fetching or converting blob:", error);
        setError("An error occurred while processing the image.");
      });
  }

  if (!props.active) {
    return null;
  }

  return (
    <Box>
      <Backdrop
        show={props.active}
        clicked={() => props.onCropPhotoCompleted(null)}
      />

      <div className="modal-crop">
        <div className="media-modal_box">
          {imgSrc && (
            <ReactCrop
              crop={crop}
              keepSelection
              aspect={1}
              onChange={onCropChange}
              minWidth={props.desiredWidth}
            >
              <img
                ref={imgRef}
                src={imgSrc}
                alt="Upload"
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}

          {crop && (
            <canvas ref={previewCanvasRef} style={{ display: "none" }} />
          )}

          {error && <p className="image-upload-error">{error}</p>}
        </div>

        <div className="media-modal_desc">
          <div
            className="media-modal_desc_crop-image"
            style={{ marginRight: "1rem" }}
          >
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={onSelectFile}
              accept="image/*"
              style={{ display: "none" }}
            />
            <button
              onClick={popUploadImage}
              className="button button-regular"
              style={{ borderRadius: "3rem" }}
            >
              Upload Image
            </button>
          </div>

          <span className="crop-modal-mobile-button">
            <button
              className="button button-regular"
              style={{ borderRadius: "3rem" }}
              onClick={onSaveImage}
            >
              Save Image
            </button>
          </span>
        </div>

        <button
          className="modal_close"
          onClick={() => props.onCropPhotoCompleted(null)}
        >
          <i className="fal fa-times" />
        </button>
      </div>
    </Box>
  );
}

export default CropImageModal;
