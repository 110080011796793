import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
// import actionType from '../../../../redux-store/action-type';
import {connect} from 'react-redux';
// import Swiper from 'react-id-swiper/lib/ReactIdSwiper.full';

// import { getTranslatedPhrase } from '../../../../util/lang';

class GroupHeader extends Component {
    onClickHandler = (t) => {
        let newTabState = this.props.tabList;
        for (let i in newTabState) {
            newTabState[i] = false;
        }
        newTabState[t+'IsClicked'] = true;
        this.props.onChangeTab(newTabState);
    }

    sendMessage = () => {
        let u = this.props.userToMessage;
        let m = '';
        this.props.updateMessagesPageProps(u,m);
    }

    render () { 

        // const params = {
        //     slidesPerView: 'auto',
        //     spaceBetween: 30,
        //     freeMode: true,
        //     scrollbar: {
        //         el: '.swiper-scrollbar',
        //         draggable: false,
        //       }
        // };

        return (
            <div className="profile_header">
 
                <div className="profile_header_cover-image">
                    <img src={this.props.coverImage} alt={this.props.userName} />       
                </div>

                <div className="profile_header_user-image">
                    <img src={this.props.profileImage} alt={this.props.userName} />
                    <h1>{this.props.userName}</h1>

                    <div className="clb" />
                </div>

                {/* <div className="logged-in-header">
                    <div className="logged-in-header_to-do">
                        <div className="logged-in-header_to-do_swiper">
                            <Swiper {...params}>

                                <div className="profile-nav-box-title profile-nav-box-title-1">
                                    <span>{getTranslatedPhrase(this.props.userLanguage,'Profile')}</span>
                                </div>

                                <button to="" className="home-new-profile"><i className="fal fa-user-circle"></i> <span>{getTranslatedPhrase(this.props.userLanguage,'Profile Updates')}</span></button>

                                <div className="profile-nav-box-title profile-nav-box-title-2">
                                    <span>{getTranslatedPhrase(this.props.userLanguage,'Social')}</span>
                                </div>

                                
                                

                                <button to="/blogs/new-blog" className="home-new-blog"><i className="fal fa-blog"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Write a Blog')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Blogs')}</span></button>
                                <button to="/photos/new-photo" className="home-new-photo"><i className="fal fa-camera-retro"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Post Photos')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Photos')}</span></button>
                                
                                <div className="profile-nav-box">
                                    <button to="/photos/new-photo" className="square-menu-item">
                                        <div className="square-menu-item_box"><img src={Image1} alt="" /></div>
                                        <div className="square-menu-item_box"><img src={Image2} alt="" /></div>
                                        <div className="square-menu-item_box"><img src={Image3} alt="" /></div>
                                        <div className="square-menu-item_box"><img src={Image4} alt="" /></div>

                                        <div className="square-menu-item-icon square-menu-item-icon-photo">
                                            <i className="fal fa-camera-retro"></i> 
                                            <span>33 {getTranslatedPhrase(this.props.userLanguage,'Photos')}</span>
                                        </div>
                                    </button>
                                </div>
                                
                                
                                <button to="/videos/new-video" className="home-new-video"><i className="fal fa-video"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Post Videos')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Videos')}</span></button>     
                                
                                <div className="profile-nav-box">
                                    <button to="/videos/new-video" className="square-menu-item">
                                        <div className="square-menu-item_box"><img src={Image1} alt="" /></div>
                                        <div className="square-menu-item_box"><img src={Image2} alt="" /></div>
                                        <div className="square-menu-item_box"><i className="fal fa-video"></i></div>
                                        <div className="square-menu-item_box"><i className="fal fa-video"></i></div>

                                        <div className="square-menu-item-icon square-menu-item-icon-video">
                                            <i className="fal fa-video"></i> 
                                            <span>2 {getTranslatedPhrase(this.props.userLanguage,'Videos')}</span>
                                        </div>
                                    </button>
                                </div>
                                
                                
                                <button to="/events/new-event" className="home-new-event"><i className="fal fa-calendar-alt"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Add an Event')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Events')}</span></button>       
                                <button to="/groups/new-group" className="home-new-group"><i className="fal fa-users"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Start a Group')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Groups')}</span></button>   
                                
                                <div className="profile-nav-box-title profile-nav-box-title-3">
                                    <span>{getTranslatedPhrase(this.props.userLanguage,'Business')}</span>
                                </div>
                                
                                <button to="/store/new-product" className="home-new-products"><i className="fal fa-shopping-bag"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Sell Products')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Products')}</span></button>
                                <button to="/store/new-product" className="home-new-cars"><i className="fal fa-car"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Sell Cars')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Auto Listings')}</span></button>
                                <button to="/coupons/new-coupon" className="home-new-coupons"><i className="fal fa-barcode"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Add Coupons')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Coupons')}</span></button>
                                <button to="/realestate/new-property" className="home-new-properties"><i className="fal fa-building"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Sell Properties')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Properties')}</span></button>
                                <button to="/jobs/new-job" className="home-new-jobs"><i className="fal fa-briefcase"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Post Jobs')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Jobs')}</span></button>
                                <button to="/services/new-service" className="home-new-services"><i className="fal fa-wrench"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Add Services')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Services')}</span></button>
                                <button to="/travel/new-travel" className="home-new-packages"><i className="fal fa-bed"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Add Travel Package')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Travel Packages')}</span></button>
                                <button to="/travel/new-accomodations" className="home-new-accommodations"><i className="fal fa-bed"></i> <span className="logged-in-cta">{getTranslatedPhrase(this.props.userLanguage,'Add Accommodation')}</span> <span className="viewer-cta">0 {getTranslatedPhrase(this.props.userLanguage,'Accommodations')}</span></button>   
                            </Swiper>
                        </div>
                    </div>
                </div> */}

                {/* <div className="profile_header_menu">    
                    <button className="active" onClick={() =>this.onClickHandler('all')}>All Updates</button>
                    { (!!this.props.stats.noVideos)?(<button onClick={() =>this.onClickHandler('video')}>Videos ({this.props.stats.noVideos})</button>):''}
                    { (!!this.props.stats.noPhotos)?(<button onClick={() =>this.onClickHandler('photo')}>Photos ({this.props.stats.noPhotos})</button>):''}
                    { (!!this.props.stats.noBlogs)?(<button onClick={() =>this.onClickHandler('blog')}>Blogs ({this.props.stats.noBlogs})</button>):''}
                    { (!!this.props.stats.noProducts)?(<button onClick={() =>this.onClickHandler('storeitem')}>Products ({this.props.stats.noProducts})</button>):''}
                    { (!!this.props.stats.noProperties)?(<button onClick={() =>this.onClickHandler('realestate')}>Properties ({this.props.stats.noProperties})</button>):''}
                    { (!!this.props.stats.noTravel)?(<button onClick={() =>this.onClickHandler('travel')}>Travel ({this.props.stats.noTravel})</button>):''}
                    { (!!this.props.stats.noEvents)?(<button onClick={() =>this.onClickHandler('events')}>Events ({this.props.stats.noEvents})</button>):''}
                    { (!!this.props.stats.noGroups)?(<button onClick={() =>this.onClickHandler('groups')}>Groups ({this.props.stats.noGroups})</button>):''}
                    { (!!this.props.stats.noFollowers)?(<button onClick={() =>this.onClickHandler('followers')}>Followers ({this.props.stats.noFollowers})</button>):''}
                    { (!!this.props.stats.noForerunners)?(<button onClick={() =>this.onClickHandler('forerunners')}>You Follow ({this.props.stats.noForerunners})</button>):''}
                </div> */}
            </div>
        );
    }
}

//export default GroupHeader;
const mapStateToGroupHeaderProps = (
    state,
    props
) => {
    return {
        userIsAuthenticated: state.userIsAuthenticated,
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToGroupHeaderProps, null)(GroupHeader);