import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';
import { getTranslatedPhrase } from '../../../util/lang';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import StripeBadge from '../../../../assets/stripe-badge-white.png';


var stripePromise;
async function initStripe() {
  let cheia01 = "cGtfdGVzdF9kTEpWVHphMVdKRzliVnM3RjRETlRJaVY=";
  let cheia02 = "cGtfbGl2ZV9zc3VVTmtaZFhBRmdqSHpnQ0VqNHlOMU8=";
  if ( process.env.NODE_ENV && process.env.NODE_ENV === 'development' ) stripePromise = await loadStripe(atob(cheia01))
  else stripePromise = await loadStripe(atob(cheia02));
}

initStripe();

const createOptions = (fontSize) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, Menlo, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
};

function FormElements(props) {
    const [errorMessage, setErrorMessage] = useState('');
    const userLanguage = useSelector(state => state.userLanguage);
    const stripe = useStripe();
    const elements = useElements();

    const handleChange = (event) => {
        if (event.error) {
          setErrorMessage(event.error.message);
        } else {
          setErrorMessage('');
        }
    }

    function onSubmitHandler(e) {
        e.preventDefault();

        if (!stripe || !elements) return;

        stripe.createToken(elements.getElement(CardElement))
        .then( result => {
            if (!result.error) {
                setErrorMessage('');
                let { token } = result;
                props.onSubmitUserInfo({ stripeToken: token, gpaymentId: '' });
                props.onMoveForward();
            }
            else {
                setErrorMessage(result.error.message);
            }
        })
        .catch(err => {
            //console.log(`stripe ${err}`);
        });
    }


    return (
        <form onSubmit={onSubmitHandler} > 
            <h4 style={{marginBottom: '1rem'}}>{getTranslatedPhrase(userLanguage,'Payment')}</h4>
            <CardElement {...createOptions(props.fontSize)} onChange={handleChange} />
            <FormFieldError>{getTranslatedPhrase(userLanguage, errorMessage)}</FormFieldError>
            <p className="mgb-sm mgt-sm">By clicking "Pay", you agree to the <Link target="_blank" to="/terms-of-service">{getTranslatedPhrase(props.userLanguage, 'terms of service')}</Link></p>
            <button className="button button-regular" onClick={onSubmitHandler}>{getTranslatedPhrase(userLanguage, props.submitButtonLabel || 'Pay')} {!props.promoCharge?'':'$'+props.promoCharge}</button>

            <img src={StripeBadge} className="stripe-badge" />
        </form>
    );
}

function PaymentForm(props) {
  return (
    <Elements stripe={stripePromise} tag="payment-form">
      <FormElements {...props} />
    </Elements>
  );
}


export default PaymentForm;

        // <form onSubmit={onSubmitHandler} >
        //     <h4>{getTranslatedPhrase(userLanguage,'Payment')}</h4>
        //     <CardElement {...createOptions(props.fontSize)} />
        //     <div className="payment-field-box">
        //         <label>{getTranslatedPhrase(userLanguage,"Card number")}</label>
        //         <div className="payment-field">
        //             <CardNumberElement {...createOptions(props.fontSize)} />
        //         </div>
        //     </div>
        //     <div className="payment-field-box">
        //         <label>{getTranslatedPhrase(userLanguage, "Expiration date")}</label>
        //         <div className="payment-field">
        //             <CardExpiryElement {...createOptions(props.fontSize)} />
        //         </div>
        //     </div>
        //     <div className="payment-field-box">
        //         <label>CVC</label>
        //         <div className="payment-field">
        //             <CardCVCElement {...createOptions(props.fontSize)} />
        //         </div>
        //     </div>
        //     <div className="payment-field-box">
        //         <label>{getTranslatedPhrase(userLanguage, "Postal code")}</label>
        //         <div className="payment-field">
        //             <PostalCodeElement {...createOptions(props.fontSize)} />
        //         </div>
        //     </div>
        //     <FormFieldError>{getTranslatedPhrase(userLanguage, errorMessage)}</FormFieldError>
        //     <button className="button button-regular" type="submit">{getTranslatedPhrase(userLanguage, props.submitButtonLabel || 'Pay')} {!props.promoCharge?'':'$'+props.promoCharge}</button>
        // </form>