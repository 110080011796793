import React from 'react';
import SideBar from '../../../shared/templates/CategoryTemplate/category-sidebar';

function NewsSideBar(props) {
  return (<SideBar {...props} 
    section="news"
    searchTitle="Search News"
    createNewTitle="New News"
    filterList={[
      { name:'keywords', value: 'Enter Keywords' }, 
    ]}
  />);
}

export default NewsSideBar;