import React from 'react';
import { connect, useSelector } from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import actionType from '../../../redux-store/action-type';


const HomeSignUp = props => {
    const userLanguage = useSelector(state => state.userLanguage);

    return (
        <div className="home-signup-box">
            <div className="hsub-text">
                <h1>{getTranslatedPhrase(userLanguage,'Sign-Up Today for Free')}</h1>
                <p>{getTranslatedPhrase(userLanguage,'Sell products, cars, properties and more. Post jobs, services, news, blog posts & more.')}</p>
            </div>
            
            <div className="hsub-buttons">
                <button className="button button-regular" onClick={props.onClickSignInButtonHandler}>{getTranslatedPhrase(userLanguage,'Sign Up')}</button>
                <button className="button button-white" onClick={props.onClickLoginButtonHandler}>{getTranslatedPhrase(userLanguage,'Log In')}</button>
            </div>
        </div>
    )
}

const mapDispatchTosignedOutHomeProps = (
    dispatch
) => {
    return {
        onClickLoginButtonHandler: () => dispatch({type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: window.location.pathname }),
        onClickSignInButtonHandler: () => dispatch({type: actionType.ACCOUNT_TYPE_MODAL, accountTypeModalActive: true})
    };
};

export default connect(null,mapDispatchTosignedOutHomeProps)(HomeSignUp);