import React from 'react';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import VideoPreview from './VideoPreview';
import VideosSideBar from './VideosSideBar';
import VideosSideBarMobile from './VideosSideBarMobile';
import VideosFeatured from './VideosFeatured';

function VideosCategory() {
  return (
    <CategoryPage
      section="videos"
      description="Create or Post Videos"
      ItemPreview={VideoPreview}
      SideBar={VideosSideBar}
      SideBarMobile={VideosSideBarMobile}
      FeaturedItems={VideosFeatured}
    />);
}

export default VideosCategory;