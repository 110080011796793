import React, { Component } from "react";
import ListLoader from "../../../../shared/util/list-loader";
import { titleCase } from "../../../../shared/util/string";
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import CreateCouponModal from "../../../../shared/components/Modals/Account/CreateCouponModal";
import moment from "moment";
import { hostNameAndPort } from "../../../../shared/util/vars";
import Axios from "axios";
import LoadingSvg from "../../../../shared/uielements/LoadingSpinner/LoadingSvg";
import { connect } from 'react-redux';
import { currencyList } from '../../../../shared/util/string';

class ProductEditFields extends Component {
  constructor(props) {
    super(props);
    this.initialState = this.state = {
      title: "",
      brandName: "",
      ticketSaleURL: "",
      category: "",
      subcategory: "",
      subcategoriesSelect: [],
      productCondition: "",
      pickUp: "sell-local",
      callForPricing: false,
      description: "",
      price: "",
      currency: "USD",
      privacy: "public",
      categoriesSelect: [],
      specList: [],
      couponList: [],
      addDiscount: "how-about-discount",
      discountId: "",
      alreadyLoadedItem: false,
      csvFileWaiting: false,
      temp: {
        specName: "",
        specValue: "",
        selectWhichCoupon: "",
        discountType: "",
        discountValue: "",
        discountCurrency: "%",
        availability: "",
        endDate: moment().format("YYYY-MM-DD"),
        startDate: moment().format("YYYY-MM-DD"),
        showCouponModal: false
      }
    };
  }

  componentDidMount() {
    let Loader = new ListLoader({
      cb: this.onLoad,
      options: {
        delay: 3000,
        count: 20,
        request: {
          url: "/api/coupons/items",
          method: "post",
          formParams: { filter: JSON.stringify({ author: "", isDeal: false }) }
        }
      }
    });
    Loader.init();
    this.setState({ Loader });
    setTimeout(() => {
      this.forceUpdate();
    }, 2000);
  }

  componentDidUpdate() {
    if (!this.state.alreadyLoadedItem && this.props.loadedItem.title && this.props.categoriesSelect.length) {
      let s = { ...this.state };
      let { Loader, couponList } = this.state;
      for (let i in s) {
        if (s.hasOwnProperty(i)) s[i] = this.props.loadedItem[i];
      }
      s.Loader = Loader;
      s.couponList = couponList;
      s.alreadyLoadedItem = true;

      // Subcategories
      let a = this.props.categoriesSelect;
      let b = [];
      let i = 0;
      for (; i < a.length; i++) {
        if (a[i] === s.category) break;
      }
      i++;
      while (/^\s*-+/.test(a[i]) && i < a.length) {
        b.push(a[i].replace(/^\s*-+\s*/, ''));
        i++;
      }
      s.subcategoriesSelect = [...b];
      this.setState(s);
      if (s.pickUp === 'sell-online') this.props.onUpdateItem(false, 'addressIsNeeded');
      else if (s.pickUp === 'local-only') this.props.onUpdateItem(true, 'addressIsNeeded');
    }
    if (this.props.loadedItem.clearAllFields !== this.state.clearAllFields) {
      this.setState({ clearAllFields: this.props.loadedItem.clearAllFields });
      let a = this.initialState;
      a.specList = [];
      this.setState(a);

      for (let i in a.temp) {
        this.props.onUpdateTempItem(a.temp[i], i);
      }
      for (let i in a) {
        this.props.onUpdateItem(a[i], i);
      }
    }
  }

  onLoad = () => {
    if (!this.state.Loader) return;
    let { items } = this.state.Loader;
    if (!items) items = [];
    this.setState({ couponList: items });
  };

  onChangeDiscountProperty = (e, _propName, _sizeLimit, _propMsg) => {
    let newValue = e.target.value;
    if (newValue.length > _sizeLimit) return;
    if (_propName === 'discountValue') {
      newValue = newValue.replace(/,/g, '').replace('.', '');
      newValue = Number(newValue);
      if (Number.isNaN(newValue)) newValue = 0;
      if (this.state.temp.discountCurrency === '%' && (newValue > 100 || newValue < 0)) return;
      if (this.state.temp.discountCurrency === this.state.currency && newValue > Number(this.state.price)) return;
    }
    if (_propName === 'selectWhichCoupon' && newValue === 'add-new-coupon') {
      this.setState({ temp: { ...this.state.temp, showCouponModal: true } });
    } else {
      if (!this.props.onUpdateTempState) return;
      let nextTempState = Object.assign({}, this.state.temp);
      nextTempState[_propName] = newValue;

      // Copy to all listings
      if (_propName === 'selectWhichCoupon') {
        const foundCoupon = this.state.couponList.filter(v => v.itemId === newValue)

        if (foundCoupon.length) {
          nextTempState.discountValue = foundCoupon[0].couponValue;
          nextTempState.discountCurrency = foundCoupon[0].couponCurrency;
        }
      }


      if (_propName === 'discountType') {
        nextTempState.discountValue = '';
        nextTempState.discountCurrency = '%';
        nextTempState.availability = '';
      }
      if (_propName === 'discountCurrency') nextTempState.discountValue = '';
      this.props.onUpdateTempState(nextTempState);
      this.setState({ temp: nextTempState });
    }
  }

  onCloseCouponModal = _newCoupon => {
    let a = this.state.couponList;
    if (!!_newCoupon) {
      a.unshift(_newCoupon);
      if (!this.props.onUpdateTempState) return;


      // Copy to all listings
      let result =
        this.props.onUpdateTempState({
          ...this.state.temp,
          discountValue: _newCoupon.couponValue,
          discountCurrency: _newCoupon.couponCurrency,
          selectWhichCoupon: _newCoupon.itemId,
        })

      if (result) {

        this.setState({
          couponList: a,
          temp: {
            ...this.state.temp,
            discountValue: _newCoupon.couponValue,
            discountCurrency: _newCoupon.couponCurrency,
            selectWhichCoupon: _newCoupon.itemId,
            showCouponModal: false
          }
        });
      }
    } else {
      this.setState({ temp: { ...this.state.temp, showCouponModal: false } });
    }
  };

  onAddSpecSubmit = e => {
    e.preventDefault();
    this.onAddSpec({
      newSpecName: this.state.temp.specName,
      newSpecValue: this.state.temp.specValue
    });
    this.state.temp.specFocusedInput.focus();
  };

  onAddSpec = ({ newSpecName, newSpecValue }) => {
    if (!newSpecName || !newSpecValue) return;
    let _specList = this.state.specList;
    _specList.push({ specName: newSpecName, specValue: newSpecValue });
    if (!this.props.onUpdateItem) return;
    let result = this.props.onUpdateItem(_specList, "specList");
    if (result)
      this.setState({
        specList: _specList,
        temp: { ...this.state.temp, specName: "", specValue: "" }
      });
  };

  onRemoveSpec = k => {
    let _specList = this.state.specList.filter((v, i) => i !== k);
    if (!this.props.onUpdateItem) return;
    let result = this.props.onUpdateItem(_specList, "specList");
    if (result) this.setState({ specList: _specList });
  };

  onChangeSpecNameHandler = e => {
    if (e.target.value.length > 100) return;
    this.setState({ temp: { ...this.state.temp, specName: e.target.value } });
  };

  onChangeSpecValueHandler = e => {
    if (e.target.value.length > 100) return;
    this.setState({ temp: { ...this.state.temp, specValue: e.target.value } });
  };

  onChangeItem = (value, propName, limit, message) => {
    if (!this.props.onUpdateItem) return;
    if (propName === 'callForPricing' && value) {
      this.onChangeItem('no-discount', 'addDiscount', 50, 'Add Discount');
    }
    if (propName === 'addDiscount' && value === 'no-discount') {
      this.onChangeDiscountProperty({ target: { value: '' } }, 'discountType', 50, 'Discount Type');
    }
    if (propName === 'pickUp') {
      if (value === 'sell-online') {
        this.props.onUpdateItem(false, 'addressIsNeeded');
        this.props.onUpdateItem('no-address-needed', 'newAddress', 50, 'Address');
      } else this.props.onUpdateItem(true, 'addressIsNeeded');
    }
    if (propName === 'category' && value !== 'select-category') {
      let a = this.props.categoriesSelect;
      let b = [];
      let i = 0;
      for (; i < a.length; i++) {
        if (a[i] === value) break;
      }
      i++;
      while (/^\s*-+/.test(a[i]) && i < a.length) {
        b.push(a[i].replace(/^\s*-+\s*/, ''));
        i++;
      }
      this.setState({ subcategoriesSelect: [...b] })
      this.props.onUpdateItem('', 'subcategory', 50, 'Subcategory');
    }
    let result = this.props.onUpdateItem(value, propName, limit, message);
    if (result) this.setState({ [propName]: value });
  };

  onChangePriceItem = (value) => {
    value = value.replace(/,/g, '').replace('.', '');
    if (String(value).length > 12) return;
    if (!this.props.onUpdateItem) return;
    let result = this.props.onUpdateItem(value, 'price', 20, 'Price');
    if (result) this.setState({ price: value });
  }

  displayPriceValue = (value, propName) => {
    if (isNaN(value) || value === '') return '';
    if (value === '0' || value === 0) return '0';
    if (this.state.temp.discountCurrency === '%' && propName === 'discountValue') return value;
    value = String(value).split('').reverse();
    let result = [];
    for (let i = 0; i < value.length; i++) {
      if (i === 2) result.push('.');
      else if ((i > 2) && (i % 3 === 2)) result.push(',');
      result.push(value[i]);
    }
    if (result.length > 1)
      while (result[result.length - 1] === ',' || ((result[result.length - 1] === '0') && (result[result.length - 2] !== '.')))
        result.pop();
    if (value.length && value.length <= 2) result.push('.', '0');
    result = result.reverse().join('');
    return result;
  }

  importCsvFile = async (e) => {
    try {
      this.setState({ csvFileWaiting: true });
      document.getElementById('csvImportSuccess').style.display = 'none';
      document.getElementById('csvImportFailure').style.display = 'none';
      let _data = new FormData();
      _data.append('csvFile', e.target.files[0]);
      let options = {
        url: `${hostNameAndPort}/api/store/import-csv`,
        method: 'post',
        data: _data
      };

      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        // No error
        document.getElementById('csvImportSuccess').style.display = 'block';
        this.setState({ csvFileWaiting: false });
      } else {
        if (process.env.NODE_ENV === 'development') console.log(data.error);
        document.getElementById('csvImportFailure').style.display = 'block';
        this.setState({ csvFileWaiting: false });
      }
    } catch (err) {
      // Server error
      this.setState({ csvFileWaiting: false });
      document.getElementById('csvImportFailure').style.display = 'block';
      if (process.env.NODE_ENV === 'development') console.log(err);
    }
  }

  render() {

    // console.log('store edit', this.state.temp, this.state.couponList)

    let categoriesSelect = this.props.categoriesSelect.filter(v => (!/^\s*-+/.test(v))).map(v => ({ value: v, text: getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/g, ' '))) })).sort((a, b) => ((a.text > b.text) ? 1 : -1));

    return (
      <>

        {/* Super Admin CSV Update */}
        <div className="new-edit-page_section">
          <div className="new-edit-page_section-title">
            <p>PRODUCT CSV UPLOAD</p>
          </div>

          <div className="new-edit-page_section-content">
            <div className="edit-page-field edit-page-field-full">
              <label className="button button-regular" htmlFor="upload-document">Select CSV File</label>
              <input type="file" className="button button-regular upload-more-photos" id="upload-document" onChange={this.importCsvFile} />
              <LoadingSvg active={this.state.csvFileWaiting} />
              <button className="button button-regular" id="csvImportSuccess" style={{ display: 'none' }}>Import Successful</button>
              <button className="button button-sale" id="csvImportFailure" style={{ display: 'none' }}>Error On Import</button>
            </div>
          </div>
        </div>



        {/* Main Info */}
        <div className="new-edit-page_section">
          <div className="new-edit-page_section-title">
            <p>Main Info</p>
          </div>

          <div className="new-edit-page_section-content">
            <div className="edit-page-field edit-page-field-full">
              <input
                type="text"
                className="form-input-text form-input-full"
                placeholder={getTranslatedPhrase(
                  this.props.userLanguage,
                  "Product Name"
                ) + ' *'}
                value={this.state.title}
                onChange={e =>
                  this.onChangeItem(e.target.value, "title", 50, "Product Name")
                }
              />
            </div>

            <div className="edit-page-field">
              <input
                type="text"
                className="form-input-text form-input-full"
                placeholder={getTranslatedPhrase(
                  this.props.userLanguage,
                  "Brand Name"
                ) + ' *'}
                value={this.state.brandName}
                onChange={e =>
                  this.onChangeItem(e.target.value, "brandName", 30, "Brand Name")
                }
              />
            </div>

            <div className="edit-page-field">
              <div className="form-input-select">
                <select
                  value={this.state.productCondition}
                  className="form-input-full"
                  onChange={e =>
                    this.onChangeItem(
                      e.target.value,
                      "productCondition",
                      50,
                      "Condition"
                    )
                  }
                >
                  <option value="choose-condition">
                    {getTranslatedPhrase(this.props.userLanguage, "Condition") + ' *'}
                  </option>
                  <option value="New">
                    {getTranslatedPhrase(this.props.userLanguage, "New")}
                  </option>
                  <option value="Used">
                    {getTranslatedPhrase(this.props.userLanguage, "Used")}
                  </option>
                </select>
              </div>
            </div>

            <div className="edit-page-field">
              <div className="form-input-select">
                <select
                  value={this.state.category}
                  className="form-input-full"
                  onChange={e =>
                    this.onChangeItem(e.target.value, "category", 50, "Category")
                  }
                >
                  <option value="select-category">
                    {getTranslatedPhrase(
                      this.props.userLanguage,
                      "Select Category"
                    )}{" "}
                    *
                  </option>
                  {categoriesSelect.map((v, i) => (
                    <option value={v.value} key={i}>
                      {v.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {!!this.state.subcategoriesSelect && !!this.state.subcategoriesSelect.length && (
              <div className="edit-page-field">
                <div className="form-input-select">
                  <select
                    value={this.state.subcategory}
                    className="form-input-full"
                    onChange={e =>
                      this.onChangeItem(e.target.value, "subcategory", 50, "Subcategory")
                    }
                  >
                    <option value="select-subcategory">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Select Subcategory"
                      )}
                    </option>
                    {this.state.subcategoriesSelect.map((v, i) => (
                      <option value={v} key={'sub' + i}>
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          titleCase(v.replace(/-/g, " "))
                        )}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="edit-page-field">
              <div className="form-input-select">
                <select
                  value={this.state.pickUp}
                  className="form-input-full"
                  onChange={e =>
                    this.onChangeItem(e.target.value, "pickUp", 50, "Pick up")
                  }
                >
                  <option value="local-only">
                    {getTranslatedPhrase(this.props.userLanguage, "Local Only")}
                  </option>
                  <option value="sell-online">
                    {getTranslatedPhrase(this.props.userLanguage, "Online")}
                  </option>
                </select>
              </div>
            </div>

            {!(this.state.pickUp === "sell-online") ? null : (
              <div className="edit-page-field">
                <input
                  type="text"
                  className="form-input-text form-input-full"
                  placeholder={getTranslatedPhrase(
                    this.props.userLanguage,
                    "URL for buying the product, e.g. http://example.com *"
                  )}
                  value={this.state.ticketSaleURL}
                  onChange={e =>
                    this.onChangeItem(
                      e.target.value,
                      "ticketSaleURL",
                      2000,
                      "The URL"
                    )
                  }
                />
              </div>
            )}
          </div>
        </div>



        {/* Pricing & Discount */}
        <div className="new-edit-page_section">
          <div className="new-edit-page_section-title">
            <p>Pricing & Discount</p>
          </div>


          <div className="new-edit-page_section-content">
            {!this.state.callForPricing && (<>
              <div className="edit-page-field">
                <input
                  type="text"
                  className="form-input-text form-input-full"
                  placeholder={getTranslatedPhrase(
                    this.props.userLanguage,
                    "Price"
                  ) + ' *'}
                  value={this.displayPriceValue(this.state.price)}
                  onChange={e =>
                    this.onChangePriceItem(e.target.value)
                  }
                />
              </div>

              <div className="edit-page-field">
                <div className="form-input-select">
                  <select
                    value={this.state.currency}
                    className="form-input-full"
                    onChange={e =>
                      this.onChangeItem(e.target.value, "currency", 10, "Currency")
                    }
                  >
                    {currencyList.map(v => (
                      <option key={v} value={v}>{v}</option>)
                    )}
                  </select>
                </div>
              </div>

              <div className="edit-page-field">
                <div className="form-input-select">
                  <select
                    value={this.state.addDiscount}
                    className="form-input-full"
                    onChange={e =>
                      this.onChangeItem(
                        e.target.value,
                        "addDiscount",
                        50,
                        "Add Discount"
                      )
                    }
                  >
                    <option value="how-about-discount">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Add Discount?"
                      ) + ' *'}
                    </option>
                    <option value="no-discount">
                      {getTranslatedPhrase(this.props.userLanguage, "No Discount")}
                    </option>
                    <option value="add-discount">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Add New Discount"
                      )}
                    </option>
                  </select>
                </div>
              </div>

              {this.state.addDiscount === 'add-discount' && (
                <div className="edit-page-field">
                  <div className="form-input-select">
                    <select
                      value={this.state.temp.discountType}
                      className="form-input-full"
                      onChange={e =>
                        this.onChangeDiscountProperty(
                          e,
                          "discountType",
                          50,
                          "Discount Type"
                        )
                      }
                    >
                      <option value="discount-type">
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "Discount Type"
                        ) + ' *'}
                      </option>
                      <option value="discount-coupon">
                        {getTranslatedPhrase(this.props.userLanguage, "Coupon")}
                      </option>
                      <option value="discount-deal">
                        {getTranslatedPhrase(this.props.userLanguage, "Deal")}
                      </option>
                    </select>
                  </div>
                </div>
              )}

              {this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-coupon' && (
                <div className="edit-page-field">
                  <div className="form-input-select">
                    <select
                      value={this.state.temp.selectWhichCoupon}
                      className="form-input-full"
                      onChange={e =>
                        this.onChangeDiscountProperty(
                          e,
                          "selectWhichCoupon",
                          50,
                          "Select Coupon"
                        )
                      }
                    >
                      <option value="select-coupon">
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "Select Coupon *"
                        )}
                      </option>
                      <option value="add-new-coupon">
                        {getTranslatedPhrase(this.props.userLanguage, "Add New")}
                      </option>
                      {this.state.couponList.map((v, i) => (
                        <option key={"coupon-" + i} value={v.itemId}>
                          {v.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {(this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-deal') && (
                <>
                  <div className="edit-page-field">
                    <input
                      type="text"
                      className="form-input-text form-input-full"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "Discount Value *"
                      )}
                      value={this.displayPriceValue(this.state.temp.discountValue, 'discountValue')}
                      onChange={e =>
                        this.onChangeDiscountProperty(
                          e,
                          "discountValue",
                          20,
                          "Discount Value"
                        )
                      }
                    />
                  </div>

                  <div className="edit-page-field">
                    <div className="form-input-select">
                      <select
                        value={this.state.temp.discountCurrency}
                        className="form-input-full"
                        onChange={e =>
                          this.onChangeDiscountProperty(
                            e,
                            "discountCurrency",
                            50,
                            "Currency"
                          )
                        }
                      >
                        <option value="%">
                          {getTranslatedPhrase(this.props.userLanguage, "%")}
                        </option>
                        <option value={this.state.currency}>
                          {this.state.currency}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="edit-page-field">
                    <div className="form-input-select">
                      <select
                        value={this.state.temp.availability}
                        className="form-input-full"
                        onChange={e =>
                          this.onChangeDiscountProperty(
                            e,
                            "availability",
                            50,
                            "Set Availability"
                          )
                        }
                      >
                        <option value="set-availability">
                          {getTranslatedPhrase(
                            this.props.userLanguage,
                            "Set Availability *"
                          )}
                        </option>
                        <option value="choose-dates">
                          {getTranslatedPhrase(
                            this.props.userLanguage,
                            "Choose Dates"
                          )}
                        </option>
                        <option value="no-expiration-date">
                          {getTranslatedPhrase(
                            this.props.userLanguage,
                            "No Expiration Date"
                          )}
                        </option>
                      </select>
                    </div>
                  </div>
                </>
              )}

              {(this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-deal' && this.state.temp.availability === 'choose-dates') && (
                <>
                  <div className="edit-page-field">
                    <input
                      type="date"
                      className="form-input-text form-input-full"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "Start Date"
                      )}
                      value={this.state.temp.startDate}
                      onChange={e =>
                        this.onChangeDiscountProperty(
                          e,
                          "startDate",
                          20,
                          "Start Date"
                        )
                      }
                    />
                  </div>

                  <div className="edit-page-field">
                    <input
                      type="date"
                      className="form-input-text form-input-full"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "Expiration Date"
                      )}
                      value={this.state.temp.endDate}
                      onChange={e =>
                        this.onChangeDiscountProperty(
                          e,
                          "endDate",
                          20,
                          "Expiration Date"
                        )
                      }
                    />
                  </div>
                </>
              )}
            </>)}

            {/* <div className="edit-page-field edit-page-field-full">
              <div className="form-input-lists">
                <input
                  type="checkbox"
                  name="displayCallForPricing"
                  checked={this.state.callForPricing}
                  onChange={() => null}
                  style={{ display: 'none' }}
                />
                <label
                  htmlFor="displayCallForPricing"
                  style={{ paddingLeft: "16px", width: '100%' }}
                >
                  <span>
                    <i
                      className="fal fa-check"
                      onClick={e =>
                        this.onChangeItem(
                          !this.state.callForPricing,
                          "callForPricing"
                        )
                      }
                    />
                  </span>
                  {getTranslatedPhrase(
                    this.props.userLanguage,
                    "Call For Pricing"
                  )}
                </label>
              </div>
            </div> */}
          </div>
        </div>



        {/* Specifications */}
        <div className="new-edit-page_section">
          <div className="new-edit-page_section-title">
            <p>Add Specifications</p>
          </div>


          <div className="new-edit-page_section-specs">
            <div>
              <form onSubmit={this.onAddSpecSubmit}>
                <table className="addSpecTable">
                  <tbody>
                    <tr>
                      <td className="addSpecLabel">
                        <input
                          type="text" className="form-input-text form-input-full"
                          placeholder={getTranslatedPhrase(this.props.userLanguage, "ex: Height")}
                          ref={n => { if (!this.state.temp.specFocusedInput) this.setState({ temp: { ...this.state.temp, specFocusedInput: n } }) }}
                          value={this.state.temp.specName}
                          onChange={this.onChangeSpecNameHandler}
                          maxLength='20'
                        />
                      </td>
                      <td className="addSpecValue">
                        <input
                          type="text"
                          className="form-input-text form-input-full"
                          placeholder={getTranslatedPhrase(this.props.userLanguage, "ex: 4'")}
                          value={this.state.temp.specValue}
                          onChange={this.onChangeSpecValueHandler}
                          maxLength='20'
                        />
                      </td>
                      <td className="addRemoveValue">
                        <button onClick={() => this.onAddSpec({ newSpecName: this.state.temp.specName, newSpecValue: this.state.temp.specValue })} >
                          <span>Add</span> <i className="fal fa-plus-square" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>


              <table className="SubmitedSpecTable">
                <tbody>
                  {this.state.specList.map((v, i) => (
                    <tr key={i}>
                      <td className="addSpecLabel">{v.specName}</td>
                      <td className="addSpecValue">{v.specValue}</td>
                      <td className="addRemoveValue">
                        <button onClick={() => this.onRemoveSpec(i)}>
                          <i className="fal fa-minus-square" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </div>
          </div>
        </div>



        {/* Description */}
        <div className="new-edit-page_section">
          <div className="new-edit-page_section-title">
            <p>Description</p>
          </div>


          <div className="new-edit-page_section-content">
            <div className="edit-page-field edit-page-field-full">
              <textarea
                className="form-input-text form-input-full"
                placeholder={getTranslatedPhrase(
                  this.props.userLanguage,
                  "Write your product description. *"
                )}
                value={this.state.description}
                onChange={e =>
                  this.onChangeItem(
                    e.target.value,
                    "description",
                    10000,
                    "Description"
                  )
                }
              />
            </div>
          </div>
        </div>



        <CreateCouponModal
          active={this.state.temp.showCouponModal}
          onClose={this.onCloseCouponModal}
          initialPrice={this.state.price}
          oldCoupon={null}
          couponCurrency={this.state.currency}
        />
      </>
    );
  }
}

const mapStateToStoreEditProps = (
  state,
  props
) => {
  return {
    userIsAuthenticated: state.userIsAuthenticated,
    loggedInUser: state.loggedInUser,
  };
};

export default connect(mapStateToStoreEditProps, null)(ProductEditFields);