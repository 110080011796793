import React from "react";
import { Link, withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import { titleCase } from "../../../../shared/util/string";


function RealEstateListingMeta(props) {
    const userLanguage = useSelector(state => state.userLanguage);

    let subcategory = "  /  " + getTranslatedPhrase(userLanguage, titleCase(props.subcategory.replace(/-/g, " ")));


    // Andrei Check - warning says we don't use complete Url anywhere? weird, is it save to just comment it out, what does it do ? maybe I need to add it back. 

    let completeUrl;
    let newUrl = props.ticketSaleURL;

    completeUrl = newUrl;

    if (newUrl) {
        newUrl = newUrl.trim().replace(/\s/g, "");

        if (/^(:\/\/)/.test(newUrl)) {
            completeUrl = `http${newUrl}`;
        }
        if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
            completeUrl = `http://${newUrl}`;
        }
    }

    // <Link to={`/realestate/${props.categoryName}`}>

    return (
        <div className="auto-listing-title-map">
            <div className="car-meta-info">
                <h1 className="capitalize">{props.productDetailTitle}</h1>
            </div>

            <div className="mls-meta">
                by <Link className="capitalize"
                    to={"/" + props.authorSlug}><strong>{props.authorName}</strong></Link>
                &nbsp;/
                in <strong className="capitalize">{props.categoryName.replace(/-/g, " ")}</strong>
                {subcategory}
            </div>

            <div className="mls-listing-address-box">
                <p>{props.streetName === "Undisclosed" && "Undisclosed"} Listing Address</p>
                {props.propertyName && <p>Building Name: <strong>{props.propertyName}</strong></p>}
                <h4><i className="fal fa-map-marker-alt" style={{ paddingRight: '0.2rem' }} /> <a target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${props.PropertyDetailAddress}/`}>{props.PropertyDetailAddress}</a></h4>
            </div>
        </div>
    );
}


export default withRouter(RealEstateListingMeta);