import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { howRecentIsThis } from '../../../../../shared/util/date';
import { titleCase } from '../../../../../shared/util/string';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import Axios from 'axios';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../../../shared/util/lang';

class WallJobPosting extends Component {
    state = {
        jobpostingNotFound: true,
        featuredImage: '',
    }

    componentDidMount() {
        let b = this.props.jobpostingId;
        if (!b) {
            this.setState({ jobpostingNotFound: true });
            return;
        }
        let url = `${hostNameAndPort}/api/jobs/${b}`;
        Axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {                
                data.item.creationDate = (new Date(data.item.creationDate)).toDateString();
                data.item.jobpostingNotFound = false;
                this.setState(data.item);
                let _wallAction;
                switch (this.props.activityType) {
                    case 'comment':
                    _wallAction = 'commented on a job posting';
                    break;
                    case 'like':
                    _wallAction = 'liked a job posting';
                    break;
                    case 'share':
                    _wallAction = 'shared a job posting';
                    break;
                    case 'post':
                    _wallAction = 'posted a new job';
                    break;
                    default:
                    break;
                }
                this.props.onItemLoaded({
                    itemFailedLoading: false,
                    wallAction: _wallAction,
                });
            }  
            else {
                this.props.onItemLoaded({ itemFailedLoading: true });
            }
        })
        .catch(err => {
            //console.log('Network error');
            this.props.onItemLoaded({ itemFailedLoading: true });
        });
    }

    render () {
        if (this.state.jobpostingNotFound) {
            return '';
        }
        let jobpostingSlug = `/jobs/${this.state.category}/${this.state.slug}`;
        return (
            <div className="category-list_item">
                <Link to={jobpostingSlug}>
                    <img src={this.state.featuredImage} alt={this.state.title} />
                </Link>

                <div className="store-listing-preview">
                    <p className="store-listing-preview_category">{getTranslatedPhrase(this.props.userLanguage,titleCase(this.state.category.replace(/-/g,' ')))}</p>
                    <p className="store-listing-preview_title">{this.state.title}</p>
                    <p className="store-listing-preview_brand">{getTranslatedPhrase(this.props.userLanguage,'by')} {this.state.authorName}</p>

                    <div className="job-description-preview-list">
                        <p><span>{getTranslatedPhrase(this.props.userLanguage,'Posted')}:</span> {howRecentIsThis( this.state.creationDate,this.props.userLanguage )}</p>
                        <p><span>{getTranslatedPhrase(this.props.userLanguage,'Location')}:</span> {titleCase(this.state.city) + ' ' + this.state.state + ' ' + this.state.zipCode}</p>
                        <p><span>{getTranslatedPhrase(this.props.userLanguage,'Type')}:</span> {getTranslatedPhrase(this.props.userLanguage,titleCase(this.state.jobType.replace(/-/g,' ')))}</p>
                        <p><span>{getTranslatedPhrase(this.props.userLanguage,'Experience')}:</span> {getTranslatedPhrase(this.props.userLanguage,titleCase(this.state.experienceLevel.replace(/-/g,' ')))}</p>
                    </div>

                    <div className="job-description-actions">
                        <Link to={jobpostingSlug}>
                            <button className="button button-clear button-small">{getTranslatedPhrase(this.props.userLanguage,'Learn More')}</button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToWallJobPostingProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToWallJobPostingProps, null)(WallJobPosting);
