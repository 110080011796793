import React, { Component } from "react";
import { titleCase } from '../../../../shared/util/string';
import { getTranslatedPhrase } from '../../../../shared/util/lang';


export default class JobEditFields extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state = {
            title: '',
            slug: '',
            jobType: '',
            experienceLevel: '',
            description: '',
            privacy: 'public',
            department: '',
            category: '',
            subcategory: '',
            subcategoriesSelect: [],
            responsibilities: '',
            requiredQualifications: '',
            preferredQualifications: '',
            alreadyLoadedItem: false,
            temp: {}
        };
    }

    componentWillUpdate() {

        console.log('here');

        if (!this.state.alreadyLoadedItem && this.props.loadedItem.title && this.props.categoriesSelect.length) {
            let s = { ...this.state };
            for (let i in s) {
                if (s.hasOwnProperty(i))
                    s[i] = this.props.loadedItem[i];
            }
            s.alreadyLoadedItem = true;
            // Subcategories
            let a = this.props.categoriesSelect;
            let b = [];
            let i = 0;
            for (; i < a.length; i++) {
                if (a[i] === s.category) break;
            }
            i++;
            while (/^\s*-+/.test(a[i]) && i < a.length) {
                b.push(a[i].replace(/^\s*-+\s*/, ''));
                i++;
            }
            s.subcategoriesSelect = [...b];
            this.setState(s);
        }
        if (this.props.loadedItem.clearAllFields !== this.state.clearAllFields) {
            this.setState({ clearAllFields: this.props.loadedItem.clearAllFields });
            let a = this.initialState;
            this.setState(a);

            for (let i in a) {
                this.props.onUpdateItem(a[i], i);
            }
        }
    }

    onChangeItem = (value, propName, limit, message) => {
        if (!this.props.onUpdateItem) return;
        if (propName === 'category' && value !== 'select-category') {
            let a = this.props.categoriesSelect;
            let b = [];
            let i = 0;
            for (; i < a.length; i++) {
                if (a[i] === value) break;
            }
            i++;
            while (/^\s*-+/.test(a[i]) && i < a.length) {
                b.push(a[i].replace(/^\s*-+\s*/, ''));
                i++;
            }
            this.setState({ subcategoriesSelect: [...b] })
            this.props.onUpdateItem('', 'subcategory', 50, 'Subcategory');
        }
        let result = this.props.onUpdateItem(value, propName, limit, message);
        if (result) this.setState({ [propName]: value });
    }

    render() {
        let categoriesSelect = this.props.categoriesSelect.filter(v => (!/^\s*-+/.test(v))).map(v => ({ value: v, text: getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/g, ' '))) })).sort((a, b) => ((a.text > b.text) ? 1 : -1));
        return (<>


            {/* Main Info */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Main Info</p>
                </div>

                <div className="new-edit-page_section-content">
                    <div className="edit-page-field edit-page-field-full">
                        <input className="form-input-text form-input-full" type="text" value={this.state.title} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Job Title') + ' *'}
                            onChange={(e) => this.onChangeItem(e.target.value, 'title', 50, 'The job title')} />
                    </div>

                    <div className="edit-page-field">
                        <input className="form-input-text form-input-full" type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Department') + ' *'} value={this.state.department} onChange={e => this.onChangeItem(e.target.value, 'department', 30, 'Department')} />
                    </div>

                    <div className="edit-page-field">
                        <div className="form-input-select">
                            <select className="form-input-full" value={this.state.jobType} onChange={(e) => this.onChangeItem(e.target.value, 'jobType', 40, getTranslatedPhrase(this.props.userLanguage, 'Job Type'))}>
                                <option value="job-type">{getTranslatedPhrase(this.props.userLanguage, 'Job Type')} *</option>
                                <option value="full-time">{getTranslatedPhrase(this.props.userLanguage, 'Full Time')}</option>
                                <option value="part-time">{getTranslatedPhrase(this.props.userLanguage, 'Part Time')}</option>
                                <option value="internship">{getTranslatedPhrase(this.props.userLanguage, 'Internship')}</option>
                                <option value="contract">{getTranslatedPhrase(this.props.userLanguage, 'Contract')}</option>
                                <option value="temp-project">{getTranslatedPhrase(this.props.userLanguage, 'Temp / Project')}</option>
                                <option value="commission">{getTranslatedPhrase(this.props.userLanguage, 'Commission')}</option>
                                <option value="volunteer">{getTranslatedPhrase(this.props.userLanguage, 'Volunteer')}</option>
                            </select>
                        </div>
                    </div>

                    <div className="edit-page-field">
                        <div className="form-input-select">
                            <select className="form-input-full" value={this.state.experienceLevel} onChange={(e) => this.onChangeItem(e.target.value, 'experienceLevel', 50, getTranslatedPhrase(this.props.userLanguage, 'Experience Level'))}>
                                <option value="experience-level">{getTranslatedPhrase(this.props.userLanguage, 'Experience Level')} *</option>
                                <option value="entry-level">{getTranslatedPhrase(this.props.userLanguage, 'Entry Level')}</option>
                                <option value="mid-level">{getTranslatedPhrase(this.props.userLanguage, 'Mid Level')}</option>
                                <option value="senior">{getTranslatedPhrase(this.props.userLanguage, 'Senior')}</option>
                                <option value="director">{getTranslatedPhrase(this.props.userLanguage, 'Director')}</option>
                                <option value="vp">{getTranslatedPhrase(this.props.userLanguage, 'VP')}</option>
                            </select>
                        </div>
                    </div>

                    <div className="edit-page-field">
                        <div className="form-input-select">
                            <select className="form-input-full" value={this.state.category} onChange={(e) => this.onChangeItem(e.target.value, 'category', 50, getTranslatedPhrase(this.props.userLanguage, 'Category'))}>
                                <option value="select-category">{getTranslatedPhrase(this.props.userLanguage, 'Select Category')} *</option>
                                {categoriesSelect.map((v, i) => (<option key={i} value={v.value}>{v.text}</option>))}
                            </select>
                        </div>
                    </div>

                    {!!this.state.subcategoriesSelect && !!this.state.subcategoriesSelect.length && (
                        <div className="edit-page-field">
                            <div className="form-input-full form-input-select">
                                <select value={this.state.subcategory} onChange={(e) => this.onChangeItem(e.target.value, 'subcategory', 50, getTranslatedPhrase(this.props.userLanguage, 'Subcategory'))}>
                                    <option value="select-subcategory">{getTranslatedPhrase(this.props.userLanguage, 'Select Subcategory')} *</option>
                                    {this.state.subcategoriesSelect.map((v, i) => (<option key={'sub' + i} value={v}>{getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/g, ' ')))}</option>))}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            </div>



            {/* Description */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Job Description</p>
                </div>


                <div className="new-edit-page_section-content">
                    <div className="edit-page-field edit-page-field-full">
                        <textarea className="form-input-textarea form-input-full" value={this.state.description} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Job Description') + ' *'}
                            onChange={(e) => this.onChangeItem(e.target.value, 'description', 10000, getTranslatedPhrase(this.props.userLanguage, 'Job Description'))}>
                        </textarea>
                    </div>

                    <div className="edit-page-field edit-page-field-full">
                        <textarea className="form-input-textarea form-input-full" value={this.state.responsibilities} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Responsibilities') + ' (' + getTranslatedPhrase(this.props.userLanguage, 'one per line') + ')'}
                            onChange={(e) => this.onChangeItem(e.target.value, 'responsibilities', 10000, 'The job responsibilities')}>
                        </textarea>
                    </div>

                    <div className="edit-page-field edit-page-field-full">
                        <textarea className="form-input-textarea form-input-full" value={this.state.requiredQualifications} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Required Qualifications') + ' (' + getTranslatedPhrase(this.props.userLanguage, 'one per line') + ')'}
                            onChange={(e) => this.onChangeItem(e.target.value, 'requiredQualifications', 10000, 'The job required qualifications')}>
                        </textarea>
                    </div>

                    <div className="edit-page-field edit-page-field-full">
                        <textarea className="form-input-textarea form-input-full" value={this.state.preferredQualifications} placeholder={getTranslatedPhrase(this.props.userLanguage, 'Preferred Qualifications') + ' (' + getTranslatedPhrase(this.props.userLanguage, 'one per line') + ')'}
                            onChange={(e) => this.onChangeItem(e.target.value, 'preferredQualifications', 10000, 'The job preferred qualifications')}>
                        </textarea>
                    </div>
                </div>
            </div>
        </>);
    }
}