import React, { Component } from 'react';
import { getTranslatedPhrase } from '../../../util/lang';
import { splitBigLine } from '../../../util/string';
import {connect} from 'react-redux';
import Box from '../../../util/box';

class DescriptionBox extends Component {
    state = {
        size: 200,
        buttonLabel: 'Read More',
        userLanguage: '',
    }

    componentDidUpdate() {
        let v = this.props.userLanguage;
        if (this.state.userLanguage !== v) {
            this.setState({ 
                userLanguage: v, 
                buttonLabel: getTranslatedPhrase(this.props.userLanguage, this.state.buttonLabel)
            });
        }
    }

    toggleAllDescription = () => {
        let n = this.state.size;
        let s;
        if ( n === 200) {
            n = this.props.descriptionBoxText.length;
            s = getTranslatedPhrase(this.props.userLanguage,'Read Less');
        } else {
            n = 200;
            s = getTranslatedPhrase(this.props.userLanguage,'Read More');
        }
        this.setState({ size: n, buttonLabel: s });
    }

    render () {
        return (
            <div className="main-sidebar_box">
                <h4>{this.props.descriptionBoxTitle}</h4>

                <div>
                    {this.props.descriptionBoxText.substring(0, this.state.size).split(/\n/g)
                        .map( (vi) => (<Box key={vi}> 
                                {vi.split(' ')
                                .map( (v,i) => (<Box key={i}>{splitBigLine(v,30)
                                            .map( (v2,i) => (<Box key={i}>{v2}{v2.length>=30?<br />:null}</Box>) )}
                                            {' '}
                                            </Box>)
                                        )}
                                <br />
                            </Box>)
                    )}
                </div>
                {(this.state.size < this.props.descriptionBoxText.length)?(
                <button onClick={this.toggleAllDescription} className="show-more-link">{this.state.buttonLabel}</button>)
                :null}
            </div>
        );
    }
}   

const mapStateToDescriptionBoxProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToDescriptionBoxProps, null)(DescriptionBox);