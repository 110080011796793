import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import { Helmet } from "react-helmet";

import TeamMember from "./components/TeamMember";
import ChrisPopescu from "./Assets/mtt_chrispopescu.jpg";
import FelixMacovei from "./Assets/mtt_felixmacovei.jpg";
import PopescuCozmin from "./Assets/mtt_popescucozmin.jpg";
import SamiBela from "./Assets/mtt_samibela.jpg";
import AureliaOtonoga from "./Assets/mtt_aureliaotonoga.jpg";
import MarianaAntonescu from "./Assets/mtt_marianaantonescu.jpg";
import IosifLuta from "./Assets/mtt_iosifluta.jpg";
import AlinRujoni from "./Assets/mtt_alinrujoni.jpg";
import NeluTermure from "./Assets/mtt_nelutermure.jpg";

const MeetTheTeam = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userLanguage = useSelector((state) => state.userLanguage);

  const currentYear = new Date().getFullYear();

  return (
    <div className="main-100 meet-the-team">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Meet The Team | Followone</title>
        <meta
          name="description"
          content="Meet the Followone Team. Together we make awesome things happen."
        />
        <link rel="canonical" href="https://followone.com/meet-the-team" />
      </Helmet>

      <div className="main-100-header">
        <h1>{getTranslatedPhrase(userLanguage, "Executives & Directors")}</h1>
        <p>
          {getTranslatedPhrase(
            userLanguage,
            "Our executive team is composed of experience and creative dedicated professionals focused on delivering outstanding services using the latest available techonlogy."
          )}
        </p>
      </div>

      <h2>{getTranslatedPhrase(userLanguage, "Executives")}</h2>

      <div className="the-team">
        <div className="the-team_section">
          <TeamMember
            image={ChrisPopescu}
            name="Chris Popescu"
            role="Chief Executive Officer, Director, (Chairman of the Board)"
            location="2014 - Present, Followone Inc, Bellevue WA"
            points={[
              `${
                currentYear - 2003
              }+ years of successful business experience and leadership.`,
              "Expert in strategic planning & implementation.",
              "Focused on maximizing revenue and productivity ensuring strong future growth.",
            ]}
          />

          <TeamMember
            image={PopescuCozmin}
            name="Cozmin Popescu"
            role="Vice President"
            location="2017 - Present, Followone Inc, Bucharest Romania"
            points={[
              "Bachelor of Business Management",
              `${currentYear - 2014}+ years management experience with strong
            sales & leadership skills.`,
              "Succesfull in creating & maintaning great relationships with our service providers.",
              "Proficient in hiring great employees and creating training materials.",
            ]}
          />

          <TeamMember
            image={FelixMacovei}
            name="Felix Macovei"
            role="CTO, COO"
            location="2017 - Present, Followone Inc, Bellevue WA"
            points={[
              `${
                currentYear - 2005
              }+ years of experience in UX design, graphic design, 
            front end development & project manangement.`,
              "7 years experience working for Lowes as lead graphic designer, marketing coordinator & front-end dev.",
              "Masters Degree in UX / HCI (Human Computer Interaction), GPA 4.0.",
              "Bachelor of Science - BS, Information Technology",
            ]}
          />

          <TeamMember
            image={MarianaAntonescu}
            name="Mariana Antonescu"
            role="Healthcare Executive Director"
            location="2021 - Present, Followone Inc, Bellevue WA"
            points={[
              `20 years of experience in healthcare management and business leadership.`,
            ]}
          />

          <TeamMember
            image={AureliaOtonoga}
            name="Aurelia Otonoga"
            role="Real Estate Executive Broker"
            location="2020 - Present, Followone Inc, Bellevue WA"
            points={[
              `${
                currentYear - 2004
              }+ years of experience managing and representing clients for some of the largest Real Estate Firms in the U.S.`,
              "Successfully closed over 109 real estate transactions to date.",
              "Highly experienced in commercial and residential real estate agency representation, transactions, and sales.",
            ]}
          />

         

          
        </div>
      </div>

      <h2>{getTranslatedPhrase(userLanguage, "Directors")}</h2>

      <div className="the-team">
        <div className="the-team_section">
          

        <TeamMember
            image={AlinRujoni}
            name="Alin Rujoni"
            role="Investor Relations Director"
            location="2021 - Present, Followone Inc, Bellevue WA"
            points={[
              `20 years of business management in healthcare,`,
              "17 years of real-estate experience, and investment management.",
            ]}
          />


          <TeamMember
            image={IosifLuta}
            name="Iosif Luta"
            role="Independent Director"
            location="2021 - Present, Followone Inc, Bellevue WA"
            points={[
              `20 years of successful business experience in healthcare, real estate, construction and investment.`,
            ]}
          />

          

          <TeamMember
            image={NeluTermure}
            name="Nelu Termure"
            role="Independent Director"
            location="2021 - Present, Followone Inc, Bellevue WA"
            points={[
              `12+ years of experience in business management and commercial real estate.`,
            ]}
          />

          <TeamMember
            image={SamiBela}
            name="Samuel Bela"
            role="Independent Director"
            location="2015 - Present, Followone Inc, Bellevue WA"
            points={[
              `15+ years of experience in commercial construction projects.`,
              "Early angel investor.",
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default MeetTheTeam;
