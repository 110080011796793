import React from 'react';
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import { connect } from "react-redux";

const RealEstateSpecs = props => {
    if (props.isMlsBiz) {
        return (
          <div className="ProductDetailBox ProductDetailSpecs">

              <h4>{getTranslatedPhrase(props.userLanguage, 'Financial Information')}</h4>

              <table className="mgb-lg">
                  <tbody>
                  {props.financialInfoSpecList.map((v, i) => (!v.specValue) ? null : (<tr key={v.specName + '-' + i}>
                      <td>{getTranslatedPhrase(props.userLanguage, v.specName)}</td>
                      <td>{getTranslatedPhrase(props.userLanguage, v.specValue)}</td>
                  </tr>))}
                  </tbody>
              </table>

              <h4>{getTranslatedPhrase(props.userLanguage, 'BUSINESS INFORMATION')}</h4>

              <table>
                  <tbody>
                  {props.businessOppSpecList.map((v, i) => (!v.specValue) ? null : (<tr key={v.specName + '-' + i}>
                      <td>{getTranslatedPhrase(props.userLanguage, v.specName)}</td>
                      <td>{getTranslatedPhrase(props.userLanguage, v.specValue)}</td>
                  </tr>))}
                  </tbody>
              </table>
          </div>
        )
    } else {
        return (
          <div className="ProductDetailBox ProductDetailSpecs">
              <h4>{(props.specList && props.specList.length)
                ? getTranslatedPhrase(props.userLanguage, 'Specifications')
                : getTranslatedPhrase(props.userLanguage, 'No Specifications')
              }</h4>

              <table>
                  <tbody>
                  {props.specList.map( (v,i) => (!v.specValue) ? null : (<tr key={v.specName+'-'+i}>
                      <td style={{verticalAlign: 'top'}}>{getTranslatedPhrase(props.userLanguage, v.specName)}</td>
                      <td style={{whiteSpace: 'pre-wrap'}}>{getTranslatedPhrase(props.userLanguage, v.specValue)}</td>
                  </tr>))}
                  </tbody>
              </table>

              {props.availableStatus === 'Sold' &&
                <>
                    <h4 className="mgt-lg">{ getTranslatedPhrase(props.userLanguage, 'SELLING INFO') }</h4>

                    <table>
                        <tbody>
                        {props.soldInfo.map( (v,i) => (!v.specValue) ? null : (<tr key={v.specName+'-'+i}>
                            <td>{getTranslatedPhrase(props.userLanguage, v.specName)}</td>
                            <td>{getTranslatedPhrase(props.userLanguage, v.specValue)}</td>
                        </tr>))}
                        </tbody>
                    </table>
                </>
              }
          </div>
        )
    }
}

const mapStateToProps = (
  state,
  props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToProps)(RealEstateSpecs);