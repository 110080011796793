import React, { useState } from 'react';
import ProductPreview from './ProductPreview';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import StoreSideBar from './StoreSideBar';
import StoreSideBarMobile from './StoreSideBarMobile';
import StoreFeatured from './StoreFeatured';
import StoreHeader from '../../Home/Components/Store';
import feaImage from '../../../assets/category/followone_cat_feature_store.jpg';

const StoreCategory = () => {
  const [categoryList, setCategoryList] = useState([]);

  function onUpdateCategories(c) {
    if (!categoryList.length)
      setCategoryList(c);
  }

  return (
    <>
      <div className="category-search-panel" style={{ backgroundImage: `url(${feaImage})` }}>
        <StoreHeader category section="store" categoryList={categoryList} />
      </div>

      <CategoryPage
        section="store"
        description="Look for Products, or Promote Your Own"
        ItemPreview={ProductPreview}
        SideBar={(props) => (<StoreSideBar onLoadCategories={onUpdateCategories} {...props} />)}
        SideBarMobile={(props) => (<StoreSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
        FeaturedItems={StoreFeatured}
      />
    </>
  )
}

export default StoreCategory;