import React, {Component} from 'react';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';
import Box from '../../../util/box';
import moment from "moment";

class ListingDescription extends Component {
    render() {
        if (!this.props.show) {
            return null;
        }
        if (!this.props.productDescription) {
            return (
                <div className="ProductDetailBox ProductDetailDescription">
                    <h4>{getTranslatedPhrase(this.props.userLanguage,'Description')}</h4>
                    {getTranslatedPhrase(this.props.userLanguage,'There is no description')}
                </div>
            );
        }
        return (
            <div className="ProductDetailBox ProductDetailDescription">
                <h4>{getTranslatedPhrase(this.props.userLanguage,'Description')}</h4>
                <p>{this.props.productDescription.split('\n').map( (v,i) => (<Box key={'line-'+i}>{v}<br /></Box>) )}</p>

                {!this.props.callForPricing && !!this.props.addDiscount && this.props.addDiscount === "deal" &&  (
                  <>
                    <p className="mgt-md" style={{marginBottom: 0}}><strong>{getTranslatedPhrase(this.props.userLanguage,'Discount Terms & Conditions')}</strong></p>
                    <p>
                        {moment(this.props.discountId.endDate).isAfter(moment("2190-01-01"))
                          ? getTranslatedPhrase(this.props.userLanguage, 'Deal expires at the discretion of the seller.')
                          : getTranslatedPhrase(this.props.userLanguage, 'Discount valid through: ')+ ": " + moment(this.props.discountId.endDate).format('L')}
                    </p>
                  </>
                )}
            </div>
        );
    }
}

const mapStateToListingDescriptionProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToListingDescriptionProps, null)(ListingDescription);