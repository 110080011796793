import React, { Component } from 'react';
import ListLoader from '../../../../shared/util/list-loader';
import { titleCase } from '../../../../shared/util/string';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import CreateCouponModal from '../../../../shared/components/Modals/Account/CreateCouponModal';
import { currencyList } from '../../../../shared/util/string';
import moment from 'moment';
const DEFAULT_SPEC_LIST = ['Bedrooms',
    'Bathrooms',
    'Floor Size SQ Feet',
    'Year Built', // 3
    'Parking Spaces',
    'Garage', //5
    'HOA/Month',
    'USD',
    'Basement',
    'A/C', //9
    'Accessible',
    'Pool',
    'Lot Size Acres',
    'Price/sqft',
    'Fitness', //14
    'View Water',
    'View Mountain',
    'View City',
    'View Park'];//18
// let specsNotRequired = true;

export default class PropertyEditFields extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state = {
            title: '',
            brandName: '',
            ticketSaleURL: '',
            moreSpecs: [],
            category: 'select-category',
            subcategory: '',
            subcategoriesSelect: [],
            description: '',
            price: '',
            currency: 'USD',
            callForPricing: '',
            specList: DEFAULT_SPEC_LIST.map(v => ({ specName: v, specValue: '' })),
            couponList: [],
            addDiscount: 'how-about-discount',
            discountId: '',
            rentOrSell: '',
            bedCount: '',
            bathCount: '',
            floorSize: '',
            alreadyLoadedItem: false,
            temp: {
                specName: '',
                specValue: '',
                selectWhichCoupon: '',
                discountType: '',
                discountValue: '',
                discountCurrency: '%',
                availability: '',
                endDate: moment().format('YYYY-MM-DD'),
                startDate: moment().format('YYYY-MM-DD'),
                showCouponModal: false
            }
        };
    }

    componentDidMount() {
        let Loader = new ListLoader({
            cb: this.onLoad,
            options: {
                delay: 3000,
                count: 20,
                request: {
                    url: '/api/coupons/items',
                    method: 'post',
                    formParams: { filter: JSON.stringify({ author: '', isDeal: false }) }
                }
            }
        });
        Loader.init();
        this.setState({ Loader });
        this.onChangeItem('new-address', 'newAddress');
        setTimeout(() => {
            this.forceUpdate();
        }, 2000);
    }

    componentDidUpdate() {
        if (!this.state.alreadyLoadedItem && this.props.loadedItem.title && this.props.categoriesSelect.length) {
            let s = { ...this.state };
            let { Loader, couponList } = this.state;
            for (let i in s) {
                if (s.hasOwnProperty(i))
                    s[i] = this.props.loadedItem[i];
            }
            s.Loader = Loader;
            s.couponList = couponList;
            s.alreadyLoadedItem = true;

            // Subcategories
            let a = this.props.categoriesSelect;
            let b = [];
            let i = 0;
            for (; i < a.length; i++) {
                if (a[i] === s.category) break;
            }
            i++;
            while (/^\s*-+/.test(a[i]) && i < a.length) {
                b.push(a[i].replace(/^\s*-+\s*/, '').replace(/_/g, '/'));
                i++;
            }
            s.subcategoriesSelect = [...b];
            if (s.specList.length < DEFAULT_SPEC_LIST.length) {
                if (!s.moreSpecs) s.moreSpecs = [];
                s.moreSpecs.push(...s.specList);
                s.specList = DEFAULT_SPEC_LIST.map(v => ({ specName: v, specValue: '' }));
            }
            this.setState(s);
        }
        if (this.props.loadedItem.clearAllFields !== this.state.clearAllFields) {
            this.setState({ clearAllFields: this.props.loadedItem.clearAllFields });
            let a = this.initialState;
            a.moreSpecs = [];
            a.specList = DEFAULT_SPEC_LIST.map(v => ({ specName: v, specValue: '' }));
            this.setState(a);

            for (let i in a.temp) {
                this.props.onUpdateTempItem(a.temp[i], i);
            }
            for (let i in a) {
                this.props.onUpdateItem(a[i], i);
            }
        }
    }

    onLoad = () => {
        if (!this.state.Loader) return;
        let { items } = this.state.Loader;
        if (!items) items = [];
        this.setState({ couponList: items });
    }

    onChangeDiscountProperty = (e, _propName, _sizeLimit, _propMsg) => {
        let newValue = e.target.value;
        if (newValue.length > _sizeLimit) return;
        if (_propName === 'discountValue') {
            newValue = newValue.replace(/,/g, '').replace('.', '');
            newValue = Number(newValue);
            if (Number.isNaN(newValue)) newValue = 0;
            if (this.state.temp.discountCurrency === '%' && (newValue > 100 || newValue < 0)) return;
            if (this.state.temp.discountCurrency === this.state.currency && newValue > Number(this.state.price)) return;
        }
        if (_propName === 'selectWhichCoupon' && newValue === 'add-new-coupon') {
            this.setState({ temp: { ...this.state.temp, showCouponModal: true } });
        } else {
            if (!this.props.onUpdateTempState) return;
            let nextTempState = Object.assign({}, this.state.temp);
            nextTempState[_propName] = newValue;

            // Copy to all listings
            if (_propName === 'selectWhichCoupon') {
                const foundCoupon = this.state.couponList.filter(v => v.itemId === newValue)

                if (foundCoupon.length) {
                    nextTempState.discountValue = foundCoupon[0].couponValue;
                    nextTempState.discountCurrency = foundCoupon[0].couponCurrency;
                }
            }


            if (_propName === 'discountType') {
                nextTempState.discountValue = '';
                nextTempState.discountCurrency = '%';
                nextTempState.availability = '';
            }
            if (_propName === 'discountCurrency') nextTempState.discountValue = '';
            this.props.onUpdateTempState(nextTempState);
            this.setState({ temp: nextTempState });
        }
    }

    onCloseCouponModal = _newCoupon => {
        let a = this.state.couponList;
        if (!!_newCoupon) {
            a.unshift(_newCoupon);
            if (!this.props.onUpdateTempState) return;


            // Copy to all listings
            let result =
                this.props.onUpdateTempState({
                    ...this.state.temp,
                    discountValue: _newCoupon.couponValue,
                    discountCurrency: _newCoupon.couponCurrency,
                    selectWhichCoupon: _newCoupon.itemId,
                })

            if (result) {

                this.setState({
                    couponList: a,
                    temp: {
                        ...this.state.temp,
                        discountValue: _newCoupon.couponValue,
                        discountCurrency: _newCoupon.couponCurrency,
                        selectWhichCoupon: _newCoupon.itemId,
                        showCouponModal: false
                    }
                });
            }
        } else {
            this.setState({ temp: { ...this.state.temp, showCouponModal: false } });
        }
    };

    // onAddSpecSubmit = (e) => {
    //     e.preventDefault();
    //     this.onAddSpec({ newSpecName: this.state.temp.specName, newSpecValue: this.state.temp.specValue });
    //     this.state.temp.specFocusedInput.focus();
    // }

    onAddSpec = ({ newSpecName, newSpecValue }) => {
        if (!newSpecValue) return;

        let moreSpecs = [...this.state.moreSpecs];
        moreSpecs.push({ specName: newSpecName, specValue: newSpecValue });
        if (!this.props.onUpdateItem) return;
        let result = this.props.onUpdateItem(moreSpecs, 'moreSpecs');
        if (result) this.setState({ moreSpecs, temp: { ...this.state.temp, specName: '', specValue: '' } });
    }

    onRemoveSpec = (k) => {
        let moreSpecs = this.state.moreSpecs;
        moreSpecs.splice(k);
        this.onChangeItem(moreSpecs, 'moreSpecs');
    }

    onChangeSpecListItem = ({ newSpecName, newSpecValue }, i) => {
        if (i === 3) {
            if (newSpecValue.length > 4) return;
            if (Number(newSpecValue) > new Date().getFullYear()) return;
        }
        let _specList = [...this.state.specList];
        _specList[i] = { specName: newSpecName, specValue: newSpecValue };
        // Special case HOA/Month
        if (i === 6 && !newSpecValue) _specList[7] = { specName: _specList[7].specName, specValue: '' };
        this.onChangeItem(_specList, 'specList');
    }

    onChangeSpecNameHandler = (e) => {
        if (e.target.value.length > 100) return;
        this.setState({ temp: { ...this.state.temp, specName: e.target.value } });
    }

    onChangeSpecValueHandler = (e) => {
        if (e.target.value.length > 100) return;
        this.setState({ temp: { ...this.state.temp, specValue: e.target.value } });
    }

    onChangeItem = (value, propName, limit, message) => {
        if (!this.props.onUpdateItem) return;
        if (propName === 'callForPricing' && value) {
            this.onChangeItem('no-discount', 'addDiscount', 50, 'Add Discount');
        }
        if (propName === 'addDiscount' && value === 'no-discount') {
            this.onChangeDiscountProperty({ target: { value: '' } }, 'discountType', 50, 'Discount Type');
        }
        if (propName === 'category' && value !== 'residential') {
            this.props.onUpdateItem('', 'bedCount', 10, 'Bedrooms');
            this.props.onUpdateItem('', 'bathCount', 10, 'Bathrooms');
            this.setState({ bedCount: '', bathCount: '' });
        }

        if (propName === 'category' && value === 'business-opportunities') {
            this.props.onUpdateItem('sell', 'rentOrSell', 10, 'Sale');
            this.setState({ rentOrSell: 'sell' })
        }

        if (propName === 'category' && value !== 'select-category') {
            let a = this.props.categoriesSelect;
            let b = [];
            let i = 0;
            for (; i < a.length; i++) {
                if (a[i] === value) break;
            }
            i++;
            while (/^\s*-+/.test(a[i]) && i < a.length) {
                b.push(a[i].replace(/_/g, '/').replace(/^\s*-+\s*/, ''));
                i++;
            }
            this.setState({ subcategoriesSelect: [...b] })
            this.onChangeItem('', 'subcategory', 50, 'Subcategory');
            this.onChangeItem(DEFAULT_SPEC_LIST.map(v => ({ specName: v, specValue: '' })), 'specList');
            this.onChangeItem([], 'moreSpecs');
        }
        let result = this.props.onUpdateItem(value, propName, limit, message);
        if (result) this.setState({ [propName]: value });
    }

    onChangePriceItem = (value) => {
        value = value.replace(/,/g, '').replace('.', '');
        if (String(value).length > 12) return;
        if (!this.props.onUpdateItem) return;
        let result = this.props.onUpdateItem(value, 'price', 20, 'Price');
        if (result) this.setState({ price: value });
    }

    displayPriceValue = (value, propName) => {
        if (isNaN(value) || value === '') return '';
        if (value === '0' || value === 0) return '0';
        if (this.state.temp.discountCurrency === '%' && propName === 'discountValue') return value;
        value = String(value).split('').reverse();
        let result = [];
        for (let i = 0; i < value.length; i++) {
            if (i === 2) result.push('.');
            else if ((i > 2) && (i % 3 === 2)) result.push(',');
            result.push(value[i]);
        }
        if (result.length > 1)
            while (result[result.length - 1] === ',' || ((result[result.length - 1] === '0') && (result[result.length - 2] !== '.')))
                result.pop();
        if (value.length && value.length <= 2) result.push('.', '0');
        result = result.reverse().join('');
        return result;
    }

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    render() {
        let categoriesSelect = this.props.categoriesSelect.filter(v => (!/^\s*-+/.test(v))).map(v => ({ value: v, text: getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/g, ' '))) })).sort((a, b) => ((a.text > b.text) ? 1 : -1));
        // specsNotRequired = ['gas-stations', 'health-care', 'industrial-properties', 'land', 'office-space', 'other', 'restaurants', 'self-storage', 'showrooms', 'stores', 'warehouses'].indexOf(this.state.category) >= 0;
        return (<>


            {/* Main Info */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Main Info</p>
                </div>

                <div className="new-edit-page_section-content">
                    <div className="edit-page-field edit-page-field-full">
                        <input className="form-input-text form-input-full" type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Title') + ' *'} value={this.state.title} onChange={e => this.onChangeItem(e.target.value, 'title', 50, 'Title')} />
                    </div>

                    <div className="edit-page-field">
                        <div className="form-input-select">
                            <select className="form-input-full" value={this.state.category} onChange={e => this.onChangeItem(e.target.value, 'category', 50, 'The type of property')}>
                                <option value="select-category">{getTranslatedPhrase(this.props.userLanguage, 'Select Type')} *</option>
                                {categoriesSelect.map((v, i) => (<option key={i} value={v.value}>{v.text}</option>))}
                            </select>
                        </div>
                    </div>

                    {!!this.state.subcategoriesSelect && !!this.state.subcategoriesSelect.length && (
                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select className="form-input-full" value={this.state.subcategory} onChange={e => this.onChangeItem(e.target.value, 'subcategory', 50, 'The subtype of property')}>
                                    <option value="select-subcategory">{getTranslatedPhrase(this.props.userLanguage, 'Select Sub-type')} *</option>
                                    {this.state.subcategoriesSelect.map((v, i) => (<option key={'sub' + i} value={v}>{getTranslatedPhrase(this.props.userLanguage, v)}</option>))}
                                </select>
                            </div>
                        </div>
                    )}

                    {this.state.category !== 'business-opportunities' && (
                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select className="form-input-full" value={this.state.rentOrSell} onChange={e => this.onChangeItem(e.target.value, 'rentOrSell', 10, 'Renting or selling')}>
                                    <option value="sell-rent">{getTranslatedPhrase(this.props.userLanguage, 'Sell or Rent/Lease?')} *</option>
                                    <option value="sell">{getTranslatedPhrase(this.props.userLanguage, 'Sell')}</option>
                                    <option value="rent">{getTranslatedPhrase(this.props.userLanguage, 'Rent/Lease')}</option>
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            </div>



            {/* Pricing & Discount */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Pricing & Discount</p>
                </div>


                <div className="new-edit-page_section-content">
                    {!this.state.callForPricing && (<>
                        <div className="edit-page-field">
                            <input className="form-input-text form-input-full" type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Price') + ' *'}
                                value={this.displayPriceValue(this.state.price)}
                                onChange={e => this.onChangePriceItem(e.target.value)} />
                        </div>

                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select className="form-input-full" value={this.state.currency} onChange={(e) => this.onChangeItem(e.target.value, 'currency', 10, 'The currency')} >
                                    {currencyList.map(v => (
                                        <option key={v} value={v}>{v}</option>)
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="edit-page-field">
                            <div className="form-input-select">
                                <select className="form-input-full" value={this.state.addDiscount} onChange={(e) => this.onChangeItem(e.target.value, 'addDiscount', 50, 'Add Discount')}>
                                    <option value="how-about-discount">{getTranslatedPhrase(this.props.userLanguage, 'Add Discount?')} *</option>
                                    <option value="no-discount">{getTranslatedPhrase(this.props.userLanguage, 'No Discount')}</option>
                                    <option value="add-discount">{getTranslatedPhrase(this.props.userLanguage, 'Add New Discount')}</option>
                                </select>
                            </div>
                        </div>

                        {this.state.addDiscount === 'add-discount' && (
                            <div className="edit-page-field">
                                <div className="form-input-select">
                                    <select className="form-input-full" value={this.state.temp.discountType} onChange={(e) => this.onChangeDiscountProperty(e, 'discountType', 50, 'Discount Type')}>
                                        <option value="discount-type">{getTranslatedPhrase(this.props.userLanguage, 'Discount Type')} *</option>
                                        <option value="discount-coupon">{getTranslatedPhrase(this.props.userLanguage, 'Coupon')}</option>
                                        <option value="discount-deal">{getTranslatedPhrase(this.props.userLanguage, 'Deal')}</option>
                                    </select>
                                </div>
                            </div>
                        )}

                        {this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-coupon' && (
                            <div className="edit-page-field">
                                <div className="form-input-select">
                                    <select className="form-input-full" value={this.state.temp.selectWhichCoupon} onChange={(e) => this.onChangeDiscountProperty(e, 'selectWhichCoupon', 50, 'Select Coupon')}>
                                        <option value="select-coupon">{getTranslatedPhrase(this.props.userLanguage, 'Select Coupon')} *</option>
                                        <option value="add-new-coupon">{getTranslatedPhrase(this.props.userLanguage, 'Add New')}</option>
                                        {this.state.couponList.map((v, i) => (
                                            <option key={'coupon-' + i} value={v.itemId}>{v.title}</option>)
                                        )}
                                    </select>
                                </div>
                            </div>
                        )}

                        {(this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-deal') && (
                            <>
                                <div className="edit-page-field">
                                    <input className="form-input-text form-input-full" type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Discount Value *')}
                                        value={this.displayPriceValue(this.state.temp.discountValue, 'discountValue')}
                                        onChange={(e) => this.onChangeDiscountProperty(e, 'discountValue', 20, 'Discount Value')} />
                                </div>

                                <div className="edit-page-field">
                                    <div className="form-input-select">
                                        <select className="form-input-full" value={this.state.temp.discountCurrency} onChange={(e) => this.onChangeDiscountProperty(e, 'discountCurrency', 50, 'Currency')}>
                                            <option value="%">{getTranslatedPhrase(this.props.userLanguage, '%')}</option>
                                            <option value={this.state.currency}>{this.state.currency}</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="edit-page-field">
                                    <div className="form-input-select">
                                        <select className="form-input-full" value={this.state.temp.availability} onChange={(e) => this.onChangeDiscountProperty(e, 'availability', 50, 'Set Availability')}>
                                            <option value="set-availability">{getTranslatedPhrase(this.props.userLanguage, 'Set Availability')} *</option>
                                            <option value="choose-dates">{getTranslatedPhrase(this.props.userLanguage, 'Choose Dates')}</option>
                                            <option value="no-expiration-date">{getTranslatedPhrase(this.props.userLanguage, 'No Expiration Date')}</option>
                                        </select>
                                    </div>
                                </div>
                            </>
                        )}

                        {(this.state.addDiscount === 'add-discount' && this.state.temp.discountType === 'discount-deal' && this.state.temp.availability === 'choose-dates') && (
                            <>
                                <div className="edit-page-field">
                                    <input className="form-input-text form-input-full" type="date" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Start Date')}
                                        value={this.state.temp.startDate}
                                        onChange={(e) => this.onChangeDiscountProperty(e, 'startDate', 20, 'Start Date')} />
                                </div>

                                <div className="edit-page-field">
                                    <input className="form-input-text form-input-full" type="date" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Expiration Date')}
                                        value={this.state.temp.endDate}
                                        onChange={(e) => this.onChangeDiscountProperty(e, 'endDate', 20, 'Expiration Date')} />
                                </div>
                            </>
                        )}
                    </>)}

                    {/* <div className="edit-page-field edit-page-field-full">
                        <div className="form-input-lists">
                            <input type="checkbox" name="displayCallForPricing" checked={this.state.callForPricing} onChange={() => null} style={{ display: 'none' }} />
                            <label htmlFor="displayCallForPricing" style={{ paddingLeft: "16px", width: '100%' }}>
                                <span><i className="fal fa-check" onClick={(e) => this.onChangeItem(!this.state.callForPricing, 'callForPricing')} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Call For Pricing')}
                            </label>
                        </div>
                    </div> */}
                </div>
            </div>



            {/* Specifications */}


            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Specifications</p>
                </div>

                {this.state.category === 'residential' ? (
                    <div className="new-edit-page_section-specs">
                        <div>
                            <table className="addSpecTable addDefaultSpecTable">
                                <tbody>
                                    <tr>
                                        <td className="addSpecLabel"><input type="text" className="form-input-text form-input-full"
                                            value={getTranslatedPhrase(this.props.userLanguage, 'Bedrooms')} disabled /></td>
                                        <td className="addSpecValue"><input type="number" placeholder="ex: 4" className="form-input-text form-input-full"
                                            value={this.state.bedCount} onChange={e => this.onChangeItem(e.target.value, 'bedCount', 3, 'Bedrooms')} /></td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>
                                    < tr>
                                        <td className="addSpecLabel"><input type="text" className="form-input-text form-input-full"
                                            value={getTranslatedPhrase(this.props.userLanguage, 'Bathrooms')} disabled /></td>
                                        <td className="addSpecValue"><input type="number" placeholder="ex: 2" className="form-input-text form-input-full"
                                            value={this.state.bathCount} onChange={e => this.onChangeItem(e.target.value, 'bathCount', 3, 'Bathrooms')} /></td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <div className="form-input-select">
                                                <select className="form-input-full" value={this.state.specList[2].specName}
                                                    onChange={e => this.onChangeSpecListItem({ newSpecName: e.target.value, newSpecValue: this.state.specList[2].specValue }, 2)}>
                                                    <option>Floor Size SQ Feet</option>
                                                    <option>Floor Size SQ Meters</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addSpecValue">
                                            <input className="form-input-text form-input-full" type="number" placeholder="ex: 1450" value={this.state.specList[2].specValue}
                                                onChange={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[2].specName, newSpecValue: e.target.value }, 2)}
                                                maxLength="10" onInput={this.maxLengthCheck}
                                            />
                                        </td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <div className="form-input-select">
                                                <select className="form-input-full" value={this.state.specList[12].specName}
                                                    onChange={e => this.onChangeSpecListItem({ newSpecValue: this.state.specList[12].specValue, newSpecName: e.target.value }, 12)}>
                                                    <option>Lot Size Acres</option>
                                                    <option>Lot Size SQ Meters</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addSpecValue">
                                            <input className="form-input-text form-input-full" type="number" placeholder="ex: 145" value={this.state.specList[12].specValue}
                                                onChange={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[12].specName, newSpecValue: e.target.value }, 12)}
                                                maxLength="10" onInput={this.maxLengthCheck}
                                            /></td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                    <br />
                                    <br />
                                    <tr>
                                        <td className="addSpecLabel"><input type="text" className="form-input-text form-input-full"
                                            value={getTranslatedPhrase(this.props.userLanguage, this.state.specList[3].specName)} disabled /></td>
                                        <td className="addSpecValue"><input type="number" placeholder="ex: 2010" className="form-input-text form-input-full" value={this.state.specList[3].specValue}
                                            onChange={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[3].specName, newSpecValue: e.target.value }, 3)} /></td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>
                                    < tr>
                                        <td className="addSpecLabel"><input type="text" className="form-input-text form-input-full"
                                            value={getTranslatedPhrase(this.props.userLanguage, this.state.specList[4].specName)} disabled /></td>

                                        <td className="addSpecValue"><input type="number" placeholder="ex: 2" className="form-input-text form-input-full"
                                            onChange={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[4].specName, newSpecValue: e.target.value }, 4)}
                                            maxLength="5" onInput={this.maxLengthCheck}
                                        /></td>

                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>


                                </tbody>
                            </table>

                            <div className="form-input-lists">
                                <input type="checkbox" name="hasGarage" checked={this.state.specList[5].specValue} onChange={() => null} />
                                <label htmlFor="hasGarage" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[5].specName, newSpecValue: !this.state.specList[5].specValue }, 5)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Has Garage')}
                                </label>
                            </div>

                            <br />

                            <div className="form-input-lists">
                                <input type="checkbox" name="HOA" checked={this.state.specList[6].specValue} onChange={() => null} />
                                <label htmlFor="HOA" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[6].specName, newSpecValue: !this.state.specList[6].specValue }, 6)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'HOA / Month')}
                                </label>
                            </div>

                            {this.state.specList[6].specValue && (
                                <table className="addSpecTable addDefaultSpecTable full-width">
                                    <tbody>
                                        <tr>
                                            <td className="addSpecLabel">
                                                <div className="form-input-select">
                                                    <select className="form-input-full" value={this.state.specList[7].specName}
                                                        onChange={e => this.onChangeSpecListItem({ newSpecName: e.target.value, newSpecValue: this.state.specList[7].specValue }, 7)}>
                                                        <option>USD</option>
                                                        <option>EUR</option>
                                                        <option>RON</option>
                                                        <option>GBP</option>
                                                        <option>CNY</option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td className="addSpecValue">
                                                <input className="form-input-text form-input-full" type="number" placeholder="ex: 1450" value={this.state.specList[7].specValue}
                                                    onChange={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[7].specName, newSpecValue: e.target.value }, 7)}
                                                    maxLength="6" onInput={this.maxLengthCheck}
                                                /></td>
                                            <td className="addRemoveValue">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            )}




                        </div>

                        <div>
                            {/* <label>{getTranslatedPhrase(this.props.userLanguage, 'Add More') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'Specifications')}</label>

                          <form onSubmit={this.onAddSpecSubmit}>
                              <table className="addSpecTable">
                                  <tbody>
                                  <tr>
                                      <td className="addSpecLabel">
                                          <div className="form-input-select">
                                              <select
                                                className="form-input-full"
                                                ref={(n) => { if (!this.state.temp.specFocusedInput) this.setState({ temp: { ...this.state.temp, specFocusedInput: n } }); }}
                                                value={this.state.temp.specName}
                                                disabled={this.state.moreSpecs.length === 0 ? true : false}
                                                onChange={this.onChangeSpecNameHandler}>
                                                  {this.state.moreSpecs.map(v => (
                                                    <option key={v} value={v}>{getTranslatedPhrase(this.props.userLanguage, v)}</option>
                                                  ))}
                                              </select>
                                          </div>
                                      </td>
                                      <td className="addSpecValue"><input className="form-input-text form-input-full"
                                                                          disabled={this.state.moreSpecs.length === 0 ? true : false}
                                                                          type="text" placeholder="ex: 1990"
                                                                          value={this.state.temp.specValue}
                                                                          onChange={this.onChangeSpecValueHandler} /></td>
                                      <td className="addRemoveValue">
                                          <button disabled={this.state.moreSpecs.length === 0 ? true : false} onClick={() => this.onAddSpec({ newSpecName: this.state.temp.specName, newSpecValue: this.state.temp.specValue })}>
                                              <i className="fal fa-plus-square"></i>
                                          </button>
                                      </td>
                                  </tr>
                                 

                                  </tbody>
                              </table>
                          </form> */}

                            {/* <table className="SubmitedSpecTable">
                              <tbody>
                              {this.state.specList.map((v, i) => (<tr key={i}>
                                  <td className="addSpecLabel">{v.specName}</td>
                                  <td className="addSpecValue">{v.specValue}</td>
                                  <td className="addRemoveValue">
                                      <button onClick={() => this.onRemoveSpec(i)}>
                                          <i className="fal fa-minus-square"></i>
                                      </button></td>
                              </tr>))}
                              </tbody>
                          </table> */}
                            <label> <strong>Other Specifications</strong> </label>
                            <div className="form-input-lists">
                                <input type="checkbox" name="hasBasemnet" checked={this.state.specList[8].specValue} onChange={() => null} />
                                <label htmlFor="hasBasemnet" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[8].specName, newSpecValue: !this.state.specList[8].specValue }, 8)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Has Basement')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="ac" checked={this.state.specList[9].specValue} onChange={() => null} />
                                <label htmlFor="ac" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[9].specName, newSpecValue: !this.state.specList[9].specValue }, 9)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'A/C')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="accessible" checked={this.state.specList[10].specValue} onChange={() => null} />
                                <label htmlFor="accessible" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[10].specName, newSpecValue: !this.state.specList[10].specValue }, 10)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Accessible')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="pool" checked={this.state.specList[11].specValue} onChange={() => null} />
                                <label htmlFor="pool" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[11].specName, newSpecValue: !this.state.specList[11].specValue }, 11)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Pool')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="fitnessCenter" checked={this.state.specList[14].specValue} onChange={() => null} />
                                <label htmlFor="fitnessCenter" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[14].specName, newSpecValue: !this.state.specList[14].specValue }, 14)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Fitness Center')}
                                </label>
                            </div>

                            <br />
                            <br />

                            <label><strong>View</strong></label>

                            <div className="form-input-lists">
                                <input type="checkbox" name="water" checked={this.state.specList[15].specValue} onChange={() => null} />
                                <label htmlFor="water" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[15].specName, newSpecValue: !this.state.specList[15].specValue }, 15)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Water')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="mountain" checked={this.state.specList[16].specValue} onChange={() => null} />
                                <label htmlFor="mountain" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[16].specName, newSpecValue: !this.state.specList[16].specValue }, 16)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Mountain')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="city" checked={this.state.specList[17].specValue} onChange={() => null} />
                                <label htmlFor="city" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[17].specName, newSpecValue: !this.state.specList[17].specValue }, 17)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'City')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="park" checked={this.state.specList[18].specValue} onChange={() => null} />
                                <label htmlFor="park" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[18].specName, newSpecValue: !this.state.specList[18].specValue }, 18)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Park')}
                                </label>
                            </div>

                        </div>
                    </div>
                ) : null}

                {this.state.category === 'commercial' ? (

                    <div className="new-edit-page_section-specs">

                        <div>
                            <table className="addSpecTable addDefaultSpecTable">
                                <tbody>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <div className="form-input-select">
                                                <select className="form-input-full" value={this.state.specList[2].specName}
                                                    onChange={e => this.onChangeSpecListItem({ newSpecName: e.target.value, newSpecValue: this.state.specList[2].specValue }, 2)}>
                                                    <option>Floor Size SQ Feet</option>
                                                    <option>Floor Size SQ Meters</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addSpecValue">
                                            <input className="form-input-text form-input-full" type="number" placeholder="ex: 1450" value={this.state.specList[2].specValue}
                                                onChange={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[2].specName, newSpecValue: e.target.value }, 2)}
                                                maxLength="10" onInput={this.maxLengthCheck}
                                            /></td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="addSpecLabel"><input type="text" className="form-input-text form-input-full"
                                            value={getTranslatedPhrase(this.props.userLanguage, this.state.specList[3].specName)} disabled /></td>
                                        <td className="addSpecValue"><input type="number" placeholder="ex: 2010" className="form-input-text form-input-full" value={this.state.specList[3].specValue}
                                            onChange={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[3].specName, newSpecValue: e.target.value }, 3)} /></td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                    <br />
                                    <label><strong>Other Specifications</strong></label>
                                    <tr>
                                        <td className="addSpecLabel"><input type="text" placeholder="ex: Height" className="form-input-text form-input-full mgb-none"
                                            value={this.state.temp.specName} onChange={this.onChangeSpecNameHandler}
                                            maxLength="15"
                                        /> </td>
                                        <td className="addSpecValue"><input type="text" placeholder="ex: 14 feet" className="form-input-text form-input-full mgb-none"
                                            value={this.state.temp.specValue} onChange={this.onChangeSpecValueHandler}
                                            maxLength="15"
                                        /></td>
                                        <td className="addRemoveValue">
                                            <button onClick={e => this.onAddSpec({ newSpecName: this.state.temp.specName, newSpecValue: this.state.temp.specValue })} >
                                                <i className="fal fa-plus-square" />
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            <table className="SubmitedSpecTable">
                                <tbody>
                                    {this.state.moreSpecs.map((v, i) => (
                                        <tr key={i}>
                                            <td className="addSpecLabel">{v.specName}</td>
                                            <td className="addSpecValue">{v.specValue}</td>
                                            <td className="addRemoveValue">
                                                <button onClick={() => this.onRemoveSpec(i)}>
                                                    <i className="fal fa-minus-square" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div>

                            <label><strong>View</strong></label>

                            <div className="form-input-lists">
                                <input type="checkbox" name="water" checked={this.state.specList[15].specValue} onChange={() => null} />
                                <label htmlFor="water" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[15].specName, newSpecValue: !this.state.specList[15].specValue }, 15)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Water')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="mountain" checked={this.state.specList[16].specValue} onChange={() => null} />
                                <label htmlFor="mountain" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[16].specName, newSpecValue: !this.state.specList[16].specValue }, 16)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Mountain')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="city" checked={this.state.specList[17].specValue} onChange={() => null} />
                                <label htmlFor="city" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[17].specName, newSpecValue: !this.state.specList[17].specValue }, 17)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'City')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="park" checked={this.state.specList[18].specValue} onChange={() => null} />
                                <label htmlFor="park" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[18].specName, newSpecValue: !this.state.specList[18].specValue }, 18)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Park')}
                                </label>
                            </div>

                        </div>
                    </div>
                ) : null}

                {this.state.category === 'land' ? (

                    <div className="new-edit-page_section-specs">

                        <div>
                            <table className="addSpecTable addDefaultSpecTable">
                                <tbody>

                                    <tr>
                                        <td className="addSpecLabel">
                                            <div className="form-input-select">
                                                <select className="form-input-full" value={this.state.specList[12].specName}
                                                    onChange={e => this.onChangeSpecListItem({ newSpecValue: this.state.specList[12].specValue, newSpecName: e.target.value }, 12)}>
                                                    <option>Lot Size Acres</option>
                                                    <option>Lot Size SQ Meters</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td className="addSpecValue">
                                            <input className="form-input-text form-input-full" type="number" placeholder="ex: 145" value={this.state.specList[12].specValue}
                                                onChange={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[12].specName, newSpecValue: e.target.value }, 12)} /></td>
                                        <td className="addRemoveValue">
                                        </td>
                                    </tr>

                                    <br />
                                    <label><strong>Other Specifications</strong></label>
                                    <tr>
                                        <td className="addSpecLabel"><input type="text" placeholder="ex: Purpose" className="mgb-none form-input-text form-input-full"
                                            value={this.state.temp.specName} onChange={this.onChangeSpecNameHandler} /> </td>
                                        <td className="addSpecValue"><input type="text" placeholder="ex: Construction" className="mgb-none form-input-text form-input-full"
                                            value={this.state.temp.specValue} onChange={this.onChangeSpecValueHandler} /></td>


                                        <td className="addRemoveValue">
                                            <button onClick={e => this.onAddSpec({ newSpecName: this.state.temp.specName, newSpecValue: this.state.temp.specValue })} >
                                                <i className="fal fa-plus-square" />
                                            </button>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            <table className="SubmitedSpecTable">
                                <tbody>
                                    {this.state.moreSpecs.map((v, i) => (
                                        <tr key={i}>
                                            <td className="addSpecLabel">{v.specName}</td>
                                            <td className="addSpecValue">{v.specValue}</td>
                                            <td className="addRemoveValue">
                                                <button onClick={() => this.onRemoveSpec(i)}>
                                                    <i className="fal fa-minus-square" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div>

                            <label><strong>View</strong></label>

                            <div className="form-input-lists">
                                <input type="checkbox" name="water" checked={this.state.specList[15].specValue} onChange={() => null} />
                                <label htmlFor="water" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[15].specName, newSpecValue: !this.state.specList[15].specValue }, 15)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Water')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="mountain" checked={this.state.specList[16].specValue} onChange={() => null} />
                                <label htmlFor="mountain" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[16].specName, newSpecValue: !this.state.specList[16].specValue }, 16)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Mountain')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="city" checked={this.state.specList[17].specValue} onChange={() => null} />
                                <label htmlFor="city" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[17].specName, newSpecValue: !this.state.specList[17].specValue }, 17)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'City')}
                                </label>
                            </div>
                            <div className="form-input-lists">
                                <input type="checkbox" name="park" checked={this.state.specList[18].specValue} onChange={() => null} />
                                <label htmlFor="park" >
                                    <span><i className="fal fa-check" onClick={e => this.onChangeSpecListItem({ newSpecName: this.state.specList[18].specName, newSpecValue: !this.state.specList[18].specValue }, 18)} /></span>{getTranslatedPhrase(this.props.userLanguage, 'Park')}
                                </label>
                            </div>

                        </div>
                    </div>
                ) : null}

            </div>





            {/* Description */}
            <div className="new-edit-page_section">
                <div className="new-edit-page_section-title">
                    <p>Description</p>
                </div>


                <div className="new-edit-page_section-content">
                    <div className="edit-page-field edit-page-field-full">
                        <textarea className="form-input-textarea form-input-full" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Write your property listing description. *')} value={this.state.description} onChange={e => this.onChangeItem(e.target.value, 'description', 10000, 'The property description')}>
                        </textarea>
                    </div>
                </div>
            </div>






            <CreateCouponModal
                active={this.state.temp.showCouponModal}
                onClose={this.onCloseCouponModal}
                oldCoupon={null}
                initialPrice={this.state.price}
                couponCurrency={this.state.currency}
            />
        </>);
    }
}