import React, { Component } from 'react';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import Box from '../../../../../shared/util/box';
import SubscriptionModal from '../../../../../shared/components/Modals/Advertise/SubscriptionModal';
import actionType from '../../../../../redux-store/action-type';
import { connect } from 'react-redux';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import { getTranslatedPhrase } from '../../../../../shared/util/lang';
import LoadingSvg from '../../../../../shared/uielements/LoadingSpinner/LoadingSvg';
import ErrorMessages from '../../../../../shared/uielements/ErrorMessages/ErrorMessages';
import moment from 'moment';

import carverveIcon from './assets/carverve-app-icon.png';
import carzlookIcon from './assets/carzlook-app-icon.png';
import zaklistIcon from './assets/zaklist-app-icon.png';

class ThePlans extends Component {
    state = {
        openAlertModal: false,
        openPaymentModal: false,
        subscription: 'free',
        futureSubscription: '',
        futureSubscriptionStartDate: null,
        tempSubscription: '',
        subscriptionList: ['subscription-plan_box', 'subscription-plan_box premium-plan'],
        downgradeOperationConfirmed: false,
        waitingForDowngradeConfirmation: false,
        waitingForResponse: false,
        enterprisePrice: 0,
        accountType: 'unknown',
        isDealer: '',
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.getSubscriptionInfoFromServer();
            this.getUserDataFromServer();
            this.getEnterpriseInfo();
        }, 500);
    }

    componentDidUpdate() {
        let user = this.props.loggedInUser;
        if (user && this.state.userId !== user.userId) {
            this.setState(user);
            this.setState({isDealer: user.dealer_id})
        }

        if (this.state.downgradeOperationConfirmed === this.props.downgradeOperationConfirmed) {
            return;
        }
        if (!this.props.downgradeOperationConfirmed) {
            this.setState({
                waitingForDowngradeConfirmation: false,
                downgradeOperationConfirmed: false,
            });
            return;
        }
        else {
            if (!!this.state.waitingForDowngradeConfirmation) {
                if (this.state.tempSubscription === 'free') {
                    this.onSubmitUserInfoHandler({ stripeToken: { id: -1 }, gpaymentId: { id: -2 } });
                } else {
                    this.setState({ openPaymentModal: true });
                }
                this.setState({
                    waitingForDowngradeConfirmation: false,
                    downgradeOperationConfirmed: false,
                });
            }
            this.props.resetDowngradeOperationConfirmation();
        }
    }

    getSubscriptionInfoFromServer = async () => {
        let url = hostNameAndPort + '/api/user/subscription';
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                this.setState(data);
            } else this.props.history.push('/');
        } catch (err) {
            this.props.history.push('/');
        }
    }

    getUserDataFromServer = async () => {
        let user = this.props.loggedInUser;
        if (user) this.setState(user);
    }

    getEnterpriseInfo = async () => {
        try {
            let url = `${hostNameAndPort}/api/user/enterprise-info`;
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                this.setState(data);
            }
        } catch (err) {
            // Server error
        }

    }

    onChangeSubscriptionHandler = (_newSubscription) => {
        let c;
        switch (_newSubscription) {
            case 'premium':
                c = 299;
                break;
            case 'premium-one-year':
                c = 3000;
                break;
            case 'premium-two-years':
                c = 4800;
                break;
            case 'basic':
                c = 29;
                break;
            case 'enterprise':
                c = this.state.enterprisePrice;
                break;
            default:
                c = 0;
                break;
        }
        this.setState({
            charge: c,
            tempSubscription: _newSubscription,
        });
        if (((_newSubscription === 'basic' || _newSubscription.startsWith('premium')) && this.state.subscription === 'enterprise') || _newSubscription === 'free') {
            this.setState({ waitingForDowngradeConfirmation: true });
            let newSubs;
            switch (_newSubscription) {
                case 'free':
                    newSubs = 'Free';
                    break;
                case 'basic':
                    newSubs = 'Basic';
                    break;
                case 'premium':
                    newSubs = 'Premium';
                    break;
                case 'premium-one-year':
                    newSubs = 'Premium Yearly';
                    break;
                case 'premium-two-years':
                    newSubs = 'Premium Bi-yearly';
                    break;
                case 'enterprise':
                    newSubs = 'Enterprise';
                    break;
                default:
                    newSubs = 'Free';
                    break;
            }
            this.props.onShowConfirmDowngradeModal(getTranslatedPhrase(this.props.userLanguage, 'Are you sure you want to downgrade? By downgrading you will disable the listings number to the limit of the ' + newSubs + ' Membership. The remaining listings will be the most recent ones.'));
        } else {
            this.setState({ openPaymentModal: true });
        }
    }

    onCloseModalHandler = () => {
        this.setState({ openAlertModal: false, openPaymentModal: false, charge: 0 });
    }

    onSubmitUserInfoHandler = async ({ stripeToken, gpaymentId }) => {
        this.onCloseModalHandler();

        let s = this.state.tempSubscription;
        if (['free', 'basic', 'premium', 'premium-one-year', 'premium-two-years', 'enterprise'].indexOf(s) < 0) {
            return;
        }
        this.setState({ waitingForResponse: true });

        let _data = new FormData();
        _data.append('oldSubscriptionType', this.state.subscription);
        _data.append('subscriptionType', s);
        _data.append('stripeToken', stripeToken.id);
        _data.append('gpaymentId', gpaymentId.id);

        _data.append('storeListings', this.state.storeListings);
        _data.append('foodListings', this.state.foodListings);
        _data.append('autoListings', this.state.autoListings);
        _data.append('realestateListings', this.state.realestateListings);
        _data.append('travelListings', this.state.travelListings);
        _data.append('jobsListings', this.state.jobsListings);
        _data.append('servicesListings', this.state.servicesListings);
        _data.append('enterprisePrice', this.state.enterprisePrice);

        let options = {
            url: `${hostNameAndPort}/api/pay/subscription`,
            method: 'post',
            data: _data,
        }
        Axios(options)
            .then(result => {
                let { data } = result;
                if (!data.error) {
                    this.setState({
                        subscription: data.subscription,
                        waitingForResponse: false
                    });
                    if (!!data.message) {
                        this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, data.message));
                    }
                } else {
                    this.setState({ waitingForResponse: false });
                    this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, data.error));
                }
            })
            .catch(err => {
                this.setState({ waitingForResponse: false });
                this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEPLNS01'));
            });
    }

    getLabelForBasic = () => {
        let s = this.state.subscription;
        switch (s) {
            case 'basic':
                return getTranslatedPhrase(this.props.userLanguage, 'Enrolled');
            case 'premium':
            case 'premium-one-year':
            case 'premium-two-years':
                return getTranslatedPhrase(this.props.userLanguage, 'Downgrade');
            case 'free':
            default:
                return getTranslatedPhrase(this.props.userLanguage, 'Upgrade');
        }
    }

    getSubscriptionTitleCase = (s) => {
        s = s.toLowerCase();
        switch (s) {
            case 'free':
                return 'Free';
            case 'basic':
                return 'Basic';
            case 'premium':
                return 'Premium';
            case 'premium-one-year':
                return 'Premium Yearly';
            case 'premium-two-years':
                return 'Premium Bi-yearly';
            default:
                return s;
        }
    }

    onClickContact = () => {
        this.props.onEnterpriseMembership('enterprise');
        this.props.history.push('/contact');
    }

    render() {

        return (<Box>
            <ErrorMessages
                errorMessageClass={"error-message error-message-info"}
                errorMessageTitle={getTranslatedPhrase(this.props.userLanguage, 'Subscription changed to ') + this.getSubscriptionTitleCase(this.state.futureSubscription || '')}
                errorMessageBody={getTranslatedPhrase(this.props.userLanguage, 'Your new subscription will start on ') + moment(this.state.futureSubscriptionStartDate).format('L')} show={!!this.state.futureSubscription} />

            <div className="subscription-plan" style={this.state.isDealer ? {gridTemplateColumns: 'none'} : {}}>


            {!this.state.isDealer &&
                <div className={`${this.state.subscriptionList[Number(this.state.subscription === 'free')]} subscription-plan_box_free_header`}>

                    <h2 className="subscription-plan_box_title">{getTranslatedPhrase(this.props.userLanguage, 'Free')}<br /><span>{getTranslatedPhrase(this.props.userLanguage, 'MEMBERSHIP')}</span></h2>

                    <div className="plan-benefits">
                        <h2><span>Add</span> 2 <span>Listings</span></h2>

                        <div className="icon-box">
                            <div className="icon-box-item">
                                <i className="fal fa-shopping-bag" />
                                <span>Store</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-utensils" />
                                <span>Food</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-car" />
                                <span>Auto</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-building" />
                                <span>Real Estate</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-bed" />
                                <span>Travel</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-briefcase" />
                                <span>Jobs</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-wrench" />
                                <span>Services</span>
                            </div>
                        </div>
                    </div>

                    <div className="plans-ultimate-pricing-container">
                        <div className="plans-ultimate-pricing">
                                <div className="form-input-lists" style={{cursor: 'default'}}>
                                    <label style={{cursor: 'default'}}><i className="fal fa-check noShowCheck" />Always Free</label>
                                </div>
                        </div>
                    </div>


                    <div className="GetPlan">
                        <button className="button button-clear-white" disabled={this.state.subscription === 'free'} onClick={() => this.onChangeSubscriptionHandler('free')}>{(this.state.subscription === 'free') ? getTranslatedPhrase(this.props.userLanguage, 'Enrolled') : getTranslatedPhrase(this.props.userLanguage, 'Downgrade')}</button>
                        <LoadingSvg active={this.state.waitingForResponse && (this.state.tempSubscription === 'free')} />
                    </div>
                </div>
            }




            {!this.state.isDealer &&
                <div className={`${this.state.subscriptionList[Number(this.state.subscription.startsWith('premium'))]} subscription-plan_box_premium_header`} >

                    <h2 className="subscription-plan_box_title">{getTranslatedPhrase(this.props.userLanguage, 'Premium')}<br /><span>{getTranslatedPhrase(this.props.userLanguage, 'MEMBERSHIP')}</span></h2>

                    <div className="plan-benefits">
                        <h2><span>Add</span> 1.5k <span>Listings</span></h2>

                        <div className="icon-box">
                            <div className="icon-box-item">
                                <i className="fal fa-shopping-bag" />
                                <span>Store</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-utensils" />
                                <span>Food</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-car" />
                                <span>Auto</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-building" />
                                <span>Real Estate</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-bed" />
                                <span>Travel</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-briefcase" />
                                <span>Jobs</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-wrench" />
                                <span>Services</span>
                            </div>
                        </div>
                    </div>

                    <div className="plans-ultimate-pricing-container">
                        <div className="plans-ultimate-pricing">
                            {this.state.isDealer &&
                                <>
                                    <div className="form-input-lists">
                                        <input type="radio" name="premiumpricing" id="yymo" checked={this.state.subscription === 'premium-two-years'} value="200" onChange={() => this.onChangeSubscriptionHandler('premium-two-years')}/>
                                        <label htmlFor="yymo"><i className="fal fa-check" />$200 <span>/mo.</span> <span>(24/mo. Term)</span></label>
                                    </div>

                                    <p style={{color: '#444', marginTop: '20px'}}><strong>MOST POPULAR PLAN</strong></p>
                                    <div className="form-input-lists">
                                        <input type="radio" name="premiumpricing" id="ymo" checked={this.state.subscription === 'premium-one-year'} value="250" onChange={() => this.onChangeSubscriptionHandler('premium-one-year')}/>
                                        <label htmlFor="ymo"><i className="fal fa-check" />$250 <span>/mo.</span> <span>(12/mo. Term)</span></label>
                                    </div>
                                </>
                            } 

                            {!this.state.isDealer &&
                                <div className="form-input-lists">
                                    <input type="radio" name="premiumpricing" id="mo" checked={this.state.subscription === 'premium'} value="299" onChange={() => this.onChangeSubscriptionHandler('premium')} />
                                    <label htmlFor="mo"><i className="fal fa-check noShowCheck" />$299 <span>/mo.</span> <span /></label>
                                </div>
                            }
                        </div>
                    </div>


                    <div className="GetPlan">
                        <button className="button button-clear-white" disabled={this.state.subscription.startsWith('premium')} onClick={() => this.onChangeSubscriptionHandler('premium')}>
                            {this.state.subscription.startsWith('premium') ? getTranslatedPhrase(this.props.userLanguage, 'Enrolled') : (this.state.subscription === 'enterprise') ?
                                getTranslatedPhrase(this.props.userLanguage, 'Downgrade') : getTranslatedPhrase(this.props.userLanguage, 'Upgrade')}</button>
                        <LoadingSvg active={this.state.waitingForResponse && (this.state.tempSubscription.startsWith('premium'))} />
                    </div>
                </div>
            }




            {this.state.isDealer &&
                <div className={`${this.state.subscriptionList[Number(this.state.subscription.startsWith('premium'))]}`}  style={{minWidth: '300px', backgroundColor: '#444'}}>

                    <h2 className="subscription-plan_box_title">{getTranslatedPhrase(this.props.userLanguage, 'Premium')}<br /><span>{getTranslatedPhrase(this.props.userLanguage, 'MEMBERSHIP')}</span></h2>

                    <div className="plan-benefits">
                        <h2><span>Add</span> 1.5k <span>Listings</span></h2>

                        <div className="icon-box">
                            <div className="icon-box-item">
                                <i className="fal fa-shopping-bag" />
                                <span>Store</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-car" />
                                <span>Auto</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-briefcase" />
                                <span>Jobs</span>
                            </div>

                            <div className="icon-box-item">
                                <i className="fal fa-wrench" />
                                <span>Services</span>
                            </div>
                        </div>

                        <div className="dealerPlus">
                            <p><strong>PLUS</strong> Premium accounts on our<br />auto dealer dedicated platforms:</p>

                            <ul>
                                <li><a href="https://zaklist.com" target="_blank"><img src={zaklistIcon} /></a></li>
                                <li><a href="https://carverve.com" target="_blank"><img src={carverveIcon} /></a></li>
                                <li><a href="https://carzlook.com" target="_blank"><img src={carzlookIcon} /></a></li>
                            </ul>
                        </div>
                    </div>

                    <div className="plans-ultimate-pricing-container">
                        <div className="plans-ultimate-pricing">
                            {this.state.isDealer &&
                                <>
                                    <div className="form-input-lists">
                                        <input type="radio" name="premiumpricing" id="yymo" checked={this.state.subscription === 'premium-two-years'} value="200" onChange={() => this.onChangeSubscriptionHandler('premium-two-years')}/>
                                        <label htmlFor="yymo"><i className="fal fa-check" />$200 <span>/mo.</span> <span>(24/mo. Term)</span></label>
                                    </div>

                                    <p style={{color: '#444', marginTop: '20px'}}><strong>MOST POPULAR PLAN</strong></p>
                                    <div className="form-input-lists">
                                        <input type="radio" name="premiumpricing" id="ymo" checked={this.state.subscription === 'premium-one-year'} value="250" onChange={() => this.onChangeSubscriptionHandler('premium-one-year')}/>
                                        <label htmlFor="ymo"><i className="fal fa-check" />$250 <span>/mo.</span> <span>(12/mo. Term)</span></label>
                                    </div>
                                </>
                            } 

                            {!this.state.isDealer &&
                                <div className="form-input-lists">
                                    <input type="radio" name="premiumpricing" id="mo" checked={this.state.subscription === 'premium'} value="299" onChange={() => this.onChangeSubscriptionHandler('premium')} />
                                    <label htmlFor="mo"><i className="fal fa-check noShowCheck" />$299 <span>/mo.</span> <span /></label>
                                </div>
                            }
                        </div>
                    </div>


                    <div className="GetPlan">
                        <button className="button button-clear-white" disabled={this.state.subscription.startsWith('premium')} onClick={() => this.onChangeSubscriptionHandler('premium-one-year')}>
                            {this.state.subscription.startsWith('premium') ? getTranslatedPhrase(this.props.userLanguage, 'Active') : (this.state.subscription === 'enterprise') ?
                                getTranslatedPhrase(this.props.userLanguage, 'Downgrade') : getTranslatedPhrase(this.props.userLanguage, 'Activate Plan')}</button>
                        <LoadingSvg active={this.state.waitingForResponse && (this.state.tempSubscription.startsWith('premium'))} />

                        <button className="autoCancelSubscription" disabled={this.state.subscription === 'free'} onClick={() => this.onChangeSubscriptionHandler('free')}>{(this.state.subscription === 'free') ? getTranslatedPhrase(this.props.userLanguage, '') : getTranslatedPhrase(this.props.userLanguage, 'Cancel Subscription')}</button>
                    </div>

                    <div className="sub-cancel-policy" style={{marginTop: '2rem'}}><strong>30 DAYS MONEY BACK GUARANTEE</strong>< br />You have 30 days to cancel your plan. To cancel your subscription please: <br /> call us at (833)-531-2158 or <br />  email us at <a href="mailto: support@followone.com">support@followone.com</a> and we will issue a refund within 5 to 7 business days.</div>
                </div>
            }

               




                {!this.state.isDealer &&
                    <>

                        {(this.state.subscription !== 'enterprise') ? null : (
                            <div className={`${this.state.subscriptionList[Number(this.state.subscription === 'enterprise')]} subscription-plan_box_enterprise_header`}>

                                <h2 className="subscription-plan_box_title">{getTranslatedPhrase(this.props.userLanguage, 'Enterprise')}<br /><span>{getTranslatedPhrase(this.props.userLanguage, 'MEMBERSHIP')}</span></h2>

                                <div className="plan-benefits">
                                    <h2><span>Add</span> 1M <span>Listings</span></h2>

                                    <div className="icon-box">
                                        <div className="icon-box-item">
                                            <i className="fal fa-shopping-bag" />
                                            <span>Store</span>
                                        </div>

                                        <div className="icon-box-item">
                                            <i className="fal fa-utensils" />
                                            <span>Food</span>
                                        </div>

                                        <div className="icon-box-item">
                                            <i className="fal fa-car" />
                                            <span>Auto</span>
                                        </div>

                                        <div className="icon-box-item">
                                            <i className="fal fa-building" />
                                            <span>Real Estate</span>
                                        </div>

                                        <div className="icon-box-item">
                                            <i className="fal fa-bed" />
                                            <span>Travel</span>
                                        </div>

                                        <div className="icon-box-item">
                                            <i className="fal fa-briefcase" />
                                            <span>Jobs</span>
                                        </div>

                                        <div className="icon-box-item">
                                            <i className="fal fa-wrench" />
                                            <span>Services</span>
                                        </div>
                                    </div>
                                </div>

                                <h2 className="subscription-plan_box_price">${this.state.enterprisePrice}<span>/{getTranslatedPhrase(this.props.userLanguage, 'month')}</span></h2>
                                <div className="GetPlan">
                                    <button className="button button-clear-white" disabled={this.state.subscription === 'enterprise'} onClick={() => this.onChangeSubscriptionHandler('enterprise')}>
                                        {(this.state.subscription === 'enterprise') ? getTranslatedPhrase(this.props.userLanguage, 'Enrolled') : getTranslatedPhrase(this.props.userLanguage, 'Upgrade')}</button>
                                    <LoadingSvg active={this.state.waitingForResponse && (this.state.tempSubscription === 'enterprise')} />
                                </div>
                            </div>)}



                        {(this.state.subscription !== 'enterprise') ? (
                        <div className={`${this.state.subscriptionList[Number(this.state.subscription === 'enterprise')]} subscription-plan_box_enterprise_header`}>

                            <h2 className="subscription-plan_box_title">{getTranslatedPhrase(this.props.userLanguage, 'Enterprise')}<br /><span>{getTranslatedPhrase(this.props.userLanguage, 'MEMBERSHIP')}</span></h2>

                            <div className="plan-benefits">
                                <h2><span>Up To</span> 1M <span>Listings</span></h2>

                                <div className="icon-box">
                                    <div className="icon-box-item">
                                        <i className="fal fa-shopping-bag" />
                                        <span>Store</span>
                                    </div>

                                    <div className="icon-box-item">
                                        <i className="fal fa-utensils" />
                                        <span>Food</span>
                                    </div>

                                    <div className="icon-box-item">
                                        <i className="fal fa-car" />
                                        <span>Auto</span>
                                    </div>

                                    <div className="icon-box-item">
                                        <i className="fal fa-building" />
                                        <span>Real Estate</span>
                                    </div>

                                    <div className="icon-box-item">
                                        <i className="fal fa-bed" />
                                        <span>Travel</span>
                                    </div>

                                    <div className="icon-box-item">
                                        <i className="fal fa-briefcase" />
                                        <span>Jobs</span>
                                    </div>

                                    <div className="icon-box-item">
                                        <i className="fal fa-wrench" />
                                        <span>Services</span>
                                    </div>
                                </div>
                            </div>

                            <div className="plans-ultimate-pricing-container">
                                <div className="plans-ultimate-pricing">
                                        <div className="form-input-lists" style={{cursor: 'default'}}>
                                            <label style={{cursor: 'default'}}><i className="fal fa-check noShowCheck" />Call us for details.</label>
                                        </div>
                                </div>
                            </div>

                            <div className="GetPlan">
                                <button className="button"><a className="button-enterprise-contact" href="#contact" onClick={this.onClickContact}>{getTranslatedPhrase(this.props.userLanguage,'Contact')}</a></button>  

                                <LoadingSvg active={this.state.waitingForResponse && (this.state.tempSubscription === 'enterprise')} />
                            </div>
                        </div>
                        ) : null}

                </>
            }
                
            </div>

            <SubscriptionModal
                active={this.state.openPaymentModal}
                promoCharge={this.state.charge}
                onCloseModal={this.onCloseModalHandler}
                onSubmitUserInfo={this.onSubmitUserInfoHandler}
                onMoveForward={() => null}
            />
        </Box>);
    }
}

const mapStateToThePlansProps = (
    state,
    props
) => {
    return {
        downgradeOperationConfirmed: state.deleteOperationConfirmed,
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

const mapDispatchToThePlansProps = (
    dispatch
) => {
    return {
        onShowConfirmDowngradeModal: (m) => {
            dispatch({ type: actionType.CONFIRM_DELETE_OPERATION_MODAL, confirmDeleteOperationModalActive: true, confirmDeleteOperationModalMessage: m });
        },
        resetDowngradeOperationConfirmation: () => {
            dispatch({ type: actionType.DELETE_OPERATION, deleteOperationConfirmed: false });
        },
        showAlertModal: (u) => {
            dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: u });
        },
        onEnterpriseMembership:(a)=> {
            dispatch({type: actionType.SELECT_REASON, reason:a})
        }
    };
};

export default connect(mapStateToThePlansProps, mapDispatchToThePlansProps)(withRouter(ThePlans));