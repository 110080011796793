import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { titleCase } from '../../../../../shared/util/string';
import { formatPriceAndCurrency } from '../../../../../shared/util/number';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import Axios from 'axios';
import Rate from '../../../../../shared/uielements/Engage/EngageButtons/RateButton';
import EngageButtons from '../../../../../shared/uielements/Engage/EngageButtons/EngageButtons';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../../../shared/util/lang';


class WallService extends Component {
    state = {
        serviceNotFound: true,
        reviews: [],
    }

    componentDidMount() {
        let b = this.props.serviceId;
        if (!b) {
            this.setState({ serviceNotFound: true });
            return;
        }
        let url = `${hostNameAndPort}/api/services/${b}`;
        Axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {                
                data.item.creationDate = (new Date(data.item.creationDate)).toDateString();
                data.item.serviceNotFound = false;
                this.setState(data.item);
                let _wallAction;
                switch (this.props.activityType) {
                    case 'comment':
                    _wallAction = 'commented on a service';
                    break;
                    case 'review':
                    _wallAction = 'rated a service';
                    break;
                    case 'share':
                    _wallAction = 'shared a service';
                    break;
                    case 'post':
                    _wallAction = 'posted a new service';
                    break;
                    default:
                    break;
                }
                this.props.onItemLoaded({
                    itemFailedLoading: false,
                    wallAction: _wallAction,
                });
            } 
            else {
                this.props.onItemLoaded({ itemFailedLoading: true });
            }
        })
        .catch(err => {
            //console.log('Network error');
            this.props.onItemLoaded({ itemFailedLoading: true });
        });
    }

    render () {
        if (this.state.serviceNotFound) {
            return '';
        }
        let serviceSlug = `/services/${this.state.category}/${this.state.slug}`;
        return (
            <div className="category-list_item">
                <Link to={serviceSlug}>
                    <img src={this.state.featuredImage} alt={this.state.title} />
                </Link>

                <div className="store-listing-preview">
                    <p className="store-listing-preview_category">{getTranslatedPhrase(this.props.userLanguage,titleCase(this.state.category.replace(/-/g,' ')))}</p>
                    <p className="store-listing-preview_title">{this.state.title}</p>
                    <p className="store-listing-preview_brand">{getTranslatedPhrase(this.props.userLanguage,'by')} {this.state.authorName}</p>

                    <EngageButtons>
                        <Rate noStars={this.state.averageRating} noReviews={this.state.reviewCount} />
                    </EngageButtons>

                    <p className="store-listing-preview_price">{formatPriceAndCurrency( this.state.price, this.state.currency, ',', getTranslatedPhrase(this.props.userLanguage,titleCase(this.state.rateType)) ) }</p>
                    
                    <Link to={serviceSlug}>
                        <button className="button button-clear button-small">{getTranslatedPhrase(this.props.userLanguage,'Learn More')}</button>
                    </Link>
                
                </div>

            </div>
        );
    }
}


const mapStateToWallServiceProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToWallServiceProps, null)(WallService);
