import React, { useState, useEffect } from 'react';
import ImageFeature1 from '../../../assets/static/small-business-image-1.jpg';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import {withRouter} from 'react-router-dom';
import { Helmet } from "react-helmet";
import FormFieldError from '../../../shared/uielements/ErrorMessages/FormFieldError';
import ErrorMessages from '../../../shared/uielements/ErrorMessages/ErrorMessages';
import { hostNameAndPort } from '../../../shared/util/vars';
import Axios from 'axios';
import { Link } from 'react-router-dom';

import ImgAdvertising from "./assets/stups-advertising.jpg";
import ImgApps from "./assets/stups-apps.jpg";
import ImgBranding from "./assets/stups-branding.jpg";
import ImgBusinessCreation from "./assets/stups-business-creation.jpg";
import ImgBusinessPlan from "./assets/stups-business-plan.jpg";
import ImgWebsite from "./assets/stups-websites.jpg";
import ContactBKG from './assets/stups-getinfo-bkg.jpg';

const formState = {
    name: '',
    emailAddress: '',
    adSource: '',
    phoneNo: '',
    selectCategory: ''
}

const SmallBusinesses = (props) => {

    const [form, setForm] = useState(formState);
    const [agree, setAgree] = useState(false);
    const [formSubmitted, setSormSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    const changeProperty = (e, _prop, _limit, _propLabel) => {
        if (e.target.value.length > _limit) {
            return setErrorMessage(getTranslatedPhrase(props.userLanguage, _propLabel)+' '+getTranslatedPhrase(props.userLanguage,'must be smaller than')+' '+_limit );
        }
        setForm({ ...form, [_prop]: e.target.value });
    }
 
    const infoIsValid = () => {
        let _ = form;
        let res = true, _err = '';

        if (!_.name) {
            _err = getTranslatedPhrase(props.userLanguage,'Name')+' '+getTranslatedPhrase(props.userLanguage,'is required.');
            res = false;
        }
        if (!_.emailAddress) {
            _err = getTranslatedPhrase(props.userLanguage,'Email Address')+' '+getTranslatedPhrase(props.userLanguage,'is required.');
            res = false;
        }
        if (!_.phoneNo) {
            _err = getTranslatedPhrase(props.userLanguage,'Phone Number')+' '+getTranslatedPhrase(props.userLanguage,'is required.');
            res = false;
        }
        
        if (!_.adSource || _.adSource === 'How did you hear about us?') {
            _err = getTranslatedPhrase(props.userLanguage,'Please select valid source.');
            res = false;
        }
        if (!_.selectCategory || _.selectCategory === 'Select Category') {
            _err = getTranslatedPhrase(props.userLanguage,'Please select valid category.');
            res = false;
        }
        if ( !/[-_.A-Za-z0-9]+@[a-z][a-z0-9.]*.[a-z]{2,10}/.test(_.emailAddress) ) {
            _err = getTranslatedPhrase(props.userLanguage,'Email address has invalid format.');
            res = false;
        }
        if (_.formSubmitted) {
            _err = getTranslatedPhrase(props.userLanguage,'Message has been sent already.');
            res = false;
        }
        if (!agree) {
            _err = getTranslatedPhrase(props.userLanguage,'Please agree to our terms and conditions');
            res = false;
        }

        setErrorMessage(_err);
        return res;
    }

    const onChangeAgree = () => {
        setAgree(value => {
            return !value
        })
    }

    const onSubmitMessage = async (e) => {
        e.preventDefault();

        if (!infoIsValid()) {
            return;
        }

        let _data = new FormData();
        _data.append('name', form.name);
        _data.append('emailAddress', form.emailAddress);
        _data.append('phoneNo', form.phoneNo);
        _data.append('adSource', form.adSource);
        _data.append('selectCategory', form.selectCategory);

        let options = {
                url: `${hostNameAndPort}/api/mail/startup`,
                method: 'post',
                data: _data,
            };
        try {
            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                setSormSubmitted(true);
            } else {
                setErrorMessage(getTranslatedPhrase(props.userLanguage,'Form could not be submitted'));
            }
        } catch(err) {
            setErrorMessage(getTranslatedPhrase(props.userLanguage,'Network error ERRFESMBUS01'));
        }
    }


        return (
            <div className="main-100 small-businesses">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Start-up Business | Followone</title>
                    <meta name="description" content="From legal documents, to design and implementation, we have your product launch covered!" />
                    <link rel="canonical" href="https://followone.com/start-ups" />
                </Helmet>

                <div className="main-100-header">
                    <h1>{getTranslatedPhrase(props.userLanguage,'Start-Ups')}</h1>
                    <p>{getTranslatedPhrase(props.userLanguage,'From legal documents, to design and implementation, we have your product launch covered!')}</p>
                </div>

                <div className="static-page-feature">
                    <img src={ImageFeature1} alt="We help Start-ups Succeed" />

                    <p>{getTranslatedPhrase(props.userLanguage,'We help start-ups succeed by providing them with a wide range of services. This can be anything from incorporating your business, drafting legal documents, or developing a business plan. We can also help brand, design, and launch your business through promotion.')}</p>
                </div>





                <h3>We Manage Everything from Concept to Completion:</h3>

                <div className="startup-list">
                    <div className="startup-list-box">
                        <img src={ImgBusinessCreation} alt="Business Creation" />
                        <h4>1. <i className="fal fa-balance-scale" /> Business Creation</h4>
                        <ul>
                            <li><i className="fal fa-check" /> Corporation Creation</li>
                            <li><i className="fal fa-check" /> Article of Incorporation</li>
                            <li><i className="fal fa-check" /> Shareholder Structure</li>
                            <li><i className="fal fa-check" /> By-Law</li>
                        </ul>

                        <p>Creating a corporation is a complicated and lengthy process. Our team of experts is ready to put together a full package for you, helping your business get up and running.</p>
                    </div>


                    <div className="startup-list-box">
                        <img src={ImgBusinessPlan} alt="Business Plan" />
                        <h4>2. <i className="fal fa-file-signature" /> Business Documents</h4>
                        <ul>
                            <li><i className="fal fa-check" /> Market Research</li>
                            <li><i className="fal fa-check" /> Business Plan</li>
                            <li><i className="fal fa-check" /> Trademark & Copyright</li>
                            <li><i className="fal fa-check" /> Private Place Memorandum (PPM)</li>
                        </ul>

                        <p>Running a successful business requires funding. We do market research while preparing your business plan, and private place memorandum (PPM). This prepares your business for investors. We cover trademark and copyright legal documents, to make sure that your company is truly yours.</p>
                    </div>


                    <div className="startup-list-box">
                        <img src={ImgBranding} alt="Branding" />
                        <h4>3. <i className="fal fa-fill-drip" /> Branding</h4>
                        <ul>
                            <li><i className="fal fa-check" /> Logo</li>
                            <li><i className="fal fa-check" /> Style Guide</li>
                            <li><i className="fal fa-check" /> Stationary</li>
                        </ul>

                        <p>We bring your brand to life by offering you a personalized, custom logo, that aligns with your vision for success. The style guide contains logo usage, colors, fonts, and design styles bringing the design together beautifully within a website or an Android app.</p>
                    </div>


                    <div className="startup-list-box">
                        <img src={ImgApps} alt="Apps" />
                        <h4>4. <i className="fal fa-mobile" /> Android App</h4>
                        <ul>
                            <li><i className="fal fa-check" /> Design</li>
                            <li><i className="fal fa-check" /> Content</li>
                            <li><i className="fal fa-check" /> Implementation</li>
                        </ul>
                        <p>Mobile apps are the future. Using the latest technology and design trends, we create apps that enhance the customer experience and broaden your market coverage, while also generating additional sales and sign-ups.</p>
                    </div>


                    <div className="startup-list-box">
                        <img src={ImgWebsite} alt="Websites" />
                        <h4>5. <i className="fal fa-browser" /> Website</h4>
                        <ul>
                            <li><i className="fal fa-check" /> Design</li>
                            <li><i className="fal fa-check" /> Content</li>
                            <li><i className="fal fa-check" /> Implementation</li>
                            <li><i className="fal fa-check" /> Transactional Emails</li>
                        </ul>
                        <p>Depending on your needs, we can design a website that lines up with your branding and style guide. Whether you need an e-Commerce website, a presentational website for your business, or a website to support your mobile app, we have you covered.</p>
                    </div>


                    <div className="startup-list-box">
                        <img src={ImgAdvertising} alt="Advertising" />
                        <h4>6. <i className="fal fa-rocket" /> Launch & Growth</h4>
                        <ul>
                            <li><i className="fal fa-check" /> Social Media Accounts</li>
                            <li><i className="fal fa-check" /> Online Marketing</li>
                            <li><i className="fal fa-check" /> Search Engine Optimization</li>
                            <li><i className="fal fa-check" /> Promote App Downloads</li>
                        </ul>
                        <p>Your business, website, or app has been created. Great! Now it’s time to launch. We can publicize your business to give you that push through the door. We will optimize your websites for search engines. This will allow us to promote you or your apps through various methods of online advertising. </p>
                    </div> 
                </div>
            

                <h3>Talk to a Professional about Getting Started</h3>

                <div className="getinfo-wrapper" style={{backgroundImage: `url(${ContactBKG})`  }}>
                    <div className="contact-box">
                        <input type="text" className="form-input-text" placeholder={getTranslatedPhrase(props.userLanguage,'First & Last Name')} value={form.name} onChange={(e) => changeProperty(e, 'name', 200, 'Name')} />
                        <input type="email" className="form-input-text" placeholder={getTranslatedPhrase(props.userLanguage,'Email Address')} value={form.emailAddress} onChange={(e) => changeProperty(e, 'emailAddress', 100, 'Email Address')} />
                        <input type="phone" className="form-input-text" placeholder={getTranslatedPhrase(props.userLanguage,'Phone #')} value={form.phoneNo} onChange={(e) => changeProperty(e, 'phoneNo', 100, 'Phone Number')} />

                        <div className="form-input-select">
                            <select value={form.selectCategory} onChange={(e) => changeProperty(e, 'selectCategory', 100, 'Select Category')}>
                                <option value="Select Category">{getTranslatedPhrase(props.userLanguage,'Select Your Category')}</option>
                                <option value="Accounting">{getTranslatedPhrase(props.userLanguage,'Accouting')}</option>
                                <option value="Auto Sales/Service">{getTranslatedPhrase(props.userLanguage,'Auto Sales/Service')}</option>
                                <option value="Banking/Finance">{getTranslatedPhrase(props.userLanguage,'Banking/Finance')}</option>
                                <option value="Business Services">{getTranslatedPhrase(props.userLanguage,'Business Services')}</option>
                                <option value="E-Commerce">{getTranslatedPhrase(props.userLanguage,'E-Commerce ')}</option>
                                <option value="Education">{getTranslatedPhrase(props.userLanguage,'Education')}</option>
                                <option value="Entertainment">{getTranslatedPhrase(props.userLanguage,'Entertainment')}</option>
                                <option value="Events">{getTranslatedPhrase(props.userLanguage,'Events ')}</option>
                                <option value="Food Services">{getTranslatedPhrase(props.userLanguage,'Food Services')}</option>
                                <option value="Health/Wellness">{getTranslatedPhrase(props.userLanguage,'Health/Wellness')}</option>
                                <option value="Health Care">{getTranslatedPhrase(props.userLanguage,'Health Care')}</option>
                                <option value="Home Improvement">{getTranslatedPhrase(props.userLanguage,'Home Improvement')}</option>
                                <option value="Insurance">{getTranslatedPhrase(props.userLanguage,'Insurance')}</option>
                                <option value="Legal Service">{getTranslatedPhrase(props.userLanguage,'Legal Service')}</option>
                                <option value="Lodging/Travel">{getTranslatedPhrase(props.userLanguage,'Lodging/Travel')}</option>
                                <option value="Marketing">{getTranslatedPhrase(props.userLanguage,'Marketing')}</option>
                                <option value="Medical">{getTranslatedPhrase(props.userLanguage,'Medical')}</option>
                                <option value="News/Media">{getTranslatedPhrase(props.userLanguage,'News/Media')}</option>
                                <option value="Pet Services">{getTranslatedPhrase(props.userLanguage,'Pet Services')}</option>
                                <option value="Real Estate">{getTranslatedPhrase(props.userLanguage,'Real Estate')}</option> 
                                <option value="Shopping">{getTranslatedPhrase(props.userLanguage,'Shopping')}</option>
                                <option value="Sports ">{getTranslatedPhrase(props.userLanguage,'Sports ')}</option>
                                <option value="Transportation ">{getTranslatedPhrase(props.userLanguage,'Transportation ')}</option>      
                            </select>
                        </div>

                        <div className="form-input-select">
                            <select value={form.adSource} onChange={(e) => changeProperty(e, 'adSource', 100, 'Select Reason')}>
                                <option value="Select Source">{getTranslatedPhrase(props.userLanguage,'How did you hear about us?')}</option>
                                <option value="Friends/Familly">{getTranslatedPhrase(props.userLanguage,'Friends/Familly')}</option>
                                <option value="Google Search">{getTranslatedPhrase(props.userLanguage,'Google Search')}</option>
                                <option value="Print Ad">{getTranslatedPhrase(props.userLanguage,'Print Ad')}</option>
                                <option value="Social Media">{getTranslatedPhrase(props.userLanguage,'Social Media')}</option>
                                <option value="TV">{getTranslatedPhrase(props.userLanguage,'TV')}</option>
                                <option value="Work">{getTranslatedPhrase(props.userLanguage,'Work')}</option>
                            </select>
                        </div>

                                {/* Business Category  */}

                                <div className="txtc fnts modal_body_functions_agree-terms form-input-lists">
                    <input type="checkbox" id="createAccountTerms" checked={agree} onChange={onChangeAgree} />
                    <label htmlFor="createAccountTerms">
                    <span><i className="fal fa-check" /></span> {getTranslatedPhrase(props.userLanguage, 'I have read and agree to the')} <Link target="_blank" to="/terms-of-service">{getTranslatedPhrase(props.userLanguage, 'terms of service')}</Link> and <Link target="_blank" to="/privacy">{getTranslatedPhrase(props.userLanguage, 'privacy policy')}</Link>.</label>
                </div>



                            <FormFieldError>{errorMessage}</FormFieldError>
                            <button className="button button-regular mgt-sm" onClick={onSubmitMessage}>{getTranslatedPhrase(props.userLanguage,'Send Request')}</button>
                            <ErrorMessages 
                                errorMessageClass={"error-message error-message-info"} 
                                errorMessageTitle={getTranslatedPhrase(props.userLanguage,'Success')} 
                                errorMessageBody={getTranslatedPhrase(props.userLanguage,'Your message has been sent')} show={formSubmitted}/>
                            
                        </div>
                    </div>
               
                    
                    
            </div>
        ); 

}


const mapStateToSmallBusinessesProps = (state) => {
    return {
        userLanguage: state.userLanguage
    };
};



export default connect(mapStateToSmallBusinessesProps, null)(withRouter(SmallBusinesses));