import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Analytics from 'react-router-ga';

import Header from './shared/components/Header/Header';
import Breadcrumb from './shared/uielements/Breadcrumb/Breadcrumb';
import MainContent from './AppMain';
import Footer from './shared/components/Footer/Footer';
// import CookieModal from './shared/components/Modals/Website/CookieModal';


const App = (props) => {

    const [wrapperClass, setWrapperClass] = useState('container home');

    useEffect(() => {
        if (window.location.pathname === '/invest')
            window.location = 'https://investbyfollowone.com';
        if (window.location.pathname === '/capital')
            window.location = 'https://followonecapital.com';
        if (window.location.pathname === '/startup')
            window.location = 'https://followonestartup.com';
    }, [])

    const propsWindowLocation = useSelector(state => state.windowLocation);

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const currentPage = Number(urlParams.get('page'));

        const sections = ['store', 'autos', 'autos-new', 'food', 'realestate', 'jobs', 'services', 'travel', 'news'];

        let noSlash = (propsWindowLocation.match(new RegExp("/", "g")) || []).length;
        let firstWord = propsWindowLocation.split('/')[1];

        if (propsWindowLocation === '/') noSlash = 0;
        if (sections.indexOf(firstWord) < 0) noSlash = 5;
        if (currentPage !== 0 && !/\/search/.test(propsWindowLocation)) noSlash = 2;
        if (/\/search\/filters/.test(propsWindowLocation)) noSlash = 3;
        if (/\/search/.test(propsWindowLocation) && firstWord !== 'search') noSlash = 3;
        // if (propsWindowLocation === '/chat') noSlash = 6;
        if (/\/chat/.test(propsWindowLocation)) noSlash = 6;

        let wrapperClasses = ['container wrapper-home', 'container wrapper-cat-home', 'container wrapper-cat-page', 'container wrapper-cat-page', 'container wrapper-listing', 'container wrapper-static', 'container wrapper-messages'];

        if (wrapperClass === wrapperClasses[noSlash]) {
            return;
        }

        setWrapperClass(wrapperClasses[noSlash]);
    }, [propsWindowLocation, wrapperClass])

    if (['/invest', '/capital', '/startup'].indexOf(window.location.pathname) >= 0) return null;

    return (
        <>
            {(process.env.NODE_ENV && process.env.NODE_ENV === 'development') ? (
                <>
                    <BrowserRouter>
                        {/* <Analytics id="UA-126827401-1"> */}
                            <div className={wrapperClass}>
                                <Header />
                                <Breadcrumb />
                                <MainContent />
                                <Footer />
                                {/* <CookieModal /> */}
                            </div>
                        {/* </Analytics> */}
                    </BrowserRouter>
                </>
            ) : (
                <>
                    <BrowserRouter>
                        {/* <Analytics id="UA-126827401-1"> */}
                            <div className={wrapperClass}>
                                <Header />
                                <Breadcrumb />
                                <MainContent />
                                <Footer />
                                {/* <CookieModal /> */}
                            </div>
                        {/* </Analytics> */}
                    </BrowserRouter>
                </>
            )}
        </>
    );
}


export default App;
