import React, { Component } from 'react';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { connect } from 'react-redux';
import actionType from '../../../redux-store/action-type';
import EditUserModal from '../../../shared/components/Modals/Account/EditUserModal';
import moment from 'moment';
import PageNumbers from '../../../shared/uielements/Pagination/page-numbers';
import Axios from 'axios';
import { hostNameAndPort } from '../../../shared/util/vars';
import LoadingSvg from '../../../shared/uielements/LoadingSpinner/LoadingSvg';
import { Link } from 'react-router-dom';
import SectionLoader from '../../../shared/util/section-loader';

class AdminArea extends Component {

    state = {
        showUserEditModal: false,
        userList: [],
        currentUser: null,
        itemId: '',
        waitingForDeleteConfirmation: false,
        deleteOperationConfirmed: false,
        currentPage: 1,
        usersPerPage: 20,
        userListLoaded: false,
        userCount: 0,
        // initialLoading: true,
        waitingForServer: false,
        // Filters
        filter: {},
        filterHasChanged: false,
        keywords: '',
        subscriptionFilter: 'Filter By Subscription',
        accountTypeFilter: 'Filter By Account Type',
        countryFilter: 'Filter By Country',
        userCountryList: [],
        Loader: null
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getUserCountryList();
        // trigger the update
        this.setState({ userListLoaded: true, currentPage: 0 });
    }

    initLoader = (page, formParams) => {
        this.setState({
            Loader: new SectionLoader({
                section: 'admin/user',
                cb: this.onLoad,
                options: {
                    page,
                    pageCount: this.state.usersPerPage,
                    formParams
                }
            })
        });
    }

    onLoad = (err) => {
        if (err && (process.env.NODE_ENV === 'development')) console.log(err);
        window.scrollTo(0, 0);
        this.setState({
            userListLoaded: true,
            userCount: this.state.Loader.totalCount,
            userList: this.state.Loader.items
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // Get the current page
        const urlParams = new URLSearchParams(window.location.search);
        const currentPage = Number(urlParams.get('page')) || 1;

        let formParams = {
            filter: JSON.stringify(this.state.filter),
        };
        if (this.state.filterHasChanged) {
            if (!this.state.Loader) {
                this.initLoader(currentPage, formParams);
                return;
            }
            this.state.Loader.updateFormParams(formParams);
            this.setState({ filterHasChanged: false });
        } else if (currentPage !== this.state.currentPage) {
            this.setState({
                currentPage,
                userListLoaded: false
            });
            if (!this.state.Loader) {
                this.initLoader(currentPage, formParams);
                return;
            }
            this.state.Loader.updatePage(currentPage);
        }

        if (this.state.deleteOperationConfirmed === this.props.deleteOperationConfirmed) {
            return;
        }
        if (!this.props.deleteOperationConfirmed) {
            this.setState({
                waitingForDeleteConfirmation: false,
                deleteOperationConfirmed: false,
                itemId: ''
            });
            return;
        }
        else {
            if (!!this.state.waitingForDeleteConfirmation) {
                this.onDeleteConfirmedNow(this.state.itemId);
                this.setState({
                    waitingForDeleteConfirmation: false,
                    deleteOperationConfirmed: false,
                    itemId: ''
                });
            }
            this.props.resetDeleteOperationConfirmation();
        }
    }

    onUpdatePageNumber = (n = 1) => {
        this.props.history.push(this.props.match.url + '?page=' + n);
    }

    onClickDeleteHandler = (id) => {
        this.setState({ waitingForDeleteConfirmation: true, itemId: id });
        this.props.onShowConfirmDeleteModal();
    }

    onUpdateList = (id) => {
        let a = this.state.userList;
        a = a.filter(v => (v.userId !== id));
        this.setState({ userList: [...a] });
    }

    onDeleteConfirmedNow = async (id) => {
        let _data = new FormData();
        _data.append(`userId`, id);
        let options = {
            url: `${hostNameAndPort}/api/admin/delete-account`,
            method: 'post',
            data: _data
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            // console.log(data)
            if (!data.error) {
                this.onUpdateList(id);
            }
        } catch (err) {
            alert(getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEADM01'));
        }
    }

    onClickNewUser = (e) => {
        this.setState({ showUserModal: true, currentUser: null });
    }

    onCloseUserModal = (_newUser) => {
        let a = this.state.userList;
        if (!!_newUser && !this.state.currentUser) {
            a.unshift(_newUser);
        } else if (!!_newUser) {
            for (let i in a) {
                if (a[i].userId === this.state.currentUser.userId) {
                    a[i] = _newUser;
                    break;
                }
            }
        }
        this.setState({ showUserModal: false, userList: a });
    }

    onEditUser = (c) => {
        this.setState({ currentUser: c || null, showUserModal: true });
    }

    onEditUserProfile = (c) => {
        window.open('/profile-update?slug=' + c.slug, '_nextWindow' + Date.now());
    }

    onDisableUser = async (u) => {
        this.setState({ waitingForServer: true });

        let newUserData = new FormData();
        newUserData.append('userId', u);
        let
            options = {
                url: `${hostNameAndPort}/api/admin/disable-account`,
                method: 'post',
                data: newUserData
            }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                // this.props.onClose();
                let a = this.state.userList;
                for (let i in a) {
                    if (a[i].userId === u) {
                        a[i].userStatus = 'disabled';
                        this.setState({ userList: a });
                        break;
                    }
                }
                this.setState({ waitingForServer: false });
            } else {
                this.setState({ waitingForServer: false });
            }
            // console.log(data);

        } catch (err) {
            this.setState({ waitingForServer: false });
        }
    }

    onEnableUser = async (u) => {
        this.setState({ waitingForServer: true });

        let newUserData = new FormData();
        newUserData.append('userId', u);
        let
            options = {
                url: `${hostNameAndPort}/api/admin/enable-account`,
                method: 'post',
                data: newUserData
            }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                // this.props.onClose();
                let a = this.state.userList;
                for (let i in a) {
                    if (a[i].userId === u) {
                        a[i].userStatus = 'enabled';
                        this.setState({ userList: a });
                        break;
                    }
                }
                this.setState({ waitingForServer: false });
            } else {
                this.setState({ waitingForServer: false });
            }
            // console.log(data);

        } catch (err) {
            this.setState({ waitingForServer: false });
        }
    }

    onClickSubscriptionFilter = () => {
        let possibleValues = ['', 'free', 'basic', 'premium', 'enterprise'];
        let sLabels = ['Filter By Subscription', 'Subscription: Social', 'Subscription: Basic', 'Subscription: Premium', 'Subscription: Enterprise'];
        let findSubscription = sLabels.indexOf(this.state.subscriptionFilter);
        let sindex = (findSubscription + 1) % 5;

        let newFilter = this.state.filter;
        delete newFilter.userLevel;
        if (!sindex) {
            delete newFilter.subscription;
        } else {
            newFilter.subscription = possibleValues[sindex];
        }
        this.setState({ subscriptionFilter: sLabels[sindex], filter: newFilter, filterHasChanged: true, userListLoaded: false });
    }

    onClickCustomerServiceFilter = () => {
        let newFilter = {
            userLevel: 'customer-service'
        }
        this.setState({ filter: newFilter, filterHasChanged: true, userListLoaded: false });
    }

    onClickAccountTypeFilter = () => {
        let possibleValues = ['', 'personal', 'business', 'organization', 'institution', 'public-figure'];
        let sLabels = ['Filter By Account Type', 'Type: Personal', 'Type: Business', 'Type: Organization', 'Type: Institution', 'Type: Public Figure'];
        let findAccountType = sLabels.indexOf(this.state.accountTypeFilter);
        let sindex = (findAccountType + 1) % 6;

        let newFilter = this.state.filter;
        delete newFilter.userLevel;
        if (!sindex) {
            delete newFilter.accountType;
        } else {
            newFilter.accountType = possibleValues[sindex];
        }
        this.setState({ accountTypeFilter: sLabels[sindex], filter: newFilter, filterHasChanged: true, userListLoaded: false });
    }

    onClickCountryFilter = () => {
        let possibleValues = ['', ...this.state.userCountryList];
        let sLabels = ['Filter By Country'];
        let skipFirst = true;
        for (let e of possibleValues) {
            if (skipFirst) {
                skipFirst = false;
                continue;
            }
            sLabels.push('Country: ' + e);
        }
        let findCountry = sLabels.indexOf(this.state.countryFilter);
        let n = possibleValues.length
        let sindex = (findCountry + 1) % n;

        let newFilter = this.state.filter;
        delete newFilter.userLevel;
        if (!sindex) {
            delete newFilter.country;
        } else {
            newFilter.country = possibleValues[sindex];
        }
        this.setState({ countryFilter: sLabels[sindex], filter: newFilter, filterHasChanged: true, userListLoaded: false });
    }

    getUserCountryList = async () => {
        try {
            let options = `${hostNameAndPort}/api/admin/country-list`;
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                if (!data.userCountryList) {
                    data.userCountryList = [];
                }
                this.setState({ userCountryList: data.userCountryList });
            }
        } catch (err) {
            // Error
        }
    }

    onChangeKeywordsHandler = (e) => {
        let v = e.target.value;
        if (v.length > 40 || /\W/.test(v)) {
            return;
        }
        this.setState({ keywords: v });
    }

    onClickSearchUsers = () => {
        let newFilter = this.state.filter;
        if (!this.state.keywords) {
            delete newFilter.keywords;
        } else {
            newFilter.keywords = this.state.keywords;
        }
        this.setState({ filter: newFilter, filterHasChanged: true, userListLoaded: false });
    }

    getColorForUserStatus = (color) => {
        switch (color) {
            case 'enabled':
                return '#ffffff';
            case 'verified':
                return '#ffffff';
            case 'deleted':
                return '#ffaaaa';
            case 'disabled':
            default:
                return '#aaaaaa'
        }
    }

    render() {
        if (!this.props.userIsAuthenticated) this.props.history.push('/');
        return (
            <div className="main-100 super-admin">

                <div className="main-100-header">
                    <h1>Manage Users</h1>
                    <p>This is the administration page, so be careful what you do!</p>
                </div>

                <div className="manage-coupons-box">
                    <h4>There are {this.state.userCount} users</h4>

                    <div className="row super-admin-nav">
                        <div className="super-admin-filter">
                            <button className="button button-regular button-small" onClick={this.onClickSubscriptionFilter}>{this.state.subscriptionFilter}</button>
                            <button className="button button-regular button-small" onClick={this.onClickAccountTypeFilter}>{this.state.accountTypeFilter}</button>
                            <button className="button button-regular button-small" onClick={this.onClickCountryFilter}>{this.state.countryFilter}</button>
                            <button className="button button-regular button-small" onClick={this.onClickCustomerServiceFilter}>CUSTOMER SERVICE</button>
                        </div>


                        <div className="super-admin-search">
                            <input type="text" placeholder="Keywords" value={this.state.keywords} onChange={this.onChangeKeywordsHandler} />
                            <button className="button button-regular button-small" onClick={this.onClickSearchUsers}>Go</button>
                        </div>
                    </div>

                    <LoadingSvg active={!this.state.userListLoaded} />

                    <table className="super-admin-users-table">
                        <tbody>
                            <tr className="table-header">
                                <td>First Name</td>
                                <td>Last Name</td>
                                <td>Entity Name</td>
                                <td>Email</td>
                                <td>Account Type</td>
                                <td>Subscription</td>
                                <td>Customer Service</td>
                                <td>Joined</td>
                                <td>Views</td>
                                <td>Affiliate</td>
                                <td>Admin</td>
                            </tr>

                            {this.state.userList.map((v) => (<tr key={v.email} style={{ backgroundColor: this.getColorForUserStatus(v.userStatus) }}>
                                <td>{v.firstName}</td>
                                <td>{v.lastName}</td>
                                <td>{(!v.slug) ? v.entityName : (<Link to={v.slug}>{v.entityName}</Link>)}</td>
                                <td>{v.email}</td>
                                <td>{v.accountType}</td>
                                <td>{(v.subscription === 'free') ? 'social' : v.subscription} {(!v.futureSubscriptionStartDate) ? null : (<div><h4>{'> '}{(v.futureSubscription === 'free') ? 'social' : v.futureSubscription}</h4>{` on ${moment(v.futureSubscriptionStartDate).format('L')}`}</div>)}</td>
                                <td>{v.customerService ? 'yes' : 'no'}</td>
                                <td>{moment(v.joined).format('L')}</td>
                                <td>{v.viewCount}</td>

                                {/* Can be: N/A (for no affiliates), Reseller, Cashin */}
                                <td>{(!v.affiliateId && !v.resellerId) ? 'n/a' : (!v.affiliateId) ? 'reseller' : 'cashin'}</td>
                                <td>{!this.props.loggedInUser ? 'Edit' : (this.props.loggedInUser.userLevel === 'admin') ? (
                                    <button onClick={() => this.onEditUser(v)}>Edit</button>
                                ) : (this.props.loggedInUser.userLevel === 'customer-service') ? (
                                    <button onClick={() => this.onEditUserProfile(v)}>Edit</button>
                                ) : 'Edit'}
                                    {/* {v.userStatus === 'enabled' && (
                                    <button onClick={() => this.onDisableUser(v.userId)}>Disable</button>)} 
                                    {v.userStatus === 'disabled' && (
                                    <button onClick={() => this.onEnableUser(v.userId)}>Enable</button>)}
                                    <span>/</span>
                                    <button onClick={() => this.onClickDeleteHandler(v.userId)}>Delete</button> 
                                    <LoadingSvg show={this.state.waitingForServer} /> */}
                                </td>
                            </tr>))}
                        </tbody>
                    </table>

                    {/* <button onClick={this.onClickNewUser} className="button button-regular"><i className="fal fa-plus"></i>New User</button> */}
                </div>

                <PageNumbers
                    count={Math.ceil(this.state.userCount / this.state.usersPerPage)}
                    activePage={this.state.currentPage}
                    onClick={this.onUpdatePageNumber} />


                <div className="clb" />

                <EditUserModal
                    active={this.state.showUserModal}
                    onClose={this.onCloseUserModal}
                    oldUser={this.state.currentUser}
                    couponCurrency={'$'}
                />
            </div>
        );
    }
}

const mapStateToAdminAreaProps = (
    state,
    props
) => {
    return {
        deleteOperationConfirmed: state.deleteOperationConfirmed,
        userLanguage: state.userLanguage,
        userIsAuthenticated: state.userIsAuthenticated,
        loggedInUser: state.loggedInUser
    };
};

const mapDispatchToAdminAreaProps = (
    dispatch
) => {
    return {
        // onShowAdvertiseModalHandler: (adItemType, adItemId) => {
        //     dispatch({type: actionType.ADVERTISE_MODAL, advertiseModalActive: true, advertiseItemType: adItemType, advertiseItemId: adItemId });
        // },
        onShowConfirmDeleteModal: () => {
            dispatch({ type: actionType.CONFIRM_DELETE_OPERATION_MODAL, confirmDeleteOperationModalActive: true, confirmDeleteOperationModalMessage: '' });
        },
        resetDeleteOperationConfirmation: () => {
            dispatch({ type: actionType.DELETE_OPERATION, deleteOperationConfirmed: false });
        },
        // showAlert: (m, r, s) => {
        //     dispatch({type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: m, alertModalRedirect: r, alertModalButtonLabel: s });
        // },
    };
};

export default connect(mapStateToAdminAreaProps, mapDispatchToAdminAreaProps)(AdminArea);