import React from "react";
import ListingPage from "../../../../shared/templates/ListingTemplate/listing-edit";
import JobEditFields from "./JobEditFields";

function JobEdit(props) {
  function checkReqs(item) {
    let m = "";

    if (item.title === "") {
      m = "Title is required.";
    }
    if (item.department === "") {
      m = "Department is required.";
    }
    if (!item.description.trim()) {
      m = "Description is required.";
    }
    if (!item.newAddress || item.newAddress === "choose-where-to-buy") {
      m = "Address is required.";
    }
    if (item.newAddress === "new-address") {
      if (!item.state || item.state === "choose-state") {
        m = "State is required.";
      }
      if (!item.country || item.country === "choose-country" || item.country === '-1') {
        m = "Please select your country.";
      }
      if (!item.city) {
        m = "Please add your city.";
      }
      if (!item.zipCode) {
        m = "Zip Code is required.";
      }
    }
    if (!item.experienceLevel || item.experienceLevel === "experience-level") {
      m = "Experience level is required.";
    }
    if (!item.category || item.category === "select-category") {
      m = "Select a valid category.";
    }
    // if (!item.subcategory || item.subcategory === "select-subcategory") {
    //   m = "Select a valid subcategory.";
    // }
    if (!item.jobType || item.jobType === "job-type") {
      m = "Job Type is required.";
    }
    if (!item.featuredImage) {
      m = "You need to choose the main photo.";
    }

    return m;
  }

  return (
    <ListingPage
      section="jobs"
      editListingUrl="/edit-job"
      itemType="Job Listing"
      mainSubtitle="Find the right candidate."
      checkRequirements={checkReqs}
      ItemFields={JobEditFields}
    />
  );
}

export default JobEdit;
