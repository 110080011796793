import React, { useEffect, useState } from "react";
// import ProfileSettingsNav from "../ProfileNav/ProfileSettingsNav";
import ProfileSettingsBox from "../ProfileSettingsBox";
import FormFieldError from "../../../../shared/uielements/ErrorMessages/FormFieldError";
import ErrorMessages from "../../../../shared/uielements/ErrorMessages/ErrorMessages";
import LoadingSvg from "../../../../shared/uielements/LoadingSpinner/LoadingSvg";
import CropImageModal from "../../../../shared/util/crop-image-modal";
import Box from "../../../../shared/util/box";
import { titleCase } from "../../../../shared/util/string";

import { hostNameAndPort } from "../../../../shared/util/vars";
import actionType from "../../../../redux-store/action-type";
import { connect, useSelector } from "react-redux";
import Axios from "axios";
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import {
  populateStates,
  populateCountries,
} from "../../../../shared/util/countries";
import SettingsMenu from "../SettingsMenu";

var _profileImageRef = "";
var _coverImageRef = "";

function ProfileUpdate(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userId, setUserId] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [aboutMe, setAboutMe] = useState("");
  const [education, setEducation] = useState("");
  const [entityName, setEntityName] = useState("");
  const [entityFounded, setEntityFounded] = useState("");
  const [accountType, setAccountType] = useState("personal");
  const [entityContact, setEntityContact] = useState({
    "Customer Service Phone #": "",
    "Customer Service Email": "",
    Website: "",
    Address: "",
    Unit: "",
    City: "",
  });
  const [entityPolicies, setEntityPolicies] = useState({});
  const [entityDetails, setEntityDetails] = useState({
    Payment: [],
  });
  const [countryElementId, setCountryElementId] = useState("");
  const [stateElementId, setStateElementId] = useState("");
  const [countriesLoaded, setCountriesLoaded] = useState(false);
  const [profileInfoSaved, setProfileInfoSaved] = useState(false);
  const [businessCategories, setBusinessCategories] = useState(["other"]);
  const [userName, setUserName] = useState("User");
  const [openModalProfile, setOpenModalProfile] = useState(false);
  const [openModalCover, setOpenModalCover] = useState(false);
  const [temp, setTemp] = useState({
    column: 1,
    errorMessage: "",
    waitingForAnswer: false,
    accountTypeCapitalized: "",
  });
  // const [_coverImageRef, setCoverImageRef] = useState(null);
  // const [_profileImageRef, setProfileImageRef] = useState(null);
  // const [coverImage, setCoverImage] = useState("");
  //   const [profileImage, setProfileImage] = useState("");
  // const [profileImageFile, setProfileImageFile] = useState("");
  // const [coverImageFile, setCoverImageFile] = useState("");
  const [imageHasChanged, setImageHasChanged] = useState(1);
  const userIsAuthenticated = useSelector((state) => state.userIsAuthenticated);
  const userLanguage = useSelector((state) => state.userLanguage);
  const history = useHistory();

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getProfileData();
  }, [_profileImageRef, _coverImageRef, imageHasChanged]);

  useEffect(() => {
    return function () {
      _profileImageRef = "";
      _coverImageRef = "";
    };
  });

  const b1 = document.getElementById("stateElementForProfileUpdate");
  const b2 = document.getElementById("countryElementForProfileUpdate");
  const b2Len = b2 && b2.options.length > 1 ? b2.options.length : 0;
  const b1Len = b1 && b1.options.length ? b1.options.length : 0;
  const Country = entityContact.Country;

  useEffect(() => {
    if (
      countryElementId &&
      stateElementId &&
      b1 &&
      // && b1Len
      b2 &&
      b2Len
    ) {
      let cc = b2;
      let i = 0;
      for (; i < cc.options.length; i++)
        if (cc.options[i].value === entityContact.Country) break;
      if (i < cc.options.length) cc.selectedIndex = i;
      else cc.selectedIndex = 0;
      populateStates(countryElementId, stateElementId);
      cc = b1;
      i = 0;
      for (; i < cc.options.length; i++)
        if (cc.options[i].value === entityContact.State) break;

      if (i < cc.options.length) cc.selectedIndex = i;
      else cc.selectedIndex = 0;
    }
  }, [b1, b2, b1Len, b2Len, Country]);

  async function getCategories() {
    let url = `${hostNameAndPort}/api/user/businesscategories`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        setBusinessCategories(data.categories);
      }
    } catch (err) {
      // Network error
    }
  }

  async function getProfileData() {
    try {
      if (_coverImageRef && _profileImageRef)
        // && _coverImageRef.children.length)
        return;

      const urlParams = new URLSearchParams(window.location.search);
      const currentSlug = urlParams.get("slug") || "";

      let url = hostNameAndPort + "/api/user";
      if (currentSlug) url = url + "/slug/" + currentSlug;
      let result = await Axios(url);
      let { data } = result;
      if (data.error) throw Error("User not authenticated.");
      setTemp({
        ...temp,
        accountTypeCapitalized: data.user.accountType
          .replace("-", " ")
          .replace(/(^|\s)\S/g, (l) => l.toUpperCase()),
      });
      let image = new Image();
      image.src = data.user.profileImage;
      image.alt = "Profile";
      let myNode = _profileImageRef;
      if (myNode) {
        while (myNode.firstChild) {
          myNode.removeChild(myNode.firstChild);
        }
        _profileImageRef.appendChild(image);
      }
      image = new Image();
      image.src = data.user.coverImage;
      image.alt = "Cover";
      myNode = _coverImageRef;
      if (myNode) {
        while (myNode.firstChild) {
          myNode.removeChild(myNode.firstChild);
        }
        _coverImageRef.appendChild(image);
      }

      const changeNow = {
        userId: setUserId,
        firstName: setFirstName,
        lastName: setLastName,
        aboutMe: setAboutMe,
        userName: setUserName,
        education: setEducation,
        maritalStatus: setMaritalStatus,
        entityName: setEntityName,
        accountType: setAccountType,
        entityFounded: setEntityFounded,
        // profileImage: setProfileImage,
        // coverImage: setCoverImage,
        entityDetails: setEntityDetails,
        entityContact: setEntityContact,
        entityPolicies: setEntityPolicies,
      };
      let userKeys = Object.keys(data.user);
      for (let i of userKeys) {
        if (!!changeNow[i]) changeNow[i](data.user[i]);
      }
    } catch (err) {
      if (!userIsAuthenticated) history.push("/");
    }
  }

  async function saveUserInfo(column) {
    if (!userInfoIsValid(column)) {
      return;
    }
    if (profileInfoSaved) {
      setTemp({
        ...temp,
        column,
        errorMessage: getTranslatedPhrase(
          userLanguage,
          "Your information is already saved"
        ),
      });
      return;
    }
    let _state = {
      userId,
      firstName,
      lastName,
      maritalStatus,
      entityName,
      aboutMe,
      education,
      userName,
      // profileImage,
      // coverImage,
      // profileImageFile,
      // coverImageFile,
      entityFounded,
    };

    let b = businessCategories;

    if (!entityDetails.Licensed) {
      entityDetails.Licensed = "n/a";
    }
    if (!entityDetails.Bonded) {
      entityDetails.Bonded = "n/a";
    }
    if (!entityDetails.Insured) {
      entityDetails.Insured = "n/a";
    }
    if (!entityDetails.Discount) {
      entityDetails.Discount = "n/a";
    }
    if (!entityDetails.Warranties) {
      entityDetails.Warranties = "n/a";
    }
    if (!entityContact["State"] || entityContact["State"] === "select-state") {
      entityContact["State"] = "n/a";
    }

    let _data = new FormData();
    for (let i in _state) {
      if (!_state[i]) {
        _state[i] = "";
      }
      _data.append(i, _state[i]);
    }
    _data.append("entityPolicies", JSON.stringify(entityPolicies));
    _data.append("entityDetails", JSON.stringify(entityDetails));
    _data.append("entityContact", JSON.stringify(entityContact));

    setBusinessCategories(b);
    setTemp({ ...temp, waitingForAnswer: true });
    let options = {
      url: hostNameAndPort + "/api/user/update",
      method: "post",
      data: _data,
    };
    try {
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        setProfileInfoSaved(true);
        setTemp({ ...temp, column, waitingForAnswer: false });
        setTimeout(() => {
          props.onUpdateUserProfile(data.user);
        }, 1000);
      } else {
        setTemp({
          ...temp,
          errorMessage: data.error,
          column,
          waitingForAnswer: false,
        });
      }
    } catch (err) {
      // Network error
      console.log(err);
      setTemp({
        ...temp,
        column,
        errorMessage: getTranslatedPhrase(
          userLanguage,
          "Network error ERRFEPRFUPD01"
        ),
        waitingForAnswer: false,
      });
    }
  }

  function userInfoIsValid(column) {
    let errorMsg = "",
      _verified = true;
    let rn = /[*|";:,?`~!@#$%^&=)(><}{\\0-9]/g;
    let ri = /[*|;:?`~!@#$%^&=)(><}{\\]/g;

    if (!!firstName && rn.test(firstName)) {
      errorMsg = getTranslatedPhrase(
        userLanguage,
        "Your first name cannot include special characters"
      );
      _verified = false;
    }
    if (!!lastName && rn.test(lastName)) {
      errorMsg = getTranslatedPhrase(
        userLanguage,
        "Your last name cannot include special characters"
      );
      _verified = false;
    }
    if (!!education && ri.test(education)) {
      errorMsg = getTranslatedPhrase(
        userLanguage,
        "Your education cannot include special characters"
      );
      _verified = false;
    }
    if (errorMsg) setTemp({ ...temp, errorMessage: errorMsg, column });
    return _verified;
  }

  function onChangePropertyHandler(e, p, lim) {
    if (e.target.value.length > lim) {
      return;
    }
    const changeNow = {
      firstName: setFirstName,
      lastName: setLastName,
      aboutMe: setAboutMe,
      education: setEducation,
      maritalStatus: setMaritalStatus,
      entityName: setEntityName,
      entityFounded: setEntityFounded,
    };

    changeNow[p](e.target.value);
    setProfileInfoSaved(false);
    setTemp({ ...temp, errorMessage: "" });
  }

  function onChangeEntityContactHandler(e, p, lim) {
    if (e.target.value.length > lim) {
      return;
    }
    setEntityContact({ ...entityContact, [p]: e.target.value });
    setTemp({ ...temp, errorMessage: "" });
    setProfileInfoSaved(false);
  }

  function onChangeEntityDetailsHandler(e, p, lim) {
    if (e.target.value.length > lim) {
      return;
    }
    setEntityDetails({ ...entityDetails, [p]: e.target.value });
    setTemp({ ...temp, errorMessage: "" });
    setProfileInfoSaved(false);
  }

  function onChangeEntityPoliciesHandler(e, p, lim) {
    if (e.target.value.length > lim) {
      return;
    }
    setEntityPolicies({ ...entityPolicies, [p]: e.target.value });
    setTemp({ ...temp, errorMessage: "" });
    setProfileInfoSaved(false);
  }

  function onChangeEntityDetailsPayment(e) {
    let _payment = entityDetails.Payment;

    if (_payment.indexOf(e.target.id) > -1) {
      _payment = _payment.filter((v) => v !== e.target.id);
    } else {
      _payment.push(e.target.id);
    }
    setEntityDetails({ ...entityDetails, Payment: _payment });
    setTemp({ ...temp, errorMessage: "" });
    setProfileInfoSaved(false);
  }

  function getCroppedProfileImage(f) {
    setOpenModalProfile(false);
    setImageHasChanged((prev) => prev + 1);
  }

  function getCroppedCoverImage(f) {
    setOpenModalCover(false);
    setImageHasChanged((prev) => prev + 1);
  }

  function onClickToChangeProfileImage() {
    setOpenModalProfile(true);
  }

  function onClickToChangeCoverImage() {
    setOpenModalCover(true);
  }

  function aboutMeBox(c, m) {
    if (c) {
      return (
        <textarea
          className="form-input-textarea form-input-full"
          placeholder={m}
          value={aboutMe}
          onChange={(e) => onChangePropertyHandler(e, "aboutMe", 1000)}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | Followone</title>
        <meta
          name="description"
          content="Sell products, cars, properties & travel packages, post jobs & services, find deals & coupons and connect with customers, friends & family."
        />
        <link rel="canonical" href={"https://followone.com/"} />
      </Helmet>

      <SettingsMenu ProfileLiActive="active">
        <div className="profile-update-settings">
          <ProfileSettingsBox>
            <div className="profile-update-settings_box">
              <h4>
                {getTranslatedPhrase(
                  userLanguage,
                  "Update Your Profile Photos"
                )}
              </h4>

              <div className="update-profile-photo-settings">
                <div>
                  <div
                    className="photo-placeholder photo-placeholder_profile"
                    ref={(n) => {
                      if (!_profileImageRef) _profileImageRef = n;
                    }}
                  />
                  <button
                    className="button button-clear"
                    onClick={onClickToChangeProfileImage}
                  >
                    {getTranslatedPhrase(userLanguage, "New Profile Photo")}
                  </button>
                </div>

                <div>
                  <div
                    className="photo-placeholder photo-placeholder_cover"
                    ref={(n) => {
                      if (!_coverImageRef) _coverImageRef = n;
                    }}
                  />
                  <button
                    className="button button-clear"
                    onClick={onClickToChangeCoverImage}
                  >
                    {getTranslatedPhrase(userLanguage, "New Cover Photo")}
                  </button>
                </div>
              </div>
            </div>
          </ProfileSettingsBox>

          <ProfileSettingsBox>
            <div className="profile-update-settings_box">
              <h4>
                {getTranslatedPhrase(userLanguage, "Your Personal Information")}
              </h4>

              <div className="flx flx-2">
                <input
                  className="form-input-text form-input-full mgb-sm flx-item"
                  type="text"
                  placeholder={getTranslatedPhrase(userLanguage, "First Name")}
                  value={firstName}
                  onChange={(e) => onChangePropertyHandler(e, "firstName", 30)}
                />
                <input
                  className="form-input-text form-input-full mgb-sm flx-item"
                  type="text"
                  placeholder={getTranslatedPhrase(userLanguage, "Last Name")}
                  value={lastName}
                  onChange={(e) => onChangePropertyHandler(e, "lastName", 30)}
                />
              </div>

              <div className="flx flx-2">
                <input
                  className="form-input-text form-input-full mgb-sm flx-item"
                  type="text"
                  placeholder={getTranslatedPhrase(userLanguage, "Education")}
                  value={education}
                  onChange={(e) => onChangePropertyHandler(e, "education", 50)}
                />
                <input
                  className="form-input-text form-input-full mgb-sm flx-item"
                  type="text"
                  placeholder={getTranslatedPhrase(
                    userLanguage,
                    "Marital Status"
                  )}
                  value={maritalStatus}
                  onChange={(e) =>
                    onChangePropertyHandler(e, "maritalStatus", 20)
                  }
                />
              </div>

              {aboutMeBox(
                accountType === "personal",
                getTranslatedPhrase(
                  userLanguage,
                  "Tell your friends something about you..."
                )
              )}
            </div>

            {/* {(accountType === 'personal')?null:(<Box> */}

            <FormFieldError>
              {temp.column === 1
                ? getTranslatedPhrase(userLanguage, temp.errorMessage)
                : ""}
            </FormFieldError>
            <button
              className="button button-regular mgt-md"
              onClick={(e) => saveUserInfo(1)}
            >
              {getTranslatedPhrase(userLanguage, "Update Profile")}
            </button>
            <LoadingSvg active={temp.waitingForAnswer && temp.column === 1} />
            <ErrorMessages
              errorMessageClass={"error-message error-message-info"}
              errorMessageTitle={getTranslatedPhrase(userLanguage, "Success")}
              errorMessageBody={getTranslatedPhrase(
                userLanguage,
                "Your profile information has been saved"
              )}
              show={profileInfoSaved && temp.column === 1}
            />
          </ProfileSettingsBox>

          <ProfileSettingsBox>
            <h4>
              {getTranslatedPhrase(
                userLanguage,
                "Update Your " +
                  (accountType === "personal"
                    ? "Contact"
                    : temp.accountTypeCapitalized) +
                  " Information"
              )}
            </h4>

            {accountType === "personal" ? null : (
              <Box>
                <input
                  className="form-input-text form-input-full mgb-sm"
                  type="text"
                  placeholder={temp.accountTypeCapitalized + " Name"}
                  value={entityName}
                  onChange={(e) => onChangePropertyHandler(e, "entityName", 30)}
                />

                {aboutMeBox(
                  [
                    "institution",
                    "organization",
                    "public-figure",
                    "business",
                  ].indexOf(accountType) >= 0,
                  getTranslatedPhrase(
                    userLanguage,
                    "Tell us about your business / organization."
                  )
                )}

                <div className="form-input-select mgb-sm mgt-sm">
                  <select
                    className="form-input-full"
                    value={entityContact["Category"]}
                    onChange={(e) =>
                      onChangeEntityContactHandler(e, "Category", 200)
                    }
                  >
                    <option value="select-category">
                      {getTranslatedPhrase(userLanguage, "Select Category")}
                    </option>
                    {businessCategories.map((v, i) => (
                      <option key={i} value={v}>
                        {getTranslatedPhrase(
                          userLanguage,
                          titleCase(v.replace(/-/g, " "))
                        )}
                      </option>
                    ))}
                  </select>
                </div>

                <input
                  className="form-input-text form-input-full mgb-sm"
                  type="text"
                  placeholder={getTranslatedPhrase(userLanguage, "Founded")}
                  value={entityFounded}
                  onChange={(e) =>
                    onChangePropertyHandler(e, "entityFounded", 20)
                  }
                />
              </Box>
            )}

            <h4 className="mgt-lg">Contact</h4>

            <div className="flx flx-3">
              <input
                className="form-input-text form-input-full mgb-sm flx-item"
                type="text"
                placeholder={getTranslatedPhrase(
                  userLanguage,
                  "Public Phone #"
                )}
                value={entityContact["Customer Service Phone #"]}
                onChange={(e) =>
                  onChangeEntityContactHandler(
                    e,
                    "Customer Service Phone #",
                    15
                  )
                }
              />
              <input
                className="form-input-text form-input-full mgb-sm flx-item"
                type="text"
                placeholder={getTranslatedPhrase(userLanguage, "Public Email")}
                value={entityContact["Customer Service Email"]}
                onChange={(e) =>
                  onChangeEntityContactHandler(e, "Customer Service Email", 80)
                }
              />
              <input
                className="form-input-text form-input-full mgb-sm flx-item"
                type="text"
                placeholder={getTranslatedPhrase(userLanguage, "Website")}
                value={entityContact["Website"]}
                onChange={(e) =>
                  onChangeEntityContactHandler(e, "Website", 255)
                }
              />
            </div>

            <div className="flx flx-2">
              <input
                className="form-input-text form-input-full mgb-sm flx-item-lg"
                type="text"
                placeholder={getTranslatedPhrase(userLanguage, "Address")}
                value={entityContact["Address"]}
                onChange={(e) => onChangeEntityContactHandler(e, "Address", 75)}
              />

              <input
                className="form-input-text form-input-full mgb-sm flx-item-sm"
                type="text"
                placeholder={getTranslatedPhrase(userLanguage, "Unit")}
                value={entityContact["Unit"]}
                onChange={(e) => onChangeEntityContactHandler(e, "Unit", 15)}
              />
            </div>

            <div className="flx flx-4">
              <div className="form-input-select mgb-sm flx-item">
                <select
                  className="form-input-full"
                  id="countryElementForProfileUpdate"
                  ref={(n) => {
                    if (!countryElementId)
                      setCountryElementId("countryElementForProfileUpdate");
                    if (
                      countryElementId &&
                      stateElementId &&
                      !countriesLoaded
                    ) {
                      populateCountries(countryElementId, stateElementId);
                      setCountriesLoaded(true);
                    }
                  }}
                  value={entityContact["Country"]}
                  onChange={(e) =>
                    onChangeEntityContactHandler(e, "Country", 85)
                  }
                />
              </div>

              <div className="form-input-select mgb-sm flx-item">
                <select
                  className="form-input-full"
                  id="stateElementForProfileUpdate"
                  ref={(n) => {
                    if (!stateElementId)
                      setStateElementId("stateElementForProfileUpdate");
                    if (
                      countryElementId &&
                      stateElementId &&
                      !countriesLoaded
                    ) {
                      // populateCountries(countryElementId, stateElementId);
                      setCountriesLoaded(true);
                    }
                  }}
                  value={entityContact["State"]}
                  onChange={(e) => onChangeEntityContactHandler(e, "State", 85)}
                ></select>
              </div>

              <input
                className="form-input-text form-input-full mgb-sm flx-item"
                type="text"
                placeholder={getTranslatedPhrase(userLanguage, "City")}
                value={entityContact["City"]}
                onChange={(e) => onChangeEntityContactHandler(e, "City", 85)}
              />

              <input
                className="form-input-text form-input-full mgb-sm flx-item"
                type="text"
                placeholder={getTranslatedPhrase(userLanguage, "Zip Code")}
                value={entityContact["Zip Code"]}
                onChange={(e) =>
                  onChangeEntityContactHandler(e, "Zip Code", 10)
                }
              />
            </div>

            <FormFieldError>
              {temp.column === 2
                ? getTranslatedPhrase(userLanguage, temp.errorMessage)
                : ""}
            </FormFieldError>
            <button
              className="button button-regular mgt-md"
              onClick={(e) => saveUserInfo(2)}
            >
              {getTranslatedPhrase(userLanguage, "Update Profile")}
            </button>
            <LoadingSvg active={temp.waitingForAnswer && temp.column === 2} />
            <ErrorMessages
              errorMessageClass={"error-message error-message-info"}
              errorMessageTitle={getTranslatedPhrase(userLanguage, "Success")}
              errorMessageBody={getTranslatedPhrase(
                userLanguage,
                "Your profile information has been saved"
              )}
              show={profileInfoSaved && temp.column === 2}
            />
          </ProfileSettingsBox>

          <ProfileSettingsBox>
            <div className="business-options">
              {accountType === "personal" ? null : (
                <Box>
                  <h4>
                    {getTranslatedPhrase(
                      userLanguage,
                      "Update Your " + temp.accountTypeCapitalized + " Details"
                    )}
                  </h4>

                  <input
                    className="form-input-text form-input-full mgb-sm"
                    type="text"
                    placeholder={getTranslatedPhrase(userLanguage, "Owner")}
                    value={entityDetails["Owner"]}
                    onChange={(e) =>
                      onChangeEntityDetailsHandler(e, "Owner", 60)
                    }
                  />
                  <input
                    className="form-input-text form-input-full mgb-sm"
                    type="text"
                    placeholder={getTranslatedPhrase(userLanguage, "Hours")}
                    title="E.g. M-F / 8 AM - 4:30 PM"
                    value={entityDetails["Hours"]}
                    onChange={(e) =>
                      onChangeEntityDetailsHandler(e, "Hours", 20)
                    }
                  />

                  <div className="business-options-box">
                    <p>{getTranslatedPhrase(userLanguage, "Licensed")}</p>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="licensed-radio-group-yes"
                        type="radio"
                        name="licensedRadioGroup"
                        value="yes"
                        onChange={() => null}
                        checked={entityDetails["Licensed"] === "yes"}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Licensed", 10)
                        }
                      />
                      <label htmlFor="licensed-radio-group-yes">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "Yes")}
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="licensed-radio-group-no"
                        type="radio"
                        name="licensedRadioGroup"
                        value="no"
                        onChange={() => null}
                        checked={entityDetails["Licensed"] === "no"}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Licensed", 10)
                        }
                      />
                      <label htmlFor="licensed-radio-group-no">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "No")}
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="licensed-radio-group-na"
                        type="radio"
                        name="licensedRadioGroup"
                        value="n/a"
                        onChange={() => null}
                        checked={entityDetails["Licensed"] === "n/a"}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Licensed", 10)
                        }
                      />
                      <label htmlFor="licensed-radio-group-na">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "N/A")}
                      </label>
                    </div>
                  </div>

                  <div className="business-options-box">
                    <p>{getTranslatedPhrase(userLanguage, "Bonded")}</p>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="bonded-radio-group-yes"
                        type="radio"
                        name="bondedRadioGroup"
                        value="yes"
                        onChange={() => null}
                        checked={entityDetails["Bonded"] === "yes"}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Bonded", 10)
                        }
                      />
                      <label htmlFor="bonded-radio-group-yes">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "Yes")}
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="bonded-radio-group-no"
                        type="radio"
                        name="bondedRadioGroup"
                        value="no"
                        onChange={() => null}
                        checked={entityDetails["Bonded"] === "no"}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Bonded", 10)
                        }
                      />
                      <label htmlFor="bonded-radio-group-no">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "No")}
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="bonded-radio-group-na"
                        type="radio"
                        name="bondedRadioGroup"
                        value="n/a"
                        onChange={() => null}
                        checked={entityDetails["Bonded"] === "n/a"}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Bonded", 10)
                        }
                      />
                      <label htmlFor="bonded-radio-group-na">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "N/A")}
                      </label>
                    </div>
                  </div>

                  <div className="business-options-box">
                    <p>{getTranslatedPhrase(userLanguage, "Insured")}</p>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="insured-radio-group-yes"
                        type="radio"
                        name="insuredRadioGroup"
                        value="yes"
                        onChange={() => null}
                        checked={entityDetails["Insured"] === "yes"}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Insured", 10)
                        }
                      />
                      <label htmlFor="insured-radio-group-yes">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "Yes")}
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="insured-radio-group-no"
                        type="radio"
                        name="insuredRadioGroup"
                        value="no"
                        onChange={() => null}
                        checked={entityDetails["Insured"] === "no"}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Insured", 10)
                        }
                      />
                      <label htmlFor="insured-radio-group-no">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "No")}
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="insured-radio-group-na"
                        type="radio"
                        name="insuredRadioGroup"
                        value="n/a"
                        onChange={() => null}
                        checked={entityDetails["Insured"] === "n/a"}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Insured", 10)
                        }
                      />
                      <label htmlFor="insured-radio-group-na">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "N/A")}
                      </label>
                    </div>
                  </div>

                  <div className="business-options-box">
                    <p className="payment-method-label">
                      {getTranslatedPhrase(userLanguage, "Payment Method")}
                    </p>

                    <div className="form-input-lists form-input-lists_row mgb-sm">
                      <input
                        id="VISA"
                        checked={entityDetails.Payment.indexOf("VISA") >= 0}
                        type="checkbox"
                        onChange={onChangeEntityDetailsPayment}
                      />
                      <label htmlFor="VISA">
                        <i className="fal fa-check" /> VISA
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row mgb-sm">
                      <input
                        id="MasterCard"
                        checked={
                          entityDetails.Payment.indexOf("MasterCard") >= 0
                        }
                        type="checkbox"
                        onChange={onChangeEntityDetailsPayment}
                      />
                      <label htmlFor="MasterCard">
                        <i className="fal fa-check" /> MasterCard
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row mgb-sm">
                      <input
                        id="Check"
                        checked={entityDetails.Payment.indexOf("Check") >= 0}
                        type="checkbox"
                        onChange={onChangeEntityDetailsPayment}
                      />
                      <label htmlFor="Check">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "Check")}
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row mgb-sm">
                      <input
                        id="Paypal"
                        checked={entityDetails.Payment.indexOf("Paypal") >= 0}
                        type="checkbox"
                        onChange={onChangeEntityDetailsPayment}
                      />
                      <label htmlFor="Paypal">
                        <i className="fal fa-check" /> Paypal
                      </label>
                    </div>
                  </div>

                  <div className="business-options-box">
                    <p>{getTranslatedPhrase(userLanguage, "Discount")}</p>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="discount-radio-group-yes"
                        type="radio"
                        name="discountRadioGroup"
                        value="yes"
                        checked={entityDetails["Discount"] === "yes"}
                        onChange={() => null}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Discount", 10)
                        }
                      />
                      <label htmlFor="discount-radio-group-yes">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "Yes")}
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="discount-radio-group-no"
                        type="radio"
                        name="discountRadioGroup"
                        value="no"
                        checked={entityDetails["Discount"] === "no"}
                        onChange={() => null}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Discount", 10)
                        }
                      />
                      <label htmlFor="discount-radio-group-no">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "No")}
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="discount-radio-group-na"
                        type="radio"
                        name="discountRadioGroup"
                        value="n/a"
                        checked={entityDetails["Discount"] === "n/a"}
                        onChange={() => null}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Discount", 10)
                        }
                      />
                      <label htmlFor="discount-radio-group-na">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "N/A")}
                      </label>
                    </div>
                  </div>

                  <div className="business-options-box">
                    <p>{getTranslatedPhrase(userLanguage, "Warranties")}</p>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="warranties-radio-group-yes"
                        type="radio"
                        name="warrantiesRadioGroup"
                        value="yes"
                        checked={entityDetails["Warranties"] === "yes"}
                        onChange={() => null}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Warranties", 10)
                        }
                      />
                      <label htmlFor="warranties-radio-group-yes">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "Yes")}
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="warranties-radio-group-no"
                        type="radio"
                        name="warrantiesRadioGroup"
                        value="no"
                        checked={entityDetails["Warranties"] === "no"}
                        onChange={() => null}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Warranties", 10)
                        }
                      />
                      <label htmlFor="warranties-radio-group-no">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "No")}
                      </label>
                    </div>

                    <div className="form-input-lists form-input-lists_row">
                      <input
                        id="warranties-radio-group-na"
                        type="radio"
                        name="warrantiesRadioGroup"
                        value="n/a"
                        checked={entityDetails["Warranties"] === "n/a"}
                        onChange={() => null}
                        onClick={(e) =>
                          onChangeEntityDetailsHandler(e, "Warranties", 10)
                        }
                      />
                      <label htmlFor="warranties-radio-group-na">
                        <i className="fal fa-check" />{" "}
                        {getTranslatedPhrase(userLanguage, "N/A")}
                      </label>
                    </div>
                  </div>

                  <input
                    className="form-input-text form-input-full"
                    placeholder={getTranslatedPhrase(
                      userLanguage,
                      "Estimates Price"
                    )}
                    value={entityDetails["Estimates"]}
                    onChange={(e) =>
                      onChangeEntityDetailsHandler(e, "Estimates", 50)
                    }
                  />

                  <div className="spacer" />
                </Box>
              )}

              <h4>
                {getTranslatedPhrase(
                  userLanguage,
                  "Update Your Sales Policies"
                )}
              </h4>

              <div className="flx flx-2">
                <textarea
                  className="form-input-textarea form-input-full flx-item"
                  placeholder={getTranslatedPhrase(
                    userLanguage,
                    "Return Policy"
                  )}
                  value={entityPolicies["Return Policy"]}
                  onChange={(e) =>
                    onChangeEntityPoliciesHandler(e, "Return Policy", 10000)
                  }
                />
                <textarea
                  className="form-input-textarea form-input-full flx-item"
                  placeholder={getTranslatedPhrase(
                    userLanguage,
                    "Shipping Policy"
                  )}
                  value={entityPolicies["Shipping Policy"]}
                  onChange={(e) =>
                    onChangeEntityPoliciesHandler(e, "Shipping Policy", 10000)
                  }
                />
              </div>

              <FormFieldError>
                {temp.column === 3
                  ? getTranslatedPhrase(userLanguage, temp.errorMessage)
                  : ""}
              </FormFieldError>
              <button
                className="button button-regular mgt-md"
                onClick={(e) => saveUserInfo(3)}
              >
                {getTranslatedPhrase(userLanguage, "Update Profile")}
              </button>
              <LoadingSvg active={temp.waitingForAnswer && temp.column === 3} />
              <ErrorMessages
                errorMessageClass={"error-message error-message-info"}
                errorMessageTitle={getTranslatedPhrase(userLanguage, "Success")}
                errorMessageBody={getTranslatedPhrase(
                  userLanguage,
                  "Your profile information has been saved"
                )}
                show={profileInfoSaved && temp.column === 3}
              />
            </div>
          </ProfileSettingsBox>
          {/* </Box>)} */}
        </div>

        <CropImageModal
          isProfileImage
          active={openModalProfile}
          onCropPhotoCompleted={getCroppedProfileImage}
          //   authorProfileImage={profileImage}
          authorName={userName}
          desiredWidth={400}
          desiredHeight={400}
          onUpdateUserProfile={props.onUpdateUserProfile}
          userId={userId}
          entityContact={entityContact}
          entityPolicies={entityPolicies}
          entityDetails={entityDetails}
          firstName={firstName}
          lastName={lastName}
          aboutMe={aboutMe}
          userName={userName}
          education={education}
          maritalStatus={maritalStatus}
          entityName={entityName}
          accountType={accountType}
          entityFounded={entityFounded}
        />

        <CropImageModal
          isCoverImage
          active={openModalCover}
          onCropPhotoCompleted={getCroppedCoverImage}
          //   authorProfileImage={profileImage}
          authorName={userName}
          desiredWidth={1400}
          desiredHeight={280}
          onUpdateUserProfile={props.onUpdateUserProfile}
          userId={userId}
          entityContact={entityContact}
          entityPolicies={entityPolicies}
          entityDetails={entityDetails}
          firstName={firstName}
          lastName={lastName}
          aboutMe={aboutMe}
          userName={userName}
          education={education}
          maritalStatus={maritalStatus}
          entityName={entityName}
          accountType={accountType}
          entityFounded={entityFounded}
        />
      </SettingsMenu>
    </Box>
  );
}

const mapDispatchToUserProfileProps = (dispatch) => {
  return {
    onUpdateUserProfile: (u) =>
      dispatch({
        type: actionType.USER_LOGIN,
        userIsAuthenticated: true,
        loggedInUser: u,
      }),
  };
};

export default connect(null, mapDispatchToUserProfileProps)(ProfileUpdate);
