import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const CodeOfConduct = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="main-100 terms-of-service">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Code of Conduct | Followone</title>
        <meta
          name="description"
          content="The guide to the ethical and professional standards."
        />
        <link rel="canonical" href="https://followone.com/code-of-conduct" />
      </Helmet>

      <div className="main-100-header">
        <h1>Code of Conduct</h1>
        <p>Our ethical and professional standards.</p>
      </div>

      <div className="legal-text">
        <h4>Introduction</h4>
        <p>
          This Code of Conduct serves as a guide to the ethical and professional
          standards expected of all employees, directors, and stakeholders
          within our company. It aims to foster a culture of integrity,
          compliance, and mutual respect, ensuring that all business dealings
          are conducted lawfully and ethically.
        </p>

        <h4>Our Commitment</h4>
        <p>
          At Followone, we are committed to upholding the highest standards of
          business ethics and corporate governance. This commitment is reflected
          in the following core values:
        </p>

        <ul className="mgb-md">
          <li>
            <strong>Integrity and Honesty</strong>: We act with integrity in all
            our interactions, ensuring that our business is conducted in a fair,
            honest, and ethical manner. We expect our employees to uphold these
            principles in every action and decision.
          </li>
          <li>
            <strong>Respect for Individuals</strong>: We are committed to
            treating all individuals with respect and dignity, valuing
            diversity, and ensuring an inclusive environment free from
            discrimination and harassment. We recognize the worth of every
            individual and respect their rights and beliefs.
          </li>
          <li>
            <strong>Transparency</strong>: We strive for transparency in our
            operations, making information available to stakeholders while
            respecting confidentiality and privacy obligations. Transparency
            builds trust and confirms our commitment to ethical practices.
          </li>
          <li>
            <strong>Accountability</strong>: We hold ourselves accountable for
            our actions and decisions. We encourage openness and honesty, and we
            expect all employees to take responsibility for their actions, learn
            from experiences, and foster a culture of continuous improvement.
          </li>
        </ul>

        <h4>Compliance with Laws</h4>
        <p>
          We are committed to full compliance with all laws, rules, and
          regulations in the jurisdictions where we operate. Employees are
          expected to understand and obey all laws that apply to their job
          responsibilities and seek advice from the legal department when in
          doubt.
        </p>

        <h4>Conflicts of Interest</h4>
        <p>
          We expect employees and directors to act in the best interests of
          Followone at all times. A conflict of interest occurs when personal
          interests interfere, or appear to interfere, with the interests of the
          company. All potential conflicts must be disclosed to management
          through a formal process, and guidance must be sought to resolve such
          issues in the best interest of the company.
        </p>

        <h4>Communication</h4>
        <p>
          Every communication from Followone, whether internal or external, must
          be:
        </p>

        <ul className="mgb-md">
          <li>
            <strong>Clear</strong>: Avoid jargon and technical terms when not
            necessary, ensuring that the message is understandable by the
            intended audience.
          </li>
          <li>
            <strong>Truthful</strong>: Ensure all statements are factually
            correct and not misleading.
          </li>
          <li>
            <strong>Respectful</strong>: Communicate in a professional manner
            that reflects respect for the recipient, regardless of the context
            or medium.
          </li>
        </ul>

        <h4>Workplace Environment</h4>
        <p>Our commitment to a positive workplace environment includes:</p>

        <ul className="mgb-md">
          <li>
            <strong>Harassment-Free</strong>: We do not tolerate harassment of
            any kind. Regular training and a clear reporting mechanism are in
            place to address potential issues.
          </li>
          <li>
            <strong>Non-Discrimination</strong>: We ensure equal opportunity and
            treatment in hiring, promotion, and compensation without regard to
            race, ethnicity, religion, gender, sexual orientation, age, or
            disability.
          </li>
          <li>
            <strong>Safety and Health</strong>: We strive to provide a safe and
            healthy work environment by complying with all occupational health
            and safety laws and implementing best practices in workplace safety.
          </li>
        </ul>

        <h4>Reporting and Enforcement</h4>
        <p>
          We encourage a culture where ethical concerns or violations of this
          Code can be raised without fear of retribution. An anonymous reporting
          mechanism is available for all employees, and all reports are treated
          with the utmost confidentiality and investigated promptly.
        </p>

        <h4>Disciplinary Actions</h4>
        <p>
          Violations of this Code are taken seriously and will result in
          disciplinary action, which may include reprimands, suspension,
          termination, or legal action, depending on the severity of the breach.
        </p>

        <h4>Amendments and Modifications</h4>
        <p>
          This Code of Conduct is dynamic and may be amended as necessary to
          respond to new challenges, changes in our business environment, or
          regulatory requirements. All updates will be communicated through
          official channels and require adherence from the date of
          implementation.
        </p>

        <p>
          This Code of Conduct is foundational to our operations and our
          corporate identity. We expect every member of the Followone.com
          community to uphold these principles, thereby ensuring our continued
          growth and success in a responsible and ethical manner.
        </p>
      </div>
    </div>
  );
};

export default CodeOfConduct;
