import React, { Component } from "react";
import FormFieldError from "../../uielements/ErrorMessages/FormFieldError";
import ErrorMessages from "../../uielements/ErrorMessages/ErrorMessages";
import { Link, withRouter } from "react-router-dom";
import { maxUploadFileSize } from "../../util/file";
import { hostNameAndPort } from "../../util/vars";
import Axios from "axios";
import PhotoPreviewArea from "./PhotoPreviewArea";
import CropImageModal from "../../util/crop-image-modal";
import LoadingSvg from "../../uielements/LoadingSpinner/LoadingSvg";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../util/lang";
import { populateCountries, populateStates } from "../../util/countries";
import moment from "moment";
import Helmet from 'react-helmet';
import actionType from '../../../redux-store/action-type';


class ListingEdit extends Component {
  constructor(props) {
    super(props);
    this.initialState = this.state = {
      itemId: "",
      title: "",
      slug: "",
      newPhotosFiles: [],
      featuredImage: "",
      featuredImageFile: null,
      privacy: "public",
      category: "",
      subcategory: "",
      newAddress: "choose-where-to-buy",
      address: "",
      addressIsNeeded: true,
      unit: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      currency: "USD",
      specList: [],
      fImageDivRef: null,
      hashTags: "",
      categoriesSelect: [],
      openModal: false,
      clearAllFields: true,
      endDate: moment().format("YYYY-MM-DD"),
      startDate: moment().format("YYYY-MM-DD"),
      countryElementId: '',
      stateElementId: '',
      countriesLoaded: false,
      temp: {
        mainTitle: "Post",
        mainButtonLabel: "Publish",
        itemSaved: false,
        errorMessage: "",
        errorMessageUploader: "",
        mainImageDisplayed: false,
        postListingPressed: false,
        selectWhichCoupon: "",
        discountType: "",
        discountValue: "",
        discountCurrency: "%",
        availability: "",
        endDate: moment().format("YYYY-MM-DD"),
        startDate: moment().format("YYYY-MM-DD")
      }
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getCategories();
    let l = this.props.location;
    if (l.pathname.indexOf(this.props.editListingUrl) >= 0 && l.search) {
      let b = l.search.slice(3);
      this.setState({ slug: b });
      this.props.history.replace(this.props.match.url);
      this.fetchItem(b);
    } else
      this.setState({
        temp: {
          ...this.state.temp,
          mainButtonLabel: "Publish " + this.props.itemType,
          mainTitle: "Add " + this.props.itemType
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.countryElementId
      && this.state.stateElementId
      && document.getElementById('stateElementForEditListings')
      && document.getElementById('stateElementForEditListings').innerHTML === ''
      //&& this.state.country 
      && document.getElementById('countryElementForEditListings')
      && document.getElementById('countryElementForEditListings').selectedIndex) {
      populateCountries(this.state.countryElementId, this.state.stateElementId);
      let cc = document.getElementById('countryElementForEditListings');
      let i = 0;
      for (; i < cc.options.length; i++)
        if (cc.options[i].value === this.state.country) break;
      if (i < cc.options.length) cc.selectedIndex = i;
      else cc.selectedIndex = 0;
      populateStates(this.state.countryElementId, this.state.stateElementId);
      cc = document.getElementById('stateElementForEditListings');
      i = 0;
      for (; i < cc.options.length; i++)
        if (cc.options[i].value === this.state.state) break;
      if (i < cc.options.length) cc.selectedIndex = i;
      else cc.selectedIndex = 0;
    }

    if (
      !!this.state.fImageDivRef &&
      !this.state.temp.mainImageDisplayed &&
      !!this.state.featuredImage
    ) {
      let image = new Image();
      image.src = this.state.featuredImage;
      image.alt = this.state.featuredImage;
      let d = this.state.fImageDivRef;
      if (d.hasChildNodes()) {
        for (let i = 0; i < d.childNodes.length; i++) {
          d.removeChild(d.childNodes[i]);
        }
      }
      d.appendChild(image);
      this.setState({ temp: { ...this.state.temp, mainImageDisplayed: true } });
    }
    if (
      !this.state.categoriesSelect.length &&
      prevState.categoriesSelect.length
    ) {
      this.setState({ categoriesSelect: prevState.categoriesSelect });
      // console.log('again categories select')
    }
  }

  getCategories = async () => {
    let url = `${hostNameAndPort}/api/${this.props.section}/categories`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        this.setState({ categoriesSelect: data.categories });
      }
    } catch (err) {
      // Network error
    }
  };

  fetchItem = async j => {
    let url = `${hostNameAndPort}/api/${this.props.section}/${j}`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        data.item.temp = {
          ...this.state.temp,
          mainTitle: "Edit " + this.props.itemType,
          mainButtonLabel: "Update " + this.props.itemType
        };

        if (!data.item.addDiscount) data.item.addDiscount = 'no-discount';
        else {
          let newTemp = {
            discountType: 'discount-' + data.item.addDiscount,
            discountCurrency: data.item.discountId.couponCurrency,
            discountValue: data.item.discountId.couponValue,
            mainTitle: "Edit " + this.props.itemType,
            mainButtonLabel: "Update " + this.props.itemType
          };

          if (data.item.addDiscount === "deal") {
            data.item.addDiscount = "add-discount";
            if (
              moment(data.item.discountId.startDate).isSame(
                moment("1970-01-01"),
                "day"
              ) &&
              moment(data.item.discountId.endDate).isSame(
                moment("2200-01-01"),
                "day"
              )
            ) {
              newTemp.availability = "no-expiration-date";
            } else {
              newTemp.availability = "choose-dates";
              newTemp.startDate = moment(data.item.discountId.startDate).format(
                "YYYY-MM-DD"
              );
              newTemp.endDate = moment(data.item.discountId.endDate).format(
                "YYYY-MM-DD"
              );
            }
          } else if (data.item.addDiscount === "coupon") {
            data.item.addDiscount = "add-discount";
            newTemp.selectWhichCoupon = data.item.discountId._id;
          }
          data.item.temp = newTemp;
        }
        data.item.newPhotosFiles = [];
        for (let i in data.item.extraPhotos) {
          data.item.newPhotosFiles.push({
            file: null,
            imageName: data.item.extraPhotos[i],
            imagePreviewUrl: data.item.extraPhotos[i],
            className: ""
          });
        }
        delete data.item.extraPhotos;
        // if (!!data.item.address || !!data.item.city) {
        //   data.item.newAddress = "new-address";
        // }
        this.setState(data.item);
      }
    } catch (err) {
      // Network error
      //if (process.env.NODE_ENV === 'development') console.log(err);
    }
  };

  onClickOnPhotoHandler = (pos, action) => {
    let a = this.state.newPhotosFiles;
    if (action === "select") {
      // Choose main photo
      // for (let i in a) {
      //     if (a[i].className === 'selectedPhoto') {
      //         a[i].className = '';
      //     }
      // }
      // if (pos >= 0 && pos < a.length) {
      //     a[pos].className = 'selectedPhoto';
      // }
      this.setState({
        //featuredImage: '',
        newPhotosFiles: a,
        temp: {
          ...this.state.temp,
          serviceSaved: false,
          errorMessage: ""
        }
      });
    } else {
      if (action === "remove") {
        // Remove photo
        a.splice(pos, 1);
        this.setState({
          newPhotosFiles: a,
          temp: {
            ...this.state.temp,
            serviceSaved: false,
            errorMessage: ""
          }
        });
      }
    }
  };

  itsTimeToSetNewPhotosFiles = newPhotos => {
    this.setState({
      newPhotosFiles: newPhotos.concat(this.state.newPhotosFiles),
      temp: {
        ...this.state.temp,
        serviceSaved: false,
        errorMessage: ""
      }
    });
    //this.onClickOnPhotoHandler(0, 'select');
  };

  onChangeUploadedImagesHandler = e => {
    function photoLoader(n) {
      let k = 0;
      let loadedPhotos = Array(n);
      return function (ctx, files, i, reader) {
        loadedPhotos[i] = {
          file: files[i],
          imagePreviewUrl: reader.result,
          imageName: files[i].name,
          className: ""
        };
        k++;
        if (k === n) {
          ctx.itsTimeToSetNewPhotosFiles(loadedPhotos);
        }
      };
    }

    let _error = false;
    let epf = e.target.files;
    let o = Object.keys(epf);
    if (o.length > 20) {
      this.setState({
        temp: {
          ...this.state.temp,
          errorMessageUploader: getTranslatedPhrase(
            this.props.userLanguage,
            "You selected more than 20 photos"
          ) + '!'
        }
      });
      return;
    } else this.setState({
      temp: {
        ...this.state.temp,
        errorMessageUploader: ''
      }
    });
    for (let i of o) {
      if (epf[i].size > maxUploadFileSize) {
        this.setState({
          temp: {
            ...this.state.temp,
            errorMessage: getTranslatedPhrase(
              this.props.userLanguage,
              "Each photo must be smaller than 5 MB"
            ) + '!'
          }
        });
        _error = true;
        break;
      }
    }
    if (!_error) {
      let n = o.length;
      let onRegisterPhoto = photoLoader(n);
      for (let i of o) {
        let reader = new FileReader();
        let ctx = this;
        reader.onloadend = function () {
          onRegisterPhoto(ctx, epf, i, this);
        };
        reader.readAsDataURL(epf[i]);
      }
    }
  };

  onChangeItem = (value, _propName, _sizeLimit, _propMsg) => {
    if (_sizeLimit)
      if (value.length > _sizeLimit) {
        this.setState({
          temp: {
            ...this.state.temp,
            errorMessage: `${_propMsg} ${getTranslatedPhrase(
              this.props.userLanguage,
              "must be smaller than"
            )} ${_sizeLimit} ${getTranslatedPhrase(
              this.props.userLanguage,
              "characters"
            )}`
          }
        });
        return false;
      }
    this.setState({
      [_propName]: value,
      temp: { ...this.state.temp, itemSaved: false, errorMessage: "" }
    });

    return true;
  };

  onChangeTempItem = (value, _propName, _sizeLimit, _propMsg) => {
    if (_sizeLimit)
      if (value.length > _sizeLimit) {
        this.setState({
          temp: {
            ...this.state.temp,
            errorMessage: `${_propMsg} ${getTranslatedPhrase(
              this.props.userLanguage,
              "must be smaller than"
            )} ${_sizeLimit} ${getTranslatedPhrase(
              this.props.userLanguage,
              "characters"
            )}`
          }
        });
        return false;
      }
    this.setState({
      temp: {
        ...this.state.temp,
        [_propName]: value,
        itemSaved: false,
        errorMessage: ""
      }
    });
    return true;
  };

  onChangeTempState = (temp) => {
    const newTemp = { ...this.state.temp, ...temp }
    this.setState({ temp: newTemp });

    return true;
  }

  onClickToCropMainImage = () => {
    this.setState({ openModal: true });
  };

  getCroppedFeaturedImage = f => {
    this.setState({ openModal: false });
    if (!f) {
      return;
    }
    let _fileName = "cropped-photo.jpg";
    let _file = new File([f], _fileName, { type: "image/jpeg" });
    if (_file.size > maxUploadFileSize) {
      this.setState({
        featuredImage: "",
        temp: {
          ...this.state.temp,
          errorMessage:
            getTranslatedPhrase(
              this.props.userLanguage,
              "The main image must be smaller than"
            ) + " 5 MB"
        }
      });
      return;
    }

    let _reactComp = this;

    let image = new Image();
    let url = URL.createObjectURL(f);
    image.src = url;
    image.alt = _fileName;

    image.onload = function () {
      URL.revokeObjectURL(url);

      if (this.height < 280 || this.width < 440) {
        _reactComp.setState({
          featuredImage: "",
          temp: {
            ..._reactComp.state.temp,
            errorMessage:
              getTranslatedPhrase(
                _reactComp.props.userLanguage,
                "Image size must be at least"
              ) + " 440 x 280 px"
          }
        });
      } else {
        _reactComp.setState({
          featuredImage: _fileName,
          featuredImageFile: _file,
          temp: { ..._reactComp.state.temp, errorMessage: "" }
        });
        let d = _reactComp.state.fImageDivRef;
        if (d.hasChildNodes()) {
          for (let i = 0; i < d.childNodes.length; i++) {
            d.removeChild(d.childNodes[i]);
          }
        }
        d.appendChild(image);
      }
    };
  };

  requiredFieldsAreFilled() {
    let err = this.props.checkRequirements(this.state);
    if (this.state.temp.itemSaved && !err) {
      err = getTranslatedPhrase(this.props.userLanguage, this.props.itemType) + " " + getTranslatedPhrase(this.props.userLanguage, "is already saved");
      //this.props.itemType + " is already saved";

    }

    if (err.indexOf('Warning') >= 0 && !this.state.temp.firstTimeWarning)
      this.setState({ temp: { ...this.state.temp, errorMessage: err, firstTimeWarning: true } });
    else if (err.indexOf('Warning') >= 0) {
      this.setState({ temp: { ...this.state.temp, errorMessage: '', firstTimeWarning: false } });
      err = '';
    } else this.setState({ temp: { ...this.state.temp, errorMessage: err } });
    return !err;
  }

  onClearDraftHandler = () => {
    window.scrollTo(0, 0);
    let c = Array.from(this.state.categoriesSelect);
    let d = this.state.fImageDivRef;
    if (d.hasChildNodes()) {
      for (let i = 0; i < d.childNodes.length; i++) {
        d.removeChild(d.childNodes[i]);
      }
    }
    let initialState = {
      ...this.initialState,
      itemId: "",
      categoriesSelect: c,
      clearAllFields: false,
      temp: {
        itemSaved: false,
        mainTitle: "New " + this.props.itemType,
        mainButtonLabel: "Publish " + this.props.itemType,
        errorMessage: ""
      }
    };
    this.setState(initialState);
    setTimeout(() => {
      this.setState({
        clearAllFields: true
        // categoriesSelect: c
      });
    }, 1000);
  };

  onPublishHandler = async () => {
    if (!this.requiredFieldsAreFilled()) {
      return;
    }
    this.setState({ temp: { ...this.state.temp, postListingPressed: true } });

    let nbd = Object.assign({}, this.state);
    nbd.extraPhotosFiles = [];
    nbd.extraPhotos = [];

    for (let i in nbd.newPhotosFiles) {
      if (!nbd.newPhotosFiles[i].file) {
        nbd.extraPhotos.push(nbd.newPhotosFiles[i].imageName);
      } else {
        nbd.extraPhotosFiles.push(nbd.newPhotosFiles[i].file);
      }
    }

    delete nbd.newPhotosFiles;
    delete nbd.categoriesSelect;
    delete nbd.openModal;
    delete nbd.fImageDivRef;
    delete nbd.couponList;

    if (!!nbd.price) {
      nbd.price = String(nbd.price).replace(/,/g, '').replace('.', '');
    }

    if (!nbd.temp.discountType || nbd.temp.discountType === "discount-type" || nbd.addDiscount === 'no-discount') {
      nbd.addDiscount = "";
      nbd.discountId = "";
    } else if (nbd.temp.discountType === "discount-coupon") {
      nbd.addDiscount = "coupon";
      nbd.discountId = nbd.temp.selectWhichCoupon;
    } else if (nbd.temp.discountType === "discount-deal") {
      nbd.addDiscount = "deal";
      nbd.discountId = "";
    }

    let discountProps = {
      discountCurrency: nbd.temp.discountCurrency,
      discountValue: nbd.temp.discountValue,
      availability: nbd.temp.availability,
      startDate: nbd.temp.startDate,
      endDate: nbd.temp.endDate
    };


    if (discountProps.discountCurrency !== '%' && Number(discountProps.discountValue) > Number(nbd.price)) {
      this.props.showAlert(getTranslatedPhrase(this.props.userLanguage, 'Discount cannot be greater than the price.'));
      this.setState({ temp: { ...this.state.temp, postListingPressed: false } });
      return;
    }


    delete nbd.temp;

    let newItemData = new FormData();
    for (let i in nbd) {
      newItemData.append(i, nbd[i]);
    }
    newItemData.append("discountProps", JSON.stringify(discountProps));
    newItemData.delete("specList");
    newItemData.append("specList", JSON.stringify(nbd.specList));
    newItemData.delete("moreSpecs");
    newItemData.append("moreSpecs", JSON.stringify(nbd.moreSpecs));

    if (!!nbd.featureList) {
      let features = Object.keys(nbd.featureList).filter(v => nbd.featureList[v]).sort().join('|');
      newItemData.delete("featureList");
      newItemData.delete("features");
      newItemData.append("features", features);
    }
    if (!!nbd.optionList) {
      let options = Object.keys(nbd.optionList).filter(v => nbd.optionList[v]).sort().join('|');
      newItemData.delete("optionList");
      newItemData.delete("options");
      newItemData.append("options", options);
    }

    newItemData.delete("extraPhotosFiles");
    for (let i in nbd.extraPhotosFiles) {
      newItemData.append("extraPhotosFile" + i, nbd.extraPhotosFiles[i]);
    }

    let options = {
      url: `${hostNameAndPort}/api/${this.props.section}/update`,
      method: "post",
      data: newItemData
    };
    try {
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        this.setState({
          itemId: data.itemId,
          slug: data.slug,
          temp: {
            ...this.state.temp,
            itemSaved: true,
            mainTitle: "Edit " + this.props.itemType,
            mainButtonLabel: "Update " + this.props.itemType,
            errorMessage: "",
            mainImageDisplayed: true,
            postListingPressed: false
          }
        });
      } else {
        this.setState({
          temp: {
            ...this.state.temp,
            itemSaved: false,
            errorMessage: data.error,
            postListingPressed: false
          }
        });
      }
    } catch (err) {
      if (process.env.NODE_ENV === 'development') console.log(err);
      this.setState({
        temp: {
          ...this.state.temp,
          errorMessage: getTranslatedPhrase(
            this.props.userLanguage,
            "Network error ERRFELSTED01"
          ),
          itemSaved: false,
          postListingPressed: false
        }
      });
    }
  };

  render() {


    let styleClass = "";

    styleClass = this.state.temp.mainTitle.toLowerCase().replace(/ /g, '');

    let backgroundClass = `layout-background-new layout-background-new-${styleClass}`;
    let subcategory = this.props.section.startsWith('realestate') ? this.state.subcategory.replace(/\//g, '_') : (this.state.subcategory || 'other');


    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{getTranslatedPhrase(this.props.userLanguage, this.state.temp.mainTitle)} | Followone</title>
          <meta name="description" content="Sell products, cars, properties & travel packages, post jobs & services, find deals & coupons and connect with customers, friends & family." />
          <link rel="canonical" href={"https://followone.com/"} />
        </Helmet>

        <div className={backgroundClass} />

        <div className="main-100 add-new-page add-jobs new-edit-page">

          <div className="new-edit-page_header">
            <h2>
              {getTranslatedPhrase(
                this.props.userLanguage,
                this.state.temp.mainTitle
              )}
            </h2>
          </div>


          <this.props.ItemFields
            categoriesSelect={this.state.categoriesSelect}
            onUpdateItem={this.onChangeItem}
            onUpdateTempItem={this.onChangeTempItem}
            onUpdateTempState={this.onChangeTempState}
            loadedItem={this.state}
            userLanguage={this.props.userLanguage}
          />

          {/*<div className="new-edit-page_section">*/}
          {/*  <div className="new-edit-page_section-title">*/}
          {/*    <p>Main Info</p>*/}
          {/*  </div>*/}

          {/*  <div className="new-edit-page_section-content">*/}
          {/*  </div>*/}

          {/*</div>*/}



          {/*<div className="main-100-header">*/}
          {/*  */}
          {/*  <p>*/}
          {/*    {getTranslatedPhrase(*/}
          {/*      this.props.userLanguage,*/}
          {/*      this.props.mainSubtitle*/}
          {/*    )}*/}
          {/*  </p>*/}
          {/*</div>*/}



          <div className="new-edit-page_section">
            <div className="new-edit-page_section-title">
              <p>Add Main Image *</p>
            </div>

            <div className="new-edit-page_section-content">
              <div className="edit-page-field">
                <button
                  className="button button-regular"
                  onClick={this.onClickToCropMainImage}
                >
                  {getTranslatedPhrase(
                    this.props.userLanguage,
                    "Upload New Photo"
                  )}
                </button>
              </div>
            </div>

            <div
              className="new-edit-page_section-image"
              ref={n => {
                if (!this.state.fImageDivRef)
                  this.setState({ fImageDivRef: n });
              }}
            />
          </div>

          {/* Image Gallery */}
          <div className="new-edit-page_section">
            <div className="new-edit-page_section-title">
              <p>Image Gallery</p>
            </div>

            <div className="new-edit-page_section-content">
              <div className="edit-page-field edit-page-field-full">
                <label className="button button-regular" htmlFor="upload-photo">Select Images</label>
                <input
                  type="file"
                  className="upload-more-photos"
                  multiple="multiple"
                  id="upload-photo"
                  accept="image/*"
                  onChange={this.onChangeUploadedImagesHandler}
                />
                <label className="info"><i className="fal fa-info-circle" />
                  {getTranslatedPhrase(this.props.userLanguage, 'Add 20 images max at a time. Images will have the right orientation after the post is saved.')}
                </label>
                <label>
                  <FormFieldError>{this.state.temp.errorMessageUploader}</FormFieldError>
                </label>
              </div>
            </div>

            {!this.state.newPhotosFiles.length ? '' :
              <div className="new-edit-page_section-images">
                <PhotoPreviewArea
                  photoList={this.state.newPhotosFiles}
                  onClick={this.onClickOnPhotoHandler}
                />
              </div>}
          </div>

          {/* Location */}
          <div className="new-edit-page_section">
            <div className="new-edit-page_section-title">
              <p>Location</p>
            </div>

            <div className="new-edit-page_section-content">
              <div className="edit-page-field edit-page-field-full">
                <div className="form-input-select">
                  <select
                    className="form-input-full"
                    value={this.state.newAddress}
                    onChange={e =>
                      this.onChangeItem(
                        e.target.value,
                        "newAddress",
                        75,
                        "Address"
                      )
                    }
                  >
                    <option value="choose-where-to-buy">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Choose " + this.props.itemType + " Location"
                      )}{" "}
                      *
                    </option>
                    {this.props.section === 'jobs' || this.props.section === 'realestate' || this.props.section === 'travel' || this.props.section === 'autos' || this.props.section === 'cars' || this.state.addressIsNeeded ? null : (
                      <option value="no-address-needed">
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "No Address Needed"
                        )}
                      </option>)}
                    <option value="same-as-business-address">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Same as Your Business Address"
                      )}
                    </option>
                    <option value="new-address">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "New Address"
                      )}
                    </option>
                  </select>
                </div>
              </div>

              {this.state.newAddress !== "new-address" ? null : (
                <>
                  <div className="edit-page-field">
                    <input
                      className="form-input-text form-input-full"
                      type="text"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "Address"
                      )}
                      value={this.state.address}
                      onChange={e =>
                        this.onChangeItem(
                          e.target.value,
                          "address",
                          50,
                          getTranslatedPhrase(
                            this.props.userLanguage,
                            "The Address"
                          )
                        )
                      }
                    />
                  </div>

                  <div className="edit-page-field">
                    <input
                      className="form-input-text form-input-full"
                      type="text"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "Unit"
                      )}
                      value={this.state.unit}
                      onChange={e =>
                        this.onChangeItem(
                          e.target.value,
                          "unit",
                          20,
                          getTranslatedPhrase(
                            this.props.userLanguage,
                            "The Unit"
                          )
                        )
                      }
                    />
                  </div>

                  <div className="edit-page-field">
                    <div className="form-input-select">
                      <select
                        className="form-input-full"
                        id="countryElementForEditListings"
                        ref={n => {
                          if (!this.state.countryElementId) this.setState({ countryElementId: "countryElementForEditListings" });
                          if (this.state.countryElementId && this.state.stateElementId && !this.state.countriesLoaded) {
                            populateCountries(this.state.countryElementId, this.state.stateElementId);
                            this.setState({ countriesLoaded: true });
                          }
                        }}
                        value={this.state.country}
                        onChange={e =>
                          this.onChangeItem(
                            e.target.value,
                            "country",
                            100,
                            "Country"
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="edit-page-field">
                    <div className="form-input-select">
                      <select
                        className="form-input-full"
                        id="stateElementForEditListings"
                        ref={n => {
                          if (!this.state.stateElementId) this.setState({ stateElementId: "stateElementForEditListings" });
                          if (this.state.countryElementId && this.state.stateElementId && !this.state.countriesLoaded) {
                            populateCountries(this.state.countryElementId, this.state.stateElementId);
                            this.setState({ countriesLoaded: true });
                          }
                        }}
                        value={this.state.state}
                        onChange={e =>
                          this.onChangeItem(
                            e.target.value,
                            "state",
                            30,
                            "The state"
                          )
                        } />
                    </div>
                  </div>

                  <div className="edit-page-field">
                    <input
                      className="form-input-text form-input-full"
                      type="text"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "City"
                      ) + ' *'}
                      value={this.state.city}
                      onChange={e =>
                        this.onChangeItem(
                          e.target.value,
                          "city",
                          50,
                          getTranslatedPhrase(
                            this.props.userLanguage,
                            "The City"
                          )
                        )
                      }
                    />
                  </div>

                  <div className="edit-page-field">
                    <input
                      className="form-input-text form-input-full"
                      type="text"
                      placeholder={getTranslatedPhrase(
                        this.props.userLanguage,
                        "Zip Code"
                      ) + ' *'}
                      value={this.state.zipCode}
                      onChange={e =>
                        this.onChangeItem(
                          e.target.value,
                          "zipCode",
                          10,
                          getTranslatedPhrase(
                            this.props.userLanguage,
                            "The Zip Code"
                          ) + ' *'
                        )
                      }
                    />
                  </div>
                </>)}
            </div>
          </div>

          {/* Search Keywords */}
          <div className="new-edit-page_section">
            <div className="new-edit-page_section-title">
              <p>Search Keywords</p>
            </div>

            <div className="new-edit-page_section-content">
              <div className="edit-page-field edit-page-field-full">
                <textarea
                  className="form-input-textarea form-input-full"
                  placeholder={
                    getTranslatedPhrase(this.props.userLanguage, "Keywords") +
                    ", " +
                    getTranslatedPhrase(this.props.userLanguage, "Example") +
                    ": " +
                    getTranslatedPhrase(
                      this.props.userLanguage,
                      "health, food, exercise, fruits, strawberries, smoothie"
                    ) +
                    "..."
                  }
                  value={this.state.hashTags}
                  onChange={e =>
                    this.onChangeItem(
                      e.target.value,
                      "hashTags",
                      1000,
                      getTranslatedPhrase(this.props.userLanguage, "Keywords")
                    )
                  }
                />

                <label className="info"><i className="fal fa-info-circle" />
                  {getTranslatedPhrase(this.props.userLanguage, 'By adding search keywords you increase your chances of being found by users.')}
                </label>
              </div>
            </div>
          </div>








          <div className="submit-listing">
            <FormFieldError>
              {getTranslatedPhrase(this.props.userLanguage, this.state.temp.errorMessage)}{" "}
              {/maximum number/.test(this.state.temp.errorMessage) && (
                <Link to="/subscription-settings">
                  {getTranslatedPhrase(this.props.userLanguage, 'Click to upgrade your subscription.')}
                </Link>
              )}
            </FormFieldError>
            <LoadingSvg active={this.state.temp.postListingPressed} />
            <div className="submit-listing-button">
              <button
                className="button button-white"
                onClick={this.onClearDraftHandler}
              >
                <i className="fal fa-plus" />{" "}
                {getTranslatedPhrase(this.props.userLanguage, "Add New")}
              </button>
              <button
                className="button button-regular"
                onClick={this.onPublishHandler}
              >
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  this.state.temp.mainButtonLabel
                )}
              </button>
            </div>

            <ErrorMessages
              errorMessageClass={"error-message error-message-info"}
              errorMessageTitle={getTranslatedPhrase(
                this.props.userLanguage,
                "Success"
              )}
              errorMessageBody={getTranslatedPhrase(
                this.props.userLanguage, this.props.itemType) + " " +
                getTranslatedPhrase(
                  this.props.userLanguage, "has been published"
                )}
              show={this.state.temp.itemSaved}
            >
              <Link
                className="button button-regular button-small dsp-in-blk mgt-sm"
                to={
                  "/" +
                  this.props.section +
                  "/" +
                  this.state.category +
                  "/" +
                  subcategory +
                  "/" +
                  this.state.slug
                }
              >
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  "See Your Listing"
                )}
              </Link>
            </ErrorMessages>
          </div>
        </div>

        <CropImageModal
          active={this.state.openModal}
          onCropPhotoCompleted={this.getCroppedFeaturedImage}
          authorProfileImage={this.state.authorProfileImage}
          authorName={this.state.authorName}
          desiredWidth={440}
          desiredHeight={280}
        />
      </>
    );
  }
}

const mapStateToListingEditProps = (state, props) => {
  return {
    userLanguage: state.userLanguage
  };
};

const mapDispatchToListingEditProps = (
  dispatch
) => {
  return {
    showAlert: (m) => {
      dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: m });
    },
    pageShouldRefresh: (p) => {
      dispatch({ type: actionType.PAGE_REFRESH, pageRefresh: p });
    }
  };
};

export default connect(
  mapStateToListingEditProps,
  mapDispatchToListingEditProps
)(withRouter(ListingEdit));