import React, { Component } from 'react';
import { titleCase } from '../../../shared/util/string';
import { formatPriceAndCurrency } from '../../../shared/util/number';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { hostNameAndPort } from '../../../shared/util/vars';
import Box from '../../../shared/util/box';
import ListingSidebar from '../../../shared/templates/ListingTemplate/listing-sidebar';
import Axios from 'axios';
import Rate from '../../../shared/uielements/Engage/EngageButtons/RateButton';
import ListingHeader from '../../../shared/templates/ListingTemplate/components/ListingHeader';
import ListingMap from '../../../shared/templates/ListingTemplate/components/ListingMap';
import ListingReviews from '../../../shared/templates/ListingTemplate/components/ListingReviews';
import ListingPhotos from '../../../shared/templates/ListingTemplate/components/ListingPhotos';
import { connect } from 'react-redux';
import moment from 'moment';
import actionType from '../../../redux-store/action-type';
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import LoadingSvg from '../../../shared/uielements/LoadingSpinner/LoadingSvg';

class ServiceDetail extends Component {
    state = {
        category: 'all',
        subcategory: 'all',
        description: '',
        authorId: '',
        authorProfileImage: '\\uploads\\default\\default-profile-image.jpg',
        allPhotos: [],
        reviews: [],
        averageRating: 0,
        pageRefresh: '',
        authorName: '',
        brandName: '',
        title: '',
        afterDiscountPrice: '0',
        current_item_id: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let p = this.props.match.params || {};
        this.setState({ pageRefresh: this.props.pageRefresh, current_item_id: p.storeItem, pageViewStart: moment() });
        if (p && p.storeItem) {
            setTimeout(() => {
                this.getService(p.storeItem);
            }, 100);
        }
    }

    componentDidUpdate() {
        let k = this.props.pageRefresh;
        let p = this.props.match.params || {};
        if (this.state.pageRefresh !== k || this.state.current_item_id !== p.storeItem) {
            this.setState({ pageRefresh: k, current_item_id: p.storeItem });
            window.scrollTo(0, 0);
            if (p && p.storeItem) {
                this.getService(p.storeItem);
            }
        }
    }

    handlePPVCampaignViewer = async (isOrganic, itemId) => {
        try {
            let ppvData = new FormData();
            ppvData.append('campaignId', this.props.payperviewRedirect);
            ppvData.append('itemId', itemId);
            ppvData.append('itemType', 'services');
            let options = {
                url: `${hostNameAndPort}/api/ppv/add${isOrganic}view`,
                method: 'post',
                data: ppvData
            };
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                // Success! Nothing to do
                // console.log('success')
            }
        } catch (error) {
            // Network error
        }
    }

    getService = async (j) => {
        let url = `${hostNameAndPort}/api/services/${j}`;
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                data.item.allPhotos = [data.item.featuredImage, ...data.item.extraPhotos];
                // data.item.price = Math.floor(data.item.price / 100) + (data.item.price % 100) / 100;
                data.item.price = Number(data.item.price) / 100;
                if (data.item.discountId && data.item.discountId.couponCurrency !== '%') data.item.discountId.couponValue = Number(data.item.discountId.couponValue) / 100;
                this.setState(data.item);
                this.computeAfterDiscountPrice(data.item.price, data.item.currency, data.item.discountId);

                setTimeout(() => {
                    if (moment().diff(moment(this.state.pageViewStart), 'seconds') >= 5) {
                        if (!!this.props.payperviewRedirect) {
                            this.handlePPVCampaignViewer('', data.item.itemId);
                            this.props.resetPayperviewRedirect();
                        } else {
                            this.handlePPVCampaignViewer('organic', data.item.itemId);
                        }
                    }
                }, 5100);
            } else this.props.history.push('/404');
        } catch (err) {
            // Network error
            this.props.history.push('/404');
        }
    }

    computeAfterDiscountPrice = (p, c, d) => {
        // let c = s.currency;
        // let p = s.price;
        // let d = s.addDiscount;
        if (!!d) {
            let theDiscount = d.couponValue;
            if (d.couponCurrency === '%') {
                p = Math.round((p - p * theDiscount / 100) * 100) / 100;
            } else {
                // p = p - Number(theDiscount);
                // theDiscount = theDiscount / 100;
                p = Math.round((p - Number(theDiscount)) * 100) / 100;
            }
            if (p > 0) {
                this.setState({ afterDiscountPrice: formatPriceAndCurrency(p, c, ',') });
            } else {
                this.setState({ afterDiscountPrice: '0' });
            }
        } else {
            this.setState({ afterDiscountPrice: '' });
        }
    }

    getCouponCode = (v, cc, pc) => {
        if (cc !== '%') {
            return formatPriceAndCurrency(v, pc, ',');
        }
        return v + cc;
    }

    onClickContact = (e) => {
        if (!this.props.loggedInUser) {
            this.props.onShowLoginModal();
            return;
        }
        if (this.props.loggedInUser.userId === this.state.authorId) {
            this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, 'This feature is not available!'));
            return;
        }
        let u = {
            userId: this.state.authorId,
            userName: this.state.authorName,
            profileImage: this.state.authorProfileImage,
            slug: this.state.authorSlug
        };
        let m = window.location.href;
        this.props.updateMessagesPageProps(u, getTranslatedPhrase(this.props.userLanguage, 'I am writing in regards to this') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'service') + ': ' + m);
        this.props.history.push('/chat');
    }

    render() {
        let theAddress = this.state.address;
        if (!!this.state.unit) {
            theAddress = theAddress + ' #' + this.state.unit;
        }
        theAddress = theAddress + ' ' + this.state.city;
        if (!!this.state.state && this.state.state !== 'n/a') {
            theAddress = theAddress + ' ' + this.state.state;
        }
        theAddress = theAddress + ', ' + this.state.zipCode + ', ' + this.state.country;

        if (!this.state.title) return (<LoadingSvg active={true} />);

        // <Link to={`/services/${this.state.category}`}>

        return (
            <div className="listing-body">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.title} | Followone</title>
                    <meta name="description" content={this.state.description.substring(0, 200)} />
                    <link rel="canonical" href={"https://followone.com/services/" + this.state.category + "/" + this.state.subcategory + "/" + this.state.slug} />
                </Helmet>

                <div className="main-container ServiceContent">

                    <div className="ServiceContent__Details-Container">
                        <ListingHeader
                            address={theAddress}
                            photos={this.state.allPhotos}
                            websiteSection={"services"}
                            authorId={this.state.authorId}
                            itemId={this.state.itemId}
                            addDiscount={this.state.addDiscount}

                            callForPricing={this.state.callForPricing}
                            volunteer={this.state.volunteer}
                            afterDiscountPrice={this.state.afterDiscountPrice}
                            price={this.state.price}
                            currency={this.state.currency}
                            rateType={this.state.rateType}
                        />
                    </div>


                    <div className="ServiceContent__Details">


                        <div className="auto-listing-title-map">
                            <div className="car-meta-info">
                                <h1>{this.state.title}</h1>
                                <div><Rate noStars={this.state.averageRating} noReviews={this.state.reviewCount} /></div>
                            </div>

                            <div className="product-main-contact">
                                <div className="ProductDetailInfo">
                                    by <Link className="capitalize" to={'/' + this.state.authorSlug}><strong>{this.state.authorName}</strong></Link>
                                    &nbsp;/
                                    in <strong className="capitalize">{this.state.category.replace(/-/g, ' ')}</strong>
                                </div>



                                <div className="touch-contact">
                                    {(!this.props.loggedInUser || (this.props.loggedInUser && this.props.loggedInUser.userId !== this.state.authorId)) &&
                                        <button data-tip="Chat" onClick={this.onClickContact}><i className="fal fa-comment-lines" /></button>
                                    }

                                    {!this.state.numberToCall ? null :
                                        (<a data-tip="Call" href={`tel:${this.state.numberToCall}`}><i className="fal fa-phone-alt" /></a>)}

                                    {!this.state.sellerEmail ? null : (
                                        <a data-tip="eMail" href={`mailto:${this.state.sellerEmail}`}><i className="fal fa-envelope" /></a>)}

                                    <a target="_blank" data-tip="Get Directions" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${theAddress}/`}><i className="fal fa-map-marker-alt" /></a>

                                    {!this.state.ticketSaleURL ? null : (
                                        <a href={this.state.ticketSaleURL} data-tip="See on Dealer's Website" target="_blank" rel="noopener noreferrer"><i className="fal fa-globe" /></a>)}
                                </div>
                            </div>
                        </div>


                        {(!!this.state.callForPricing) ? null : (
                            !!this.state.addDiscount && this.state.addDiscount === 'coupon') ? (<Box>
                                <div className="listing-coupon">
                                    <div className="coupon-code-text-box">
                                        <span>{getTranslatedPhrase(this.props.userLanguage, 'SAVE')} {this.getCouponCode(this.state.discountId.couponValue, this.state.discountId.couponCurrency, this.state.currency)}</span><br />
                                        {getTranslatedPhrase(this.props.userLanguage, 'Coupon Code')}{' :'}

                                    </div>

                                    <div className="coupon-code-box">
                                        <p className="listing-coupon-code">
                                            <span className="listing-main-code">{this.state.discountId.title}<br />
                                                <span className="listing-coupon-terms">{getTranslatedPhrase(this.props.userLanguage, 'Ends')}: {moment(this.state.discountId.endDate).format('L')}</span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="listing-coupon-divider" />
                            </Box>) : null}



                        {/*{(!this.props.userIsAuthenticated || !this.props.loggedInUser || !this.state.authorId || (this.state.authorId === this.props.loggedInUser.userId)) ? null : (*/}
                        {/*    <button className="button button-regular button-small" style={{ marginTop: '2rem' }} onClick={this.onClickContact}>{getTranslatedPhrase(this.props.userLanguage, 'Send Message')}</button>)}*/}

                        {/*{(!!this.state.callForPricing) ? (<div className="service-cost"><span className="service-cost-main">{getTranslatedPhrase(this.props.userLanguage, 'Call For Pricing')}</span></div>) : (*/}
                        {/*    !!this.state.volunteer) ? (<div className="service-cost"><span className="service-cost-main">{getTranslatedPhrase(this.props.userLanguage, 'Volunteer')}</span></div>) : (<div className="service-cost">*/}
                        {/*        <span className="service-cost-main">{this.state.afterDiscountPrice === '0' ? getTranslatedPhrase(this.props.userLanguage, 'FREE') : this.state.afterDiscountPrice}</span>*/}
                        {/*        {(!this.state.afterDiscountPrice) ? (<span className="service-cost-main">{formatPriceAndCurrency(this.state.price, this.state.currency, ',', getTranslatedPhrase(this.props.userLanguage, titleCase(this.state.rateType)))}</span>) : (<Box>*/}
                        {/*            <span className="service-cost-lt">{formatPriceAndCurrency(this.state.price, this.state.currency, ',')}</span> {(!this.state.rateType) ? '' : (<span className="service-cost-type">{'/ ' + getTranslatedPhrase(this.props.userLanguage, titleCase(this.state.rateType))}</span>)}*/}
                        {/*        </Box>)}*/}
                        {/*        /!* <span className="service-cost-type">/ Month</span> *!/*/}
                        {/*    </div>)}*/}



                        {/*    </div>*/}




                        {/*    {!this.state.callForPricing && !!this.state.addDiscount && this.state.addDiscount === "deal" &&  (*/}
                        {/*      <>*/}
                        {/*          <div className="deal-listing-page-box">*/}
                        {/*              <p>*/}
                        {/*                  {moment(this.state.discountId.endDate).isAfter(moment("2190-01-01"))*/}
                        {/*                    ? getTranslatedPhrase(this.props.userLanguage, 'Deal expires at the discretion of the seller.')*/}
                        {/*                    : getTranslatedPhrase(this.props.userLanguage, 'Discount valid through: ')+ ": " + moment(this.state.discountId.endDate).format('L')}*/}
                        {/*              </p>*/}
                        {/*          </div>*/}
                        {/*      </>)}*/}


                        <div className="ServiceContent__Details__Sidebar">

                            <h4>{getTranslatedPhrase(this.props.userLanguage, 'Service Details')}</h4>

                            <table>
                                <tbody>
                                    <tr>
                                        <td>{getTranslatedPhrase(this.props.userLanguage, 'Location')}</td>
                                        <td>{titleCase(this.state.city) + ', ' + (this.state.state === 'n/a' ? this.state.country : this.state.state) + ' ' + this.state.zipCode}</td>
                                    </tr>

                                    <tr>
                                        <td>{getTranslatedPhrase(this.props.userLanguage, 'Category')}</td>
                                        <td>{getTranslatedPhrase(this.props.userLanguage, titleCase(this.state.category.replace(/-/g, ' ')))}</td>
                                    </tr>

                                    {!!this.state.subcategory && this.state.subcategory !== 'null' && (
                                        <tr>
                                            <td>{getTranslatedPhrase(this.props.userLanguage, 'Subcategory')}</td>
                                            <td>{getTranslatedPhrase(this.props.userLanguage, titleCase(this.state.subcategory.replace(/-/g, ' ')))}</td>
                                        </tr>)}

                                    {this.state.deliveryTime && (
                                        <tr>
                                            <td>{getTranslatedPhrase(this.props.userLanguage, 'Delivery Time')}</td>
                                            <td>{this.state.deliveryTime}</td>
                                        </tr>)}

                                    {this.state.revisions && (this.state.revisions !== '0') && (
                                        <tr>
                                            <td>{getTranslatedPhrase(this.props.userLanguage, 'Revisions')}</td>
                                            <td>{this.state.revisions}</td>
                                        </tr>)}

                                    {/* <tr>
                                    <td>{getTranslatedPhrase(this.props.userLanguage,'Cost')}</td>
                                    <td>{this.state.price==='0'?getTranslatedPhrase(this.props.userLanguage,'FREE'):formatPriceAndCurrency(this.state.price, this.state.currency, ',')}</td>
                                </tr> */}
                                </tbody>
                            </table>
                        </div>


                        <div className="ServiceContent__Details__Main">
                            <h4>{getTranslatedPhrase(this.props.userLanguage, 'Description')}</h4>

                            {this.state.description.split('\n').map((v, i) => (<Box key={'line-' + i}>{v}<br /></Box>))}

                            {!this.state.callForPricing && !!this.state.addDiscount && this.state.addDiscount === "deal" && (
                                <>
                                    <p className="mgt-md" style={{ marginBottom: 0 }}><strong>{getTranslatedPhrase(this.props.userLanguage, 'Discount Terms & Conditions')}</strong></p>
                                    <p>
                                        {moment(this.state.discountId.endDate).isAfter(moment("2190-01-01"))
                                            ? getTranslatedPhrase(this.props.userLanguage, 'Deal expires at the discretion of the seller.')
                                            : getTranslatedPhrase(this.props.userLanguage, 'Discount valid through: ') + ": " + moment(this.state.discountId.endDate).format('L')}
                                    </p>
                                </>
                            )}
                        </div>
                    </div>


                    <div className="ServiceContent__Gallery">
                        <ListingPhotos photoList={this.state.allPhotos} show={true} />
                    </div>


                    <div className="ServiceContent__Reviews">
                        <ListingReviews
                            reviewsList={this.state.reviews}
                            show={true}
                            websiteSection="services"
                            itemId={this.state.itemId}
                            authorId={this.state.authorId}
                        />
                    </div>


                    <div className="Listing-Mobile-Map">
                        <ListingMap address={theAddress} />
                    </div>

                </div>



                <ListingSidebar
                    section={"services"}
                    category={this.state.category}
                    itemSlug={this.state.slug}
                    itemId={this.state.itemId}
                    authorId={this.state.authorId}
                    authorSlug={this.state.authorSlug}
                    authorName={this.state.authorName}
                    authorProfileImage={this.state.authorProfileImage}
                    likeList={this.state.likes}
                    createNewTitle={"New Service"}
                    userIsAuthorized={this.state.userIsAuthorized}
                />
            </div>
        );
    }
}

const mapStateToServiceDetailProps = (
    state,
    props
) => {
    return {
        userIsAuthenticated: state.userIsAuthenticated,
        pageRefresh: state.pageRefresh,
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser,
        payperviewRedirect: state.payperviewRedirect
    };
};

const mapDispatchToServiceDetailProps = (
    dispatch
) => {
    return {
        resetPayperviewRedirect: () => dispatch({ type: actionType.PAYPERVIEW_REDIRECT, payperviewRedirect: false }),
        updateMessagesPageProps: (u, m) => dispatch({ type: actionType.MESSAGES_PAGE, messagesDefaultUser: u, messagesDefaultMessage: m }),
        onShowLoginModal: () => dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: window.location.pathname }),
        showAlertModal: (m) => dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: m })
    };
};

export default connect(mapStateToServiceDetailProps, mapDispatchToServiceDetailProps)(withRouter(ServiceDetail));