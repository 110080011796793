import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import Axios from 'axios';
// import IDFront from '../Assets/id-example-front.jpg';
// import IDBack from '../Assets/id-example-back.jpg';
import { hostNameAndPort } from '../../../../shared/util/vars';
import FormFieldError from '../../../../shared/uielements/ErrorMessages/FormFieldError';
import ErrorMessages from '../../../../shared/uielements/ErrorMessages/ErrorMessages';
import LoadingSvg from '../../../../shared/uielements/LoadingSpinner/LoadingSvg';
import Box from '../../../../shared/util/box';
import {Link, useHistory} from 'react-router-dom';
import actionType from '../../../../redux-store/action-type';
import CashInTerms from '../Terms/CashInTerms'
import { Helmet } from "react-helmet";

const initCashin = {
    accountType: '',
    username: '',
    affiliateId: '',
    applicationSaved: false,
    submitWaiting: false,
    agreeWithTerms: false,
    paymentGateway: 'paypal',
    address: '',
    entityName: '',
    tabClicked: [false, true]
}

const CashInApplication = (props) => {

    const [ cashin, setCashin ] = useState(initCashin);
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ successMessage, setSuccessMessage ] = useState('Your application has been submitted.');
    const userLanguage = useSelector(state => state.userLanguage);
    const loggedInUser = useSelector(state => state.loggedInUser);
    const history = useHistory();


    useEffect(() => {
        window.scrollTo(0,0);
        getUserDataFromServer();
        getAffiliateData();
        getAffiliateAddressData();
    }, [])


    const getAffiliateData = async () => {
        let url = `${hostNameAndPort}/api/affiliate`;
        try { let result = await Axios(url);
            let {data} = result;
            if (!data.error) {
                data.affiliate.agreeWithTerms = true;
                setCashin(prevCashin => {
                    return {...prevCashin, ...data.affiliate };
                });
            }
        } catch(err) {
            // Network error
        }
    }

    const getAffiliateAddressData = async () => {
        let url = `${hostNameAndPort}/api/affiliate/address`;
        try { let result = await Axios(url);
            let {data} = result;
            if (!data.error) {
                setCashin(prevCashin => {
                    return {...prevCashin, ...data.affiliate };
                });
            }
        } catch(err) {
            // Network error
        }
    }

    const getUserDataFromServer = async () => {
        let url = `${hostNameAndPort}/api/user/whoami`;
        try { let result = await Axios(url);
            let {data} = result;
            if (!data.error && data.user.accountType === 'personal') {
                setCashin(prevCashin => {
                    return {...prevCashin, ...data.user };
                });
            } else {
                history.push('/');
            }
        } catch(err) {
            // Network error
            history.push('/');
        }
    }

    const toggleAgreeWithTerms = () => {
        setErrorMessage('');
        setCashin(prevCashin => {
            return {...prevCashin, agreeWithTerms: !prevCashin.agreeWithTerms };
        });
    }

    const onChangeValue = (e, _propName, _sizeLimit, _propMsg) => {
        if (e.target.value.length > _sizeLimit) {
            setErrorMessage(`${getTranslatedPhrase(userLanguage,_propMsg)} ${getTranslatedPhrase(userLanguage,'must be smaller than')} ${_sizeLimit} ${getTranslatedPhrase(userLanguage,'characters')}`)
            return;
        }

        const value = e.target.value;

        setErrorMessage('');

        setCashin(prevCashin => {
            return {...prevCashin, [_propName]: value, applicationSaved: false };
        });
    }

    const onChangePaymentType = (paymentGateway) => {
        setCashin(prevCashin => {
            return {...prevCashin, paymentGateway, applicationSaved: false };
        });
    }

    const formIsValid = () => {
        let err = '';
        let _ = cashin;

        if (_.paymentGateway === 'paypal' && !_.username) err = `Paypal information missing.`;
        if (!_.agreeWithTerms) err = 'You must agree with the terms.';
        
        if (err) {
            setErrorMessage(err);
            return false;
        } else return true;
    }

    // Work in Progress

    const onSubmitCashInApplication = async (i) => {
        try {
            let t = cashin.tabClicked;
            for (let j in t) t[j] = false;
            t[i] = true;
            setCashin(prevCashin => {
                return {...prevCashin, tabClicked: t };
            });

            if (cashin.applicationSaved) {
                setErrorMessage('Application has already been saved');
                return;
            }
            if (!formIsValid()) return;
            let affiliateData = new FormData();
            let nbd = Object.assign({}, cashin);
            for (let i in nbd) {
                affiliateData.append(i, nbd[i]);
            }

            let options = {
                url: `${hostNameAndPort}/api/affiliate/update`,
                method: 'post',
                data: affiliateData
            };

            setCashin(prevCashin => {
                return {...prevCashin, submitWaiting: true };
            });

            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                setSuccessMessage('Your application has been submitted.')
                setCashin(prevCashin => {
                    return {...prevCashin, affiliateId: data.affiliateId, submitWaiting: false, applicationSaved: true };
                });
                let u = loggedInUser;
                u.affiliateId = data.affiliateId;
                props.pageShouldRefresh(JSON.stringify(u));
                props.onChangeLoginInfo(u);
            } else {
                setErrorMessage( data.error )
                setCashin(prevCashin => {
                    return {...prevCashin, submitWaiting: false, applicationSaved: false };
                });
            }
        } catch (err) {
            setErrorMessage('Network error ERRFRCSHAPP01')
            setCashin(prevCashin => {
                return {...prevCashin, submitWaiting: false, applicationSaved: false };
            });
        }
    }

    const onSubmitOptOut = async (i) => {
        try {
            let t = cashin.tabClicked;
            for (let j in t) t[j] = false;
            t[i] = true;
            setCashin(prevCashin => {
                return {...prevCashin, tabClicked: t  };
            });

            let options = {
                url: `${hostNameAndPort}/api/affiliate`,
                method: 'delete'
            };

            setCashin(prevCashin => {
                return {...prevCashin, submitWaiting: true  };
            });

            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                setSuccessMessage('You have been opted out.');
                setCashin(prevCashin => {
                    return {...prevCashin, submitWaiting: false, affiliateId: '', username: ''  };
                });
                let u = loggedInUser;
                u.affiliateId = '';
                props.pageShouldRefresh(JSON.stringify(u));
                props.onChangeLoginInfo(u);
                history.push('/');
            } else {
                setErrorMessage(data.error);
                setCashin(prevCashin => {
                    return {...prevCashin, submitWaiting: false  };
                });
            }
        } catch (err) {
            setErrorMessage('Network error ERRFRCSHAPP02');
            setCashin(prevCashin => {
                return {...prevCashin, submitWaiting: false  };
            });
        }
    }

    const onUpdateInfo = () => {
        history.push('/profile-update');
    }

    return(
        <div className="main-100 cash-in-application">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Cashin Application | Followone</title>
                <meta name="description" content="Earn commission by seeing ads, deals and coupons on Followone." />
                <link rel="canonical" href="https://followone.com/cash-in-application" />
            </Helmet>

            <div className="main-100-header">
                <h1>{getTranslatedPhrase(userLanguage,'Cashin')}<span style={{verticalAlign : "super", fontSize : "30px"}}>&reg;</span> {getTranslatedPhrase(userLanguage,'Affiliate')}</h1>
                <p>{getTranslatedPhrase(userLanguage,'Earn commission by seeing ads, deals and coupons.')}</p>
            </div>

            {/* <div className="cash-in-application-box">
                <h4>ID Verification</h4>
                <p>In order to be part of the Cashin Affiliate Program you need to be 18 years of age or older, we also use your ID to verify your identity.</p>

                <div className="personal-id">
                    <div className="personal-id-box">
                        <button className="button button-clear"> Upload ID Front</button>
                        <img src={IDFront} />
                    </div>

                    <div className="personal-id-box">
                        <button className="button button-clear">Upload ID Back</button>
                        <img src={IDBack} />
                    </div>
                </div>
            </div> */}


            <div className="cash-in-application-box">
                <h4>SELECT ONE METHOD OF GETTING PAID</h4>
                <p>Once you have a minimum of $50 you can deposit your cash into the Paypal account you provide below, or receive a check by mail.</p>


                <div className="select-affiliate-payment">
                    <div onClick={(e) => onChangePaymentType('paypal')} className={`affiliate-payment-box affiliate-payment-paypal ${(cashin.paymentGateway==='paypal')?'affiliate-payment-box-selected':''}`}>
                        <h4><i className="fab fa-paypal" /> PayPal</h4>

                        <p>Transfer my cash to my PayPal:</p>

                        <label>Paypal Email or Phone Number</label>
                        <input type="text" className="form-input-text" onChange={(e) => onChangeValue(e, 'username', 50, 'Paypal username')} value={cashin.username} />

                        {(cashin.paymentGateway==='paypal') && (<Box>
                            {(!!cashin.affiliateId) && (
                                <button onClick={(e) => onSubmitCashInApplication(0)}>Update Paypal Payment</button>
                                )}
                            <FormFieldError>{cashin.tabClicked[0] && getTranslatedPhrase(userLanguage, errorMessage)}</FormFieldError>
                            {cashin.tabClicked[0] && cashin.applicationSaved && (<FormFieldError>{getTranslatedPhrase(userLanguage,'Your Paypal information has been updated.')}</FormFieldError>)}
                        </Box>)}

                        <div className="affiliate-payment-check">{(cashin.paymentGateway==='paypal') && (<i className="far fa-check" />)}</div>
                    </div>

                    <div onClick={(e) => onChangePaymentType('check')} className={`affiliate-payment-box affiliate-payment-check ${(cashin.paymentGateway==='check')?'affiliate-payment-box-selected':''}`}>
                        <h4><i className="fal fa-money-check" /> Check</h4>

                        <p>Mail my cash to:</p>

                        <table><tbody>
                            <tr>
                                <td>Name</td>
                                <td><strong>{cashin.entityName}</strong></td>
                            </tr>

                            <tr>
                                <td>Address</td>
                                <td><strong>{cashin.address.split(',').map( (v,i) => (<Box key={i}>{v}<br /></Box>))}</strong></td>
                            </tr>
                        </tbody></table>

                        <button onClick={onUpdateInfo}>Update Information</button>

                        <div className="affiliate-payment-check">{(cashin.paymentGateway==='check') && (<i className="far fa-check" />)}</div>
                    </div>
                </div>

            </div>


            <div className="cash-in-application-box">
                <h4>Terms & Conditions</h4>

                <CashInTerms />

                <div className="cash-in-agreement">
                    <div className="form-input-lists form-input-lists_row mgb-sm">
                        <input id="Check" type="checkbox" checked={cashin.agreeWithTerms} onChange={()=>null} onClick={toggleAgreeWithTerms}/>
                        <label htmlFor="Check">
                            <i className="fal fa-check" /> <span>I have read and agreed with all terms and conditions mentioned above. I declare that I am 18 years of age or older and that this is my only Cashin<span className="reg-td">&reg;</span> account.</span>
                        </label>
                    </div>
                </div>

                <div className="submit-listing">
                    <FormFieldError>{cashin.tabClicked[1] && errorMessage}</FormFieldError>

                    <ErrorMessages
                        errorMessageClass={"error-message error-message-info"}
                        errorMessageTitle={getTranslatedPhrase(userLanguage,'Success')}
                        errorMessageBody={getTranslatedPhrase(userLanguage, successMessage)} show={cashin.tabClicked[1] && cashin.applicationSaved} >
                        { !!cashin.affiliateId && (
                        <Link className="button button-regular button-small dsp-in-blk mgt-sm" to="/notifications">{getTranslatedPhrase(userLanguage,'See Your Cashin Ads')}</Link>)}
                    </ErrorMessages>

                    <LoadingSvg active={cashin.submitWaiting} />
                    {!cashin.affiliateId?(
                    <button onClick={(e) =>onSubmitCashInApplication(1)} className="button button-regular">Submit</button>):(
                        <button onClick={(e) =>onSubmitOptOut(1)} className="button button-sale">Opt Out</button>
                    )}
                </div>
            </div>
        </div>
    );
}




const mapDispatchToCashInApplicationProps = (
    dispatch
) => {
    return {
        showAlertModal: (m) => dispatch({type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: m }),
        pageShouldRefresh: (p) => dispatch({type: actionType.PAGE_REFRESH, pageRefresh: p }),
        onChangeLoginInfo: (u) => dispatch({type: actionType.USER_LOGIN, userIsAuthenticated: true, loggedInUser: u })
    };
};

export default connect(null, mapDispatchToCashInApplicationProps)(CashInApplication);


