import React from 'react';
import NewsPreview from './NewsPreview';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import NewsSideBar from './NewsSideBar';

function NewsCategory() {
  return (
  <CategoryPage 
    section="news" 
    description="Organize, Invite, or Celebrate Something"
    ItemPreview={NewsPreview} 
    SideBar={NewsSideBar} 
    FeaturedItems={() => null}
  />);
}

export default NewsCategory;