import React from 'react';
// import ProfileSettingsNav from '../ProfileNav/ProfileSettingsNav';
import ProfileSettingsBox from '../ProfileSettingsBox';
// import FormFieldError from '../../../../shared/uielements/ErrorMessages/FormFieldError';
// import LoadingSvg from '../../../../shared/uielements/LoadingSpinner/LoadingSvg';
// import ErrorMessages from '../../../../shared/uielements/ErrorMessages/ErrorMessages';
// import { hostNameAndPort } from '../../../../shared/util/vars';
// import Axios from 'axios';
// import { connect } from 'react-redux';
// import actionType from '../../../../redux-store/action-type';
// import { getTranslatedPhrase } from '../../../../shared/util/lang';
// import { Link } from 'react-router-dom';
import SettingsMenu from "../SettingsMenu";

const ListingSettings = (props) => {

    return (
        <SettingsMenu ListingSettingsLiActive="active">
            <>
                <div className="account-settings-options">
                    <ProfileSettingsBox>
                        <h4>Profile Navigation Listing</h4>
                        <p>Uncheck the categories you wish to hide from your profile page navigation.</p>

                        <div className="form-input-lists">
                            <input type="checkbox" name="hideStore" id="hideStore" value="hideStore" checked />
                            <label htmlFor="hideStore"><i className="fal fa-check"></i>Store</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="hideAuto" id="hideAuto" value="hideAuto" checked />
                            <label htmlFor="hideAuto"><i className="fal fa-check"></i>Auto</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="hideRealEstate" id="hideRealEstate" value="hideRealEstate" checked />
                            <label htmlFor="hideRealEstate"><i className="fal fa-check"></i>Real Estate</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="hideFood" id="hideFood" value="hideFood" checked />
                            <label htmlFor="hideFood"><i className="fal fa-check"></i>Food</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="hideJobs" id="hideJobs" value="hideJobs" checked />
                            <label htmlFor="hideJobs"><i className="fal fa-check"></i>Jobs</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="hideServices" id="hideServices" value="hideServices" checked />
                            <label htmlFor="hideServices"><i className="fal fa-check"></i>Services</label>
                        </div>
                        <div className="form-input-lists">
                            <input type="checkbox" name="hideFsp" id="hideFsp" value="hideFsp" checked />
                            <label htmlFor="hideFsp"><i className="fal fa-check"></i>Travel</label>
                        </div>
                    </ProfileSettingsBox>

                    <ProfileSettingsBox>
                        <h4>Auto Listings</h4>

                        <div className="form-input-lists">
                            <input type="checkbox" name="hideFsp" id="hideFsp" value="hideFsp" />
                            <label htmlFor="hideFsp"><i className="fal fa-check"></i>Hide Followone Suggested Price (FSP)</label>
                        </div>
                    </ProfileSettingsBox>

                </div>
            </>
        </SettingsMenu>
    );
}



export default ListingSettings;
