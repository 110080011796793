import React, {Component} from 'react';

import Auto from "../../../assets/home/cat-auto.jpg";
import Electronics from "../../../assets/home/cat-electronics.jpg";
import Entertainment from "../../../assets/home/cat-entertainment.jpg";
import Fashion from "../../../assets/home/cat-fashion.jpg";
import Food from "../../../assets/home/cat-food.jpg";
import Furniture from "../../../assets/home/cat-furniture.jpg";
import HomeDecor from "../../../assets/home/cat-homdecor.jpg";
import Outdoor from "../../../assets/home/cat-outdoor.jpg";
import RealEstate from "../../../assets/home/cat-realestate.jpg";
import Sports from "../../../assets/home/cat-sport.jpg";
 
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';

class Categories extends Component {
    render() {
    return (
        <div className="home-categories">
                <Link to="/autos" className="home-categories_box">
                    <img src={Auto} alt={getTranslatedPhrase(this.props.userLanguage,'Autos')} />
                    <span>{getTranslatedPhrase(this.props.userLanguage,'Auto')}</span>
                </Link>

                <Link to="/realestate" className="home-categories_box">
                    <img src={RealEstate} alt={getTranslatedPhrase(this.props.userLanguage,'Real Estate')} />
                    <span>{getTranslatedPhrase(this.props.userLanguage,'Real Estate')}</span>
                </Link>

                <Link to="/store/electronics" className="home-categories_box">
                    <img src={Electronics} alt={getTranslatedPhrase(this.props.userLanguage,'Electronics')} />
                    <span>{getTranslatedPhrase(this.props.userLanguage,'Electronics')}</span>
                </Link>

                <Link to="/store/outdoor" className="home-categories_box">
                    <img src={Outdoor} alt={getTranslatedPhrase(this.props.userLanguage,'Outdoor')} />
                    <span>{getTranslatedPhrase(this.props.userLanguage,'Outdoor')}</span>
                </Link>

                <Link to="/store/fashion" className="home-categories_box">
                    <img src={Fashion} alt={getTranslatedPhrase(this.props.userLanguage,'Fashion')} />
                    <span>{getTranslatedPhrase(this.props.userLanguage,'Fashion')}</span>
                </Link>

                <Link to="/store/entertainment" className="home-categories_box">
                    <img src={Entertainment} alt={getTranslatedPhrase(this.props.userLanguage,'Entertainment')} />
                    <span>{getTranslatedPhrase(this.props.userLanguage,'Entertainment')}</span>
                </Link>

                <Link to="/store/furniture" className="home-categories_box">
                    <img src={Furniture} alt={getTranslatedPhrase(this.props.userLanguage,'Furniture')} />
                    <span>{getTranslatedPhrase(this.props.userLanguage,'Furniture')}</span>
                </Link>

                <Link to="/store/home-decor" className="home-categories_box">
                    <img src={HomeDecor} alt={getTranslatedPhrase(this.props.userLanguage,'Home Decor')} />
                    <span>{getTranslatedPhrase(this.props.userLanguage,'Home Decor')}</span>
                </Link>

                <Link to="/store/sport" className="home-categories_box">
                    <img src={Sports} alt={getTranslatedPhrase(this.props.userLanguage,'Sport')} />
                    <span>{getTranslatedPhrase(this.props.userLanguage,'Sport')}</span>
                </Link>

                <Link to="/food" className="home-categories_box">
                    <img src={Food} alt={getTranslatedPhrase(this.props.userLanguage,'Food')} />
                    <span>{getTranslatedPhrase(this.props.userLanguage,'Food')}</span>
                </Link>

        </div>
        );
    }
}

const mapStateToHomeCategoriesProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToHomeCategoriesProps, null)(Categories);