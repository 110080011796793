import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Rate from '../../../../../shared/uielements/Engage/EngageButtons/RateButton';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import { formatPriceAndCurrency } from '../../../../../shared/util/number';
import Axios from 'axios';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../../../shared/util/lang';


class WallProduct extends Component {
    state = {
        storeItemNotFound: true,
        featuredImage: '',
        title: '',
        reviews: [],
    }

    componentDidMount() {
        let b = this.props.storeItemId;
        if (!b) {
            this.setState({ storeItemNotFound: true });
            return;
        }
        let url = `${hostNameAndPort}/api/store/${b}`;
        Axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {                
                data.item.storeItemNotFound = false;
                this.setState(data.item);
                let _wallAction;
                switch (this.props.activityType) {
                    case 'review':
                    _wallAction = 'rated a product';
                    break;
                    case 'like':
                    _wallAction = 'liked a product';
                    break;
                    case 'post':
                    _wallAction = 'posted a product';
                    break;
                    case 'share':
                    _wallAction = 'shared a product';
                    break;
                    default:
                    break;  
                }
                this.props.onItemLoaded({
                    itemFailedLoading: false,
                    wallAction: _wallAction,
                });
            }
            else {
                this.props.onItemLoaded({ itemFailedLoading: true });
            }
        })
        .catch(err => {
            //console.log('Network error');
            this.props.onItemLoaded({ itemFailedLoading: true });
        });
    }

    render () {
        if (this.state.storeItemNotFound) {
            return '';
        }
        let productSlug = `/store/${this.state.category}/${this.state.slug}`;
        return (
            <div className="WallProduct">
                <Link to={productSlug}>
                    <img src={this.state.featuredImage} alt={this.state.title} />
                </Link>

                <div className="WallProductDetails">
                    <div className="left">
                        <h4>{this.state.title}</h4>
                        <p className="WallProductPrice">{formatPriceAndCurrency(this.state.price,this.state.currency,',')}</p>
                        <div className="WallproductRating"> 
                            <Rate noStars={this.state.averageRating} noReviews={this.state.reviewCount} />
                        </div>
                    </div>

                    <div className="right">
                    <Link to={productSlug}>{getTranslatedPhrase(this.props.userLanguage,'Shop Now')}</Link>
                    </div>

                    <div className="clb" />
                </div>
            </div>
        );
    }
}


const mapStateToWallProductProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToWallProductProps, null)(WallProduct);
