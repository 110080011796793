import React from 'react';
import GroupPreview from './GroupPreview';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import GroupsSideBar from './GroupsSideBar';
import GroupsFeatured from './GroupsFeatured';

function GroupsCategory() {
  return (
  <CategoryPage 
    section="groups" 
    description="Set Up a Group to Share Your Passion and Excitement"
    ItemPreview={GroupPreview} 
    SideBar={GroupsSideBar} 
    FeaturedItems={GroupsFeatured}
  />);
}

export default GroupsCategory;