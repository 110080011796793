import React, { Component } from "react";
import FormFieldError from "../../../shared/uielements/ErrorMessages/FormFieldError";
import ErrorMessages from "../../../shared/uielements/ErrorMessages/ErrorMessages";
import { Link } from "react-router-dom";
import LoadingSvg from "../../../shared/uielements/LoadingSpinner/LoadingSvg";
import { hostNameAndPort } from "../../../shared/util/vars";
import { titleCase } from "../../../shared/util/string";
import Axios from "axios";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import Helmet from "react-helmet";

class NewVideo extends Component {
  constructor(props) {
    super(props);
    this.initialState = this.state = {
      itemId: "",
      title: "",
      slug: "",
      videoURL: "",
      description: "",
      featuredImage: "",
      iframeContent: "",
      category: "",
      subcategory: "",
      subcategoriesSelect: [],
      privacy: "public",
      hashTags: "",
      fVideoDivRef: null,
      temp: {
        mainTitle: "Add Video",
        mainButtonLabel: "Publish Video",
        errorMessage: "",
        videoSaved: false,
        postListingPressed: false,
      },
      categoriesSelect: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getVideoCategories();
    let l = this.props.location;
    if (l.pathname.indexOf("/edit-video") >= 0 && l.search) {
      let b = l.search.slice(3);
      this.setState({ slug: b });
      this.props.history.replace(this.props.match.url);
      this.getVideoData(b);
    }
  }

  getVideoCategories = async () => {
    try {
      let url = `${hostNameAndPort}/api/videos/categories`;
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        this.setState({ categoriesSelect: data.categories });
      }
    } catch (err) {
      // server, network error
    }
  };

  getVideoData = async (b) => {
    try {
      let url = `${hostNameAndPort}/api/videos/${b}`;
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        data.item.temp = {
          ...this.state.temp,
          mainTitle: "Edit Video",
          mainButtonLabel: "Update Video",
        };

        if (!!data.item.videoURL) {
          let _video = document.createElement("iframe");
          _video.title = data.item.title;
          _video.width = 288;
          _video.height = 160;
          _video.src = data.item.videoURL;
          _video.allow = "autoplay; encrypted-media";
          _video.frameBorder = "0";
          _video.allowFullscreen = true;
          this.state.fVideoDivRef.appendChild(_video);

          // let f = new DOMParser().parseFromString(data.item.iframeContent, 'text/html');
          // d.appendChild( f.firstChild );
        }

        // Subcategories
        let a = this.state.categoriesSelect;
        let b = [];
        let i = 0;
        for (; i < a.length; i++) {
          if (a[i] === data.item.category) break;
        }
        i++;
        while (/^\s*-+/.test(a[i]) && i < a.length) {
          b.push(a[i].replace(/^\s*-+\s*/, ""));
          i++;
        }
        data.item.subcategoriesSelect = [...b];
        this.setState(data.item);
      }
    } catch (err) {
      // Network error
      //console.log('network error');
    }
  };

  requiredFieldsAreFilled() {
    let res = true,
      _ = this.state;
    let _err = "";

    if (_.title === "") {
      _err = getTranslatedPhrase(this.props.userLanguage, "Title is required.");
      res = false;
    }
    if (_.description === "") {
      _err = getTranslatedPhrase(
        this.props.userLanguage,
        "Description is required."
      );
      res = false;
    }
    if (_.videoURL === "") {
      _err =
        getTranslatedPhrase(this.props.userLanguage, "Video URL") +
        " " +
        getTranslatedPhrase(this.props.userLanguage, "is required.");
      res = false;
    }
    if (
      !_.videoURL.match(
        /^(http:|https:)?\/\/([a-z]*\.)?(youtu.be|(youtube)\.com)/
      )
    ) {
      _err = getTranslatedPhrase(
        this.props.userLanguage,
        "Video URL can be from YouTube."
      );
      res = false;
    }
    if (!_.category || _.category === "select-category") {
      _err = getTranslatedPhrase(
        this.props.userLanguage,
        "Select a valid category."
      );
      res = false;
    }
    if (_.temp.videoSaved && res) {
      _err = getTranslatedPhrase(
        this.props.userLanguage,
        "Video is already saved."
      );
      res = false;
    }
    this.setState({ temp: { ...this.state.temp, errorMessage: _err } });
    return res;
  }

  onClearDraftHandler = (e) => {
    window.scrollTo(0, 0);
    let c = this.state.categoriesSelect;
    this.setState(this.initialState);
    this.setState({ categoriesSelect: c });
    let d = this.state.fVideoDivRef;
    if (d.hasChildNodes()) {
      for (let i = 0; i < d.childNodes.length; i++) {
        d.removeChild(d.childNodes[i]);
      }
    }
  };

  onPublishHandler = async () => {
    if (!this.requiredFieldsAreFilled()) {
      return;
    }
    this.setState({ temp: { ...this.state.temp, postListingPressed: true } });

    let nbd = Object.assign({}, this.state);
    nbd.title = titleCase(nbd.title);
    // delete nbd.temp;
    // delete nbd.fVideoDivRef;
    // delete nbd.categoriesSelect;

    if (/youtu.be|youtube/.test(nbd.videoURL)) {
      nbd.videoURL = nbd.videoURL.replace("watch?v=", "embed/");
      nbd.videoURL = nbd.videoURL.replace("www.", "");

      if (nbd.videoURL.includes("list")) {
        nbd.videoURL = nbd.videoURL.split("&list")[0];
      }
    }

    let newVideoData = new FormData();
    newVideoData.append("itemId", nbd.itemId);
    newVideoData.append("title", nbd.title);
    newVideoData.append("videoURL", nbd.videoURL);
    newVideoData.append("featuredImage", nbd.featuredImage);
    newVideoData.append("description", nbd.description);
    newVideoData.append("category", nbd.category);
    newVideoData.append("subcategory", nbd.subcategory);
    newVideoData.append("privacy", "public");
    newVideoData.append("hashTags", nbd.hashTags);

    if (typeof nbd.iframeContent === "string") {
      newVideoData.append("iframeContent", nbd.iframeContent);
    } else {
      newVideoData.append("iframeContent", nbd.iframeContent.outerHTML);
    }

    let options = {
      url: `${hostNameAndPort}/api/videos/update`,
      method: "post",
      data: newVideoData,
    };
    try {
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        this.setState({
          itemId: data.itemId,
          slug: data.slug,
          temp: {
            ...this.state.temp,
            videoSaved: true,
            errorMessage: "",
            mainTitle: "Edit Video",
            mainButtonLabel: "Update Video",
            postListingPressed: false,
          },
        });
      } else {
        this.setState({
          temp: {
            ...this.state.temp,
            errorMessage: data.error,
            videoSaved: false,
            postListingPressed: false,
          },
        });
      }
    } catch (err) {
      this.setState({
        temp: {
          ...this.state.temp,
          errorMessage: getTranslatedPhrase(
            this.props.userLanguage,
            "Network error ERRFEVDED01"
          ),
          videoSaved: false,
          postListingPressed: false,
        },
      });
    }
  };

  onChangeVideoProperty = (e, _propName, _sizeLimit, _propMsg) => {
    if (e.target.value.length > _sizeLimit) {
      this.setState({
        temp: {
          ...this.state.temp,
          errorMessage: `${_propMsg} must be smaller than ${_sizeLimit} characters`,
        },
      });
      return;
    }

    let _err = "";

    if (_propName === "videoURL") {
      let v = e.target.value;

      this.setState({
        videoURL: v,
      });

      if (
        !/^(http:|https:)?\/\/([a-z0-9]*.)?(youtu\.be|youtube\.com)/.test(v)
      ) {
        return this.setState({
          temp: {
            ...this.state.temp,
            errorMessage: getTranslatedPhrase(
              this.props.userLanguage,
              "Video URL can be from YouTube."
            ),
          },
        });
      }

      if (/^https?:\/\/([a-z0-9]*.)?(youtube.com|youtu.be)/.test(v)) {
        let vidName = v.substring(v.lastIndexOf("/") + 1, v.length);

        if (vidName.includes("list")) {
          vidName = vidName.split("&list")[0];
        }

        if (v.indexOf("youtube.com/watch") >= 0) {
          vidName = vidName.substring(vidName.indexOf("=") + 1, vidName.length);
        }
        // v = "https://youtube.com/embed/" + vidName;

        if (vidName.includes("&")) {
          vidName = vidName.substring(0, vidName.indexOf("&"));
        }

        let icnt = document.createElement("iframe");
        icnt.title = this.state.title;
        icnt.src = "https://youtube.com/embed/" + vidName;
        icnt.allow = "autoplay; encrypted-media";
        icnt.frameBorder = "0";
        icnt.allowFullscreen = true;

        let d = this.state.fVideoDivRef;
        if (d.hasChildNodes()) {
          for (let i = 0; i < d.childNodes.length; i++) {
            d.removeChild(d.childNodes[i]);
          }
        }
        d.appendChild(icnt);

        let featImg = `https://img.youtube.com/vi/${vidName}/default.jpg`;
        this.setState({
          videoURL: v,
          featuredImage: featImg,
          iframeContent: icnt,
          temp: { ...this.state.temp, videoSaved: false, errorMessage: "" },
        });
      }
    } else {
      if (_propName === "category" && e.target.value !== "select-category") {
        let a = this.state.categoriesSelect;
        let b = [];
        let i = 0;
        for (; i < a.length; i++) {
          if (a[i] === e.target.value) break;
        }
        i++;
        while (/^\s*-+/.test(a[i]) && i < a.length) {
          b.push(a[i].replace(/^\s*-+\s*/, ""));
          i++;
        }
        this.setState({ subcategoriesSelect: [...b] });
        this.onChangeVideoProperty(
          { target: { value: "" } },
          "subcategory",
          50,
          "Subcategory"
        );
      }
      this.setState({
        [_propName]: e.target.value,
        temp: { ...this.state.temp, videoSaved: false, errorMessage: _err },
      });
    }
  };

  render() {
    let categoriesSelect = this.state.categoriesSelect
      .filter((v) => !/^\s*-+/.test(v))
      .map((v) => ({
        value: v,
        text: getTranslatedPhrase(
          this.props.userLanguage,
          titleCase(v.replace(/-/g, " "))
        ),
      }))
      .sort((a, b) => (a.text > b.text ? 1 : -1));
    let backgroundClass = `layout-background-new layout-background-new-addvideo`;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {getTranslatedPhrase(this.props.userLanguage, "Add Video")} |
            Followone
          </title>
          <meta
            name="description"
            content="Sell products, cars, properties & travel packages, post jobs & services, find deals & coupons and connect with customers, friends & family."
          />
          <link rel="canonical" href={"https://followone.com/"} />
        </Helmet>

        <div className={backgroundClass} />

        <div className="main-100 add-new-page add-video">
          <div className="main-100-header">
            <h1>{getTranslatedPhrase(this.props.userLanguage, "Add Video")}</h1>
            {/* <p>Be passionate, be you, express yourself!</p>         */}
            <p>
              {getTranslatedPhrase(
                this.props.userLanguage,
                "Post Youtube. More features coming soon!"
              )}
            </p>
          </div>

          <div className="add-listing add-listing_full-width">
            <div className="row">
              <div className="col span-2-of-2">
                <input
                  type="text"
                  placeholder={
                    getTranslatedPhrase(
                      this.props.userLanguage,
                      "Paste YouTube Video Url Here"
                    ) + " *"
                  }
                  value={this.state.videoURL}
                  onChange={(e) =>
                    this.onChangeVideoProperty(e, "videoURL", 2048, "Video URL")
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col span-2-of-4">
                <input
                  type="text"
                  placeholder={
                    getTranslatedPhrase(this.props.userLanguage, "Video Name") +
                    " *"
                  }
                  value={this.state.title}
                  onChange={(e) =>
                    this.onChangeVideoProperty(e, "title", 50, "Video Name")
                  }
                />
              </div>

              <div className="col span-1-of-4">
                <div className="form-input-select">
                  <select
                    value={this.state.category}
                    onChange={(e) =>
                      this.onChangeVideoProperty(e, "category", 40, "Category")
                    }
                  >
                    <option value="select-category">
                      {getTranslatedPhrase(
                        this.props.userLanguage,
                        "Select Category"
                      )}{" "}
                      *
                    </option>
                    {categoriesSelect.map((v, i) => (
                      <option key={i} value={v.value}>
                        {v.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {!this.state.subcategoriesSelect ||
              !this.state.subcategoriesSelect.length ? null : (
                <div className="col span-1-of-4">
                  <div className="form-input-select">
                    <select
                      value={this.state.subcategory}
                      onChange={(e) =>
                        this.onChangeVideoProperty(
                          e,
                          "subcategory",
                          40,
                          "Subcategory"
                        )
                      }
                    >
                      <option value="select-subcategory">
                        {getTranslatedPhrase(
                          this.props.userLanguage,
                          "Select Subcategory"
                        )}{" "}
                        *
                      </option>
                      {this.state.subcategoriesSelect.map((v, i) => (
                        <option key={"sub" + i} value={v}>
                          {getTranslatedPhrase(
                            this.props.userLanguage,
                            titleCase(v.replace(/-/g, " "))
                          )}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>

            <div className="row">
              <div className="col span-2-of-2">
                <div
                  className="embedded-video-preview"
                  ref={(n) => {
                    if (!this.state.fVideoDivRef)
                      this.setState({ fVideoDivRef: n });
                  }}
                />
              </div>
            </div>

            <div className="row row-border-divider" />

            <div className="row">
              <div className="col span-1-of-2">
                <textarea
                  className="textarea_keywords"
                  placeholder={
                    getTranslatedPhrase(
                      this.props.userLanguage,
                      "Write your video description."
                    ) + " *"
                  }
                  value={this.state.description}
                  onChange={(e) =>
                    this.onChangeVideoProperty(
                      e,
                      "description",
                      1000,
                      "Description"
                    )
                  }
                />
              </div>

              <div className="col span-1-of-2">
                <textarea
                  className="textarea_keywords"
                  placeholder={
                    getTranslatedPhrase(this.props.userLanguage, "Keywords") +
                    ", " +
                    getTranslatedPhrase(this.props.userLanguage, "Example") +
                    ": " +
                    getTranslatedPhrase(
                      this.props.userLanguage,
                      "health, food, exercise, fruits, strawberries, smoothie"
                    ) +
                    "..."
                  }
                  value={this.state.hashTags}
                  onChange={(e) =>
                    this.onChangeVideoProperty(
                      e,
                      "hashTags",
                      1000,
                      "The video keywords"
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div className="submit-listing">
            <FormFieldError>{this.state.temp.errorMessage}</FormFieldError>

            <LoadingSvg active={this.state.temp.postListingPressed} />
            <div className="submit-listing-button">
              <button
                className="button button-white"
                onClick={this.onClearDraftHandler}
              >
                <i className="fal fa-plus" />{" "}
                {getTranslatedPhrase(this.props.userLanguage, "Add New")}
              </button>
              <button
                className="button button-regular"
                onClick={this.onPublishHandler}
              >
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  this.state.temp.mainButtonLabel
                )}
              </button>
            </div>

            <ErrorMessages
              errorMessageClass={"error-message error-message-info"}
              errorMessageTitle={getTranslatedPhrase(
                this.props.userLanguage,
                "Success"
              )}
              errorMessageBody={
                getTranslatedPhrase(this.props.userLanguage, "Video") +
                " " +
                getTranslatedPhrase(
                  this.props.userLanguage,
                  "has been published"
                )
              }
              show={this.state.temp.videoSaved}
            >
              <Link
                className="button button-regular button-small dsp-in-blk mgt-sm"
                to={
                  "/videos/" +
                  this.state.category +
                  "/" +
                  (this.state.subcategory || "other") +
                  "/" +
                  this.state.slug
                }
              >
                {getTranslatedPhrase(
                  this.props.userLanguage,
                  "See Your Listing"
                )}
              </Link>
            </ErrorMessages>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToNewVideoProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
  };
};

export default connect(mapStateToNewVideoProps, null)(NewVideo);
