import React from "react";
import { Link } from "react-router-dom";
import { hostNameAndPort } from "../../../shared/util/vars";
import { titleCase } from "../../../shared/util/string";
import Box from "../../../shared/util/box";
import BlogListingSidebar from "../../../shared/templates/ListingTemplate/listing-sidebar";
import EngageWithComments from "../../../shared/uielements/Engage/EngageBox";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import actionType from "../../../redux-store/action-type";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import BlogPreview from "./BlogPreview";

// import Love from '../../../UI/EngageButtons/LoveButton';
// import Comments from '../../../UI/EngageButtons/CommentButton';
// import Share from '../../../UI/EngageButtons/ShareButton';
// import EngageButtons from '../../../UI/EngageButtons/EngageButtons';

class BlogPost extends React.Component {
  state = {
    title: "",
    comments: [],
    category: "all",
    subcategory: "all",
    authorName: "",
    clickedToComment: false,
    creationDate: " ",
    featuredImage: "/uploads/default/default-featured-blog-image.jpg",
    blogContent: "",
    current_item_id: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let p = this.props.match.params || {};
    this.setState({
      pageRefresh: this.props.pageRefresh,
      current_item_id: p.storeItem,
      pageViewStart: moment(),
    });
    if (p && p.storeItem) {
      setTimeout(() => {
        this.getBlog(p.storeItem);
      }, 100);
    }
  }

  componentDidUpdate() {
    let k = this.props.pageRefresh;
    let p = this.props.match.params || {};
    if (
      this.state.pageRefresh !== k ||
      this.state.current_item_id !== p.storeItem
    ) {
      this.setState({ pageRefresh: k, current_item_id: p.storeItem });
      window.scrollTo(0, 0);
      if (p && p.storeItem) {
        this.getBlog(p.storeItem);
      }
    }
  }

  // componentDidMount() {
  //   window.scrollTo(0, 0);
  //   this.setState({
  //     pageRefresh: this.props.pageRefresh,
  //     pageViewStart: moment()
  //   });
  //   let p = this.props.match.params;
  //   if (p && p.storeItem) {
  //     setTimeout(() => {
  //       this.getBlog(p.storeItem);
  //     }, 100);
  //   }

  //   // this.setState({ locationKey: this.props.history.location.key });
  // }

  // componentDidUpdate() {
  //   let k = this.props.pageRefresh;
  //   if (this.state.pageRefresh !== k) {
  //     this.setState({ pageRefresh: k });
  //     window.scrollTo(0, 0);
  //     let p = this.props.match.params;
  //     if (p && p.storeItem) {
  //       if (moment().diff(moment(this.state.pageViewStart), "seconds") >= 5) {
  //         if (!!this.props.payperviewRedirect) {
  //           this.handlePPVCampaignViewer("");
  //           this.props.resetPayperviewRedirect();
  //         } else {
  //           this.handlePPVCampaignViewer("organic");
  //         }
  //         this.setState({ pageViewStart: moment() });
  //       }
  //       this.getBlog(p.storeItem);
  //     }
  //   }

  //   // let h = this.props.history.location.key;
  //   // if (h !== this.state.locationKey) {
  //   //   this.props.history.goBack();
  //   // }
  // }

  // componentWillUnmount() {
  //   if (moment().diff(moment(this.state.pageViewStart), "seconds") >= 5) {
  //     if (!!this.props.payperviewRedirect) {
  //       this.handlePPVCampaignViewer("");
  //       this.props.resetPayperviewRedirect();
  //     } else {
  //       this.handlePPVCampaignViewer("organic");
  //     }
  //   }
  // }

  handlePPVCampaignViewer = async (isOrganic, itemId) => {
    try {
      let ppvData = new FormData();
      ppvData.append("campaignId", this.props.payperviewRedirect);
      ppvData.append("itemId", itemId);
      ppvData.append("itemType", "blogs");
      let options = {
        url: `${hostNameAndPort}/api/ppv/add${isOrganic}view`,
        method: "post",
        data: ppvData,
      };
      let result = await axios(options);
      let { data } = result;
      if (!data.error) {
        // Success! Nothing to do
      }
    } catch (error) {
      // Network error
    }
  };

  getBlog = async (b) => {
    try {
      let url = `${hostNameAndPort}/api/blogs/${b}`;
      let result = await axios(url);
      let { data } = result;
      if (!data.error) {
        if (this.props.userIsAuthenticated) data.item.clickedToComment = true;
        setTimeout(() => {
          let myDiv = document.getElementById("blogContentDiv");
          if (myDiv) myDiv.innerHTML = data.item.blogContent;
        }, 200);

        this.setState(data.item);

        setTimeout(() => {
          if (moment().diff(moment(this.state.pageViewStart), "seconds") >= 5) {
            if (!!this.props.payperviewRedirect) {
              this.handlePPVCampaignViewer("", data.item.itemId);
              this.props.resetPayperviewRedirect();
            } else {
              this.handlePPVCampaignViewer("organic", data.item.itemId);
            }
          }
        }, 5100);
      } else this.props.history.push("/404");
    } catch (err) {
      console.log(err);
      this.props.history.push("/404");
    }
  };

  onLeaveComment = () => {
    let a = document.createElement("A");
    a.href = "#whereTheCommentStarts";
    a.click();
    this.setState({ clickedToComment: true });
  };

  resetClickToComment = () => {
    this.setState({ clickedToComment: false });
  };

  render() {
    if (!this.state.title) return null;
    return (
      <Box>
        <div className="listing-body">
          <div className="main-container blog-post">
            <Helmet>
              <meta charSet="utf-8" />
              <title>{this.state.title} | Followone</title>
              <meta
                name="description"
                content={this.state.blogContent.substring(0, 200)}
              />
              <link
                rel="canonical"
                href={
                  "https://followone.com/blogs/" +
                  this.state.category +
                  "/" +
                  this.state.subcategory +
                  "/" +
                  this.state.slug
                }
              />
            </Helmet>

            <div className="blog-post_feature">
              <div className="blog-post_feature_image">
                <img
                  src={this.state.featuredImage}
                  alt={this.state.title}
                  width="958"
                />
              </div>

              <div className="blog-post_header">
                <div className="blog-post_header_title">
                  <h1>{this.state.title}</h1>
                </div>

                <div className="blog-post_header_meta">
                  {getTranslatedPhrase(this.props.userLanguage, "posted on")}{" "}
                  {moment(this.state.creationDate).local().format("LL")} /{" "}
                  {getTranslatedPhrase(this.props.userLanguage, "in")}{" "}
                  {/* <Link to={"/blogs/" + this.state.category}> */}
                  {getTranslatedPhrase(
                    this.props.userLanguage,
                    titleCase(this.state.category.replace(/-/g, " "))
                  )}
                  {/* </Link>*/}{" "}
                  {this.state.subcategory &&
                    this.state.subcategory !== "null" &&
                    "/ " +
                      getTranslatedPhrase(
                        this.props.userLanguage,
                        titleCase(this.state.subcategory.replace(/-/g, " "))
                      ) +
                      " "}
                  / &nbsp;{getTranslatedPhrase(this.props.userLanguage, "by")}{" "}
                  <Link to={"/" + this.state.authorSlug}>
                    {this.state.authorName}
                  </Link>
                </div>

                <div className="blog-post_header_engage">
                  <EngageWithComments
                    itemId={this.state.itemId}
                    websiteSection="blogs"
                    authorId={this.state.authorId}
                    withComments={true}
                    hideAllComments={true}
                    getClickToCommentEvent={true}
                    onLeaveComment={this.onLeaveComment}
                  />
                </div>
              </div>
            </div>

            <div className="blog-post_content">
              <div id="blogContentDiv">
                {/* {this.state.blogContent.split("\n").map((v, i) => (
                  <Box key={"line-" + i}>
                    <p>{v}</p>
                  </Box>
                ))} */}
              </div>
            </div>

            <div className="blog-post_comments" id="whereTheCommentStarts">
              <EngageWithComments
                itemId={this.state.itemId}
                websiteSection="blogs"
                authorId={this.state.authorId}
                showCommentsTitle={true}
                withComments={true}
                hideButtons={true}
                clickedToComment={this.state.clickedToComment}
                resetOutsideClickToComment={this.resetClickToComment}
              />
            </div>
          </div>

          <BlogListingSidebar
            section="blogs"
            category={this.state.category}
            itemSlug={this.state.slug}
            itemId={this.state.itemId}
            authorId={this.state.authorId}
            createNewTitle="New Blog"
            authorProfileImage={this.state.authorProfileImage}
            authorSlug={this.state.authorSlug}
            authorName={this.state.authorName}
            likeList={this.state.likes}
            userIsAuthorized={this.state.userIsAuthorized}
            ItemPreview={BlogPreview}
          />
        </div>
      </Box>
    );
  }
}

const mapStateToBlogPostProps = (state, props) => {
  return {
    userIsAuthenticated: state.userIsAuthenticated,
    pageRefresh: state.pageRefresh,
    userLanguage: state.userLanguage,
    payperviewRedirect: state.payperviewRedirect,
  };
};

const mapDispatchToBlogPostProps = (dispatch) => {
  return {
    resetPayperviewRedirect: () => {
      dispatch({
        type: actionType.PAYPERVIEW_REDIRECT,
        payperviewRedirect: false,
      });
    },
  };
};

export default connect(
  mapStateToBlogPostProps,
  mapDispatchToBlogPostProps
)(withRouter(BlogPost));
