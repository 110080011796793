import React, { useState } from 'react';
import TravelPreview from './TravelPreview';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import TravelSideBar from './TravelSideBar';
import TravelSideBarMobile from './TravelSideBarMobile';
import TravelFeatured from './TravelFeatured';
import TravelHeader from '../../Home/Components/Travel';
import feaImage from '../../../assets/category/followone_cat_feature_travel.jpg';

function TravelCategory() {
  const [categoryList, setCategoryList] = useState([]);

  function onUpdateCategories(c) {
    if (!categoryList.length)
      setCategoryList(c);
  }

  return (
    <>
      <div className="category-search-panel" style={{ backgroundImage: `url(${feaImage})` }}>
        <TravelHeader category section="travel" categoryList={categoryList} />
      </div>
      <CategoryPage
        section="travel"
        description="Browse Places to Vacation"
        ItemPreview={TravelPreview}
        SideBar={(props) => (<TravelSideBar onLoadCategories={onUpdateCategories} {...props} />)}
        SideBarMobile={(props) => (<TravelSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
        FeaturedItems={TravelFeatured}
      />
    </>
  );
}

export default TravelCategory;