import React, { Component } from 'react';
import { connect } from 'react-redux';
import Backdrop from '../../../uielements/Backdrop/Backdrop';
import { Link } from 'react-router-dom';
import { getTranslatedPhrase } from '../../../util/lang';
import { formatPriceAndCurrency } from '../../../util/number';
import { hostNameAndPort } from '../../../util/vars';
import moment from 'moment';
import FormFieldError from '../../../uielements/ErrorMessages/FormFieldError';
import LoadingSvg from '../../../uielements/LoadingSpinner/LoadingSvg';
import Axios from 'axios';
import EnterpriseModal from './EnterpriseModal';



class EditUserModal extends Component {
    constructor(props) {
        super(props);
        this.initialState = this.state = {
            userId: '',
            email: '',
            invoiceList: [
                { title: 'Hello', amount: '200', creationDate: '2019-12-12', paymentMethod: 'paypal', paid: '' },
                { title: 'World', amount: '300', creationDate: '2019-12-12', paymentMethod: 'paypal', paid: '' }
            ],
            accountType: '',
            subscription: '',
            affiliateId: '',
            resellerId: '',
            customerService: false,
            enterpriseModalOpen: false,
            temp: {
                mainTitle: 'NEW USER',
                errorMessage: '',
                userSaved: false,
                postListingPressed: false,
            },
        };
    }

    componentDidMount() {
        this.setState(this.initialState);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.active && prevProps.active !== this.props.active) {
            if (!this.props.oldUser) {
                this.setState(this.initialState);
            } else {
                let c = this.props.oldUser;
                if (Object.keys(c).length) {
                    c.temp = { mainTitle: 'EDIT USER' };
                    this.updateInvoiceList(c);
                    this.setState(c);
                }
            }
        }
    }

    updateInvoiceList = async (u) => {
        try {
            let f = { user: '' };
            if (!!u) f.user = u.userId;
            let invoiceData = new FormData();
            invoiceData.append('filter', JSON.stringify(f));
            let options = {
                url: `${hostNameAndPort}/api/invoices/items`,
                method: 'post',
                data: invoiceData
            }
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.setState({ invoiceList: data.invoiceList });
            }
        } catch (err) {
            // Network error
        }
    }

    onChangeUserProp = (e, _propName, _sizeLimit, _propMsg) => {
        let ourCurrentValue = e.target.value;
        if (ourCurrentValue.length > _sizeLimit) {
            this.setState({ temp: { ...this.state.temp, errorMessage: `${getTranslatedPhrase(this.props.userLanguage, _propMsg)} ${getTranslatedPhrase(this.props.userLanguage, 'must be smaller than')} ${_sizeLimit} ${getTranslatedPhrase(this.props.userLanguage, 'characters')}` } });
            return;
        }
        this.setState({ [_propName]: ourCurrentValue, temp: { ...this.state.temp, userSaved: false, errorMessage: '' } });
    }

    requiredFieldsAreFilled() {
        let res = true, _ = this.state;
        let _err = '';

        if (!_.accountType) {
            _err = 'Account Type is invalid';
            res = false;
        }
        if (_.temp.userSaved && res) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'User') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'is already saved');
            res = false;
        }
        this.setState({ temp: { ...this.state.temp, errorMessage: _err } });
        return res;
    }

    onClickOnCustomerService = async () => {
        let newUserData = new FormData();
        newUserData.append('userId', this.state.userId);
        let url = `${hostNameAndPort}/api/admin/cs-account/` + this.state.userId + '/' + (!this.state.customerService ? 'add' : 'remove');
        try {
            let result = await Axios(url);
            let { data } = result; console.log(data)
            if (!data.error) {
                let nbd = Object.assign({}, this.state);
                delete nbd.temp;
                delete nbd.openModal;
                delete nbd.enterpriseModalOpen;
                nbd.customerService = !this.state.customerService;

                this.props.onClose(nbd);
                this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: '', userSaved: false } });
            } else {
                this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: data.error, userSaved: false } });
            }
        } catch (err) {
            this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEEDUSRMD02'), userSaved: false } });
        }
    }

    onSaveUser = async () => {
        if (!this.requiredFieldsAreFilled()) {
            return;
        }
        this.setState({ temp: { ...this.state.temp, postListingPressed: true } });

        let nbd = Object.assign({}, this.state);
        delete nbd.temp;
        delete nbd.openModal;
        delete nbd.enterpriseModalOpen;

        let newUserData = new FormData();
        for (let i in nbd) {
            newUserData.append(i, nbd[i]);
        }
        let
            options = {
                url: `${hostNameAndPort}/api/user/update`,
                method: 'post',
                data: newUserData
            }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                nbd.userId = data.userId;
                this.props.onClose(nbd);
                this.setState({ userId: data.userId, temp: { ...this.state.temp, postListingPressed: false, userSaved: true, errorMessage: '', mainTitle: 'EDIT USER' } });
            }
            else {
                this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: data.error, userSaved: false } });
            }
        } catch (err) {
            this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEEDUSRMD01'), userSaved: false } });
        }
    }

    onDisableUser = async () => {
        this.setState({ temp: { ...this.state.temp, postListingPressed: true } });

        let newUserData = new FormData();
        newUserData.append('userId', this.state.userId);
        let
            options = {
                url: `${hostNameAndPort}/api/admin/disable-account`,
                method: 'post',
                data: newUserData
            }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.props.onClose();
                this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: '', userSaved: false } });
            } else {
                this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: data.error, userSaved: false } });
            }
        } catch (err) {
            this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEEDUSRMD02'), userSaved: false } });
        }
    }

    togglePaymentMethod = (i) => {
        let a = this.state.invoiceList;
        if (a[i].paymentMethod === 'paypal') a[i].paymentMethod = 'check';
        else a[i].paymentMethod = 'paypal';
        this.setState({ invoiceList: a });
    }

    onChangeTransactionId = (e, i) => {
        let a = this.state.invoiceList;
        if (a[i].transactionId.length < 200) a[i].transactionId = e.target.value;
        this.setState({ invoiceList: a });
    }

    onClickPay = async (v, i) => {
        if (v.paid === 'yes') {
            this.onClickUndoPay(v, i);
            return;
        }

        this.setState({ temp: { ...this.state.temp, postListingPressed: true } });

        let newUserData = new FormData();
        newUserData.append('user', JSON.stringify(v || {}));
        let
            options = {
                url: `${hostNameAndPort}/api/invoices/update`,
                method: 'post',
                data: newUserData
            }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                let a = this.state.invoiceList;
                a[i].paid = 'yes';
                a[i].undo = 'Undo';
                this.setState({ invoiceList: a, temp: { ...this.state.temp, postListingPressed: false, errorMessage: '', userSaved: false } });
            } else {
                this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: data.error, userSaved: false } });
            }
        } catch (err) {
            this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEEDUSRMD03'), userSaved: false } });
        }
    }

    onClickUndoPay = async (v, i) => {
        if (v.paid === 'not-yet') return;

        this.setState({ temp: { ...this.state.temp, postListingPressed: true } });

        let newUserData = new FormData();
        newUserData.append('user', JSON.stringify(v || {}));
        newUserData.append('undo', 'undo');
        let
            options = {
                url: `${hostNameAndPort}/api/invoices/update`,
                method: 'post',
                data: newUserData
            }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                let a = this.state.invoiceList;
                a[i].paid = 'not-yet';
                a[i].undo = '';
                this.setState({ invoiceList: a, temp: { ...this.state.temp, postListingPressed: false, errorMessage: '', userSaved: false } });
            } else {
                this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: data.error, userSaved: false } });
            }
        } catch (err) {
            this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEEDUSRMD04'), userSaved: false } });
        }
    }

    onLoginAsUser = async () => {
        this.setState({ temp: { ...this.state.temp, postListingPressed: true } });

        let newUserData = new FormData();
        newUserData.append('userId', this.state.userId);
        let
            options = {
                url: `${hostNameAndPort}/api/admin/login`,
                method: 'post',
                data: newUserData
            }
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.props.onClose();
                this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: '', userSaved: false } });
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            } else {
                this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: data.error, userSaved: false } });
            }
        } catch (err) {
            this.setState({ temp: { ...this.state.temp, postListingPressed: false, errorMessage: getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEEDUSRMD05'), userSaved: false } });
        }
    }

    openEnterpriseModal = () => {
        this.props.onClose();
        this.setState({ enterpriseModalOpen: true });
    }

    closeEnterpriseModal = () => {
        this.setState({ enterpriseModalOpen: false });
    }

    onEditUserProfile = () => {
        window.open('/profile-update?slug=' + this.state.slug, '_nextWindow');
    }

    render() {
        if (!this.props.active) {
            if (this.state.enterpriseModalOpen) return (<EnterpriseModal
                active={this.state.enterpriseModalOpen}
                onClose={this.closeEnterpriseModal}
                userId={this.state.userId}
            />);
            return null;
        }
        return (
            <div className="modal-wrapper">
                <Backdrop show={this.props.active} clicked={(e) => this.props.onClose()} />
                <div className="modal admin-user-modal">
                    <div className="modal_body">
                        <h4 className="mgt-sm">{getTranslatedPhrase(this.props.userLanguage, this.state.temp.mainTitle)}</h4>

                        <div className="moda_body_functions">

                            <div className="admin-user-modal-header">
                                <h4>User: {this.state.email} <span>(<Link to={this.state.slug || '/'}>View Profile</Link>)</span></h4>
                                <p>{this.state.accountType} / {(this.state.subscription === 'free') ? 'social' : this.state.subscription} / {(!this.state.affiliateId && !this.state.resellerId) ? 'n/a' : (!this.state.affiliateId) ? 'reseller' : 'cashin'}</p>
                            </div>



                            <div className="admin-user-modal-af-payment-requests">

                                <p><strong>Affiliate Payment Requests</strong></p>
                                <table className="ras-invoice-table">
                                    <tbody>
                                        <tr className="table-header">
                                            <td>Invoice ID</td>
                                            <td>Request Date</td>
                                            <td>Amount</td>
                                            <td>Method</td>
                                            <td>Transaction ID</td>
                                            <td>Process</td>
                                        </tr>

                                        {this.state.invoiceList.filter(v => (v.paid === 'not-yet' || !!v.undo)).map((v, i) => (
                                            <tr>
                                                <td>{v.title}</td>
                                                <td>{moment(v.creationDate).format('L')}</td>
                                                <td>{formatPriceAndCurrency(v.amount, 'USD', ',')}</td>
                                                <td><button onClick={() => this.togglePaymentMethod(i)}>{v.paymentMethod}</button></td>
                                                <td><input type="text" value={v.transactionId} onChange={(e) => this.onChangeTransactionId(e, i)} /></td>
                                                <td>
                                                    <div className="form-input-lists" onClick={() => this.onClickPay(v, i)}>
                                                        <input type="checkbox" name="adminUserAffiliatePaid" checked={v.paid === 'yes'} />
                                                        <label htmlFor="adminUserAffiliatePaid"><span><i className="fal fa-check" /></span> {(!v.undo) ? 'Paid' : 'Undo'}</label>
                                                    </div>
                                                </td>
                                            </tr>))}
                                    </tbody>

                                </table>
                            </div>



                            <div className="admin-user-modal-af-payment-history">
                                <p><strong>Affiliate Payment History</strong></p>
                                <table className="ras-invoice-table">
                                    <tbody>
                                        <tr className="table-header">
                                            <td>Invoice ID</td>
                                            <td>Payment Date</td>
                                            <td>Amount</td>
                                            <td>Method</td>
                                            <td>Transaction ID</td>
                                        </tr>

                                        {this.state.invoiceList.filter(v => (v.paid === 'yes' && !v.undo)).map((v, i) => (
                                            <tr>
                                                <td>{v.title}</td>
                                                <td>{moment(v.creationDate).format('L')}</td>
                                                <td>{formatPriceAndCurrency(v.amount, 'USD', ',')}</td>
                                                <td>{v.paymentMethod}</td>
                                                <td>{v.transactionId}</td>
                                            </tr>))}
                                    </tbody>
                                </table>
                            </div>



                            <div className="admin-user-modal-af-transactions-history">

                            </div>



                            {/* <div className="row">
                                    <div className="col span-2-of-2">
                                        <input type="text" className="form-input-text" value={this.state.email} 
                                            placeholder={getTranslatedPhrase(this.props.userLanguage,'Email')} />  
                                    </div>
                                </div> */}


                            {/* <div className="row">
                                    <div className="col span-3-of-7"><input type="text" className="form-input-text" value={getTranslatedPhrase(this.props.userLanguage,'Account Type')} 
                                            placeholder={getTranslatedPhrase(this.props.userLanguage,'Account Type')} /></div>


                                    <div className="col span-4-of-7">
                                        <div className="form-input-select">
                                            <select value={this.state.accountType} onChange={(e) => this.onChangeUserProp(e, 'accountType', 40, 'Account Type')}>
                                              <option value="personal">{getTranslatedPhrase(this.props.userLanguage,'Personal')}</option>
                                              <option value="business">{getTranslatedPhrase(this.props.userLanguage,'Business')}</option>
                                              <option value="organization">{getTranslatedPhrase(this.props.userLanguage,'Organization')}</option>
                                              <option value="institution">{getTranslatedPhrase(this.props.userLanguage,'Institution')}</option>
                                              <option value="public-figure">{getTranslatedPhrase(this.props.userLanguage,'Public Figure')}</option>
                                            </select>
                                        </div>
                                    </div>  
                                </div> */}


                            <br />

                            <FormFieldError>{this.state.temp.errorMessage}</FormFieldError>
                            <LoadingSvg active={this.state.temp.postListingPressed} />


                            <div className="admin-user-modal-footer">
                                <button className="button button-regular button-small"
                                    onClick={this.onSaveUser}>{getTranslatedPhrase(this.props.userLanguage, 'UPDATE')}
                                </button>

                                <button className="button button-sale button-small"
                                    onClick={this.onDisableUser}>{getTranslatedPhrase(this.props.userLanguage, 'DISABLE USER')}
                                </button>

                                <button className="button button-regular button-small"
                                    onClick={this.onLoginAsUser}>{getTranslatedPhrase(this.props.userLanguage, 'LOGIN AS USER')}
                                </button>

                                <button className="button button-regular button-small"
                                    onClick={this.onEditUserProfile}>{getTranslatedPhrase(this.props.userLanguage, 'EDIT PROFILE')}
                                </button>

                                <button className="button button-regular button-small"
                                    onClick={this.openEnterpriseModal}>{getTranslatedPhrase(this.props.userLanguage, 'ENTERPRISE')}
                                </button>

                                <button className="button button-regular button-small"
                                    onClick={this.onClickOnCustomerService}>{getTranslatedPhrase(this.props.userLanguage, 'MAKE ' + (this.state.customerService ? 'REGULAR' : 'CUSTOMER SERVICE'))}
                                </button>
                            </div>

                        </div>
                    </div>

                    <span onClick={(e) => this.props.onClose()} className="modal_close"><i className="fal fa-times" /></span>
                </div>
            </div>
        );
    }
}

const mapStateToEditUserModalProps = (
    state,
    props
) => {
    return {
        // active: state.EditUserModalActive,
        // modalMessage: state.EditUserModalMessage,
        // EditUserModalButtonLabel: state.EditUserModalButtonLabel,
        // EditUserModalRedirect: state.EditUserModalRedirect,
        userLanguage: state.userLanguage
    };
};

const mapDispatchToEditUserModalProps = (
    dispatch
) => {
    return {
        // onHideEditUserModal: () => {
        //     dispatch({type: actionType.ALERT_MODAL, EditUserModalActive: false, EditUserModalMessage: '', EditUserModalRedirect: '', alerModalButtonLabel: ''});
        // },
    };
};

export default connect(mapStateToEditUserModalProps, mapDispatchToEditUserModalProps)(EditUserModal);