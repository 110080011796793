import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import LoadingSvg from '../../../shared/uielements/LoadingSpinner/LoadingSvg';

import PageNumbers from '../../../shared/uielements/Pagination/page-numbers';
import {getTranslatedPhrase, getCountryFilter} from '../../../shared/util/lang';
import SectionLoader from '../../../shared/util/section-loader';
import JobsPreview from '../../Listings/Jobs/JobsPreview';
import {Helmet} from "react-helmet";

const initCareer = {
    filter: '',
    value: '',
    currentPage: 1,
    itemsPerPage: 18,
    loaded: false,
    pageRefresh: '',
    totalCount: 0,
    items: [],
    Loader: null,
}

const Careers = (props) => {

    const [careers, setCareers] = useState(initCareer);

    useEffect(() => {
        // Trigger the update
        window.scrollTo(0, 0);

        let f = {
            keywords: 'followone',
            country: 'USA'
        };

        let formParams = {
            filter: JSON.stringify(f),
            geolocation: JSON.stringify(props.currentGeolocation)
        };

        if (!careers.Loader) {
            initLoader(1, formParams);
            return;
        }

        careers.Loader.updateFormParams(formParams);
    }, [])

    useEffect(() => {
        // Get the current page
        const urlParams = new URLSearchParams(window.location.search);
        const currentPage = Number(urlParams.get('page')) || 1;

        let m = props.match, formParams = {};
        // When the category changes
        let localCategory;
        if (typeof m.params.storeCategory !== 'undefined') {
            localCategory = m.params.storeCategory;
        } else {
            localCategory = '';
        }
        let categoryChanged = (m.url.indexOf('/jobs/search/') < 0) && (careers.value !== localCategory);
        // When the search query changes
        let q = '';
        if (typeof m.params.storeQuery !== 'undefined') {
            q = m.params.storeQuery;
        }

        if (categoryChanged) {
            setCareers(prevCareers => {
                return {
                    ...prevCareers,
                    filter: 'category',
                    value: localCategory,
                    loaded: false
                }
            })

            let f = {
                category: localCategory,
                country: getCountryFilter(props.searchQuery)
            };
            formParams = {
                filter: JSON.stringify(f),
                //  geolocation: JSON.stringify(props.currentGeolocation)
            };

            let geolocationposition = JSON.stringify(props.currentGeolocation);

            if (f.country === "Select Location") {
                formParams.geolocation = geolocationposition;
            }

            if (!careers.Loader) {
                initLoader(currentPage, formParams);
                return;
            }
            careers.Loader.updateFormParams(formParams);
        } else if (q && (careers.value !== q)) {
            setCareers(prevCareers => {
                return {
                    ...prevCareers,
                    filter: 'keywords',
                    value: q,
                    loaded: false
                }
            })
            let f = {
                keywords: q,
                country: getCountryFilter(props.searchQuery)
            };
            formParams = {
                filter: JSON.stringify(f),
                geolocation: JSON.stringify(props.currentGeolocation)
            };
            if (!careers.Loader) {
                initLoader(currentPage, formParams);
                return;
            }
            careers.Loader.updateFormParams(formParams);
        } else if (currentPage !== careers.currentPage) {
            setCareers(prevCareers => {
                return {
                    ...prevCareers,
                    currentPage,
                    loaded: false
                }
            })

            careers.Loader.updatePage(currentPage);
        }
    })


    const initLoader = (page, formParams) => {
        setCareers(prevCareers => {
            return {
                ...prevCareers,
                Loader: new SectionLoader({
                    section: 'jobs',
                    cb: onLoad,
                    options: {
                        page,
                        pageCount: careers.itemsPerPage,
                        formParams
                    }
                })
            }
        })
    }

    const onLoad = (err) => {
        if (err && (process.env.NODE_ENV === 'development')) console.log(err);

        window.scrollTo(0, 0);

        setCareers(prevCareers => {
            return {
                ...prevCareers,
                loaded: true,
            }
        })
        
    }

    const onUpdatePageNumber = (n = 1) => {
        props.history.push(props.match.url + '?page=' + n);
    }

    let totalCount = !careers.Loader ? 0 : careers.Loader.totalCount;
    let items = !careers.Loader ? [] : careers.Loader.items;

    return (
        <div className="main-100 careers">

            <Helmet>
                <meta charSet="utf-8"/>
                <title>Careers | Followone</title>
                <meta name="description" content="Start a career, not a job. Join us today!"/>
                <link rel="canonical" href="https://followone.com/careers"/>
            </Helmet>

            <div className="main-100-header">
                <h1>{getTranslatedPhrase(props.userLanguage, 'Careers')}</h1>
                <p>{getTranslatedPhrase(props.userLanguage, 'Start a career, not a job. Join us today!')}</p>
            </div>

            <div className="main-container store-home">
                <div className="category-list">

                    <LoadingSvg active={!careers.loaded}/>

                    {(!totalCount && careers.loaded) ? getTranslatedPhrase(props.userLanguage, 'No items that match this criteria were found.') : null}
                    {items.map((v) => (<JobsPreview key={'jobs/' + v.slug} {...v} />))}
                </div>

                <PageNumbers
                    count={Math.ceil(totalCount / careers.itemsPerPage)}
                    activePage={careers.currentPage}
                    onClick={onUpdatePageNumber}/>
            </div>


        </div>
    );
}


const mapStateToCareersProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        currentGeolocation: state.currentGeolocation,
        searchQuery: state.searchQuery
    };
};

export default connect(mapStateToCareersProps, null)(withRouter(Careers));
