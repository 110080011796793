import React from "react";
import ListingPage from "../../../../shared/templates/ListingTemplate/listing-edit";
import PropertyEditFields from "./PropertyEditFields";
import moment from 'moment';

function PropertyEdit(props) {
  function checkReqs(item) {
    let m = "";

    if (!item.title) {
      m = "Title is required.";
    }
    if (!item.description.trim()) {
      m = "Description is required.";
    }
    if (
      (!item.callForPricing && !Number(item.price)) ||
      Number(item.price) < 0
    ) {
      m = "Price is not valid.";
    }
    if (!item.callForPricing && String(item.price).indexOf('.') >= 0 && String(item.price).length > 2 && String(item.price).indexOf('.') < String(item.price).length - 2) {
      m = 'Warning! Price cannot include comma(,). Also the dot(.) means decimals follow.'
    }
    if (item.addDiscount === "how-about-discount") {
      m = "Does your listing have a discount?";
    }
    if (item.addDiscount === "add-discount") {

      if (moment(item.temp.startDate).isAfter(moment(item.temp.endDate))) {
        m = "Discount end date should be after the start date."
      }

      if (moment(item.temp.endDate).isBefore(moment().subtract(1, 'days'))) {
        m = "Discount end date can't be a past date."
      }

      if (moment(item.temp.startDate).year() > 3000 || moment(item.temp.endDate).year() > 3000) {
        m = "Please enter a valid year."
      }

      if (!item.temp.discountType || item.temp.discountType === 'discount-type' ||
        (item.temp.discountType === 'discount-deal' && (!Number(item.temp.discountValue) || !item.temp.availability || item.temp.availability === 'set-availability' || ((!item.temp.startDate || !item.temp.endDate) && item.temp.availability !== 'no-expiration-date'))) ||
        (item.temp.discountType === 'discount-coupon' && (!item.temp.selectWhichCoupon || item.temp.selectWhichCoupon === 'select-coupon')))
        m = 'You need to complete the discount information';
    }
    if (!item.featuredImage) {
      m = "You need to choose the main photo.";
    }
    if (!item.category || item.category === "select-category") {
      m = "Select a valid type.";
    }
    if (!item.subcategory || item.subcategory === "select-subcategory") {
      m = "Select a valid subtype.";
    }
    if (item.newAddress !== "same-as-business-address") {
      // if (!item.address) {
      //   m = "Address is required.";
      // }
      if (!item.city) {
        m = "Please add your city.";
      }
      if (!item.state || item.state === "choose-state") {
        m = "State is required.";
      }
      if (!item.country || item.country === "choose-country" || item.country === '-1') {
        m = "Please select your country.";
      }
      if (!item.zipCode) {
        m = "Zip Code is required.";
      }
    }
    // if ( !item.bedCount) {
    //     m = 'Choose the number of bedrooms');
    //     res = false;
    // }
    // if ( !item.bathCount) {
    //     m = 'Choose the number of bathrooms');
    //     res = false;
    // }
    // if ( !item.floorSize) {
    //     m = 'Choose the floor size');
    //     res = false;
    // }
    if (!item.rentOrSell || item.rentOrSell === "sell-rent") {
      m = "Specify whether you are renting or selling.";
    }

    return m;
  }

  return (
    <ListingPage
      section="realestate"
      editListingUrl="/edit-property"
      itemType="Property Listing"
      mainSubtitle="Sell your property without selling fees."
      checkRequirements={checkReqs}
      ItemFields={PropertyEditFields}
    />
  );
}

export default PropertyEdit;
