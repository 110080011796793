import React from 'react';
import ListingDetail from '../../../shared/templates/ListingTemplate/listing-detail';

export default function EventListingDetail(props) {
  return (
    <ListingDetail 
      section="events" 
      createNewTitle="New Event"
    />
  );
}