import React, {Component} from 'react';
import {connect} from 'react-redux';
import { getTranslatedPhrase } from '../../../util/lang';

class ListingSpecs extends Component {
    render() {
    if (!this.props.show || !this.props.specList) {
        return null;
    }
    return (
        <div className="ProductDetailBox ProductDetailSpecs">
            <h4>{(this.props.specList && this.props.specList.length)?getTranslatedPhrase(this.props.userLanguage, this.props.specsTitle || 'Specifications'):(
                !this.props.specsTitle)?getTranslatedPhrase(this.props.userLanguage, 'No Specifications'):getTranslatedPhrase(this.props.userLanguage, 'No '+this.props.specsTitle)}</h4>

            <table>
                <tbody>
                    {this.props.specList.map( (v,i) => (!v.specValue)?null:(<tr key={v.specName+'-'+i}>
                        <td>{getTranslatedPhrase(this.props.userLanguage, v.specName)}</td>
                        <td>{getTranslatedPhrase(this.props.userLanguage, v.specValue)}</td>
                    </tr>) )}
                </tbody>
            </table>
        </div>
        );
    }
}

const mapStateToListingSpecsProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToListingSpecsProps, null)(ListingSpecs);