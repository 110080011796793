import React, { Component } from 'react';
import { howRecentIsThis } from '../../../shared/util/date';
import { titleCase } from '../../../shared/util/string';
import { hostNameAndPort } from '../../../shared/util/vars';
import Box from '../../../shared/util/box';
import Axios from 'axios';
import ListingSidebar from '../../../shared/templates/ListingTemplate/listing-sidebar';
import ListingMap from '../../../shared/templates/ListingTemplate/components/ListingMap';
import moment from 'moment';
import ListingHeader from '../../../shared/templates/ListingTemplate/components/ListingHeader';
import { connect } from 'react-redux';
import actionType from '../../../redux-store/action-type';
import ListingPhotos from '../../../shared/templates/ListingTemplate/components/ListingPhotos';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import LoadingSvg from '../../../shared/uielements/LoadingSpinner/LoadingSvg';


class JobPosting extends Component {
    state = {
        preferredQualifications: [],
        requiredQualifications: [],
        responsibilities: [],
        description: '',
        authorName: '',
        authorId: '',
        authorProfileImage: '\\uploads\\default\\default-profile-image.jpg',
        brandName: '',
        title: '',
        category: 'all',
        subcategory: 'all',
        jobType: '',
        experienceLevel: '',
        pageRefresh: '',
        allPhotos: [`/uploads/default/default-group-profile-image.jpg`],
        current_item_id: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let p = this.props.match.params || {};
        this.setState({ pageRefresh: this.props.pageRefresh, current_item_id: p.storeItem, pageViewStart: moment() });
        if (p && p.storeItem) {
            setTimeout(() => {
                this.getJobPosting(p.storeItem);
            }, 100);
        }
    }

    componentDidUpdate() {
        let k = this.props.pageRefresh;
        let p = this.props.match.params || {};
        if (this.state.pageRefresh !== k || this.state.current_item_id !== p.storeItem) {
            this.setState({ pageRefresh: k, current_item_id: p.storeItem });
            window.scrollTo(0, 0);
            if (p && p.storeItem) {
                this.getJobPosting(p.storeItem);
            }
        }
    }

    handlePPVCampaignViewer = async (isOrganic, itemId) => {
        try {
            let ppvData = new FormData();
            ppvData.append('campaignId', this.props.payperviewRedirect);
            ppvData.append('itemId', itemId);
            ppvData.append('itemType', 'jobs');
            let options = {
                url: `${hostNameAndPort}/api/ppv/add${isOrganic}view`,
                method: 'post',
                data: ppvData
            };
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                // Success! Nothing to do
                // console.log('success')
            }
        } catch (error) {
            // Network error
        }
    }

    getJobPosting = async (j) => {
        let url = `${hostNameAndPort}/api/jobs/${j}`;
        try {
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                data.item.responsibilities = data.item.responsibilities.split('\n').filter(v => (!!v && v !== '\r'));
                data.item.requiredQualifications = data.item.requiredQualifications.split('\n').filter(v => (!!v && v !== '\r'));
                data.item.preferredQualifications = data.item.preferredQualifications.split('\n').filter(v => (!!v && v !== '\r'));
                data.item.allPhotos = [data.item.featuredImage, ...data.item.extraPhotos];

                this.setState(data.item);

                setTimeout(() => {
                    if (moment().diff(moment(this.state.pageViewStart), 'seconds') >= 5) {
                        if (!!this.props.payperviewRedirect) {
                            this.handlePPVCampaignViewer('', data.item.itemId);
                            this.props.resetPayperviewRedirect();
                        } else {
                            this.handlePPVCampaignViewer('organic', data.item.itemId);
                        }
                    }
                }, 5100);
            } else this.props.history.push('/404');
        } catch (err) {
            // Network error
            this.props.history.push('/404');
        }
    }

    onClickContact = (e) => {
        if (!this.props.loggedInUser) {
            this.props.onShowLoginModal();
            return;
        }
        if (this.props.loggedInUser.userId === this.state.authorId) {
            this.props.showAlertModal(getTranslatedPhrase(this.props.userLanguage, 'This feature is not available!'));
            return;
        }
        let u = {
            userId: this.state.authorId,
            userName: this.state.authorName,
            profileImage: this.state.authorProfileImage,
            slug: this.state.authorSlug
        };
        let m = window.location.href;
        this.props.updateMessagesPageProps(u, getTranslatedPhrase(this.props.userLanguage, 'I am writing in regards to this') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'job posting') + ': ' + m);
        this.props.history.push('/chat');
    }

    render() {
        let theAddress = this.state.address;
        if (!!this.state.unit) {
            theAddress = theAddress + ' #' + this.state.unit;
        }
        theAddress = theAddress + ' ' + this.state.city;
        if (!!this.state.state && this.state.state !== 'n/a') {
            theAddress = theAddress + ' ' + this.state.state;
        }
        theAddress = theAddress + ', ' + this.state.zipCode + ', ' + this.state.country;

        if (!this.state.title) return (<LoadingSvg active={true} />);

        console.log(this.state);

        // <Link to={`/${this.state.websiteSection}/${this.state.category}`}>
        return (

            <div className="listing-body">

                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.title} | Followone</title>
                    <meta name="description" content={this.state.description.substring(0, 200)} />
                    <link rel="canonical" href={"https://followone.com/jobs/" + this.state.category + "/" + this.state.subcategory + "/" + this.state.slug} />
                </Helmet>

                <div className="main-container JobPostingContent">



                    <div className="JobPostingContent__Details-Container">
                        <ListingHeader
                            address={theAddress}
                            websiteSection="jobs"
                            photos={this.state.allPhotos}
                            itemId={this.state.itemId}
                            authorId={this.state.authorId}
                        />
                    </div>


                    <div className="product-main-details" style={{ paddingBottom: 0 }}>
                        <div className="auto-listing-title-map" style={{ paddingBottom: 0 }}>
                            <div className="car-meta-info">
                                <h1>{this.state.title}</h1>
                            </div>

                            <div className="product-main-contact">
                                <div className="ProductDetailInfo">
                                    by <Link className="capitalize" to={'/' + this.state.authorSlug}><strong>{this.state.authorName}</strong></Link>
                                    &nbsp;/
                                    in <strong className="capitalize">{this.state.category.replace(/-/g, ' ')}</strong>
                                </div>


                                <div className="touch-contact">
                                    {(!this.props.loggedInUser || (this.props.loggedInUser && this.props.loggedInUser.userId !== this.state.authorId)) &&
                                        <button data-tip="Chat" onClick={this.onClickContact}><i className="fal fa-comment-lines" /></button>
                                    }

                                    {!this.state.numberToCall ? null :
                                        (<a data-tip="Call" href={`tel:${this.state.numberToCall}`}><i className="fal fa-phone-alt" /></a>)}

                                    {!this.state.sellerEmail ? null : (
                                        <a data-tip="eMail" href={`mailto:${this.state.sellerEmail}`}><i className="fal fa-envelope" /></a>)}

                                    <a target="_blank" data-tip="Get Directions" rel="noopener noreferrer" href={`https://www.google.com/maps/place/${theAddress}/`}><i className="fal fa-map-marker-alt" /></a>

                                    {!this.props.ticketSaleURL ? null : (
                                        <a href={this.props.ticketSaleURL} data-tip="See on Dealer's Website" target="_blank" rel="noopener noreferrer"><i className="fal fa-globe" /></a>)}
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="JobPostingContent__Details">

                        <div className="ServiceContent__Details__Sidebar">

                            <h4>{getTranslatedPhrase(this.props.userLanguage, 'Job Details')}</h4>

                            <table>
                                <tbody>
                                    <tr>
                                        <td>{getTranslatedPhrase(this.props.userLanguage, 'Posted')}</td>
                                        <td>{howRecentIsThis(this.state.creationDate, this.props.userLanguage)}</td>
                                    </tr>

                                    <tr>
                                        <td>{getTranslatedPhrase(this.props.userLanguage, 'Location')}</td>
                                        <td>{titleCase(this.state.city) + ', ' + this.state.state + ' ' + this.state.zipCode}</td>
                                    </tr>

                                    <tr>
                                        <td>{getTranslatedPhrase(this.props.userLanguage, 'Category')}</td>
                                        <td>{getTranslatedPhrase(this.props.userLanguage, titleCase(this.state.category.replace(/-/g, ' ')))}</td>
                                    </tr>

                                    {!!this.state.subcategory && this.state.subcategory !== 'null' && (
                                        <tr>
                                            <td>{getTranslatedPhrase(this.props.userLanguage, 'Subcategory')}</td>
                                            <td>{getTranslatedPhrase(this.props.userLanguage, titleCase(this.state.subcategory.replace(/-/g, ' ')))}</td>
                                        </tr>)}

                                    <tr>
                                        <td>{getTranslatedPhrase(this.props.userLanguage, 'Job Type')}</td>
                                        <td>{getTranslatedPhrase(this.props.userLanguage, titleCase(this.state.jobType.replace(/-/g, ' ')))}</td>
                                    </tr>

                                    <tr>
                                        <td>{getTranslatedPhrase(this.props.userLanguage, 'Experience')}</td>
                                        <td>{getTranslatedPhrase(this.props.userLanguage, titleCase(this.state.experienceLevel.replace(/-/g, ' ')))}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                        <div className="JobPostingContent__Details__Main">

                            <h4>{getTranslatedPhrase(this.props.userLanguage, 'Description')}</h4>

                            <p>{this.state.description.split('\n').map((v, i) => (<Box key={'line-' + i}>{v}<br /></Box>))}</p>


                            {!!this.state.responsibilities.length && (
                                <div className="mgt-md">
                                    <h4>{getTranslatedPhrase(this.props.userLanguage, 'Responsibilities')}:</h4>

                                    <ul>
                                        {this.state.responsibilities.map((v, i) => <li key={i}>{v}</li>)}
                                    </ul>
                                </div>
                            )}

                            {!!this.state.requiredQualifications.length && (
                                <div className="mgt-md">
                                    <h4>{getTranslatedPhrase(this.props.userLanguage, 'Required Qualifications')}:</h4>

                                    <ul>
                                        {this.state.requiredQualifications.map((v, i) => <li key={i}>{v}</li>)}
                                    </ul>
                                </div>
                            )}



                            {!!this.state.preferredQualifications.length && (
                                <div className="mgt-md">
                                    <h4>{getTranslatedPhrase(this.props.userLanguage, 'Preferred Qualifications')}:</h4>

                                    <ul>
                                        {this.state.preferredQualifications.map((v, i) => <li key={i}>{v}</li>)}
                                    </ul>
                                </div>
                            )}




                        </div>

                    </div>



                    <ListingPhotos show={true} photoList={this.state.allPhotos} />


                    <div className="Listing-Mobile-Map">
                        <ListingMap address={theAddress} />
                    </div>

                </div>

                <ListingSidebar
                    section={"jobs"}
                    category={this.state.category}
                    itemSlug={this.state.slug}
                    itemId={this.state.itemId}
                    authorId={this.state.authorId}
                    authorSlug={this.state.authorSlug}
                    authorName={this.state.authorName}
                    authorProfileImage={this.state.authorProfileImage}
                    likeList={this.state.likes}
                    createNewTitle={"New Job"}
                    userIsAuthorized={this.state.userIsAuthorized}
                />
            </div>
        );
    }
}

const mapStateToJobPostingProps = (
    state,
    props
) => {
    return {
        userIsAuthenticated: state.userIsAuthenticated,
        loggedInUser: state.loggedInUser,
        pageRefresh: state.pageRefresh,
        userLanguage: state.userLanguage,
        payperviewRedirect: state.payperviewRedirect
    };
};

const mapDispatchToJobPostingProps = (
    dispatch
) => {
    return {
        resetPayperviewRedirect: () => dispatch({ type: actionType.PAYPERVIEW_REDIRECT, payperviewRedirect: false }),
        updateMessagesPageProps: (u, m) => dispatch({ type: actionType.MESSAGES_PAGE, messagesDefaultUser: u, messagesDefaultMessage: m }),
        onShowLoginModal: () => dispatch({ type: actionType.LOGIN_MODAL, accountLoginModalActive: true, returnUrl: window.location.pathname }),
        showAlertModal: (m) => dispatch({ type: actionType.ALERT_MODAL, alertModalActive: true, alertModalMessage: m })
    };
};

export default connect(mapStateToJobPostingProps, mapDispatchToJobPostingProps)(withRouter(JobPosting));