import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BrandLogo extends Component {
    render () {
        return (
            <div className="main-sidebar_box main-sidebar_box_brand-logo">
                {this.props.BrandLogoImg}
                {!this.props.BrandURL?(
                <span>{this.props.BrandLogoName}</span>):
                (<Link to={this.props.BrandURL}><span>{this.props.BrandLogoName}</span></Link>)}
            </div>
        );
    }
}

export default BrandLogo;