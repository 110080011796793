import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { Helmet } from "react-helmet";

import FeaturedAdsImage from '../../../assets/static/featured-ads.jpg'
import SponsoredAdsImage from '../../../assets/static/sponsored-ads.jpg'
import GppvAdsImage from '../../../assets/static/pay-per-view-ads.jpg'

const Advertise = props => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const userLanguage = useSelector(state => state.userLanguage);

    return (
        <div className="main-100 advertise">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Advertise | Followone</title>
                <meta name="description" content="Promote your brand with ease. Find the placement that makes your business successful." />
                <link rel="canonical" href="https://followone.com/advertise" />
            </Helmet>

            <div className="main-100-header">
                <h1>{getTranslatedPhrase(userLanguage, 'Advertise')}</h1>
                <p>{getTranslatedPhrase(userLanguage, 'Promote your brand with ease. Find the placement that makes your business successful.')}</p>
            </div>

            <div className="static-page-content advertising-page">
                <div className="advertise-section advertise-section_featured">
                    <div className="advertise-section-header">
                        <h2>FEATURED ADS</h2>
                        <p>Featured ads allow customers to get visibility above the fold on each category page, including main categories such as: Store, Auto, Real Estate, Travel, Jobs, Services.</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Options:</td>
                                    <td>Geo Location, Start Date & End Date.</td>
                                </tr>
                                <tr>
                                    <td>Spots:</td>
                                    <td>6 / Category</td>
                                </tr>
                            </tbody>
                        </table>

                        <p className="note">Note: Featured Ads doesn’t guarantee you clicks / views, it only gets you exposure to our users based on the location of your choice.</p>
                    </div>

                    <img src={FeaturedAdsImage} alt="Featured Ads Image" style={{ paddingTop: '2rem' }} />
                </div>

                <div className="advertise-section advertise-section_sponsored">
                    <div className="advertise-section-header">
                        <h2>SPONSORED ADS</h2>
                        <p>Sponsored ads allow customers to get visibility while the user is scrolling through the category pages. Located in the sidebar of our website thay can be placed on every category page.</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Options:</td>
                                    <td>Geo Location, Start Date & End Date.</td>
                                </tr>
                                <tr>
                                    <td>Spots:</td>
                                    <td>2 / Category</td>
                                </tr>
                            </tbody>
                        </table>

                        <p className="note">Note: Sponsored Ads doesn’t guarantee you clicks / views, it only gets you exposure to our users based on the location of your choice.</p>
                    </div>

                    <img src={SponsoredAdsImage} alt="Sponsored Ads image" />
                </div>

                <div className="advertise-section advertise-section_gppv">
                    <div className="advertise-section-header">
                        <h2>GUARANTEED PAY PER VIEW ADS</h2>
                        <p>Taking advantage of our proprietary technology, we can guarantee unqiue views for each ad that runs using Followone.com. </p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Options:</td>
                                    <td>Geo Location, Gender, Age, Budget, Start Date & End Date.</td>
                                </tr>
                                <tr>
                                    <td>Spots:</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>Cost:</td>
                                    <td>$1 / View </td>
                                </tr>
                            </tbody>
                        </table>

                        <p className="note">Note: Each view is guaranteed, you won’t be charge unless your ad has been seen.</p>
                    </div>

                    <img src={GppvAdsImage} alt="Guaranteed Pay Per View Ads" />
                </div>

                <div className="advertise-pricing">
                    <h2 className="mgb-lg">PRICING</h2>
                    <table>
                        <thead>
                            <tr>
                                <td>Featured Ad Placement</td>
                                <td className="th-featured-bkg">Main Category</td>
                                <td className="th-featured-bkg">Secondary Category</td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Spot 1</td>
                                <td>$200 <span>/ Day</span></td>
                                <td>$160 <span>/ Day</span></td>
                            </tr>
                            <tr>
                                <td>Spot 2</td>
                                <td>$180 <span>/ Day</span></td>
                                <td>$140 <span>/ Day</span></td>
                            </tr>
                            <tr>
                                <td>Spot 3</td>
                                <td>$160 <span>/ Day</span></td>
                                <td>$120 <span>/ Day</span></td>
                            </tr>
                            <tr>
                                <td>Spot 4</td>
                                <td>$100 <span>/ Day</span></td>
                                <td>$80 <span>/ Day</span></td>
                            </tr>
                            <tr>
                                <td>Spot 5</td>
                                <td>$80 <span>/ Day</span></td>
                                <td>$60 <span>/ Day</span></td>
                            </tr>
                            <tr>
                                <td>Spot 6</td>
                                <td>$60 <span>/ Day</span></td>
                                <td>$40 <span>/ Day</span></td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <thead>
                            <tr>
                                <td>Sponsored Ad Placement</td>
                                <td className="th-sponsored-bkg">Price/Day</td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Main Category</td>
                                <td>$60</td>
                            </tr>
                            <tr>
                                <td>Secondary Category</td>
                                <td>$40</td>
                            </tr>
                        </tbody>
                    </table>

                    <p><strong>Main categories</strong>: Store, Auto, Food, Real Estate, Jobs, Services, Travel.</p>

                    <p><strong>Secondary categories</strong>: are categories inside a main category, for example in Store we have secondary categories: Electronics, Sport & Fitness, Smart Home, etc.</p>

                    <p>There are 6 featured spots on each category page. Depending on the screen size there can be 2, 3 or 4 visbile at once, remaning are visible on scroll. On mobile devices one featured spot is displayed at once, the next appearing after 6 seconds. User can navigate through them manually as well. </p>
                </div>
            </div>
        </div>
    );

}

export default Advertise;
