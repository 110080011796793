import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import HomeSlider from "./HomeSlider";
// import moment from 'moment';

const HomeHeader = (props) => {
    if (!props.loggedInUser) return null;

    const user = props.loggedInUser;

    // if (isDealer) {
    //     const trialStartDate = moment(user.trialActiveDate);
    //     const trialEndDate = moment(user.trialActiveDate).add(7, 'days')
    // }

    // console.log('trial:', trialStartDate, trialEndDate);

    return (
        <div className="home-box">
            <div className="logged-in-header">
                <div className="home-welcome">
                    <Link to={`/${props.loggedInUser.slug}`}><img src={props.loggedInUser.profileImage} alt={props.loggedInUser.profileImage} /></Link>
                    <div>
                        <h1> {getTranslatedPhrase(props.userLanguage, `Hi`)} {props.loggedInUser.firstName}!</h1>
                        <p className="mainCta">{getTranslatedPhrase(props.userLanguage, 'What would you like to do today?')}</p>
                    </div>
                </div>

                <HomeSlider />
            </div>

            { (user.isDealer && (!user.defaultPasswordChanged || !user.defaultUserChanged || user.subscription === 'free')) &&
                <div className="dealer-welcome">
                    <h4>Welcome to your Dealer Account</h4>
                    {/* <p className="tag">Before you get started please <strong>update your password first</strong>, than change the default email address with your email address and activate your Membership plan.</p> */}

                    <p className="tag">To <strong>get started</strong> follow the steps bellow:</p>
                    <ul>
                        <li className={user.defaultPasswordChanged ? 'done' : ''}>
                            {!user.defaultPasswordChanged ? <i className="fa-solid fa-1"></i> : <i className="fa-light fa-circle-check"></i> }
                            <Link to="/account-settings">Update Your Password</Link>
                        </li>

                        <li className={user.defaultUserChanged ? 'done' : ''}>
                            {!user.defaultUserChanged ? <i className="fa-solid fa-2"></i> : <i className="fa-light fa-circle-check"></i> }
                            <Link to="/account-settings">Update Your Login Email Address</Link>
                        </li>

                        <li className={user.subscription === 'free' ? '' : 'done'}>
                            {user.subscription === 'free' ? <i className="fa-solid fa-3"></i> : <i className="fa-light fa-circle-check"></i> }
                            <Link to="/subscription-settings">Activate Your Dealer Membership</Link>
                        </li>
                    </ul>
                </div>
            }
        </div>
    )
}

const mapStateToPersonalHeaderProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
        loggedInUser: state.loggedInUser
    };
};

export default connect(mapStateToPersonalHeaderProps, null)(HomeHeader);