import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import { Helmet } from "react-helmet";


const Help = (props) => {
    const loggedInUser = useSelector(state => state.loggedInUser);
    const userLanguage = useSelector(state => state.userLanguage);
    let user;

    if (!loggedInUser)
        user = {
            accountType: 'personal',
            affiliateId: ''
        };
    else user = loggedInUser;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="main-100 help">

            <Helmet>
                <meta charSet="utf-8" />
                <title>FAQ | Followone</title>
                <meta name="description" content="We are here for you. If you can't find what you are looking for, contact us." />
                <link rel="canonical" href="https://followone.com/help" />
            </Helmet>

            <div className="main-100-header">
                <h1>{getTranslatedPhrase(userLanguage, 'FAQ')}</h1>
                <p>{getTranslatedPhrase(userLanguage, 'We are here for you.')}<br /> {getTranslatedPhrase(userLanguage, 'If you can\'t find what you are looking for, contact us.')}</p>
            </div>

            <div className="faq-container">
                <div className="faq-nav">
                    <ul>
                        <li className="faq-li-header"><h4>Account</h4></li>
                        <li className="faq-li-item"><a href="#setup">Account Set-Up</a></li>
                        <li className="faq-li-item"><a href="#subscriptions">Subscriptions</a></li>
                        <li className="faq-li-item"><a href="#payments">Payments</a></li>
                        <li className="faq-li-spacer">&nbsp;</li>
                        <li className="faq-li-header"><h4>Affiliates</h4></li>
                        <li className="faq-li-item"><a href="#reselleraffiliate">Reseller Affiliate</a></li>
                        {(user.accountType === 'personal' && user.affiliateId) && (
                            <li className="faq-li-item"><a href="#cashinaffiliate">Cashin</a></li>)}
                    </ul>
                </div>

                <div className="faq-content">

                    <h2>ACCOUNT</h2>

                    <div id="setup">&nbsp;</div>
                    <div className="faq-content-section">
                        <h3>Account Set-Up</h3>

                        <div className="the-faq">
                            <h4>How to create an account?'</h4>
                            <p>You can create an account by using the <strong>Sign Up</strong> button from the navigation bar at the top of the screen.</p>
                        </div>

                        <div className="the-faq">
                            <h4>Why do I need an account?</h4>
                            <p>You need an account to be able to post on the site and use its various services. An account also allows you to contact sellers in order to purchase items or learn more about listings.</p>
                        </div>

                        <div className="the-faq">
                            <h4>How do I delete my account?</h4>
                            <p>To delete an account, log into your account with your username and password, go into <strong>Account Settings</strong> and click on the <strong>Delete Account</strong> tab. Here, you have two options: <strong>I'll Take a Break</strong> and <strong>Delete My Account</strong>.</p>

                            <p><strong>I'll Take a Brake</strong> option hides your account from other viewers. It keeps all your listings and activity as it is. Once you are ready to return, simply log in with your username and password and everything will be back again.</p>

                            <p><strong>Delete My Account</strong> option removes everything from our servers. If you return, you will need to setup a new account.</p>
                        </div>

                        <a className="faq-arrow-up" href="#root" title="Go Up"><i className="fal fa-chevron-up" /></a>
                    </div>



                    <div id="subscriptions">&nbsp;</div>
                    <div className="faq-content-section">

                        <h3>Subscriptions</h3>

                        <div className="the-faq">
                            <h4>How do I change my subscription?</h4>
                            <p>To change your subscription, log into your account, go to the <strong>Settings</strong> page and click on the <strong>Subscription</strong> tab. Select the plan that's right for you.</p>
                        </div>

                        <div className="the-faq">
                            <h4>Which plan is right for me?</h4>
                            <p><strong>Social Membership</strong> is great for users who like to browse our store, products, and services. With a social membership a user can post unlimited posts in social which includes: blogs, photos, videos, events and groups. With this membership you can also post 2 listings at a time in Store, Auto, Foods, Travel, Jobs, Services, and 1 listing in Real Estate.</p>

                            {/*<p><strong>Basic Membership</strong> is designed for users and small businesses, with less than 20 products. With a basic membership you can post up to: 20 store listings, 5 auto listings, 5 job listings, 5 service listings, 20 food listings, 3 real estate listings and 3 travel listings.</p>*/}

                            <p><strong>Premium Membership</strong> is designed for small to medium businesses as well as users, with less than 250 products. With a Premium Membership you can post: 250 store listings, 100 auto listings, 60 job listings, 35 service listings, 100 food listings, 35 real estate listings, and 60 travel listings.</p>

                            <p><strong>Enterprise Membership</strong> is designed for large businesses. We work closely together to create a custom plan that works best for your needs.</p>
                        </div>

                        <a className="faq-arrow-up" href="#root" title="Go Up"><i className="fal fa-chevron-up" /></a>

                    </div>



                    <div id="payments">&nbsp;</div>
                    <div className="faq-content-section">

                        <h3>Payments</h3>

                        <div className="the-faq">
                            <h4>How to add a Credit Card?</h4>
                            <p>Log into your account and go to <strong>Settings</strong>, click on the <strong>Account Settings</strong> tab and then click on the <strong>Add New Card</strong> button.</p>
                        </div>

                        <div className="the-faq">
                            <h4>How to remove a Credit Card?</h4>
                            <p>Log into your account and go to <strong>Settings</strong>, click on the <strong>Account Settings</strong> tab and then click on the trash icon: <i className="fal fa-trash-alt" />.</p>
                        </div>

                        <a className="faq-arrow-up" href="#root" title="Go Up"><i className="fal fa-chevron-up" /></a>
                    </div>



                    <h2>AFFILIATES</h2>

                    <div id="reselleraffiliate">&nbsp;</div>
                    <div className="faq-content-section">

                        <h3>Reseller Affiliate</h3>

                        <div className="the-faq">
                            <h4>How do I join the Reseller Affiliate Program?</h4>
                            <p>Log into your account and click on the <strong>Affiliate</strong> link in the footer. In the Reseller Affiliate Program box click on the <strong>Join Now</strong> button.</p>
                        </div>

                        <div className="the-faq">
                            <h4>Why do I need to upload my ID or Driver License?</h4>
                            <p>We use your ID or Driver License to verify your age and identity. In order to be part of the Reseller Affiliate Program you have to be at least 18 years old.</p>
                        </div>

                        <div className="the-faq">
                            <h4>How do I get paid?</h4>
                            <p>With our affiliate program you have two options: PayPal or Check. You can request a payout as soon as you reach $1000 in sales.</p>
                        </div>

                        <div className="the-faq">
                            <h4>How does the Reseller Affiliate Program works?</h4>
                            <p>Once you sign up, you will be redirected to the Reseller Affiliate Dashboard page. There you can see your personal URL, earnings, current level, invoices, accounts you signed up for, and how to opt out of the program.</p>

                            <p><strong>1.</strong> When you sign up a business (basic or premium membership) you have to give them your personal URL. We use this personal URL to associate the sale to your account.</p>

                            <p><strong>2.</strong> Once you reach at least $1000 in sales you can request a payout using your selected payment method.</p>
                        </div>

                        <a className="faq-arrow-up" href="#root" title="Go Up"><i className="fal fa-chevron-up" /></a>

                    </div>



                    <div id="cashinaffiliate">&nbsp;</div>
                    {(user.accountType === 'personal' && user.affiliateId) && (
                        <div className="faq-content-section">

                            <h3>Cashin</h3>

                            <div className="the-faq">
                                <h4>How does the Cashin Program works?</h4>
                                <p>Once you sign up you are redirected to the Cashin page where you can see your ads. You get paid $0.10 per each ad view. Once you reach $50 you can transfer your cash to PayPal.</p>
                            </div>

                            <div className="the-faq">
                                <h4>What is an ad view?</h4>
                                <p>In order to get paid $0.10 you have to click on an add and look at it for at least 6 seconds.</p>
                            </div>

                            <div className="the-faq">
                                <h4>How many times can I see an ad?</h4>
                                <p>You can see ads as many times as you'd like, but you will only get paid for first view.</p>
                            </div>

                            <div className="the-faq">
                                <h4>Do I have to buy anything?</h4>
                                <p>No, you don't have  to buy anything to be part of the Cashin Affiliate Program.</p>
                            </div>

                            <div className="the-faq">
                                <h4>How do I get paid?</h4>
                                <p>Go to the Cashin page from the main navigation and once you have at least $50 you can click the Cashin button.</p>
                            </div>

                            <a className="faq-arrow-up" href="#root" title="Go Up"><i className="fal fa-chevron-up" /></a>
                        </div>)}
                </div>
            </div>
        </div>
    );
}



export default Help;