import React, { Component } from 'react';
import { hostNameAndPort } from '../../../../../shared/util/vars';
import Axios from 'axios';
import Box from '../../../../../shared/util/box';
import {connect} from 'react-redux';
import { splitBigLine } from '../../../../../shared/util/string';


class WallText extends Component {
    state = {
        textNotFound: true,
        textContent: ''
    }

    componentDidMount() {
        let b = this.props.textId;
        if (!b) {
            this.setState({ textNotFound: true });
            return;
        }
        let url = `${hostNameAndPort}/api/texts/${b}`;
        Axios(url)
        .then(result => {
            let {data} = result;
            if (!data.error) {                
                data.item.textNotFound = false;
                this.setState(data.item);
                let _wallAction;
                switch (this.props.activityType) {
                    case 'comment':
                    _wallAction = 'commented on a status';
                    break;
                    case 'like':
                    _wallAction = 'liked a status';
                    break;
                    case 'share':
                    _wallAction = 'shared a status';
                    break;
                    case 'post':
                    _wallAction = 'said';
                    break;
                    default:
                    break;
                }

                if ( (data.item.groupId && this.props.isGroupWall) || 
                    (!data.item.groupId && !this.props.isGroupWall) ) {
                    this.props.onItemLoaded({
                        itemFailedLoading: false,
                        wallAction: _wallAction,
                    });
                } else { 
                    this.props.onItemLoaded({ itemFailedLoading: true });
                } 
            }
            else {
                this.props.onItemLoaded({ itemFailedLoading: true });
            }
        })
        .catch(err => {
            //console.log('Network error');
            this.props.onItemLoaded({ itemFailedLoading: true });
        });
    }

    render () {
        if (this.state.textNotFound) {
            return '';
        }
        
        return (
            <div className="WallText">
                {this.state.textContent.split(/\n/g)
                .map( (vi,i) => (<Box key={'line-'+i}> 
                                {vi.split(' ')
                                .map( (v,i) => (<Box key={i}>{splitBigLine(v,30)
                                            .map( (v2,i) => (<Box key={i}>{v2}{v2.length>=30?<br />:null}</Box>) )}
                                            {' '}
                                            </Box>)
                                        )}
                                <br />
                            </Box>)
                    )}
                {/* {(this.state.textContent.length > 5000)?'...':''} */}
            </div>
        );
    }
}



const mapStateToWallTextProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToWallTextProps, null)(WallText);
