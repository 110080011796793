import React from 'react';
import Logo from './Logo/Logo';
import Helmet from 'react-helmet';

import Navigation from './Navigation/Navigation';
// import SearchBar from './SearchBar/SearchBar';
import SignedInHeaderTools from './HeaderTools/SignedInHeaderTools/SignedInHeaderTools';
import SignedOutHeaderTools from './HeaderTools/SignedOutHeaderTools/SignedOutHeaderTools';
import AccountTypeModal from '../Modals/Account/AccountTypeModal';
import AdvertiseModal from '../Modals/Advertise/AdvertiseModal';
import AccountInformationModal from '../Modals/Account/AccountInformationModal';
import WelcomeModal from '../Modals/Account/WelcomeModal';
import AccountLoginModal from '../Modals/Account/AccountLoginModal';
import MobileMenu from './Navigation/MobileMenu';
import ConfirmDeleteModal from '../Modals/Account/ConfirmDeleteModal';
import AlertModal from '../Modals/Account/AlertModal';
import WebsiteLocation from "./Location/Location";

function Header(props) {
    return (
        <>
            <Helmet>
                <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="6b35725d-976e-4dee-b8e2-4c0a7631ea41" data-blockingmode="auto" type="text/javascript"></script>
            </Helmet>

            <div id="BackToTop" />

            <a href="#BackToTop" className="back-top-top"><i className="fal fa-chevron-circle-up" /></a>

            <header className="mgb-sm master-header">
                <MobileMenu />
                <Logo />
                <Navigation />
                <WebsiteLocation />
                <SignedInHeaderTools />
                <SignedOutHeaderTools />
            </header>
            {/* <SearchBar /> */}

            <AccountLoginModal />
            <AccountTypeModal />
            <AccountInformationModal />
            <ConfirmDeleteModal />
            <AlertModal />
            <AdvertiseModal />

            <WelcomeModal />
        </>
    );
}


export default Header;