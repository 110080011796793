import React from 'react';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import EventPreview from './EventPreview';
import EventsSideBar from './EventsSideBar';
import EventsSideBarMobile from './EventsSideBarMobile';
import EventsFeatured from './EventsFeatured';

function EventsCategory() {
  return (
    <CategoryPage
      section="events"
      description="Organize, Invite, or Celebrate Something"
      ItemPreview={EventPreview}
      SideBar={EventsSideBar}
      SideBarMobile={EventsSideBarMobile}
      FeaturedItems={EventsFeatured}
    />);
}

export default EventsCategory;