import React from "react";
import ListingPage from "../../../../shared/templates/ListingTemplate/listing-edit";
import TravelEditFields from "./TravelEditFields";
import moment from "moment";

function TravelEdit(props) {
  function checkReqs(item) {
    let m = "";

    if (!item.title) {
      m = "Travel package name is required.";
    }
    if (!item.brandName) {
      m = "Brand name is required.";
    }
    if (!item.callForPricing && !item.price) {
      m = "Price is required.";
    }
    if (!item.callForPricing && String(item.price).indexOf('.') >= 0 && String(item.price).length > 2 && String(item.price).indexOf('.') < String(item.price).length - 2) {
      m = 'Warning! Price cannot include comma(,). Also the dot(.) means decimals follow.'
    }
    if (!item.startDate) {
      m = "Start date is required.";
    }
    if (!item.endDate) {
      m = "End date is required.";
    }
    if (!item.pickUp) {
      m = "Ticket picking is required.";
    }
    if (item.addDiscount === "how-about-discount") {
      m = "Does your listing have a discount?";
    }
    if (item.addDiscount === "add-discount") {

      if (moment(item.temp.startDate).isAfter(moment(item.temp.endDate))) {
        m = "Discount end date should be after the start date."
      }

      if (moment(item.temp.endDate).isBefore(moment().subtract(1, 'days'))) {
        m = "Discount end date can't be a past date."
      }

      if (moment(item.temp.startDate).year() > 3000 || moment(item.temp.endDate).year() > 3000) {
        m = "Please enter a valid year."
      }

      if (!item.temp.discountType || item.temp.discountType === 'discount-type' ||
        (item.temp.discountType === 'discount-deal' && (!Number(item.temp.discountValue) || !item.temp.availability || item.temp.availability === 'set-availability' || ((!item.temp.startDate || !item.temp.endDate) && item.temp.availability !== 'no-expiration-date'))) ||
        (item.temp.discountType === 'discount-coupon' && (!item.temp.selectWhichCoupon || item.temp.selectWhichCoupon === 'select-coupon')))
        m = 'You need to complete the discount information';
    }
    if (
      item.pickUp !== "sell-online" &&
      item.newAddress !== "same-as-business-address"
    ) {
      // if (!item.address) {
      //   m = "Address is required.";
      // }
      if (!item.city) {
        m = "Please add your city.";
      }
      if (!item.state || item.state === "choose-state") {
        m = "State is required.";
      }
      if (!item.country || item.country === "choose-country" || item.country === '-1') {
        m = "Please select your country.";
      }
      if (!item.zipCode) {
        m = "Zip Code is required.";
      }
    }
    if (!item.description.trim()) {
      m = "Description is required.";
    }
    if (moment(item.endDate).isBefore(moment(item.startDate))) {
      m = "End date needs to be after start date.";
    }
    if ((!item.callForPricing && !Number(item.price)) || item.price < 0) {
      m = "Price is not valid.";
    }
    if (!item.category || item.category === "select-category") {
      m = "Select a valid category.";
    }
    // if (item.subcategory === "select-subcategory") {
    //   m = "Select a valid subcategory.";
    // }
    if (!item.featuredImage) {
      m = "You need to choose the main photo.";
    }

    return m;
  }

  return (
    <ListingPage
      section="travel"
      editListingUrl="/edit-travel"
      itemType="Travel Package"
      mainSubtitle="You're one step closer to success."
      checkRequirements={checkReqs}
      ItemFields={TravelEditFields}
    />
  );
}

export default TravelEdit;
