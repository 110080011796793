import React, { Component } from 'react'
import FormFieldError from '../../../shared/uielements/ErrorMessages/FormFieldError'
import ErrorMessages from '../../../shared/uielements/ErrorMessages/ErrorMessages'
import { Link } from 'react-router-dom';
import PhotoPreviewArea from '../../../shared/templates/ListingTemplate/PhotoPreviewArea';
import { hostNameAndPort } from '../../../shared/util/vars'
import { maxUploadFileSize } from '../../../shared/util/file';
import { titleCase } from '../../../shared/util/string';
import Axios from 'axios';
import { connect } from 'react-redux';
import { getTranslatedPhrase } from '../../../shared/util/lang';
import LoadingSvg from '../../../shared/uielements/LoadingSpinner/LoadingSvg';
import Helmet from 'react-helmet';

class EditPhoto extends Component {
    constructor(props) {
        super(props)
        this.initialState = this.state = {
            title: '',
            slug: '',
            itemId: '',
            featuredImage: '',
            category: '',
            subcategory: '',
            subcategoriesSelect: [],
            privacy: 'public',
            hashTags: '',
            errorMessage: '',
            errorMessageUploader: '',
            categoriesSelect: [],
            newPhotosFiles: [],
            description: '',
            photoGallerySaved: false,
            postListingPressed: false,
            mainButtonLabel: 'Publish Gallery'
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getPhotoCategories();
        let l = this.props.location;
        if (l.pathname.indexOf('/edit-photo') >= 0 && l.search) {
            let b = l.search.slice(3);
            this.setState({ slug: b });
            this.props.history.replace(this.props.match.url);
            this.getPhotoData(b);
        }
    }

    getPhotoCategories = async () => {
        try {
            let url = `${hostNameAndPort}/api/photos/categories`;
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                this.setState({ categoriesSelect: data.categories })
            }
        } catch (err) {
            // server, network error
        }
    }

    getPhotoData = async (b) => {
        try {
            let url = `${hostNameAndPort}/api/photos/${b}`;
            let result = await Axios(url);
            let { data } = result;
            if (!data.error) {
                data.item.mainButtonLabel = 'Update Gallery';
                data.item.newPhotosFiles = [];
                data.item.newPhotosFiles.push({
                    file: null,
                    imageName: data.item.featuredImage,
                    imagePreviewUrl: data.item.featuredImage,
                    className: 'selectedPhoto'
                });

                for (let i in data.item.extraPhotos) {
                    data.item.newPhotosFiles.push({
                        file: null,
                        imageName: data.item.extraPhotos[i].photoName,
                        imagePreviewUrl: data.item.extraPhotos[i].photoName,
                        className: ''
                    });
                }
                //delete data.item.featuredImage;
                delete data.item.extraPhotos;

                // Subcategories
                let a = this.state.categoriesSelect;
                let b = [];
                let i = 0;
                for (; i < a.length; i++) {
                    if (a[i] === data.item.category) break;
                }
                i++;
                while (/^\s*-+/.test(a[i]) && i < a.length) {
                    b.push(a[i].replace(/^\s*-+\s*/, ''));
                    i++;
                }
                data.item.subcategoriesSelect = [...b];
                this.setState(data.item);
            }
        } catch (err) {
            // server, network error
        }
    }

    onClickOnPhotoHandler = (pos, action) => {
        let a = this.state.newPhotosFiles;
        if (action === 'select') {
            // Choose main photo
            for (let i in a) {
                if (a[i].className === 'selectedPhoto') {
                    a[i].className = '';
                }
            }
            if (pos >= 0 && pos < a.length) {
                a[pos].className = 'selectedPhoto';
            }
            this.setState({
                newPhotosFiles: a,
                photoGallerySaved: false,
                errorMessage: ''
            });
        }
        else {
            if (action === 'remove') {
                // Remove photo
                a.splice(pos, 1);
                this.setState({
                    newPhotosFiles: a,
                    photoGallerySaved: false,
                    errorMessage: ''
                });
            }
        }
    }

    itsTimeToSetNewPhotosFiles = (newPhotos) => {
        let a = this.state.newPhotosFiles.concat(newPhotos);
        let notSelected = true;
        for (let i in a) {
            if (a[i].className === 'selectedPhoto') {
                notSelected = false;
                break;
            }
        }
        if (notSelected) a[0].className = 'selectedPhoto';
        this.setState({
            newPhotosFiles: a,
            photoGallerySaved: false,
            errorMessage: ''
        });
    }

    onChangeUploadedImagesHandler = (e, realThis) => {
        function photoLoader(n) {
            let k = 0;
            let loadedPhotos = Array(n);
            return function (ctx, files, i, reader) {
                loadedPhotos[i] = {
                    file: files[i],
                    imagePreviewUrl: reader.result,
                    imageName: files[i].name,
                    className: ''
                };
                k++;
                if (k === n) {
                    ctx.itsTimeToSetNewPhotosFiles(loadedPhotos);
                }
            }
        }

        let _error = false;
        let epf = e.target.files;
        let o = Object.keys(epf);
        if (o.length > 20) {
            realThis.setState({ errorMessageUploader: getTranslatedPhrase(realThis.props.userLanguage, 'You selected more than 20 photos')+'!' });
            return;
        } else realThis.setState({ errorMessageUploader: '' });
        for (let i of o) {
            if (epf[i].size > maxUploadFileSize) {
                realThis.setState({ errorMessageUploader: getTranslatedPhrase(realThis.props.userLanguage, 'Each photo must be smaller than 5 MB')+'!' });
                _error = true;
                break;
            }
        }
        if (!_error) {
            let n = o.length;
            let onRegisterPhoto = photoLoader(n);
            for (let i of o) {
                let reader = new FileReader();
                let ctx = this;
                reader.onloadend = function () {
                    onRegisterPhoto(ctx, epf, i, this);
                };
                reader.readAsDataURL(epf[i]);
            }
        }
    }

    onChangePropertyHandler = (e, p, l, d) => {
        if (e.target.value.length > l) {
            return this.setState({ errorMessage: `The ${d} must be smaller than ${l} characters` });
        }
        if (p === 'category' && e.target.value !== 'select-category') {
            let a = this.state.categoriesSelect;
            let b = [];
            let i = 0;
            for (; i < a.length; i++) {
                if (a[i] === e.target.value) break;
            }
            i++;
            while (/^\s*-+/.test(a[i]) && i < a.length) {
                b.push(a[i].replace(/^\s*-+\s*/, ''));
                i++;
            }
            this.setState({ subcategoriesSelect: [...b] })
            this.onChangePropertyHandler({ target: { value: '' } }, 'subcategory', 50, 'Subcategory');
        }
        this.setState({ [p]: e.target.value, photoGallerySaved: false, errorMessage: '' });
    }

    isFeaturedImageSet = () => {
        // if (!!this.state.featuredImage) {
        //     return true;
        // }

        let a = this.state.newPhotosFiles;
        for (let i in a) {
            if (a[i].className === 'selectedPhoto') {
                return true;
            }
        }
        return false;
    }

    requiredFieldsAreFilled() {
        let res = true, _ = this.state;
        let _err = '';

        if (!_.title) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Title is required.');
            res = false;
        }
        if (!_.description) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Description is required.');
            res = false;
        }
        if (!_.category || _.category === 'select-category') {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Select a valid category.');
            res = false;
        }
        if (!_.newPhotosFiles.length) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'You need to add at least one photo');
            res = false;
        }
        if (_.photoGallerySaved && res) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'Gallery is already saved');
            res = false;
        }
        if (res && !this.isFeaturedImageSet()) {
            _err = getTranslatedPhrase(this.props.userLanguage, 'You need to choose the main photo.');
            res = false;
        }
        this.setState({ errorMessage: _err });
        return res;
    }

    onClearDraftHandler = () => {
        window.scrollTo(0, 0);
        let c = this.state.categoriesSelect;
        this.setState(this.initialState);
        this.setState({ categoriesSelect: c });
    }

    onPublishHandler = async () => {
        if (!this.requiredFieldsAreFilled()) {
            return;
        }
        this.setState({ postListingPressed: true });

        let nbd = Object.assign({}, this.state);
        nbd.title = titleCase(nbd.title);
        delete nbd.categoriesSelect;
        delete nbd.errorMessage;
        delete nbd.photoGallerySaved;
        delete nbd.mainTitle;

        nbd.extraPhotosFiles = [];
        nbd.extraPhotos = [];

        for (let i in nbd.newPhotosFiles) {
            if (nbd.newPhotosFiles[i].className === 'selectedPhoto') {
                // This is the featured image
                nbd.featuredImage = nbd.newPhotosFiles[i].imageName;
                nbd.featuredImageFile = nbd.newPhotosFiles[i].file;
            }
            else {
                if (!nbd.newPhotosFiles[i].file) {
                    nbd.extraPhotos.push(nbd.newPhotosFiles[i].imageName);
                }
                else {
                    nbd.extraPhotosFiles.push(nbd.newPhotosFiles[i].file);
                }
            }
        }

        delete nbd.newPhotosFiles;

        let newPhotoData = new FormData();
        for (let i in nbd) {
            newPhotoData.append(i, nbd[i]);
        }

        newPhotoData.delete('extraPhotosFiles');

        for (let i in nbd.extraPhotosFiles) {
            newPhotoData.append('extraPhotoFile' + i, nbd.extraPhotosFiles[i]);
        }

        let options = {
            url: `${hostNameAndPort}/api/photos/update`,
            method: 'post',
            data: newPhotoData
        };
        try {
            let result = await Axios(options);
            let { data } = result;
            if (!data.error) {
                this.setState({
                    itemId: data.itemId,
                    slug: data.slug,
                    mainButtonLabel: 'Update Gallery',
                    photoGallerySaved: true,
                    errorMessage: '',
                    postListingPressed: false
                });
            }
            else {
                this.setState({ errorMessage: data.error, photoGallerySaved: false, postListingPressed: false });
            }
        } catch (err) {
            //console.log(err)
            this.setState({ errorMessage: getTranslatedPhrase(this.props.userLanguage, 'Network error ERRFEPHOGED01'), photoGallerySaved: false, postListingPressed: false });
        }
    }

    // (<Link target="_blank" to={`/photos/${this.state.category}/${this.state.slug}`}>See preview</Link>)
    render() {
        let categoriesSelect = this.state.categoriesSelect.filter(v => (!/^\s*-+/.test(v))).map(v => ({ value: v, text: getTranslatedPhrase(this.props.userLanguage,titleCase(v.replace(/-/g,' '))) })).sort((a,b) => ((a.text>b.text)?1:-1));
        let backgroundClass = `layout-background-new layout-background-new-addphotos`;

        return (



            <>

        <Helmet>
                <meta charSet="utf-8" />
                <title>{getTranslatedPhrase(this.props.userLanguage, 'Add Photos')} | Followone</title>
                <meta name="description" content="Sell products, cars, properties & travel packages, post jobs & services, find deals & coupons and connect with customers, friends & family." />
                <link rel="canonical" href={"https://followone.com/"} />
        </Helmet>
                <div className={backgroundClass} />

                <div className="main-100 add-new-page add-photos">

                    <div className="main-100-header">
                        <h1>{getTranslatedPhrase(this.props.userLanguage, 'Add Photos')}</h1>
                        <p>{getTranslatedPhrase(this.props.userLanguage, 'Create a new gallery, or add photos to an existing gallery(s).')}</p>
                    </div>


                    <div className="add-listing add-listing_full-width">

                        <div className="row">
                            <div className="col span-2-of-4">
                                <label>{getTranslatedPhrase(this.props.userLanguage, 'Gallery Name')} *</label>
                                <input type="text" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Gallery Name')} value={this.state.title} onChange={(e) => this.onChangePropertyHandler(e, 'title', 50, 'Gallery Name')} />
                            </div>

                            <div className="col span-1-of-4">
                                <label>{getTranslatedPhrase(this.props.userLanguage, 'Category')} *</label>
                                <div className="form-input-select">
                                    <select value={this.state.category} onChange={(e) => this.onChangePropertyHandler(e, 'category', 50, 'category')}>
                                        <option value={'select-category'} key={'select-category'}>{getTranslatedPhrase(this.props.userLanguage, 'Select Category')}</option>
                                        {categoriesSelect.map((v, i) => (<option value={v.value} key={i}>{v.text}</option>))}
                                    </select>
                                </div>
                            </div>

                            {!this.state.subcategoriesSelect || !this.state.subcategoriesSelect.length ? null : (
                                <div className="col span-1-of-4">
                                    <label>{getTranslatedPhrase(this.props.userLanguage, 'Subcategory')} *</label>
                                    <div className="form-input-select">
                                        <select value={this.state.subcategory} onChange={(e) => this.onChangePropertyHandler(e, 'subcategory', 50, 'subcategory')}>
                                            <option value={'select-subcategory'} key={'select-subcategory'}>{getTranslatedPhrase(this.props.userLanguage, 'Select Subcategory')}</option>
                                            {this.state.subcategoriesSelect.map((v, i) => (<option value={v} key={'sub' + i}>{getTranslatedPhrase(this.props.userLanguage, titleCase(v.replace(/-/g, ' ')))}</option>))}
                                        </select>
                                    </div>
                                </div>
                            )}

                            {/* <div className="col span-1-of-3">
                            <label>{getTranslatedPhrase(this.props.userLanguage,'Privacy')} *</label>
                            <div className="form-input-select">
                                <select value={this.state.privacy} onChange={(e) => this.onChangePropertyHandler(e, 'privacy', 50, 'privacy')}>
                                    <option value="public">{getTranslatedPhrase(this.props.userLanguage,'Public')}</option>
                                    <option value="followers">{getTranslatedPhrase(this.props.userLanguage,'Followers')}</option>
                                    <option value="private">{getTranslatedPhrase(this.props.userLanguage,'Private')}</option>
                                </select>
                            </div>
                        </div> */}
                        </div>


                        <div className="row row-border-divider" />


                        <div className="row">
                            <div className="col span-2-of-2">
                                <label>{getTranslatedPhrase(this.props.userLanguage, 'Add Photos To Gallery')} * ({getTranslatedPhrase(this.props.userLanguage, '20 max at a time')})</label>
                                <input type="file" className="button button-regular setFeaturedImageBtn" multiple="multiple"
                                    accept="image/*" onChange={e => {let n = this; this.onChangeUploadedImagesHandler(e, n);} } />
                            </div>
                        </div>

                        <div className="row">
                            <FormFieldError>{this.state.errorMessageUploader}</FormFieldError>
                        </div>
                        <div className="row row-border-divider" />


                        <div className="row">
                            <div className="col span-2-of-2">
                                <label>{getTranslatedPhrase(this.props.userLanguage, 'Your Photos')} <br /> {(!this.state.newPhotosFiles.length) ? '' : getTranslatedPhrase(this.props.userLanguage, 'Photos will have the right orientation after the post is saved.')}</label>
                                <PhotoPreviewArea
                                    photoList={this.state.newPhotosFiles}
                                    onClick={this.onClickOnPhotoHandler} />
                            </div>
                        </div>


                        <div className="row row-border-divider" />


                        <div className="row">
                            <div className="col span-1-of-2">
                                <textarea placeholder={getTranslatedPhrase(this.props.userLanguage, 'Write your photo gallery description.') + ' *'}
                                    onChange={(e) => this.onChangePropertyHandler(e, 'description', 1000, 'Description')}
                                    value={this.state.description}>
                                </textarea>
                            </div>

                            <div className="col span-1-of-2">
                                <textarea className="keywordsTextarea" placeholder={getTranslatedPhrase(this.props.userLanguage, 'Keywords') + ', ' + getTranslatedPhrase(this.props.userLanguage, 'Example') + ': ' + getTranslatedPhrase(this.props.userLanguage, 'health, food, exercise, fruits, strawberries, smoothie') + '...'}
                                    onChange={(e) => this.onChangePropertyHandler(e, 'hashTags', 1000, 'keywords')}
                                    value={this.state.hashTags}>
                                </textarea>
                            </div>
                        </div>

                    </div>



                    <div className="submit-listing">
                        <FormFieldError>{this.state.errorMessage}</FormFieldError>
                        <LoadingSvg active={this.state.postListingPressed} />
                        <div className="submit-listing-button">
                            <button className="button button-white" onClick={this.onClearDraftHandler}><i className="fal fa-plus" /> {getTranslatedPhrase(this.props.userLanguage, 'Add New')}</button>
                            <button className="button button-regular" onClick={this.onPublishHandler}>{getTranslatedPhrase(this.props.userLanguage, this.state.mainButtonLabel)}</button>
                        </div>

                        <ErrorMessages
                            errorMessageClass={"error-message error-message-info"}
                            errorMessageTitle={getTranslatedPhrase(this.props.userLanguage, 'Success')}
                            errorMessageBody={getTranslatedPhrase(this.props.userLanguage, 'Photo Gallery') + ' ' + getTranslatedPhrase(this.props.userLanguage, 'has been published')} show={this.state.photoGallerySaved}>
                            <Link className="button button-regular button-small dsp-in-blk mgt-sm"
                                to={'/photos/' + this.state.category + '/' + (this.state.subcategory || 'other') + "/" + this.state.slug}>
                                {getTranslatedPhrase(this.props.userLanguage, 'See Your Listing')}
                            </Link>
                        </ErrorMessages>
                    </div>

                </div>
            </>
        );
    }
}


const mapStateToEditPhotoProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage,
    };
};

export default connect(mapStateToEditPhotoProps, null)(EditPhoto);