import React, { Component } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ListingMediaModal from "../../../components/Modals/Media/ListingMediaModal";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../util/lang";
import { toHttps } from "../../../util/toHttps";
// import DefaultImage from '../../../../assets/default/default-events-listing-preview.jpg'

class ListingPhotos extends Component {
  state = {
    openModal: false,
    currentPhoto: -1,
    photos: [],
  };

  componentDidMount() {
    const photoList = [...this.props.photoList];
    const cleanPhotos = [];

    photoList.forEach((el) => {
      cleanPhotos.push(el.replace(/\\/g, ""));
    });

    this.setState({ photos: cleanPhotos });
  }

  componentDidUpdate() {
    if (this.state.photos[0] !== this.props.photoList[0])
      this.setState({ photos: this.props.photoList });
  }

  onClickPhoto = (i) => {
    this.setState({
      openModal: true,
      currentPhoto: i,
    });
  };

  onCloseMediaModal = () => {
    this.setState({
      openModal: false,
    });
  };

  goBack = () => {
    let c = this.state.currentPhoto;
    if (!c) {
      c = this.state.photos.length - 1;
    } else {
      c = c - 1;
    }

    this.setState({
      currentPhoto: c,
    });
  };

  goForward = () => {
    let c = this.state.currentPhoto + 1;
    if (c >= this.state.photos.length) {
      c = 0;
    }

    this.setState({
      currentPhoto: c,
    });
  };

  addDefaultSrc = (ev) => {
    // ev.target.src = DefaultImage;

    // const cleanPhotos = this.state.photos.filter(img => img !== ev.target.src);
    // this.setState({photos: cleanPhotos});

    // console.log(cleanPhotos);

    ev.target.parentElement.remove();
  };

  render() {
    if (!this.props.show || !this.props.photoList) {
      return null;
    }

    return (
      <>
        <div className="ProductDetailBox ProductDetailPhotos">
          <h4>{getTranslatedPhrase(this.props.userLanguage, "Photos")}</h4>

          <div className="MediaGalleryContainer">
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 360: 1, 650: 3, 900: 4 }}
              gutter="10px"
            >
              <Masonry>
                {this.state.photos.map((v, i) => (
                  <button key={i} onClick={() => this.onClickPhoto(i)}>
                    <img
                      onError={this.addDefaultSrc}
                      src={toHttps(
                        v.replace(
                          "https://followone.blob.core.windows.net",
                          "https://cdn-westus2.followone.com"
                        )
                      )}
                      alt={v}
                    />
                  </button>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </div>

        {this.state.currentPhoto === -1 ? null : (
          <ListingMediaModal
            active={this.state.openModal}
            onClickCloseHandler={this.onCloseMediaModal}
            photoElement={
              <img
                src={this.state.photos[this.state.currentPhoto]}
                alt={this.state.photos[this.state.currentPhoto]}
              />
            }
            showArrows={this.state.photos.length > 1}
            goBack={this.goBack}
            goForward={this.goForward}
          />
        )}
      </>
    );
  }
}

const mapStateToListingPhotosProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
  };
};

export default connect(mapStateToListingPhotosProps, null)(ListingPhotos);
