import React, { Component } from "react";
import Swiper from "react-id-swiper";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { connect } from "react-redux";

import { toHttps } from "../../../../shared/util/toHttps";
import { formatPriceAndCurrency, displayNumber } from "../../../../shared/util/number";
import { getTranslatedPhrase } from "../../../../shared/util/lang";

import DefaultImage from "../../../../assets/default/default-events-listing-preview.jpg";
import ListingMediaModal from "../../../../shared/components/Modals/Media/ListingMediaModal";
import EngageBox from "../../../../shared/uielements/Engage/EngageBox";
import RealEstateFloorSize from "../Assets/realestate-surface.svg";
import RealEstateBathroom from '../Assets/realestate-bathroom.svg';
import RealEstateBedroom from '../Assets/realestate-bedroom.svg';

class RealEstateHeader extends Component {
    state = {
        openModal: false,
        currentPhoto: -1,
        photos: []
    };

    componentDidMount() {
        this.setState({ photos: this.props.photos });
    }

    componentDidUpdate() {
        if (this.state.photos[0] !== this.props.photos[0])
            this.setState({ photos: this.props.photos });
    }

    onClickPhoto = (i) => {
        this.setState({
            openModal: true,
            currentPhoto: i
        });
    };

    onCloseMediaModal = () => {
        this.setState({
            openModal: false
        });
    };

    goBack = () => {
        let c = this.state.currentPhoto;
        if (!c) {
            c = this.state.photos.length - 1;
        } else {
            c = c - 1;
        }

        this.setState({
            currentPhoto: c
        });
    };

    goForward = () => {
        let c = this.state.currentPhoto + 1;
        if (c >= this.state.photos.length) {
            c = 0;
        }

        this.setState({
            currentPhoto: c
        });
    };

    addDefaultSrc = (ev) => {
        // const cleanPhotos = this.state.photos.filter(img => img !== ev.target.src);
        // this.setState({ photos: cleanPhotos });

        ev.target.parentElement.remove();
    };

    addDefaultSingleSrc = (ev) => {
        ev.target.src = DefaultImage;
        this.props.photos[0] = DefaultImage;
    };

    render() {
        const params = {
            slidesPerView: "auto",
            // centeredSlides: true,
            // spaceBetween: 20,
            initialSlide: 0,
            keyboard: {
                enabled: !this.state.openModal,
                onlyInViewport: false
            },
            loop: true,
            rebuildOnUpdate: true,
            freeMode: false,
            pagination: {
                el: ".swiper-pagination",
                dynamicBullets: true
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }
        };
        let a = this.props.address.trim();
        if (!a) {
            a = "0"; //Empire State Building';
        }
        a = a.replace(/\W(ste|Ste|Suite|SUITE|apt|Apt|APT|#).?\s?\d+/g, "");
        a = a.replace(/\s\s*/g, "+");
        a = a.replace(/&/g, "and");
        // let cheie3 = "QUl6YVN5QzNzUENUN1cxMXRUUDhMQ0FOdk9Cb0tlMGRLMDhBWW9J";
        // let url = "https://www.google.com/maps/embed/v1/place?k";
        // url = url + `ey=${atob(cheie3)}&q=${a}`;
        // a = encodeURI(a);

        if (!this.props.photos || !this.props.photos.length) {
            this.props.photos[0] = DefaultImage;
        }

        let timeOnMarket;

        if (this.props.listedDate) {
            timeOnMarket = moment().diff(moment(this.props.listedDate), "days");
        }


        return (

            <>
                <ReactTooltip place="bottom" type="info" effect="solid" backgroundColor="#555555" />

                <div className="ProductDetailHeader">
                    <div className="ProductDetailHeaderBox">
                        <div className='product-listing-header-image'>
                            {this.props.photos.length === 1 ?
                                <div key='first-photo' className="ProductDetailHeaderBoxinBox">
                                    <img onError={this.addDefaultSingleSrc} src={toHttps(this.props.photos[0])} alt="234093091" onClick={() => this.onClickPhoto(0)} />
                                </div> :
                                <Swiper {...params}>
                                    {this.state.photos.map((v, i) => (
                                        <div key={i}>
                                            <img onError={this.addDefaultSrc} src={toHttps(v)} alt={"Photo " + i} onClick={() => this.onClickPhoto(i)} />
                                        </div>
                                    ))}
                                </Swiper>
                            }
                        </div>

                        <div className="cost-map">
                            <div className="auto-details-cost">
                                <p>
                                    {((this.props.rentOrSell === 'sell' && !this.props.isMlsBiz) || this.props.category === 'Residential' || /sale/i.test(this.props.listingType)) && 'For Sale'}
                                    {((this.props.rentOrSell === 'rent' && /commercial/i.test(this.props.category)) || /commercial lease/i.test(this.props.category) || this.props.listingType === "For Lease") ? 'For Lease' :
                                        (this.props.rentOrSell === 'rent' || /residential lease/i.test(this.props.category)) ? 'For Rent' : ''}
                                    {(this.props.isMlsBiz || this.props.subcategory === 'Business Opportunity') && <span>Business<br />Opportunity</span>}
                                </p>

                                <div className="dealer-cost">
                                    {(!!this.props.callForPricing
                                        && this.props.callForPricing === "call-for-pricing")
                                        ? (<span className="call-for-pricing">{getTranslatedPhrase(this.props.userLanguage, "Call For Pricing")}</span>)
                                        : (this.props.pickUpLocation === "free-entrance")
                                            ? (<span className="call-for-pricing">{getTranslatedPhrase(this.props.userLanguage, "Free Entrance")}</span>)
                                            : (!this.props.afterDiscountPrice)
                                                ? this.props.productCost === "0"
                                                    ? getTranslatedPhrase(this.props.userLanguage, "FREE")
                                                    : <>
                                                        {formatPriceAndCurrency(this.props.productCost, this.props.productCurrency, ",")}
                                                        {this.props.isMlsCom && this.props.listingType === "For Lease" && (
                                                            <span className="listing-cost-type"> /SF /YR</span>
                                                        )}

                                                        {(!this.props.productRepeat || this.props.isMlsCom) ? "" : (<span className="listing-cost-type">{"/ " + getTranslatedPhrase(this.props.userLanguage, this.props.productRepeat)}</span>)}

                                                    </>
                                                : (<>
                                                    {(this.props.afterDiscountPrice !== "0")
                                                        ? <>
                                                            {this.props.afterDiscountPrice}
                                                            {(!this.props.productRepeat) ? "" : (<span className="listing-cost-type">{"/ " + getTranslatedPhrase(this.props.userLanguage, this.props.productRepeat)}</span>)}
                                                        </>
                                                        : getTranslatedPhrase(this.props.userLanguage, "FREE")}


                                                    {this.props.productCost === "0"
                                                        ? getTranslatedPhrase(this.props.userLanguage, "FREE")
                                                        : null}
                                                </>)}
                                </div>

                                <div className="original-price">
                                    {!this.props.afterDiscountPrice ? null :
                                        <>
                                            <span className="product-cost-discount">
                                                {formatPriceAndCurrency(this.props.productCost, this.props.productCurrency, ",")}
                                            </span>
                                            {/*{(!this.props.productRepeat) ? ""*/}
                                            {/*  : (<span className="listing-cost-type">{"/ " + getTranslatedPhrase(this.props.userLanguage, this.props.productRepeat)}</span>)}*/}
                                        </>}
                                </div>

                                {(this.props.bedrooms || this.props.bathrooms) &&
                                    <div className="sub-price-icons">
                                        {this.props.bedrooms &&
                                            <div>
                                                <img src={RealEstateBedroom} alt="" /> x&nbsp; <strong>{this.props.bedrooms}</strong>
                                            </div>
                                        }

                                        {this.props.bathrooms &&
                                            <div>
                                                <img src={RealEstateBathroom} alt="" /> x&nbsp; <strong>{this.props.bathrooms}</strong>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>

                            <div className="cost-map-icon">
                                <img src={RealEstateFloorSize} alt="floor size" />
                                {this.props.floorSize && <p>Floor Size <br /><strong className="capitalize">{displayNumber(this.props.floorSize)} {this.props.floorSizeUnit === 'Floor Size SQ Meters' ? 'Sq M' : 'SF'} </strong></p>}
                                {!this.props.floorSize && <p>Floor Size <br /><strong className="capitalize">Call Agent</strong></p>}
                            </div>

                            {this.props.cumulativeDaysOnMarket && this.props.availableStatus === 'Sold' ? (
                                <div className="cost-map-icon">
                                    <i className="fal fa-calendar-alt" />
                                    <p>Time on Market <br />
                                        <strong className="capitalize">
                                            {this.props.cumulativeDaysOnMarket} Days
                                        </strong>
                                    </p>
                                </div>) : (
                                <div className="cost-map-icon">
                                    <i className="fal fa-calendar-alt" />
                                    <p>Time on Market <br />
                                        <strong className="capitalize">
                                            {this.props.listedDate ? timeOnMarket + " Days" : "Call Agent"}
                                        </strong>
                                    </p>
                                </div>)
                            }
                        </div>


                        <div className="listing-engage-box listing-header-engage-box">
                            <EngageBox
                                websiteSection={this.props.websiteSection}
                                itemId={this.props.itemId}
                                favoriteItemId={this.props.favoriteItemId}
                                authorId={this.props.authorId}
                                mlsProp={this.props.mlsProp || ''}
                                withComments={false}>
                                {/*<Rate noStars={this.props.productStars} noReviews={this.props.reviewCount} />*/}
                                <button className="print-button" onClick={window.print}><i className="fal fa-print" /></button>
                            </EngageBox>
                        </div>
                    </div>

                    <div className="ProductDetailHeaderBox">
                        <iframe
                            frameBorder="0"
                            style={{ border: "0" }}
                            title="locationMap"
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC3sPCT7W11tTP8LCANvOBoKe0dK08AYoI&q=${a}`}
                            allowFullScreen
                        />
                    </div>

                    {(this.state.currentPhoto === -1) ? null : (
                        <ListingMediaModal
                            active={this.state.openModal}
                            onClickCloseHandler={this.onCloseMediaModal}
                            photoElement={<img src={this.state.photos[this.state.currentPhoto]} alt={this.state.photos[this.state.currentPhoto]} />}
                            showArrows={(this.state.photos.length > 1)}
                            goBack={this.goBack}
                            goForward={this.goForward}
                        // goBackWithArrowLeft={this.goBackWithArrowLeft}
                        />)}
                </div>
            </>
        );
    }
}


const mapStateToProps = (
    state,
    props
) => {
    return {
        userLanguage: state.userLanguage
    };
};

export default connect(mapStateToProps)(RealEstateHeader);
