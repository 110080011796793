import React from 'react';

const SellerMyBusinesses = props => {
    return (
          <div className="seller-findbiz">
              <div className="seller-findbiz-search">
                  <div className="form-input-select" >
                      <select className="form-input-full">
                          <option value="section">Select Status</option>
                          <option value="option1">New</option>
                          <option value="option1">Pending</option>
                          <option value="option1">Sold</option>
                          <option value="option1">No Deal</option>
                          <option value="option1">Don't Contact</option>
                      </select>
                  </div>

                  <div className="form-input-select" >
                      <select className="form-input-full">
                          <option value="section">Section</option>
                          <option value="option1">...</option>
                      </select>
                  </div>

                  <div className="form-input-select" >
                      <select className="form-input-full">
                          <option value="country">Country</option>
                          <option value="option1">...</option>
                      </select>
                  </div>

                  <div className="form-input-select" >
                      <select className="form-input-full">
                          <option value="state">State</option>
                          <option value="option1">...</option>
                      </select>
                  </div>

                  <div className="form-input-select" >
                      <select className="form-input-full">
                          <option value="city">City</option>
                          <option value="option1">...</option>
                      </select>
                  </div>
              </div>

              <div className="seller-findbiz-results">
                  <table>
                      <thead>
                          <tr>
                              <td>Name</td>
                              <td>Address</td>
                              <td>Listings</td>
                              <td>Subscription</td>
                              <td>Status</td>
                              <td>Actions</td>
                          </tr>
                      </thead>

                      <tbody>
                          <tr className="biz-info">
                              <td>Followone</td>

                              <td>
                                  <span>777 108th AVE NE<br />Bellevue WA, 98004</span>
                              </td>
                              <td>4</td>
                              <td>Basic</td>
                              <td className="status">Pending</td>
                              <td>
                                  <button><i className="fal fa-minus" /></button>
                                  {/*<button><i className="fal fa-check"></i></button>*/}
                                  {/*<button><i className="fal fa-times"></i></button>*/}
                                  <button onClick={props.toggleModal}><i className="fal fa-info" /></button>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>

              {/*  Add Pagination  */}
          </div>
    )
}

export default SellerMyBusinesses;