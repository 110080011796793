import React from 'react';
import ListingDetail from '../../../shared/templates/ListingTemplate/listing-detail';

export default function StoreListingDetail(props) {
  return (
    <ListingDetail 
      section="store" 
      createNewTitle="New Store Listing"
    />
  );
}