import React, { useEffect, useState } from 'react';
// import ProfileSettingsNav from '../ProfileNav/ProfileSettingsNav';
import ProfileSettingsBox from '../ProfileSettingsBox';
import { getTranslatedPhrase } from '../../../../shared/util/lang';
import moment from 'moment';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import {hostNameAndPort} from '../../../../shared/util/vars';
import FormFieldError from '../../../../shared/uielements/ErrorMessages/FormFieldError';
import ErrorMessages from '../../../../shared/uielements/ErrorMessages/ErrorMessages';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    ViberShareButton,
    // EmailShareButton,
  } from 'react-share';
import { formatPriceAndCurrency } from '../../../../shared/util/number';
import SettingsMenu from "../SettingsMenu";
import { useHistory } from 'react-router';


function PaidNotifications(props) {
    const [coins, setCoins] = useState(0);
    const [cashinDisabled, setCashinDisabled] = useState('disabled');
    const [cashinClicked, setCashinClicked] = useState(false);
    const [invoiceList, setInvoiceList] = useState([]);
    const [emails, setEmails] = useState('');
    const [totalCoins, setTotalCoins] = useState(0);
    const [success, setSuccess] = useState(false);
    const [copied, setCopied] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const loggedInUser = useSelector(state => state.loggedInUser);// {profileImage: '/assets/email/images/email-invite-friends-2.jpg'});

    const userLanguage = useSelector(state => state.userLanguage);
    const history = useHistory();


    useEffect(() => {
        getCoinStatus();
        checkCashRequestPending();
        updateInvoiceList();
    }, []);

    async function getCoinStatus() {
        try {
            let url = `${hostNameAndPort}/api/ppv/cashincoins`;
            let result = await Axios(url);
            let {data} = result;
            if (!data.error) {
                let bt = 'disabled';
                // data.coins = 576;
                let coinsLocal = coins;
                coinsLocal += Number(data.coins)/10;
                if (coinsLocal >= 50) {
                    bt = 'regular';
                }
                setCoins(coinsLocal);
                setCashinDisabled(bt);
            }
        } catch (err) {
            // Network error
        }
    }

    async function checkCashRequestPending() {
        try {
            let url = `${hostNameAndPort}/api/invoices/checkcashrequestpending`;
            let result = await Axios(url);
            let {data} = result;
            if (!data.error) {
                setCashinClicked(true);
            }
        } catch (err) {
            // Network error
        }
    }

    function messageUntil50dollars() {
        if (coins > 50) {
            return `You have $${coins} and you can cash in $50 anytime.`;
        }
        return `You need $${(50-coins)} more dollars until you can Cashin $50.`;
    }

    function onChangeEmails(e) {
        if (e.target.value.length > 1000) {
            return;
        }
        setEmails(e.target.value);
        if (!!errorMessage) {
            setErrorMessage('');
        }
    }

    async function sendEmailsToFriends(e) {
        let _data = new FormData();
        _data.append('emails', emails);
        let options = {
            url: `${hostNameAndPort}/api/mail/sendaffiliateinvite`,
            method: 'post',
            data: _data, 
        }
        try { let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                setEmails('');
                setSuccess(true);
                setErrorMessage('');
            }
        } catch(err) {
            setErrorMessage(getTranslatedPhrase(userLanguage,'Network error ERRFECSHIN01'));
        }
    }

    async function sendCashinRequest() {
        try {
            if (!coins || Number(coins) < 50) return;
            let options = `${hostNameAndPort}/api/mail/sendcashinrequest`;
            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                setCashinClicked(true);
                setErrorMessage('');
            }
        } catch(err) {
            setErrorMessage(getTranslatedPhrase(userLanguage,'Network error ERRFECSHIN02'));
        }
    }

    function onUpdatePayment() {
        history.push('/cash-in-application');
    }

    async function updateInvoiceList() {
        try {
            let invoiceData = new FormData();
            invoiceData.append('filter', JSON.stringify({ affiliate: 1 }));
            let options = {
                url: `${hostNameAndPort}/api/invoices/items`,
                method: 'post',
                data: invoiceData
            }
            let result = await Axios(options);
            let {data} = result;
            // console.log(data)
            if (!data.error) {
                let totalCoinsLocal = data.items.reduce( (a,v) => a+v.amount, 0);
                let coinsLocal = Number(coins);
                if (!totalCoinsLocal) coinsLocal += 20;
                setCoins(coinsLocal);
                setTotalCoins(totalCoinsLocal);
                setInvoiceList(data.items);
            }
        } catch (err) {
            // Network error
        }
    }

    async function onSubmitOptOut() {
        try {
            let options = {
                url: `${hostNameAndPort}/api/affiliate`,
                method: 'delete'
            };

            let result = await Axios(options);
            let {data} = result;
            if (!data.error) {
                history.push('/');
            } else setErrorMessage(data.error);
        } catch (err) {
            setErrorMessage('Network error ERRFRCSHAPP02');
        }
    }

    
    let shareLink = 'https://www.followone.com/affiliates';

    return(
        <SettingsMenu PaidNotificationsLiActive="active">


        
                <ProfileSettingsBox>
                    <h4 className="txtc">Earnings</h4>

                    <div className="ras-earnings">
                        
                        <div className="ras-circle ras-cash-ready">
                            <p>${coins}</p>
                            <p>Current<br /> Balance</p>
                        </div>

                        <div className="ras-circle">
                            <p>{formatPriceAndCurrency(totalCoins, 'USD', ',')}</p>
                            <p>Total<br /> Paid Amount</p>
                        </div>

                        <div className="ras-circle">
                            <p>{formatPriceAndCurrency(totalCoins+coins, 'USD', ',')}</p>
                            <p>Total<br /> Earned to Date</p>
                        </div>
                    </div>

                    <p className="cash-in-disclaimer">{messageUntil50dollars()}<br />
                    {getTranslatedPhrase(userLanguage,'1 view = $0.10')} &nbsp;|&nbsp; {getTranslatedPhrase(userLanguage,'Every 500 views you can Cashin $50.')}</p>

                    <div className="ras-actions">
                        { !cashinClicked && (<button onClick={sendCashinRequest} className={`button button-${cashinDisabled}`}>Transfer Cash</button>)}
                        { cashinClicked && (<button className={`button button-disabled`}>Request Sent</button>)}
                        <button className="button" onClick={onUpdatePayment}>Update Payment Account</button>
                    </div>
                </ProfileSettingsBox>


                <ProfileSettingsBox>

                    <h4>Invite Your Friends to Join the Cashin<span className="reg-td">&reg;</span> Affiliate Program and they will get $20 credit.</h4>

                    <p><strong>Invite using your social media accounts:</strong></p>
                    <div className="ras-url">                             
                        <CopyToClipboard text={shareLink} onCopy={() => setCopied(true)}>
                            <button className="button button-regular button-copy"><i className="fal fa-copy" /> {copied ? <span>Copied!</span> : null}</button>
                        </CopyToClipboard>

                        <FacebookShareButton url={shareLink} >
                            <button className="button button-regular button-copy"><i className="fab fa-facebook-f" /></button>
                        </FacebookShareButton>
                        <TwitterShareButton url={shareLink} >
                            <button className="button button-regular button-copy"><i className="fab fa-twitter" /></button>
                        </TwitterShareButton>
                        <PinterestShareButton url={shareLink} media={loggedInUser.profileImage}>
                            <button className="button button-regular button-copy"><i className="fab fa-pinterest-p" /></button>
                        </PinterestShareButton>
                        <WhatsappShareButton url={shareLink}>
                            <button className="button button-regular button-copy"><i className="fab fa-whatsapp" /></button>
                        </WhatsappShareButton>
                        <ViberShareButton url={shareLink}>
                            <button className="button button-regular button-copy"><i className="fab fa-viber" /></button>
                        </ViberShareButton>
                    </div>

                    <br />

                    <div className="pns-container-box pns-container-invite">
                        <p><strong>Send email invites by entering their email addresses sepparated by commas:</strong></p>
                        <textarea value={emails} onChange={onChangeEmails} className="form-input-textarea form-input-full" placeholder="email@one.com, email@two.com" />
                        
                        <FormFieldError>{errorMessage}</FormFieldError>
                        <button onClick={sendEmailsToFriends} className="button button-regular button-small">Send Invites</button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <button onClick={onSubmitOptOut} className="button button-small button-sale">Opt Out</button>
                        <ErrorMessages 
                            errorMessageClass={"error-message error-message-info"} 
                            errorMessageTitle={getTranslatedPhrase(userLanguage,'Success')} 
                            errorMessageBody={getTranslatedPhrase(userLanguage,'Your invitation has been sent')} show={success}/>
                    </div>


                    
                </ProfileSettingsBox>



                {(!invoiceList || !invoiceList.length)?null:(
                <ProfileSettingsBox>
                    <h4>Invoices</h4>

                    <table className="ras-invoice-table">
                    <tbody>
                        <tr className="table-header">
                            <td>Invoice ID</td>
                            <td>Date</td>
                            <td>Amount</td>
                            <td>Method</td>
                        </tr>

                        {invoiceList.map( (v,i) => (<tr>
                            <td>{v.title}</td>
                            <td>{moment(v.paidOn).format('L')}</td>
                            <td>{formatPriceAndCurrency(v.amount, 'USD',',')}</td>
                            <td>{v.paymentMethod}</td>
                        </tr>) )}

                    </tbody>
                    </table>

                </ProfileSettingsBox>)}


        </SettingsMenu>
    );
}


export default PaidNotifications;