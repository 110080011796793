import React from "react";

const TeamMember = ({ image, name, role, location, points }) => {
  return (
    <div className="the-team_member">
      <img src={image} alt={name} />
      <div className="the-team_member-details">
        <p className="the-team_member_name">{name}</p>
        <p className="the-team_member_title">{role}</p>
        <p className="the-team_member_history">{location}</p>
        <ul>
          {points?.map((point) => {
            return <li>{point}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default TeamMember;
