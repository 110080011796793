import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
// import SubMenu from './SubMenu';
import { getTranslatedPhrase } from "../../../util/lang";
import Axios from "axios";
import { hostNameAndPort } from "../../../util/vars";
import { connect } from "react-redux";

class Navigation extends Component {
  state = {
    socialSubmenuActive: false,
    storeSubmenuActive: false,
    affiliateId: "",
    accountType: "unknown",
    userIsAuthenticated: false,
    locationPathname: "-",
    pageRefresh: "",
    activeMenuItem: ["", "", "", "", "", "", "", "", "", "", ""],
    hash: "",
  };

  getAccountType = async () => {
    let url = `${hostNameAndPort}/api/user/affiliate-info`;
    try {
      let result = await Axios(url);
      let { data } = result;
      if (!data.error) {
        this.setState(data.user);
      } else
        this.setState({
          affiliateId: "",
          accountType: "unknown",
          userIsAuthenticated: false,
        });
    } catch (err) {
      // Network error
    }
  };

  componentDidMount() {
    this.getAccountType();
  }

  componentDidUpdate() {
    let p = this.props.userIsAuthenticated;
    let q = this.props.pageRefresh;
    if (!this.props.loggedInUser && this.state.accountType !== "unknown") {
      this.setState({
        userIsAuthenticated: false,
        accountType: "unknown",
        affiliateId: "",
      });
      return;
    }
    if (this.state.userIsAuthenticated !== p || this.state.pageRefresh !== q) {
      this.setState({ userIsAuthenticated: p, pageRefresh: q });
      this.getAccountType();
    }
    let m0 = this.props.location.pathname;
    let m = m0.split("/");
    const sections = [
      "blogs",
      "photos",
      "videos",
      "events",
      "groups",
      "coupons",
      "store",
      "autos",
      "food",
      "realestate",
      "jobs",
      "services",
      "travel",
      "specials",
      "notifications",
      "news",
    ];

    const samePage =
      m0 === this.state.locationPathname &&
      window.location.hash === this.state.hash;
    m.shift();
    if (m.length && !samePage) {
      let z = sections.indexOf(m[0]);
      this.setState({ locationPathname: m0, hash: window.location.hash });
      if (
        z < 0 ||
        (m[0] === "notifications" &&
          window.location.hash !== "#paidNotifications")
      ) {
        this.hideAllSubmenus(20);
      } else if (this.state.activeMenuItem[z - 5] !== "active")
        this.hideAllSubmenus(z - 5);
    }
  }

  // toggleSocialSubmenuActive = (idx) => {
  //     let a = Array(11).fill('');
  //     a[idx] = 'active';
  //     this.setState({ socialSubmenuActive: !this.state.socialSubmenuActive, storeSubmenuActive: false, activeMenuItem: a });
  // }

  // toggleStoreSubmenuActive = (idx) => {
  //     let a = Array(11).fill('');
  //     a[idx] = 'active';
  //     this.setState({ storeSubmenuActive: !this.state.storeSubmenuActive, socialSubmenuActive: false, activeMenuItem: a });
  // }

  hideAllSubmenus = (idx) => {
    let a = Array(11).fill("");
    a[idx] = "active";
    this.setState({
      storeSubmenuActive: false,
      socialSubmenuActive: false,
      activeMenuItem: a,
    });
  };

  // onBlurMenu = () => {
  //     setTimeout(() => {
  //         this.hideAllSubmenus();
  //     }, 300);
  // }

  render() {
    return (
      <nav>
        <div>
          <Link
            className={"home-gradient-hover " + this.state.activeMenuItem[0]}
            onClick={() => this.hideAllSubmenus(0)}
            to="/"
          >
            {getTranslatedPhrase(this.props.userLanguage, "Home")}
          </Link>
        </div>
        {/* <div><button className={"social-gradient-hover "+this.state.activeMenuItem[10]} 
                        onClick={() => this.toggleSocialSubmenuActive(10)} 
                        onBlur={this.onBlurMenu} >
                    {getTranslatedPhrase(this.props.userLanguage,'Social')} <span><i className="fal fa-angle-down"></i></span></button> 
                    <SubMenu 
                        subMenuName="menuItemsSocial" 
                        show={this.state.socialSubmenuActive}
                        onClick={() => this.toggleSocialSubmenuActive(10)} />
                </div> */}
        {/*<div><button className={"store-gradient-hover " + this.state.activeMenuItem[1]}*/}
        {/*    onBlur={this.onBlurMenu}*/}
        {/*    onClick={() => this.toggleStoreSubmenuActive(1)} >*/}
        {/*    {getTranslatedPhrase(this.props.userLanguage, 'Store')} <span><i className="fal fa-angle-down"></i></span></button>*/}
        {/*    <SubMenu*/}
        {/*        subMenuName="menuItemsStore"*/}
        {/*        show={this.state.storeSubmenuActive}*/}
        {/*        onClick={() => this.toggleStoreSubmenuActive(1)} />*/}
        {/*</div>*/}
        <div>
          <Link
            className={"store-gradient-hover " + this.state.activeMenuItem[1]}
            onBlur={() => this.hideAllSubmenus(1)}
            onClick={() => this.hideAllSubmenus(1)}
            to="/store"
          >
            {getTranslatedPhrase(this.props.userLanguage, "Store")}
          </Link>
        </div>
        <div>
          <Link
            className={"auto-gradient-hover " + this.state.activeMenuItem[2]}
            onBlur={() => this.hideAllSubmenus(2)}
            onClick={() => this.hideAllSubmenus(2)}
            to="/autos"
          >
            {getTranslatedPhrase(this.props.userLanguage, "Auto")}
          </Link>
        </div>
        {/* <div><Link className={"food-gradient-hover " + this.state.activeMenuItem[3]} onBlur={() => this.hideAllSubmenus(3)} onClick={() => this.hideAllSubmenus(3)} to="/food">{getTranslatedPhrase(this.props.userLanguage, 'Food')}</Link></div> */}
        <div>
          <Link
            className={
              "real-estate-gradient-hover " + this.state.activeMenuItem[4]
            }
            onBlur={() => this.hideAllSubmenus(4)}
            onClick={() => this.hideAllSubmenus(4)}
            to="/realestate"
          >
            {getTranslatedPhrase(this.props.userLanguage, "Real Estate")}
          </Link>
        </div>
        <div>
          <Link
            className={"jobs-gradient-hover " + this.state.activeMenuItem[5]}
            onBlur={() => this.hideAllSubmenus(5)}
            onClick={() => this.hideAllSubmenus(5)}
            to="/jobs"
          >
            {getTranslatedPhrase(this.props.userLanguage, "Jobs")}
          </Link>
        </div>
        <div>
          <Link
            className={
              "services-gradient-hover " + this.state.activeMenuItem[6]
            }
            onBlur={() => this.hideAllSubmenus(6)}
            onClick={() => this.hideAllSubmenus(6)}
            to="/services"
          >
            {getTranslatedPhrase(this.props.userLanguage, "Services")}
          </Link>
        </div>
        {/* <div><Link className={"travel-gradient-hover " + this.state.activeMenuItem[7]} onBlur={() => this.hideAllSubmenus(7)} onClick={() => this.hideAllSubmenus(7)} to="/travel">{getTranslatedPhrase(this.props.userLanguage, 'Travel')}</Link></div> */}

        {!this.state.affiliateId || this.state.accountType !== "personal" ? (
          <div>
            <Link
              className={"specials-main-nav " + this.state.activeMenuItem[8]}
              onBlur={() => this.hideAllSubmenus(8)}
              onClick={() => this.hideAllSubmenus(8)}
              to="/specials"
            >
              {getTranslatedPhrase(this.props.userLanguage, "Specials")}
            </Link>
          </div>
        ) : (
          <div>
            <Link
              className={"specials-main-nav " + this.state.activeMenuItem[9]}
              onBlur={() => this.hideAllSubmenus(9)}
              onClick={() => this.hideAllSubmenus(9)}
              to="/notifications#paidNotifications"
            >
              {getTranslatedPhrase(this.props.userLanguage, "Cashin")} &reg;
            </Link>
          </div>
        )}
      </nav>
    );
  }
}

const mapStateToNavigationProps = (state, props) => {
  return {
    userIsAuthenticated: state.userIsAuthenticated,
    pageRefresh: state.pageRefresh,
    userLanguage: state.userLanguage,
    blurHeaderMenu: state.blurHeaderMenu,
    loggedInUser: state.loggedInUser,
  };
};

export default connect(mapStateToNavigationProps, null)(withRouter(Navigation));
