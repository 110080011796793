import React from "react";
import { useSelector } from "react-redux";

export default function ConversationListItem(props) {
  let style;
  useSelector((state) => state.gotNewMessageFlag);
  useSelector((state) => state.currentConversation);

  let { entityName, lastMessage, profileImage, isOnline, unSeen } = props.data;

  if (!profileImage)
    profileImage =
      "https://cdn-westus2.followone.com/images/default-profile-image.jpg";

  const { contactIdForLoggedInUser, activeConversationMarker } = props;

  if (!unSeen) {
    style = { background: "white" };
  } else if (unSeen === contactIdForLoggedInUser.toString()) {
    style = { background: "#e7efe5", borderBottom: "1px solid white" }; //, 'color': '#000'}
  }

  if (activeConversationMarker) style = { background: "#e0f0ff" };

  return (
    <div
      className="conversation-list-item"
      onClick={props.onClick}
      style={style}
    >
      <img
        className="conversation-photo"
        src={profileImage}
        alt="conversation"
      />
      <div className="conversation-info">
        <h1 className="conversation-title">{entityName}</h1>
        <p className="conversation-snippet">
          {(lastMessage || "").substring(0, 15)}
          {!lastMessage ? "" : "..."}
        </p>
      </div>
      {!isOnline ? null : <i className="fas fa-circle" />}
    </div>
  );
}
