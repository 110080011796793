import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import actionType from "../../../redux-store/action-type";
import BackgroundImage from "../../../assets/home/home-search-medical.jpg";
import AdvTravel from "../../../shared/components/Modals/Search/AdvTravel";
import { getTranslatedPhrase } from '../../../shared/util/lang';

const Healthcare = props => {
    const loggedInUser = useSelector(state => state.loggedInUser);
    const [modalOpen, setModalOpen] = useState(false);
    const userLanguage = useSelector(state => state.userLanguage);

    function checkLogin() {
        if (!loggedInUser) props.onClickLoginButtonHandler("/travel/new-travel");
        else props.history.push("/travel/new-travel");
    }

    function onSubmitTravelForm(e, s) { }

    const advancedSearchHandler = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            {modalOpen && <AdvTravel closeModal={closeModal} {...props} onSubmitHandler={onSubmitTravelForm} />}
            <div className="home-search-box" style={{ backgroundImage: `url(${BackgroundImage})` }}>
                <div className="hsb-background" />
                <div className="hsb-container">
                    <h2>{getTranslatedPhrase(userLanguage, 'Healthcare')}</h2>
                    <form>
                        <div className="hsb-filter">
                            <input type="radio" name="travel" id="stays" value="stays" /> <label htmlFor="facilities">{getTranslatedPhrase(userLanguage, 'Facilities')}</label>
                            <input type="radio" name="travel" id="flights" value="flights" /> <label htmlFor="providers">{getTranslatedPhrase(userLanguage, 'Providers')}</label>
                            <input type="radio" name="travel" id="cars" value="cars" /> <label htmlFor="caregivers">{getTranslatedPhrase(userLanguage, 'Caregivers')}</label>
                            <input type="radio" name="travel" id="packages" value="Products" /> <label htmlFor="products">{getTranslatedPhrase(userLanguage, 'Products')}</label>
                        </div>

                        <div className="hsb-input hsb-input-medical">
                            <input type="text" placeholder={getTranslatedPhrase(userLanguage, "I'm looking for...")} />
                        </div>

                        <div className="hsb-input hsb-input-location" style={{marginTop: '1rem'}}>
                            <input type="text" placeholder={getTranslatedPhrase(userLanguage, "Type a city, state.")} />
                        </div>

                        {/* <div className="date-flex">
                            <div className="hsb-input hsb-input-date">
                                <div className="date-label">{getTranslatedPhrase(userLanguage, 'Check-in')}</div>
                                <input type="text" placeholder={moment(Date.now()).format("MM/DD/YYYY")} />
                            </div>

                            <div className="hsb-input hsb-input-date">
                                <div className="date-label">{getTranslatedPhrase(userLanguage, 'Check-out')}</div>
                                <input type="text" placeholder={moment(Date.now()).format("MM/DD/YYYY")} />
                            </div>
                        </div> */}


                        <div className="hsb-buttons">
                            <div>
                                <button className="button button-white" type="submit">{getTranslatedPhrase(userLanguage, 'Search')}</button>
                                {props.category && <button className="button button-white" type="button" onClick={advancedSearchHandler}>{getTranslatedPhrase(userLanguage, 'Advanced Search')}</button>}
                            </div>
                            <div>
                                <Link to="/travel">{props.category ? getTranslatedPhrase(userLanguage, "Travel Home") : getTranslatedPhrase(userLanguage, "Home")}</Link> /
                              <button style={{ fontSize: "1.4rem", color: "#fff", marginLeft: "1rem" }} onClick={checkLogin}>{getTranslatedPhrase(userLanguage, 'Post Healthcare Listing')}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="coming-soon">{getTranslatedPhrase(userLanguage, 'Under Development')}</div>
            </div>
        </>
    );
};


const mapDispatchToTravelSearchProps = dispatch => {
    return {
        onClickLoginButtonHandler: (returnUrl) => dispatch({
            type: actionType.LOGIN_MODAL,
            accountLoginModalActive: true,
            returnUrl
        })
    };
};

export default connect(null, mapDispatchToTravelSearchProps)(withRouter(Healthcare));