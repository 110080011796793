import React, { useEffect, useState } from "react";
import FormFieldError from "../../../../shared/uielements/ErrorMessages/FormFieldError";
import ErrorMessages from "../../../../shared/uielements/ErrorMessages/ErrorMessages";
import { hostNameAndPort } from "../../../../shared/util/vars";
import Axios from "axios";
import { connect } from "react-redux";
import { getTranslatedPhrase } from "../../../../shared/util/lang";
import { Helmet } from "react-helmet";
import actionType from "../../../../redux-store/action-type";

const initialForm = {
  // Details
  fullName: "",
  phoneNumber: "",
  emailAddress: "",
  fullAddress: "",
  dob: "",

  // Verfication
  userAnswer: "",
  expectedResult: Math.random() * 10,
  questionText: "1+1=",
  formSubmitted: false,
  errorMessage: "",
};

const CareerApplication = (props) => {
  const [contactInfo, setContactInfo] = useState(initialForm);
  const [photoId, setPhotoId] = useState();
  const [previewImage, setPreviewImage] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    genRandQuestion();
  });

  const changeProperty = (e, _prop, _limit, _propLabel) => {
    if (e.target.value.length > _limit) {
      return setContactInfo((prevContactInfo) => {
        return {
          ...prevContactInfo,
          errorMessage:
            getTranslatedPhrase(props.userLanguage, _propLabel) +
            " " +
            getTranslatedPhrase(props.userLanguage, "must be smaller than") +
            " " +
            _limit,
        };
      });
    }

    const value = e.target.value;

    setContactInfo((prevContactInfo) => {
      return { ...prevContactInfo, [_prop]: value, errorMessage: "" };
    });
  };

  const infoIsValid = () => {
    let _ = contactInfo;
    let res = true,
      _err = "";

    if (!_.fullName) {
      _err =
        getTranslatedPhrase(props.userLanguage, "Full Name") +
        " " +
        getTranslatedPhrase(props.userLanguage, "is required");
      res = false;
    }

    if (!_.fullAddress) {
      _err =
        getTranslatedPhrase(props.userLanguage, "Full Address") +
        " " +
        getTranslatedPhrase(props.userLanguage, "is required");
      res = false;
    }

    if (!_.dob) {
      _err =
        getTranslatedPhrase(props.userLanguage, "Date of Birth") +
        " " +
        getTranslatedPhrase(props.userLanguage, "is required");
      res = false;
    }

    if (!_.emailAddress) {
      _err =
        getTranslatedPhrase(props.userLanguage, "Email Address") +
        " " +
        getTranslatedPhrase(props.userLanguage, "is required");
      res = false;
    }

    if (!_.phoneNumber) {
      _err =
        getTranslatedPhrase(props.userLanguage, "Phone Number") +
        " " +
        getTranslatedPhrase(props.userLanguage, "is required");
      res = false;
    }

    // if (!_.reason || _.reason === 'Select Reason') {
    //     _err = getTranslatedPhrase(props.userLanguage,'Please select valid reason');
    //     res = false;
    // }

    if (!/[-_.A-Za-z0-9]+@[a-z][a-z0-9.]*.[a-z]{2,10}/.test(_.emailAddress)) {
      _err = getTranslatedPhrase(
        props.userLanguage,
        "Email address has invalid format"
      );
      res = false;
    }

    if (_.formSubmitted) {
      _err = getTranslatedPhrase(
        props.userLanguage,
        "Message has been sent already"
      );
      res = false;
    }

    if (contactInfo.expectedResult !== Number(contactInfo.userAnswer)) {
      _err = getTranslatedPhrase(props.userLanguage, "Wrong answer");
      res = false;
    }

    setContactInfo((prevContactInfo) => {
      return { ...prevContactInfo, errorMessage: _err };
    });

    return res;
  };

  const onSubmitMessage = async (e) => {
    e.preventDefault();

    if (!infoIsValid()) {
      return;
    }

    let _data = new FormData();
    _data.append("fullName", contactInfo.fullName);
    _data.append("fullAddress", contactInfo.fullAddress);
    _data.append("phoneNumber", contactInfo.phoneNumber);
    _data.append("dob", contactInfo.dob);
    _data.append("emailAddress", contactInfo.emailAddress);
    _data.append("photo", photoId);
    // _data.append('reason', contactInfo.reason);
    // _data.append('message', contactInfo.message)
    let options = {
      url: `${hostNameAndPort}/api/mail/careerapplication`,
      method: "post",
      data: _data,
    };
    try {
      let result = await Axios(options);
      let { data } = result;
      if (!data.error) {
        setContactInfo((prevContactInfo) => {
          return { ...prevContactInfo, formSubmitted: true };
        });
      } else {
        setContactInfo((prevContactInfo) => {
          return {
            ...prevContactInfo,
            errorMessage: getTranslatedPhrase(
              props.userLanguage,
              "Form could not be submitted"
            ),
          };
        });
      }
    } catch (err) {
      setContactInfo((prevContactInfo) => {
        return {
          ...prevContactInfo,
          errorMessage: getTranslatedPhrase(
            props.userLanguage,
            "Network error ERRFECNT01"
          ),
        };
      });
    }
  };

  const genRandQuestion = () => {
    let op1, op2;
    op1 = Math.ceil(Math.random() * 10);
    op2 = Math.ceil(Math.random() * 10);
    let c = document.getElementById("myCanvasForgotPassword");
    if (!c) return;
    if (contactInfo.questionText !== "1+1=") return;
    let ctx = c.getContext("2d");
    ctx.font = "13px Open Sans";
    ctx.fillStyle = "#000000";
    ctx.fillText("Please verify that you are human.", 10, 30);
    ctx.fillText(op1 + "+" + op2 + "=", 10, 60);

    setContactInfo((prevContactInfo) => {
      return {
        ...prevContactInfo,
        questionText: op1 + "+" + op2 + "=",
        expectedResult: op1 + op2,
      };
    });
  };

  const onChangeExpectedResult = (e) => {
    let a = e.target.value;
    if (a.length < 10) {
      setContactInfo((prevContactInfo) => {
        return { ...prevContactInfo, userAnswer: a };
      });
    }
  };

  const selectFile = (event) => {
    setPhotoId(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <div className="main-100 contact">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Career Application | Followone</title>
        <meta name="description" content="Join our awesome Followone team." />
        <link rel="canonical" href="https://followone.com/careers/apply" />
      </Helmet>

      <div className="main-100-header">
        <h1>{getTranslatedPhrase(props.userLanguage, "Career Application")}</h1>
        <p>
          {getTranslatedPhrase(
            props.userLanguage,
            "Usability Tester, Independent Contractor"
          )}
        </p>
      </div>

      <div className="contact-box">
        <input
          type="text"
          className="form-input-text"
          placeholder={getTranslatedPhrase(props.userLanguage, "Full Name")}
          value={contactInfo.fullName}
          onChange={(e) => changeProperty(e, "fullName", 200, "Full Name")}
          autoComplete="off"
        />
        <input
          type="number"
          className="form-input-text"
          placeholder={getTranslatedPhrase(props.userLanguage, "Phone Number")}
          value={contactInfo.phoneNumber}
          onChange={(e) =>
            changeProperty(e, "phoneNumber", 200, "Phone Number")
          }
          autoComplete="off"
        />
        <input
          type="email"
          className="form-input-text"
          placeholder={getTranslatedPhrase(props.userLanguage, "Email Address")}
          value={contactInfo.emailAddress}
          onChange={(e) =>
            changeProperty(e, "emailAddress", 100, "Email Address")
          }
          autoComplete="off"
        />
        <input
          type="text"
          className="form-input-text"
          placeholder={getTranslatedPhrase(props.userLanguage, "Full Address")}
          value={contactInfo.fullAddress}
          onChange={(e) =>
            changeProperty(e, "fullAddress", 200, "Full Address")
          }
          autoComplete="off"
        />
        <input
          type="text"
          className="form-input-text"
          placeholder={getTranslatedPhrase(props.userLanguage, "Date of Birth")}
          value={contactInfo.dob}
          onChange={(e) => changeProperty(e, "dob", 200, "Date of Birth")}
          autoComplete="off"
        />

        <label
          htmlFor="photo-id"
          style={{ paddingLeft: "1.6rem", marginTop: "1rem", display: "block" }}
        >
          Attached Photo ID
        </label>
        <input
          type="file"
          id="photo-id"
          accept="image/*"
          className="form-input-text"
          onChange={selectFile}
        />

        {previewImage && (
          <div style={{ width: "100%" }}>
            <img
              style={{ width: "100%" }}
              className="preview"
              src={previewImage}
              alt=""
            />
          </div>
        )}

        <canvas
          id="myCanvasForgotPassword"
          height="60"
          width="250"
          style={{ border: "0px" }}
        />
        <input
          type="number"
          className="form-input-text"
          value={contactInfo.userAnswer}
          onChange={onChangeExpectedResult}
          placeholder={getTranslatedPhrase(props.userLanguage, "Answer")}
        />

        <FormFieldError>{contactInfo.errorMessage}</FormFieldError>
        <button
          className="button button-regular mgt-sm"
          onClick={onSubmitMessage}
        >
          {getTranslatedPhrase(props.userLanguage, "Submit Application")}
        </button>
        <ErrorMessages
          errorMessageClass={"error-message error-message-info"}
          errorMessageTitle={getTranslatedPhrase(props.userLanguage, "Success")}
          errorMessageBody={getTranslatedPhrase(
            props.userLanguage,
            "Thank you, your application has been submited. If you are selected for the position of Temporary Usability Tester you will receive an email from us."
          )}
          show={contactInfo.formSubmitted}
        />
      </div>
    </div>
  );
};

const mapStateToContactProps = (state, props) => {
  return {
    userLanguage: state.userLanguage,
    reason: state.reason,
  };
};

const mapDispatchToContactProps = (dispatch) => {
  return {
    onSelectReason: (a) => {
      dispatch({ type: actionType.SELECT_REASON, reason: a });
    },
  };
};

export default connect(
  mapStateToContactProps,
  mapDispatchToContactProps
)(CareerApplication);
