import React, { useState } from 'react';
import FoodPreview from './FoodPreview';
import CategoryPage from '../../../shared/templates/CategoryTemplate/category-page';
import FoodSideBar from './FoodSideBar';
import FoodSideBarMobile from './FoodSideBarMobile';
import FoodFeatured from './FoodFeatured';
import FoodHeader from '../../Home/Components/Foods';
import feaImage from '../../../assets/category/followone_cat_feature_food.jpg';

function FoodCategory() {
  const [categoryList, setCategoryList] = useState([]);

  function onUpdateCategories(c) {
    if (!categoryList.length)
      setCategoryList(c);
  }

  return (
    <>
      <div className="category-search-panel" style={{ backgroundImage: `url(${feaImage})` }}>
        <FoodHeader category section="food" categoryList={categoryList} />
      </div>
      <CategoryPage
        section="food"
        description="Search for Meal Options"
        ItemPreview={FoodPreview}
        SideBar={(props) => (<FoodSideBar onLoadCategories={onUpdateCategories} {...props} />)}
        SideBarMobile={(props) => (<FoodSideBarMobile onLoadCategories={onUpdateCategories} {...props} />)}
        FeaturedItems={FoodFeatured}
      />
    </>
  );
}

export default FoodCategory;