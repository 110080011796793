import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import BackgroundImage from "../../../assets/home/home-search-cars.jpg";
import { connect, useSelector } from "react-redux";
import actionType from "../../../redux-store/action-type";
import AdvAuto from "../../../shared/components/Modals/Search/AdvAuto";
import { getTranslatedPhrase } from "../../../shared/util/lang";
import Axios from "axios";
import { hostNameAndPort } from "../../../shared/util/vars";
import Autosuggest from "react-autosuggest";
import { populateMakes, populateModels } from "../../../shared/util/car-models";

const AutoSearch = (props) => {
  const [seller, setSeller] = useState("");
  const [productCondition, setProductCondition] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [address, setAddress] = useState("");
  const [cityMissing, setCityMissing] = useState(false);
  const [carMake, setCarMake] = useState("");
  const [carModel, setCarModel] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [makeElementId, setMakeElementId] = useState("");
  const [modelElementId, setModelElementId] = useState("");
  const [makesLoaded, setMakesLoaded] = useState(false);
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const searchQuery = useSelector((state) => state.searchQuery);

  const [country, setCountry] = useState(
    JSON.parse(searchQuery || "{}").countryUpdated
  );
  const [stateVar, setStateVar] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");

  const loggedInUser = useSelector((state) => state.loggedInUser);
  const userLanguage = useSelector((state) => state.userLanguage);
  const windowLocationSearch = window.location.search;

  function checkLogin() {
    if (!loggedInUser)
      props.onClickLoginButtonHandler("/autos/new-auto-listing");
    else props.history.push("/autos/new-auto-listing");
  }

  const b1 = document.getElementById("modelElementForAutoSearch");
  const b2 = document.getElementById("makeElementForAutoSearch");
  const b2Len = b2 && b2.options.length > 1 ? b2.options.length : 0;
  const b1Len = b1 && b1.options.length ? b1.options.length : 0;
  useEffect(() => {
    if (makeElementId && modelElementId && b1 && b1Len && b2 && b2Len) {
      let cc = b2;
      let i = 0;
      for (; i < cc.options.length; i++)
        if (cc.options[i].value === carMake) break;
      if (i < cc.options.length) cc.selectedIndex = i;
      else cc.selectedIndex = 0;
      populateModels(makeElementId, modelElementId);
      cc = document.getElementById("modelElementForAutoSearch");
      i = 0;
      for (; i < cc.options.length; i++)
        if (cc.options[i].value === carModel) break;
      if (i < cc.options.length) cc.selectedIndex = i;
      else cc.selectedIndex = 0;
    }
  }, [b1, b2, b2Len, b1Len]);

  let currentCountry = JSON.parse(searchQuery || "{}").countryUpdated;

  useEffect(() => {
    if (currentCountry !== country) {
      const ts = {
        category,
        subcategory,
        productCondition,
        seller,
        carMake,
        carModel,
        fuelType,
        address,
        country,
        stateVar,
        city,
        zipCode,
      };
      for (let i of Object.keys(ts)) onChangeItem("", i);
      onChangeAddress(null, { newValue: "" });
      setCountry(currentCountry);
      if (!!props.category) props.history.push("/autos");
    }
  }, [currentCountry]);

  useEffect(() => {
    if (!windowLocationSearch) {
      const ts = {
        category,
        subcategory,
        productCondition,
        seller,
        carMake,
        carModel,
        fuelType,
        address,
        country,
        stateVar,
        city,
        zipCode,
      };
      for (let i of Object.keys(ts)) onChangeItem("", i);
      onChangeAddress(null, { newValue: "" });
      setCountry(currentCountry);
      return;
    }
    const urlParams = new URLSearchParams(windowLocationSearch);

    let cat = urlParams.get("category") || "";
    cat = cat.replace(/%26/g, "&");
    if (cat === "all") cat = "";
    let subcat = urlParams.get("subcategory") || "";
    subcat = subcat.replace(/%26/g, "&");
    if (subcat === "all") subcat = "";
    if (category !== cat) setCategory(cat);
    if (subcategory !== subcat) setSubcategory(subcat);

    const ts = {
      category,
      subcategory,
      productCondition,
      seller,
      carMake,
      carModel,
      fuelType,
      address,
      country,
      stateVar,
      city,
      zipCode,
    };
    let changeState = false;

    // console.log("ts", ts);

    const ns = {
      category: urlParams.get("category") || "",
      subcategory: urlParams.get("subcategory") || "",
      productCondition: urlParams.get("condition") || "",
      seller: urlParams.get("seller") || "",
      carMake: urlParams.get("carMake") || "",
      carModel: urlParams.get("carModel")
        ? urlParams.get("carModel").replace("and", " & ")
        : urlParams.get("carModel") || "",
      fuelType: urlParams.get("fuelType") || "",
      address: urlParams.get("address") || "",
      country: urlParams.get("country") || "",
      stateVar: urlParams.get("state") || "",
      city: urlParams.get("city") || "",
      zipCode: urlParams.get("zipCode") || "",
    };

    // console.log("ns", ns);

    let noFilters = true;
    for (let i in ns)
      if (!!ns[i]) {
        noFilters = false;
        break;
      }

    if (noFilters) return;
    if (
      ns.seller !== ts.seller ||
      ns.carMake !== ts.carMake ||
      ns.carModel !== ts.carModel ||
      ns.address !== ts.address ||
      ns.fuelType !== ts.fuelType ||
      ns.productCondition !== ts.productCondition ||
      ns.country !== ts.country ||
      ns.city !== ts.city ||
      ns.stateVar !== ts.stateVar ||
      ns.zipCode !== ts.zipCode
    )
      changeState = true;

    // console.log(changeState);

    if (changeState) {
      for (let i of Object.keys(ns)) onChangeItem(ns[i], i);
      if (!!ns.city || !!ns.stateVar)
        onChangeAddress(null, { newValue: ns.city + ", " + ns.stateVar });
    }
  }, [windowLocationSearch]);

  function onChangeItem(_value, _prop, regularChange) {
    const changeNow = {
      category: setCategory,
      subcategory: setSubcategory,
      productCondition: setProductCondition,
      seller: setSeller,
      carMake: setCarMake,
      carModel: setCarModel,
      fuelType: setFuelType,
      address: setAddress,
      country: setCountry,
      stateVar: setStateVar,
      city: setCity,
      zipCode: setZipCode,
    };
    if (_prop === "productCondition") {
      if (productCondition === _value && !!regularChange)
        setProductCondition("");
      else setProductCondition(_value);
      return;
    }
    if (_prop === "carMake") {
      setCarMake(_value);
      setCarModel("");
      return;
    }
    if (_prop === "seller") {
      if (seller === _value && !!regularChange) setSeller("");
      else setSeller(_value);
      return;
    }
    changeNow[_prop](_value);
  }

  function onChangeAddress(event, { newValue }) {
    if (/\\/g.test(newValue)) return;
    if (/^ +/.test(newValue)) newValue = newValue.replace(/^ +/, "");
    if (
      address.length < 2 &&
      newValue.length >= 1 &&
      !cityList.length &&
      !/^[0-9]+/.test(newValue)
    )
      getCityList(newValue.substring(0, 2));
    if (newValue.length < 2) {
      if (cityList.length) setCityList([]);
      setCityMissing(false);
    }
    if (/^, ?/.test(newValue)) {
      setCityMissing(true);
      newValue = newValue.replace(/^, ?/, "");
    }
    setAddress(newValue);
  }

  async function getCityList(value) {
    try {
      let localCountry;

      if (!country) {
        if (document.cookie.match(/fo_userCountry=([^;]*)(;|$)/)) {
          let data = document.cookie.match(/fo_userCountry=([^;]*)(;|$)/);
          localCountry = data[1] || "USA";
        }
      } else {
        localCountry = country;
      }

      let result = await Axios(
        hostNameAndPort +
          "/api/cities/options-for/" +
          localCountry +
          "/" +
          value
      );
      let { data } = result;
      if (!data.error) {
        setCityList(data.items);
      } else setCityList([]);
    } catch (error) {
      setCityList([]);
    }
  }

  function getSuggestions(value = "") {
    if (/\\/g.test(value)) return cityList;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    let a = cityList;
    let r;
    if (value.length && value[value.length - 1] === " ") {
      let v = value.substring(0, value.length - 1);
      r = new RegExp("^" + v + ",?", "i");
    } else r = new RegExp("^" + value, "i");
    a = a.filter((v) => r.test(v));

    return inputLength === 0 ? [] : a;
  }

  function getSuggestionValue(suggestion) {
    return suggestion;
  }

  function renderSuggestion(suggestion) {
    return <div>{suggestion}</div>;
  }

  function onSuggestionsFetchRequested({ value }) {
    setSuggestions(getSuggestions(value));
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onSubmitAutosForm(e, s) {
    e.preventDefault();
    if (props.disabled) return;
    let f = "filters-" + Date.now();

    let a = address;
    if (cityMissing) {
      a = ", " + a;
    }
    a = a.split(",");
    let city = "";
    let zipCode = "";
    let state = "";
    if (a.length > 1) {
      city = a[0].trim();
      state = a[1].trim();
    } else {
      a = a[0].trim();
      if (/^[-a-z' ]+$/.test(a.toLowerCase())) city = a;
      // else if (/^[-a-z0-9]+$/.test(a.toLowerCase())) zipCode = a;
    }
    let s_q = JSON.parse(searchQuery || "{}");
    if (!state && country === s_q.country) state = s_q.region;

    let newAutoModel;

    if (carModel.includes("&")) {
      newAutoModel = carModel.replace(" & ", "and"); //
    } else {
      newAutoModel = carModel;
    }

    if (!s) {
      if (
        !seller &&
        !productCondition &&
        !carMake &&
        !carModel &&
        !city &&
        !zipCode &&
        !state &&
        (!country || country === JSON.parse(searchQuery || "{}").countryUpdated)
      )
        props.history.push("/autos");
      else
        props.history
          .push(`/autos/search/${f}?priceAscending=0&priceMin=&priceMax=&viewsDescending=false&priceRange=&withCoupons=false&rating=0&seller=${seller}&yearMin=&yearMax=&condition=${productCondition}&
carMake=${carMake}&carModel=${newAutoModel}&fuelType=&address=&city=${city}&zipCode=${zipCode}&state=${state}&country=${country}&exteriorColor=&category=&subcategory=&keywords=${f}`);
    } else {
      props.history.push(s);
    }
  }

  const advancedSearchHandler = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const inputProps = {
    placeholder: getTranslatedPhrase(userLanguage, "Type a city, state."),
    value: address,
    onChange: onChangeAddress,
  };

  return (
    <>
      {modalOpen && (
        <AdvAuto
          closeModal={closeModal}
          {...props}
          onSubmitHandler={onSubmitAutosForm}
        />
      )}

      <div
        className="home-search-box"
        style={{ backgroundImage: `url(${BackgroundImage})` }}
      >
        <div className="hsb-background" />
        <div className="hsb-container">
          <h2>Auto</h2>
          <form onSubmit={onSubmitAutosForm}>
            <div className="hsb-filter-group">
              <div className="hsb-filter">
                <input
                  type="radio"
                  name="condition"
                  id="new"
                  checked={productCondition === "New"}
                  onChange={(e) => null}
                />{" "}
                <label
                  htmlFor="new"
                  onClick={(e) => onChangeItem("New", "productCondition", true)}
                >
                  {getTranslatedPhrase(userLanguage, "New")}
                </label>
                <input
                  type="radio"
                  name="condition"
                  id="used"
                  checked={productCondition === "Used"}
                  onChange={(e) => null}
                />{" "}
                <label
                  htmlFor="used"
                  onClick={(e) =>
                    onChangeItem("Used", "productCondition", true)
                  }
                >
                  {getTranslatedPhrase(userLanguage, "Used")}
                </label>
              </div>

              <div className="hsb-filter">
                <input
                  type="radio"
                  name="seller"
                  id="bydealer"
                  checked={seller === "dealer"}
                  onChange={(e) => null}
                />{" "}
                <label
                  htmlFor="bydealer"
                  onClick={(e) => onChangeItem("dealer", "seller", true)}
                >
                  {getTranslatedPhrase(userLanguage, "By Dealer")}
                </label>
                <input
                  type="radio"
                  name="seller"
                  id="byowner"
                  checked={seller === "owner"}
                  onChange={(e) => null}
                />{" "}
                <label
                  htmlFor="byowner"
                  onClick={(e) => onChangeItem("owner", "seller", true)}
                >
                  {getTranslatedPhrase(userLanguage, "By Owner")}
                </label>
              </div>
            </div>

            <div className="date-flex">
              <div className="hsb-input hsb-input-auto hsb-input-auto-select">
                <select
                  className="form-input-full"
                  id="makeElementForAutoSearch"
                  ref={(n) => {
                    if (!makeElementId)
                      setMakeElementId("makeElementForAutoSearch");
                    if (makeElementId && modelElementId && !makesLoaded) {
                      populateMakes(makeElementId, modelElementId);
                      setMakesLoaded(true);
                    }
                  }}
                  value={carMake}
                  onChange={(e) => onChangeItem(e.target.value, "carMake")}
                />
              </div>

              <div className="hsb-input hsb-input-auto hsb-input-auto-select">
                <select
                  className="form-input-full"
                  id="modelElementForAutoSearch"
                  ref={(n) => {
                    if (!modelElementId)
                      setModelElementId("modelElementForAutoSearch");
                    if (makeElementId && modelElementId && !makesLoaded) {
                      populateModels(makeElementId, modelElementId);
                      setMakesLoaded(true);
                    }
                  }}
                  value={carModel}
                  onChange={(e) => onChangeItem(e.target.value, "carModel")}
                ></select>
              </div>
            </div>

            <div className="hsb-input hsb-input-location">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />
            </div>

            <div className="hsb-buttons">
              <div>
                <button
                  className="button button-white"
                  type="submit"
                  onSubmit={onSubmitAutosForm}
                >
                  {getTranslatedPhrase(userLanguage, "Search")}
                </button>
                {props.category && (
                  <button
                    className="button button-white"
                    type="button"
                    onClick={advancedSearchHandler}
                  >
                    {getTranslatedPhrase(userLanguage, "Advanced Search")}
                  </button>
                )}
              </div>
              <div>
                <Link to="/autos">
                  {props.category
                    ? getTranslatedPhrase(userLanguage, "Auto Home")
                    : getTranslatedPhrase(userLanguage, "Home")}
                </Link>{" "}
                /
                <button
                  style={{
                    fontSize: "1.4rem",
                    color: "#fff",
                    marginLeft: "1rem",
                  }}
                  onClick={checkLogin}
                >
                  {getTranslatedPhrase(userLanguage, "Sell Cars")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const mapDispatchToAutosSearchProps = (dispatch) => {
  return {
    onClickLoginButtonHandler: (returnUrl) =>
      dispatch({
        type: actionType.LOGIN_MODAL,
        accountLoginModalActive: true,
        returnUrl,
      }),
  };
};

export default connect(
  null,
  mapDispatchToAutosSearchProps
)(withRouter(AutoSearch));
